import { api, q } from 'config/api';

export default function useProvisionedDevicesUsage(variables, overrides) {
  return q.useQuery({
    queryKey: ['provisionedDevicesUsage', variables],
    queryFn: () =>
      api(
        `query provisionedDevicesUsage($rootList: listFields, $usagesList: listFields) {
          devices: provisionedDevicesUsage(list: $rootList) {
            count
            cursor {
              before
              after
            }
            data {
              serialNumber
              model
              createdAt
              device {
                id
                status
                autoClaim
                location {
                  address {
                    address
                    locality
                  }
                }
              }
              organization {
                name
              }
              claimantId
              usages(list: $usagesList) {
                count
                cursor {
                  before
                  after
                }
                data {
                  id
                  messagesTransmitted
                  ownerName
                }
              }
            }
          }
        }`,
        variables,
      ),
    placeholderData: q.keepPreviousData,
    ...overrides,
  });
}
