import { api, q } from 'config/api';

export default function useNotifications(variables) {
  return q.useInfiniteQuery({
    queryKey: ['notifications', variables],
    queryFn({ pageParam }) {
      return api(
        `query notifications($list: listFields) {
          list: notifications(list: $list) {
            data {
              id
              read
              referrerId
              type
              payload
              createdAt
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            sort: {
              direction: 'desc',
            },
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 5000,
  });
}
