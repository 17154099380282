import { api, q } from 'config/api';

export default function useProvisionedDevices(variables) {
  return q.useQuery({
    queryKey: ['provisionedDevices', variables],
    queryFn: () =>
      api(
        `query provisionedDevices($list: listFields) {
          list: provisionedDevices(list: $list) {
            cursor {
              before
              after
            }
            data {
              id
              model
              serialNumber
              claimable
              status
              adminConnectionString: adminConnectionStringPrimary
              moduleConnectionString: moduleConnectionStringPrimary
              certificateErrors {
                module
                admin
              }
              claimant {
                name
              }
              organization {
                name
              }
            }
          }
        }`,
        variables,
      ),
    placeholderData: q.keepPreviousData,
    // refetchInterval: 5000,
  });
}
