import { useForm } from 'react-hook-form';
import { useTitle } from 'react-use';

import { Card, Button } from '@optra/kit';
import { enums } from '@optra/shared';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillBuilderCollaboratorsSelect from 'components/skill-builder-collaborators-select';
import SkillBuilderDeviceSelect from 'components/skill-builder-device-select';
import SkillBuilderProjectTypeSelect from 'components/skill-builder-project-type-select';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus } from 'hooks';
import { useEntitlements, useCurrentUser, useCurrentOrganization } from 'queries';

const defaultValues = {
  name: '',
  directoryUrl: '',
  enterprise: false,
  modelType: enums.MODEL_TYPE.EDGE_IMPULSE.value,

  devices: {
    push: [],
    pull: [],
  },
  collaborators: {
    push: [],
    pull: [],
  },
};

export default function CreateModel() {
  useTitle('New Model | Optra');

  const { handleClose } = useModalContext();
  const form = useForm({
    defaultValues,
  });

  const {
    formState: { errors },
    handleSubmit: onSubmit,
    register,
    setFocus,
    control,
    reset,
  } = form;

  useInputFocus(setFocus, 'name');

  const [currentUser, , isSuccess] = useCurrentUser();
  const [organization] = useCurrentOrganization();
  const isPersonal = organization.id === '$$NONE';

  useOnSuccess(
    () => {
      reset(formValues => {
        return {
          ...defaultValues,
          collaborators: {
            push: [currentUser?.email, ...(formValues?.collaborators?.push || [])],
            pull: formValues?.collaborators?.pull || [],
          },
        };
      });
    },
    { isSuccess },
    [currentUser, reset],
  );

  const Entitlements = useEntitlements();
  const { limit = 0, current = 0 } = Entitlements.data?.edgeImpulse || {};

  const create = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createModel($form: createModelForm!) {
          createModel(form: $form) {
            id
            name
          }
        }`,
        { form },
      ),
  });

  const handleOnSubmit = onSubmit(form => {
    create.mutate(
      {
        name: form.name,
        type: form.modelType,
        collaborators: form.collaborators.push,
        enterprise: form.enterprise,
        samplingDeviceIds: form.devices.push,
      },
      {
        onSuccess(r) {
          handleClose();
        },
      },
    );
  });

  return (
    <ModalInner as="form" onSubmit={handleOnSubmit}>
      <ModalTitle title="New Model" icon="Plus" />
      <ModalBody>
        {create?.isError && (
          <Message variant="danger" title="Couldn't Create Model">
            {create?.error?.message}
          </Message>
        )}
        <Card variant="secondary">
          <div className="space-y-3">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              placeholder="Enter Name..."
              {...register('name', { required: 'Please enter a name.' })}
            />
            <ValidationError errors={errors} name="name" />
          </div>
        </Card>
        <Card variant="secondary" className="my-3">
          <SkillBuilderProjectTypeSelect
            control={control}
            name="enterprise"
            seatsLeft={limit - current}
          />
          <ValidationError errors={errors} name="enterprise" />
        </Card>
        <Card variant="secondary" className="my-3">
          <SkillBuilderDeviceSelect control={control} name="devices" />
          <ValidationError errors={errors} name="devices" />
        </Card>
        {!isPersonal && (
          <Card variant="secondary" className="my-3">
            <SkillBuilderCollaboratorsSelect control={control} name="collaborators" />
            <ValidationError errors={errors} name="collaborators" />
          </Card>
        )}
        <ModalFooter>
          <Button type="submit" size="xl" loading={create?.isPending || Entitlements.isLoading}>
            Save
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalInner>
  );
}
