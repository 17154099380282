import { Card, Icon, Text } from '@optra/kit';

function FeatureItem({ icon, title, description }) {
  return (
    <div className="flex items-start justify-items-start">
      <div className="w-12 h-12 flex-shrink-0 flex items-center justify-center bg-gradient-to-r from-primary-500 to-primary-400 rounded mr-4 text-white">
        <Icon name={icon} size="lg" />
      </div>
      <div>
        <Text className="block font-bold text-sm">{title}</Text>
        <Text className="block text-xs leading-tight">{description}</Text>
      </div>
    </div>
  );
}

export default function PortalSubscriptionFeatures() {
  return (
    <div>
      <Card variant="secondary">
        <Text className="block font-bold mb-6">Your Optra Portal Subscription Includes…</Text>
        <div className="grid grid-cols-2 gap-4">
          <FeatureItem
            icon="Aperture"
            title="Device Management"
            description="Monitor and manage your entire fleet of devices"
          />
          <FeatureItem
            icon="Broadcast"
            title="Remote Deployment"
            description="Deploy firmware and skills rapidly and securely"
          />
          <FeatureItem
            icon="Cube"
            title="Skills Marketplace"
            description="Develop, publish and purchase custom software skills"
          />
          <FeatureItem
            icon="GitBranch"
            title="Workflow Builder"
            description="Combine skills and trigger actions to create complex business logic"
          />
        </div>
      </Card>
    </div>
  );
}
