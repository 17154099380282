import { api, q } from 'config/api';

export default function useLatestSkillVersion(id, overrides) {
  return q.useQuery({
    queryKey: ['skill', id, 'latestSkillVersion'],
    queryFn: () =>
      api(
        `query latestSkillVersion($id: ID!) {
          skill(id: $id) {
            versions(list: { limit: 1 }) {
              data {
                id
                version
                repository {
                  uri
                  username
                  password
                }
                createOptions
                storage
                devices {
                  sound
                  hdmi
                  cameras
                }
                env
                outputs(excludeDefaults: true) {
                  id
                  label
                  tracked
                  mapping {
                    type
                    statusMapping {
                      upperThreshold
                      lowerThreshold
                      direction
                    }
                  }
                }
                inputs {
                  label
                  binding
                  type
                }
                port
                hostName
                endpointAliases
                portBindings
                tmpfs
                shmSize
                port
                removableMedia
                hostNetworking
                dockerCompose {
                  key
                }
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    // refetchInterval: 5000,
    ...overrides,
  });
}
