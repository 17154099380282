import { Routes, Route, Outlet } from 'react-router-dom';

import DataSinkActionForm from 'components/data-sink-action-form';
import DataSinkActionQuery from 'components/data-sink-action-query';

export default function DataSinkActionRoutes(props) {
  const { onClose } = props;
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <DataSinkActionForm onClose={onClose} />
            <Outlet />
          </>
        }
      >
        <Route path="query" element={<DataSinkActionQuery />} />
      </Route>
    </Routes>
  );
}
