import cx from 'classnames';
import cleanProps from 'clean-react-props';
import isEmpty from 'lodash/isEmpty';

import { Button, Icon } from '@optra/kit';

import { api, q } from 'config/api';

export default function DevicesBulkActions({
  selectedDeviceIds,
  selectAll = false,
  filter,
  sort,
  devicesOmitted = [],
  numberOfDevices,
  className,
  ...rest
}) {
  const qc = q.useQueryClient();
  const rebootDevices = q.useMutation({
    mutationFn: form =>
      api(
        `mutation rebootDevices($form: runDevicesFnsForm!) {
          didComplete: runDevicesFns(form: $form)
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
    },
    onError() {
      window.alert('There was an error rebooting the selected device(s). Please try again.');
    },
  });

  const pingDevices = q.useMutation({
    mutationFn: form =>
      api(
        `mutation pingDevices($form: runDevicesFnsForm!) {
          didComplete: runDevicesFns(form: $form)
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
    },
    onError() {
      window.alert('There was an error pinging the selected device(s). Please try again.');
    },
  });

  const updateDevicesFirmware = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateDevicesFirmware($form: updateDevicesFirmwareForm!) {
          didComplete: updateDevicesFirmware(form: $form)
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
    },
    onError() {
      window.alert('There was an error updating the selected device(s). Please try again.');
    },
  });

  if (isEmpty(selectedDeviceIds) && !selectAll) {
    return <div />;
  }

  const form = {
    devices: selectedDeviceIds.map(deviceId => ({
      id: deviceId,
    })),
    selectAll,
    filter,
    sort,
    devicesOmitted,
  };

  const noneSelected =
    (selectAll && devicesOmitted.length === numberOfDevices) ||
    (!selectAll && selectedDeviceIds?.length < 1);

  return (
    <div className={cx('flex flex-nowrap', className)} {...cleanProps(rest)}>
      <Button
        variant="secondary"
        className={cx('rounded-r-none border-r-0', 'flex flex-nowrap items-center px-3 space-x-2')}
        loading={updateDevicesFirmware.isPending}
        onClick={() => {
          if (updateDevicesFirmware.isPending) return;
          if (noneSelected) {
            window.alert('Please select a device or devices to update.');
            return;
          }
          if (
            !window.confirm(
              'Would you like to update the selected device(s) to the latest firmware?',
            )
          )
            return;

          updateDevicesFirmware.mutate(form);
        }}
      >
        <div className="flex items-center space-x-1">
          <Icon name="ArrowCircleUp" color="primary" weight="duotone" size="sm" />
          <span className="text-xs md:text-sm">Firmware</span>
        </div>
      </Button>
      <Button
        variant="secondary"
        className={cx(
          'rounded-r-none rounded-l-none',
          'flex flex-nowrap items-center px-3 space-x-2',
        )}
        loading={rebootDevices.isPending}
        onClick={() => {
          if (rebootDevices.isPending) return;
          if (noneSelected) {
            window.alert('Please select a device or devices to reboot.');
            return;
          }
          if (!window.confirm('Would you like to reboot the selected device(s)?')) return;
          rebootDevices.mutate({
            ...form,
            fn: 'reboot',
          });
        }}
      >
        <div className="flex items-center space-x-1">
          <Icon name="Power" color="primary" weight="duotone" size="sm" />
          <span className="text-xs md:text-sm">Reboot</span>
        </div>
      </Button>
      <Button
        variant="secondary"
        className={cx('rounded-l-none border-l-0', 'flex flex-nowrap items-center px-3 space-x-2')}
        loading={pingDevices.isPending}
        onClick={() => {
          if (pingDevices.isPending) return;
          if (noneSelected) {
            window.alert('Please select a device or devices to ping.');
            return;
          }
          if (!window.confirm('Would you like to ping the selected device(s)?')) return;
          pingDevices.mutate({
            ...form,
            fn: 'ping',
          });
        }}
      >
        <div className="flex items-center space-x-1">
          <Icon name="Broadcast" color="primary" weight="duotone" size="sm" />
          <span className="text-xs md:text-sm">Ping</span>
        </div>
      </Button>
    </div>
  );
}
