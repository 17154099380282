import { Card, Heading, Text } from '@optra/kit';

import StepperInput from 'components/stepper-input';
import ImageSizePreview from 'modals/skill-pipeline-builder/components/image-size-preview';

export default function BoundingBoxCropElement({ config = {}, onChange = () => false }) {
  function handleChange(key, value) {
    onChange({ key, value });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Crop Region</Heading>
      <Card variant="secondary" className="space-y-2 flex flex-col items-center">
        <div className="flex flex-row items-center space-x-8">
          <div className="flex flex-row items-center space-x-2">
            <Text variant="label" size="xs" className="w-14 text-right">
              Top
            </Text>
            <StepperInput
              label="%"
              value={parseInt(config?.top_margin * 100, 10)}
              max={100}
              onChange={v => handleChange('top_margin', v / 100)}
              className="w-24"
            />
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Text variant="label" size="xs" className="w-14 text-right">
              Left
            </Text>
            <StepperInput
              label="%"
              value={parseInt(config?.left_margin * 100, 10)}
              max={100}
              onChange={v => handleChange('left_margin', v / 100)}
              className="w-24"
            />
          </div>
        </div>
        <div className="flex flex-row items-center space-x-8">
          <div className="flex flex-row items-center space-x-2">
            <Text variant="label" size="xs" className="w-14 text-right">
              Bottom
            </Text>
            <StepperInput
              label="%"
              value={parseInt(config?.bottom_margin * 100, 10)}
              max={100}
              onChange={v => handleChange('bottom_margin', v / 100)}
              className="w-24"
            />
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Text variant="label" size="xs" className="w-14 text-right">
              Right
            </Text>
            <StepperInput
              label="%"
              value={parseInt(config?.right_margin * 100, 10)}
              max={100}
              onChange={v => handleChange('right_margin', v / 100)}
              className="w-24"
            />
          </div>
        </div>
        <ImageSizePreview
          top={config?.top_margin}
          left={config?.left_margin}
          bottom={config?.bottom_margin}
          right={config?.right_margin}
        />
      </Card>
    </div>
  );
}
