import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddPlaceholderButton, PrimaryCTAButton } from '@optra/kit';

import ChartsWorkspaceEdit from 'components/charts/charts-workspace-edit';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';

export default function AddWorkspaceButton(props) {
  const { variant = 'placeholder' } = props;

  const navigate = useNavigate();
  const [readOnly] = useHasRoles(['chartViewerOnly'], true);
  const [showWorkspaceModal, setShowWorkspaceModal] = useState(false);

  const qc = q.useQueryClient();
  const createWorkspace = q.useMutation({
    mutationFn: form => {
      if (!isEmpty(form.duplicateFromId)) {
        return api(
          `mutation duplicateWorkspace($form: duplicateWorkspaceForm!) {
              workspace: duplicateWorkspace(form: $form) {
                id
              }
            }`,
          {
            form: {
              sourceWorkspaceId: form.duplicateFromId,
              name: form.name,
              config: form.config,
            },
          },
        );
      }

      return api(
        `mutation createWorkspace($form: createWorkspaceForm!) {
            workspace: createWorkspace(form: $form) {
              id
            }
          }`,
        {
          form: {
            name: form.name,
            config: form.config,
          },
        },
      );
    },
    onSuccess(data) {
      qc.invalidateQueries({ queryKey: ['workspaces'] });
      setShowWorkspaceModal(false);
    },
  });

  if (readOnly) return null;

  const buttonVariants = {
    placeholder: (
      <AddPlaceholderButton
        block
        text="Add Workspace"
        loading={createWorkspace.isPending}
        onClick={() => setShowWorkspaceModal(true)}
      />
    ),
    primary: (
      <PrimaryCTAButton
        text="Add Workspace"
        onClick={() => setShowWorkspaceModal(true)}
        loading={createWorkspace.isPending}
      />
    ),
  };

  return (
    <>
      {buttonVariants[variant]}
      <ChartsWorkspaceEdit
        isCreating
        isOpen={showWorkspaceModal}
        onClose={() => setShowWorkspaceModal(false)}
        onSubmit={form =>
          createWorkspace.mutate(form, {
            onSuccess: r => {
              navigate(`/charts/${r?.workspace.id}`);
            },
          })
        }
        isError={createWorkspace.isError}
        isLoading={createWorkspace.isPending}
      />
    </>
  );
}
