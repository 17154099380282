import { difference, last, omit, mapKeys } from 'lodash';
import * as uuid from 'uuid';

import { Card, Heading, IconButton } from '@optra/kit';

import Select from 'components/select';
import ImageSelector from 'modals/skill-pipeline-builder/components/image-selector';
import InputDimensions, {
  IMAGE_SIZES,
} from 'modals/skill-pipeline-builder/components/input-dimensions';
import useInputLabels from 'modals/skill-pipeline-builder/context/use-input-labels';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function LabelToImageElement(props) {
  const { nodeId } = props || {};
  const [node, { update }] = usePipelineNode(nodeId);
  const labels = useInputLabels(node, 'label');
  const remainingLabels = difference(labels, Object.keys(node?.config?.imageMapping || {}));

  function getIdFromPath(path) {
    return last((path || '').split('/')).split('.')?.[0];
  }

  function getMappingForPath(path) {
    return (Object.entries(node?.config?.imageMapping) || []).find(([l, p]) => p === path);
  }

  function getMetaForPath(path) {
    const imageId = getIdFromPath(path);
    return (node?.meta?.images || []).find(i => i.imageId === imageId);
  }

  function addImageFile({ label, image, ext }) {
    const imageId = uuid.v4();
    const path = `file:///app/skill/config/${imageId}.${ext}`;

    update({
      key: 'config.imageMapping',
      value: {
        ...node?.config?.imageMapping,
        [label]: path,
      },
    });
    update({
      key: 'meta',
      value: {
        images: [...(node?.meta?.images || []), { imageId, image, ext }],
      },
    });
  }

  function updateImageFile({ label, path, image, ext }) {
    const imageId = getIdFromPath(path);
    const metaIndex = (node?.meta?.images || []).findIndex(i => i.imageId === imageId);

    if (metaIndex < 0) {
      return;
    }

    update({
      key: 'meta',
      value: {
        images: [
          ...(node?.meta?.images || [])?.slice(0, metaIndex),
          { imageId, image, ext },
          ...(node?.meta?.images || [])?.slice(metaIndex + 1),
        ],
      },
    });
  }

  function removeImageFile({ label, path }) {
    const imageId = getIdFromPath(path);
    const metaIndex = (node?.meta?.images || []).findIndex(i => i.imageId === imageId);

    if (metaIndex < 0) {
      return;
    }

    update({
      key: 'meta',
      value: {
        images: [
          ...(node?.meta?.images || [])?.slice(0, metaIndex),
          { imageId },
          ...(node?.meta?.images || [])?.slice(metaIndex + 1),
        ],
      },
    });
  }

  function updateLabel(path, label) {
    const [currentLabel] = getMappingForPath(path);

    update({
      key: 'config.imageMapping',
      value: mapKeys(node?.config?.imageMapping, (v, k) => {
        if (k === currentLabel) {
          return label;
        }
        return k;
      }),
    });
  }

  function addImageMapping() {
    if (remainingLabels.length === 0) return;

    update({
      key: 'config.imageMapping',
      value: {
        ...node?.config?.imageMapping,
        [remainingLabels[0]]: '',
      },
    });
  }

  function removeImageMapping(path) {
    if (Object.entries(node?.config?.imageMapping || []).length <= 0) return;
    const imageId = getIdFromPath(path);
    const [currentLabel] = getMappingForPath(path);

    update({
      key: 'config.imageMapping',
      value: {
        ...omit(node?.config?.imageMapping, currentLabel),
      },
    });
    update({
      key: 'meta',
      value: {
        images: [...node?.meta?.images?.filter(img => img.imageId !== imageId)],
      },
    });
  }

  const imageMappings = Object.entries(node?.config?.imageMapping || {});

  return (
    <div className="space-y-3">
      <Heading level={3}>Config</Heading>
      <Card variant="secondary" className="space-y-6">
        <InputDimensions
          value={{ width: node?.config?.imageSize?.[0], height: node?.config?.imageSize?.[1] }}
          onChange={({ width, height, size }) => {
            update({
              key: 'config.imageSize',
              value: size === IMAGE_SIZES.custom ? [width, height] : null,
            });
          }}
        />
      </Card>

      <Heading level={3}>Annotations</Heading>
      <Card variant="secondary" className="space-y-6">
        {imageMappings.map(([label, path], idx) => (
          <div className="flex row items-center space-x-4" key={`image-${idx}`}>
            <ImageSelector
              value={getMetaForPath(path)?.image}
              onChange={(image, ext) => {
                if (image && ext !== '' && path === '') {
                  addImageFile({ label, image, ext });
                } else if (image && ext !== '' && path !== '') {
                  updateImageFile({ label, path, image, ext });
                } else if (!image) {
                  removeImageFile({ label, path });
                }
              }}
            />

            <div className="flex-1">
              <Select
                name="output"
                value={label}
                onChange={e => {
                  updateLabel(path, e.target.value);
                }}
              >
                <option value="">Choose a label...</option>
                {labels.map(label => (
                  <option key={label} value={label} disabled={!remainingLabels.includes(label)}>
                    {label}
                  </option>
                ))}
              </Select>
            </div>

            <div className="flex items-center justify-end space-x-2">
              <IconButton
                name="Minus"
                variant="tertiary"
                disabled={imageMappings.length <= 1}
                onClick={() => {
                  removeImageMapping(path);
                }}
              />
              <IconButton
                name="Plus"
                variant="tertiary"
                disabled={remainingLabels.length <= 0 || imageMappings.length >= labels.length}
                onClick={() => {
                  addImageMapping();
                }}
              />
            </div>
          </div>
        ))}
      </Card>
    </div>
  );
}
