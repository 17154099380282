"use strict";
Object.defineProperty(exports, "giftCardPurchaseOrderGiftCardRevoke", {
    enumerable: true,
    get: function() {
        return giftCardPurchaseOrderGiftCardRevoke;
    }
});
const _zod = require("zod");
const giftCardPurchaseOrderGiftCardRevoke = _zod.z.object({
    giftCardPurchaseOrderId: _zod.z.string().min(1, 'Gift card purchase order id is required'),
    giftCardIds: _zod.z.string().array().nonempty('At least 1 gift card must be selected')
});
