import cx from 'classnames';
import { forwardRef } from 'react';

const VARIANTS = {
  base: ['block', 'w-full', 'rounded-full', 'shadow-sm', 'text-xs'],
  default: [
    // Light
    'border-opacity-50',
    'bg-light-bg-secondary',
    'border-light-fg-secondary',
    // Dark
    'dark:border-opacity-50',
    'dark:bg-dark-bg-primary',
    'dark:border-dark-fg-secondary',
    // Focus
    'focus:border-primary',
    'focus:ring-primary',
  ],
  button: [
    'border',
    'cursor-pointer',
    // Light
    'bg-light-bg-secondary',
    'text-light-fg-primary',
    'border-light-bg-primary',
    'hover:bg-light-bg-primary',
    'hover:border-light-bg-secondary',
    // Dark
    'dark:bg-dark-bg-secondary',
    'dark:text-dark-fg-primary',
    'dark:border-dark-bg-primary',
    'dark:hover:border-dark-bg-secondary',
    'dark:hover:bg-dark-bg-primary',
  ],
  big: [
    // Default
    // Light
    'border-opacity-50',
    'bg-light-bg-secondary',
    'border-light-fg-secondary',
    // Dark
    'dark:border-opacity-50',
    'dark:bg-dark-bg-primary',
    'dark:border-dark-fg-secondary',
    // Focus
    'focus:border-primary',
    'focus:ring-primary',
    'text-base',
    'min-w-[120px]',
  ],
  danger: ['bg-red-800', 'border-red'],
};

export default forwardRef(({ variant = 'default', className, ...rest }, ref) => (
  <select ref={ref} className={cx([VARIANTS.base, VARIANTS[variant], className])} {...rest} />
));
