import cx from 'classnames';

export default function TR({ hover = true, children, className, ...rest }) {
  return (
    <tr
      className={cx([
        hover && 'hover:bg-light-bg-primary dark:hover:bg-dark-bg-tertiary',
        className,
      ])}
      {...rest}
    >
      {children}
    </tr>
  );
}
