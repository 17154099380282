import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Table, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import SkillIcon from 'components/skill-icon';

export default function AdminSkillBuilderBetaNodesList({ nodes = [] }) {
  const navigate = useNavigate();

  if (isEmpty(nodes)) {
    return <EmptyContentMessage icon="Nut" title="No Nodes" />;
  }

  return (
    <Table>
      <Table.Body>
        {nodes.map(node => (
          <Table.Row
            key={node?.id}
            caret={true}
            hover={true}
            onClick={() => navigate(`/admin/skill-builder-content/beta-nodes/${node?.id}`)}
          >
            <Table.TD collapsed>
              <SkillIcon icon={node.icon} size="sm" />
            </Table.TD>
            <Table.TD>
              <Text size="sm">{node?.label}</Text>
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
