import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'ImageLayerElement',
  label: 'Layer',
  icon: 'Stack',
  description: 'Layers one image on top of another.',
  type: 'graphic_elements',
  inputs: [
    {
      label: 'Layered Image',
      key: 'sub_image',
      type: DATA_TYPES['Image'],
      required: true,
    },
    {
      label: 'Base Image',
      key: 'base_image',
      type: DATA_TYPES['Image'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
  schema: z.object({
    inputs: z.object({
      sub_image: z.string().min(1),
      base_image: z.string().min(1),
    }),
  }),
};
