import { Dropdown, DROPDOWN_MENU_PLACEMENT, Button, Icon, Spinner } from '@optra/kit';

import Feature from 'components/feature';

export default function WorkflowEditMenu(props) {
  const { canEdit, canDuplicate, loading, onDelete, workflowId, disabled } = props;

  if (loading) {
    return <Spinner size="sm" color="gradient" />;
  }

  const handleCopyWorkflowId = () => {
    navigator.clipboard.writeText(workflowId);
    window.alert(`Copied workflow id ${workflowId} to your clipboard.`);
  };

  return (
    <Dropdown
      className="flex content-center"
      components={{
        button: ({ open }) => (
          <Button as="span" icon="Gear" size="xs" variant="tertiary">
            <Icon
              name={open ? 'CaretUp' : 'CaretDown'}
              weight="regular"
              size="xs"
              className="ml-1"
            />
          </Button>
        ),
        body: (
          <>
            {canEdit && (
              <>
                <Dropdown.Item to={`/workflows/${workflowId}/edit`} icon="Pencil" text="Rename" />
                <Feature feature="bulkWorkflowConfig">
                  <Dropdown.Item
                    to={`/workflows/${workflowId}/bulk-edit`}
                    icon="FileArrowUp"
                    text="Bulk Edit"
                  />
                </Feature>
              </>
            )}
            {canDuplicate && (
              <>
                <Dropdown.Item
                  to={`/workflows/${workflowId}/duplicate`}
                  icon="Copy"
                  text="Duplicate"
                />
                <Dropdown.Item
                  to={`/workflows/${workflowId}/copy-workflow-items`}
                  icon="ArrowBendUpRight"
                  text="Copy Items To…"
                />
              </>
            )}
            <Dropdown.Item
              icon="ClipboardText"
              text="Copy Workflow Id"
              onClick={handleCopyWorkflowId}
            />
            {canEdit && <Dropdown.Item onClick={onDelete} icon="TrashSimple" text="Delete" />}
          </>
        ),
      }}
      placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
      disabled={disabled}
    />
  );
}
