import { isEmpty, reverse } from 'lodash';
import { useForm, useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Modal, Card, Button, Table, Text } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ValidationError from 'components/validation-error';
import { useInputFocus } from 'hooks';
import { useWorkspaces } from 'queries';

function SelectWorkspace(props) {
  const { data } = useWorkspaces();
  const workspaces = reverse(data);

  const { rules, name, control, defaultValue = '' } = props;

  const controller = useController({
    control,
    rules,
    name,
    defaultValue,
  });

  const currentValue = controller.field?.value;

  return (
    <Card variant="secondary">
      <div className="space-y-2">
        <Label htmlFor={name}>Duplicate To</Label>
        <Table>
          <Table.Body>
            {workspaces?.map?.(({ name, id }) => {
              return (
                <Table.Row
                  key={id}
                  hover
                  selectable
                  selected={currentValue === id}
                  onClick={() => {
                    controller.field.onChange(id);
                  }}
                >
                  <Table.TD>
                    <Text size="sm">{name}</Text>
                  </Table.TD>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </Card>
  );
}

function ChartsWorkspaceForm({ onSubmit, chart, isLoading, isError, error }) {
  const { workspaceId } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      name: chart?.name ? `${chart?.name} Duplicate` : '',
      targetWorkspaceId: workspaceId,
    },
  });
  useInputFocus(setFocus, 'name');

  const handleOnSubmit = handleSubmit(form => {
    onSubmit?.({ ...form, sourceChartId: chart?.id });
  });

  return (
    <Modal.Window as="form" onSubmit={handleOnSubmit}>
      <Modal.Header icon="CopySimple" heading="Duplicate Chart" subHeading={chart?.name} />
      <Modal.Body>
        {!!isError && (
          <Message variant="danger">{!isEmpty(error) ? error : 'Something went wrong...'}</Message>
        )}
        <div className="space-y-3">
          <Card variant="secondary">
            <div className="space-y-2">
              <Label htmlFor="name">Chart Name</Label>
              <Input
                type="text"
                {...register('name', { required: 'Please enter a name.' })}
                readOnly={isLoading}
              />
              <ValidationError errors={errors} name="name" />
            </div>
          </Card>
          <SelectWorkspace control={control} name="targetWorkspaceId" />
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-center">
        <Button type="submit" size="xl" loading={isLoading}>
          Create
        </Button>
      </Modal.Footer>
    </Modal.Window>
  );
}

function ChartsWorkspaceDuplicate(props) {
  const { isOpen, onClose, onSubmit, chart, isLoading, isError, error } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} renderWindow={false}>
      <ChartsWorkspaceForm
        onSubmit={onSubmit}
        chart={chart}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
    </Modal>
  );
}

export default ChartsWorkspaceDuplicate;
