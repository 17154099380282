import { UiState, Card } from '@optra/kit';

export default function MaintenanceMode() {
  return (
    <div className="fixed flex items-center justify-center w-screen h-screen left-0 top-0">
      <Card variant="subtle" className="shadow-xl">
        <UiState
          variant="construction"
          text="Under Maintenance..."
          detail="Apologies, the Optra Portal is currently undergoing maintenance. Please come back later."
        />
      </Card>
    </div>
  );
}
