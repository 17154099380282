import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from '@optra/kit';

import ChartsWorkspaceDuplicate from 'components/charts/charts-workspace-duplicate';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';

export default function DuplicateWorkspaceButton(props) {
  const { workspace } = props; // NOTE: not ideal but good enough

  const [isOpen, setIsOpen] = useState(false);
  const [readOnly] = useHasRoles(['chartViewerOnly'], true);
  const navigate = useNavigate();

  const qc = q.useQueryClient();
  const duplicateWorkspace = q.useMutation({
    mutationFn: form =>
      api(
        `mutation duplicateWorkspace($form: duplicateWorkspaceForm!) {
          workspace: duplicateWorkspace(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workspaces'] });
    },
  });

  return (
    <>
      <Dropdown.Item icon="CopySimple" text="Duplicate" onClick={() => setIsOpen(true)} />
      <ChartsWorkspaceDuplicate
        workspace={workspace}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={form => {
          if (readOnly) return;
          duplicateWorkspace.mutate(form, {
            onSuccess: r => {
              setIsOpen(false);
              navigate(`/charts/${r.workspace.id}`);
            },
          });
        }}
        isError={duplicateWorkspace.isError}
        isLoading={duplicateWorkspace.isPending}
      />
    </>
  );
}
