import { isEmpty } from 'lodash/fp';

import { PrimaryCTAButton, PageWrapper, SubNavList, IconButton } from '@optra/kit';

import PinPageButton from 'components/pin-page-button';

export default function AdminSkillBuilderPageWrapper(props) {
  const { children, loading, error, active, createLink } = props;
  return (
    <PageWrapper
      icon="PuzzlePiece"
      title="Skill Builder Content"
      heading="Skill Builder"
      inline
      loading={loading}
      error={error}
      components={{
        actions: (
          <>
            <PinPageButton />
            <IconButton
              name="Gear"
              variant="secondary"
              size="lg"
              to="/admin/skill-builder-content/config"
            />
            {!isEmpty(createLink) && <PrimaryCTAButton to={createLink} text="New" />}
          </>
        ),
        subnav: (
          <SubNavList>
            <SubNavList.Item
              icon="FileCode"
              to="/admin/skill-builder-content/"
              active={active === 'templates'}
            >
              Templates
            </SubNavList.Item>
            <SubNavList.Item
              icon="Cpu"
              to="/admin/skill-builder-content/models"
              active={active === 'models'}
            >
              Models
            </SubNavList.Item>
            <SubNavList.Item
              icon="Book"
              to="/admin/skill-builder-content/recipes"
              active={active === 'recipes'}
            >
              Recipes
            </SubNavList.Item>
            <SubNavList.Item
              icon="Nut"
              to="/admin/skill-builder-content/beta-nodes"
              active={active === 'nodes'}
            >
              Nodes
            </SubNavList.Item>
          </SubNavList>
        ),
      }}
    >
      {children}
    </PageWrapper>
  );
}
