import { useState } from 'react';

import { api, q } from 'config/api';

export default function useSignedUpload(defaultForm = {}) {
  const [uploadState, setUpload] = useState({
    fetching: false,
  });

  const signUpload = q.useMutation({
    mutationFn: form =>
      api(
        `mutation ($form: signUploadForm!) {
          signature: signUpload(form: $form) {
            url
            fields
          }
        }`,
        { form },
      ),
  });

  const upload = async (blob, form) => {
    try {
      const signature = await signUpload.mutateAsync({
        ...defaultForm,
        ...form,
      });

      const uploadForm = new FormData();
      Object.entries(signature.signature.fields).forEach(([k, v]) => {
        uploadForm.append(k, v);
      });
      uploadForm.append('file', blob);

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener(
          'progress',
          event => {
            setUpload({
              loaded: event.loaded,
              total: event.total,
              percent: Math.floor((event.loaded / event.total) * 100),
              status: 'uploading',
              fetching: true,
            });
          },
          false,
        );

        xhr.addEventListener('load', () => {
          if (xhr.status < 400) {
            setUpload({
              ...uploadState,
              percent: 100,
              status: 'complete',
              fetching: false,
            });
            resolve({
              url: `${signature.signature.url}/${signature.signature.fields.key}`,
              key: signature.signature.fields.key,
            });
          } else {
            setUpload({
              ...uploadState,
              status: 'error',
              fetching: false,
            });
            reject();
          }
        });

        xhr.open('POST', signature.signature.url, true);
        xhr.send(uploadForm);
      });
    } catch (err) {
      throw new Error('Failed to upload!');
    }
  };

  return [upload, uploadState];
}
