import { api, q } from 'config/api';

export default function useFeaturedSkills(variables, overrides) {
  return q.useQuery({
    queryKey: ['featuredSkills', variables],
    queryFn: () =>
      api(
        `
          query featuredSkills($list: listFields) {
            list: marketplaceSkills(list: $list) {
              data {
                id
                name
                icon
                iconUrl
                color
                developerProfile {
                  name
                }
              }
            }
          }
        `,
        {
          ...variables,
          list: {
            limit: 3,
            filter: { featured: true },
            ...variables?.list,
          },
        },
      ),
    placeholderData: q.keepPreviousData,
    ...overrides,
  });
}
