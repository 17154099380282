import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTitle } from 'react-use';

import { Card, Button } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import WorkflowFormFields from 'components/workflow-form-fields';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';

export default function CreateWorkflow() {
  useTitle('New Workflow | Optra');
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');

  const qc = q.useQueryClient();
  const createWorkflow = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createWorkflow($form: createWorkflowForm!) {
          createWorkflow(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    createWorkflow.mutate(form);
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="New Workflow" icon="GitBranch" loading={createWorkflow.isPending} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create Workflow">
            {error.message}
          </Message>
        )}
        <Card variant="secondary">
          <WorkflowFormFields
            register={register}
            errors={errors}
            loading={createWorkflow.isPending}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={createWorkflow.isPending}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
