import { api, q } from 'config/api';

export default function useOutputs(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['outputs', variables],
    queryFn({ pageParam }) {
      return api(
        `query outputs($list: listFields) {
          list: outputs(list: $list) {
            data {
              key
              label
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => data.pages.flatMap(page => page.list.data),
    ...overrides,
  });
}
