import { Icon, Text, DetailHeading, DetailList } from '@optra/kit';

import Feature from 'components/feature';
import List from 'components/list';
import ListItem from 'components/list-item';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';

export default function CreateSkillOptions({ isOpen, onClose, ...rest }) {
  return (
    <ModalInner>
      <ModalTitle title="Create Skill" />
      <ModalBody className="space-y-4">
        <Feature feature="skillBuilder">
          <List variant="secondary">
            <ListItem
              to="/skills/create/templates"
              renderLeft={() => <SkillIcon icon="model" color="#0d9488" size="sm" circle />}
              renderRight={() => <Icon name="CaretRight" color="gray" size="sm" />}
              className="py-4"
            >
              <div>
                <DetailHeading variant="loud">Build Your Own</DetailHeading>
                <DetailList details={['Use our builder to customize your own model.']} />
              </div>
            </ListItem>
          </List>

          <Text variant="label" color="muted" size="xs" className="block text-center">
            Or Bring Your Own…
          </Text>
        </Feature>

        <List variant="secondary">
          <ListItem
            to="/skills/create/docker-image"
            renderLeft={() => <SkillIcon size="sm" />}
            renderRight={() => <Icon name="CaretRight" color="gray" size="sm" />}
            className="py-4"
          >
            <div>
              <DetailHeading variant="loud">Docker Image</DetailHeading>
              <DetailList details={['Point to a Docker image you already have.']} />
            </div>
          </ListItem>
          <Feature feature="dockerCompose">
            <ListItem
              to="/skills/create/docker-compose"
              renderLeft={() => <SkillIcon icon="grid" color="#0db7ed" size="sm" />}
              renderRight={() => <Icon name="CaretRight" color="gray" size="sm" />}
              className="py-4"
            >
              <div>
                <DetailHeading variant="loud">Docker Compose</DetailHeading>
                <DetailList details={['Deploy Docker Compose application']} />
              </div>
            </ListItem>
          </Feature>
        </List>
      </ModalBody>
    </ModalInner>
  );
}
