import { truncate } from 'lodash';
import _isFinite from 'lodash/isFinite';
import { useTitle } from 'react-use';

import {
  DetailHeading,
  DetailList,
  Table,
  VersionLabel,
  WhatsThis,
  Button,
  Icon,
  Tooltip,
  Spinner,
  Text,
} from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Feature, { useFeature } from 'components/feature';
import FlexCenter from 'components/flex-center';
import ModalBody from 'components/modal-body';
import SkillIcon from 'components/skill-icon';
import SkillStatus from 'components/skill-status';
import WebUIButton from 'components/web-ui-button';
import { api, q } from 'config/api';
import { useDevice } from 'queries';

function ResetSkills({ device, handleResetSkills, resetDeviceSkills }) {
  return (
    <div className="flex items-center gap-4">
      {device?.capabilities?.wipeSkills && (
        <>
          <WhatsThis>
            This will remove and reinstall all skills and delete data from all volumes.
          </WhatsThis>
          <Button
            icon="Warning"
            size="xs"
            variant="secondary"
            onClick={handleResetSkills}
            loading={resetDeviceSkills.isPending}
          >
            Reset All Skills
          </Button>
        </>
      )}
      {!device?.capabilities?.wipeSkills && (
        <Tooltip label="This device's firmware doesn't support this feature. Upgrade the firmware to enable it.">
          <span>
            <Button
              icon="Warning"
              size="xs"
              variant="secondary"
              loading={resetDeviceSkills.isPending}
              disabled
            >
              Reset All Skills
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  );
}

function RestartDeviceSkillButton({ device, skill, composeName }) {
  const form = composeName
    ? {
        deviceId: device.id,
        moduleId: 'composer',
        fn: 'restart',
        props: {
          skillname: skill.id,
          service: composeName,
        },
      }
    : {
        deviceId: device.id,
        moduleId: '$edgeAgent',
        fn: 'RestartModule',
        props: {
          id: skill.id,
          schemaVersion: '1.0',
        },
      };
  const restartDeviceSkill = q.useMutation({
    enabled: !!skill.id,
    mutationFn: () =>
      api(
        `mutation restartDeviceSkill($form: runModuleFnForm!) {
          runModuleFn(form: $form)
        }`,
        {
          form,
        },
      ),
  });

  const handleRestartClick = event => {
    event.stopPropagation();
    event.preventDefault();
    if (
      window.confirm(
        `Are you sure you want to restart the "${
          composeName || skill.name
        }" module on this device?`,
      )
    ) {
      restartDeviceSkill.mutate();
    }
  };

  return device?.capabilities?.restartSkill || composeName ? (
    <>
      <Button
        size="xs"
        variant="tertiary"
        icon="Warning"
        loading={restartDeviceSkill.isPending}
        onClick={handleRestartClick}
      >
        <span className="hidden sm:inline">Restart</span>
      </Button>
    </>
  ) : (
    <Tooltip label="This device's firmware doesn't support this feature. Upgrade the firmware to enable it.">
      <span>
        <Button
          size="xs"
          variant="tertiary"
          icon="Warning"
          loading={restartDeviceSkill.isPending}
          disabled
        >
          <span className="hidden sm:inline">Restart</span>
        </Button>
      </span>
    </Tooltip>
  );
}

const deviceComposeStateLabel = {
  running: 'Docker Compose is running',
  stopped: 'Docker Compose is stopped',
  unknown: 'Docker Compose state unknown',
};

export default function DeviceDetailSkills({ deviceId }) {
  useTitle('Device Skills | Optra');
  const skillLogsEnabled = useFeature('skillLogs');
  const skillStatusEnabled = useFeature('skillStatus');

  const { data, isLoading: fetching } = useDevice(
    deviceId,
    `
      id
      status
      capabilities {
        tunneling
        wipeSkills
        restartSkill
      }
      workflows {
        data {
          id
          name
          skills {
            data {
              id
              name
              runningOnDevice(id: $deviceId)
              deleted
              port
              version {
                version
                port
                ... on SkillVersion {
                  deprecated
                }
              }
              skill {
                id
                name
                icon
                iconUrl
                color
                usage
              }
              deviceComposeSkills(id: $deviceId) {
                name
                state
              }
              deviceComposeState(id: $deviceId)
            }
          }
        }
      }
    `,
    { refetchInterval: 20000 },
  );

  const resetDeviceSkills = q.useMutation({
    enabled: !!deviceId,
    mutationFn: () =>
      api(
        `mutation resetDeviceSkills($form: runDeviceFnForm!) {
          device: runDeviceFn(form: $form) {
            id
          }
        }`,
        {
          form: {
            id: deviceId,
            fn: 'wipeSkills',
          },
        },
      ),
  });

  const handleResetSkills = event => {
    event.stopPropagation();
    event.preventDefault();
    if (
      data?.device?.capabilities?.wipeSkills &&
      window.confirm(
        'Are you sure you want to reset all skills installed on this device? This will remove the skills and reinstall them.',
      )
    ) {
      resetDeviceSkills.mutate();
    }
  };

  const workflowsWithSkills =
    data?.device?.workflows?.data?.filter(wf => wf?.skills?.data?.length > 0) || [];

  const workflowSkills = workflowsWithSkills.reduce((acc, workflow) => {
    return [
      ...acc,
      ...(workflow.skills?.data || [])
        .filter(({ skill }) => !skill?.usage)
        .map(ws => ({ ...ws, workflow })),
    ];
  }, []);
  const deviceSkills = workflowsWithSkills.reduce((acc, workflow) => {
    return [
      ...acc,
      ...(workflow.skills?.data || [])
        .filter(({ skill }) => !!skill?.usage)
        .map(ws => ({ ...ws, workflow })),
    ];
  }, []);

  const deviceHasSkills = workflowsWithSkills?.length > 0;

  return (
    <ModalBody size="lg">
      <div className="flex items-center justify-between mb-6">
        <DetailHeading level={2} variant="decorated">
          Skills
        </DetailHeading>
        <ResetSkills
          device={data?.device}
          handleResetSkills={handleResetSkills}
          resetDeviceSkills={resetDeviceSkills}
        />
      </div>

      {fetching && (
        <FlexCenter className="min-h-[200px]">
          <Spinner color="gradient" size="xl" />
        </FlexCenter>
      )}

      {/* Docker Compose Skills */}
      {!fetching &&
        workflowSkills
          .filter(ws => ws && ws.deviceComposeSkills !== null)
          .map(ws => {
            const {
              workflow,
              version,
              skill,
              deviceComposeSkills,
              name: skillName,
              deleted,
              deviceComposeState,
              id,
              port,
            } = ws;
            return (
              <div key={id} className="mb-6">
                <div className="flex items-center justify-between mb-4">
                  <DetailHeading level={2}>
                    {skillName}
                    {skillStatusEnabled && (
                      <Tooltip label={deviceComposeStateLabel[deviceComposeState]}>
                        <span>
                          <SkillStatus
                            status={deviceComposeState}
                            hideLabel={true}
                            className="inline-block ml-2"
                          />
                        </span>
                      </Tooltip>
                    )}
                  </DetailHeading>
                  <div>
                    <Feature feature="deviceTunnel">
                      <WebUIButton
                        deviceId={deviceId}
                        workflowSkillId={id}
                        skillHasWebUI={_isFinite(version?.port) && _isFinite(port)}
                        deviceSupportsTunneling={data?.device?.capabilities?.tunneling}
                        deviceConnected={data?.device?.status === 'enabled'}
                        variant="secondary"
                      />
                    </Feature>
                  </div>
                </div>
                <Table variant="secondary">
                  <Table.Body>
                    {deviceComposeSkills.map(composeSkill => {
                      const { name, state } = composeSkill;
                      return (
                        <Table.Row key={name} hover>
                          <Table.TD collapsed>
                            <SkillIcon
                              icon={skill.icon}
                              iconUrl={skill.iconUrl}
                              color={skill.color}
                              size="sm"
                            />
                          </Table.TD>
                          <Table.TD>
                            <Text size="sm">{truncate(name, { length: 40 })}</Text>
                            <DetailList
                              details={[
                                skillStatusEnabled && {
                                  title: 'Runtime Status',
                                  component: <SkillStatus status={state} />,
                                },
                                {
                                  title: `Version${version?.deprecated ? ' [DEPRECATED]' : ''}`,
                                  component: (
                                    <>
                                      <VersionLabel version={version?.version} />
                                      {version?.deprecated && (
                                        <>
                                          <Icon
                                            name="Warning"
                                            weight="regular"
                                            color="yellow"
                                            size="sm"
                                          />
                                          <span>Deprecated</span>
                                        </>
                                      )}
                                    </>
                                  ),
                                },
                                {
                                  title: 'Workflow',
                                  to: `/workflows?id=${workflow?.id}&name=${workflow?.name}`,
                                  component: (
                                    <>
                                      <Icon
                                        name="CaretDoubleRight"
                                        weight="duotone"
                                        color="primary"
                                        size="sm"
                                      />
                                      <span>{truncate(workflow?.name, { length: 24 })}</span>
                                    </>
                                  ),
                                },
                                deleted && {
                                  title: 'Marked for Deletion',
                                  component: <Icon name="Trash" weight="duotone" size="sm" />,
                                },
                              ]}
                            />
                          </Table.TD>
                          <Table.TD collapsed>
                            <span className="flex items-center flex-nowrap space-x-2 whitespace-nowrap">
                              {skillLogsEnabled && (
                                <Button
                                  size="xs"
                                  variant="tertiary"
                                  icon="Terminal"
                                  to={`../logs?workflowSkillId=${id}.${name}`}
                                >
                                  <span className="hidden sm:inline">Logs</span>
                                </Button>
                              )}
                              <RestartDeviceSkillButton
                                skill={ws}
                                device={data?.device}
                                composeName={name}
                              />
                            </span>
                          </Table.TD>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </div>
            );
          })}

      {/* Workflow Skills */}
      {!fetching && deviceHasSkills && (
        <div className="mb-6">
          <div className="flex items-center justify-between mb-4">
            <DetailHeading level={2}>Standard Skills</DetailHeading>
          </div>
          {workflowSkills.length === 0 ? (
            <EmptyContentMessage title="No Skills Installed" icon="Cube" />
          ) : (
            <Table variant="secondary">
              <Table.Body>
                {workflowSkills
                  ?.filter(_skill => _skill && _skill.deviceComposeSkills === null)
                  .map(_skill => {
                    const {
                      id,
                      name,
                      version,
                      port,
                      runningOnDevice,
                      skill = {},
                      workflow,
                      deleted,
                    } = _skill;

                    return (
                      <Table.Row key={id} hover>
                        <Table.TD collapsed>
                          <SkillIcon
                            icon={skill.icon}
                            iconUrl={skill.iconUrl}
                            color={skill.color}
                            size="sm"
                          />
                        </Table.TD>
                        <Table.TD>
                          <Text size="sm">{truncate(name, { length: 40 })}</Text>
                          <DetailList
                            details={[
                              skillStatusEnabled && {
                                title: 'Runtime Status',
                                component: <SkillStatus status={runningOnDevice} />,
                              },
                              {
                                title: `Version${version?.deprecated ? ' [DEPRECATED]' : ''}`,
                                component: (
                                  <>
                                    <VersionLabel
                                      version={version?.edgeImpulse?.version || version?.version}
                                    />
                                    {version?.deprecated && (
                                      <>
                                        <Icon
                                          name="Warning"
                                          weight="regular"
                                          color="yellow"
                                          size="sm"
                                        />
                                        <span>Deprecated</span>
                                      </>
                                    )}
                                  </>
                                ),
                              },
                              {
                                title: 'Workflow',
                                to: `/workflows?id=${workflow?.id}&name=${workflow?.name}`,
                                component: (
                                  <>
                                    <Icon
                                      name="CaretDoubleRight"
                                      weight="duotone"
                                      color="primary"
                                      size="sm"
                                    />
                                    <span>{truncate(workflow?.name, { length: 24 })}</span>
                                  </>
                                ),
                              },
                              deleted && {
                                title: 'Marked for Deletion',
                                component: <Icon name="Trash" weight="duotone" size="sm" />,
                              },
                            ]}
                          />
                        </Table.TD>
                        <Table.TD collapsed>
                          <span className="flex items-center flex-nowrap space-x-2 whitespace-nowrap">
                            <Feature feature="deviceTunnel">
                              <WebUIButton
                                deviceId={deviceId}
                                workflowSkillId={id}
                                skillHasWebUI={_isFinite(version?.port) && _isFinite(port)}
                                deviceSupportsTunneling={data?.device?.capabilities?.tunneling}
                                deviceConnected={data?.device?.status === 'enabled'}
                              />
                            </Feature>
                            {skillLogsEnabled && (
                              <Button
                                size="xs"
                                variant="tertiary"
                                icon="Terminal"
                                to={`../logs?workflowSkillId=${id}`}
                              >
                                <span className="hidden sm:inline">Logs</span>
                              </Button>
                            )}
                            <RestartDeviceSkillButton skill={_skill} device={data?.device} />
                          </span>
                        </Table.TD>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          )}
        </div>
      )}

      {/* Internal Skills */}
      {!fetching && deviceSkills.length > 0 && (
        <div className="mb-6">
          <div className="flex items-center justify-between mb-4">
            <DetailHeading level={2}>Agents</DetailHeading>
            <WhatsThis>
              Agents are added to your device to enable other features and cannot be modified
              directly
            </WhatsThis>
          </div>
          <Table variant="secondary">
            <Table.Body>
              {deviceSkills?.map(skill => {
                if (!skill) {
                  return null;
                }
                const { id, port, runningOnDevice, workflow, deleted } = skill;
                const isEdgeImpulse = skill.skill?.usage === 'skillBuilder';
                const name = isEdgeImpulse ? workflow.name : skill.name || skill.skill?.name;
                const icon = skill.icon || skill.skill?.icon;
                const color = skill.color || skill.skill?.color;
                const iconUrl = skill.iconUrl || skill.skill?.iconUrl;
                const version = skill.version?.edgeImpulse?.version || skill.version?.version;

                return (
                  <Table.Row key={id} hover>
                    <Table.TD collapsed>
                      <SkillIcon
                        icon={icon}
                        iconUrl={iconUrl}
                        color={color}
                        size="sm"
                        circle={isEdgeImpulse}
                      />
                    </Table.TD>
                    <Table.TD>
                      <Text size="sm">{truncate(name, { length: 40 })}</Text>
                      <DetailList
                        details={[
                          skillStatusEnabled && {
                            title: 'Runtime Status',
                            component: <SkillStatus status={runningOnDevice} />,
                          },
                          isEdgeImpulse ? 'Data Collector' : null,
                          {
                            title: 'Version',
                            component: <VersionLabel version={version} />,
                          },
                          {
                            component: (
                              <WhatsThis>
                                This skill is used to collect data from your device's inputs
                              </WhatsThis>
                            ),
                          },
                          deleted && {
                            title: 'Marked for Deletion',
                            component: <Icon name="Trash" weight="duotone" size="sm" />,
                          },
                        ]}
                      />
                    </Table.TD>
                    <Table.TD collapsed>
                      <span className="flex items-center flex-nowrap space-x-2 whitespace-nowrap">
                        <Feature feature="deviceTunnel">
                          <WebUIButton
                            deviceId={deviceId}
                            workflowSkillId={id}
                            skillHasWebUI={_isFinite(version?.port) && _isFinite(port)}
                            deviceSupportsTunneling={data?.device?.capabilities?.tunneling}
                            deviceConnected={data?.device?.status === 'enabled'}
                          />
                        </Feature>
                        {skillLogsEnabled && (
                          <Button
                            size="xs"
                            variant="tertiary"
                            icon="Terminal"
                            to={`../logs?workflowSkillId=${id}`}
                          >
                            <span className="hidden sm:inline">Logs</span>
                          </Button>
                        )}
                        <RestartDeviceSkillButton
                          skill={{ ...skill, name }}
                          device={data?.device}
                        />
                      </span>
                    </Table.TD>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}

      {!fetching && !deviceHasSkills && (
        <EmptyContentMessage title="No Skills Installed" icon="Cube" />
      )}
    </ModalBody>
  );
}
