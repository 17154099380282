import cx from 'classnames';
import { format } from 'date-fns';
import { truncate, snakeCase, isBoolean, isEmpty } from 'lodash';
import moment from 'moment';
import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { Card, Button, Text, Heading, Table, DetailList, Icon, Spinner } from '@optra/kit';

import DateRangeSelect from 'components/date-range-select';
import { useFeature } from 'components/feature';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import Select from 'components/select';
import SkillIcon from 'components/skill-icon';
import TimestreamActionDataFields from 'components/timestream-action-data-fields';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useWorkflowAction } from 'queries';

function AvailableExport({ exp = {}, variant = 'tertiary' }) {
  if (!exp) return null;
  return (
    <Table.Row className={cx(exp.isInProgress && 'animate-fade-in')}>
      <Table.TD>
        <Text>{exp.tableName}</Text>
        <DetailList size="xs" details={[format(new Date(exp.timestamp), 'PPp')]} />
      </Table.TD>
      <Table.TD collapsed>
        {!!exp.isTimedOut ? (
          <Text className="flex flex-row flex-nowrap items-center whitespace-nowrap space-x-1 text-sm">
            <Icon name="Warning" color="red" weight="bold" size="xs" />
            <span>Timed Out</span>
          </Text>
        ) : !!exp.isInProgress ? (
          <Text className="flex flex-row flex-nowrap items-center whitespace-nowrap space-x-1 text-sm">
            <Spinner color="gradient" size="sm" />
            <span>Generating...</span>
          </Text>
        ) : (
          <Button href={exp?.url} size="xs" variant={variant}>
            Download
          </Button>
        )}
      </Table.TD>
    </Table.Row>
  );
}

function AvailableExports({ availableExports = [] }) {
  return (
    <Table variant="secondary">
      <Table.Body>
        {availableExports?.map?.((exp, idx) => {
          return <AvailableExport key={idx} exp={exp} />;
        })}
      </Table.Body>
    </Table>
  );
}

export default function TimestreamActionForm(props) {
  const { onClose } = props;
  const { workflowActionId } = useParams();
  const allSkillOutputsEnabled = useFeature('allSkillOutputs', 'global');
  const Form = useForm({
    defaultValues: {
      configuration: {},
    },
  });

  const [error, setError] = useState();
  const selectedExportTable = useRef();
  const exportRangeSelector = useRef();

  const defaults = useWorkflowAction(workflowActionId, {
    refetchInterval: 60000,
  });

  useDeepCompareEffect(() => {
    if (Form.formState.isDirty) return;

    const workflowAction = defaults.data.workflowAction;
    const configuration = { ...workflowAction.configuration };

    workflowAction?.workflow?.skills?.data?.forEach(workflowSkill => {
      configuration[workflowSkill?.id] = {
        payload: {},
      };

      workflowSkill?.outputs
        ?.filter(({ output }) => !output.default)
        .forEach(output => {
          const existingConfig =
            workflowAction?.configuration?.[workflowSkill.id]?.payload?.[output.output.id];

          configuration[workflowSkill.id]['payload'][output.output.id] = {
            id: output.output.id,
            as: existingConfig?.as || snakeCase(output.output.id),
            enabled: isBoolean(existingConfig?.enabled) ? existingConfig?.enabled : false,
          };
        });
    });

    Form.reset({
      configuration,
    });
  }, [defaults.data.workflowAction?.workflow?.skills?.data?.map(ws => ws.id)]);

  const qc = q.useQueryClient();
  const updateWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowActionAPI ($form: updateWorkflowActionForm!) {
          workflowAction: updateWorkflowAction(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        {
          form: {
            id: workflowActionId,
            ...form,
          },
        },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
    },
  });

  const exportTable = q.useMutation({
    mutationFn: ({ tableName, timeRange }) =>
      api(
        `mutation exportTimestreamTable ($form: runWorkflowActionMutationForm!) {
          workflowAction: runWorkflowActionMutation(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        {
          form: {
            id: workflowActionId,
            fn: 'exportTable',
            props: { tableName, timeRange },
          },
        },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
    },
  });

  const configuration = defaults.data?.workflowAction?.configuration;

  const handleSubmit = Form.handleSubmit(form => {
    setError(null);

    updateWorkflowAction.mutate(form, {
      onSuccess() {
        onClose();
      },
      onError(error) {
        setError(error);
      },
    });
  });

  const loading = defaults.error || defaults.isLoading;
  const workflowAction = defaults?.data?.workflowAction;
  const workflowSkills = defaults?.data?.workflowAction?.workflow?.skills?.data || [];

  return (
    <>
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
        <Link to="/charts">
          <Message variant="info" title="Captures data for charts">
            This action will capture the data that can be used in charts. Navigate to Charts to use
            this data.
          </Message>
        </Link>

        {error && (
          <Message variant="danger" title="Couldn't Save Action">
            {error.message}
          </Message>
        )}
        {defaults.error && (
          <Message variant="danger" title="Couldn't Load Action">
            {defaults.error.message}
          </Message>
        )}

        <div className="flex justify-end gap-2 py-2">
          <Button to="query" size="xs" variant="secondary" icon="CodeSimple">
            Query
          </Button>
          <DateRangeSelect
            ref={exportRangeSelector}
            heading="Export Data"
            alwaysSelected
            icon="CloudArrowDown"
          >
            <DateRangeSelect.Button size="xs" variant="secondary" icon="CloudArrowDown">
              Export
            </DateRangeSelect.Button>
            <DateRangeSelect.TopArea className="space-y-4">
              <ValidationError message={exportTable?.error?.message} />
              <div>
                <Text variant="label" size="xs" color="muted">
                  Latest Exports
                </Text>
                {!isEmpty(configuration?.availableExports) && (
                  <AvailableExports availableExports={configuration?.availableExports} />
                )}
              </div>
              <Card variant="secondary" className="flex items-center gap-4">
                <Text variant="label" size="xs" color="muted">
                  Data Set
                </Text>
                <div className="flex-1 flex space-x-2">
                  <Select ref={selectedExportTable} readOnly={loading}>
                    <option value="">Select a Data Set</option>
                    {(configuration?.availableTables || []).map(table => (
                      <option key={table.arn} value={table.tableName}>
                        {table.tableName}
                      </option>
                    ))}
                  </Select>
                </div>
              </Card>
            </DateRangeSelect.TopArea>
            <DateRangeSelect.SelectionButtons>
              <Button
                icon="DownloadSimple"
                loading={exportTable.isPending}
                hideChildrenWhenLoading={false}
                onClick={() => {
                  const { from, to } = exportRangeSelector.current.value;
                  exportTable.mutate({
                    tableName: selectedExportTable.current.value,
                    timeRange: {
                      from: moment(from).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                      to: moment(to).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    },
                  });
                }}
                variant="primary"
              >
                Start Export
              </Button>
            </DateRangeSelect.SelectionButtons>
          </DateRangeSelect>
        </div>

        <div>
          <Text variant="label" size="xs" color="muted">
            Table Columns (Metrics)
          </Text>
          {workflowSkills.map?.(workflowSkill => (
            <Card className="mb-2" variant="secondary" key={workflowSkill.id}>
              <div className="flex items-center">
                <SkillIcon
                  size="sm"
                  color={workflowSkill?.skill?.color}
                  icon={workflowSkill?.skill?.icon}
                  iconUrl={workflowSkill?.skill?.iconUrl}
                  className="mr-3"
                />
                <Heading level={4}>
                  {truncate(workflowSkill?.name, {
                    length: 32,
                  })}
                </Heading>
              </div>

              {(workflowSkill.outputs || [])
                .filter(({ output }) => !output.default)
                .map(({ output }) => (
                  <TimestreamActionDataFields
                    key={output.id}
                    Form={Form}
                    output={output}
                    loading={loading}
                    workflowSkill={workflowSkill}
                  />
                ))}
            </Card>
          ))}
        </div>
        {allSkillOutputsEnabled && !isEmpty(workflowAction?.configuration?.otherOutputs) && (
          <div>
            <Text variant="label" size="xs" color="muted">
              Other Metrics
            </Text>
            <Card variant="secondary">
              {workflowAction?.configuration?.otherOutputs?.map(output => (
                <Label key={output} className="mb-1">
                  {output}
                </Label>
              ))}
            </Card>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button size="xl" loading={updateWorkflowAction.isPending} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
