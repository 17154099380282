import { api, q } from 'config/api';

export default function useFirmwares(variables) {
  return q.useInfiniteQuery({
    queryKey: ['firmwares', variables],
    queryFn({ pageParam }) {
      return api(
        `query firmwares($list: listFields) {
          list: firmwares(list: $list) {
            data {
              id
              createdAt
              name
              buildTarget
              key
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    // refetchInterval: 5000,
  });
}
