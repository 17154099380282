import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Button, Table, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';

export default function AdminPurchaseOrdersList({ error, list = [] }) {
  const navigate = useNavigate();

  if (isEmpty(list)) {
    return (
      <EmptyContentMessage
        icon="Key"
        title="No Usage Credits"
        renderAction={() => (
          <Button to="/admin/usage-credits/create-purchase-order" icon="Plus">
            New Redemption Codes
          </Button>
        )}
      />
    );
  }

  return (
    <Table>
      <Table.Body>
        {list.map(item => (
          <Table.Row
            key={item.id}
            hover
            caret
            onClick={() => navigate(`/admin/usage-credits/purchase-order/${item.id}`)}
          >
            <Table.TD className="font-semibold">
              <Text variant="bold">{item.poNumber}</Text>
            </Table.TD>
            <Table.TD>
              <Text color="muted">{item.vendor}</Text>
            </Table.TD>
            <Table.TD>
              <Text color="muted">{item.giftCards?.count} codes</Text>
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
