import { isFinite as _isFinite, isEmpty } from 'lodash';
import { useState, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Button } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import WorkflowSkillFormFields from 'components/workflow-skill-form-fields';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useLibrarySkill } from 'queries';

export default function CreateWorkflowSkill() {
  useTitle('Configure Skill | Optra');
  const { workflowId, skillId } = useParams();
  const { handleClose } = useModalContext();

  const librarySkill = useLibrarySkill(skillId);

  const {
    handleSubmit: onSubmit,
    control,
    register,
    watch,
    reset,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      automaticPort: true,
      exposeToNetwork: true,
    },
  });

  useInputFocus(setFocus, 'name');

  const { fields: inputFields } = useFieldArray({
    control,
    name: 'inputs',
  });
  const { fields: outputFields } = useFieldArray({
    control,
    name: 'outputs',
  });

  const [error, setError] = useState();

  // TODO: Move this to a mutations hook
  const qc = q.useQueryClient();
  const createWorkflowSkill = q.useMutation({
    mutationFn: form =>
      api(
        `mutation($form: createWorkflowSkillForm!) {
          workflowSkill: createWorkflowSkill(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
      qc.invalidateQueries({ queryKey: ['workflows'] });
    },
  });

  const skill = librarySkill.data?.librarySkill;

  const automaticPortSelection = watch('automaticPort');
  const selectedSkillVersionId = watch('skillVersionId');
  const selectedSkillVersion = useMemo(() => {
    const recommendedVersions = skill?.versions?.data?.filter(v => !v.deprecated) || [];
    return selectedSkillVersionId
      ? skill?.versions?.data?.find(v => v.id === selectedSkillVersionId)
      : recommendedVersions.length > 0
      ? recommendedVersions[0]
      : skill?.versions?.data?.[0];
  }, [selectedSkillVersionId, skill?.versions?.data]);

  useOnSuccess(
    () => {
      reset({
        name: skill?.name,
        inputs: selectedSkillVersion?.inputs?.map((input, index) => ({
          key: input?.binding,
          value: '',
          label: selectedSkillVersion?.inputs?.[index]?.label,
        })),
        outputs: selectedSkillVersion?.outputs?.map(output => ({
          id: output?.id,
          enabled: true,
          label: output?.label,
        })),
        skillVersionId: selectedSkillVersion?.id,
        port: '',
        automaticPort: true,
        exposeToNetwork: true,
      });
    },
    { isSuccess: librarySkill.isSuccess },
    [skill, reset, selectedSkillVersion],
  );

  const handleSubmit = onSubmit(form => {
    setError(null);
    createWorkflowSkill.mutate(
      {
        ...form,
        port: !isEmpty(form.port) ? parseInt(form.port) : null,
        workflowId,
        inputs: form?.inputs?.map?.(i => ({
          key: i?.key,
          value: i?.value,
        })),
        outputs: form?.outputs?.map?.(o => ({
          ...o,
          enabled: undefined,
          disabled: !o.enabled,
        })),
      },
      {
        onError(error) {
          setError(error);
        },
        onSuccess() {
          handleClose();
        },
      },
    );
  });

  const loading = librarySkill.isLoading || createWorkflowSkill.isPending;

  const itemNotFound = useItemNotFound({
    fetching: librarySkill.isLoading,
    id: skill?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={skillId} type="Skill" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Configure Skill" icon="Cube" loading={loading} />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Save Skill">
            {error.message}
          </Message>
        )}
        {librarySkill.error && (
          <Message variant="danger" title="Couldn't Load Skill">
            {librarySkill.error.message}
          </Message>
        )}

        <WorkflowSkillFormFields
          errors={errors}
          inputFields={inputFields}
          loading={loading}
          outputFields={outputFields}
          skill={skill}
          register={register}
          workflowSkill={{
            name: skill?.name,
            version: skill?.versions?.data?.[0],
          }}
          versions={skill?.versions?.data}
          showExposedUIPortField={_isFinite(selectedSkillVersion?.port) && !skill?.isDockerCompose}
          automaticPortSelection={automaticPortSelection}
          onToggleAutomaticPortSelection={selectPortAutomatically => {
            // Reset
            setValue('automaticPort', selectPortAutomatically);
            setValue('port', '');
            if (!selectPortAutomatically) setFocus('port');
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
