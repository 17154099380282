import { last, isEmpty, isArray } from 'lodash';
import Papa from 'papaparse';
import { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import { FileInput, Button } from '@optra/kit';

import CsvDocs from 'components/csv-docs';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useSignedUpload } from 'queries';

export default function BulkCreateDevices() {
  useTitle('Bulk Upload | Optra');
  const navigate = useNavigate();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileChecking, setFileChecking] = useState(false);
  const {
    handleSubmit: onSubmit,
    watch,
    setError,
    reset,
    register,
    control,
  } = useForm({
    defaultValues: {
      file: null,
    },
  });
  const { errors } = useFormState({ control });
  const selectedFile = watch('file');

  const [upload, uploadState] = useSignedUpload({ type: 'bulkEnrollments' });

  const qc = q.useQueryClient();
  const createDevices = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createDevices($form: createDevicesForm!) {
          r: createDevices(form: $form) {
            success
            errors
            warnings
          }
        }`,
        { form },
      ),
    onSuccess({ r } = {}) {
      qc.invalidateQueries({ queryKey: ['devices'] });

      const error = r?.errors?.length ? r.errors.join(', ') : r?.error?.message;

      if (!isEmpty(error)) {
        setError('file', {
          type: 'manual',
          message: error,
        });
        return;
      }

      navigate('/devices');
    },
    onError(err) {
      setError('file', {
        type: 'manual',
        message: err.message,
      });
    },
  });

  const hasErrors = !isEmpty(errors);

  const handleUpload = async e => {
    try {
      fileRegistration.onChange(e);
      setFileChecking(true);
      setFileUploaded(false);
      const blob = e.currentTarget.files?.[0];

      if (!blob) {
        setError('file', {
          type: 'manual',
          message: 'CSV is required.',
        });
        return;
      }

      // NOTE: This is a good spot for showing "validation in progress" state
      await new Promise(resolve =>
        Papa.parse(blob, {
          worker: true,
          header: true,
          skipEmptyLines: true,
          step({ data, errors }, parser) {
            if (errors?.length > 0) {
              setError('file', {
                type: 'manual',
                message: `Row ${errors?.[0]?.row}: ${errors?.[0]?.message}`,
              });
              parser.abort();
              return;
            }

            if (isEmpty(data?.serialNumber)) {
              setError('file', {
                type: 'manual',
                message: 'Missing serial number value.',
              });
              parser.abort();
              return;
            }

            if (data?.serialNumber?.length !== 13) {
              setError('file', {
                type: 'manual',
                message: `${data.serialNumber} received ${data?.serialNumber?.length} digits instead of 13 digits`,
              });
              parser.abort();
              return;
            }

            if (isEmpty(data?.name)) {
              setError('file', {
                type: 'manual',
                message: 'Missing name value.',
              });
              parser.abort();
            }
          },
          complete() {
            resolve();
          },
        }),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setFileChecking(false);
      setFileUploaded(true);
    }
  };

  const handleSubmit = onSubmit(async form => {
    if (fileChecking) return;

    const blob = form.file[0];
    if (!blob) {
      setError('file', {
        type: 'manual',
        message: 'A CSV file is required',
      });

      return;
    }
    if (hasErrors) return;

    const { key } = await upload(blob, {
      extension: last(blob.name.split('.')),
    });

    createDevices.mutate({
      key,
    });
  });

  function handleReset() {
    reset();
    setFileUploaded(false);
    setFileChecking(false);
  }

  const submitting = uploadState?.fetching || createDevices.isPending;
  const currentFile = watch('file');
  const hasFile = currentFile?.length === 1;
  const fileRegistration = register('file', { required: true });

  const helpColumns = [
    {
      column: 'serialNumber',
      name: 'Serial Number',
      description: null,
      examples: ['8080000000001'],
      required: true,
    },
    {
      column: 'name',
      name: 'Device Name',
      description: null,
      examples: ['Device Name'],
      required: true,
    },
    {
      column: 'workflowName',
      name: 'Workflow Name',
      description: 'New or existing workflow',
      examples: ['Workflow Name'],
      required: false,
    },
    {
      column: 'groupName',
      name: 'Group Name',
      description: 'New or existing group',
      examples: ['Group Name'],
      required: false,
    },
    {
      column: 'tagNames',
      name: 'Tag Names',
      description: 'separated by “|”',
      examples: ['Tag1|Tag2'],
      required: false,
    },
    {
      column: 'locationId',
      name: 'Location Id',
      description: 'Id of an existing location',
      examples: ['1234'],
      required: false,
    },
    {
      column: 'locationName',
      name: 'Location Name',
      description:
        'Name of a new or existing location (address required for at least the first row)',
      examples: ['Location Name'],
      required: false,
    },
    {
      column: 'lat',
      name: 'Latitude',
      description: null,
      examples: ['38.046360'],
      required: false,
    },
    {
      column: 'lng',
      name: 'Longitude',
      description: null,
      examples: ['-84.497017'],
      required: false,
    },
    {
      column: 'address',
      name: 'Address',
      description: 'Address of a location',
      examples: ['"123 Easy Street, OH, USA"'],
      required: false,
    },
    {
      column: 'skipAutoUpdate',
      name: 'Skip Firmware Update',
      description: null,
      examples: ['true', 'false'],
      required: false,
    },
  ];

  let detail = 'Waiting for CSV file…';
  let error =
    !isEmpty(errors?.file?.message) && !isArray(errors?.file?.message) && errors.file.message;

  if (hasFile && fileUploaded) {
    detail = 'CSV file ready for upload…';
  } else {
    if (fileChecking) {
      detail = 'Validating file…';
    } else if (uploadState?.fetching) {
      detail = `${uploadState.percent} Complete`;
    } else if (submitting) {
      detail = 'Processing file…';
    }
  }

  return (
    <ModalInner>
      <ModalTitle title="Bulk Upload" />
      <div className="flex-shrink-0 px-4">
        <FileInput
          prompt="Upload CSV File"
          result={selectedFile?.[0]}
          accept=".csv"
          {...fileRegistration}
          onChange={handleUpload}
          detail={detail}
          error={error}
          loading={uploadState?.fetching}
        >
          {hasErrors && (
            <Button size="xs" variant="tertiary" onClick={handleReset}>
              Reset
            </Button>
          )}
        </FileInput>
      </div>
      <ModalBody>
        <CsvDocs columns={helpColumns} />
      </ModalBody>
      <ModalFooter>
        <Button
          size="xl"
          loading={submitting}
          disabled={!hasFile || fileChecking}
          onClick={handleSubmit}
        >
          Upload
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
