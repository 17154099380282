import { PageWrapper } from '@optra/kit';

import AdminInternalSkillsList from 'components/admin-internal-skills-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import { useInternalSkills } from 'queries';

export default function AdminInternalSkills() {
  const {
    data: skills,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInternalSkills();
  return (
    <PageWrapper
      icon="Cube"
      title="Agents"
      heading="Agents"
      inline={true}
      loading={isLoading}
      error={error}
    >
      <AdminInternalSkillsList skills={skills} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={!hasNextPage || isFetchingNextPage}
      />
    </PageWrapper>
  );
}
