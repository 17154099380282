import { api, q } from 'config/api';

export default function useWorkflowSkillSkills(variables) {
  return q.useInfiniteQuery({
    queryKey: ['workflowSkillSkills', variables],
    queryFn({ pageParam }) {
      return api(
        `query workflowSkillSkills($list: listFields) {
          list: workflowSkillSkills(list: $list) {
            data {
              id
              name
              icon
              iconUrl
              color
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
  });
}
