import { useParams } from 'react-router-dom';

import { Card, Button, Text, Heading } from '@optra/kit';

import DeviceImage from 'components/device-image';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import { useDevice } from 'queries';

export default function DeviceCreateConfirmation() {
  const { deviceId } = useParams();
  const {
    data,
    isLoading: fetching,
    error: fetchError,
  } = useDevice(
    deviceId,
    `
      id
      name
      model
      serialNumber
    `,
  );

  return (
    <>
      <ModalTitle title="Enroll Device" icon="Aperture" showBackButton={false} loading={fetching} />
      <ModalBody className="space-y-4">
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Device">
            {fetchError.message}
          </Message>
        )}

        <Heading variant="centered" icon="CheckCircle">
          Device Enrolled
        </Heading>

        <Card variant="secondary" className="text-center space-y-3">
          <DeviceImage model={data?.device?.model} hideStatus size="xl" className="mx-auto" />

          <Text className="block">{data?.device?.name}</Text>
          <Text size="xs" color="muted" className="block uppercase font-mono">
            {data?.device?.serialNumber}
          </Text>
        </Card>
      </ModalBody>

      <ModalFooter className="space-x-2">
        <Button to={`/devices/${data?.device?.id}`} variant="secondary" icon="Gear">
          Configure
        </Button>
        <Button to="." variant="secondary" icon="Plus">
          Add Another
        </Button>
      </ModalFooter>
    </>
  );
}
