import { api, q } from 'config/api';

// Deprecate and use library skill instead
export default function useSkill(id, overrides) {
  return q.useQuery({
    queryKey: ['skill', id],
    queryFn: () =>
      api(
        `query skill($id: ID!) {
          skill(id: $id) {
            id
            name
            icon
            color
            iconUrl
            publishing
            published
            reviewing
            changesRequested
            versions {
              data {
                id
                version
              }
            }
            isDockerCompose
            isSkillBuilder
            isSkillBuilderV2
          }
        }`,
        { id },
      ),
    enabled: !!id,
    // refetchInterval: 5000,
    ...overrides,
  });
}
