import { Controller } from 'react-hook-form';

import { Card } from '@optra/kit';

import KeyValueInput from 'components/key-value-input';

export default function SkillEnvFields({ visible, control, loading }) {
  return (
    <Card variant="secondary" className={visible ? 'block' : 'hidden'}>
      <Controller
        render={({ field }) => (
          <KeyValueInput {...field} keyLabel="ENV Key" valueLabel="ENV Label" />
        )}
        name="env"
        control={control}
        loading={loading}
      />
    </Card>
  );
}
