import cx from 'classnames';
import { isNil, noop, last } from 'lodash';
import { useRef } from 'react';

import { IconButton, Icon } from '@optra/kit';

import SkillIcon from 'components/skill-icon';

export default function VideoSelector(props) {
  const { value, onChange: _onChange = noop, disabled } = props;
  const onChange = disabled ? noop : _onChange;
  const uploadIconInput = useRef();

  const onVideoChange = e => {
    const file = e.target.files?.[0];
    if (!file) return;

    const fileExt = last(file.name.split('.'));
    const fileReader = new FileReader();

    fileReader.onload = e => {
      if (fileReader.readyState === 2) {
        onChange(file, fileExt);
      }
    };

    fileReader.readAsDataURL(file);
  };

  return (
    <div className={cx(['w-24', 'h-24', 'relative'])}>
      <div className="flex items-center justify-center space-x-2">
        <div
          className={cx(
            'w-24',
            'h-24',
            'flex',
            'shrink-0',
            'items-center',
            'justify-center',
            'flex-shrink-0',
            'rounded-2xl',
            'relative',
            'bg-light-bg-secondary',
            'text-light-fg-secondary',
            'dark:bg-dark-bg-secondary',
            'dark:text-dark-fg-secondary',
          )}
        >
          {isNil(value) && <Icon name="CloudArrowUp" size="xl" />}
          {!isNil(value) && <SkillIcon size="lg" icon="play" />}
          <input
            ref={uploadIconInput}
            className="absolute inset-0 opacity-0 cursor-pointer"
            type="file"
            accept="video/*"
            onChange={onVideoChange}
          />
        </div>
      </div>
      {value && (
        <IconButton
          name="X"
          variant="tertiary"
          onClick={() => {
            uploadIconInput.current.value = '';
            onChange(null);
          }}
          disabled={disabled}
          className="absolute -top-2 -right-2"
        />
      )}
    </div>
  );
}
