import { useLocation, useResolvedPath } from 'react-router-dom';

export default function usePathActive(props) {
  const { to, caseSensitive, end } = props;
  const location = useLocation();
  const path = useResolvedPath(to || '');

  let locationPathname = location.pathname;
  let toPathname = path.pathname;
  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase();
    toPathname = toPathname.toLowerCase();
  }

  const active =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/');

  return active;
}
