import { z } from 'zod';

export const DIAGNOSTICS_VIEW_KEY = 'AdminViewOutput';

export default {
  key: DIAGNOSTICS_VIEW_KEY,
  label: 'Diagnostics View',
  icon: 'TerminalWindow',
  description: 'Outputs raw data to a Web UI accessible via the portal.',
  type: 'outputs',
  inputs: [],
  outputs: [],
  defaultConfig: {
    updateFrequency: 0.2,
    compressionLevel: 75,
  },
  schema: z.object({
    config: z
      .object({
        updateFrequency: z.number().min(0),
        compressionLevel: z.number().min(0),
      })
      .optional(),
  }),
};
