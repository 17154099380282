import { truncate, snakeCase, isBoolean } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Card, Button, Icon, Text, Heading } from '@optra/kit';

import DataSinkActionDataFields from 'components/data-sink-action-data-fields';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import Select from 'components/select';
import SkillIcon from 'components/skill-icon';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useWorkflowAction } from 'queries';

export default function DataSinkActionForm(props) {
  const { onClose } = props;
  const { workflowActionId } = useParams();
  const Form = useForm({
    defaultValues: {
      configuration: {
        shared: {
          tableName: '',
          newTableName: '',
        },
      },
    },
  });
  const selectedTableName = Form.watch('configuration.shared.tableName');

  const [error, setError] = useState();
  const defaults = useWorkflowAction(workflowActionId, {
    refetchInterval: 60000,
  });

  useOnSuccess(
    () => {
      const workflowAction = defaults.data?.workflowAction;
      const configuration = {
        ...workflowAction.configuration,
      };

      workflowAction?.workflow?.skills?.data?.forEach(workflowSkill => {
        configuration[workflowSkill?.id] = {
          payload: {},
        };

        workflowSkill?.outputs
          ?.filter(({ output }) => !output.default)
          .forEach(output => {
            const existingConfig =
              workflowAction?.configuration?.[workflowSkill.id]?.payload?.[output.output.id];
            configuration[workflowSkill.id]['payload'][output.output.id] = {
              id: output.output.id,
              as: existingConfig?.as || snakeCase(output.output.id),
              enabled: isBoolean(existingConfig?.enabled) ? existingConfig?.enabled : true,
            };
          });
      });

      Form.reset({
        configuration,
      });
    },
    { isSuccess: defaults.isSuccess },
    [Form, defaults.data],
  );

  const qc = q.useQueryClient();
  const upsertTable = q.useMutation({
    mutationFn: form =>
      api(
        `mutation upsertDataSinkTable ($form: runWorkflowActionMutationForm!) {
          workflowAction: runWorkflowActionMutation(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        {
          form: {
            id: workflowActionId,
            fn: 'upsertTable',
            props: form,
          },
        },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
    },
  });

  const removeTable = q.useMutation({
    mutationFn: form =>
      api(
        `mutation removeDataSinkTable ($form: runWorkflowActionMutationForm!) {
          workflowAction: runWorkflowActionMutation(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        {
          form: {
            id: workflowActionId,
            fn: 'removeTable',
            props: form,
          },
        },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
    },
  });

  const configuration = defaults.data?.workflowAction?.configuration;

  const handleSubmit = Form.handleSubmit(form => {
    setError(null);

    return upsertTable.mutateAsync(form, {
      onSuccess() {
        onClose();
      },
      onError(error) {
        setError(error);
      },
    });
  });

  const loading = defaults.error || defaults.isLoading;
  const workflowSkills = defaults?.data?.workflowAction?.workflow?.skills?.data || [];

  return (
    <>
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Save Action">
            {error.message}
          </Message>
        )}
        {defaults.error && (
          <Message variant="danger" title="Couldn't Load Action">
            {defaults.error.message}
          </Message>
        )}

        <div>
          <Text variant="label" size="xs" color="muted">
            Run a Query
          </Text>
          <Card variant="secondary" className="flex items-center justify-between">
            <div className="flex items-center">
              <Icon name="FileText" color="gradient" className="mr-2" />
              <Text>{configuration?.eagerQuery?.status?.state || 'READY'}</Text>
            </div>
            <Button to="query" size="xs" variant="tertiary">
              Query
            </Button>
          </Card>
        </div>

        <div>
          <Text variant="label" size="xs" color="muted">
            Source
          </Text>
          <Card variant="secondary" className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="configuration.shared.tableName">Table Name</Label>
              <div className="flex space-x-2">
                <Select {...Form.register('configuration.shared.tableName')} readOnly={loading}>
                  <option value="">Select a Table</option>
                  <option value="$$NEW">Create New</option>
                  {(configuration?.availableTableNames || []).map(name => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Select>
                <Button
                  size="xs"
                  loading={loading || removeTable.isPending}
                  onClick={e => {
                    e.preventDefault();
                    removeTable.mutate({ tableName: selectedTableName });
                  }}
                >
                  Remove
                </Button>
              </div>
              <ValidationError errors={Form.errors} name="configuration.shared.tableName" />

              <Input
                className={selectedTableName === '$$NEW' ? '' : 'hidden'}
                type="text"
                {...Form.register('configuration.shared.newTableName')}
                readOnly={loading}
              />
              <ValidationError errors={Form.errors} name="configuration.shared.newTableName" />
            </div>
          </Card>
        </div>

        <div>
          <Text variant="label" size="xs" color="muted">
            Table Columns (Metrics)
          </Text>
          {workflowSkills.map?.(workflowSkill => (
            <Card className="mb-2" variant="secondary" key={workflowSkill.id}>
              <div className="flex items-center">
                <SkillIcon
                  size="sm"
                  color={workflowSkill?.skill?.color}
                  icon={workflowSkill?.skill?.icon}
                  iconUrl={workflowSkill?.skill?.iconUrl}
                  className="mr-3"
                />
                <Heading level={4}>
                  {truncate(workflowSkill?.name, {
                    length: 32,
                  })}
                </Heading>
              </div>

              {(workflowSkill.outputs || [])
                .filter(({ output }) => !output.default)
                .map(({ output }) => (
                  <DataSinkActionDataFields
                    key={output.id}
                    Form={Form}
                    output={output}
                    loading={loading}
                    workflowSkill={workflowSkill}
                  />
                ))}
            </Card>
          ))}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button size="xl" loading={loading || Form.formState.isSubmitting} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
