import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { generate } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'EdgeImpulseClassificationInferenceEngine',
  label: 'AI Classification',
  icon: 'TreeStructure',
  description:
    'Performs inference on an input image using a classification AI model from Edge Impulse.',
  type: 'inference',
  inputs: [
    {
      label: 'Source Image',
      key: 'image',
      type: DATA_TYPES['Image'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'cropped_image',
      label: 'Cropped Image',
      type: DATA_TYPES['Image'],
    },
    {
      key: 'scores',
      label: 'Scores',
      type: DATA_TYPES['DecimalList'],
      labelBehavior: generate(node => node?.meta?.labels || []),
    },
  ],
  defaultConfig: {
    model: 'file:///app/skill/config/model.eim',
  },
  schema: z.object({
    config: z.object({ model: z.string().url() }),
    inputs: z.object({ image: z.string().min(1) }),
    meta: z.object({ modelId: z.string().min(1) }), // require the user chooses a model
  }),
};
