export default function ExternalRedirect(props) {
  const { replace, to } = props;

  if (replace) {
    window.location.replace(to);
  } else {
    window.location.href = to;
  }

  return null;
}
