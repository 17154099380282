import cx from 'classnames';
import { Routes, Route } from 'react-router-dom';
import { useTitle } from 'react-use';

import { ErrorBoundary, useBreakpoint } from '@optra/kit';

import Modal from 'components/modal';
import AddPipelineNodeDataProcessor from 'modals/skill-pipeline-builder/add-pipeline-node-data-processor';
import AddPipelineNodeImageProcessor from 'modals/skill-pipeline-builder/add-pipeline-node-image-processor';
import AddPipelineNodeInput from 'modals/skill-pipeline-builder/add-pipeline-node-input';
import AddPipelineNodeModel from 'modals/skill-pipeline-builder/add-pipeline-node-model';
import AddPipelineNodeOutput from 'modals/skill-pipeline-builder/add-pipeline-node-output';
import AddPipelineNodeProcessor from 'modals/skill-pipeline-builder/add-pipeline-node-processor';
import AddNodeMenu from 'modals/skill-pipeline-builder/components/add-node-menu';
import ConfigPreview from 'modals/skill-pipeline-builder/components/config-preview';
import DataTypeLegend from 'modals/skill-pipeline-builder/components/data-type-legend';
import PipelineCards from 'modals/skill-pipeline-builder/components/pipeline-cards';
import TitleBar from 'modals/skill-pipeline-builder/components/title-bar';
import ConfigureSkillVersion from 'modals/skill-pipeline-builder/configure-skill-version';
import { PipelineProvider } from 'modals/skill-pipeline-builder/context';
import EditPipelineNode from 'modals/skill-pipeline-builder/edit-pipeline-node';

function PiplelineLayout() {
  const { thresholds } = useBreakpoint();
  const isMobile = !thresholds.lg;

  const HEADER_HEIGHT = 90;
  const LEGEND_HEIGHT = 30;
  const FOOTER_HEIGHT = isMobile ? 0 : 300;

  return (
    <div className={cx(['absolute', 'inset-0', 'flex', 'flex-col', 'items-stretch'])}>
      <div
        style={{ height: `${HEADER_HEIGHT}px` }}
        className={cx([
          'flex',
          'flex-row',
          'justify-between',
          'items-center',
          'px-5',
          'lg:px-9',
          'shadow-gray-200/50',
          'dark:shadow-[#000]/40',
          'shadow-lg',
          'bg-white',
          'dark:bg-black-900',
        ])}
      >
        <TitleBar />
      </div>
      <div
        style={{ height: `calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)` }}
        className={cx(
          'flex',
          'flex-col',
          'space-y-8',
          'px-5',
          'lg:px-9',
          'py-5',
          'lg:py-9',
          'overflow-y-scroll',
          'bg-gradient-to-b',
          'from-gray-100',
          'via-white',
          'to-white',
          'dark:bg-gradient-to-b',
          'dark:from-black-900',
          'dark:to-[#000]/70',
        )}
      >
        <div
          className="lg:grid lg:grid-cols-4 gap-4"
          style={{ height: `calc(100% - ${LEGEND_HEIGHT}px)` }}
        >
          <PipelineCards />
        </div>

        <div style={{ height: `${LEGEND_HEIGHT}px` }}>
          <DataTypeLegend />
        </div>
      </div>
      <div
        style={{ height: `${FOOTER_HEIGHT}px` }}
        className={cx([
          'flex',
          'flex-col',
          'items-center',
          'pt-4',
          'px-4',
          'w-full',
          'bg-light-bg-primary',
          'dark:bg-dark-bg-primary',
        ])}
      >
        <AddNodeMenu />
      </div>
    </div>
  );
}

function PipelineRoutes() {
  return (
    <Routes>
      <Route
        path="nodes/add-input"
        element={
          <Modal>
            <AddPipelineNodeInput />
          </Modal>
        }
      />
      <Route
        path="nodes/add-model"
        element={
          <Modal>
            <AddPipelineNodeModel />
          </Modal>
        }
      />
      <Route
        path="nodes/add-processor"
        element={
          <Modal>
            <AddPipelineNodeProcessor />
          </Modal>
        }
      />
      <Route
        path="nodes/add-data-processor"
        element={
          <Modal>
            <AddPipelineNodeDataProcessor />
          </Modal>
        }
      />
      <Route
        path="nodes/add-image-processor"
        element={
          <Modal>
            <AddPipelineNodeImageProcessor />
          </Modal>
        }
      />
      <Route
        path="nodes/add-output"
        element={
          <Modal>
            <AddPipelineNodeOutput />
          </Modal>
        }
      />
      <Route
        path="nodes/:nodeId"
        element={
          <Modal>
            <EditPipelineNode />
          </Modal>
        }
      />
      <Route
        path="config"
        element={
          <Modal>
            <ConfigureSkillVersion />
          </Modal>
        }
      />
    </Routes>
  );
}

export default function PipelineBuilder({ pipelineId }) {
  useTitle('Skill Builder Pipeline | Optra');
  return (
    <ErrorBoundary>
      <PipelineProvider pipelineId={pipelineId}>
        <PiplelineLayout />
        <PipelineRoutes />
        <ConfigPreview />
      </PipelineProvider>
    </ErrorBoundary>
  );
}
