import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { passthrough } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'MajorityLabelProcessor',
  label: 'Majority',
  icon: 'ChartPie',
  description: 'Find the most common label from a set of labels and their respective counts.',
  type: 'processors',
  inputs: [
    {
      label: 'Label Counts',
      key: 'label_counts',
      type: DATA_TYPES['NumberList'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'label',
      label: 'Label',
      type: DATA_TYPES['Text'],
      labelBehavior: passthrough(),
    },
  ],
  schema: z.object({
    inputs: z.object({ label_counts: z.string().min(1) }),
  }),
};
