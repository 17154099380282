import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useKey } from 'react-use';

import {
  NavList,
  FilterContainer,
  Filter,
  Dropdown,
  SearchField,
  DROPDOWN_MENU_PLACEMENT,
  UiState,
} from '@optra/kit';

import AddWorkspaceButton from 'components/charts/add-workspace-button';
import ChartWorkspaceStarButton from 'components/charts/chart-workspace-star-button';
import IntersectionLoadMore from 'components/intersection-load-more';
import { useWorkspaces } from 'queries';

export default function ChartsSidebar(props) {
  const { close } = props;
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const searchInputRef = useRef(null);
  useKey('/', e => {
    if (
      searchInputRef.current !== document.activeElement &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      searchInputRef.current.focus();
      e.preventDefault();
    }
  });

  const sortOptions = {
    createdAt_desc: t('Newest'),
    createdAt_asc: t('Oldest'),
    name_asc: t('Name (A-Z)'),
    name_desc: t('Name (Z-A)'),
  };
  const [sort, setSort] = useState('createdAt_desc');

  const filterOptions = {
    all: t('All'),
    starred: t('Starred'),
    private: t('Private'),
  };
  const [filter, setFilter] = useState('all');
  const memoizedFilter = useMemo(
    () => ({
      ...(filter === 'starred' ? { starred: true } : {}),
      ...(filter === 'private' ? { allowedAccess: true } : {}),
      ...(!isEmpty(search) ? { $search: search } : {}),
    }),
    [filter, search],
  );

  const memoizedSort = useMemo(() => {
    const sortOptions = sort.split('_');
    return {
      by: sortOptions?.at(0),
      direction: sortOptions?.at(1),
    };
  }, [sort]);

  const Workspaces = useWorkspaces({
    list: {
      filter: memoizedFilter,
      sort: memoizedSort,
    },
  });

  return (
    <div className="w-56">
      <div className="animate-fade-in space-y-6">
        <div className="relative">
          <FilterContainer className="justify-between h-11">
            <Filter
              variant="plain"
              menu={{
                text: filterOptions[filter],
                body: Object.keys(filterOptions).map(option => (
                  <Dropdown.Item
                    key={option}
                    text={filterOptions[option]}
                    active={option === filter}
                    onClick={() => setFilter(option)}
                  />
                )),
              }}
              placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
            />
            <Filter
              variant="plain"
              menu={{
                text: sortOptions[sort],
                body: Object.keys(sortOptions).map(option => (
                  <Dropdown.Item
                    key={option}
                    text={sortOptions[option]}
                    active={option === sort}
                    onClick={() => {
                      setSort(option);
                    }}
                  />
                )),
              }}
              placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
            />
            <div className="w-11" />
          </FilterContainer>
          <div
            className={cx(
              'absolute top-0 right-0 h-[44px] flex items-center transition-all',
              !searchFocused ? 'w-11' : 'w-full',
            )}
          >
            <SearchField
              searching={Workspaces.isRefetching && !Workspaces.isFetchedAfterMount}
              value={search}
              onChange={v => {
                setSearch(v);
                if (isEmpty(v)) setSearchFocused(false);
              }}
              onFocus={() => setSearchFocused(true)}
              onBlur={() => {
                if (isEmpty(search)) {
                  setSearchFocused(false);
                }
              }}
              debounce={500}
              placeholder={t('Search Workspaces…')}
              ref={searchInputRef}
            />
          </div>
        </div>

        <NavList>
          {Workspaces.isPending && (
            <div className="h-20">
              <UiState center />
            </div>
          )}
          {!Workspaces.isPending &&
            Workspaces.data?.map?.(({ name, id, starred }) => (
              <NavList.Item key={id} to={id} onClick={close}>
                <div className="flex justify-between items-center pr-2">
                  {name}
                  <ChartWorkspaceStarButton id={id} size="xs" value={starred} controlled />
                </div>
              </NavList.Item>
            ))}
        </NavList>
        <IntersectionLoadMore
          onVisible={Workspaces.fetchNextPage}
          disabled={Workspaces.isFetchingNextPage || !Workspaces.hasNextPage}
        />
        <AddWorkspaceButton />
      </div>
    </div>
  );
}
