import { Icon, Text } from '@optra/kit';

import ListItem from 'components/list-item';
import dateLabel from 'lib/date-label';
import { creditsToFormattedString } from 'lib/format-credits';

export default function BillingTransactionListItem({
  id,
  amount,
  description,
  createdAt,
  recurringInterval,
}) {
  let iconName = 'Coins';
  if (recurringInterval) {
    iconName = 'ArrowsClockwise';
  }

  const iconColor = amount > 0 ? 'green' : 'red';

  return (
    <ListItem
      // to={`/profile/transactions/${id}`}
      renderLeft={() => <Icon name={iconName} color={iconColor} />}
      // renderRight={() => <Icon name="CaretRight" weight="line" />}
    >
      <div className="flex flex-row justify-between">
        <Text variant="bold">
          {amount > 0 ? '+' : ''}
          {creditsToFormattedString(amount)} Credits
        </Text>
        <Text color="muted" size="xs">
          {description}
        </Text>
        <Text color="muted" size="xs">
          {dateLabel(createdAt)}
        </Text>
      </div>
    </ListItem>
  );
}
