import { useNavigate } from 'react-router-dom';

import { Card, Button, Icon, Heading, Text } from '@optra/kit';

import BillingAddressCard, { isValid } from 'components/billing-address-card';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ProfilePaymentMethods from 'components/profile-payment-methods';
import { api, q } from 'config/api';
import useRouteSearchParams from 'hooks/use-route-search-params';
import { creditsToFormattedString, creditsToFormattedCurrency } from 'lib/format-credits';
import { useBillingAddress } from 'queries';

// TODO: Render taxes
export default function AddCreditsPayment() {
  const { returnTo, numCredits = 1000 } = useRouteSearchParams();
  const { data, isLoading } = useBillingAddress();
  const navigate = useNavigate();

  const qc = q.useQueryClient();
  const Checkout = q.useMutation({
    mutationFn: () =>
      api(
        `mutation purchaseCredit($form: purchaseCreditForm!) {
          billingAccount: purchaseCredit(form: $form) {
            id
          }
        }`,
        { form: { quantity: Number(numCredits) } },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['currentBillingAccount'] });
    },
  });

  return (
    <ModalInner
      as="form"
      onSubmit={e => {
        e.preventDefault();
        Checkout.mutate(
          {},
          {
            onSuccess() {
              if (returnTo) {
                navigate(returnTo);
              } else {
                navigate('/profile');
              }
            },
          },
        );
      }}
    >
      <ModalTitle title="Add Credits" icon="PlusCircle" showBackButton />
      <ModalBody className="space-y-6">
        <Card variant="secondary" className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-3">
            <Icon name="Coins" color="gradient" size="xl" />
            <Heading level={2}>{`${creditsToFormattedString(numCredits)} Credits`}</Heading>
          </div>
          <Text size="xl">{creditsToFormattedCurrency(numCredits)}</Text>
        </Card>
        {/* TODO: Make this selectable! */}
        <ProfilePaymentMethods />
        <BillingAddressCard billingAddress={data?.currentBillingAddress} isLoading={isLoading} />
      </ModalBody>
      <ModalFooter>
        <Button
          loading={Checkout.isPending}
          disabled={!isValid(data?.currentBillingAddress)}
          type="submit"
        >
          Complete Purchase
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
