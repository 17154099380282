import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'ResultsIndicatorElement',
  label: 'Count Bubble',
  icon: 'NumberCircleOne',
  description:
    'Draw colorful circular indicators that grow and shrink with the number of times a given class label occurs on an input image.',
  type: 'graphic_elements',
  inputs: [
    {
      label: 'Source Image',
      key: 'image',
      type: DATA_TYPES['Image'],
      required: true,
    },
    {
      label: 'Label Counts',
      key: 'label_counts',
      type: DATA_TYPES['DecimalList'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
  defaultConfig: {
    colorMapping: {},
    yLocation: 120,
    indicatorSize: 100,
  },
  schema: z.object({
    config: z
      .object({
        colorMapping: z.record(z.string().min(1), z.array(z.number())),
        yLocation: z.number().min(0),
        indicatorSize: z.number().min(0),
      })
      .refine(value => {
        return Object.values(value?.colorMapping || {}).length > 0;
      }),
    inputs: z.object({
      image: z.string().min(1),
      label_counts: z.string().min(1),
    }),
  }),
};
