import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { Button, Card } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import LocationInput from 'components/location-input';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';

export default function CreateLocation() {
  const { handleBack } = useModalContext();

  const {
    handleSubmit: onSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      name: '',
      location: { lat: null, lng: null },
    },
  });
  const [error, setError] = useState();

  const qc = q.useQueryClient();
  const createLocation = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createLocation($form: createLocationForm!) {
          createLocation(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['locations'] });
      reset({
        name: '',
      });
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    createLocation.mutate(
      {
        name: form?.name,
        lat: form?.location?.lat,
        lng: form?.location?.lng,
      },
      {
        onSuccess: () => {
          handleBack();
        },
      },
    );
  });

  return (
    <>
      <ModalTitle title="New Location" icon="MapPin" showBackButton={true} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create Location">
            {error.message}
          </Message>
        )}

        <form onSubmit={handleSubmit}>
          <Card variant="secondary">
            <div className="flex flex-col space-y-2">
              <Label htmlFor="name">Location Name</Label>
              <Input
                type="text"
                placeholder="Location name"
                readOnly={createLocation.isPending}
                {...register('name', { required: 'Please enter a location name.' })}
              />
              <ValidationError errors={errors} name="name" />
            </div>
          </Card>
          <Card variant="secondary" className="mt-4">
            <Controller
              render={({ field }) => (
                <LocationInput detectLocation disabled={createLocation.isPending} {...field} />
              )}
              name="location"
              control={control}
            />
          </Card>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" onClick={handleSubmit} loading={createLocation.isPending} size="xl">
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
