import { isEmpty } from 'lodash';
import flatMap from 'lodash/flatMap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce, useTitle } from 'react-use';

import { Card, Button, Icon, SearchField, Table, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalTitle from 'components/modal-title';
import { useTags } from 'queries';

export default function ManageTagsOverview() {
  useTitle('Manage Tags | Optra');

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data, isLoading, isFetching, error, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useTags({
      list: {
        filter: {
          $search: debouncedSearch,
        },
      },
    });
  const tags = flatMap(data?.pages, page => page?.list?.data);
  const navigate = useNavigate();

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const tagList = () => (
    <>
      <Table>
        <Table.Body>
          {tags?.map(tag => (
            <Table.Row key={tag.id} caret hover onClick={() => navigate(`${tag.id}/edit`)}>
              <Table.TD>
                <Icon name="Tag" color="gradient" className="mr-2" />
                <Text>{tag.name}</Text>
              </Table.TD>
              <Table.TD>{tag.devices?.count || 0} Devices</Table.TD>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isFetchingNextPage || !hasNextPage}
      />
    </>
  );
  return (
    <>
      <ModalTitle title="Manage Tags" icon="Tag" loading={isLoading} />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Load Tag">
            {error.message}
          </Message>
        )}
        <Card variant="secondary" className="flex items-start space-x-2">
          <SearchField
            searching={!isLoading && isFetching}
            value={search}
            onChange={setSearch}
            onClear={() => setSearch('')}
            placeholder="Search Tags"
          />
          <Button type="button" icon="Plus" to="new">
            New
          </Button>
        </Card>
        {isEmpty(tags) ? <EmptyContentMessage icon="Tag" title="No Tags" /> : tagList()}
      </ModalBody>
    </>
  );
}
