import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import DeviceSubscription from 'components/device-subscription';
import { useModalContext } from 'components/modal';
import ModalTitle from 'components/modal-title';
import { useCurrentOrganization } from 'queries';

export default function DeviceDetailSubscription() {
  useTitle('Subscribe to Device | Optra');
  const { handleClose } = useModalContext();
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [organization] = useCurrentOrganization();

  useEffect(() => {
    if (organization?.pricing && organization.pricing !== 'credit') {
      navigate({
        pathname: `/devices/${deviceId}`,
        replace: true,
      });
    }
  }, [organization, navigate, deviceId]);

  return (
    <>
      <ModalTitle
        title="Subscribe to Device"
        icon="Aperture"
        handleBack={() => {
          navigate({
            pathname: `/devices/${deviceId}`,
          });
        }}
        showBackButton={true}
        handleClose={handleClose}
      />
      <DeviceSubscription
        deviceId={deviceId}
        onComplete={() => {
          navigate({
            pathname: `/devices/${deviceId}`,
          });
        }}
      />
    </>
  );
}
