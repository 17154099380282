import omit from 'lodash/omit';

import { StatusIndicator } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';

export default function DevicesFilterCpuUsage({ onFilter, filter }) {
  const selected = filter?.cpu?.usage;

  return (
    <>
      <DevicesFilterListItem
        image={<StatusIndicator status="enabled" size="lg" />}
        label="Low"
        selected={selected === 'LOW'}
        onClick={() => {
          if (selected === 'LOW') {
            onFilter(omit(filter, 'cpu'));
          } else {
            onFilter({ ...filter, cpu: { usage: 'LOW' } });
          }
        }}
      />
      <DevicesFilterListItem
        image={<StatusIndicator status="updating" size="lg" />}
        label="Moderate"
        selected={selected === 'MODERATE'}
        onClick={() => {
          if (selected === 'MODERATE') {
            onFilter(omit(filter, 'cpu'));
          } else {
            onFilter({
              ...filter,
              cpu: { usage: 'MODERATE' },
            });
          }
        }}
      />
      <DevicesFilterListItem
        image={<StatusIndicator status="disabled" size="lg" />}
        label="High"
        selected={selected === 'HIGH'}
        onClick={() => {
          if (selected === 'HIGH') {
            onFilter(omit(filter, 'cpu'));
          } else {
            onFilter({ ...filter, cpu: { usage: 'HIGH' } });
          }
        }}
      />
    </>
  );
}
