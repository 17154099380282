import { Cpu } from '@phosphor-icons/react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { Table, DetailList, VersionLabel, Text } from '@optra/kit';

import { useFeature } from 'components/feature';
import SkillStatus from 'components/skill-status';
import { useHasRoles } from 'hooks';
import pluralize from 'lib/pluralize';

export default function ModelListItem(props) {
  const {
    id,
    name,
    caret = true,
    disabled,
    onClick,
    owner,
    edgeImpulse,
    skill,
    latestVersion,
    ...rest
  } = props;
  const [canEdit] = useHasRoles(['admin']);
  const hasEdgeImpulse = edgeImpulse?.id;
  const deviceCount = edgeImpulse?.workflow?.devices?.count || 0;
  const skillStatusEnabled = useFeature('skillStatus');

  return (
    <Table.Row
      caret={canEdit && !disabled && caret}
      hover={canEdit}
      disabled={disabled}
      onClick={canEdit ? onClick : null}
      className={cx(!canEdit && 'pointer-events-none')}
      {...rest}
    >
      <Table.TD collapsed>
        <Cpu size={40} />
      </Table.TD>
      <Table.TD>
        <Text size="sm">{name}</Text>
        <DetailList
          details={[
            {
              title: 'Owner',
              component: owner.name,
            },
            latestVersion && {
              title: 'Version',
              component: <VersionLabel version={latestVersion} />,
            },
            hasEdgeImpulse && `${deviceCount} ${pluralize('Device', deviceCount)}`,
            hasEdgeImpulse &&
              !isEmpty(edgeImpulse?.workflow?.latestDeployment?.status) &&
              skillStatusEnabled && {
                title: 'Deployment Status',
                component: <SkillStatus status={edgeImpulse?.workflow?.latestDeployment?.status} />,
              },
          ]}
        />
      </Table.TD>
    </Table.Row>
  );
}
