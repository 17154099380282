import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import NotificationToast from 'components/notification-toast';
import { useUnseenNotifications } from 'queries';

export default function NotificationsCenter() {
  const [notifications] = useUnseenNotifications();

  const containerRef = useRef(null);

  // Scroll list to bottom to show new notifications when they come in.
  useEffect(() => {
    if (!containerRef?.current) return;
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [notifications]);

  const PORTAL_WRAPPER = document.getElementById('notifications-portal');

  return (
    <>
      {createPortal(
        <div
          aria-live="assertive"
          className="fixed bottom-0 right-0 max-h-[66%] flex flex-col z-priority"
        >
          <div
            className="relative inset-0 overflow-y-scroll flex flex-col p-3 space-y-3"
            style={{ marginTop: 'auto' }}
            ref={containerRef}
          >
            {notifications.map((notification, index) => (
              <NotificationToast
                key={notification.id}
                id={notification.id}
                notification={notification}
                className="sticky top-0"
              />
            ))}
          </div>
        </div>,
        PORTAL_WRAPPER,
      )}
    </>
  );
}
