import { Card, Heading, IconButton, Text } from '@optra/kit';

import Select from 'components/select';
import StepperInput from 'components/stepper-input';
import LabelChooser from 'modals/skill-pipeline-builder/components/label-chooser';
import useInputLabels from 'modals/skill-pipeline-builder/context/use-input-labels';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

const COLORS = {
  Red: [41, 52, 209],
  Yellow: [73, 204, 219],
  Green: [37, 196, 0],
  Blue: [188, 30, 80],
  Orange: [56, 113, 215],
  Purple: [210, 56, 161],
};

export default function ResultsIndicatorElement({ nodeId, config, onChange = () => false }) {
  const [node] = usePipelineNode(nodeId);
  const selectedLabels = Object.keys(config?.colorMapping);
  const labels = useInputLabels(node, 'label_counts');

  function handleChange(key, value) {
    onChange({ key, value });
  }

  function addColorItem() {
    onChange({
      key: 'colorMapping',
      value: {
        ...config.colorMapping,
        '': COLORS.Red,
      },
    });
  }

  function updateColorItem(index, label, color) {
    const currentMapping = structuredClone(config.colorMapping);
    let mappingEntries = Object.entries(currentMapping);
    mappingEntries[index] = [label, COLORS[color]];
    const updatedMapping = Object.fromEntries(mappingEntries);

    onChange({
      key: 'colorMapping',
      value: updatedMapping,
    });
  }

  function removeColorItem(label) {
    const mapping = structuredClone(config.colorMapping);
    delete mapping[label];

    onChange({
      key: 'colorMapping',
      value: mapping,
    });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Bubble Configuration</Heading>
      <Card variant="secondary">
        <div className="grid grid-cols-8 gap-2 items-center space-y-2">
          <div className="block col-span-2 text-right">
            <Text variant="label" size="xs">
              Bubble Size
            </Text>
          </div>
          <div className="block col-span-6">
            <StepperInput
              label="px"
              value={config?.indicatorSize}
              max={10000}
              onChange={v => handleChange('indicatorSize', parseInt(v))}
            />
          </div>

          <div className="block col-span-2 text-right">
            <Text variant="label" size="xs">
              Top Margin
            </Text>
          </div>
          <div className="block col-span-6">
            <StepperInput
              label="px"
              value={config?.yLocation}
              max={10000}
              onChange={v => handleChange('yLocation', parseInt(v))}
            />
          </div>
        </div>
      </Card>

      <Heading level={3}>Bubble Colors</Heading>
      <Card variant="secondary" className="space-y-2">
        {Object.entries(config.colorMapping).map(([label, color], idx) => {
          const colorKey = Object.keys(COLORS).find(k => COLORS[k].toString() === color.toString());
          return (
            <div className="flex flex-row align-center space-x-2" key={`colorMapping-${label}`}>
              <LabelChooser
                labels={labels}
                value={label}
                disabledLabels={selectedLabels}
                onChange={newLabel => {
                  updateColorItem(idx, newLabel, colorKey);
                }}
              />
              <Select
                value={colorKey}
                onChange={e => {
                  updateColorItem(idx, label, e.target.value);
                }}
              >
                {Object.entries(COLORS).map(([colorName, colorArray]) => (
                  <option value={colorName} key={colorName}>
                    {colorName}
                  </option>
                ))}
              </Select>
              <IconButton
                name="Minus"
                variant="tertiary"
                disabled={Object.entries(config.colorMapping).length === 1}
                onClick={() => removeColorItem(label)}
              />
              <IconButton
                icon="Plus"
                variant="tertiary"
                onClick={addColorItem}
                disabled={
                  selectedLabels.toString() === labels.toString() ||
                  Object.keys(config.colorMapping).length === labels.length
                }
              />
            </div>
          );
        })}
      </Card>
    </div>
  );
}
