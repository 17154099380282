import cx from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export default function DropdownSelect(props) {
  const { creatable = false, ...otherProps } = props;
  const controlStyles = {
    base: [
      'max-h-40',
      'overflow-y-scroll',
      'py-[3px]',
      '!rounded-md',
      '!border',
      'shadow-sm',
      'hover:cursor-pointer',
      // Light
      'border-opacity-50',
      'bg-light-bg-secondary',
      'border-light-fg-secondary',
      // Dark
      'dark:border-opacity-50',
      'dark:bg-dark-bg-primary',
      'dark:border-dark-fg-secondary',
    ],
    focused: 'border-primary ring-primary ring-1',
    nonFocused: 'dark:bg-dark-bg-primary dark:border-dark-fg-secondary',
  };
  const inputStyles = 'px-1 py-0.5 dark:text-white';
  const menuStyles = 'border-none dark:bg-black rounded-lg';
  const menuListStyles = 'p-1';
  const optionStyles = {
    base: ['hover:cursor-pointer px-3 rounded'],
    selected: ['bg-gradient-to-r', 'from-primary-500', 'to-primary-400', 'text-white'],
    notFocused: 'opacity-80 text-white',
    focused: ['!bg-light-bg-primary dark:!bg-dark-bg-primary', 'text-white'],
  };
  const multiValueLabelStyles = 'dark:text-white';
  const multiValueRemoveStyles =
    'hover:text-gray-300 dark:hover:text-white text-gray-500 rounded-md';
  const multiValueStyles =
    'dark:bg-dark-bg-secondary text-white rounded items-center py-0.5 pl-2 pr-1 gap-1.5';
  const singleValueStyles = 'dark:text-white';
  const noOptionsMessageStyles = 'dark:text-white dark:bg-dark rounded-sm';

  const Component = creatable ? CreatableSelect : Select;

  return (
    <Component
      {...otherProps}
      menuPortalTarget={document.body}
      styles={{
        control: (styles, state) => ({
          ...styles,
          boxShadow: '',
          borderColor: '',
          '&:hover': {
            borderColor: '',
          },
          borderWidth: '2px',
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        multiValueRemove: (baseStyles, state) => ({
          ...baseStyles,
          ':hover': { 'background-color': '', color: '' },
        }),
        singleValue: baseStyles => ({
          ...baseStyles,
          color: '',
        }),
      }}
      classNames={{
        control: ({ isFocused }) =>
          cx(controlStyles.base, isFocused ? controlStyles.focused : controlStyles.nonFocused),
        input: () => inputStyles,
        menu: () => menuStyles,
        menuList: () => menuListStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        noOptionsMessage: () => noOptionsMessageStyles,
        option: ({ isFocused, isSelected }) =>
          cx(
            optionStyles.base,
            isFocused ? optionStyles.focused : optionStyles.notFocused,
            isSelected && optionStyles.selected,
          ),
        singleValue: () => singleValueStyles,
      }}
    />
  );
}
