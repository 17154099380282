import cx from 'classnames';

import { Icon, Tooltip } from '@optra/kit';

import { useHasRoles } from 'hooks';
import actionIcon from 'lib/action-icon';

import WorkflowListItem from './workflow-list-item';

function WorkflowActionListItem({
  deleted = false,
  dirty = false,
  disabled: _disabled = false,
  actionDisabled = false,
  id,
  name,
  type,
  workflowId,
}) {
  const [canEdit] = useHasRoles(['admin', 'deviceTechnician', 'workflowDeployer']);
  const disabled = !!(_disabled || !canEdit);

  const to = `/workflows/${workflowId}/workflow-actions/${id}/edit`;

  let variant = 'default';
  let statusIcon = null;
  let tooltipLabel = null;

  if (dirty) {
    variant = 'dirty';
    statusIcon = <Icon name="ArrowsClockwise" size="xs" />;
    tooltipLabel = 'This action has un-synced changes.';
  }
  if (actionDisabled) {
    variant = 'default';
    statusIcon = <Icon name="Prohibit" size="xs" weight="line" />;
    tooltipLabel = 'This action is disabled.';
  }
  if (deleted) {
    variant = 'deleted';
    statusIcon = <Icon name="Trash" size="xs" />;
    tooltipLabel = 'This action is deleted pending sync.';
  }
  if (disabled) {
    variant = 'disabled';
    statusIcon = <Icon name="Prohibit" size="xs" weight="line" />;
    tooltipLabel = 'This action is disabled.';
  }

  return (
    <WorkflowListItem
      variant={variant}
      name={name}
      disabled={disabled}
      to={to}
      components={{
        before: (
          <div
            className={cx([
              'w-10 h-10',
              'rounded',
              'flex items-center justify-center',
              'bg-gradient-to-r from-primary-500 to-primary-400',
              'text-white',
            ])}
          >
            <Icon name={actionIcon(type)} size="md" />
          </div>
        ),
        after: statusIcon && (
          <Tooltip label={tooltipLabel}>
            <span className="relative">{statusIcon}</span>
          </Tooltip>
        ),
      }}
    />
  );
}

export default WorkflowActionListItem;
