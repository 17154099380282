import { isEmpty } from 'lodash';

import { Card, Heading } from '@optra/kit';

import DataTypeHeading from 'modals/skill-pipeline-builder/components/data-type-heading';
import InputChooser from 'modals/skill-pipeline-builder/components/input-chooser';
import { DIAGNOSTICS_VIEW_KEY } from 'modals/skill-pipeline-builder/config';

export default function InputsCard({ nodeId, node, nodeDef, onUpdatePipeline }) {
  if (isEmpty(node) || isEmpty(nodeDef) || nodeDef.key === DIAGNOSTICS_VIEW_KEY) {
    return <div />;
  }

  return (
    <div>
      {Object.keys(nodeDef?.inputs).length > 0 && (
        <div className="space-y-3">
          <Heading level={3}>Inputs</Heading>
          <Card
            variant="secondary"
            noPadding
            className="divide-y divide-light-fg-tertiary dark:divide-dark-fg-tertiary"
          >
            {Object.keys(nodeDef?.inputs).map((inputKey, index) => {
              const inputDef = nodeDef?.inputs[index];
              return (
                <div
                  key={`${inputDef?.key}-${index}`}
                  className="grid grid-cols-8 gap-2 items-center p-4"
                >
                  <div className="col-span-4">
                    <DataTypeHeading type={inputDef?.type} label={inputDef?.label} />
                  </div>
                  <div className="flex col-span-4 items-center justify-center space-x-2">
                    <InputChooser
                      nodeId={nodeId}
                      type={inputDef?.type}
                      value={node?.inputs?.[inputDef?.key] || ''}
                      onChange={value => {
                        onUpdatePipeline({
                          action: 'upsertNode',
                          payload: {
                            nodeKey: nodeDef.key,
                            nodeId,
                            key: `inputs.${inputDef?.key}`,
                            value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Card>
        </div>
      )}
    </div>
  );
}
