import { DetailHeading, Dropdown } from '@optra/kit';

import { CALCULATIONS } from 'modals/chart-details/data';

export default function MetricSelectorCalculations(props) {
  const { value, onClick } = props;
  return (
    <Dropdown.MenuBody divide={false} scrolling className="p-5">
      <DetailHeading className="px-3 mb-4">CALCULATION</DetailHeading>
      {Object.entries(CALCULATIONS).map(([type, { name }]) => {
        const active = type === value;
        return (
          <Dropdown.Item
            key={type}
            text={name}
            uppercase={false}
            className="rounded-md"
            onClick={() => onClick(type)}
            active={active}
          />
        );
      })}
    </Dropdown.MenuBody>
  );
}
