// generate - generates new labels from internal meta or config
export const generate = fn => (node, output, existingNodeLabels) => {
  return fn(node, output, existingNodeLabels);
};

// passthrough - passes labels through from parent(s)
export const passthrough = () => (node, output, existingNodeLabels) => {
  return existingNodeLabels;
};

// append - appends the 'string' to the labels from parent(s)
export const append = () => (node, output, existingNodeLabels) => {
  const appendedLabels = (output?.appendedLabels || '').split(',');
  return [...existingNodeLabels, ...appendedLabels];
};

// replace - overwrites the parent labels
export const replace = () => (node, output) => {
  const replacementLabels = (output?.replacementLabels || '').split(',');
  return replacementLabels;
};

export default {
  generate,
  passthrough,
  append,
  replace,
};
