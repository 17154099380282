import { uniq } from 'lodash';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Icon, Text, PageWrapper, PrimaryCTAButton } from '@optra/kit';

import AdminPurchaseOrderDetail from 'components/admin-purchase-order-detail';
import ExportPurchaseOrderGiftCardsButton from 'components/export-purchase-order-gift-cards-button';
import IntersectionLoadMore from 'components/intersection-load-more';
import { api, q } from 'config/api';
import { useGiftCardPurchaseOrder } from 'queries/use-gift-card-purchase-order';

export default function AdminResellerCodePurchaseOrderDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const purchaseOrder = useGiftCardPurchaseOrder(id);
  const po = purchaseOrder.data?.giftCardPurchaseOrder;
  const giftCards = po?.giftCards?.data;

  const [selectedGiftCards, setSelectGiftCards] = useState([]);

  const handleGiftCardSelect = giftCardId =>
    setSelectGiftCards(
      selectedGiftCards.includes(giftCardId)
        ? selectedGiftCards.filter(ids => ids !== giftCardId)
        : uniq([...selectedGiftCards, giftCardId]),
    );

  const qc = q.useQueryClient();
  const removePurchaseOrder = q.useMutation({
    mutationFn: form =>
      api(
        `mutation removeGiftCardPurchaseOrder($id: ID!) {
          removeGiftCardPurchaseOrder(id: $id) {
            id
          }
        }`,
        { id },
      ),
  });

  const revokeCodes = q.useMutation({
    mutationFn: form =>
      api(
        `mutation RevokeGiftCardPurchaseOrderCodes($form: revokeGiftCardCodesForm!) {
          revokeGiftCardPurchaseOrderCodes(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['giftCardPurchaseOrder', id] });
    },
  });

  const handleRemovePurchaseOrder = () => {
    removePurchaseOrder.mutate(
      {
        id,
      },
      {
        onSuccess() {
          navigate('/admin/usage-credits');
        },
      },
    );
  };

  const handleRevokeCodesSubmit = () => {
    revokeCodes.mutate(
      {
        giftCardPurchaseOrderId: id,
        giftCardIds: selectedGiftCards,
      },
      {
        onSuccess(result) {
          if (!result.revokeGiftCardPurchaseOrderCodes?.id) {
            navigate('/admin/usage-credits');
            return;
          }
          setSelectGiftCards([]);
        },
      },
    );
  };

  return (
    <PageWrapper
      title={`${po?.poNumber} | Admin`}
      heading={
        <div className="flex flex-row">
          <div onClick={() => navigate('/admin/usage-credits')}>
            <Icon name="CaretLeft" className="mt-1 mr-1 hover:cursor-pointer" size="lg" />
          </div>
          <div className="flex flex-col">
            <Text>{po?.poNumber}</Text>
            <Text size="sm" color="muted">
              {po?.vendor}
            </Text>
            <Text size="sm" color="muted">
              <span>Created by: </span>
              <span className="italic">{po?.createdBy?.name}</span>
            </Text>
          </div>
        </div>
      }
      inline
      loading={purchaseOrder.isLoading}
      error={purchaseOrder.error}
      components={{
        actions: (
          <>
            <PrimaryCTAButton
              to={`/admin/usage-credits/purchase-order/${id}/edit`}
              text="Edit"
              icon="Pencil"
            />
            {giftCards?.length && <ExportPurchaseOrderGiftCardsButton giftCardPurchaseOrder={po} />}
            <Button onClick={handleRemovePurchaseOrder} variant="tertiary" icon="Trash">
              Remove
            </Button>
            <Button
              onClick={handleRevokeCodesSubmit}
              variant="tertiary"
              icon="Prohibit"
              disabled={!selectedGiftCards?.length || revokeCodes?.isPending}
              loading={revokeCodes?.isPending}
            >
              Revoke Selected
            </Button>
          </>
        ),
      }}
    >
      <AdminPurchaseOrderDetail
        fetching={purchaseOrder.isLoading}
        error={purchaseOrder.error}
        purchaseOrder={po}
        giftCards={giftCards}
        selectedGiftCards={selectedGiftCards}
        onSelectGiftCard={handleGiftCardSelect}
      />
      <IntersectionLoadMore
        onVisible={purchaseOrder.fetchNextPage}
        disabled={purchaseOrder.isFetchingNextPage || !purchaseOrder.hasNextPage}
      />
    </PageWrapper>
  );
}
