import zipObject from 'lodash/zipObject';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Card, Button, Text } from '@optra/kit';

import Checkbox from 'components/checkbox';
import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { serviceAccountPrivileges } from 'config/constants';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';

function RemoveServiceAccountButton({ id, onDelete }) {
  const qc = q.useQueryClient();
  const removeServiceAccount = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeServiceAccount($id: ID!) {
          user: removeServiceAccount(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['superUsers'] });
      onDelete?.();
    },
  });

  function handleRemove() {
    if (window.confirm('Are you sure you want to remove this service account?')) {
      removeServiceAccount.mutate(id);
    }
  }

  return (
    <Button
      variant="secondary"
      size="xs"
      loading={removeServiceAccount.isPending}
      onClick={handleRemove}
      icon="Trash"
    >
      Remove
    </Button>
  );
}

export default function AdminEditServiceAccount() {
  useTitle('Edit Service Account | Optra');
  const { serviceAccountId } = useParams();
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      rotateSecret: false,
      privileges: zipObject(
        Object.keys(serviceAccountPrivileges),
        Object.keys(serviceAccountPrivileges).map(() => false),
      ),
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');

  const serviceAccount = q.useQuery({
    queryKey: ['serviceAccount', serviceAccountId],
    queryFn: () =>
      api(
        `query serviceAccount($id: ID!) {
          serviceAccount(id: $id) {
            id
            name
            privileges
          }
        }`,
        { id: serviceAccountId },
      ),
    enabled: !!serviceAccountId,
  });

  useOnSuccess(
    () => {
      const sa = serviceAccount.data.serviceAccount;
      reset({
        name: sa?.name,
        rotateSecret: false,
        privileges: zipObject(
          sa?.privileges,
          sa?.privileges?.map(() => true),
        ),
      });
    },
    { isSuccess: serviceAccount.isSuccess },
    [reset, serviceAccount?.data],
  );

  const qc = q.useQueryClient();
  const updateServiceAccount = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateServiceAccount($form: updateServiceAccountForm!) {
          serviceAccount: updateServiceAccount(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['serviceAccounts'] });
      handleClose();
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    updateServiceAccount.mutate({
      ...form,
      id: serviceAccountId,
      privileges: Object.entries(form.privileges)
        .filter(([, v]) => v)
        .map(([k]) => k),
    });
  });

  const loading =
    serviceAccount.error || serviceAccount.isLoading || updateServiceAccount.isPending;

  const itemNotFound = useItemNotFound({
    fetching: serviceAccount.isLoading,
    id: serviceAccount.data?.serviceAccount?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={serviceAccountId} type="Service Account" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Edit Service Account"
        icon="Robot"
        loading={loading}
        renderActions={() => (
          <RemoveServiceAccountButton id={serviceAccountId} onDelete={handleClose} />
        )}
      />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create Service Account">
            {error.message}
          </Message>
        )}

        <div className="space-y-6">
          <Card variant="secondary" className="space-y-5">
            <div className="space-y-2">
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                {...register('name', {
                  required: 'Please enter a name',
                })}
                readOnly={updateServiceAccount.isPending}
              />
              <ValidationError errors={errors} name="name" />
            </div>
            <Controller
              control={control}
              name="rotateSecret"
              render={({ field: { onChange, value, ref } }) => (
                <label className="flex items-center gap-3">
                  <span className="flex-0">
                    <Checkbox checked={!!value} ref={ref} onChange={onChange} />
                  </span>
                  <span className="flex-1">
                    <Label as="span">Rotate Secret</Label>
                    <Text color="muted" size="xs" className="normal-case font-normal">
                      Invalidates the current secret
                    </Text>
                  </span>
                </label>
              )}
            />
          </Card>

          <div className="space-y-2">
            <Label>Privileges</Label>
            <ul>
              {Object.entries(serviceAccountPrivileges).map(([privilege, description]) => {
                return (
                  <Controller
                    key={privilege}
                    control={control}
                    name={`privileges.${privilege}`}
                    render={({ field: { onChange, value, ref } }) => (
                      <FeatureToggle
                        as="li"
                        key={privilege}
                        title={privilege}
                        description={description}
                        checked={value}
                        ref={ref}
                        onChange={onChange}
                      />
                    )}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={updateServiceAccount.isPending}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
