import { flatMap, includes } from 'lodash';

import { Dropdown, Filter, UiState, Badge, Button } from '@optra/kit';

import { QUERY_OVERRIDES } from 'modals/chart-details/data';
import { useDevicesNames } from 'queries';

export default function ControlFilterDevices(props) {
  const { selectedDeviceIds, devicesQuery, onClear, onSelect, ...rest } = props;
  const { data, error, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useDevicesNames(
      {
        list: {
          sort: {
            by: 'name',
            direction: 'asc',
          },
        },
      },
      QUERY_OVERRIDES,
    );

  const devices = flatMap(data?.pages, page => page?.list?.data);

  return (
    <Filter
      label="Devices"
      loading={isLoading}
      onClear={onClear}
      menu={{
        text: !!(selectedDeviceIds && selectedDeviceIds.length) && (
          <Badge className="pl-2 pr-2">{selectedDeviceIds.length}</Badge>
        ),
        body: isLoading ? (
          <UiState margin={false} className="m-10" />
        ) : error ? (
          <UiState variant="error" margin={false} className="m-10" />
        ) : !devices?.length ? (
          <UiState variant="empty" text="No Devices" margin={false} className="m-10" />
        ) : (
          <>
            {devices.map(device => {
              const { name, id } = device;
              const active = selectedDeviceIds && includes(selectedDeviceIds, id);
              return (
                <Dropdown.Item
                  uppercase={false}
                  key={id}
                  text={name}
                  active={active}
                  onClick={e => {
                    onSelect(id);
                    e.preventDefault();
                  }}
                  selectable
                />
              );
            })}
            {hasNextPage && (
              <div className="flex justify-center py-3">
                <Button
                  variant="plain"
                  size="xs"
                  className="p-0 text-primary"
                  onClick={fetchNextPage}
                  loading={isFetchingNextPage}
                >
                  Load More
                </Button>
              </div>
            )}
          </>
        ),
      }}
      {...rest}
    />
  );
}
