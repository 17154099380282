import { Table, Text } from '@optra/kit';

import TaggedDeviceListItem from 'components/tagged-device-list-item';

export default function TaggedDeviceList({ devices, tagId }) {
  return (
    <>
      <Text>Tagged Devices</Text>
      <Table>
        <Table.Body>
          {devices?.map(device => (
            <TaggedDeviceListItem key={device?.id} device={device} tagId={tagId} />
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
