import cx from 'classnames';
import keys from 'lodash/keys';
import { CSVLink } from 'react-csv';

import {
  Dropdown,
  DROPDOWN_MENU_PLACEMENT,
  Button,
  Icon,
  Tooltip,
  Spinner,
  InteractiveWrap,
} from '@optra/kit';

import PinPageButton from 'components/pin-page-button';
import { useChartCtx, useChartInterface } from 'modals/chart-details/context';
import { TIMEFRAME_BINS } from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

function SaveButton({ className, ...rest }) {
  const {
    state: { saving, unsavedChanges },
    actions: { saveChart },
  } = useChartCtx();

  return (
    <Button
      loading={saving}
      size="sm"
      disabled={!unsavedChanges}
      className={cx('w-20', className)}
      {...rest}
      onClick={saveChart}
    >
      Save
    </Button>
  );
}

function SettingsDropdown() {
  const {
    actions: { removeChart },
  } = useChartCtx();
  const {
    actions: { setShowSettingsModal, setShowDuplicateModal },
  } = useChartInterface();
  return (
    <Dropdown
      components={{
        button: ({ open }) => (
          <Button icon="Gear" size="sm" variant="secondary" as="span">
            <Icon
              name={open ? 'CaretUp' : 'CaretDown'}
              weight="regular"
              size="xs"
              className="ml-1"
            />
          </Button>
        ),
        body: (
          <>
            <Dropdown.Item
              icon="PencilSimple"
              text="Settings"
              onClick={() => setShowSettingsModal(true)}
            />
            <Dropdown.Item
              icon="CopySimple"
              text="Duplicate"
              onClick={() => setShowDuplicateModal(true)}
            />
            <Dropdown.Item icon="TrashSimple" text="Delete" onClick={removeChart} />
          </>
        ),
      }}
      placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
    />
  );
}

function PanelControl({
  active,
  tooltip,
  label,
  icon,
  className,
  loading,
  components = {},
  ...rest
}) {
  return (
    <Tooltip label={`${active ? 'Hide' : ''} ${label}`}>
      <Button
        size="sm"
        variant="secondary"
        className={cx(
          'relative',
          'rounded-full h-9 w-9 py-0 px-0',
          'flex items-center justify-center',
          className,
        )}
        disabled={loading}
        {...rest}
      >
        {loading ? (
          <span>
            <Spinner color="gradient" size="sm" />
          </span>
        ) : (
          <span className={cx(active && 'text-primary')}>
            <Icon name={icon} weight="regular" size="sm" />
          </span>
        )}
        {!!components?.float && (
          <span className="absolute inline-block top-0 right-0 leading-none origin-top-right translate-x-1 -translate-y-1">
            {components?.float}
          </span>
        )}
      </Button>
    </Tooltip>
  );
}

function ExportButton() {
  const {
    state: { loading, parsedQueryResults },
    helpers: { getChart, getChartConfig },
  } = useChartCtx();
  const { data: rows } = parsedQueryResults;
  const chart = getChart();
  const { syncTimezones } = getChartConfig();

  const data = rows.map(_row => {
    const row = { ..._row };
    keys(row).forEach(key => {
      const value = row[key];
      row[key] = helpers.isTimeGroupDimension(key)
        ? helpers.formatTimeGroup(key, value, { useLocal: !syncTimezones })
        : helpers.isTimeDimension(key)
        ? helpers.formatTime(
            value,
            TIMEFRAME_BINS[chart?.config?.timeframeBin]?.format,
            syncTimezones,
          )
        : value;
    });
    return row;
  });

  return loading ? (
    <PanelControl label="Preparing..." loading={true} />
  ) : (
    <InteractiveWrap
      as={CSVLink}
      data={data}
      filename={`${chart?.name || 'Untitled'}.csv`}
      className="rounded-full"
    >
      <PanelControl icon="ArrowLineDown" label="Export" as="span" />
    </InteractiveWrap>
  );
}

export default function GlobalControls() {
  const {
    state: { isCustomQuery },
  } = useChartCtx();
  const {
    state: {
      isMobile,
      ui: { showSqlInput, showMobileControls, fullscreenChart, showDataTable },
    },
    actions: { setShowMobileControls, setShowSqlInput, setFullScreenChart, setShowDataTable },
  } = useChartInterface();

  return (
    <div className="flex items-center justify-between w-full flex-wrap space-x-2">
      <div className="flex items-center flex-nowrap space-x-2">
        <PinPageButton />
        {!isCustomQuery && (
          <>
            {isMobile ? (
              <PanelControl
                icon="FunnelSimple"
                label="Options"
                active={showMobileControls}
                onClick={() => setShowMobileControls(!showMobileControls)}
              />
            ) : (
              <PanelControl
                icon="Sidebar"
                label="Side Panel"
                active={!fullscreenChart}
                onClick={() => setFullScreenChart(!fullscreenChart)}
              />
            )}
          </>
        )}
        <PanelControl
          icon="Terminal"
          label="SQL Input"
          components={{
            float: isCustomQuery && (
              <Icon
                name="WarningCircle"
                weight="fill"
                color="gradient"
                className="w-4 h-4 rounded-full"
              />
            ),
          }}
          active={showSqlInput}
          onClick={() => setShowSqlInput(!showSqlInput)}
        />
        <PanelControl
          icon="Rows"
          label="Data Table"
          active={showDataTable}
          onClick={() => setShowDataTable(!showDataTable)}
        />
        <ExportButton />
      </div>
      <div className="flex items-center flex-nowrap space-x-2">
        <SettingsDropdown />
        <SaveButton />
      </div>
    </div>
  );
}
