import { Link } from 'react-router-dom';

import { DetailHeading, Text } from '@optra/kit';

import SkillIcon from 'components/skill-icon';
import { useSkillBuilderSkills } from 'queries';

export default function SkillBuilderSkills() {
  const skills = useSkillBuilderSkills();

  if (skills?.data?.list?.data?.length < 1) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-row items-start justify-between mb-6">
        <DetailHeading variant="decorated">Skill Builder</DetailHeading>
      </div>

      {/* TODO: Loading state */}
      <div className="divide-y divide-light-fg-tertiary dark:divide-dark-bg-secondary">
        {skills.data?.list?.data?.map?.(skill => (
          <Link
            to={`/skills/${skill?.id}/edit`}
            key={skill?.id}
            className="flex flex-row items-center py-3"
          >
            <SkillIcon
              size="sm"
              icon={skill?.icon || 'model'}
              iconUrl={skill?.iconUrl}
              color={skill?.color || '#0d9488'}
              className="mr-2"
              circle
            />
            <div>
              <Text className="block" size="sm">
                {skill?.name}
              </Text>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
