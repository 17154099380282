import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';

import { PageWrapper } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import FToggle from 'components/feature-toggle';
import PinPageButton from 'components/pin-page-button';
import { api, q, useOnSuccess } from 'config/api';
import { useGlobalFeatures } from 'queries';

function FeatureToggle({ feature = {} }) {
  const { enabled: _enabled, label, dbId } = feature || {};

  const [enabled, setEnabled] = useState(!!_enabled);

  const qc = q.useQueryClient();
  const updateFeatures = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateGlobalFeature($form: updateGlobalFeatureForm!) {
          feature: updateGlobalFeature(form: $form) {
            id
            enabled
          }
        }`,
        { form },
      ),
    onSuccess: data => setEnabled(data?.feature?.enabled),
    onSettled: () => qc.invalidateQueries({ queryKey: ['globalFeatures'] }),
  });

  const handleToggle = () => {
    if (updateFeatures.isPending) return;

    const action = enabled ? 'disable' : 'enable';

    if (
      window.confirm(
        `Are you sure you want to ${action} the ${label} feature across the entire app, for all users?`,
      )
    ) {
      updateFeatures.mutate({ id: dbId, enabled: !enabled });
    }
  };

  return (
    <FToggle
      className="animate-fade-in"
      variant="subtle"
      title={label}
      checked={!!enabled}
      onChange={handleToggle}
      loading={updateFeatures.isPending}
    />
  );
}

export default function AdminFeatures() {
  const { control, reset } = useForm({
    defaultValues: {
      features: [],
    },
  });

  const { fields: featureFields } = useFieldArray({
    control,
    name: 'features',
  });

  const { data, isLoading, isSuccess } = useGlobalFeatures();

  useOnSuccess(
    () => {
      const { features } = data;

      reset({
        features: features.data?.map?.(feature => ({
          dbId: feature?.id,
          enabled: feature?.enabled,
          label: feature?.label,
        })),
      });
    },
    {
      isSuccess,
    },
    [data, reset],
  );

  return (
    <PageWrapper
      icon="ListChecks"
      title="Features | Admin"
      heading="Features"
      inline
      loading={isLoading}
      components={{
        actions: <PinPageButton />,
      }}
    >
      {!isEmpty(featureFields) ? (
        featureFields.map(feature => {
          if (!feature) return null;
          return <FeatureToggle key={feature.dbId} feature={feature} />;
        })
      ) : (
        <EmptyContentMessage icon="ListChecks" title="No Features" />
      )}
    </PageWrapper>
  );
}
