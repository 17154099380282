import cx from 'classnames';

import Select from 'components/select';

export default function LabelChooser({ labels = [], value, onChange, disabledLabels = [] }) {
  function handleChange(event) {
    const label = event.target.value;
    onChange(label);
  }

  return (
    <Select
      value={value}
      onChange={handleChange}
      className={cx(['w-full', !value && ['border-red-500', 'text-red']])}
    >
      <option value="">Choose a label…</option>
      {labels.map(label => (
        <option key={label} value={label} disabled={disabledLabels.includes(label)}>
          {label}
        </option>
      ))}
    </Select>
  );
}
