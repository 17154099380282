import cx from 'classnames';
import { isFinite as _isFinite, isString, isNil } from 'lodash';

import { Card, Heading } from '@optra/kit';

import Label from 'components/label';
import Select from 'components/select';
import StepperInput from 'components/stepper-input';
import InputNumberLock from 'modals/skill-pipeline-builder/components/input-number-lock';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

function writeMeasurementValue(n) {
  if (!isString(n)) return null;
  if (n === 'auto') return -1;
  return parseInt(n, 10) / 100;
}
function readMeasurementValue(n) {
  if (!_isFinite(n)) return 'auto';
  if (n === -1) return 'auto';
  return `${Math.round(n * 100)}`;
}
function readPositionValue(n) {
  if (!isString(n) && !isNil(n)) return 'manual';
  if (isNil(n)) return 'center';
  return n;
}
function writePositionValue(n) {
  if (n === 'center') return null;
  if (n === 'manual') return [0.5, 0.5];
  return n;
}
function writeManualPositionValue(n) {
  return parseInt(n, 10) / 100;
}
function readManualPositionValue(n) {
  if (!_isFinite(n)) return n;
  return `${Math.round(n * 100)}`;
}
function getPreviewPosition(location) {
  if (isString(location)) {
    const [y, x] = location.split(' ');
    return {
      top: y === 'bottom' ? '100%' : '0%',
      left: x === 'right' ? '100%' : '0%',
    };
  }

  const [x, y] = location || [];

  return {
    top: `${Math.round((y ?? 0.5) * 100)}%`,
    left: `${Math.round((x ?? 0.5) * 100)}%`,
  };
}

export default function ImageLayerElement(props) {
  const { nodeId } = props || {};
  const [node, { update }] = usePipelineNode(nodeId);

  const current = {
    width: readMeasurementValue(node.config?.subImageShape?.[0]),
    height: readMeasurementValue(node.config?.subImageShape?.[1]),
    position: readPositionValue(node.config?.location),
    left: readManualPositionValue(node.config?.location?.[0]),
    top: readManualPositionValue(node.config?.location?.[1]),
  };

  return (
    <div className="space-y-3">
      <Heading level={3}>Config</Heading>
      <Card variant="secondary" className="space-y-6">
        <div className="grid grid-cols-12">
          {/* FORM */}
          <div className="col-span-6 mr-2">
            <div className="grid grid-cols-2 gap-1">
              <InputNumberLock
                label="Width"
                name="width"
                className="col-span-2"
                showUnitLabel={current.width !== 'auto'}
                locked={current.width === 'auto'}
                min={0}
                max={100}
                onClickLock={() => {
                  let value =
                    current.width === 'auto'
                      ? [
                          writeMeasurementValue('100'),
                          node.config?.subImageShape?.[1] ?? writeMeasurementValue('auto'),
                        ]
                      : [
                          writeMeasurementValue('auto'),
                          node.config?.subImageShape?.[1] ?? writeMeasurementValue('auto'),
                        ];
                  if (value[0] === -1 && value[1] === -1) {
                    value = null;
                  }

                  update({
                    key: 'config.subImageShape',
                    value,
                  });
                }}
                value={current.width ?? 'auto'}
                onChange={_value => {
                  const value = `${_value}`;
                  update({
                    key: 'config.subImageShape',
                    value: [
                      writeMeasurementValue(value),
                      node.config?.subImageShape?.[1] ?? writeMeasurementValue('auto'),
                    ],
                  });
                }}
              />

              <InputNumberLock
                label="Height"
                name="height"
                className="col-span-2"
                showUnitLabel={current.height !== 'auto'}
                locked={current.height === 'auto'}
                min={0}
                max={100}
                onClickLock={() => {
                  let value =
                    current.height === 'auto'
                      ? [
                          node.config?.subImageShape?.[0] ?? writeMeasurementValue('auto'),
                          writeMeasurementValue('100'),
                        ]
                      : [
                          node.config?.subImageShape?.[0] ?? writeMeasurementValue('auto'),
                          writeMeasurementValue('auto'),
                        ];
                  if (value[0] === -1 && value[1] === -1) {
                    value = null;
                  }

                  update({
                    key: 'config.subImageShape',
                    value,
                  });
                }}
                value={current.height ?? 'auto'}
                onChange={_value => {
                  const value = `${_value}`;
                  update({
                    key: 'config.subImageShape',
                    value: [
                      node.config?.subImageShape?.[0] ?? writeMeasurementValue('auto'),
                      writeMeasurementValue(value),
                    ],
                  });
                }}
              />

              <Label htmlFor="position" className="flex items-center justify-end">
                Position
              </Label>
              <Select
                name="position"
                value={current?.position ?? 'center'}
                onChange={e => {
                  const value = e.target.value;
                  update({
                    key: 'config.location',
                    value: writePositionValue(value),
                  });
                }}
              >
                <option value="center">Center</option>
                <option value="top left">Top Left</option>
                <option value="top right">Top Right</option>
                <option value="bottom left">Bottom Left</option>
                <option value="bottom right">Bottom Right</option>
                <option value="manual">Manual</option>
              </Select>

              {current?.position === 'manual' && (
                <>
                  <Label htmlFor="top" className="flex items-center justify-end">
                    Top
                  </Label>
                  <div className="relative">
                    <StepperInput
                      name="top"
                      value={current.top ?? '50'}
                      min={0}
                      max={100}
                      showUnitLabel
                      onChange={_value => {
                        const value = `${_value}`;
                        update({
                          key: 'config.location',
                          value: [
                            node.config?.location?.[0] ?? '50',
                            writeManualPositionValue(value),
                          ],
                        });
                      }}
                    />
                  </div>

                  <Label htmlFor="left" className="flex items-center justify-end">
                    Left
                  </Label>
                  <div className="relative">
                    <StepperInput
                      name="left"
                      value={current.left ?? '50'}
                      min={0}
                      max={100}
                      showUnitLabel
                      onChange={_value => {
                        const value = `${_value}`;
                        update({
                          key: 'config.location',
                          value: [
                            writeManualPositionValue(value),
                            node.config?.location?.[1] ?? '50',
                          ],
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* PREVIEW */}
          <div className="col-span-6">
            <div className="w-full h-36 relative border border-solid border-primary overflow-hidden">
              <div
                className={cx(
                  'absolute',
                  'border border-dashed border-primary bg-primary bg-opacity-10',
                  '-translate-y-1/2 -translate-x-1/2',
                )}
                style={{
                  height: current.height === 'auto' ? '100%' : `${current.height}%`,
                  width: current.width === 'auto' ? '100%' : `${current.width}%`,
                  ...getPreviewPosition(node.config?.location),
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
