import { flatMap } from 'lodash';

import { PageWrapper, PrimaryCTAButton } from '@optra/kit';

import AdminPurchaseOrdersList from 'components/admin-purchase-orders-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import { useGiftCardPurchaseOrders } from 'queries/use-gift-card-purchase-orders';

export default function ResellerCodes() {
  const giftCardPurchaseOrders = useGiftCardPurchaseOrders();
  const purchaseOrders = flatMap(giftCardPurchaseOrders.data?.pages, page => page?.list?.data);

  return (
    <PageWrapper
      icon="Key"
      title="Usage Credits | Admin"
      heading="Usage Credits"
      inline
      loading={giftCardPurchaseOrders.isLoading}
      error={giftCardPurchaseOrders.error}
      components={{
        actions: (
          <>
            <PinPageButton />
            <PrimaryCTAButton
              to="/admin/usage-credits/create-purchase-order"
              text="New Redemption Codes"
            />
          </>
        ),
      }}
    >
      <AdminPurchaseOrdersList
        fetching={giftCardPurchaseOrders.isLoading}
        error={giftCardPurchaseOrders.error}
        list={purchaseOrders}
      />
      <IntersectionLoadMore
        onVisible={purchaseOrders.fetchNextPage}
        disabled={purchaseOrders.isFetchingNextPage || !purchaseOrders.hasNextPage}
      />
    </PageWrapper>
  );
}
