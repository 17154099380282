import { api, q } from 'config/api';

export function useGiftCardLogs(giftCardId, variables) {
  return q.useInfiniteQuery({
    queryKey: ['giftCardLogs', giftCardId],
    queryFn({ pageParam }) {
      return api(
        `
					query giftCardLogs($giftCardId: ID!, $list: listFields) {
						list: giftCardLogs(giftCardId: $giftCardId, list: $list) {
							count
							cursor {
								after
							}
							data {
								event
								actor {
									... on Device {
										name
									}
									... on Organization {
										name
									}
									... on UserProfile {
										name
									}
								}
								amount
								createdAt
							}
						}
					}
				`,
        {
          ...variables,
          giftCardId,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage?.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 5000,
  });
}
