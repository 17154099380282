import omit from 'lodash/omit';

import { StatusIndicator } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';

export default function DevicesFilterConnectionStatus({ onFilter, filter }) {
  return (
    <>
      <DevicesFilterListItem
        image={<StatusIndicator status="enabled" size="lg" />}
        label="Enrolled"
        selected={filter.autoClaim === false}
        onClick={() => {
          if (filter.autoClaim === false) {
            onFilter(omit(filter, 'autoClaim'));
          } else {
            onFilter({ ...filter, autoClaim: false });
          }
        }}
      />
      <DevicesFilterListItem
        image={<StatusIndicator status="pending" size="lg" />}
        label="Pre-Enrolled"
        selected={filter.autoClaim}
        onClick={() => {
          if (filter.autoClaim) {
            onFilter(omit(filter, 'autoClaim'));
          } else {
            onFilter({ ...filter, autoClaim: true });
          }
        }}
      />
    </>
  );
}
