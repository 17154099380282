import cx from 'classnames';
import _isFinite from 'lodash/isFinite';
import { useNavigate } from 'react-router-dom';
import { PieChart, Pie } from 'recharts';

import { Button, DetailHeading, DetailList, CONFIG, ButtonWrap } from '@optra/kit';

import { useFeature } from 'components/feature';
import { useHasRoles } from 'hooks';
import useDeployWorkflow, { isInProgress } from 'queries/use-deploy-workflow';

export default function WorkflowDeployButton(props) {
  const { workflow, deleting } = props;
  const [canDeploy] = useHasRoles(['admin', 'workflowDeployer']);
  const runtimeStatusEnabled = useFeature('workflowDeploymentRuntimeStatus');
  const { deploy, cancel } = useDeployWorkflow();
  const navigate = useNavigate();

  const latestDeploymentStatus = workflow?.latestDeployment?.status;
  const syncInProgress = deploy.isPending || isInProgress(latestDeploymentStatus);

  const progressUnknown =
    ['queued', 'calculating'].includes(latestDeploymentStatus) || workflow.status?.step === 'clean';

  const rootRoute = `/workflows/${workflow?.id}`;
  const modalRoute = `${rootRoute}/deployments/latest`;
  const costRoute = `${rootRoute}/credits-confirmation`;

  const handleSync = () => {
    if (syncInProgress) return cancel.mutate(workflow.id);
    if (workflow?.hasLicenses) return navigate(costRoute);
    return deploy.mutate(workflow.id);
  };

  const pieChartSize = 24;
  const pieChartProps = {
    cx: 7,
    cy: 7,
    stroke: 'none',
    isAnimationActive: false,
    style: { outline: 'none' },
  };
  const innerPieChartProps = {
    ...pieChartProps,
    innerRadius: 4,
    outerRadius: 9,
  };
  const outerPieChartProps = {
    ...pieChartProps,
    innerRadius: 10,
    outerRadius: 12,
  };

  if (!runtimeStatusEnabled) {
    const progress = _isFinite(workflow?.latestDeployment?.progress)
      ? (workflow?.latestDeployment?.progress || 0) / 100
      : 0;
    const shouldOpenDeploymentModal =
      workflow?.latestDeployment && (syncInProgress || workflow?.synchronized);
    const deploymentHasErrors = workflow?.latestDeployment?.failedTasks > 0;
    return (
      <Button
        icon={!syncInProgress && 'ArrowsClockwise'}
        iconRight={syncInProgress && 'CaretRight'}
        iconWeight={syncInProgress && 'line'}
        size="xs"
        showProgress={syncInProgress}
        progress={progress}
        onClick={!shouldOpenDeploymentModal ? handleSync : null}
        to={shouldOpenDeploymentModal ? modalRoute : null}
        error={deploymentHasErrors && 'Errors in Deployment'}
        variant={workflow?.synchronized ? 'tertiary' : 'primary'}
        disabled={deploy.isPending}
      >
        {syncInProgress ? (deleting ? 'Deleting...' : 'Syncing...') : 'Sync'}
      </Button>
    );
  }

  return (
    <div className="inline-flex flex-row-reverse md:flex-row flex-nowrap items-center gap-2 cursor-default">
      <ButtonWrap to={workflow?.latestDeployment ? modalRoute : undefined}>
        <span className="flex flex-col items-start md:items-end">
          <DetailHeading
            variant={workflow.status.step === 'clean' ? 'muted' : 'loud'}
            className={cx(workflow.status.step === 'deploying' && 'animate-pulse')}
          >
            View Details
          </DetailHeading>
          <div className="hidden sm:block -mt-[3px]">
            <DetailList
              size="xs"
              details={[
                `${workflow.status.running} running`,
                `${workflow.status.failed} failed`,
                `${workflow.status.starting} starting`,
                `${workflow.status.unknown} unknown`,
              ]}
              simple
            />
          </div>
        </span>
      </ButtonWrap>
      <div style={{ width: pieChartSize, height: pieChartSize }}>
        <PieChart width={pieChartSize} height={pieChartSize}>
          <Pie
            dataKey="value"
            data={[
              {
                name: 'bg',
                value: 1,
                fill: CONFIG.COLORS.black[500],
              },
            ]}
            {...innerPieChartProps}
          />
          <Pie
            dataKey="value"
            data={[
              {
                name: 'starting',
                value: workflow.status.starting,
                fill: CONFIG.COLORS.yellow[400],
              },
              {
                name: 'running',
                value: workflow.status.running,
                fill: CONFIG.COLORS.primary[400],
              },
              {
                name: 'failed',
                value: workflow.status.failed,
                fill: CONFIG.COLORS.red[400],
              },
              {
                name: 'unknown',
                value: workflow.status.unknown,
                fill: CONFIG.COLORS.black[400],
              },
            ]}
            {...innerPieChartProps}
          />
          <Pie
            dataKey="value"
            data={[
              {
                name: 'bg',
                value: 1,
                fill: CONFIG.COLORS.black[500],
              },
            ]}
            {...outerPieChartProps}
          />
          {!progressUnknown && (
            <Pie
              dataKey="value"
              data={[
                {
                  name: 'successful',
                  value:
                    (workflow.latestDeployment?.completedTasks || 0) -
                    (workflow.latestDeployment?.failedTasks || 0),
                  fill: CONFIG.COLORS.primary[400],
                },
                {
                  name: 'failed',
                  value: workflow.latestDeployment?.failedTasks || 0,
                  fill: CONFIG.COLORS.red[400],
                },
                {
                  name: 'remaining',
                  value:
                    (workflow.latestDeployment?.totalTasks || 0) -
                    (workflow.latestDeployment?.completedTasks || 0),
                  fill: CONFIG.COLORS.black[400],
                },
              ]}
              {...outerPieChartProps}
            />
          )}
          {!!(syncInProgress && progressUnknown) && (
            <g>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={pieChartSize}
                height={pieChartSize}
                viewBox="0 0 38.7 38.7"
                stroke={CONFIG.COLORS.primary[400]}
              >
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)" strokeWidth={2.5}>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </g>
                </g>
              </svg>
            </g>
          )}
        </PieChart>
      </div>
      {canDeploy && (
        <Button
          size="xs"
          onClick={handleSync}
          variant={syncInProgress || workflow.synchronized ? 'tertiary' : 'primary'}
          disabled={deploy.isPending || cancel.isPending || workflow.deleted}
        >
          {syncInProgress && latestDeploymentStatus !== 'canceled'
            ? 'Cancel'
            : workflow.synchronized
            ? 'Resync'
            : 'Sync'}
        </Button>
      )}
    </div>
  );
}
