import PipelineCard from 'modals/skill-pipeline-builder/components/pipeline-card';
import { NODE_DEFS } from 'modals/skill-pipeline-builder/config';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';

export default function PipelineCards() {
  const { pipeline, sortPipeline } = usePipelineContext();
  const pipelineItems = Object.keys(pipeline.pipeline).map(id => ({
    id,
    ...pipeline.pipeline[id],
  }));
  const inputs = pipelineItems.filter(i => i.type === 'inputs');
  const models = pipelineItems.filter(i => i.type === 'inference');
  const processors = pipelineItems.filter(
    i => i.type === 'processors' || i.type === 'graphic_elements',
  );
  const outputs = pipelineItems.filter(i => i.type === 'outputs');
  const outputDefs = Object.values(NODE_DEFS).filter(v => v.type === 'outputs');

  return (
    <>
      <PipelineCard
        title="Inputs"
        subtitle="1. Configure cameras or other inputs."
        icon="Plug"
        addPath="./nodes/add-input"
        items={inputs}
        onSort={items => sortPipeline({ items, types: ['inputs'] })}
      />
      <PipelineCard
        title="Models"
        subtitle="2. Link your AI model(s)."
        icon="Cpu"
        addPath="./nodes/add-model"
        items={models}
        onSort={items => sortPipeline({ items, types: ['inference'] })}
      />
      <PipelineCard
        title="Processors"
        subtitle="3. Add nodes to process your data."
        icon="MagicWand"
        addPath="./nodes/add-processor"
        items={processors}
        onSort={items => sortPipeline({ items, types: ['processors', 'graphic_elements'] })}
      />
      <PipelineCard
        title="Outputs"
        subtitle="4. Configure your devices outputs."
        icon="MonitorPlay"
        addPath={outputs.length < outputDefs.length ? './nodes/add-output' : null}
        items={outputs}
        onSort={items => sortPipeline({ items, types: ['outputs'] })}
      />
    </>
  );
}
