import { trim, omit } from 'lodash';

import { Card, Heading, Text, Toggle } from '@optra/kit';

import Input from 'components/input';
import Select from 'components/select';
import LabelChooser from 'modals/skill-pipeline-builder/components/label-chooser';
import useInputLabels from 'modals/skill-pipeline-builder/context/use-input-labels';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

function ConfigField(props) {
  const {
    node,
    label = '',
    type = 'String',
    _key: key,
    value,
    defaultValue,
    required = false,
    min = 0,
    max = 1,
    step = 0.1,
    options = [],
    onChange = () => false,
    sourceInput,
  } = props;
  const labels = useInputLabels(node, sourceInput);

  switch (type) {
    case 'String':
      return (
        <>
          <label htmlFor={key}>{label}</label>
          <Input
            name={key}
            type="text"
            value={value || defaultValue}
            onChange={event => onChange(event.target.value)}
            required={required}
          />
        </>
      );
    case 'Integer':
      return (
        <>
          <label htmlFor={key}>{label}</label>
          <Input
            name={key}
            type="number"
            value={value ?? defaultValue}
            onChange={event => onChange(event.target.value)}
            required={required}
          />
        </>
      );
    case 'Float':
      return (
        <>
          <label htmlFor={key}>{label}</label>
          <Text size="xl" variant="bold" className="block">
            {value ?? defaultValue}
          </Text>
          <input
            name={key}
            type="range"
            step={step}
            min={min}
            max={max}
            value={value ?? defaultValue}
            onChange={event => onChange(parseFloat(parseFloat(event.target.value).toFixed(2)))}
            className="w-full"
            required={required}
          />
        </>
      );
    case 'Boolean':
      const checked = value ?? defaultValue;
      return (
        <Toggle
          name={key}
          label={label}
          checked={checked}
          onChange={() => onChange(!checked)}
          labelPlacement="right"
          required={required}
        />
      );
    case 'Multiple':
      return (
        <>
          <label htmlFor={key}>{label}</label>
          <Select
            name={key}
            value={value ?? trim(defaultValue)}
            onChange={event => onChange(event.target.value)}
            required={required}
          >
            <option>Choose an option…</option>
            {options
              .split(',')
              .map(trim)
              .map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
          </Select>
        </>
      );
    case 'Label':
      return (
        <>
          <label htmlFor={key}>{label}</label>
          <LabelChooser labels={labels} value={value} onChange={label => onChange(label)} />
        </>
      );
    default:
      return <div />;
  }
}

export default function BetaNode({ nodeId, config = {}, onChange = () => false }) {
  const [node, { nodeDef, update }] = usePipelineNode(nodeId);
  const configFields = nodeDef?.configFields || [];

  function handleChange(type, key, _value) {
    let value = _value;
    if (type === 'Integer') {
      value = parseInt(_value, 10);
    }
    if (type === 'Float') {
      value = parseFloat(parseFloat(_value).toFixed(2));
    }
    update({
      key: `config.${key}`,
      value,
    });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Config</Heading>
      <Card variant="secondary" className="space-y-6">
        {configFields.map(configField => {
          const setValue = node.config?.[configField.key];
          const defaultValue =
            configField?.defaultValue ?? node?.meta?.defaultConfig?.[configField.key];
          const value = setValue ?? defaultValue;
          return (
            <div key={configField.key}>
              <ConfigField
                {...omit(configField, 'key')}
                _key={configField.key}
                node={node}
                value={value}
                onChange={value => handleChange(configField.type, configField.key, value)}
              />
            </div>
          );
        })}
      </Card>
    </div>
  );
}
