import flatMap from 'lodash/flatMap';

import DeviceGroupListItem from 'components/device-group-list-item';
import IntersectionLoadMore from 'components/intersection-load-more';
import WorkflowCard from 'components/workflow-card';
import WorkflowDeviceListItem from 'components/workflow-device-list-item';
import { useHasRoles } from 'hooks';
import { useWorkflowsDevices } from 'queries';

export default function WorkflowDevices({ workflowId, shouldFetch, disabled, expanded = true }) {
  const [canEdit] = useHasRoles(['admin', 'workflowEditor', 'workflowDeployer']);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useWorkflowsDevices(
    workflowId,
    {
      list: {
        limit: 12,
      },
    },
    { enabled: !!workflowId && shouldFetch },
  );

  const deviceCount = data?.pages?.[0]?.workflow?.devices?.count || 0;
  const nodes = flatMap(data?.pages, page => page?.workflow?.list?.data);

  return (
    <WorkflowCard
      icon="Aperture"
      heading="Devices"
      plusUrl={`/workflows/${workflowId}/choose-devices`}
      count={deviceCount}
      allowEmptyState={!nodes?.length}
      loading={isLoading}
      disabled={!canEdit || disabled}
      expanded={expanded}
    >
      {nodes
        ?.filter(node => !!node)
        ?.map?.(node => {
          if (node.__typename === 'WorkflowDeviceGroup') {
            return (
              <DeviceGroupListItem
                key={node.id}
                id={node.id}
                workflowId={workflowId}
                name={node.name}
                count={node.members?.count}
                dirty={!node.synchronized}
                hasPending={node.autoClaim}
                hasIncompatibleDevices={node.hasIncompatibleDevices}
                disabled={disabled}
              />
            );
          }

          return (
            <WorkflowDeviceListItem
              key={node.device?.id}
              id={node.device?.id}
              workflowId={workflowId}
              name={node.device?.name}
              status={node.device?.autoClaim ? 'pending' : node.device?.status}
              model={node.device?.model}
              dirty={!node.synchronized && !node.isSynchronizing}
              deleted={node.deleted}
              disabled={disabled}
              incompatibleSkills={node.incompatibleSkills}
            />
          );
        })}

      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isLoading || isFetchingNextPage || !hasNextPage}
      />
    </WorkflowCard>
  );
}
