import { find } from 'lodash';
import { useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useTitle } from 'react-use';

import { Button, DROPDOWN_MENU_PLACEMENT } from '@optra/kit';

import { useFeature } from 'components/feature';
import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import OrganizationSelect from 'components/organization-select';
import Select from 'components/select';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus } from 'hooks';

export default function AdminCreateOrganization() {
  useTitle('Create Organization | Optra');
  const { handleClose } = useModalContext();
  const resellerCodesEnabled = useFeature('resellerCodes', 'global');

  const {
    handleSubmit: onSubmit,
    control,
    register,
    formState: { errors },
    setFocus,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      parentId: null,
      pricing: 'custom',
      features: [],
    },
  });
  const [error, setError] = useState();
  const DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME = 'deviceSkillSyncLimit';
  const SKILL_BUILDER_FEATURE_NAME = 'skillBuilder';
  const DEVICE_SYNC_LIMIT_FEATURE_NAME = 'deviceSyncLimit';
  const watchFeatures = watch('features');
  const watchDeviceSkillSyncLimit = find(watchFeatures, {
    name: DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME,
  });
  const watchDeviceSyncLimit = find(watchFeatures, { name: DEVICE_SYNC_LIMIT_FEATURE_NAME });
  const watchSkillBuilder = find(watchFeatures, { name: SKILL_BUILDER_FEATURE_NAME });
  const enterpriseSkillBuilderEnabled = useFeature('enterpriseSkillBuilder', 'global');
  useInputFocus(setFocus, 'name');

  const ownerFeatures = q.useQuery({
    queryKey: ['allOwnerFeatures'],
    queryFn: () =>
      api(
        `query allOwnerFeatures {
          features: allOwnerFeatures {
            id
            label
          }
        }`,
      ),
  });

  const { fields: featureFields } = useFieldArray({
    control,
    name: 'features',
  });

  useOnSuccess(
    () => {
      const features = ownerFeatures?.data?.features;
      reset({
        name: '',
        parentId: '',
        pricing: 'custom',
        features: features?.map(ft => ({
          id: ft.id,
          enabled: true,
          label: ft.label,
          name: ft.id,
        })),
      });
    },
    { isSuccess: ownerFeatures.isSuccess },
    [ownerFeatures?.data?.features, reset],
  );

  const qc = q.useQueryClient();
  const createOrganization = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createOrganization($form: createOrganizationForm!) {
          organization: createOrganization(form: $form) {
            id
          }
        }`,
        {
          form: {
            ...form,
            as: 'sysAdmin',
          },
        },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['organizations'] });
      handleClose();
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);

    createOrganization.mutate({
      ...form,
      features: form.features.filter(feat => feat.enabled).map(feat => feat.name),
      entitlements: Object.entries(form.entitlements).map(([entitlement, value]) => ({
        entitlement,
        value: parseInt(value),
      })),
    });
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Create Organization"
        icon="Buildings"
        loading={createOrganization.isPending}
      />
      <ModalBody className="space-y-6">
        {error && (
          <Message variant="danger" title="Couldn't Create Organization">
            {error.message}
          </Message>
        )}

        <div className="flex flex-row items-center space-x-2">
          <Label htmlFor="parentOrganization">Parent</Label>
          <Controller
            name="parentId"
            control={control}
            disabled={createOrganization.isPending}
            render={({ field: { ref, ...field } }) => (
              <OrganizationSelect
                placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
                placeholderText="No Parent"
                allOptionText="No Parent"
                allOptionValue={null}
                allOptionIcon="LinkBreak"
                filters={{
                  features: { createChildOrganizations: true },
                }}
                {...field}
              />
            )}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="name">Organization Name</Label>
          <Input
            type="text"
            {...register('name', { required: 'Please enter a name.' })}
            readOnly={createOrganization.isPending}
          />
          <ValidationError errors={errors} name="name" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="pricing">Pricing</Label>
          <div className="flex items-center">
            <Select {...register('pricing')} readOnly={createOrganization.isPending}>
              <option value="custom">Custom</option>
              {resellerCodesEnabled && <option value="credit">Usage Credits</option>}
            </Select>
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="features">Enabled Features</Label>
          {featureFields.map((field, index) => {
            if (!field) return null;
            const isEnterpriseAllowanceEnabled =
              featureFields[index]?.name === SKILL_BUILDER_FEATURE_NAME &&
              watchSkillBuilder?.enabled &&
              enterpriseSkillBuilderEnabled;
            const isDeviceSkillSyncLimitEnabled =
              featureFields[index]?.name === DEVICE_SKILL_SYNC_LIMIT_FEATURE_NAME &&
              watchDeviceSkillSyncLimit?.enabled;
            const isDeviceSyncLimitEnabled =
              featureFields[index]?.name === DEVICE_SYNC_LIMIT_FEATURE_NAME &&
              watchDeviceSyncLimit?.enabled;
            return (
              <div key={field.id}>
                <FeatureToggle
                  title={field.label}
                  expanded={
                    isEnterpriseAllowanceEnabled ||
                    isDeviceSkillSyncLimitEnabled ||
                    isDeviceSyncLimitEnabled
                  }
                  {...register(`features.${index}.enabled`)}
                  defaultChecked={field.enabled}
                >
                  {isEnterpriseAllowanceEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.edgeImpulseEnterpriseAllowance">
                        Enterprise Allowance
                      </Label>
                      <Input
                        type="number"
                        {...register('entitlements.edgeImpulseEnterpriseAllowance')}
                        readOnly={createOrganization.isPending}
                      />
                      <ValidationError
                        errors={errors}
                        name="entitlements.edgeImpulseEnterpriseAllowance"
                      />
                    </div>
                  )}
                  {isDeviceSkillSyncLimitEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.deviceSkillSyncLimit">
                        Device Skill Sync Limit
                      </Label>
                      <Input
                        type="number"
                        {...register('entitlements.deviceSkillSyncLimit')}
                        readOnly={createOrganization.isPending}
                      />
                      <ValidationError errors={errors} name="entitlements.deviceSkillSyncLimit" />
                    </div>
                  )}
                  {isDeviceSyncLimitEnabled && (
                    <div className="space-y-2">
                      <Label htmlFor="entitlements.deviceSyncLimit">Device Sync Limit</Label>
                      <Input
                        type="number"
                        {...register('entitlements.deviceSyncLimit')}
                        readOnly={createOrganization.isPending}
                      />
                      <ValidationError errors={errors} name="entitlements.deviceSyncLimit" />
                    </div>
                  )}
                </FeatureToggle>
                <input type="hidden" {...register(`features.${index}.id`)} />
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={createOrganization.isPending}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
