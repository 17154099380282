import cx from 'classnames';
import { isEmpty, truncate, uniq } from 'lodash';
import { createRef, useLayoutEffect, useState } from 'react';

import { Badge, Button, Card, Icon, Modal, SubNavList, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import SkillIcon from 'components/skill-icon';
import skillIcons from 'config/skill-icons';
import DependencyIcon from 'modals/skill-pipeline-builder/components/dependency-icon';
import { DATA_TYPES, NODE_DEFS } from 'modals/skill-pipeline-builder/config';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import createBetaDef from 'modals/skill-pipeline-builder/context/create-beta-def';
import { useSkillBuilderAddMenu } from 'queries';

function RecipeDefCard({ idx, def, onClick }) {
  const includedDefs = Object.values(def?.config || {})
    .map(def => def?.name)
    .map(name => NODE_DEFS?.[name])
    .filter(d => !isEmpty(d));

  return (
    <DefCard
      id={idx}
      title={def?.name}
      description={def?.description}
      renderIcon={() => (
        <div className="flex flex-col items-center justify-center">
          <SkillIcon iconUrl={def.iconUrl} icon={def.icon} color={def.color} size="sm" />
          <div className="flex flex-row items-center space-x-2 pt-1">
            {includedDefs.map(def => (
              <Icon size="sm" name={def.icon} key={def.key} color="gray" weight="fill" />
            ))}
          </div>
        </div>
      )}
      onClick={onClick}
    />
  );
}

function BetaDefCard({ id, def, onClick }) {
  const { missingDataTypesForNode } = usePipelineContext();
  let errorDescription;
  let missingDataTypes = missingDataTypesForNode(def);
  if (missingDataTypes.length > 0) {
    const missingDataTypeLabels = missingDataTypes.map(d => DATA_TYPES[d].label);
    const multipleOfTypeRequired =
      missingDataTypeLabels.length > 1 &&
      uniq(missingDataTypeLabels).length <= missingDataTypeLabels.length;

    if (multipleOfTypeRequired) {
      errorDescription = `This node requires ${missingDataTypeLabels.length}x outputs of type ${missingDataTypeLabels[0]} to exist in the pipeline.`;
    } else {
      errorDescription = `This node requires outputs of type ${missingDataTypeLabels.join(
        ', ',
      )} to exist in the pipeline.`;
    }
  }

  return (
    <DefCard
      id={id}
      title={def?.label}
      description={def?.description}
      inputs={def?.inputs || []}
      outputs={def?.outputs || []}
      disabled={missingDataTypes.length > 0}
      errorDescription={errorDescription}
      renderIcon={() => <Icon name={def?.icon} color="primary" weight="duotone" size="xl" />}
      renderBadge={() => (
        <Badge variant="danger" size="xs">
          Beta
        </Badge>
      )}
      onClick={onClick}
    />
  );
}

function NodeDefCard({ idx, def, onClick }) {
  const { missingDataTypesForNode } = usePipelineContext();
  let errorDescription;
  let missingDataTypes = missingDataTypesForNode(def);
  if (missingDataTypes.length > 0) {
    const missingDataTypeLabels = missingDataTypes.map(d => DATA_TYPES[d].label);
    const multipleOfTypeRequired =
      missingDataTypeLabels.length > 1 &&
      uniq(missingDataTypeLabels).length <= missingDataTypeLabels.length;

    if (multipleOfTypeRequired) {
      errorDescription = `This node requires ${missingDataTypeLabels.length}x outputs of type ${missingDataTypeLabels[0]} to exist in the pipeline.`;
    } else {
      errorDescription = `This node requires outputs of type ${missingDataTypeLabels.join(
        ', ',
      )} to exist in the pipeline.`;
    }
  }

  return (
    <DefCard
      id={idx}
      title={def?.label}
      description={def?.description}
      inputs={def?.inputs || []}
      outputs={def?.outputs || []}
      disabled={missingDataTypes.length > 0}
      errorDescription={errorDescription}
      renderIcon={() => <Icon name={def?.icon} color="primary" weight="duotone" size="xl" />}
      onClick={onClick}
    />
  );
}

function DefCard({
  id,
  title,
  description,
  inputs = [],
  outputs = [],
  disabled = false,
  errorDescription,
  onClick = () => false,
  renderIcon = () => false,
  renderBadge = () => false,
}) {
  return (
    <Card
      key={`card-${id}`}
      noPadding
      className={cx(['w-[240px]', 'p-2', 'shrink-0', 'cursor-pointer', disabled && ['opacity-50']])}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <Card variant="secondary" className="relative flex flex-col justify-center items-center mb-2">
        {renderIcon()}

        <div className="absolute left-2 flex flex-col">
          {inputs.map(input => {
            if (Array.isArray(input.type)) {
              return input.type.map(type => (
                <DependencyIcon key={`${input.key}-${type.key}`} color={type.color} />
              ));
            } else {
              return <DependencyIcon key={input.key} color={input.type.color} />;
            }
          })}
        </div>
        <div className="absolute right-2 flex flex-col">
          {outputs.map(output => (
            <DependencyIcon key={`${output.key}-${output.type.key}`} color={output.type.color} />
          ))}
        </div>
      </Card>

      <div className="flex flex-row flex-wrap text-wrap space-x-2">
        <Text size="sm" className="font-medium block break-all">
          {title}
        </Text>
        {renderBadge()}
      </div>
      <Text size="xs" color="muted" className="block leading-tight">
        {!errorDescription && truncate(description, { length: 90 })}
        {!!errorDescription && <Text color="danger">{errorDescription}</Text>}
      </Text>
    </Card>
  );
}

function SelectedDefModal({
  isOpen,
  onClose = () => false,
  onSubmit = () => false,
  renderIcon = () => false,
  title,
  description,
  inputs = [],
  outputs = [],
  includedDefs = [],
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header heading={title} />
      <Modal.Body className="space-y-4">
        <Card variant="secondary" noPadding className="flex justify-center items-center py-12">
          {renderIcon()}
        </Card>
        <div>
          <Text>{description}</Text>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {inputs.length > 0 && (
            <div>
              <Text variant="label" size="xs" color="muted">
                Inputs
              </Text>
              <Card variant="secondary" size="sm">
                {inputs.map(input => {
                  if (Array.isArray(input.type)) {
                    return input.type.map(type => (
                      <div key={`${input.key}-${type.key}`}>
                        <DependencyIcon color={type.color} />
                        <Text variant="label" size="sm">
                          {type.label}
                        </Text>
                      </div>
                    ));
                  } else {
                    return (
                      <div key={input.key}>
                        <DependencyIcon color={input.type.color} />
                        <Text variant="label" size="sm">
                          {input.type.label}
                        </Text>
                      </div>
                    );
                  }
                })}
              </Card>
            </div>
          )}

          {outputs.length > 0 && (
            <div>
              <Text variant="label" size="xs" color="muted">
                Outputs
              </Text>
              <Card variant="secondary" size="sm">
                {outputs.map(output => (
                  <div key={`${output.key}-${output.type.key}`}>
                    <DependencyIcon color={output.type.color} />
                    <Text variant="label" size="sm">
                      {output.type.label}
                    </Text>
                  </div>
                ))}
              </Card>
            </div>
          )}

          {includedDefs.length > 0 && (
            <>
              <div className="col-span-2">
                <Text variant="label" size="xs" color="muted">
                  Included Nodes
                </Text>
              </div>
              {includedDefs.map(def => (
                <Card
                  variant="secondary"
                  size="sm"
                  key={def.key}
                  className="flex flex-row items-center space-x-2"
                >
                  <Icon size="sm" name={def.icon} key={def.key} color="gradient" />
                  <Text variant="label" size="sm">
                    {def.label}
                  </Text>
                </Card>
              ))}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-8 pb-4 flex items-center justify-center">
        <Button type="button" onClick={onSubmit} size="lg" icon="Plus">
          Add to Pipeline
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function AddNodeMenu() {
  const scrollerRef = createRef();
  const [tabIdx, setTabIdx] = useState(0);
  const [selectedDef, setSelectedDef] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { pipeline, addNode, updatePipeline, newId } = usePipelineContext();

  const { data, isLoading } = useSkillBuilderAddMenu();
  const recipes = data?.skillBuilderRecipes?.data || [];
  const betaNodes = data?.skillBuilderBetaNodes?.data || [];

  useLayoutEffect(() => {
    if (scrollerRef.current.clientWidth < scrollerRef.current.scrollWidth) {
      scrollerRef.current.style.justifyContent = 'start';
    } else {
      scrollerRef.current.style.justifyContent = 'center';
    }
  }, [scrollerRef, tabIdx]);

  const betaNodeDefs = (betaNodes || [])?.map(createBetaDef);

  const inputDefs = [
    ...Object.values(NODE_DEFS).filter(n => n.type === 'inputs'),
    ...betaNodeDefs.filter(n => n.type === 'inputs'),
  ];
  const modelDefs = [
    ...Object.values(NODE_DEFS).filter(n => n.type === 'inference'),
    ...betaNodeDefs.filter(n => n.type === 'inference'),
  ];
  const dataDefs = [
    ...Object.values(NODE_DEFS).filter(n => n.type === 'processors'),
    ...betaNodeDefs.filter(n => n.type === 'processors'),
  ];
  const imageDefs = [
    ...Object.values(NODE_DEFS).filter(n => n.type === 'graphic_elements'),
    ...betaNodeDefs.filter(n => n.type === 'graphic_elements'),
  ];
  const outputDefs = [
    ...Object.values(NODE_DEFS).filter(n => n.type === 'outputs'),
    ...betaNodeDefs.filter(n => n.type === 'outputs'),
  ];
  const tabs = ['Inputs', 'Models', 'Data Processors', 'Image Processors', 'Outputs'];
  if (recipes?.length > 0) {
    tabs.push('Recipes');
  }

  const allDefs = [inputDefs, modelDefs, dataDefs, imageDefs, outputDefs, recipes];
  const currentTabDefs = allDefs[tabIdx];

  function handleSelectDef(def) {
    setSelectedDef(def);
    setIsModalOpen(true);
  }

  function handleAddSelectedDef() {
    if (tabs[tabIdx] === 'Recipes') {
      const recipeWithNewIds = Object.values(selectedDef?.config).reduce((acc, c) => {
        acc[newId()] = c;
        return acc;
      }, {});
      updatePipeline({
        action: 'restore',
        payload: {
          pipeline: {
            ...pipeline,
            pipeline: {
              ...pipeline?.pipeline,
              ...recipeWithNewIds,
            },
          },
        },
      });
    } else if (selectedDef?.isBetaNode) {
      addNode({ betaNode: selectedDef });
    } else {
      addNode({ nodeType: selectedDef?.key });
    }
    setIsModalOpen(false);
  }

  return (
    <>
      <SubNavList className="mb-4">
        <div className="h-full flex flex-row items-center space-x-1 border-r border-r-gray-200 dark:border-r-black-400 pl-2 pr-4">
          <Icon name="PlusCircle" weight="light" color="gradient" />
          <Text size="xs" color="muted">
            Add Nodes
          </Text>
        </div>
        {tabs.map((tab, idx) => (
          <SubNavList.Item
            key={`tab-${idx}`}
            active={tabIdx === idx}
            onClick={() => setTabIdx(idx)}
          >
            {tab}
          </SubNavList.Item>
        ))}
      </SubNavList>
      <div
        ref={scrollerRef}
        className={cx([
          'flex',
          'flex-1',
          'flex-row',
          'space-x-6',
          'p-4',
          'w-full',
          'overflow-x-scroll',
        ])}
      >
        {!isLoading && currentTabDefs.length <= 0 && (
          <EmptyContentMessage
            icon="MagnifyingGlass"
            title={`No ${tabs[tabIdx]}`}
            variant="secondary"
          />
        )}
        {currentTabDefs.map((def, idx) => {
          if (tabs[tabIdx] === 'Recipes') {
            return (
              <RecipeDefCard
                id={def?.id}
                def={def}
                key={`card-${idx}`}
                onClick={() => handleSelectDef(def)}
              />
            );
          }
          if (def?.isBetaNode === true) {
            return (
              <BetaDefCard
                id={def?.id}
                def={def}
                key={`card-${idx}`}
                onClick={() => handleSelectDef(def)}
              />
            );
          }
          return (
            <NodeDefCard
              id={idx}
              def={def}
              key={`card-${idx}`}
              onClick={() => handleSelectDef(def)}
            />
          );
        })}
      </div>

      <SelectedDefModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAddSelectedDef}
        renderIcon={() => {
          if (tabs[tabIdx] === 'Recipes') {
            return (
              <SkillIcon
                iconUrl={selectedDef?.iconUrl}
                icon={selectedDef?.icon}
                color={selectedDef?.color}
                size="lg"
              />
            );
          }
          if (selectedDef?.id) {
            return (
              <Icon
                name={
                  skillIcons[selectedDef?.icon]
                    ? skillIcons[selectedDef?.icon]
                    : skillIcons['skill']
                }
                color="primary"
                weight="duotone"
                size="2xl"
              />
            );
          }
          return <Icon name={selectedDef?.icon} color="primary" weight="duotone" size="2xl" />;
        }}
        title={selectedDef?.label || selectedDef?.name}
        description={selectedDef?.description}
        inputs={selectedDef?.inputs || []}
        outputs={selectedDef?.outputs || []}
        includedDefs={
          tabs[tabIdx] === 'Recipes' &&
          Object.values(selectedDef?.config || {}).map(def => NODE_DEFS[def?.name])
        }
      />
    </>
  );
}
