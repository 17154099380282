import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Table, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import SkillIcon from 'components/skill-icon';

export default function AdminSkillBuilderRecipesList({ recipes = [] }) {
  const navigate = useNavigate();

  if (isEmpty(recipes)) {
    return <EmptyContentMessage icon="FileCode" title="No Recipes" />;
  }

  return (
    <Table>
      <Table.Body>
        {recipes.map(recipe => (
          <Table.Row
            key={recipe?.id}
            caret
            hover
            onClick={() => navigate(`/admin/skill-builder-content/recipes/${recipe?.id}`)}
          >
            <Table.TD collapsed>
              <SkillIcon
                iconUrl={recipe.iconUrl}
                icon={recipe.icon}
                color={recipe.color}
                size="sm"
              />
            </Table.TD>
            <Table.TD>
              <Text size="sm">{recipe?.name}</Text>
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
