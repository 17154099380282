import { LoadScript } from '@react-google-maps/api';
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Wrapper } from '@optra/kit';

import ColorSchemeTracker from 'components/color-scheme-tracker';
import Footer from 'components/footer';
import Header from 'components/header';
import LogsSidebar from 'components/logs-sidebar';
import NotificationsCenter from 'components/notifications-center';
import NotificationsSidebar from 'components/notifications-sidebar';
import Role from 'components/role';
import SupportSidebar from 'components/support-sidebar';
import MaintenanceMode from 'pages/maintenance-mode';
import ContextProviders from 'providers/context-providers';
import MaintenanceModeTracker from 'router/maintenance-mode-tracker';
import Routes from 'router/routes';
import useGlobalState from 'state';

export default function App() {
  const [, setMapScriptLoaded] = useGlobalState('mapScriptLoaded');
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  return (
    <ContextProviders>
      <LoadScript
        onLoad={() => setMapScriptLoaded(true)}
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        language="en"
        region="EN"
        version="weekly"
      />
      <BrowserRouter>
        <MaintenanceModeTracker onChange={setMaintenanceMode} />
        {!!maintenanceMode ? (
          <MaintenanceMode />
        ) : (
          <>
            <Wrapper
              components={{
                header: (
                  <Header
                    onClickNotifications={() => setIsNotificationsOpen(!isNotificationsOpen)}
                    onClickLogs={() => setIsLogsOpen(!isLogsOpen)}
                    onClickSupport={() => setIsSupportOpen(!isSupportOpen)}
                  />
                ),
                footer: <Footer />,
                sideContent: (
                  <>
                    <NotificationsSidebar
                      isOpen={isNotificationsOpen}
                      onClose={() => setIsNotificationsOpen(false)}
                    />
                    <Role deny roles={['chartViewerOnly']}>
                      <LogsSidebar isOpen={isLogsOpen} onClose={() => setIsLogsOpen(false)} />
                    </Role>
                    <SupportSidebar
                      isOpen={isSupportOpen}
                      onClose={() => setIsSupportOpen(false)}
                    />
                  </>
                ),
              }}
            >
              <Routes />
            </Wrapper>
            <NotificationsCenter />
          </>
        )}
        <ColorSchemeTracker />
      </BrowserRouter>
    </ContextProviders>
  );
}
