import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Icon, Text } from '@optra/kit';

export default function DropdownMenuItem(props) {
  const {
    to,
    onClick,
    children,
    icon,
    iconColor,
    iconWeight,
    iconClassName,
    label,
    description,
    className,
    disabled,
    state,
  } = props;
  const WrapperComponent = to ? Link : 'button';

  return (
    <>
      <WrapperComponent
        to={to}
        state={state}
        onClick={onClick}
        disabled={disabled}
        className={cx(
          'appearance-none select-none w-full flex items-center py-2 px-4 space-x-3 first:rounded-t-md last:rounded-b-md hover:bg-light-bg-primary dark:hover:bg-dark-bg-tertiary',
          disabled ? '' : 'cursor-pointer',
          className,
        )}
      >
        {children || (
          <>
            {!!icon && (
              <div className="w-6">
                <Icon name={icon} color={iconColor} weight={iconWeight} className={iconClassName} />
              </div>
            )}
            <span className="flex flex-col text-left">
              <Text size="xs" variant="label">
                {label}
              </Text>
              {!!description && (
                <Text size="xs" className="opacity-60">
                  {description}
                </Text>
              )}
            </span>
          </>
        )}
      </WrapperComponent>
    </>
  );
}
