import { Badge } from '@optra/kit';

import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ListItem from 'modals/skill-pipeline-builder/components/list-item';
import { NODE_DEFS } from 'modals/skill-pipeline-builder/config';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import createBetaDef from 'modals/skill-pipeline-builder/context/create-beta-def';
import { useSkillBuilderBetaNodes } from 'queries';

export default function AddPipelineNodeOutput() {
  const { pipeline, addNode } = usePipelineContext();
  const type = 'outputs';
  const { data } = useSkillBuilderBetaNodes();
  const betaDefs = (data || []).filter(d => d?.type === type).map(createBetaDef);

  const nodeDefs = Object.values(NODE_DEFS).filter(n => n.type === type);
  const allDefs = [...nodeDefs, ...betaDefs];
  const existingOutputNodeNames = Object.values(pipeline.pipeline)
    .filter(i => i.type === 'outputs')
    .map(i => i.name);
  const availableNodeDefs = allDefs.filter(n => !existingOutputNodeNames.includes(n.key));

  return (
    <ModalInner>
      <ModalTitle title="Add Output" icon="MonitorPlay" />
      <ModalBody>
        {availableNodeDefs.map(nodeDef => (
          <ListItem
            key={`node-type-${nodeDef.key}`}
            icon={nodeDef.icon}
            name={nodeDef.label}
            onClick={() => {
              if (nodeDef?.isBetaNode) {
                addNode({ betaNode: nodeDef, id: nodeDef.key });
              } else {
                addNode({ nodeType: nodeDef.key, id: nodeDef.key });
              }
            }}
            description={nodeDef.description}
            className="w-full"
            truncateDescription={false}
            inputs={nodeDef.inputs}
            outputs={nodeDef.outputs}
            renderBadge={() => {
              if (nodeDef?.isBetaNode) {
                return (
                  <Badge variant="danger" size="xs">
                    Beta
                  </Badge>
                );
              } else {
                return null;
              }
            }}
          />
        ))}
      </ModalBody>
    </ModalInner>
  );
}
