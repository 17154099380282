import { Users } from '@phosphor-icons/react';
import { uniq, groupBy, isEmpty } from 'lodash';
import { useState, useRef } from 'react';
import { useController } from 'react-hook-form';

import {
  ButtonWrap,
  Table,
  UiState,
  WhatsThis,
  Button,
  Icon,
  IconButton,
  Text,
  Avatar,
  DetailList,
} from '@optra/kit';

import ItemChooser from 'components/item-chooser';
import Label from 'components/label';
import { api, q, useOnSuccess } from 'config/api';

export default function SkillBuilderCollaboratorsSelect(props) {
  const { rules, name, control, modelId } = props;

  const controller = useController({
    control,
    rules,
    name: name || 'collaborators',
    defaultValue: {
      push: [],
      pull: [],
    },
  });
  const currentValue = controller.field?.value;
  const [additionals, setAdditionals] = useState([]);
  const [loadingSelected, setLoadingSelected] = useState(true);

  const [search, setSearch] = useState('');
  const chooser = useRef();

  const handleSelect = (selected, item) => {
    if (selected) {
      controller.field.onChange({
        push: uniq(currentValue.push.filter(d => d !== item.id)),
        pull: uniq([...currentValue.pull, item.id]),
      });
    } else {
      controller.field.onChange({
        push: uniq([...currentValue.push, item.id]),
        pull: uniq(currentValue.pull.filter(d => d !== item.id)),
      });
    }
  };

  const hasModelId = !isEmpty(modelId);
  const items = q.useInfiniteQuery({
    queryKey: ['collaborators', 'skillBuilder', 'select', search, modelId],
    queryFn({ pageParam }) {
      return api(
        `query skillBuilderCollaboratorSelect($list: listFields${
          hasModelId ? ', $modelId: ID!' : ''
        }) {
          total: currentOrganizationMembers {
            count
          }
          list: currentOrganizationMembers(list: $list) {
            data {
              id: email
              userProfile {
                id: email
                name
                image
                isCurrentUser
              }
              ${
                hasModelId
                  ? `ei: edgeImpulseProject(id: $modelId) {
                      selected: isCollaborator
                    }
                  `
                  : ''
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          modelId,
          list: {
            filter: {
              $search: search,
            },
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    select: data => data.pages.flatMap(page => page.list.data),
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
  });
  const itemsList =
    items.data?.map?.(item => ({
      id: item.userProfile?.id || item.id,
      name: item.userProfile?.name || item.id,
      image: item.userProfile?.image,
      isCurrentUser: item.userProfile?.isCurrentUser || false,
      selected: item.ei?.selected || false,
    })) || [];
  const itemsById = groupBy([...itemsList, ...additionals], 'id');

  const selectedQuery = q.useQuery({
    queryKey: ['collaborators', 'skillBuilder', 'selected', modelId],
    async queryFn() {
      const getPage = async ({ after } = {}) => {
        const r = await api(
          `query skillBuilderCollaborators($list: listFields ${modelId ? ', $modelId: ID!' : ''}) {
            model(id: $modelId) {
              edgeImpulse {
                collaborators(list: $list) {
                  data {
                    id: email
                    image
                    name
                    isCurrentUser
                  }
                  cursor {
                    after
                  }
                }
              }
            }
          }`,
          {
            modelId,
            list: {
              cursor: { after },
            },
          },
        );
        return {
          after: r.model?.edgeImpulse?.collaborators?.cursor?.after,
          data: r.model?.edgeImpulse?.collaborators?.data,
        };
      };

      const getAllPages = async ({ acc = [], after } = {}) => {
        const r = await getPage({ after });
        const next = [...acc, ...r.data];
        if (r.after) {
          return getAllPages({ acc: next, after: r.after });
        }
        return next;
      };

      return getAllPages();
    },
    placeholderData: q.keepPreviousData,
    enabled: !!modelId && loadingSelected,
  });

  useOnSuccess(
    () => {
      setLoadingSelected(false);
      setAdditionals(
        selectedQuery?.data?.map(item => ({
          id: item.id,
          name: item.name || item.id,
          image: item.image,
          isCurrentUser: item.isCurrentUser || false,
          selected: item.ei?.selected || false,
        })),
      );
      controller.field.onChange({
        push: uniq([...currentValue.push, ...selectedQuery?.data?.map(({ id }) => id)]),
        pull: currentValue.pull,
      });
    },
    { isSuccess: selectedQuery.isSuccess },
    [controller.field, currentValue.pull, currentValue.push, selectedQuery?.data],
  );

  return (
    <>
      <div>
        <div className="flex items-center justify-between mb-3">
          <div>
            <Label htmlFor="devices">Collaborators</Label>
            <WhatsThis>Team members with access to your Skill Builder project</WhatsThis>
          </div>
          <IconButton icon="Plus" variant="tertiary" onClick={() => chooser.current.open()} />
        </div>
        {selectedQuery.isLoading && (
          <UiState variant="loading" text="Loading Collaborators" icon={{ component: Users }} />
        )}
        {!selectedQuery.isLoading && currentValue.push?.length === 0 && (
          <UiState variant="empty" text="No Collaborators" icon={{ component: Users }}>
            <Button size="xs" variant="tertiary" icon="Plus" onClick={() => chooser.current.open()}>
              ADD
            </Button>
          </UiState>
        )}
        {!selectedQuery.isLoading && currentValue.push?.length > 0 && (
          <Table>
            <Table.Body>
              {currentValue.push?.map?.(id => {
                const item = itemsById[id]?.[0];
                if (!item) return null;
                return (
                  <Table.Row key={id}>
                    <Table.TD collapsed>
                      <Avatar src={item?.image} size="xs" />
                    </Table.TD>
                    <Table.TD>
                      <Text size="sm">{item.name}</Text>
                      {item.isCurrentUser && <DetailList details={['You']} />}
                    </Table.TD>
                    <Table.TD collapsed>
                      <ButtonWrap
                        as="div"
                        className="opacity-50 hover:opacity-100"
                        onClick={() => handleSelect(true, item)}
                      >
                        <Icon name="XCircle" size="sm" weight="duotone" />
                      </ButtonWrap>
                    </Table.TD>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </div>
      <ItemChooser
        ref={chooser}
        items={itemsList}
        itemsInfiniteQuery={items}
        isLoading={items?.isLoading || selectedQuery.isLoading}
        onSelect={handleSelect}
        isSelected={item => {
          if (currentValue.push.includes(item.id)) return true;
          if (currentValue.pull.includes(item.id)) return false;
          return item.selected;
        }}
        search={search}
        onSearch={setSearch}
        emptyIcon="Users"
        heading="Choose Collaborators"
        loadErrorTitle="Couldn't Load Collaborators"
        RowDataComponent={({ item }) => (
          <>
            <Table.TD collapsed>
              <Avatar src={item.image} size="xs" />
            </Table.TD>
            <Table.TD>
              <Text size="sm">{item.name}</Text>
              {item.isCurrentUser && <DetailList details={['You']} />}
            </Table.TD>
          </>
        )}
      />
    </>
  );
}
