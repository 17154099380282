import flatMap from 'lodash/flatMap';

import { SideModal } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import NotificationsList from 'components/notifications-list';
import { api, q } from 'config/api';
import { useNotifications } from 'queries';

export default function NotificationsSidebar({ isOpen, onClose }) {
  const { data, isLoading, error, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useNotifications();
  const notifications = flatMap(data?.pages, page => page?.list?.data);

  const qc = q.useQueryClient();
  const markNotificationsAsRead = q.useMutation({
    mutationFn: id =>
      api(
        `mutation markNotificationsAsRead {
          markNotificationsAsRead
        }`,
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  function handleClose() {
    markNotificationsAsRead.mutate();
    onClose();
  }

  return (
    <SideModal isOpen={isOpen} onClose={handleClose} icon="Bell" title="Notifications">
      <div className="w-full">
        <NotificationsList
          fetching={isLoading}
          error={error}
          notifications={notifications}
          onItemClick={handleClose}
        />

        <IntersectionLoadMore
          onVisible={fetchNextPage}
          disabled={!(notifications.length > 0) || isFetchingNextPage || !hasNextPage}
        />
      </div>
    </SideModal>
  );
}
