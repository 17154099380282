import { Text } from '@optra/kit';

import SkillStatus from 'components/skill-status';
import { useDeploymentTaskStatus } from 'queries';

const statusLabelMap = {
  starting: 'Starting...',
  restarting: 'Restarting...',
  running: 'Running',
  stopped: 'Stopped',
  partial: 'Partial',
};

export default function SyncProgressDeviceSkillStatus(props) {
  const { taskId } = props;
  const { data } = useDeploymentTaskStatus(taskId);
  const status = data?.task?.skillsStatus;

  if (!status) return null;

  const skillsStatus = statusLabelMap[status];

  return (
    <>
      <SkillStatus hideLabel status={status} />
      <Text size="xs" color="muted" variant="label" className="md:inline ml-3">
        {skillsStatus}
      </Text>
    </>
  );
}
