import Checkbox from 'components/checkbox';
import Input from 'components/input';
import Label from 'components/label';
import ValidationError from 'components/validation-error';

export default function TimestreamActionDataFields(props) {
  const { Form, output, workflowSkill, loading } = props;
  const enabled = Form.watch(`configuration[${workflowSkill.id}].payload[${output.id}].enabled`);

  return (
    <div>
      <div className="flex items-center space-x-2 my-2">
        <Label className="flex-1">
          <Checkbox
            {...Form.register(`configuration[${workflowSkill.id}].payload[${output.id}].enabled`)}
            className="mr-2"
            readOnly={loading}
          />
          {output.label}
        </Label>
        <Input
          className="flex-1"
          type="text"
          placeholder="lowercase_column_name"
          {...Form.register(`configuration[${workflowSkill.id}].payload[${output.id}].as`, {
            validate: value => {
              // TODO: Validate to timestream columns and do not allow default columns
              if (enabled && value.trim() === '') {
                return 'Please enter a variable name';
              }
              return true;
            },
          })}
          readOnly={loading}
        />
      </div>
      <ValidationError
        errors={Form.errors}
        name={`configuration[${workflowSkill.id}].payload[${output.id}].as`}
      />
    </div>
  );
}
