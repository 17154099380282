export const CATEGORIES = {
  factories: {
    label: 'Factories',
    icon: '',
    description: '',
  },
  inputs: {
    label: 'Inputs',
    icon: '',
    description: '',
  },
  inference: {
    label: 'AI Models',
    icon: '',
    description: '',
  },
  processors: {
    label: 'Data Processor',
    icon: '',
    description: '',
  },
  graphic_elements: {
    label: 'Image Processor',
    icon: '',
    description: '',
  },
  outputs: {
    label: 'Outputs',
    icon: '',
    description: '',
  },
};

export const CATEGORIES_ORDER = [
  'factories',
  'inputs',
  'inference',
  'processors',
  'graphic_elements',
  'outputs',
];
