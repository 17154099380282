import last from 'lodash/last';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTitle } from 'react-use';

import { Button, Card, SemanticVersionInput, FileInput } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Textarea from 'components/textarea';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';
import { useSignedUpload } from 'queries';

export default function AdminCreateFirmware(props) {
  const { defaultValues, override, deviceId, isLoadingDefaults } = props;
  useTitle(`${override ? 'Override' : 'Create'} Firmware | Optra`);

  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    watch,
    reset,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      ...defaultValues,
    },
  });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const [error, setError] = useState();
  const fileUploaded = watch('files');
  useInputFocus(setFocus, 'name');
  const { handleClose } = useModalContext();

  const qc = q.useQueryClient();
  const createFirmware = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createFirmware($form: createFirmwareForm!) {
          firmware: createFirmware(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['firmwares'] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const overrideDeviceFirmware = q.useMutation({
    mutationFn: form =>
      api(
        `mutation overrideDeviceFirmware($form: overrideDeviceFirmwareForm!) {
          device: overrideDeviceFirmware(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['device', deviceId] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const [upload, uploadState] = useSignedUpload({
    type: override ? 'debuggingFirmware' : 'firmware',
  });

  const handleSubmit = onSubmit(async form => {
    const blob = form?.files?.[0];

    if (!blob) {
      alert('Could not upload file. Try again?');
      return;
    }

    const { url, key } = await upload(blob, {
      extension: last(blob.name.split('.')),
    });

    const firmwareForm = {
      name: form.name,
      releaseNotes: form.releaseNotes,
      createdAt: new Date(),
      buildTarget: form.buildTarget,
      iotedgeVersion: form.iotedgeVersion,
      url,
      key,
    };

    if (override) {
      overrideDeviceFirmware.mutate({
        deviceId,
        firmware: firmwareForm,
      });
      return;
    }

    createFirmware.mutate(firmwareForm);
  });

  const submitting =
    uploadState?.fetching ||
    createFirmware.isPending ||
    overrideDeviceFirmware.isPending ||
    isLoadingDefaults;

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title={`${override ? 'Override' : 'Create'} Firmware`}
        icon="Cpu"
        loading={submitting}
      />
      <ModalBody className="space-y-4">
        {!!error && (
          <Message variant="danger" title="Couldn't Create Firmware">
            {error.message}
          </Message>
        )}

        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Version</Label>
            <Controller
              name="name"
              control={control}
              disabled={submitting}
              render={({ field }) => <SemanticVersionInput {...field} />}
              rules={{
                required: 'Please enter a version.',
              }}
            />
            <ValidationError errors={errors} name="name" />
          </div>

          <div className="space-y-2">
            <Label htmlFor="buildTarget">Build Target</Label>
            <Input
              type="text"
              {...register('buildTarget', { required: 'Please enter a build target.' })}
              readOnly={submitting}
            />
            <ValidationError errors={errors} name="buildTarget" />
          </div>

          <div className="space-y-2">
            <Label htmlFor="iotedgeVersion">IoT Edge Version</Label>
            <Input type="text" {...register('iotedgeVersion')} readOnly={submitting} />
            <ValidationError errors={errors} name="iotedgeVersion" />
          </div>

          <div className="space-y-2">
            <Label htmlFor="releaseNotes">Release Notes</Label>
            <Textarea
              rows={6}
              {...register('releaseNotes', { required: 'Please enter release notes.' })}
              readOnly={submitting}
            />
            <ValidationError errors={errors} name="releaseNotes" />
          </div>

          <div className="space-y-2">
            <Label htmlFor="files">Binary</Label>
            <FileInput
              {...register('files', { required: 'Please upload a binary.' })}
              result={fileUploaded?.[0]}
              readOnly={submitting}
            />
            <ValidationError errors={errors} name="files" />
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={submitting}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
