import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'input_factory',
  label: 'Input Factory',
  icon: 'Plugs',
  description:
    'Based on the given input path, determine what input node is needed and automatically create it.',
  type: 'factories',
  inputs: [],
  outputs: [
    {
      key: 'image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
};
