import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Table, Button, Icon } from '@optra/kit';

import DeviceListItem from 'components/device-list-item';
import DevicesTableHeading from 'components/devices-table-heading';
import EmptyContentMessage from 'components/empty-content-message';
import { useFeature } from 'components/feature';
import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';
import { useHasRoles } from 'hooks';

export default function DevicesList({
  canCreateDevice,
  fetching,
  currentSort,
  onSort,
  error,
  devices = [],
  selectedDeviceIds = [],
  onSelectDevice = () => false,
  onSelectDevices = () => false,
  hasMore,
  onLoadMore = () => false,
  loadingMore,
  isFiltered = false,
  canSelectPending,
  visibleColumns,
  selectAll = false,
  devicesOmitted = [],
  allSelected = false,
}) {
  const [canEdit] = useHasRoles([
    'admin',
    'deviceTechnician',
    'deviceEnroller',
    'workflowEditor',
    'workflowDeployer',
  ]);

  const deviceConnectionEnabled = useFeature('deviceConnection');

  if (!deviceConnectionEnabled) {
    visibleColumns = visibleColumns.filter(
      column => column.id !== 'connectionStatus' && column.id !== 'downtime',
    );
  }

  if (error) {
    return (
      <Message variant="danger" title="Couldn't Load Devices">
        {error.message}
      </Message>
    );
  }

  if (isEmpty(devices) && !fetching && !loadingMore) {
    return (
      <EmptyContentMessage
        icon={isFiltered ? 'MagnifyingGlass' : 'Aperture'}
        title={isFiltered ? 'No Devices Found' : 'No Devices'}
        renderAction={() =>
          canCreateDevice &&
          !isFiltered && (
            <Button to="/devices/create" icon="Plus">
              Enroll Device
            </Button>
          )
        }
      />
    );
  }

  return (
    <div className={cx(fetching && 'animate-pulse')}>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.TH>
              <button className="appearance-none" type="button" onClick={onSelectDevices}>
                <Icon name="Checks" color={allSelected && 'gradient'} />
              </button>
            </Table.TH>
            <Table.TH>&nbsp;</Table.TH>
            {deviceConnectionEnabled && <Table.TH>&nbsp;</Table.TH>}
            <Table.TH className="lg:hidden">Name</Table.TH>
            {visibleColumns?.map(column => (
              <DevicesTableHeading
                key={`th-${column.id}`}
                column={column}
                currentSort={currentSort}
                onSort={onSort}
              />
            ))}
            <Table.TH />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {devices.map(device => (
            <DeviceListItem
              key={device.id}
              device={device}
              selectable={canSelectPending}
              selected={
                (selectAll && !devicesOmitted.includes(device.id)) ||
                selectedDeviceIds.includes(device.id)
              }
              onSelect={() => onSelectDevice(device.id)}
              visibleColumns={visibleColumns}
              canEdit={canEdit}
            />
          ))}
        </Table.Body>
      </Table>

      <IntersectionLoadMore onVisible={onLoadMore} disabled={loadingMore || fetching || !hasMore} />
    </div>
  );
}
