import { api, q } from 'config/api';

export default function useWorkflowsNames(variables) {
  return q.useInfiniteQuery({
    queryKey: ['workflows', variables, 'names'],
    queryFn({ pageParam }) {
      return api(
        `query workflowsNames($list: listFields) {
          list: workflows(list: $list) {
            data {
              id
              name
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    // refetchInterval: 5000,
  });
}
