import { Card, Text, StatusIndicator, Spinner, DetailList, Icon } from '@optra/kit';

import { api, q } from 'config/api';

function StatItem(props) {
  const { label, value, status, sourceName, healthy = true } = props;
  return (
    <div className="flex items-center justify-between mb-2">
      <Text size="sm" className="flex-1 pr-4">
        <span className="flex items-center">
          {label}
          {!healthy && (
            <Icon
              className="ml-1"
              name="WarningCircle"
              size="xs"
              weight="regular"
              color="red"
              aria-hidden="false"
              aria-label={`Output ${label} from ${sourceName} is unhealthy`}
            />
          )}
        </span>
        <DetailList details={[sourceName]} />
      </Text>

      <div className="flex items-center justify-end truncate">
        {status && <StatusIndicator status={status} size="xs" />}
        <Text size="sm" color="muted" className="ml-2 truncate">
          {value || '--'}
        </Text>
      </div>
    </div>
  );
}

export default function DeviceDetailOutputs(props) {
  const { deviceId } = props;
  const deviceOutputs = q.useQuery({
    queryKey: ['device', deviceId, 'outputs'],
    queryFn: () =>
      api(
        `query device($deviceId: ID!) {
          device(id: $deviceId) {
            outputs: latestOutputs {
              id
              name
              sourceName
              value
              type
              healthy
            }
          }
        }`,
        { deviceId },
      ),
    refetchInterval: 1000 * 60 * 2, // Check every 2 minutes
  });

  const hasOutputs = deviceOutputs.data?.device?.outputs?.length > 0;

  return (
    <Card variant="secondary" className="md:overflow-y-auto md:max-h-[205px]">
      <Text variant="label">Outputs</Text>
      <div className="mb-5" />
      {deviceOutputs.isLoading && (
        <div className="flex items-center justify-center h-32">
          <Spinner color="gradient" />
        </div>
      )}
      {!hasOutputs && (
        <div className="flex justify-center items-center h-28">
          <Text color="muted">No Outputs Set</Text>
        </div>
      )}
      {hasOutputs &&
        deviceOutputs.data?.device?.outputs?.map?.(output => (
          <StatItem
            key={output.id}
            label={output.name}
            sourceName={output.sourceName}
            healthy={output.healthy}
            value={output.value}
          />
        ))}
    </Card>
  );
}
