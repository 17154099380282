import { api, q } from 'config/api';

export default function useDeploymentLogs({ deploymentId, list }, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflowDeployment', deploymentId, list, 'logs'],
    async queryFn({ pageParam }) {
      const r = await api(
        `query workflowDeploymentLogs($deploymentId: ID!, $list: listFields) {
          deployment: workflowDeployment(id: $deploymentId) {
            logs(list: $list) {
              data {
                id
                message
                type
                descriptors
                createdAt
              }
              cursor {
                after
              }
            }
          }
        }`,
        {
          deploymentId,
          list: {
            ...list,
            cursor: { after: pageParam },
          },
        },
      );

      const logs = r?.deployment?.logs?.data;

      return {
        logs,
        cursor: r?.deployment?.logs?.cursor,
      };
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
    enabled: !!deploymentId,
    ...overrides,
  });
}
