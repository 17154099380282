import * as date from 'date-fns';
import { flatMap, truncate } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';

import { Table, Badge, Icon, Text, StatusIndicator } from '@optra/kit';

import DeviceImage from 'components/device-image';
import { useFeature } from 'components/feature';
import FirmwareVersionLabel from 'components/firmware-version-label';
import SkillIcon from 'components/skill-icon';
import StatusLabel from 'components/status-label';
import { statLabel, statStatus, headphoneLabel, headphoneStatus } from 'lib/device-stats';

const renderOutputColumn = (device, column) => {
  let valueToDisplay = 'No Value';
  try {
    valueToDisplay = `${device?.skillOutputs[column.mapping].value}`;
  } catch (err) {
    valueToDisplay = 'No Value';
  }
  return valueToDisplay;
};

const renderColumn = (column, device) => {
  const skills = flatMap(device?.workflows?.data || [], workflow => workflow?.skills?.data);
  switch (column.id) {
    case 'name':
      return <Text size="sm">{device?.name}</Text>;
    case 'tags':
      return (
        <div className="flex flex-wrap">
          {device?.tags?.data?.map(tag => (
            <Badge size="xs" variant="primary" key={tag?.tag?.id} className="m-1">
              {tag?.tag?.name}
            </Badge>
          ))}
        </div>
      );
    case 'firmwareVersion':
      return (
        <FirmwareVersionLabel
          to={`/devices/${device?.id}/overview/firmware`}
          updateAvailable={device?.firmware?.updateAvailable}
          updating={device?.firmware?.isUpdating}
          version={device?.firmware?.version}
        />
      );
    case 'workflows':
      return (
        <div className="flex flex-wrap w-60">
          {device?.workflows?.data?.map(workflow => (
            <Link
              to={`/workflows?id=${workflow.id}`}
              key={workflow.id}
              className="flex items-center m-1 cursor-pointer hover:opacity-50"
              onClick={event => event.stopPropagation()}
            >
              <Icon name="GitBranch" color="gradient" size="xs" className="mr-1" />
              <Text size="xs" color="muted">
                {workflow?.name}
              </Text>
            </Link>
          ))}
        </div>
      );
    case 'skills':
      return (
        <div className="flex flex-wrap w-60">
          {skills?.map(skill => (
            <div key={skill.id} className="flex items-center m-1">
              <SkillIcon
                icon={skill?.skill?.icon}
                iconUrl={skill?.skill?.iconUrl}
                color={skill?.skill?.color}
                size="xs"
                className="mr-1"
              />
              <Text size="xs" color="muted">
                {skill.name
                  ? `${truncate(skill.name, { length: 24 })} (${skill.version?.version})`
                  : `${truncate(skill.skill?.name, { length: 24 })} (${skill.version?.version})`}
              </Text>
            </div>
          ))}
        </div>
      );
    case 'location':
      return (
        <div>
          <Text size="xs" className="block">
            {device?.location?.name}
          </Text>
          <Text size="xs" color="muted" className="block">
            {device?.location?.address?.address}
          </Text>
          <Text size="xs" color="muted" className="block">
            {device?.location?.address?.locality}
          </Text>
        </div>
      );
    case 'downtime':
      if (device.downtime?.down) {
        return (
          <Text size="xs" color="muted">
            {date.formatDistanceToNow(new Date(device.downtime?.downAt))}
          </Text>
        );
      }
      return (
        <Text size="xs" color="muted">
          -
        </Text>
      );
    case 'connectionStatus':
      return (
        <div className="flex items-center">
          <StatusIndicator size="sm" status={device.status} className="mr-2" />
          <StatusLabel status={device.status} />
        </div>
      );
    case 'diskStatus':
      return (
        <div className="flex items-center">
          <StatusIndicator size="sm" status={statStatus(device?.disk?.usage)} className="mr-2" />
          <Text size="xs" color="muted">
            {statLabel(device?.disk?.usage)}
          </Text>
        </div>
      );
    case 'memoryStatus':
      return (
        <div className="flex items-center">
          <StatusIndicator size="sm" status={statStatus(device?.ram?.usage)} className="mr-2" />
          <Text size="xs" color="muted">
            {statLabel(device?.ram?.usage)}
          </Text>
        </div>
      );
    case 'cpuStatus':
      return (
        <div className="flex items-center">
          <StatusIndicator size="sm" status={statStatus(device?.cpu?.usage)} className="mr-2" />
          <Text size="xs" color="muted">
            {statLabel(device?.cpu?.usage)}
          </Text>
        </div>
      );
    case 'ip':
      return (
        <div className="flex items-center">
          <Text size="xs" color="muted">
            {device?.ip || '--'}
          </Text>
        </div>
      );
    case 'mac':
      return (
        <div className="flex items-center">
          <Text size="xs" color="muted">
            {device?.mac || '--'}
          </Text>
        </div>
      );
    case 'headphoneJack':
      return (
        <div className="flex items-center">
          <StatusIndicator
            size="sm"
            status={headphoneStatus(device?.headphoneJack)}
            className="mr-2"
          />
          <Text size="xs" color="muted">
            {headphoneLabel(device?.headphoneJack)}
          </Text>
        </div>
      );
    case 'internalTemperature':
      return (
        <div className="flex items-center">
          <StatusIndicator
            size="sm"
            status={statStatus(device?.internalTemperatureUsage)}
            className="mr-2"
          />
          <Text size="xs" color="muted">
            {statLabel(device?.internalTemperatureUsage)}
          </Text>
        </div>
      );
    case 'serialNumber':
      return (
        <Text size="xs" color="muted" className="uppercase font-mono">
          {device?.serialNumber}
        </Text>
      );
    case 'warrantyStartedAt':
      return (
        <Text size="xs" color="muted">
          {device?.provisionedDevice?.warrantyStartedAt
            ? date.format(new Date(device?.provisionedDevice?.warrantyStartedAt), 'M/d/yyyy')
            : '-'}
        </Text>
      );
    case 'subscription':
      return (
        <div className="flex items-center">
          <StatusIndicator
            size="sm"
            status={statStatus(device?.subscriptionStatus)}
            className="mr-2"
          />
          <Text size="xs" color="muted">
            {statLabel(device?.subscriptionStatus)}
          </Text>
        </div>
      );
    default:
      return (
        <Text size="xs" color="muted">
          {renderOutputColumn(device, column)}
        </Text>
      );
  }
};

export default function DeviceListItem({
  device = {},
  selected,
  onSelect,
  selectable: _selectable = true,
  visibleColumns = [],
  canEdit,
  deviceSkillOutputMappings,
}) {
  const navigate = useNavigate();
  const deviceConnectionEnabled = useFeature('deviceConnection');

  const status = device?.autoClaim ? 'pending' : device.mapState;
  const selectable = device?.autoClaim ? _selectable : true;

  const onEdit = () => navigate(`/devices/${device?.id}`);
  const handleSelect = selectable
    ? e => {
        onSelect(device.id);
        e?.stopPropagation();
      }
    : null;

  return (
    <Table.Row
      caret={canEdit}
      hover={canEdit}
      disabledSelectable={!selectable}
      selectable={true}
      selected={selected}
      onSelect={handleSelect}
      onClick={canEdit ? onEdit : null}
    >
      <Table.TD collapsed>
        <DeviceImage model={device.model} size="sm" hideStatus />
      </Table.TD>
      {deviceConnectionEnabled && (
        <Table.TD collapsed>
          <StatusIndicator status={status} size="md" />
        </Table.TD>
      )}
      <Table.TD className="lg:hidden">
        <Text size="sm">{device?.name}</Text>
      </Table.TD>
      {visibleColumns.map((column, index) => (
        <Table.TD
          className="hidden lg:table-cell"
          style={{ minWidth: 100, maxWidth: 240 }}
          key={`c${column.id}`}
        >
          {renderColumn(column, device)}
        </Table.TD>
      ))}
    </Table.Row>
  );
}
