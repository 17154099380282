import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Button, Card } from '@optra/kit';
import { validators } from '@optra/shared';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus } from 'hooks';
import { useGiftCardPurchaseOrder } from 'queries/use-gift-card-purchase-order';

export default function AdminPurchaseOrderUpdate() {
  useTitle('Usage Credits Update | Optra');

  const { id } = useParams();
  const { handleClose } = useModalContext();
  const [error, setError] = useState();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm({
    defaultValues: {
      id: '',
      poNumber: '',
      vendor: '',
    },
    resolver: zodResolver(validators.giftCardPurchaseOrderUpdate),
  });
  useInputFocus(setFocus, 'poNumber');

  const { data, error: fetchError, isSuccess } = useGiftCardPurchaseOrder(id);

  useOnSuccess(
    () => {
      if (error) {
        setError(fetchError);
      }

      const { giftCardPurchaseOrder } = data;

      reset(formValues => {
        return {
          id: giftCardPurchaseOrder?.id,
          poNumber: giftCardPurchaseOrder?.poNumber,
          vendor: giftCardPurchaseOrder?.vendor,
        };
      });
    },
    { isSuccess },
    [data, error, reset],
  );

  const qc = q.useQueryClient();
  const update = q.useMutation({
    mutationFn: form =>
      api(
        `mutation UpdateGiftCardPurchaseOrder($form: updateGiftCardPurchaseOrderForm!) {
					updateGiftCardPurchaseOrder(form: $form) {
						id
					}
				}`,
        { form },
      ),
  });

  const handleOnSubmit = handleSubmit(form => {
    const validationResult = validators.giftCardPurchaseOrderUpdate.safeParse(form);

    if (!validationResult?.success) {
      const errors = validationResult.error.errors.map(error => error.message);
      setError(`Validation failed: [${errors.join(', ')}]`);
      return;
    }

    const validatedData = validationResult.data;
    update.mutate(
      {
        id: validatedData.id,
        poNumber: validatedData.poNumber,
        vendor: validatedData.vendor,
      },
      {
        onSuccess(r) {
          handleClose();
          qc.invalidateQueries({ queryKey: ['giftCardPurchaseOrder', id] });
        },
        onError(error) {
          setError(error);
        },
      },
    );
  });

  return (
    <ModalInner as="form" onSubmit={handleOnSubmit}>
      <ModalTitle title="Update Usage Credits" icon="Key" loading={update?.isPending} />
      <ModalBody>
        {!!error && (
          <Message variant="danger" title="Couldn't Update Purchase Order">
            {error}
          </Message>
        )}

        {fetchError && (
          <Message variant="danger" title="Couldn't Load Purchase Order>">
            {fetchError.message}
          </Message>
        )}
        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="poNumber">P.O. Number</Label>
            <Input type="text" {...register('poNumber')} readOnly={update?.isPending} />
            <ValidationError errors={errors} name="poNumber" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="vendor">Retailer Name</Label>
            <Input type="text" {...register('vendor')} readOnly={update?.isPending} />
            <ValidationError errors={errors} name="vendor" />
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={update?.isPending}>
          Update
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
