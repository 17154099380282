import { Icon, IconButton, Text } from '@optra/kit';

import ListItem from 'components/list-item';
import { api, q } from 'config/api';

export default function CameraListItem({ id, name, to, onDelete }) {
  const qc = q.useQueryClient();
  const removeSensor = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeSensor($id: ID!) {
          sensor: removeSensor(id: $id) {
            id
            device {
              id
            }
          }
        }`,
        { id },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['device', r.sensor.device.id] });
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow'] });
      onDelete();
    },
  });

  const handleDelete = id => {
    if (window.confirm('Are you sure you want to delete this camera?')) {
      removeSensor.mutate(id);
    }
  };

  return (
    <ListItem
      key={id}
      renderLeft={() => <Icon name="VideoCamera" color="gradient" />}
      renderRight={() => (
        <div className="flex items-center space-x-2">
          <IconButton variant="tertiary" name="Pencil" to={to} state={{ fromModal: true }} />
          <IconButton
            variant="tertiary"
            name="Trash"
            onClick={() => handleDelete(id)}
            loading={removeSensor.isPending}
          />
        </div>
      )}
    >
      <Text>{name}</Text>
    </ListItem>
  );
}
