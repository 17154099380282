import { Route, Routes } from 'react-router-dom';
import { useTitle } from 'react-use';

import CreateLocation from 'components/create-location';
import EditDeviceLocations from 'components/edit-device-locations';
import EditLocation from 'components/edit-location';
import ModalInner from 'components/modal-inner';
import ManageLocationsOverview from 'modals/manage-locations-overview';

export default function ManageLocations() {
  useTitle('Manage Locations | Optra');

  return (
    <ModalInner>
      <Routes>
        <Route index element={<ManageLocationsOverview />} />
        <Route path="new" element={<CreateLocation />} />
        <Route path=":locationId/*">
          <Route path="edit/*">
            <Route index element={<EditLocation />} />
            <Route path="devices" element={<EditDeviceLocations />} />
          </Route>
        </Route>
      </Routes>
    </ModalInner>
  );
}
