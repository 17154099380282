import { api, q } from 'config/api';

export default function usePreclaimedDevices(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['preclaimedDevices', variables],
    queryFn: ({ pageParam }) =>
      api(
        `
          query preclaimedDevices($list: listFields) {
            list: preclaimedDevices(list: $list) {
              count
              data {
                id
                serialNumber
                provisionedDevice {
                  firstEnrolledAt
                  status
                }
              }
            }
          }
        `,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      ),
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: true,
    ...overrides,
  });
}
