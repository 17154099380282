function ChartDot(props) {
  if (!props.cy) return null;
  return (
    <circle
      r={props?.r}
      width={props?.width}
      height={props?.height}
      cx={props?.cx}
      cy={props?.cy}
      fill={props?.stroke}
      strokeWidth={0}
    />
  );
}

export default ChartDot;
