import { api, q } from 'config/api';

export default function useSkillBuilderRecipes() {
  return q.useInfiniteQuery({
    queryKey: ['skillBuilderRecipes'],
    queryFn({ pageParam }) {
      return api(
        `query skillBuilderRecipes($list: listFields) {
          list: skillBuilderRecipes(list: $list) {
            count
            data {
              id
              name
              description
              config
              iconUrl
              icon
              color
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => data.pages.flatMap(page => page.list.data),
  });
}
