import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Button } from '@optra/kit';

import CameraFormFields from 'components/camera-form-fields';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';

export default function CreateCamera() {
  useTitle('Create Camera | Optra');
  const { deviceId, workflowDeviceId } = useParams();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      ip: '',
      location: {
        lat: null,
        lng: null,
      },
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');
  const { handleBack } = useModalContext();

  const qc = q.useQueryClient();
  const createSensor = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createSensor($form: createSensorForm!) {
          sensor: createSensor(form: $form) {
            id
            device {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['device', r.sensor?.device?.id] });
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow'] });
      handleBack();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    createSensor.mutate({
      ...form,
      deviceId,
      workflowDeviceId,
    });
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Create Camera" icon="VideoCamera" loading={createSensor.isPending} />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Create Camera">
            {error.message}
          </Message>
        )}

        <CameraFormFields
          register={register}
          control={control}
          errors={errors}
          loading={createSensor.isPending}
          detectLocation
        />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={createSensor.isPending}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
