import { api, q } from 'config/api';

// NOTE: status will return null if the "workflowDeploymentRuntimeStatus" feature is not enabled for the org
export const workflowFragment = `
  fragment WorkflowFields on Workflow {
    id
    createdAt
    name
    disabled
    synchronized
    isSynchronizing
    starred
    deleted
    creditCost
    hasLicenses
    status {
      step
      starting
      running
      failed
      unknown
    }
    latestDeployment {
      id
      status
      totalTasks
      failedTasks
      completedTasks
      progress
    }
  }
`;

export default function useWorkflows(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflows', variables],
    queryFn({ pageParam }) {
      return api(
        `${workflowFragment}
        query workflows($list: listFields) {
          list: workflows(list: $list) {
            data {
              ...WorkflowFields
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
    ...overrides,
  });
}
