import cx from 'classnames';
import { compact, filter, sumBy, isString } from 'lodash';

import { DetailHeading, Text } from '@optra/kit';

import { useChartCtx } from 'modals/chart-details/context';
import { CONSTANTS } from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

import ChartValue from './chart-value';

const { SPLIT_SYMBOL_2, SPLIT_SYMBOL_3 } = CONSTANTS;

function TinyHeading({ children }) {
  return (
    <DetailHeading uppercase={false} bold={false} style={{ fontSize: 11 }}>
      {children}
    </DetailHeading>
  );
}

export function ChartTooltipContainer({ children }) {
  return (
    <div
      className={cx(
        'overflow-hidden',
        'bg-white dark:bg-[#000] bg-opacity-90 dark:bg-opacity-90',
        'border border-gray-200/50 dark:border-black-600',
        'shadow-xl dark:shadow-[#000]/20',
        'rounded-md',
        'backdrop-blur-sm',
        'animate-fade-in',
        'divide-y divide-gray-200/50 dark:divide-black-600',
      )}
    >
      {children}
    </div>
  );
}

export function ChartToolTipHeading({ isCompact, timeFormat, children }) {
  if (!children || !isString(children)) return null;

  const headingArray = helpers.splitStringViaSymbol(children);

  return (
    <div
      className={cx(
        isCompact ? 'py-2 px-3' : 'py-3 px-4',
        headingArray.length > 1 && 'grid gap-2 grid-cols-2',
      )}
    >
      {headingArray.map(heading => {
        const [name] = helpers.splitStringViaSymbol(heading, SPLIT_SYMBOL_2);
        return (
          <div key={heading}>
            {name && name !== heading && !isCompact && <TinyHeading>{name}</TinyHeading>}
            <ChartValue as={DetailHeading} variant="loud" uppercase={false} timeFormat={timeFormat}>
              {heading}
            </ChartValue>
          </div>
        );
      })}
    </div>
  );
}

export function ChartTootipItem({ color, label, value, isCompact, timeFormat }) {
  if (helpers.isUnknownValue(value)) return null;

  const labelArray = helpers.cleanList(
    helpers.splitStringViaSymbol(label).map(label => {
      const metricName = helpers.splitStringViaSymbol(label, SPLIT_SYMBOL_3)?.[3];
      return metricName || label;
    }),
  );

  return (
    <div className={cx('flex flex-row items-center space-x-2', !isCompact && 'my-1')}>
      {color && <div className="w-1 self-stretch rounded-full" style={{ background: color }}></div>}
      <div className="flex flex-col -mb-[5px] -mt-[3px]">
        {labelArray.map(label => {
          const metricName = helpers.splitStringViaSymbol(label, SPLIT_SYMBOL_3)?.[3];
          return (
            <ChartValue
              as={TinyHeading}
              key={label}
              displayName={labelArray.length > 1}
              truncate={isCompact ? 15 : undefined}
              timeFormat={timeFormat}
            >
              {metricName || label}
            </ChartValue>
          );
        })}
        <Text className={cx('font-bold inline-block', isCompact && 'text-sm')}>
          {helpers.formatNumber(value)}
        </Text>
      </div>
    </div>
  );
}

function ChartTooltipClickMore({ numHiddenItems }) {
  const {
    configuration: { showInterface },
  } = useChartCtx();

  if (!numHiddenItems && !showInterface) return null;

  return (
    <div
      className={cx(
        'flex items-center',
        numHiddenItems ? 'justify-between' : 'justify-center',
        'py-3 px-4 space-x-2',
      )}
    >
      {!!numHiddenItems && <TinyHeading>...and {numHiddenItems} more</TinyHeading>}
      {!!showInterface && <DetailHeading uppercase={false}>Click For Details</DetailHeading>}
    </div>
  );
}

export default function ChartTooltip(props) {
  const {
    label,
    active,
    payload: payloads,
    clickMore = true,
    maxVisibleItems = 6,
    isCompact,
    timeFormat,
  } = props;
  const filteredItems = compact(
    filter(payloads, ({ payload, dataKey }) => !helpers.isUnknownValue(payload[dataKey])),
  );
  const totalSum = sumBy(filteredItems, ({ payload, dataKey }) => payload[dataKey]);

  const visibleItems = compact(filteredItems.slice(0, maxVisibleItems));
  const numHiddenItems = (filteredItems?.length || 0) - (visibleItems?.length || 0);
  const hasContent = !!(active && visibleItems.length);

  return (
    <ChartTooltipContainer>
      <ChartToolTipHeading isCompact={isCompact} timeFormat={timeFormat}>
        {label}
      </ChartToolTipHeading>
      {hasContent && (
        <>
          <div className="py-3 px-4">
            <div
              className={cx(
                visibleItems.length > 1 && ['grid grid-cols-2', isCompact ? 'gap-1' : 'gap-2'],
              )}
            >
              {visibleItems.map(({ payload, name, dataKey, color }, i) => {
                const value = payload[dataKey];
                return (
                  <ChartTootipItem
                    color={color}
                    key={dataKey}
                    label={name}
                    value={value}
                    isCompact={isCompact}
                    timeFormat={timeFormat}
                  />
                );
              })}
            </div>
          </div>
          {!!(totalSum && clickMore) && <ChartTooltipClickMore numHiddenItems={numHiddenItems} />}
        </>
      )}
    </ChartTooltipContainer>
  );
}
