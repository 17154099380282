import { useParams } from 'react-router-dom';
import { useInterval } from 'react-use';

import { Card, Button, CopyButton, Spinner, Text, StatusIndicator } from '@optra/kit';

import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useDeviceSsh, useCurrentUser } from 'queries';

const TUNNEL_HEARTBEAT_LENGTH = 60000;

// TODO: Error handling
export default function SSHController() {
  const icon = 'Terminal';
  const { deviceId } = useParams();
  const { handleClose } = useModalContext();
  const [currentUser] = useCurrentUser();

  const { data, isLoading } = useDeviceSsh(deviceId, {
    refetchInterval: 10000,
  });
  const device = data?.device;

  const qc = q.useQueryClient();
  const openTunnel = q.useMutation({
    mutationFn: () =>
      api(
        `mutation openDeviceTunnel($form: openDeviceTunnelForm!) {
          tunnel: openDeviceTunnel(form: $form) {
            id
            open
          }
        }`,
        { form: { deviceId, type: 'ssh' } },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['device', deviceId, 'ssh'] });
    },
  });

  const closeTunnel = q.useMutation({
    mutationFn: id =>
      api(
        `mutation closeDeviceTunnel($id: ID!) {
          tunnel: closeDeviceTunnel(id: $id) {
            id
            open
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['device', deviceId, 'ssh'] });
    },
  });

  const refreshTunnel = q.useMutation({
    mutationFn: id =>
      api(
        `mutation refreshDeviceTunnel($id: ID!) {
          tunnel: refreshDeviceTunnel(id: $id) {
            id
            open
          }
        }`,
        { id },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['device', deviceId, 'ssh'] });
    },
  });

  const refresh = () => {
    if (device?.tunnel?.id && isOpen) {
      refreshTunnel.mutate(device?.tunnel?.id);
    }
  };

  useInterval(refresh, TUNNEL_HEARTBEAT_LENGTH);

  const isWaiting = isLoading || openTunnel.isPending || closeTunnel.isPending;
  const remainingTime = (device?.tunnel?.expiresAt || 0) - Date.now();
  const totalTime = (device?.tunnel?.expiresAt || 0) - (device?.tunnel?.createdAt || 0);
  const progressUntilClose = device?.tunnel?.open
    ? Math.max(remainingTime / Math.max(totalTime, 1), 0)
    : 0;
  const isOpen = !openTunnel.isPending && device?.tunnel?.open && progressUntilClose > 0;

  const [, tunnelAddress, tunnelPort] = device?.tunnel?.url?.split?.(':') || [];
  const tunnelCommand = `ssh root@${tunnelAddress?.replace?.('//', '') || '<address>'} -p ${
    tunnelPort || '<port>'
  }`;
  const isCreator = device?.tunnel?.creatorId === currentUser?.id;

  return (
    <ModalInner>
      <ModalTitle
        loading={isLoading}
        title="Control Panel"
        subTitle="Embedded Web Server"
        icon={isOpen || openTunnel.isPending ? null : icon}
        showProgress={isOpen || openTunnel.isPending}
        progress={progressUntilClose}
        handleClose={e => {
          // NOTE: Leaving tunnel open on modal close as per https://3.basecamp.com/3926363/buckets/25008943/todos/5440625041#__recording_5501504633
          // if (!closeTunnel.isLoading && device?.tunnel?.id && isCreator) {
          //   closeTunnel.mutate(device?.tunnel?.id);
          // }
          handleClose(e);
        }}
      />
      <ModalBody>
        <Card variant="secondary">
          {isLoading && <Spinner size="lg" className="m-auto" />}
          {!isLoading && !isOpen && (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center space-x-2">
                <StatusIndicator size="md" status="disabled" />
                <Text variant="label">
                  Tunnel: <Text color="muted">Closed</Text>
                </Text>
              </div>

              <Button
                size="xs"
                variant="tertiary"
                icon="Check"
                disabled={isWaiting}
                loading={openTunnel.isPending}
                error={openTunnel.isError ? 'Unable to open tunnel' : null}
                onClick={() => {
                  if (isWaiting) return;
                  openTunnel.mutate();
                }}
              >
                Open
              </Button>
            </div>
          )}
          {!isLoading && isOpen && (
            <>
              <div className="space-y-4">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center space-x-2">
                    <StatusIndicator size="md" status="enabled" />
                    <Text variant="label">
                      Tunnel: <Text color="gradient">Open</Text>
                    </Text>
                  </div>

                  {isCreator && (
                    <Button
                      size="xs"
                      variant="tertiary"
                      icon="X"
                      disabled={isWaiting}
                      loading={closeTunnel.isPending}
                      error={openTunnel.isError ? 'Unable to open tunnel' : null}
                      onClick={() => {
                        if (isWaiting) return;
                        closeTunnel.mutate(device?.tunnel?.id);
                        return;
                      }}
                    >
                      Close
                    </Button>
                  )}
                </div>

                <div className="flex flex-col items-center justify-center space-y-2">
                  <Text color="muted">
                    <span>{tunnelCommand}</span>
                    <CopyButton size="xl" value={tunnelCommand} />
                  </Text>
                </div>
              </div>
            </>
          )}
        </Card>
      </ModalBody>
    </ModalInner>
  );
}
