import flatMap from 'lodash/flatMap';

import { Icon, Text } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';
import { useLibrarySkills } from 'queries';

export default function AdminMarketplaceCreateListing() {
  const { isLoading, data, error, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useLibrarySkills({
      list: {
        filter: {
          excludeUnversioned: true,
        },
      },
    });
  const librarySkills = flatMap(data?.pages, page => page?.list?.data);

  return (
    <ModalInner>
      <ModalTitle title="New Listing" icon="CloudArrowUp" loading={isLoading} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Load Skills">
            {error.message}
          </Message>
        )}

        <List loading={isLoading}>
          {librarySkills.map(librarySkill => (
            <ListItem
              key={librarySkill.id}
              to={`/admin/marketplace/listings/${librarySkill.id}/edit`}
              state={{
                fromModal: true,
              }}
              renderLeft={() => (
                <SkillIcon
                  size="sm"
                  color={librarySkill?.color}
                  icon={librarySkill?.icon}
                  iconUrl={librarySkill?.iconUrl}
                />
              )}
              renderRight={() => <Icon name="CaretRight" weight="line" />}
            >
              <div>
                <Text>{librarySkill.name}</Text>
                <br />
                <Text size="sm" color="muted">
                  {librarySkill.latestVersion.version}
                </Text>
              </div>
            </ListItem>
          ))}
        </List>
        <IntersectionLoadMore
          onVisible={fetchNextPage}
          disabled={isLoading || isFetchingNextPage || !hasNextPage}
        />
      </ModalBody>
    </ModalInner>
  );
}
