import { Button, IconButton } from '@optra/kit';

import Input from 'components/input';
import Select from 'components/select';
import { DATA_TYPES } from 'modals/skill-pipeline-builder/config';

export default function AdminSkillBuilderBetaNodeInputs(props) {
  const { value = '[]', onChange } = props;
  const inputs = JSON.parse(value);

  function handleAddInput() {
    const newInputs = [...inputs, { label: '', key: '', type: '' }];
    onChange(JSON.stringify(newInputs));
  }

  function handleRemoveInput(idx) {
    const newInputs = [...inputs.slice(0, idx), ...inputs.slice(idx + 1)];
    onChange(JSON.stringify(newInputs));
  }

  function handleChangeInput(idx, vals) {
    const newInputs = [
      ...inputs.slice(0, idx),
      {
        ...inputs[idx],
        ...vals,
      },
      ...inputs.slice(idx + 1),
    ];
    onChange(JSON.stringify(newInputs));
  }

  return (
    <>
      {inputs.length < 1 && (
        <div>
          <Button icon="Plus" variant="tertiary" onClick={handleAddInput}>
            Add Input
          </Button>
        </div>
      )}
      {inputs.map((input, idx) => (
        <div className="grid grid-cols-4 gap-2" key={`inputs-${idx}`}>
          <Input
            required
            type="text"
            name="label"
            value={input.label}
            placeholder="Label"
            onChange={event => {
              const label = event?.target?.value;
              const currentKey = input?.key || '';
              const currentLabel = input?.label || '';
              if (
                currentKey === '' ||
                currentKey === currentLabel.toLowerCase().replace(/\s/gi, '_')
              ) {
                handleChangeInput(idx, { label, key: label.toLowerCase().replace(/\s/gi, '_') });
              } else {
                handleChangeInput(idx, { label });
              }
            }}
          />
          <Input
            required
            type="text"
            name="key"
            value={input.key}
            placeholder="Key"
            onChange={event => handleChangeInput(idx, { key: event?.target?.value })}
          />
          <div className="flex flex-row items-center space-x-2">
            <Select
              required
              className="flex-1"
              name="type"
              value={input.type}
              onChange={event => handleChangeInput(idx, { type: event?.target?.value })}
            >
              <option value="">Data Type…</option>
              {Object.values(DATA_TYPES).map(d => (
                <option key={d.key} value={d.key}>
                  {d.label}
                </option>
              ))}
            </Select>
            <IconButton variant="tertiary" name="Plus" onClick={handleAddInput} />
            <IconButton variant="tertiary" name="X" onClick={() => handleRemoveInput(idx)} />
          </div>
        </div>
      ))}
    </>
  );
}
