import { useNavigate } from 'react-router-dom';

import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ListItem from 'modals/skill-pipeline-builder/components/list-item';

export default function AddPipelineNodeProcessor() {
  const navigate = useNavigate();

  return (
    <ModalInner>
      <ModalTitle title="Add Processor" icon="MagicWand" />
      <ModalBody>
        <ListItem
          icon="Database"
          name="Data Processors"
          onClick={() => navigate('../nodes/add-data-processor')}
          className="w-full"
        />
        <ListItem
          icon="Image"
          name="Image Processors"
          onClick={() => navigate('../nodes/add-image-processor')}
          className="w-full"
        />
      </ModalBody>
    </ModalInner>
  );
}
