import cx from 'classnames';

import { Icon, Tooltip, UnreadDot } from '@optra/kit';

import DeviceImage from 'components/device-image';
import { useHasRoles } from 'hooks';

import WorkflowListItem from './workflow-list-item';

function WorkflowDeviceListItem({
  deleted = false,
  dirty = false,
  disabled: _disabled = false,
  incompatibleSkills = [],
  id,
  model,
  name,
  status,
  workflowId,
}) {
  const [canEdit] = useHasRoles(['admin', 'deviceTechnician']);
  const disabled = !!(_disabled || !canEdit);

  const to = workflowId ? `/workflows/${workflowId}/workflow-devices/${id}` : `/devices/${id}`;

  let variant = 'default';
  if (dirty) variant = 'dirty';
  if (deleted) variant = 'deleted';
  if (disabled) variant = 'disabled';

  let statusIcon = null;
  let tooltipLabel = null;
  if (dirty) {
    statusIcon = <Icon name="ArrowsClockwise" size="xs" />;
    tooltipLabel = 'This device has un-synced changes.';
  }
  if (deleted) {
    statusIcon = <Icon name="Trash" size="xs" />;
    tooltipLabel = 'This device is deleted pending sync.';
  }
  if (incompatibleSkills?.length > 0) {
    statusIcon = <UnreadDot />;
    tooltipLabel = 'This device requires a firmware update to be compatible with all skills.';
  }

  return (
    <WorkflowListItem
      variant={variant}
      name={name}
      details={[model?.toUpperCase()]}
      disabled={disabled}
      to={to}
      components={{
        before: (
          <div
            className={cx([
              'w-10 h-10',
              'rounded',
              'flex items-center justify-center',
              'bg-light-bg-secondary dark:bg-dark-bg-tertiary',
            ])}
          >
            <DeviceImage model={model} status={status} size="sm" />
          </div>
        ),
        after: statusIcon && (
          <Tooltip label={tooltipLabel}>
            <span className="leading-none">{statusIcon}</span>
          </Tooltip>
        ),
      }}
    />
  );
}

export default WorkflowDeviceListItem;
