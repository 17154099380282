import { Icon, IconButton, Table, Text } from '@optra/kit';

import { api, q } from 'config/api';

export default function TaggedDeviceListItem({ device, tagId }) {
  const qc = q.useQueryClient();
  const untagDevice = q.useMutation({
    mutationFn: deviceId =>
      api(
        `mutation untagDevice($deviceId: ID! $tagId: ID!) {
          untagDevice(deviceId: $deviceId, tagId: $tagId)
        }`,
        { deviceId, tagId },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['tag', tagId] });
    },
  });

  const handleUntagDevice = deviceId => {
    untagDevice.mutate(deviceId);
  };

  return (
    <Table.Row hover>
      <Table.TD>
        <Icon name="Aperture" color="gradient" className="mr-2" />
        <Text>{device?.name}</Text>
      </Table.TD>
      <Table.TD className="flex justify-end">
        <IconButton
          name="X"
          variant="tertiary"
          loading={untagDevice.isPending}
          onClick={() => handleUntagDevice(device?.id)}
        />
      </Table.TD>
    </Table.Row>
  );
}
