import { Card, Heading } from '@optra/kit';

import Select from 'components/select';

const OPTIONS = [
  {
    key: 'clockwise',
    label: '90º Clockwise',
  },
  {
    key: 'counter_clockwise',
    label: '90º Counter Clockwise',
  },
  {
    key: 'flip',
    label: '180º Flip',
  },
];

export default function RotateImageElement({ config = {}, onChange = () => false }) {
  function handleChange(event) {
    const value = event.target.value;
    onChange({ key: 'orientation', value });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Rotation Direction</Heading>
      <Card variant="secondary" className="space-y-2">
        <Select onChange={handleChange}>
          {OPTIONS.map(option => (
            <option value={option.key} key={option.key}>
              {option.label}
            </option>
          ))}
        </Select>
      </Card>
    </div>
  );
}
