import { isEmpty } from 'lodash';
import * as uuid from 'uuid';

import { Card, Heading } from '@optra/kit';

import VideoSelector from 'modals/skill-pipeline-builder/components/video-selector';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function StaticVideoPipelineInput(props) {
  const { nodeId } = props || {};
  const [node, { update }] = usePipelineNode(nodeId);
  const current = node.meta?.videos?.at(0);

  function handleChangeVideo(video, ext, fps) {
    if (video) {
      handleAddVideo(video, ext, fps);
    } else {
      handleRemoveVideo();
    }
  }

  function handleAddVideo(video, ext, fps) {
    const videoId = !isEmpty(current?.videoId) ? current.videoId : uuid.v4();
    update({
      key: 'config.file_path',
      value: `file:///app/skill/config/${videoId}.${ext}`,
    });
    update({
      key: 'meta',
      value: {
        videos: [
          {
            video,
            videoId,
            ext,
          },
        ],
      },
    });
  }

  function handleRemoveVideo() {
    update({
      key: 'config.file_path',
      value: null,
    });
    update({
      key: 'meta',
      value: !isEmpty(current?.videoId)
        ? {
            videos: [
              {
                videoId: current.videoId,
              },
            ],
          }
        : null,
    });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Video File</Heading>
      <Card variant="secondary" className="flex flex-col items-center justify-center">
        <VideoSelector value={current?.video} onChange={handleChangeVideo} />
      </Card>
    </div>
  );
}
