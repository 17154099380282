import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'UserViewOutput',
  label: 'Device HDMI',
  icon: 'Monitor',
  description: "Outputs the pipeline visuals via an Optra device's HDMI port.",
  type: 'outputs',
  inputs: [
    {
      label: 'Source Image',
      key: 'image',
      type: DATA_TYPES['Image'],
      required: true,
    },
  ],
  outputs: [],
  schema: z.object({
    inputs: z.object({ image: z.string().min(1) }),
  }),
};
