import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  mapScriptLoaded: false,
  userColorScheme: 'auto',
  appliedColorScheme: null,
};
const { useGlobalState } = createGlobalState(initialState);

export default useGlobalState;
