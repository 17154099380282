import cx from 'classnames';

import { DetailHeading, Dropdown } from '@optra/kit';

import { TIME_DIMENSIONS } from 'modals/chart-details/data';

export default function VariableSelectorTime(props) {
  const { value, disableVariables, variableAlreadyInUse, onClick } = props;
  const list = Object.values(TIME_DIMENSIONS);
  return (
    <Dropdown.MenuBody divide={false} scrolling className="p-5">
      <DetailHeading className="px-3 mb-4 float-left">TIME</DetailHeading>
      {list.map(({ name, type, selectable }, i) => {
        const active = type === value;
        const inUse = disableVariables && variableAlreadyInUse(type);
        const disabled = inUse;
        if (!selectable) return null;
        return (
          <Dropdown.Item
            key={`${type}_${i}`}
            text={name}
            uppercase={false}
            className={cx('rounded-md', disabled && !active && 'opacity-30')}
            disabled={disabled}
            onClick={disabled ? null : () => onClick(type)}
            active={active}
          />
        );
      })}
    </Dropdown.MenuBody>
  );
}
