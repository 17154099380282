import { api, q } from 'config/api';

export default function useWorkflowsLatestDeployment(id, overrides) {
  return q.useQuery({
    queryKey: ['workflow', id, 'latestDeployment'],
    queryFn: () =>
      api(
        `query workflowsLatestDeployment($id: ID!) {
          workflow: workflow(id: $id) {
            id
            name
            latestDeployment {
              id
              status
              totalTasks
              failedTasks
              completedTasks
              completedAt
              progress
            }
          }
        }`,
        { id },
      ),
    refetchInterval: 2000,
    enabled: !!id,
    ...overrides,
  });
}
