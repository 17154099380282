import cx from 'classnames';

import { ErrorBoundary } from '@optra/kit';

const SIZES = {
  sm: ['md:min-w-modal-sm', 'md:max-w-modal-sm'],
  lg: ['lg:min-w-modal-lg', 'max-w-modal-lg'],
};

export default function ModalBody(props) {
  const { as = 'div', children, size = 'sm', className, noPadding, ...rest } = props;
  const Wrapper = as;
  return (
    <div className={cx('overflow-y-auto overflow-x-hidden flex-grow', SIZES[size])}>
      <Wrapper className={cx([noPadding ? '' : 'p-4', SIZES[size], className])} {...rest}>
        <ErrorBoundary center={false} fallbackClassName="my-32">
          {children}
        </ErrorBoundary>
      </Wrapper>
    </div>
  );
}
