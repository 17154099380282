import { isEmpty, isFunction, isString, union } from 'lodash';

import labelBehaviors from 'modals/skill-pipeline-builder/config/node-label-behaviors';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import getNodeDef from 'modals/skill-pipeline-builder/context/get-node-def';

function outputProducesLabels(output) {
  return isString(output?.labelBehavior) || isFunction(output?.labelBehavior);
}

function callLabelsBehavior(node, output, existingNodeLabels) {
  if (isFunction(output?.labelBehavior)) {
    return output.labelBehavior(node, output, existingNodeLabels);
  }

  if (isString(output?.labelBehavior) && isFunction(labelBehaviors[output.labelBehavior])) {
    return labelBehaviors[output.labelBehavior]()(node, output, existingNodeLabels);
  }

  return [];
}

export function useLabelsCache() {
  const { pipeline } = usePipelineContext();
  const allNodes = Object.keys(pipeline.pipeline).map(nodeId => ({
    id: nodeId,
    ...pipeline.pipeline[nodeId],
  }));

  // first create a map of all nodes+outputs
  // reduce + Object.fromEntries + map benchmarks about ~50% faster on chrome
  const labelsCache = allNodes.reduce((agg, node) => {
    const def = getNodeDef(node);
    agg[node.id] = Object.fromEntries((def.outputs || []).map(output => [output.key, []]));
    return agg;
  }, {});

  // now populate those output/label arrays
  // TODO: look into reeling this logic into reducer (logic is dependent on order so agg should be enough to fill existingNodeLabels)
  allNodes.forEach(node => {
    const nodeDef = getNodeDef(node);

    nodeDef.outputs.forEach(output => {
      if (outputProducesLabels(output)) {
        const existingNodeLabels = labelsCache[node.id][output.key];
        const nodeLabels = callLabelsBehavior(node, output, existingNodeLabels); //union(existingNodeLabels, newNodeLabels);
        labelsCache[node.id][output.key] = nodeLabels;

        // find any inputs pointing at this node
        const key = `${node.id}.${output.key}`;
        const dependencies = allNodes
          .map(n => {
            if (Object.values(n.inputs).some(i => i === key)) {
              return n;
            }
            return null;
          })
          .filter(dep => !isEmpty(dep));

        dependencies.forEach(dep => {
          const depDef = getNodeDef(dep);
          depDef.outputs.forEach(depOutput => {
            if (outputProducesLabels(depOutput)) {
              const existingDepLabels = labelsCache[dep.id][depOutput.key];
              labelsCache[dep.id][depOutput.key] = union(existingDepLabels, nodeLabels);
            }
          });
        });
      }
    });
  });

  return labelsCache;
}

export default function useInputLabels(node, inputKey) {
  const labelsCache = useLabelsCache();

  if (
    isEmpty(node) ||
    isEmpty(inputKey) ||
    isEmpty(node.inputs) ||
    isEmpty(node.inputs[inputKey]) ||
    !isString(node.inputs[inputKey])
  ) {
    return [];
  }

  const [parentNodeId, outputKey] = node.inputs[inputKey].split('.');
  return labelsCache?.[parentNodeId]?.[outputKey] || [];
}
