import { Routes, Route } from 'react-router-dom';

import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ProfilePaymentMethods from 'components/profile-payment-methods';
import AddCreditsEditCard from 'modals/add-credits-edit-card';
import AddCreditsNewCard from 'modals/add-credits-new-card';

function CreditsSettings() {
  return (
    <ModalInner>
      <ModalTitle title="Settings" icon="Gear" />
      <ModalBody>
        <ProfilePaymentMethods addCreditCardPath="new-card" editCreditCardRootPath="edit-card" />
      </ModalBody>
    </ModalInner>
  );
}

export default function CreditsSettingsRoutes() {
  return (
    <Routes>
      <Route index element={<CreditsSettings />} />
      <Route path="edit-card/:paymentMethodId" element={<AddCreditsEditCard />} />
      <Route path="new-card" element={<AddCreditsNewCard />} />
    </Routes>
  );
}
