import { Draggable } from 'react-beautiful-dnd';

import { Group } from '@optra/kit';

import { useChartCtx } from '../context';

import { AddItemDropdown, DraggableItem, DroppableZone } from './fragments';
import VariableSelector from './variable-selector';

export default function ControlDataGrouping(props) {
  const {
    heading = 'Data Grouping',
    description = 'Select variables to split out the primary metric',
    collapsible = true,
    scrollable = false,
    defaultCollapsed = false,
    ...rest
  } = props;
  const {
    actions: { replaceVariable, removeVariable },
    helpers: { getChartConfig },
  } = useChartCtx();

  const { yAxis } = getChartConfig();

  const hasVariables = !!yAxis?.length;

  const field = 'yAxis';
  const count = yAxis?.length;

  return (
    <Group
      heading={heading}
      description={description}
      count={count}
      collapsible={collapsible}
      scrollable={scrollable}
      defaultCollapsed={defaultCollapsed}
      {...rest}
    >
      <DroppableZone droppableId={field}>
        {({ placeholder, ...droppableProps }) => (
          <div {...droppableProps}>
            {hasVariables &&
              yAxis.map((variable, index) => (
                <Draggable key={variable} draggableId={variable} index={index}>
                  {(provided, snapshot) => (
                    <VariableSelector
                      value={variable}
                      onSelect={id => replaceVariable(variable, id, field)}
                    >
                      <VariableSelector.Button>
                        <DraggableItem
                          variable={variable}
                          draggableProps={{ provided, snapshot }}
                          onRemove={() => removeVariable(variable, field)}
                        />
                      </VariableSelector.Button>
                    </VariableSelector>
                  )}
                </Draggable>
              ))}
            {placeholder}
            <AddItemDropdown field={field} />
          </div>
        )}
      </DroppableZone>
    </Group>
  );
}
