import { Controller } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { Card, Button } from '@optra/kit';

import IconChooser from 'components/icon-chooser';
import Input from 'components/input';
import Label from 'components/label';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';

export default function PublishSkillIcon(props) {
  const [form, skill] = useOutletContext();
  const { handleClose } = useModalContext();

  return (
    <ModalInner>
      <ModalTitle title="Change Name or Icon" icon="CloudArrowUp" />
      <ModalBody className="space-y-4">
        <Card variant="secondary" className="space-y-4">
          <div className="space-y-4">
            <div className="space-y-2">
              <Controller
                name="icon"
                control={form.control}
                rules={{
                  required: 'Please choose an icon.',
                }}
                render={({ field, fieldState }) => (
                  <IconChooser {...field} loading={skill.isLoading} />
                )}
              />
              <ValidationError name="icon" errors={form.formState.errors} />
            </div>
          </div>
        </Card>

        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              {...form.register('name', {
                required: 'Please enter a name',
              })}
              readOnly={skill.isLoading}
            />
            <ValidationError name="name" errors={form.formState.errors} />
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button size="xl" onClick={handleClose}>
          Ok
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
