import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { DetailList, Table, Avatar, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Message from 'components/message';

export default function AdminUsersList(props) {
  const { error, users = [] } = props;
  const navigate = useNavigate();

  if (error) {
    return (
      <Message variant="danger" title="Couldn't Load Users">
        {error.message}
      </Message>
    );
  }

  if (isEmpty(users)) {
    return <EmptyContentMessage icon="UserCircle" title="No Users" />;
  }

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TH />
          <Table.TH>Name</Table.TH>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {(users || []).map(user => {
          return (
            <Table.Row key={user.id} hover onClick={() => navigate(`/admin/users/${user.id}/edit`)}>
              <Table.TD collapsed>
                <Avatar
                  src={user?.image}
                  size="xs"
                  className="shadow-sm border border-light-bg-secondary dark:border-dark-fg-tertiary"
                />
              </Table.TD>
              <Table.TD>
                <Text size="sm">{user?.email}</Text>
                <DetailList details={[user?.name || 'Unknown Name']} />
              </Table.TD>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
