import { api, q } from 'config/api';

export default function useSkillBuilderAddMenu() {
  return q.useQuery({
    queryKey: ['skillBuilderAddMenu'],
    queryFn() {
      return api(
        `query skillBuilderAddMenu {
          skillBuilderRecipes {
            data {
              id
              name
              description
              config
              iconUrl
              icon
              color
            }
          }
          skillBuilderBetaNodes {
            data {
              id
              name
              label
              description
              icon
              type
              inputs
              outputs
              configFields
            }
          }
        }`,
      );
    },
    placeholderData: q.keepPreviousData,
  });
}
