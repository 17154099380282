import { truncate } from 'lodash';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import { Card, Text, Heading } from '@optra/kit';

import MarketplaceSkillPrivilegeCards from 'components/marketplace-skill-privilege-cards';

export default function MarketplaceSkillListing({ marketplaceSkill = {} }) {
  return (
    <>
      {marketplaceSkill?.screenshotUrls?.length > 0 && (
        <div className="overflow-y-hidden overflow-x-scroll flex space-x-3">
          {marketplaceSkill?.screenshotUrls?.map((screenshotUrl, index) => (
            <Link
              to={`./gallery?index=${index}`}
              key={`screenshot-preview-${marketplaceSkill?.id}-${index}`}
              className="bg-light-fg-tertiary dark:bg-dark-fg-secondary flex-shrink-0"
              style={{ width: 480, height: 300 }}
            >
              <img src={screenshotUrl} width={480} height={300} alt="" />
            </Link>
          ))}
        </div>
      )}

      <div className="space-y-3">
        {marketplaceSkill?.summary && (
          <Text className="block font-bold">
            {truncate(marketplaceSkill?.summary, { length: 80 })}
          </Text>
        )}

        <div className="text-content">
          <ReactMarkdown children={marketplaceSkill?.description} />
        </div>
      </div>

      <Card variant="secondary" className="space-y-3">
        <Heading>Latest Version</Heading>

        <Text color="muted" className="block font-bold">
          {marketplaceSkill?.latestRecommendedVersion?.version}
        </Text>

        <Text color="muted" className="block">
          {marketplaceSkill?.latestRecommendedVersion?.releaseNotes}
        </Text>
      </Card>

      <MarketplaceSkillPrivilegeCards marketplaceSkill={marketplaceSkill} wide />

      <div className="grid lg:grid-cols-2 space-y-6 lg:space-y-0">
        <div>
          <Text variant="label" className="block mb-3">
            About This Skill
          </Text>

          <div className="grid grid-cols-2 gap-2 space-y-1">
            <Text size="sm" color="muted">
              Price
            </Text>
            <Text size="sm" className="font-bold">
              FREE
            </Text>

            {marketplaceSkill?.licenseUrl && (
              <>
                <Text size="sm" color="muted">
                  License
                </Text>
                <Text size="sm" color="muted">
                  <a href={marketplaceSkill?.licenseUrl} target="_blank" rel="noreferrer">
                    <Text size="sm" className="underline">
                      View License File
                    </Text>
                  </a>
                </Text>
              </>
            )}
          </div>
        </div>

        <div>
          <Text variant="label" className="block mb-3">
            About The Developer
          </Text>

          <div className="grid grid-cols-2 gap-2 space-y-1">
            <Text size="sm" color="muted">
              Name
            </Text>
            <Text size="sm" className="font-bold">
              {marketplaceSkill?.developerProfile?.name}
            </Text>
            {marketplaceSkill?.developerProfile?.website && (
              <>
                <Text size="sm" color="muted">
                  Website
                </Text>
                <Text size="sm" color="muted">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={marketplaceSkill?.developerProfile?.website}
                  >
                    {marketplaceSkill?.developerProfile?.website}
                  </a>
                </Text>
              </>
            )}

            {marketplaceSkill?.developerProfile?.privacyPolicy && (
              <>
                <Text size="sm" color="muted">
                  Privacy Policy
                </Text>
                <Text size="sm" color="muted">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={marketplaceSkill?.developerProfile?.privacyPolicy}
                  >
                    {marketplaceSkill?.developerProfile?.privacyPolicy}
                  </a>
                </Text>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
