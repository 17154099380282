import { useParams } from 'react-router-dom';
import { useInterval } from 'react-use';

import { Card, Button, Spinner, Text, StatusIndicator } from '@optra/kit';

import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useDeviceTunnel, useWorkflowSkill, useCurrentUser } from 'queries';

const TUNNEL_HEARTBEAT_LENGTH = 60000;

// TODO: Error handling
export default function SkillWebUI() {
  const { workflowSkillId, deviceId } = useParams();
  const { handleClose } = useModalContext();

  const workflowSkill = useWorkflowSkill(workflowSkillId);
  const tunnel = useDeviceTunnel(
    {
      deviceId,
      workflowSkillId,
    },
    { refetchInterval: 10000 },
  );
  const [currentUser] = useCurrentUser();

  const qc = q.useQueryClient();
  const openTunnel = q.useMutation({
    mutationFn: form =>
      api(
        `mutation openDeviceTunnel($form: openDeviceTunnelForm!) {
          tunnel: openDeviceTunnel(form: $form) {
            id
            open
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['device', deviceId, workflowSkillId, 'tunnel'] });
    },
  });

  const closeTunnel = q.useMutation({
    mutationFn: id =>
      api(
        `mutation closeDeviceTunnel($id: ID!) {
          tunnel: closeDeviceTunnel(id: $id) {
            id
            open
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['device', deviceId, workflowSkillId, 'tunnel'] });
    },
  });

  const refreshTunnel = q.useMutation({
    mutationFn: id =>
      api(
        `mutation refreshDeviceTunnel($id: ID!) {
          tunnel: refreshDeviceTunnel(id: $id) {
            id
            open
          }
        }`,
        { id },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['device', deviceId, workflowSkillId, 'tunnel'] });
    },
  });

  const refresh = () => {
    if (tunnel.data?.tunnel?.id && isOpen) {
      refreshTunnel.mutate(tunnel.data?.tunnel?.id);
    }
  };

  useInterval(refresh, TUNNEL_HEARTBEAT_LENGTH);

  const tunnelUrl = `https://${tunnel.data?.tunnel?.url}`;

  const isWaiting = tunnel.isLoading || openTunnel.isPending || closeTunnel.isPending;
  const remainingTime = (tunnel.data?.tunnel?.expiresAt || 0) - Date.now();
  const totalTime = (tunnel.data?.tunnel?.expiresAt || 0) - (tunnel.data?.tunnel?.createdAt || 0);
  const progressUntilClose = tunnel.data?.tunnel?.open
    ? Math.max(remainingTime / Math.max(totalTime, 1), 0)
    : 0;
  const isOpen = !openTunnel.isPending && tunnel.data?.tunnel?.open && progressUntilClose > 0;
  const isCreator = tunnel?.data?.tunnel?.creatorId === currentUser?.id;

  return (
    <ModalInner>
      <ModalTitle
        loading={workflowSkill.isLoading || tunnel.isLoading}
        title={workflowSkill.data?.workflowSkill?.name}
        subTitle="Web UI"
        icon={isOpen || openTunnel.isPending ? null : 'Globe'}
        showProgress={isOpen || openTunnel.isPending}
        progress={progressUntilClose}
        handleClose={e => {
          // NOTE: Leaving tunnel open on modal close as per https://3.basecamp.com/3926363/buckets/25008943/todos/5440625041#__recording_5501504633
          // if (!closeTunnel.isLoading && device?.tunnel?.id && isCreator) {
          //   closeTunnel.mutate(device?.tunnel?.id);
          // }
          handleClose(e);
        }}
      />
      <ModalBody>
        <Card variant="secondary">
          {tunnel.isLoading && <Spinner size="lg" className="m-auto" />}
          {!tunnel.isLoading && !isOpen && (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center space-x-2">
                <StatusIndicator size="md" status="disabled" />
                <Text variant="label">
                  Tunnel: <Text color="muted">Closed</Text>
                </Text>
              </div>

              <Button
                size="xs"
                variant="tertiary"
                icon="Check"
                disabled={isWaiting}
                loading={openTunnel.isPending}
                error={openTunnel.isError ? 'Unable to open tunnel' : null}
                onClick={() => {
                  if (isWaiting) return;
                  openTunnel.mutate({ workflowSkillId, deviceId, type: 'workflowSkill' });
                }}
              >
                Open
              </Button>
            </div>
          )}
          {!tunnel.isLoading && isOpen && (
            <>
              <div className="space-y-4">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center space-x-2">
                    <StatusIndicator size="md" status="enabled" />
                    <Text variant="label">
                      Tunnel: <Text color="gradient">Open</Text>
                    </Text>
                  </div>

                  {isCreator && (
                    <Button
                      size="xs"
                      variant="tertiary"
                      icon="X"
                      disabled={isWaiting}
                      loading={closeTunnel.isPending}
                      error={openTunnel.isError ? 'Unable to open tunnel' : null}
                      onClick={() => {
                        if (isWaiting) return;
                        closeTunnel.mutate(tunnel.data?.tunnel?.id);
                        return;
                      }}
                    >
                      Close
                    </Button>
                  )}
                </div>

                <div className="flex flex-col items-center justify-center space-y-2">
                  <a href={tunnelUrl} target="_blank" rel="noreferrer">
                    <Text color="muted">{tunnelUrl}</Text>
                  </a>
                </div>
              </div>
            </>
          )}
        </Card>
      </ModalBody>
      {isOpen && (
        <ModalFooter>
          <Button
            size="xl"
            href={tunnelUrl}
            target="_blank"
            rel="noreferrer"
            iconRight="ArrowSquareOut"
          >
            Open Web UI
          </Button>
        </ModalFooter>
      )}
    </ModalInner>
  );
}
