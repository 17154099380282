import { startCase, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Icon, Spinner, Text } from '@optra/kit';

import DeviceImage from 'components/device-image';
import TD from 'components/td';
import TR from 'components/tr';
import dateLabel from 'lib/date-label';

export function titleText({ type }) {
  switch (type) {
    case 'deviceFirmwareUpdated':
      return 'Firmware Updated';
    case 'deviceConnectionStatusConnected':
      return 'Device Connected';
    case 'deviceConnectionStatusDisconnected':
      return 'Device Disconnected';
    case 'devicesAdded':
      return 'Multiple Devices Added';
    case 'createDevicesFailed':
      return 'Multiple Device Enrollment Failed';
    case 'importWorkflowConfigFailed':
      return 'Importing Workflow Config Failed';
    case 'importedWorkflowConfig':
      return 'Workflow configuration applied';
    default:
      return startCase(type);
  }
}

export function messageText({ type, payload }) {
  switch (type) {
    case 'workflowSynced':
      return `"${payload?.name || 'Workflow'}" has completed syncing.`;
    case 'workflowSyncFailed': {
      const errorText = payload?.error?.slice?.(0, 64);
      return `Some devices failed to sync in "${payload?.workflow?.name || 'a workflow'}." ${
        errorText ? `Error: ${errorText}` : ''
      }`;
    }
    case 'deviceFirmwareUpdated':
      return `"${payload.name}" was updated to ${payload?.firmware?.version}.`;
    case 'deviceConnectionStatusConnected':
      return `"${payload.name}" has reconnected.`;
    case 'deviceConnectionStatusDisconnected':
      return `"${payload.name}" has lost connection.`;
    case 'deviceAdded':
      return `"${payload.name}" was created.`;
    case 'deviceRemoved':
      return `"${payload.name}" was permanently deleted.`;
    case 'devicePing': {
      if (payload.error) {
        return `"${payload.device?.name}" ping failed. ${payload.error}`;
      }
      return `"${payload.device?.name}" was pinged successfully.`;
    }
    case 'createDevicesFailed':
      return payload.map((err, idx) => <Text key={idx}>{err} </Text>);
    case 'devicesAdded':
      return (
        <>
          <Text className="block">Multiple devices were created.</Text>
          {payload?.length > 0 && <Text>Warning: {payload.join(',\n')}</Text>}
        </>
      );
    case 'manifestSynced':
      return `"${payload.name}" has completed syncing.`;
    case 'manifestSyncFailed':
      return `"${payload.device?.name}" failed to sync. ${
        isEmpty(payload?.error) ? '' : payload.error
      }`;
    case 'importWorkflowConfigFailed':
      return payload.map((err, idx) => <Text key={idx}>{err} </Text>);
    default:
      return '';
  }
}

export function pathFor({ referrerId, type }) {
  switch (type) {
    case 'workflowSyncFailed':
    case 'workflowSynced': {
      return `/workflows?id=${referrerId}`;
    }
    case 'deviceConnectionStatusConnected':
    case 'deviceConnectionStatusDisconnected':
    case 'deviceFirmwareUpdated':
    case 'deviceAdded':
    case 'deviceRemoved':
    case 'devicePing': {
      return `/devices/${referrerId}`;
    }
    case 'manifestSyncFailed':
    case 'manifestSynced': {
      return `/devices/${referrerId}`;
    }
    default: {
      return null;
    }
  }
}

export function iconFor({ type }) {
  switch (type) {
    case 'loading':
      return <Spinner color="gradient" />;
    case 'importWorkflowConfigFailed':
    case 'importedWorkflowConfig':
    case 'workflowSyncFailed':
    case 'workflowSynced': {
      return <Icon name="GitBranch" color="neon" size="md" weight="duotone" />;
    }
    case 'manifestSyncFailed':
    case 'manifestSynced': {
      return <Icon name="Aperture" color="neon" size="md" weight="duotone" />;
    }
    case 'deviceConnectionStatusConnected':
    case 'deviceConnectionStatusDisconnected':
    case 'deviceFirmwareUpdated':
    case 'deviceAdded':
    case 'devicesAdded':
    case 'deviceRemoved':
    case 'devicePing': {
      return <DeviceImage model="cx1000" size="sm" />;
    }
    case 'createDevicesFailed': {
      return <Icon name="Warning" color="neon" size="md" weight="duotone" />;
    }
    default: {
      return null;
    }
  }
}

export default function NotificationListItem({ notification, onClick = () => false }) {
  const { referrerId, type, payload, createdAt } = notification || {};
  const navigate = useNavigate();

  const path = pathFor({ referrerId, type });

  return (
    <TR
      onClick={() => {
        path && navigate(path);
        onClick();
      }}
      style={{
        opacity: notification?.read ? 0.6 : 1,
        cursor: path ? 'pointer' : 'default',
      }}
    >
      <TD>
        <div className="flex items-center justify-center w-8 h-8">{iconFor({ type })}</div>
      </TD>

      <TD wrap>
        <Text>{titleText({ type })}</Text>
      </TD>

      <TD wrap className="hidden md:table-cell">
        <Text size="xs" color="muted">
          {messageText({
            type,
            payload,
          })}
        </Text>
      </TD>

      <TD className="hidden md:table-cell">
        <Text size="xs" color="muted">
          {dateLabel(createdAt)}
        </Text>
      </TD>
    </TR>
  );
}
