import { last, omit, isEmpty } from 'lodash';

import { api, q } from 'config/api';
import isFile from 'lib/is-file';
import { useSignedUpload } from 'queries';

async function updateIcon({ skillId, iconUrl: iconFile, uploadIcon }) {
  let iconUrl = null;
  if (iconFile instanceof File) {
    const uploadedIcon = await uploadIcon(iconFile, {
      extension: last(iconFile.name.split('.')),
      name: `${skillId}-icon`,
    });
    iconUrl = uploadedIcon.url;
  }

  return api(
    `mutation updateSkill($form: updateSkillForm!) {
      skill: updateSkill(form: $form) {
        id
      }
    }`,
    { form: { id: skillId, iconUrl } },
  );
}

async function uploadMediaAssets({ pipeline, uploadImageInput, uploadVideoInput }) {
  const images = Object.entries(pipeline.pipeline || {}).flatMap(([nodeId, v]) => {
    if (v?.meta?.images?.length > 0) {
      return v.meta.images.map(img => ({ ...img, nodeId }));
    }
    return [];
  });

  const videos = Object.entries(pipeline.pipeline || {}).flatMap(([nodeId, v]) => {
    if (v?.meta?.videos?.length > 0) {
      return v.meta.videos.map(img => ({ ...img, nodeId }));
    }
    return [];
  });

  const uploadedImages = await Promise.all(
    images
      .filter(({ image }) => isFile(image))
      .map(async ({ image, imageId, ext, nodeId }) => {
        const { url } = await uploadImageInput(image, {
          extension: ext,
          name: imageId,
        });
        return { nodeId, id: imageId, url, type: 'image' };
      }),
  );

  const uploadedVideos = await Promise.all(
    videos
      .filter(({ video }) => isFile(video))
      .map(async ({ video, videoId, ext, nodeId }) => {
        const { url } = await uploadVideoInput(video, {
          extension: ext,
          name: videoId,
        });
        return { nodeId, id: videoId, url, type: 'video' };
      }),
  );

  const uploadedAssets = [...uploadedImages, ...uploadedVideos];

  const updatedPipeline = uploadedAssets.reduce((acc, { nodeId, id, url, type }) => {
    if (type === 'image') {
      const currentImages = acc[nodeId].meta.images;
      const currentImageIdx = currentImages.findIndex(img => img.imageId === id);
      const currentImage = currentImages[currentImageIdx];
      const nextImages = [
        ...currentImages.slice(0, currentImageIdx),
        {
          ...currentImage,
          image: url,
        },
        ...currentImages.slice(currentImageIdx + 1),
      ];

      return {
        ...acc,
        [nodeId]: {
          ...pipeline.pipeline[nodeId],
          meta: {
            ...pipeline.pipeline[nodeId].meta,
            images: nextImages,
          },
        },
      };
    } else if (type === 'video') {
      const currentVideos = acc[nodeId].meta.videos;
      const currentVideoIdx = currentVideos.findIndex(vid => vid.videoId === id);
      const currentVideo = currentVideos[currentVideoIdx];
      const nextVideos = [
        ...currentVideos.slice(0, currentVideoIdx),
        {
          ...currentVideo,
          video: url,
        },
        ...currentVideos.slice(currentVideoIdx + 1),
      ];

      return {
        ...acc,
        [nodeId]: {
          ...pipeline.pipeline[nodeId],
          meta: {
            ...pipeline.pipeline[nodeId].meta,
            videos: nextVideos,
          },
        },
      };
    }
    return null;
  }, pipeline.pipeline);

  return updatedPipeline;
}

export default function useSkillBuilder(props) {
  const { skillVersionId, ...queryOverrides } = props;
  const [uploadIcon, uploadIconState] = useSignedUpload({ type: 'skillImage' });
  const [uploadImageInput, uploadImageState] = useSignedUpload({ type: 'skillBuilderImageInput' });
  const [uploadVideoInput, uploadVideoState] = useSignedUpload({ type: 'skillBuilderVideoInput' });
  const qc = q.useQueryClient();
  const upsert = q.useMutation({
    mutationFn: async form => {
      const { skill, ..._versionForm } = form;
      const skillForm = omit(skill, 'iconUrl');
      const skillId = skill?.id;

      // Upload Assets
      const updatedPipeline = await uploadMediaAssets({
        pipeline: _versionForm.pipeline,
        uploadImageInput,
        uploadVideoInput,
      });

      const versionForm = {
        ..._versionForm,
        pipeline: {
          ..._versionForm.pipeline,
          pipeline: updatedPipeline,
        },
      };

      // Completely new
      if (isEmpty(form.id) && isEmpty(form.skill.id)) {
        const result = await api(
          `mutation createSkillBuilderSkillVersion($form: createSkillBuilderSkillVersionForm!) {
            version: createSkillBuilderSkillVersion(form: $form) {
              id
              pipeline
              skill {
                id
              }
            }
          }`,
          { form: { ...versionForm, skill: skillForm } },
        );

        await updateIcon({
          skillId: result?.version?.skill?.id,
          iconUrl: form.skill.iconUrl,
          uploadIcon,
        });

        return result;
      }

      // Same skill, new version
      if (isEmpty(form.id)) {
        await updateIcon({ skillId, iconUrl: form.skill.iconUrl, uploadIcon });
        const result = await api(
          `mutation createSkillBuilderSkillVersion($versionForm: createSkillBuilderSkillVersionForm!, $skillForm: updateSkillForm!) {
            version: createSkillBuilderSkillVersion(form: $versionForm) {
              id
              pipeline
              skill {
                id
              }
            }
            skill: updateSkill(form: $skillForm) {
              id
            }
          }`,
          {
            versionForm: {
              ...versionForm,
              skillId,
            },
            skillForm,
          },
        );

        return result;
      }

      // Same skill, same version
      await updateIcon({ skillId, iconUrl: form.skill.iconUrl, uploadIcon });
      const result = await api(
        `mutation updateSkillBuilderSkillVersion($versionForm: updateSkillBuilderSkillVersionForm!, $skillForm: updateSkillForm!) {
          version: updateSkillBuilderSkillVersion(form: $versionForm) {
            id
            pipeline
            skill {
              id
            }
          }
          skill: updateSkill(form: $skillForm) {
            id
          }
        }`,
        {
          versionForm,
          skillForm,
        },
      );

      return result;
    },
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['skillBuilder', r.version?.id] });
      qc.invalidateQueries({ queryKey: ['skill', r.version?.skill?.id] });
      qc.invalidateQueries({ queryKey: ['skillVersion', r.version?.id] });
      qc.invalidateQueries({ queryKey: ['librarySkills'] });
      qc.invalidateQueries({ queryKey: ['librarySkill', r.version?.skill?.id] });
      qc.invalidateQueries({ queryKey: ['librarySkillVersion', r.version?.id] });
    },
  });

  const skillVersion = q.useQuery({
    queryKey: ['skillBuilder', skillVersionId],
    queryFn: () =>
      api(
        `query skillBuilderVersion($id: ID!) {
          skillVersion(id: $id) {
            id
            version
            pipeline
            targetDeviceFamily
            storage
            hostName
            endpointAliases
            portBindings
            tmpfs
            shmSize
            port
            removableMedia
            hostNetworking
            devices {
              sound
              hdmi
              cameras
            }
            env
            skill {
              name
              icon
              color
              iconUrl
              versions {
                data {
                  id
                  version
                }
              }
            }
          }
        }`,
        { id: skillVersionId },
      ),
    enabled: !isEmpty(skillVersionId),
    ...queryOverrides,
  });

  const saving =
    upsert.isPending ||
    uploadIconState.fetching ||
    uploadImageState.fetching ||
    uploadVideoState.fetching;

  return [
    skillVersion,
    {
      upsert,
      saving,
      uploadIconState,
    },
  ];
}
