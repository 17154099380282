import { api, q } from 'config/api';

export default function useTag(id, overrides) {
  return q.useQuery({
    queryKey: ['tag', id],
    queryFn: () =>
      api(
        `query tag($id: ID!) {
          tag(id: $id) {
            id
            name
            devices {
              data {
                id
                name
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
