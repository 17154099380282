import { api, q } from 'config/api';

export default function useWorkflowsActions(id, variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflow', id, variables, 'actions'],
    queryFn({ pageParam }) {
      return api(
        `query workflowsActions($id: ID!, $list: listFields) {
          workflow(id: $id) {
            id
            list: actions(list: $list) {
              count
              data {
                id
                deleted
                disabled
                synchronized
                isSynchronizing
                action {
                  id
                  name
                }
              }
              cursor {
                after
              }
            }
          }
        }`,
        {
          ...variables,
          id,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.workflow?.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
    enabled: !!id,
    ...overrides,
  });
}
