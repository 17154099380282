import { isEmpty } from 'lodash';
import * as uuid from 'uuid';

import { Card, Heading } from '@optra/kit';

import ImageColorSelector from 'modals/skill-pipeline-builder/components/image-color-selector';
import InputDimensions, {
  IMAGE_SIZES,
} from 'modals/skill-pipeline-builder/components/input-dimensions';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

// TODO: InputDimensions needs some way to clear or set to "auto"
export default function StaticImageElement(props) {
  const { nodeId } = props || {};
  const [node, { update }] = usePipelineNode(nodeId);
  const isColor = Array.isArray(node.config?.imageSrc);
  const current = {
    width: node.config?.imageSize?.[0],
    height: node.config?.imageSize?.[1],
    imgOrColor: (isColor ? node.config?.imageSrc : node.meta?.images?.[0]?.image) ?? null,
  };
  const currentImg = !isColor ? node.meta?.images?.[0] : null;

  function handleChangeColor({ value }) {
    update({
      key: 'config.imageSrc',
      value,
    });
    update({
      key: 'meta',
      value: null,
    });
  }

  function handleChangeImage({ value, ext }) {
    if (value) {
      handleAddImage({ image: value, ext });
    } else {
      handleRemoveImage();
    }
  }

  function handleAddImage({ image, ext }) {
    const imageId = !isEmpty(currentImg?.imageId) ? currentImg.imageId : uuid.v4();
    update({
      key: 'config.imageSrc',
      value: `file:///app/skill/config/${imageId}.${ext}`,
    });
    update({
      key: 'meta',
      value: {
        images: [
          {
            image,
            imageId,
            ext,
          },
        ],
      },
    });
  }

  function handleRemoveImage() {
    update({
      key: 'config.imageSrc',
      value: null,
    });
    update({
      key: 'meta',
      value: !isEmpty(current.imageId)
        ? {
            images: [
              {
                imageId: current.imageId,
              },
            ],
          }
        : null,
    });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Config</Heading>
      <Card variant="secondary">
        <InputDimensions
          value={{ width: current.width, height: current.height }}
          onChange={({ width, height, size }) => {
            update({
              key: 'config.imageSize',
              value: size === IMAGE_SIZES.custom ? [width, height] : null,
            });
          }}
          className="px-4 pt-2 pb-6"
        />
        <ImageColorSelector
          value={current?.imgOrColor}
          onChange={({ type, value, ext }) => {
            if (type === 'color') {
              handleChangeColor({ value });
            } else {
              handleChangeImage({ value, ext });
            }
          }}
        />
      </Card>
    </div>
  );
}
