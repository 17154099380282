import { without, flatMap } from 'lodash';

import { Icon, Spinner } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';
import { useWorkflowsNames } from 'queries';

export default function DevicesFilterWorkflows({ onFilter, filter }) {
  const { data, isLoading: fetching } = useWorkflowsNames();
  const workflows = flatMap(data?.pages, page => page?.list?.data);

  if (fetching) {
    return (
      <DevicesFilterListItem image={<Spinner size="sm" color="gradient" />} label="Loading…" />
    );
  }

  return (
    workflows?.map?.((workflow, idx) => {
      const selected = filter.workflowIds?.includes?.(workflow.id);

      return (
        <DevicesFilterListItem
          key={workflow.id}
          image={<Icon name="GitBranch" size="sm" color="gradient" />}
          label={workflow.name}
          selected={selected}
          onClick={() => {
            if (selected) {
              onFilter({
                ...filter,
                workflowIds: without(filter.workflowIds, workflow.id),
              });
            } else {
              onFilter({
                ...filter,
                workflowIds: [...(filter.workflowIds || []), workflow.id],
              });
            }
          }}
        />
      );
    }) || null
  );
}
