import cx from 'classnames';

const VARIANTS = {
  base: ['block', 'uppercase', 'select-none', 'font-medium', 'text-xs', 'tracking-wide'],
};

export default function Label({ as = 'label', children, className, ...rest }) {
  const Componenent = as;
  return (
    <Componenent className={cx(VARIANTS.base, className)} {...rest}>
      {children}
    </Componenent>
  );
}
