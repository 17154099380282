import { SideModal, Card, Icon, Heading, Text, Button } from '@optra/kit';

export default function SupportSidebar({ isOpen, onClose }) {
  return (
    <SideModal isOpen={isOpen} onClose={onClose} icon="Question" title="Support">
      <div className="w-full">
        <Heading level={2} className="mb-2">
          User Guides
        </Heading>

        <div className="grid lg:grid-cols-2 gap-8 mb-8">
          <Card variant="secondary" className="text-center space-y-4">
            <div>
              <Icon size="2xl" name="FileText" className="mx-auto" color="gradient" />
              <Heading level={2} variant="centered">
                Getting Started Guide
              </Heading>
            </div>
            <Button href="https://optra.com/getting-started" target="_blank">
              View
            </Button>
          </Card>
          <Card variant="secondary" className="text-center space-y-4">
            <div>
              <Icon size="2xl" name="FileArrowDown" className="mx-auto" color="gradient" />
              <Heading level={2} variant="centered">
                Optra Device User‘s Guide
              </Heading>
            </div>
            <Button href="https://optra.com/Optra User's Guide.pdf" target="_blank">
              Download
            </Button>
          </Card>
        </div>

        <Heading level={2} className="mb-2">
          Support Options
        </Heading>
        <div className="grid lg:grid-cols-2 gap-8">
          <Card variant="secondary">
            <Heading level={2} icon="Phone" className="mb-2">
              Phone Support
            </Heading>
            <Text size="sm">
              USA Phone:{' '}
              <a
                href="tel:18005396275"
                className="text-primary font-bold underline decoration-primary-500 decoration-1"
              >
                (800) 539-6275
              </a>{' '}
              <br />
              <Text color="muted">M–F 8am-9pm EST</Text>
              <br />
              Follow these steps in the phone menu:
              <br />
              First select [1] for Technical Support
              <br />
              Select [3] for Optra IoT Solutions
            </Text>
          </Card>

          <Card variant="secondary">
            <Heading level={2} icon="Envelope" className="mb-2">
              Email Support
            </Heading>
            <Text size="sm">
              <a
                href="mailto:optra.techsupport@lexmark.com"
                className="text-primary font-bold underline decoration-primary-500 decoration-1"
              >
                optra.techsupport@lexmark.com
              </a>{' '}
              <br />
              <Text color="muted">8am - 9pm EST</Text>
              <br />
              Email received after business hours will receive a response on the next business day
            </Text>
          </Card>
        </div>
      </div>
    </SideModal>
  );
}
