import { api, q } from 'config/api';

export default function useDeviceTunnel({ deviceId, workflowSkillId }, overrides) {
  return q.useQuery({
    queryKey: ['device', deviceId, workflowSkillId, 'tunnel'],
    queryFn: () =>
      api(
        `query deviceTunnel($deviceId: ID!, $workflowSkillId: ID!) {
          tunnel: deviceTunnel(deviceId: $deviceId, workflowSkillId: $workflowSkillId) {
            id
            url
            authentication
            createdAt
            expiresAt
            open
            workflowSkill {
              name
            }
            creatorId
          }
        }`,
        { deviceId, workflowSkillId },
      ),
    enabled: !!deviceId && !!workflowSkillId,
    ...overrides,
  });
}
