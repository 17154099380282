import { isEmpty, omit } from 'lodash';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { Card, Button, IconButton, PageWrapper } from '@optra/kit';

import AdminSkillBuilderBetaNodeConfigFields from 'components/admin-skill-builder-beta-node-config-fields';
import AdminSkillBuilderBetaNodeInputs from 'components/admin-skill-builder-beta-node-inputs';
import AdminSkillBuilderBetaNodeOutputs from 'components/admin-skill-builder-beta-node-outputs';
import IconChooser from 'components/icon-chooser';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import Select from 'components/select';
import Textarea from 'components/textarea';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus } from 'hooks';
import { useSkillBuilderBetaNode } from 'queries';

export function validateJSON(form = {}) {
  const { inputs = '[]', outputs = '[]', configFields = '[]' } = form;
  const inputsArr = JSON.parse(inputs);
  const outputsArr = JSON.parse(outputs);
  const configFieldsArr = JSON.parse(configFields);

  const inputsValid = inputsArr.every(
    ({ label, key, type }) => !isEmpty(label) && !isEmpty(key) && !isEmpty(type),
  );
  const outputsValid = outputsArr.every(
    ({ label, key, type }) => !isEmpty(label) && !isEmpty(key) && !isEmpty(type),
  );
  const configFieldsValid = configFieldsArr.every(
    ({ label, key, type }) => !isEmpty(label) && !isEmpty(key) && !isEmpty(type),
  );

  return inputsValid && outputsValid && configFieldsValid;
}

export default function AdminSkillBuilderBetaNodeDetail() {
  const { id } = useParams();
  const { data, isSuccess, error: fetchError, isLoading } = useSkillBuilderBetaNode(id);
  const navigate = useNavigate();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      label: '',
      description: '',
      inputs: '[]',
      outputs: '[]',
      configFields: '[]',
      type: '',
      icon: {
        icon: 'action',
      },
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'label');

  const qc = q.useQueryClient();
  const updateSkillBuilderBetaNode = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateSkillBuilderBetaNode($form: updateSkillBuilderBetaNodeForm!) {
          skillBuilderBetaNode: updateSkillBuilderBetaNode(form: $form) {
            id
          }
        }`,
        {
          form: {
            ...omit(form, 'icon'),
            icon: form.icon?.icon,
          },
        },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skillBuilderBetaNode', id] });
      qc.invalidateQueries({ queryKey: ['skillBuilderBetaNodes'] });
      navigate('/admin/skill-builder-content/beta-nodes');
    },
    onError(error) {
      setError(error);
    },
  });

  const removeSkillBuilderBetaNode = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeSkillBuilderBetaNode($id: ID!) {
          removeSkillBuilderBetaNode(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skillBuilderBetaNode', id] });
      qc.invalidateQueries({ queryKey: ['skillBuilderBetaNodes'] });
      navigate('/admin/skill-builder-content');
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);

    if (!validateJSON(form)) {
      setError({
        message: 'Invalid node configuration! Check your inputs, outputs and config fields.',
      });
      return;
    }

    updateSkillBuilderBetaNode.mutate({
      ...form,
      id,
    });
  });

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this node?')) {
      removeSkillBuilderBetaNode.mutate(id);
    }
  };

  useOnSuccess(
    () => {
      reset({
        name: data?.skillBuilderBetaNode?.name,
        label: data?.skillBuilderBetaNode?.label,
        description: data?.skillBuilderBetaNode?.description,
        inputs: data?.skillBuilderBetaNode?.inputs || '[]',
        outputs: data?.skillBuilderBetaNode?.outputs || '[{}]',
        configFields: data?.skillBuilderBetaNode?.configFields || '[]',
        type: data?.skillBuilderBetaNode?.type,
        icon: {
          icon: data?.skillBuilderBetaNode?.icon,
        },
      });
    },
    { isSuccess },
    [data, reset],
  );

  const watchedInputs = watch('inputs');

  return (
    <PageWrapper
      icon="Nut"
      title={data?.skillBuilderBetaNode?.name}
      heading={data?.skillBuilderBetaNode?.name}
      inline={true}
      loading={isLoading}
      error={fetchError}
      components={{
        headingBefore: (
          <IconButton
            name="CaretLeft"
            variant="plain"
            to="/admin/skill-builder-content/beta-nodes"
          />
        ),
        actions: (
          <Button variant="secondary" icon="Trash" onClick={handleDelete}>
            Delete
          </Button>
        ),
      }}
    >
      {error && (
        <Message variant="danger" title="Couldn't Create Node">
          {error.message}
        </Message>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="label">Label</Label>
          <Input
            type="text"
            {...register('label', {
              required: 'Please enter a label',
            })}
            readOnly={updateSkillBuilderBetaNode.isPending}
          />
          <ValidationError errors={errors} name="label" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="name">Class Name</Label>
          <Input
            type="text"
            {...register('name', {
              required: 'Please enter a name',
            })}
            readOnly={updateSkillBuilderBetaNode.isPending}
          />
          <ValidationError errors={errors} name="name" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="description">Description</Label>
          <Textarea
            {...register('description', {
              required: 'Please enter a description',
            })}
            readOnly={updateSkillBuilderBetaNode.isPending}
          />
          <ValidationError errors={errors} name="description" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="type">Type</Label>
          <Select
            {...register('type', {
              required: 'Please select a type',
            })}
            readOnly={updateSkillBuilderBetaNode.isPending}
          >
            <option>Choose a type</option>
            <option value="inputs">Input</option>
            <option value="inference">Model</option>
            <option value="processors">Data Processor</option>
            <option value="graphic_elements">Image Processor</option>
            <option value="outputs">Output</option>
          </Select>
          <ValidationError errors={errors} name="type" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="icon">Icon</Label>
          <Controller
            name="icon"
            control={control}
            rules={{
              required: 'Please choose an icon.',
            }}
            render={({ field }) => (
              <Card>
                <IconChooser
                  {...field}
                  loading={updateSkillBuilderBetaNode.isPending}
                  disabled={updateSkillBuilderBetaNode.isPending}
                  showColors={false}
                  allowsUpload={false}
                />
              </Card>
            )}
          />
          <ValidationError errors={errors} name="icon" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="inputs">Inputs</Label>
          <Controller
            name="inputs"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <AdminSkillBuilderBetaNodeInputs {...field} />
            )}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="configFields">Config Fields</Label>
          <Controller
            name="configFields"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <AdminSkillBuilderBetaNodeConfigFields
                {...field}
                availableInputs={JSON.parse(watchedInputs)}
              />
            )}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="outputs">Outputs</Label>
          <Controller
            name="outputs"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <AdminSkillBuilderBetaNodeOutputs {...field} />
            )}
          />
        </div>

        <Button type="submit" size="xl" loading={updateSkillBuilderBetaNode.isPending}>
          Save
        </Button>
      </form>
    </PageWrapper>
  );
}
