export default function actionIcon(id) {
  switch (id) {
    case 'googleSpreadsheet':
      return 'FileText';
    case 'email':
      return 'Envelope';
    case 'sms':
      return 'DeviceMobile';
    case 'firebasePushNotification':
      return 'DeviceMobile';
    case 'dataSink':
      return 'FunnelSimple';
    case 'timestream':
      return 'Infinity';
    default:
      return 'Code';
  }
}
