import cx from 'classnames';
import { isEmpty } from 'lodash';

import { Card, Icon, Text } from '@optra/kit';

export default function MarketplaceSkillPrivilegeCards({ marketplaceSkill = {}, wide = false }) {
  const requiresStorage = !isEmpty(marketplaceSkill?.latestVersion?.storage);
  const requiresEnv = !isEmpty(marketplaceSkill?.latestVersion?.env);
  const requiresPort = marketplaceSkill?.latestVersion?.port > 0;
  const requiresSound = marketplaceSkill?.latestVersion?.devices?.sound === true;
  const requiresHDMI = marketplaceSkill?.latestVersion?.devices?.hdmi === true;

  if (!requiresStorage && !requiresEnv && !requiresPort && !requiresSound) {
    return null;
  }

  return (
    <div className="mt-4">
      <Text variant="label" className="block" size="sm" color="muted">
        Skill Requirements
      </Text>
      <div className={cx(['grid', wide ? 'lg:grid-cols-3' : 'lg:grid-cols-2', 'gap-2', 'my-2'])}>
        {requiresStorage && (
          <Card variant="secondary" className="flex items-start">
            <div className="flex-shrink-0 mr-3">
              <Icon name="Database" />
            </div>
            <div>
              <Text variant="label" className="block" size="sm">
                Storage
              </Text>
              <Text size="xs" className="block leading-snug mt-2">
                This skill will be given access to the filesystem of the device(s) on which it is
                installed.
              </Text>
            </div>
          </Card>
        )}
        {requiresEnv && (
          <Card variant="secondary" className="flex items-start">
            <div className="flex-shrink-0 mr-3">
              <Icon name="Sliders" />
            </div>
            <div>
              <Text variant="label" className="block" size="sm">
                Environment
              </Text>
              <Text size="xs" className="block leading-snug mt-2">
                This skill sets custom Environment Variables on the device(s) on which it is
                installed.
              </Text>
            </div>
          </Card>
        )}
        {requiresPort && (
          <Card variant="secondary" className="flex items-start">
            <div className="flex-shrink-0 mr-3">
              <Icon name="Globe" />
            </div>
            <div>
              <Text variant="label" className="block" size="sm">
                Web UI
              </Text>
              <Text size="xs" className="block leading-snug mt-2">
                This skill exposes a web-based user-interface via port{' '}
                <span className="font-bold">{marketplaceSkill?.latestVersion?.port}</span>.
              </Text>
            </div>
          </Card>
        )}
        {requiresSound && (
          <Card variant="secondary" className="flex items-start">
            <div className="flex-shrink-0 mr-3">
              <Icon name="SpeakerHigh" />
            </div>
            <div>
              <Text variant="label" className="block" size="sm">
                Sound
              </Text>
              <Text size="xs" className="block leading-snug mt-2">
                This skill utilizes the device's sound port to play sounds.
              </Text>
            </div>
          </Card>
        )}
        {requiresHDMI && (
          <Card variant="secondary" className="flex items-start">
            <div className="flex-shrink-0 mr-3">
              <Icon name="MonitorPlay" />
            </div>
            <div>
              <Text variant="label" className="block" size="sm">
                HDMI
              </Text>
              <Text size="xs" className="block leading-snug mt-2">
                This skill utilizes the device's HDMI port to play videos.
              </Text>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}
