import flatMap from 'lodash/flatMap';

import { PrimaryCTAButton, PageWrapper } from '@optra/kit';

import AdminSuperUsersList from 'components/admin-super-users-list';
import PinPageButton from 'components/pin-page-button';
import { useSuperUsers } from 'queries';

export default function AdminSuperUsers() {
  const { data, error, isLoading } = useSuperUsers();
  const superUsers = flatMap(data?.pages, page => page?.list?.data);

  return (
    <PageWrapper
      icon="UserCircleGear"
      title="Super Users | Admin"
      heading="Super Users"
      components={{
        actions: (
          <>
            <PinPageButton />
            <PrimaryCTAButton to="/admin/super-users/create" text="New Super User" />
          </>
        ),
      }}
      inline={true}
      loading={isLoading}
      error={error}
    >
      <AdminSuperUsersList fetching={isLoading} error={error} users={superUsers} />
    </PageWrapper>
  );
}
