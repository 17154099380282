import cx from 'classnames';

import { Card, Text, Icon, InteractiveWrap } from '@optra/kit';

import DataTypeHeading from 'modals/skill-pipeline-builder/components/data-type-heading';
import getNodeDef from 'modals/skill-pipeline-builder/context/get-node-def';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function OutputListItem({ nodeId, output }) {
  const [, { nodesConnectedToOutput }] = usePipelineNode(nodeId);
  const dependencies = nodesConnectedToOutput(output.key);
  return (
    <div
      className={cx([
        'grid',
        'grid-cols-8',
        'gap-2',
        'items-center',
        'p-4',
        dependencies.length < 1 && ['opacity-40'],
      ])}
    >
      <div className="col-span-4">
        <DataTypeHeading type={output.type} label={output.label} />
      </div>
      <div className="col-span-4 space-y-2">
        {dependencies.map((dep, index) => {
          const depDef = getNodeDef(dep);
          return (
            <InteractiveWrap
              to={`../nodes/${dep.id}`}
              key={`${dep.name}-${index}`}
              className="block"
            >
              <Card
                variant="clean"
                size="sm"
                className="flex flex-row items-center justify-between"
              >
                <div className="flex flex-row items-center space-x-2">
                  <Icon name={depDef.icon} color="primary" weight="duotone" />
                  <Text size="sm">{depDef.label}</Text>
                </div>
                <Icon name="CaretRight" size="xs" />
              </Card>
            </InteractiveWrap>
          );
        })}
      </div>
    </div>
  );
}
