import { Tag } from '@phosphor-icons/react';
import { noop } from 'lodash';

import { Table, UiState, SearchField } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';

export default function TagsChooser(props) {
  const {
    onSelectTag = () => false,
    tags = [],
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    filter,
    onFilter = noop,
    newSelectedTags = [],
    newDeselectedTags = [],
    RowDataComponent,
  } = props;

  const isSelected = tag => {
    if (newSelectedTags.includes(tag.id)) {
      return true;
    }
    if (newDeselectedTags.includes(tag.id)) {
      return false;
    }
    return tag?.selected;
  };

  return (
    <>
      {error && (
        <Message variant="danger" title="Couldn't Load Tags">
          {error.message}
        </Message>
      )}

      <div className="my-4 flex items-center justify-between space-x-2">
        <SearchField
          className="flex-1"
          value={filter?.$search || ''}
          onChange={$search => onFilter(prev => ({ ...prev, $search }))}
          onClear={() => onFilter(prev => ({ ...prev, $search: '' }))}
          searching={isLoading}
        />
      </div>

      {isLoading ? (
        <UiState />
      ) : tags?.length === 0 ? (
        <UiState variant="empty" icon={{ component: Tag }} text="No Tags..." />
      ) : (
        <>
          <Table variant="secondary">
            <Table.Body>
              {tags.map(tag => {
                if (!tag) {
                  return null;
                }
                const { disabled, id } = tag;
                const selected = isSelected(tag);

                return (
                  <Table.Row
                    key={id}
                    hover={!disabled}
                    disabled={disabled}
                    disabledSelectable={disabled}
                    selectable
                    selected={selected}
                    onClick={
                      disabled
                        ? null
                        : () => {
                            onSelectTag(selected, tag);
                          }
                    }
                  >
                    {RowDataComponent && <RowDataComponent tag={tag} selected={selected} />}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <IntersectionLoadMore
            onVisible={fetchNextPage}
            disabled={isFetchingNextPage || !hasNextPage}
          />
        </>
      )}
    </>
  );
}
