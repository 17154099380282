import { Button } from '@optra/kit';

import { api, q } from 'config/api';
import merge from 'lib/merge';

export default function UpdateCollectorModelButton(props) {
  const { id } = props;
  const queryKey = ['model', id, 'collector'];
  const collector = q.useQuery({
    queryKey,
    queryFn: () =>
      api(
        `query eiSkillCollector($id: ID!) {
          model(id: $id) {
            edgeImpulse {
              workflow {
                isSynchronizing
                skills {
                  data {
                    updateAvailable
                  }
                }
              }
            }
          }
        }`,
        { id },
      ),
    refetchInterval: 1000 * 60,
    enabled: !!id,
  });

  const qc = q.useQueryClient();
  const updateCollector = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateEdgeImpulseModelCollector($form: updateEdgeImpulseCollectorForm!) {
          model: updateEdgeImpulseModelCollector(form: $form) {
            id
          }
        }`,
        { form },
      ),
    async onMutate() {
      await qc.cancelQueries({ queryKey });
      const current = qc.getQueryData(queryKey);
      qc.setQueryData(queryKey, olds => {
        return merge(
          { ...olds },
          {
            model: {
              edgeImpulse: {
                workflow: {
                  isSynchronizing: true,
                  skills: {
                    data: [
                      {
                        updateAvailable: false,
                      },
                    ],
                  },
                },
              },
            },
          },
        );
      });

      return { current };
    },
    onError: (err, form, context) => {
      qc.setQueryData(queryKey, context.current);
    },
    onSettled() {
      qc.invalidateQueries({ queryKey: ['model', id, 'collector'] });
    },
  });

  const updateAvailable =
    collector.data?.model?.edgeImpulse?.workflow?.skills?.data?.at(0)?.updateAvailable || false;
  const isSynchronizing = collector.data?.model?.edgeImpulse?.workflow?.isSynchronizing || false;

  const disabled = !updateAvailable || isSynchronizing;
  const loading = collector.isLoading || updateCollector.isPending;

  return (
    <Button
      size="xs"
      variant="secondary"
      onClick={() => {
        if (disabled) return;
        updateCollector.mutate({ id, version: 'latest' });
      }}
      loading={loading}
      disabled={disabled}
      icon="Warning"
    >
      Update
    </Button>
  );
}
