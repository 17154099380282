export default {
  compute500: 'Optra Compute 500',
  compute550: 'Optra Compute 550',
  cx1000: 'Optra CX1000',
  cx2000: 'Optra CX2000',
  vz1000: 'Optra VZ1000',
  vz5000: 'Optra VZ5000',
  vz5100: 'Optra VZ5100',
  vz6100: 'Optra VZ6100',
  vz6000: 'Optra VZ6000',
};
