import { uniq } from 'lodash';

import { Badge } from '@optra/kit';

import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import DataTypeLegend from 'modals/skill-pipeline-builder/components/data-type-legend';
import ListItem from 'modals/skill-pipeline-builder/components/list-item';
import { NODE_DEFS, DATA_TYPES } from 'modals/skill-pipeline-builder/config';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import createBetaDef from 'modals/skill-pipeline-builder/context/create-beta-def';
import { useSkillBuilderBetaNodes } from 'queries';

export default function AddPipelineNodeDataProcessor() {
  const { addNode, missingDataTypesForNode } = usePipelineContext();
  const type = 'processors';
  const { data } = useSkillBuilderBetaNodes();
  const betaDefs = (data || []).filter(d => d?.type === type).map(createBetaDef);

  const nodeDefs = Object.values(NODE_DEFS).filter(n => n.type === type);
  const allDefs = [...nodeDefs, ...betaDefs];

  return (
    <ModalInner>
      <ModalTitle title="Data Processors" icon="Database" showBackButton />
      <ModalBody>
        {allDefs.map(nodeDef => {
          const missingDataTypes = missingDataTypesForNode(nodeDef);
          let errorDescription;
          if (missingDataTypes.length > 0) {
            const missingDataTypeLabels = missingDataTypes.map(d => DATA_TYPES[d].label);
            const multipleOfTypeRequired =
              missingDataTypeLabels.length > 1 &&
              uniq(missingDataTypeLabels).length <= missingDataTypeLabels.length;

            if (multipleOfTypeRequired) {
              errorDescription = `This node requires ${missingDataTypeLabels.length}x outputs of type ${missingDataTypeLabels[0]} to exist in the pipeline.`;
            } else {
              errorDescription = `This node requires outputs of type ${missingDataTypeLabels.join(
                ', ',
              )} to exist in the pipeline.`;
            }
          }
          return (
            <ListItem
              key={`node-def-${nodeDef.key}`}
              icon={nodeDef.icon}
              name={nodeDef.label}
              onClick={() => {
                if (nodeDef?.isBetaNode) {
                  addNode({ betaNode: nodeDef });
                } else {
                  addNode({ nodeType: nodeDef.key });
                }
              }}
              description={nodeDef.description}
              errorDescription={errorDescription}
              className="w-full"
              truncateDescription={false}
              inputs={nodeDef.inputs}
              outputs={nodeDef.outputs}
              disabled={missingDataTypes.length > 0}
              renderBadge={() => {
                if (nodeDef?.isBetaNode) {
                  return (
                    <Badge variant="danger" size="xs">
                      Beta
                    </Badge>
                  );
                } else {
                  return null;
                }
              }}
            />
          );
        })}
      </ModalBody>
      <ModalFooter className="md:min-w-modal-sm md:max-w-modal-sm">
        <DataTypeLegend alignment="justify-center" />
      </ModalFooter>
    </ModalInner>
  );
}
