import cx from 'classnames';
import { noop } from 'lodash';

import { Button, Icon, Dropdown, DROPDOWN_MENU_PLACEMENT, DetailHeading, Text } from '@optra/kit';

export default function VersionSelector(props) {
  const { isLoading = false, isError = false, versions = [], value, onChange = noop } = props;
  const selected = versions.find(version => version.id === value);

  return (
    <Dropdown
      divide={false}
      scrolling={true}
      placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
      components={{
        button: ({ isOpen }) => (
          <span className="flex flex-nowrap items-center space-x-2">
            <Button
              as="span"
              variant="secondary"
              size="sm"
              loading={isLoading}
              error={isError && 'Failed to load!'}
            >
              <span className={cx('flex flex-nowrap items-center justify-center space-x-2')}>
                <Text size="sm" color="muted">
                  {selected?.version}
                </Text>
                <Icon name={isOpen ? 'CaretUp' : 'CaretDown'} size="xs" />
              </span>
            </Button>
          </span>
        ),
        body: (
          <div className={cx('space-y-7 p-2 my-2 min-w-[16rem]')}>
            <div className="px-3 space-y-4">
              <DetailHeading>Versions</DetailHeading>
              <div className="space-y-2">
                {(versions || []).map(version => (
                  <Dropdown.Item
                    key={version.id}
                    text={version.version}
                    onClick={() => onChange(version.id)}
                    active={value === version.id}
                    className="rounded-md bg-gray-100 dark:bg-white/10"
                  />
                ))}
              </div>
            </div>
          </div>
        ),
      }}
    />
  );
}
