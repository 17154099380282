import { api, q } from 'config/api';

export default function useDeviceSoftwareVersions(variables) {
  return q.useInfiniteQuery({
    queryKey: ['deviceSoftwareVersions'],
    queryFn: ({ pageParam }) =>
      api(
        `query deviceSoftwareVersions($list: listFields) {
          list: deviceSoftwareVersions(list: $list) {
            data {
              version
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      ),
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage?.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => data.pages.flatMap(page => page.list.data),
  });
}
