import { useController } from 'react-hook-form';

import { Card, Table, Text } from '@optra/kit';

import Label from 'components/label';
import { useWorkspaces } from 'queries';

export default function SelectWorkspace(props) {
  const { data } = useWorkspaces();
  const workspaces = data?.toReversed?.() || [];

  const { rules, name, control, defaultValue = '' } = props;

  const controller = useController({
    control,
    rules,
    name,
    defaultValue,
  });

  const currentValue = controller.field?.value;

  if (!workspaces?.length) return null;

  return (
    <Card variant="secondary">
      <div className="space-y-2">
        <Label htmlFor="copyWorkspace">Duplicate Existing Workspace</Label>
        <Table>
          <Table.Body>
            {workspaces?.map?.(({ name, id }) => {
              return (
                <Table.Row
                  key={id}
                  hover
                  selectable
                  selected={currentValue === id}
                  onClick={() => {
                    if (currentValue === id) {
                      controller.field.onChange('');
                      return;
                    }
                    controller.field.onChange(id);
                  }}
                >
                  <Table.TD>
                    <Text size="sm">{name}</Text>
                  </Table.TD>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </Card>
  );
}
