import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useVersionCheck() {
  const location = useLocation();
  useLayoutEffect(() => {
    if (window.localStorage.getItem('version-update')) {
      const lastUpdate = window.localStorage.getItem('last-version-update');
      if (!lastUpdate || Date.now() - lastUpdate > 1000 * 60 * 5) {
        window.localStorage.removeItem('version-update');
        window.localStorage.setItem('last-version-update', Date.now());
        window.location.reload();
      }
    }
  }, [location?.pathname]);
}
