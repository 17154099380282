import { useNavigate, useLocation } from 'react-router-dom';

import { Button, Tooltip, UnreadDot } from '@optra/kit';

import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';
import { useDeviceTunnel } from 'queries';

export default function WebUIButton(props) {
  const {
    deviceId,
    workflowSkillId,
    skillHasWebUI,
    deviceSupportsTunneling,
    deviceConnected,
    variant = 'tertiary',
  } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const redirectUrl = `${pathname}/${workflowSkillId}/web-ui`;

  const tunnel = useDeviceTunnel(
    {
      deviceId,
      workflowSkillId,
    },
    { refetchInterval: 10000 },
  );

  const qc = q.useQueryClient();
  const openTunnel = q.useMutation({
    mutationFn: form =>
      api(
        `mutation openDeviceTunnel($form: openDeviceTunnelForm!) {
          tunnel: openDeviceTunnel(form: $form) {
            id
            url
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['device', deviceId, workflowSkillId, 'tunnel'] });
      navigate(redirectUrl);
    },
  });

  const [canOpenWebUI] = useHasRoles([
    'admin',
    'deviceEnroller',
    'deviceTechnician',
    'workflowEditor',
    'workflowDeployer',
  ]);

  // User can't open the Web UI, so no skills will display a button
  if (!canOpenWebUI) return null;

  // Some skills might not have a Web UI. Hide the button, but reserve the space to keep alignment
  if (!skillHasWebUI) return <div className="flex flex-1" />;

  if (!deviceSupportsTunneling) {
    return (
      <div className="flex flex-1 flex-col">
        <Tooltip label="Firmware Update Required">
          <span>
            <Button size="xs" variant="tertiary" icon="Globe" disabled>
              Web UI
              <span className="absolute top-0 right-0">
                <UnreadDot />
              </span>
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  }

  const isWaiting = tunnel.isLoading || openTunnel.isPending;
  const remainingTime = (tunnel.data?.tunnel?.expiresAt || 0) - Date.now();
  const totalTime = (tunnel.data?.tunnel?.expiresAt || 0) - (tunnel.data?.tunnel?.createdAt || 0);
  const progressUntilClose = Math.max(remainingTime / Math.max(totalTime, 1), 0);
  const isOpen = progressUntilClose > 0;

  // Show a warning if the device isn't connected and there isn't an error
  // (because the tooltip for the error badge conflicts with this warning).
  const displayConnectionWarning = !deviceConnected && !openTunnel.isError;

  return (
    <div className="flex flex-1 flex-col">
      <Tooltip
        label={
          displayConnectionWarning
            ? 'Device not fully connected - opening tunnel may fail'
            : undefined
        }
        disabled={!!!displayConnectionWarning}
      >
        <span>
          <Button
            size="xs"
            variant={variant}
            icon={isOpen ? null : 'Globe'}
            loading={openTunnel.isPending}
            hideChildrenWhenLoading={false}
            disabled={isWaiting}
            showProgress={isOpen}
            progress={progressUntilClose}
            error={openTunnel.isError ? 'Unable to open tunnel' : null}
            onClick={() => {
              if (isWaiting) return;
              if (isOpen) {
                navigate(redirectUrl);
                return;
              }
              openTunnel.mutate({ workflowSkillId, deviceId, type: 'workflowSkill' });
            }}
          >
            Web UI
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}
