import { isEmpty, truncate } from 'lodash';

import { Table, IconButton, Tooltip, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Textarea from 'components/textarea';
import { api, q } from 'config/api';

function DeprovisionDeviceButton({ id }) {
  const qc = q.useQueryClient();
  const removeProvisionedDevice = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeProvisionedDevice($id: ID!) {
          device: removeProvisionedDevice(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['provisionedDevices'] });
    },
  });

  const handleDelete = () => {
    if (removeProvisionedDevice.isPending) return;
    if (window.confirm('Are you sure you want to remove this device?')) {
      removeProvisionedDevice.mutate(id);
    }
  };

  return (
    <IconButton
      variant="secondary"
      onClick={handleDelete}
      loading={removeProvisionedDevice.isPending}
      name="Trash"
    />
  );
}

function ClaimDeviceButton({ id, claimable }) {
  const qc = q.useQueryClient();
  const claimDevice = q.useMutation({
    mutationFn: form =>
      api(
        `mutation claimDevice($form: claimDeviceForm!) {
          claimDevice(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['provisionedDevices'] });
    },
  });

  const handleClaim = () => {
    if (claimDevice.isPending) return;
    claimDevice.mutate({ iotDeviceId: id, claimable: !claimable });
  };

  return (
    <IconButton
      variant="secondary"
      onClick={handleClaim}
      loading={claimDevice.isPending}
      name={claimable ? 'X' : 'Check'}
    />
  );
}

export default function AdminProvisioningList({ devices = [] }) {
  if (isEmpty(devices)) {
    return <EmptyContentMessage icon="GitFork" title="No Provisioned Devices" />;
  }

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TH>Serial Number</Table.TH>
          <Table.TH>Model</Table.TH>
          <Table.TH>Status</Table.TH>
          <Table.TH style={{ minWidth: 200 }}>Admin Connection String</Table.TH>
          <Table.TH style={{ minWidth: 200 }}>Module Connection String</Table.TH>
          <Table.TH style={{ minWidth: 200 }}>Errors</Table.TH>
          <Table.TH>Claimed By</Table.TH>
          <Table.TH>Claim?</Table.TH>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {devices.map?.(device => {
          const certOnly =
            isEmpty(device?.adminConnectionString) || isEmpty(device?.moduleConnectionString);
          return (
            <Table.Row key={device.serialNumber} hover>
              <Table.TD>
                <Tooltip label={device.serialNumber}>
                  <div className="text-xs uppercase font-mono">
                    {truncate(device.serialNumber, { length: 24 })}
                  </div>
                </Tooltip>
              </Table.TD>
              <Table.TD>
                <Text size="xs" color="muted">
                  {device.model || '--'}
                </Text>
              </Table.TD>
              <Table.TD>
                <Text size="xs" color="muted">
                  {device.status}
                </Text>
              </Table.TD>
              {certOnly ? (
                <Table.TD colSpan={2}>
                  <Text className="text-red text-xs">Certificate required to connnect</Text>
                </Table.TD>
              ) : (
                <>
                  <Table.TD>
                    <Textarea
                      className="text-xs shadow-none"
                      value={device?.adminConnectionString || ''}
                      readOnly
                    />
                  </Table.TD>
                  <Table.TD>
                    <Textarea
                      className="text-xs shadow-none"
                      value={device?.moduleConnectionString || ''}
                      readOnly
                    />
                  </Table.TD>
                </>
              )}
              <Table.TD className="space-y-1">
                {!isEmpty(device?.certificateErrors?.module) && (
                  <Textarea
                    className="text-red text-xs shadow-none"
                    readOnly
                    value={`Modules Cert: ${device.certificateErrors.module}`}
                  />
                )}
                {!isEmpty(device?.certificateErrors?.admin) && (
                  <Textarea
                    className="text-red text-xs shadow-none"
                    readOnly
                    value={`Admin Cert: ${device.certificateErrors.admin}`}
                  />
                )}
              </Table.TD>
              <Table.TD className="whitespace-nowrap">
                <Text size="xs" color="muted">
                  {device.claimant
                    ? `${device.claimant?.name} ${
                        device.organization?.name ? `(${device.organization?.name})` : ''
                      }`
                    : 'Unclaimed'}
                </Text>
              </Table.TD>
              <Table.TD collapsed>
                <div className="flex items-center space-x-2">
                  <ClaimDeviceButton id={device.serialNumber} claimable={device.claimable} />
                  <DeprovisionDeviceButton id={device.id} />
                </div>
              </Table.TD>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
