import { Card, Button, Text } from '@optra/kit';

import { usePinnedPage } from 'queries';

export default function ProfilePinnedPageSection() {
  const [pinnedLandingPage, { isLoading: isLoadingPinnedPage, setPinnedPage }] = usePinnedPage();

  if (isLoadingPinnedPage || !pinnedLandingPage) {
    return null;
  }

  return (
    <div>
      <Text size="xs" variant="label" className="block mb-2">
        Pinned Home Page
      </Text>
      <Card variant="secondary">
        <div className="flex items-center justify-between">
          <Text
            size="xs"
            className="bg-light-bg-secondary border-light-fg-secondary dark:bg-dark-bg-secondary dark:border-dark-fg-secondary text-green font-mono text-xs py-1 px-2 rounded-md shadow-sm"
          >
            {pinnedLandingPage}
          </Text>
          <Button
            size="xs"
            variant="secondary"
            icon="Trash"
            onClick={() => setPinnedPage.mutate(null)}
            loading={setPinnedPage.isPending}
          >
            Unpin
          </Button>
        </div>
      </Card>
    </div>
  );
}
