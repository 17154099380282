import { Icon, Text } from '@optra/kit';

import { DATA_TYPES } from 'modals/skill-pipeline-builder/config';

export default function DataTypeLegend({ alignment = 'justify-start' }) {
  return (
    <div className={`flex flex-row items-center ${alignment} space-x-4 flex-wrap`}>
      {Object.values(DATA_TYPES).map(d => (
        <div key={d.key} className="flex flex-row items-center space-x-1 mb-1">
          <Icon color={d.color} name="CaretRight" weight="fill" size="xxs" />
          <Text variant="label" size="xs" color="muted">
            {d.label}
          </Text>
        </div>
      ))}
    </div>
  );
}
