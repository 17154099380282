import { useEffect, useRef, useState } from 'react';

function getCssValue(intOrFloat, scaleFactor) {
  if (!intOrFloat || Number.isNaN(intOrFloat)) {
    return null;
  }

  if (Number.isInteger(intOrFloat)) {
    return `${Math.floor(intOrFloat * scaleFactor)}px`;
  }

  return intOrFloat.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 0,
  });
}

// Assume 1080p / 1920x1080?
const OUTPUT_WIDTH = 1920;
// const OUTPUT_HEIGHT = 1080;

export default function ImageSizePreview({ width, height, top, left, bottom, right }) {
  const ref = useRef(null);

  const [renderedWidth, setRenderedWidth] = useState(0);
  // const [renderedHeight, setRenderedHeight] = useState(0);

  useEffect(() => {
    setRenderedWidth(ref.current.offsetWidth);
    // setRenderedHeight(ref.current.offsetHeight);
  }, []);

  const scaleFactor = renderedWidth / OUTPUT_WIDTH;
  // console.info({ renderedWidth, width, height, top, left, bottom, right, scaleFactor });

  return (
    <div
      ref={ref}
      className="w-full aspect-w-16 aspect-h-9 relative border border-solid border-primary"
    >
      <div
        className="absolute w-auto h-auto border border-dashed border-primary bg-primary bg-opacity-10"
        style={{
          width: getCssValue(width, scaleFactor),
          height: getCssValue(height, scaleFactor),
          top: getCssValue(top, scaleFactor),
          left: getCssValue(left, scaleFactor),
          bottom: getCssValue(bottom, scaleFactor),
          right: getCssValue(right, scaleFactor),
        }}
      />
    </div>
  );
}
