import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Card, Badge, Button, Heading, Icon, StepperInput, Text } from '@optra/kit';

import Feature from 'components/feature';
import Input from 'components/input';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q, useOnSuccess } from 'config/api';
import useRouteSearchParams from 'hooks/use-route-search-params';
import { creditsToFormattedString, creditsToFormattedCurrency } from 'lib/format-credits';
import pluralize from 'lib/pluralize';
import { useCurrentBillingAccount } from 'queries';

function calculateMonthlyUsage(currentBillingAccount) {
  const balances = currentBillingAccount?.balances;
  let estimatedMonthlyUsage = Math.abs(balances?.dailyUsage * 30) || 0;
  if (estimatedMonthlyUsage < 100000) {
    estimatedMonthlyUsage = 100000;
  }
  return estimatedMonthlyUsage;
}

export default function AddCreditsChoose() {
  const { returnTo } = useRouteSearchParams();
  const { showBackButton, handleBack } = useModalContext();
  const qc = q.useQueryClient();
  const [numCredits, setNumCredits] = useState(1000);
  const [estimatedMonthlyUsage, setEstimatedMonthlyUsage] = useState(100000);
  const [numMonths, setNumMonths] = useState(0);

  const { data, error, isSuccess } = useCurrentBillingAccount();
  const { handleSubmit, register, reset } = useForm({
    code: '',
  });

  useOnSuccess(
    () => {
      const { currentBillingAccount } = data;
      const usage = calculateMonthlyUsage(currentBillingAccount);
      setEstimatedMonthlyUsage(usage);
      setNumCredits(usage);
    },
    { isSuccess },
    [data, error],
  );

  useEffect(() => {
    setNumMonths(parseInt(numCredits / estimatedMonthlyUsage, 10));
  }, [numCredits, estimatedMonthlyUsage]);

  const redeemCode = q.useMutation({
    mutationFn: form =>
      api(
        `mutation redeemGiftCard($code: String!) {
          redeemGiftCard(code: $code) {
            id
          }
        }`,
        { code: form.code },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['currentBillingAccount'] });
    },
  });

  const handleKeyRedeemSubmit = handleSubmit(form => {
    redeemCode.mutate(
      {
        code: form.code,
      },
      {
        onSuccess() {
          reset();
        },
      },
    );
  });

  return (
    <ModalInner>
      <ModalTitle
        title="Add Credits"
        icon="PlusCircle"
        showBackButton={showBackButton}
        handleBack={handleBack}
      />
      <ModalBody>
        <Feature feature="billingBeta">
          <div className="flex flex-col items-center space-y-4 mt-4 mb-12">
            <Heading level={2}>How many credits would you like to add?</Heading>
            <Card variant="secondary" className="w-full space-y-2 flex flex-col items-center">
              <StepperInput
                size="lg"
                step={10000}
                min={10000}
                max={10000000}
                value={numCredits}
                displayValue={creditsToFormattedString(numCredits)}
                onChange={n => setNumCredits(n)}
              />
            </Card>
            <div className="flex flex-col items-center space-y-2">
              <Button to={`payment?returnTo=${returnTo}&numCredits=${numCredits}`} size="xl">
                Purchase Credits
                <Badge className="ml-2" variant="tertiary">
                  {creditsToFormattedCurrency(numCredits)}
                </Badge>
              </Button>
              <div className="flex flex-row items-center space-x-2">
                <Icon name="CalendarBlank" size="xs" color="muted" />
                <Text size="xs" color="muted" variant="label">
                  {numMonths} {pluralize('Month', numMonths)} Estimated Usage
                </Text>
              </div>
            </div>
          </div>
        </Feature>

        <Card variant="secondary">
          <Text size="sm" className="block mb-2">
            Have a Redemption Code?
          </Text>
          <form onSubmit={handleKeyRedeemSubmit}>
            {redeemCode.isSuccess && (
              <Message variant="success" title="Code Successfully Redeemed!"></Message>
            )}
            {redeemCode.isError && (
              <Message variant="danger" title="Couldn't Redeem Code">
                {redeemCode.error?.message}
              </Message>
            )}
            <div className="flex items-center justify-between">
              <Input
                type="text"
                placeholder="Enter your code..."
                className="mr-2"
                {...register('code', { required: 'Please enter a code.' })}
              />
              <Button
                size="sm"
                variant="tertiary"
                type="submit"
                loading={redeemCode.isPending}
                disabled={redeemCode.isPending}
              >
                Redeem
              </Button>
            </div>
          </form>
        </Card>
      </ModalBody>
    </ModalInner>
  );
}
