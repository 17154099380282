import { CaretDoubleRight } from '@phosphor-icons/react';
import { noop } from 'lodash';

import { Table, UiState, SearchField } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';

export default function WorkflowsChooser(props) {
  const {
    onSelectWorkflow = () => false,
    workflows = [],
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    filter,
    onFilter = noop,
    newSelectedWorkflows = [],
    newDeselectedWorkflows = [],
    RowDataComponent,
  } = props;

  const isSelected = workflow => {
    if (newSelectedWorkflows.includes(workflow.id)) {
      return true;
    }
    if (newDeselectedWorkflows.includes(workflow.id)) {
      return false;
    }
    return workflow?.selected;
  };

  return (
    <>
      {error && (
        <Message variant="danger" title="Couldn't Load Workflows">
          {error.message}
        </Message>
      )}

      <div className="my-4 flex items-center justify-between space-x-2">
        <SearchField
          className="flex-1"
          value={filter?.$search || ''}
          onChange={$search => onFilter(prev => ({ ...prev, $search }))}
          onClear={() => onFilter(prev => ({ ...prev, $search: '' }))}
          searching={isLoading}
        />
      </div>

      {isLoading ? (
        <UiState />
      ) : workflows?.length === 0 ? (
        <UiState variant="empty" icon={{ component: CaretDoubleRight }} text="No Workflows..." />
      ) : (
        <>
          <Table variant="secondary">
            <Table.Body>
              {workflows.map(workflow => {
                if (!workflow) {
                  return null;
                }
                const { disabled, id } = workflow;
                const selected = isSelected(workflow);

                return (
                  <Table.Row
                    key={id}
                    hover={!disabled}
                    disabled={disabled}
                    disabledSelectable={disabled}
                    selectable
                    selected={selected}
                    onClick={
                      disabled
                        ? null
                        : () => {
                            onSelectWorkflow(selected, workflow);
                          }
                    }
                  >
                    {RowDataComponent && (
                      <RowDataComponent workflow={workflow} selected={selected} />
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
            <IntersectionLoadMore
              onVisible={fetchNextPage}
              disabled={isFetchingNextPage || !hasNextPage}
            />
          </Table>
        </>
      )}
    </>
  );
}
