import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'ImageResultDecoratorElement',
  label: 'Annotate Data',
  icon: 'ChartPie',
  description:
    'Draw inference results from classification or detection inference engines onto an image.',
  type: 'graphic_elements',
  inputs: [
    {
      label: 'Source Image',
      key: 'image',
      type: DATA_TYPES['Image'],
      required: true,
    },
    {
      label: 'Results',
      key: 'inf_results',
      type: [DATA_TYPES['DecimalList'], DATA_TYPES['BoundingBoxes']],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'deco_image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
  defaultConfig: {
    confidenceThreshold: 0,
  },
  schema: z.object({
    config: z.object({ confidenceThreshold: z.number().min(0) }),
    inputs: z.object({
      image: z.string().min(1),
      inf_results: z.string().min(1),
    }),
  }),
};
