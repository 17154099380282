import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Table, Button } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import { useImpersonation } from 'hooks';

export default function AdminOrganizationsList({ organizations = [] }) {
  const { impersonateOrganization } = useImpersonation();
  const navigate = useNavigate();

  if (isEmpty(organizations)) {
    return (
      <EmptyContentMessage
        icon="Buildings"
        title="No Organizations"
        renderAction={() => (
          <Button to="/admin/organizations/create" icon="Plus">
            New Organization
          </Button>
        )}
      />
    );
  }

  return (
    <Table>
      <Table.Body>
        {organizations.map(organization => (
          <Table.Row
            hover
            caret
            key={organization?.id}
            onClick={() => navigate(`/admin/organizations/${organization?.id}/edit`)}
          >
            <Table.TD>{organization?.name}</Table.TD>
            <Table.TD collapsed className="whitespace-nowrap space-x-2">
              <Button
                variant="secondary"
                size="xs"
                onClick={e => {
                  impersonateOrganization.mutate(organization?.id);
                  e.stopPropagation();
                }}
                icon="Play"
              >
                Switch To
              </Button>
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
