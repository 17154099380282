import { Badge } from '@optra/kit';

import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import DataTypeLegend from 'modals/skill-pipeline-builder/components/data-type-legend';
import ListItem from 'modals/skill-pipeline-builder/components/list-item';
import { NODE_DEFS } from 'modals/skill-pipeline-builder/config';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import createBetaDef from 'modals/skill-pipeline-builder/context/create-beta-def';
import { useSkillBuilderBetaNodes } from 'queries';

export default function AddPipelineNodeInput() {
  const { addNode } = usePipelineContext();
  const type = 'inputs';
  const { data } = useSkillBuilderBetaNodes();
  const betaDefs = (data || []).filter(d => d?.type === type).map(createBetaDef);

  const nodeDefs = Object.values(NODE_DEFS).filter(n => n.type === type);
  const allDefs = [...nodeDefs, ...betaDefs];

  return (
    <ModalInner>
      <ModalTitle title="Add Input" icon="Plug" />
      <ModalBody>
        {allDefs.map(nodeDef => (
          <ListItem
            key={`node-type-${nodeDef.key}`}
            icon={nodeDef.icon}
            name={nodeDef.label}
            onClick={() => {
              if (nodeDef?.isBetaNode) {
                addNode({ betaNode: nodeDef });
              } else {
                addNode({ nodeType: nodeDef.key });
              }
            }}
            description={nodeDef.description}
            className="w-full"
            truncateDescription={false}
            inputs={nodeDef.inputs}
            outputs={nodeDef.outputs}
            renderBadge={() => {
              if (nodeDef?.isBetaNode) {
                return (
                  <Badge variant="danger" size="xs">
                    Beta
                  </Badge>
                );
              } else {
                return null;
              }
            }}
          />
        ))}
      </ModalBody>
      <ModalFooter className="md:min-w-modal-sm md:max-w-modal-sm">
        <DataTypeLegend alignment="justify-center" />
      </ModalFooter>
    </ModalInner>
  );
}
