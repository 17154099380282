import { has, isEmpty, startCase } from 'lodash';
import { useEffect, useState } from 'react';

import DropdownSelect from 'components/dropdown-select';
import { api, q } from 'config/api';
import useDebouncedState from 'hooks/use-debounced-state';
import useOutputs from 'queries/useOutputs';

export default function OutputSelect(props) {
  const [$search, setSearch] = useDebouncedState('');
  const Outputs = useOutputs({
    list: {
      filter: {
        $search,
      },
    },
  });

  const qc = q.useQueryClient();
  const createOutput = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createOutput($form: upsertOutputForm!) {
          createOutput(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['outputs'] });
    },
  });

  const options = Outputs.data?.map(output => ({ label: output.label, value: output.key })) || [];
  const [_value, setValue] = useState(props?.value);
  useEffect(() => {
    setValue(props?.value);
  }, [props?.value]);
  const value = has(_value, 'label') ? _value : options.find(opt => opt.value === _value?.value);

  return (
    <DropdownSelect
      maxMenuHeight="170px"
      {...props}
      value={value}
      onChange={selected => {
        setValue(selected);
        props?.onChange?.(selected);
      }}
      onInputChange={typedValue => setSearch(typedValue)}
      onCreateOption={newKey => {
        createOutput.mutate({ key: newKey });
        const created = { label: startCase(newKey), value: newKey };
        setValue(created);
        props?.onCreateOption?.({ ...created, __isNew__: true });
        props?.onChange?.({ ...created, __isNew__: true });
      }}
      isLoading={Outputs.isFetching && !Outputs.isFetched}
      allowCreateWhileLoading
      creatable
      isClearable
      formatCreateLabel={currentValue => `Create "${currentValue}"`}
      options={options}
      isValidNewOption={newOption => {
        if (isEmpty(newOption)) return false;
        if (newOption.includes(' ')) return false;
        if (Outputs.data?.map(o => o.key).includes(newOption)) return false;
        return true;
      }}
    />
  );
}
