import { set, unset, omit, cloneDeep, isEmpty } from 'lodash';
import { useReducer } from 'react';

import { NODE_DEFS, CATEGORIES_ORDER, DIAGNOSTICS_VIEW_KEY } from '../config';
import defaultPipeline from '../default-pipeline';

/**
 * Ensure new nodes are added in the right spot in the pipeline
 */
function sortPipelineKeys(pipeline) {
  const keysByType = Object.keys(pipeline).reduce((r, k) => {
    const type = pipeline[k].type;
    const typePrevVals = r[type] || [];
    return { ...r, [type]: [...typePrevVals, k] };
  }, {});

  const apply = (after, type) =>
    (keysByType[type] || []).reduce((after, k) => ({ ...after, [k]: pipeline[k] }), after);

  return CATEGORIES_ORDER.reduce(apply, {});
}

function addNode(state, payload) {
  let newState = {
    ...state,
  };
  const { nodeId, betaNode, nodeType, key, value } = payload;

  if (!isEmpty(betaNode)) {
    const defaultConfig = (betaNode?.configFields || []).reduce(
      (acc, configField) => ({
        ...acc,
        [configField.key]: configField.defaultValue,
      }),
      {},
    );
    newState.pipeline[nodeId] = {
      name: betaNode?.key,
      type: betaNode?.type,
      inputs: {},
      config: defaultConfig,
      meta: betaNode,
    };
  } else {
    const def = NODE_DEFS[nodeType];
    const { inputs = [], defaultConfig } = def;
    const defaultInputs = inputs.reduce((agg, input) => ({ ...agg, [input.key]: '' }), {});
    newState.pipeline[nodeId] = {
      ...set(
        {
          config: defaultConfig,
          inputs: defaultInputs,
        },
        key,
        value,
      ),
      type: def.type,
      name: def.key,
    };
  }

  newState.pipeline = sortPipelineKeys(newState.pipeline);
  return newState;
}

function upsertNode(state, payload) {
  let newState = {
    ...state,
  };
  const { nodeId, key, value } = payload;

  const curr = newState.pipeline?.[nodeId] || undefined;
  if (!curr) {
    return addNode(state, payload);
  }

  let next;
  if (value === null) {
    next = omit(curr, key);
  } else {
    next = set(cloneDeep(curr), key, value);
  }
  newState.pipeline[nodeId] = next;

  return newState;
}

function deleteNode(state, payload) {
  let newState = {
    ...state,
  };
  const { nodeId, nodeDef } = payload;

  // Find other nodes that have this node in their `inputs`
  const dependencies = Object.keys(newState.pipeline)
    .map(k => ({
      ...newState.pipeline[k],
      id: k,
    }))
    .flatMap(node => {
      if (node.inputs) {
        return Object.keys(node.inputs).map(inputKey => {
          if ((node?.inputs?.[inputKey] || '').split('.')[0] === nodeId) {
            return [node.id, node.name, inputKey];
          } else {
            return null;
          }
        });
      }
      return null;
    })
    .filter(Boolean);

  // Remove this node from the `inputs` key of other nodes
  dependencies.forEach(([nodeId, nodeKey, inputKey]) => {
    unset(newState, `pipeline.${nodeId}.inputs.${inputKey}`);
  });

  // Remove this node from the diagnostics view
  nodeDef.outputs.forEach(output => {
    const diagnosticsKey = `${nodeId}|${nodeDef.label} ${output.label}`;
    unset(newState, `pipeline.${DIAGNOSTICS_VIEW_KEY}.inputs.${diagnosticsKey}`);
  });

  // Delete the node itself
  delete newState.pipeline[nodeId];

  return newState;
}

function reorder(state, payload) {
  const { items = [], types } = payload;

  const validItems = items.filter(i => !isEmpty(i) && types.includes(i.type));
  if (validItems.length < 1) {
    return state;
  }

  const newItems = validItems.reduce((prev, item) => {
    return {
      ...prev,
      [item.id]: omit(item, 'id'),
    };
  }, {});

  const sortedPipeline = Object.entries(state.pipeline).reduce((prev, [k, v]) => {
    if (types.includes(v?.type)) {
      return {
        ...prev,
        ...newItems,
      };
    }

    return {
      ...prev,
      [k]: v,
    };
  }, {});

  return {
    ...state,
    pipeline: sortedPipeline,
  };
}

export function reducer(state, { action, payload }) {
  switch (action) {
    case 'upsertNode':
      return upsertNode(state, payload);
    case 'addNode':
      return addNode(state, payload);
    case 'deleteNode':
      return deleteNode(state, payload);
    case 'reorder':
      return reorder(state, payload);
    case 'reset':
      return defaultPipeline;
    case 'restore':
      return payload.pipeline;
    default:
      return state;
  }
}

export default function usePipelineReducer() {
  return useReducer(reducer, defaultPipeline);
}
