import { flatMap } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, DetailHeading, DetailList } from '@optra/kit';

import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';
import { api, q } from 'config/api';
import defaultPipeline from 'modals/skill-pipeline-builder/default-pipeline';
import { useSkillBuilderTemplates } from 'queries';

export default function CreateSkillTemplates() {
  const [error, setError] = useState();
  const { data, isLoading } = useSkillBuilderTemplates();
  const navigate = useNavigate();
  const templates = flatMap(data?.pages, page => page.list?.data);

  const createSkillBuilderSkill = q.useMutation({
    mutationFn: async form => {
      const result = await api(
        `mutation createSkillBuilderSkillVersion($form: createSkillBuilderSkillVersionForm!) {
          version: createSkillBuilderSkillVersion(form: $form) {
            id
            skill {
              id
            }
          }
        }`,
        { form },
      );
      return result;
    },
    onSuccess(r) {
      navigate(`/skills/${r?.version?.skill?.id}/versions/${r?.version?.id}/pipeline`);
    },
    onError(err) {
      setError(err);
    },
  });

  const handleChooseTemplate = template => {
    if (template && template.config) {
      createSkillBuilderSkill.mutate({
        version: '1.0.0',
        skill: {
          name: template.name,
          iconUrl: template.iconUrl,
          icon: template.icon,
          color: template.color,
        },
        pipeline: template.config,
      });
    } else {
      createSkillBuilderSkill.mutate({
        version: '1.0.0',
        skill: {
          name: 'New Skill',
        },
        pipeline: defaultPipeline,
      });
    }
  };

  return (
    <ModalInner>
      <ModalTitle title="Choose a Template" />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Select Template">
            {error.message}
          </Message>
        )}
        <List loading={isLoading}>
          <ListItem
            onClick={() => handleChooseTemplate()}
            renderLeft={() => <SkillIcon size="sm" />}
            renderRight={() => <Icon name="CaretRight" color="gray" size="sm" />}
            className="py-4"
          >
            <div>
              <DetailHeading variant="loud">Blank</DetailHeading>
              <DetailList details={['Start with an empty skill.']} />
            </div>
          </ListItem>
          {templates.map(template => (
            <ListItem
              key={`template-${template.id}`}
              onClick={() => handleChooseTemplate(template)}
              renderLeft={() => (
                <SkillIcon
                  iconUrl={template.iconUrl}
                  icon={template.icon}
                  color={template.color}
                  size="sm"
                />
              )}
              renderRight={() => <Icon name="CaretRight" color="gray" size="sm" />}
              className="py-4"
            >
              <div>
                <DetailHeading variant="loud">{template.name}</DetailHeading>
                <DetailList details={[template.description]} />
              </div>
            </ListItem>
          ))}
        </List>
      </ModalBody>
    </ModalInner>
  );
}
