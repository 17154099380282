import { camelCase, isEmpty, truncate, upperFirst } from 'lodash';
import { useParams } from 'react-router-dom';

import { Button, Card, Heading } from '@optra/kit';

import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ItemNotFound from 'modals/item-not-found';
import InputsCard from 'modals/skill-pipeline-builder/components/inputs-card';
import OutputListItem from 'modals/skill-pipeline-builder/components/output-list-item';
import { DIAGNOSTICS_VIEW_KEY } from 'modals/skill-pipeline-builder/config';
import CONFIG_FORMS from 'modals/skill-pipeline-builder/config-forms';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function EditPipelineNode() {
  const { handleClose } = useModalContext();
  const { nodeId: _nodeId } = useParams();
  const { updatePipeline, skillVersion } = usePipelineContext();
  const nodeId = _nodeId.endsWith('-output') ? upperFirst(camelCase(_nodeId)) : _nodeId;
  const [node, { nodeDef }] = usePipelineNode(nodeId);

  const { outputs = [] } = nodeDef || {};

  function handleChangeConfig({ key, value }) {
    updatePipeline({
      action: 'upsertNode',
      payload: {
        nodeKey: nodeDef.key,
        nodeId,
        key: `config.${key}`,
        value,
      },
    });
  }

  function handleDelete() {
    if (window.confirm('Are you sure you want to delete this node?')) {
      updatePipeline({ action: 'deleteNode', payload: { nodeId, nodeDef } });
      handleClose();
    }
  }

  if (!node && !skillVersion.isLoading && nodeDef?.type !== 'outputs') {
    return <ItemNotFound id={nodeId} type="Node" />;
  }

  let ConfigForm;
  if (nodeDef?.isBetaNode) {
    ConfigForm = CONFIG_FORMS.BetaNode;
  } else {
    ConfigForm = CONFIG_FORMS[nodeDef?.key];
  }
  const hasSelectedInputs =
    Object.values(node?.inputs || {}).length > 0 &&
    Object.values(node.inputs).every(v => !isEmpty(v));
  const showConfigForm =
    ConfigForm &&
    (nodeDef.key === DIAGNOSTICS_VIEW_KEY ||
      nodeDef.key === 'SourceImagePipelineInput' ||
      nodeDef.key === 'StaticImagePipelineInput' ||
      nodeDef.key === 'StaticVideoPipelineInput' ||
      nodeDef.key === 'CombineLabelsProcessor' ||
      nodeDef.key === 'StaticImageElement' ||
      hasSelectedInputs);

  return (
    <ModalInner>
      <ModalTitle
        title={nodeDef?.label ? truncate(nodeDef.label, { length: 32 }) : 'Loading'}
        icon={nodeDef?.icon}
        loading={skillVersion?.isLoading}
        renderActions={() => (
          <Button variant="secondary" size="xs" icon="Trash" onClick={handleDelete}>
            Delete
          </Button>
        )}
      />
      <ModalBody className="space-y-6">
        {nodeDef?.key !== 'CombineLabelsProcessor' && (
          <InputsCard
            nodeId={nodeId}
            node={node}
            nodeDef={nodeDef}
            onUpdatePipeline={updatePipeline}
          />
        )}

        {showConfigForm && (
          <ConfigForm nodeId={nodeId} config={node?.config} onChange={handleChangeConfig} />
        )}

        {outputs.length > 0 && (
          <div className="space-y-3">
            <Heading level={3}>Outputs</Heading>
            <Card
              variant="secondary"
              noPadding
              className="divide-y divide-light-fg-tertiary dark:divide-dark-fg-tertiary"
            >
              {outputs.map(output => (
                <OutputListItem key={output.key} output={output} nodeId={nodeId} />
              ))}
            </Card>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={handleClose} size="xl">
          Done
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
