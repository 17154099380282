import { isEmpty, trim, mapKeys, omit } from 'lodash';
import { Fragment, useState } from 'react';

import { Button, Card, Dropdown, Heading, IconButton } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Select from 'components/select';
import DataTypeHeading from 'modals/skill-pipeline-builder/components/data-type-heading';
import InputChooser from 'modals/skill-pipeline-builder/components/input-chooser';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import getNodeDef from 'modals/skill-pipeline-builder/context/get-node-def';
import { useLabelsCache } from 'modals/skill-pipeline-builder/context/use-input-labels';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

function useLabelRules(nodeId, idx) {
  const [node, { update }] = usePipelineNode(nodeId);
  const [label, rulesString] = Object.entries(node?.config?.logic || {})?.[idx];
  const labelsCache = useLabelsCache();
  const { orderedRules, orderedLogicalOperators } = rulesFromString(rulesString);
  const [labelInputValue, setLabelInputValue] = useState(label);

  function createRule(props) {
    const { output, comparisonOperator, value, logicalOperator } = props || {};
    let rules = orderedRules || [];
    let logicalOperators = orderedLogicalOperators || [];

    logicalOperators.push(logicalOperator || 'and');
    rules = [
      ...rules,
      {
        output: output || '',
        value: value || '',
        comparisonOperator: comparisonOperator || '==',
      },
    ];

    const nextRules = rulesToString(rules, { logicalOperators });
    update({
      key: 'config.logic',
      value: {
        ...node?.config?.logic,
        [label]: nextRules,
      },
    });

    return nextRules;
  }

  function removeRule(targetIdx) {
    const currentRules = orderedRules || [];
    const currentLogicalOperators = orderedLogicalOperators || [];

    let rules = currentRules;
    if (currentRules.length > 1) {
      rules = currentRules.filter((rule, idx) => idx !== targetIdx);
    }

    let logicalOperators = [];
    if (currentLogicalOperators.length > 1) {
      logicalOperators = currentLogicalOperators.filter((rule, idx) => idx - 1 !== targetIdx);
    }

    const nextRules = rulesToString(rules, { logicalOperators });
    update({
      key: 'config.logic',
      value: {
        ...node?.config?.logic,
        [label]: nextRules,
      },
    });

    return nextRules;
  }

  function updateRule(targetIdx, props) {
    const { output, comparisonOperator, value } = props || {};
    const rules = orderedRules || [];
    const logicalOperators = orderedLogicalOperators || [];

    const targetRule = rules?.[targetIdx];
    if (targetRule) {
      rules[targetIdx] = {
        comparisonOperator: comparisonOperator || targetRule.comparisonOperator,
        output: output || targetRule.output,
        value: value || targetRule.value,
      };
    }

    const nextRules = rulesToString(rules, { logicalOperators });
    update({
      key: 'config.logic',
      value: {
        ...node?.config?.logic,
        [label]: nextRules,
      },
    });

    return nextRules;
  }

  function updateLogicalOperator(_targetIdx, logicalOperator) {
    const targetIdx = _targetIdx - 1;
    const rules = orderedRules || [];
    const logicalOperators = orderedLogicalOperators || [];

    const targetOperator = logicalOperators?.[targetIdx];
    if (targetOperator) {
      logicalOperators[targetIdx] = logicalOperator;
    }

    const nextRules = rulesToString(rules, { logicalOperators });
    update({
      key: 'config.logic',
      value: {
        ...node?.config?.logic,
        [label]: nextRules,
      },
    });

    return nextRules;
  }

  function updateLabel(newLabel) {
    let cleanLabel = newLabel?.replace(/^\d/, ''); // JS objects will reorder keys starting with int
    setLabelInputValue(cleanLabel);

    const dupLabel = Object.keys(node?.config?.logic).find(l => l === cleanLabel);

    // Only update the pipeline if the label is unique
    if (!dupLabel) {
      const existingLabel = Object.keys(node?.config?.logic)[idx];
      update({
        key: 'config.logic',
        value: mapKeys(node?.config?.logic, (value, key) => {
          if (key === existingLabel) {
            return cleanLabel;
          }
          return key;
        }),
      });
    }
  }

  function labelAliasToInputString(labelAlias) {
    return (node?.inputs || {})?.[labelAlias] || '';
  }

  function inputStringToLabelAlias(inputString) {
    return Object.keys(node?.inputs || {}).find(key => (node?.inputs || {})?.[key] === inputString);
  }

  function rulesFromString(str) {
    const rulesAndLogicalOperators = str
      .split(/(\w+ !*=+.'[\w\s\d]+')/gi)
      .map(s => s.trim())
      .filter(s => s !== '');

    const orderedRules = [];
    const orderedLogicalOperators = [];

    rulesAndLogicalOperators.forEach(ruleOrLogic => {
      if (['and', 'or'].includes(ruleOrLogic)) {
        orderedLogicalOperators.push(ruleOrLogic);
        return;
      }
      const rule = ruleOrLogic;
      const comparisonOperator = rule.includes('!=') ? '!=' : '==';
      const value = rule.match(/'.*'/gi)?.[0]?.replaceAll("'", '') || '';
      const output = trim(rule.replace(comparisonOperator, '').replace(`'${value}'`, '')) || '';

      const inputKey = labelAliasToInputString(output);
      const [parentNodeId, outputKey] = inputKey?.split('.');
      const labels = labelsCache?.[parentNodeId]?.[outputKey] || [];

      orderedRules.push({
        comparisonOperator,
        output: isEmpty(output) ? '' : output,
        value: isEmpty(value) ? '' : value,
        labels,
      });
    });

    if (orderedRules.length < 1) {
      orderedRules.push({
        comparisonOperator: '',
        output: '',
        value: '',
        labels: [],
      });
    }

    return {
      orderedRules,
      orderedLogicalOperators,
    };
  }

  function rulesToString(rules, ctx) {
    const { logicalOperators } = ctx || {};
    return rules
      .map((rule, idx) => {
        const logicalOperator = logicalOperators[idx - 1] || '';
        return trim(`${logicalOperator} ${rule.output} ${rule.comparisonOperator} '${rule.value}'`);
      })
      .join(' ');
  }

  return {
    label,
    labelInputValue,
    updateLabel,
    updateRule,
    createRule,
    removeRule,
    updateLogicalOperator,
    orderedRules,
    orderedLogicalOperators,
    labelAliasToInputString,
    inputStringToLabelAlias,
  };
}

function InputsCard({ nodeId }) {
  const [node, { nodeDef, update, availableOutputsForInputType }] = usePipelineNode(nodeId);
  const inputType = nodeDef?.inputs[0]?.type;
  const availableOutputs = availableOutputsForInputType({ type: inputType });

  function handleChangeInput({ inputKey, value }) {
    const existingInput = Object.entries(node?.inputs || {}).find(([k, v]) => v === value);
    if (existingInput) {
      const [existingKey] = existingInput;
      update({
        key: `inputs.${existingKey}`,
        value: '',
      });
    }
    update({
      key: `inputs.${inputKey}`,
      value,
    });
  }

  function handleAddInput() {
    const currentLength = Object.keys(node.inputs).length;
    const prevInputKey = Object.keys(node.inputs)[currentLength];

    if (currentLength >= availableOutputs.length) {
      return;
    }

    update({
      key: `inputs.input_label_${currentLength + 1}`,
      value: node.inputs[prevInputKey],
    });
  }

  function handleRemoveInput(index) {
    update({
      key: `inputs.input_label_${index + 1}`,
      value: null,
    });
  }

  return (
    <div>
      <div className="space-y-3">
        <Heading level={3}>Inputs</Heading>
        <Card
          variant="secondary"
          noPadding
          className="divide-y divide-light-fg-tertiary dark:divide-dark-fg-tertiary"
        >
          {Object.keys(node?.inputs).map((inputKey, index) => {
            const inputDef = nodeDef?.inputs[0];
            return (
              <div key={inputKey} className="grid grid-cols-8 gap-2 items-center p-4">
                <div className="col-span-3">
                  <DataTypeHeading type={inputDef.type} label={inputDef.label} />
                </div>
                <div className="flex col-span-5 items-center justify-center space-x-2">
                  <InputChooser
                    nodeId={nodeId}
                    type={inputDef.type}
                    value={node?.inputs?.[inputKey] || ''}
                    onChange={value => {
                      handleChangeInput({ inputKey, value });
                    }}
                  />
                  <IconButton
                    name="Minus"
                    variant="tertiary"
                    disabled={index < 2}
                    onClick={() => handleRemoveInput(index)}
                  />
                  <IconButton
                    name="Plus"
                    variant="tertiary"
                    disabled={Object.keys(node?.inputs).length >= availableOutputs.length}
                    onClick={handleAddInput}
                  />
                </div>
              </div>
            );
          })}
        </Card>
      </div>
    </div>
  );
}

function RuleListItem({ rule, nodeId, labelIdx, idx, logicalOperator }) {
  const { pipeline } = usePipelineContext();
  const [node, { nodeDef }] = usePipelineNode(nodeId);
  const inputType = nodeDef?.inputs[0]?.type;

  const {
    updateRule,
    createRule,
    removeRule,
    updateLogicalOperator,
    orderedRules,
    labelAliasToInputString,
    inputStringToLabelAlias,
  } = useLabelRules(nodeId, labelIdx);

  const selectedInputIds = Object.entries(node?.inputs || {}).map(
    ([label, key]) => key?.split('.')?.[0],
  );
  const availableOutputs = selectedInputIds.flatMap(id => {
    const outputNode = {
      id,
      ...pipeline.pipeline[id],
    };
    const { outputs = [] } = getNodeDef(outputNode);
    const validOutputs = outputs
      .filter(o => {
        // Handle `type` param being an Array
        if (Array.isArray(inputType)) {
          return inputType.some(t => t.key === o.type.key);
        }
        return inputType.key === o.type.key;
      })
      .map(o => ({ ...o, node: outputNode }));
    return validOutputs;
  });

  return (
    <>
      {logicalOperator && (
        <>
          <div className="col-span-4" />
          <div className="col-span-4">
            <Select
              name="logicalOperator"
              value={logicalOperator}
              onChange={e => {
                const logicalOperator = e.target.value;
                updateLogicalOperator(idx, logicalOperator);
              }}
            >
              <option value="and">AND</option>
              <option value="or">OR</option>
            </Select>
          </div>
          <div className="col-span-4" />
        </>
      )}
      <div className="col-span-5">
        <InputChooser
          nodeId={nodeId}
          type={inputType}
          value={labelAliasToInputString(rule.output || '')}
          availableOutputs={availableOutputs}
          onChange={value => {
            updateRule(idx, { output: inputStringToLabelAlias(value) });
          }}
        />
      </div>
      <div className="col-span-2">
        <Select
          name="comparisonOperator"
          value={rule.comparisonOperator ?? '=='}
          onChange={e => {
            const comparisonOperator = e.target.value;
            updateRule(idx, { comparisonOperator });
          }}
        >
          <option value="==">=</option>
          <option value="!=">≠</option>
        </Select>
      </div>
      <div className="col-span-3">
        <Select
          name="value"
          value={rule.value ?? ''}
          onChange={e => {
            const value = e.target.value;
            updateRule(idx, { value });
          }}
        >
          <option value="">Choose a label…</option>
          {rule.labels.map(label => (
            <option key={label} value={label}>
              {label}
            </option>
          ))}
        </Select>
      </div>
      <div className="col-span-2 grid grid-cols-2">
        <div className="flex items-center justify-center">
          <IconButton
            name="Minus"
            variant="tertiary"
            disabled={orderedRules.length === 1}
            onClick={() => {
              removeRule(idx);
            }}
          />
        </div>
        <div className="flex items-center justify-center">
          <IconButton
            name="Plus"
            variant="tertiary"
            disabled={orderedRules.length - 1 !== idx}
            onClick={() => {
              createRule({ output: rule.output, value: rule.value });
            }}
          />
        </div>
      </div>
    </>
  );
}

function CombinedLabelCard({ nodeId, idx, onDuplicate, onDelete }) {
  const { labelInputValue, updateLabel, orderedRules, orderedLogicalOperators } = useLabelRules(
    nodeId,
    idx,
  );

  return (
    <Card variant="secondary" className="space-y-6">
      <div>
        <Label htmlFor="label" className="mb-2">
          Label Name
        </Label>
        <div className="flex flex-row items-center space-x-2">
          <Input
            type="text"
            name="label"
            value={labelInputValue}
            onChange={event => {
              updateLabel(event?.target?.value);
            }}
          />
          <Dropdown
            components={{
              button: <IconButton name="DotsThree" variant="tertiary" />,
              body: (
                <>
                  <Dropdown.Item
                    icon="Copy"
                    text="Duplicate Label"
                    onClick={() => onDuplicate(idx)}
                  />
                  <Dropdown.Item
                    icon="Trash"
                    text="Delete Label"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this label?')) {
                        onDelete(idx);
                      }
                    }}
                  />
                </>
              ),
            }}
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-y-4 gap-x-2 items-center">
        <div className="col-span-12">
          <Label>Conditions</Label>
        </div>
        {orderedRules.map((rule, ridx) => (
          <RuleListItem
            key={`rule-${ridx}`}
            rule={rule}
            nodeId={nodeId}
            labelIdx={idx}
            idx={ridx}
            logicalOperator={orderedLogicalOperators?.[ridx - 1]}
          />
        ))}
      </div>
    </Card>
  );
}

export default function CombineLabelsProcessor({ nodeId }) {
  const [node, { update }] = usePipelineNode(nodeId);

  function handleAddLabel() {
    update({
      key: 'config.logic',
      value: {
        ...node?.config?.logic,
        '': '',
      },
    });
  }

  function handleDuplicateLabel(idx) {
    const [label, rules] = Object.entries(node?.config?.logic)[idx];

    update({
      key: 'config.logic',
      value: {
        ...node?.config?.logic,
        [`${label} Copy`]: rules,
      },
    });
  }

  function handleDeleteLabel(idx) {
    const label = Object.keys(node?.config?.logic)[idx];
    update({
      key: 'config.logic',
      value: omit(node?.config?.logic, label),
    });
  }

  return (
    <div className="space-y-6">
      <InputsCard nodeId={nodeId} />
      <div className="space-y-3">
        <Heading level={3}>Labels</Heading>
        {Object.keys(node?.config?.logic || {}).map((k, idx) => (
          <CombinedLabelCard
            key={`label-${idx}`}
            nodeId={nodeId}
            idx={idx}
            onDuplicate={handleDuplicateLabel}
            onDelete={handleDeleteLabel}
          />
        ))}
        <div className="flex flex-col items-center">
          <Button icon="Plus" variant="tertiary" size="sm" onClick={handleAddLabel}>
            Add Label
          </Button>
        </div>
      </div>
    </div>
  );
}
