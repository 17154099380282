import { flatMap } from 'lodash';

import LogsScroller from 'components/logs-scroller';
import { useDeploymentLogs } from 'queries';

export default function SyncProgressLogs(props) {
  const { deploymentId } = props;

  const deploymentLogs = useDeploymentLogs({
    deploymentId,
  });

  const flatLogs = flatMap(deploymentLogs.data?.pages, page => page?.logs);

  return (
    <LogsScroller
      logs={flatLogs}
      loading={deploymentLogs.isLoading}
      hasMore={deploymentLogs.hasNextPage}
      loadingMore={deploymentLogs.isFetchingNextPage}
      onLoadMore={deploymentLogs.fetchNextPage}
    />
  );
}
