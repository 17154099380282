import { isFunction, max, mean, min, round as _round, sum, nth, isEmpty } from 'lodash';

import alias from 'modals/chart-details/charts-query-engine/alias';
import round from 'modals/chart-details/charts-query-engine/round';
import helpers from 'modals/chart-details/helpers';

// resolves totals in-memory
const defaultTotal = func => _list => {
  if (!Array.isArray(_list) || !isFunction(func)) return 0;
  const list = _list?.filter?.(value => !Number.isNaN(value));
  return _round(func(list), 3);
};

// resolves select statement in query
const defaultSelect =
  (aggregateFn, castAs) =>
  ({ metric }) => {
    const metricName = helpers.parseVariableId(metric);
    const resolvedMetric = !isEmpty(castAs)
      ? `try_cast("${metricName}" as ${castAs})`
      : `"${metricName}"`;
    const fnStatement = `${aggregateFn}(${resolvedMetric})`; // Example: sum(try_cast(thing as boolean))

    return alias({
      text: round(fnStatement),
      alias: metricName,
    });
  };

export default {
  LAST: {
    type: 'LAST',
    name: 'Last',
    nickname: 'Last',
    totalResolver: list => nth(list, -1) ?? 0,
    resolver: ({ metric }) => {
      const metricName = helpers.parseVariableId(metric);
      return alias({
        text: round(`element_at(array_agg("${metricName}"), -1)`),
        alias: metricName,
      });
    },
  },
  COUNT: {
    type: 'COUNT',
    name: 'Count',
    nickname: 'Count',
    totalResolver: defaultTotal(sum),
    resolver: defaultSelect('count'),
  },
  SUM: {
    type: 'SUM',
    name: 'Sum',
    nickname: 'Sum',
    totalResolver: defaultTotal(sum),
    resolver: defaultSelect('sum', 'double'),
  },
  AVG: {
    type: 'AVG',
    name: 'Average',
    nickname: 'Avg',
    totalResolver: defaultTotal(mean),
    resolver: defaultSelect('avg', 'double'),
  },
  MIN: {
    type: 'MIN',
    name: 'Minimum',
    nickname: 'Min',
    totalResolver: defaultTotal(min),
    resolver: defaultSelect('min', 'double'),
  },
  MAX: {
    type: 'MAX',
    name: 'Maximum',
    nickname: 'Max',
    totalResolver: defaultTotal(max),
    resolver: defaultSelect('max', 'double'),
  },
};
