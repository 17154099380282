import { Card } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';

export default function SkillContainerFields({ visible, loading, register }) {
  return (
    <Card variant="secondary" className={visible ? 'block' : 'hidden'}>
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="repository.uri">Docker Container URL</Label>
          <Input
            type="text"
            id="repository.uri"
            {...register('repository.uri', {
              required: 'Please enter a Container URL',
              pattern: {
                value: /[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                message:
                  'Docker uri must have a valid domain. If your image is hosted on docker hub please prefix with docker.io',
              },
            })}
            readOnly={loading}
          />
        </div>

        <div className="flex space-x-2">
          <div className="space-y-2">
            <Label htmlFor="repository.username">Registry Username</Label>
            <Input
              type="text"
              id="repository.username"
              {...register('repository.username')}
              readOnly={loading}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="repository.password">Registry Password</Label>
            <Input
              type="password"
              id="repository.password"
              {...register('repository.password')}
              readOnly={loading}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}
