import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Card } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';

export default function CreateTag() {
  const { handleBack } = useModalContext();

  const {
    handleSubmit: onSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const [error, setError] = useState();

  const qc = q.useQueryClient();
  const createTag = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createTag($form: createTagForm!) {
          createTag(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['tags'] });
      reset({
        name: '',
      });
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    createTag.mutate(form, {
      onSuccess: () => {
        handleBack();
      },
    });
  });

  return (
    <>
      <ModalTitle title="New Tag" icon="Tag" showBackButton={true} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create Tag">
            {error.message}
          </Message>
        )}

        <form onSubmit={handleSubmit}>
          <Card variant="secondary">
            <div className="flex flex-col space-y-2">
              <Label htmlFor="name">Tag Name</Label>
              <Input
                type="text"
                placeholder="New Tag"
                readOnly={createTag.isPending}
                {...register('name', { required: 'Please enter a tag name.' })}
              />
              <ValidationError errors={errors} name="name" />
            </div>
          </Card>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" onClick={handleSubmit} loading={createTag.isPending} size="xl">
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
