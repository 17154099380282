import { last } from 'lodash';

import { Button } from '@optra/kit';

import { api, q } from 'config/api';

export default function ExportPurchaseOrderGiftCardsButton({ giftCardPurchaseOrder }) {
  const exportFile = q.useMutation({
    mutationFn: form =>
      api(
        `mutation ExportGiftCardPurchaseOrderCodes($id: ID!) {
         export: exportGiftCardPurchaseOrderCodes(id: $id) {
           url
         }
       }`,
        { id: form.id },
      ),
  });

  return (
    <Button
      variant="tertiary"
      onClick={async () => {
        if (exportFile.isPending) {
          return;
        }

        const exportResult = await exportFile.mutateAsync({
          id: giftCardPurchaseOrder.id,
        });
        const exportData = exportResult.export;

        const link = document.createElement('a');
        link.download = last(exportData.url.split('/'));
        link.href = exportData.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }}
      icon="Export"
      iconWeight="line"
      loading={exportFile.isPending}
      disabled={exportFile.isPending}
    >
      Export
    </Button>
  );
}
