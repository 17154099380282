import React from 'react';

import { QueryProvider } from 'config/api';
import AuthProvider from 'providers/auth-provider';
import FeaturesProvider from 'providers/features-provider';
import OrganizationProvider from 'providers/organization-provider';
import ProviderComposer from 'providers/provider-composer';
import RolesProvider from 'providers/roles-provider';
import StripeProvider from 'providers/stripe-provider';

export default function ContextProviders(props) {
  return (
    <ProviderComposer
      providers={[
        <OrganizationProvider key="OrganizationProvider" />,
        <AuthProvider key="AuthProvider" />,
        <QueryProvider key="QueryProvider" />,
        <RolesProvider key="RolesProvider" />,
        <FeaturesProvider key="FeaturesProvider" />,
        <StripeProvider key="StripeProvider" />,
      ]}
    >
      {props?.children}
    </ProviderComposer>
  );
}
