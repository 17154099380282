import { Button, Dropdown, DROPDOWN_MENU_PLACEMENT, Icon, Spinner } from '@optra/kit';

import DropdownMenuItem from 'components/dropdown-menu-item';
import ListItem from 'components/list-item';
import PaymentMethodIcon from 'components/payment-method-icon';
import PaymentMethodLabel from 'components/payment-method-label';
import { api, q } from 'config/api';

export default function PaymentMethodListItem(props) {
  const { id, processor, expiration, isDefault, last4, to } = props;
  const qc = q.useQueryClient();
  const deletePaymentMethod = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removePaymentMethod($id: ID!) {
          removePaymentMethod(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['paymentMethods'] });
    },
  });

  const updatePaymentMethod = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updatePaymentMethod($form: updatePaymentMethodForm!) {
          updatePaymentMethod(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['paymentMethods'] });
    },
  });

  function handleDelete() {
    if (window.confirm('Are you sure you want to delete this credit card?')) {
      deletePaymentMethod.mutate(id);
    }
  }

  function handleMakeDefault() {
    if (window.confirm('Are you sure you want to make this credit card the default?')) {
      updatePaymentMethod.mutate({
        id,
        makeDefault: true,
      });
    }
  }

  const loading = deletePaymentMethod.isPending || updatePaymentMethod.isPending;

  return (
    <ListItem
      key={id}
      renderLeft={() => <PaymentMethodIcon brand={processor} />}
      renderRight={() => (
        <Dropdown placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}>
          <Dropdown.Button unstyled>
            {({ isOpen }) => {
              return (
                <Button as="span" icon="Gear" size="xs" variant="tertiary">
                  {!loading && (
                    <Icon name={isOpen ? 'CaretUp' : 'CaretDown'} size="xs" className="ml-1" />
                  )}
                  {loading && <Spinner size="xs" />}
                </Button>
              );
            }}
          </Dropdown.Button>
          <Dropdown.MenuBody>
            {!isDefault && (
              <DropdownMenuItem onClick={handleMakeDefault} icon="Check" label="Make Default" />
            )}
            <DropdownMenuItem to={to} state={{ fromModal: true }} icon="Pencil" label="Edit" />
            <DropdownMenuItem
              onClick={handleDelete}
              icon="Trash"
              label="Delete"
              loading={deletePaymentMethod.isPending}
            />
          </Dropdown.MenuBody>
        </Dropdown>
      )}
    >
      <div className="flex items-center space-x-2">
        <PaymentMethodLabel
          brand={processor}
          last4={last4}
          expiration={expiration}
          isDefault={isDefault}
        />
      </div>
    </ListItem>
  );
}
