import { api, q } from 'config/api';

export default function useSkillBuilderSkills(variables, overrides) {
  return q.useQuery({
    queryKey: ['skillBuilderSkills', variables],
    queryFn: () =>
      api(
        `
          query skillBuilderSkills($list: listFields) {
            list: edgeImpulseSkills(list: $list) {
              data {
                id
                name
                icon
                iconUrl
                color
              }
            }
          }
        `,
        {
          ...variables,
          list: {
            limit: 3,
            sort: {
              direction: 'desc',
            },
            ...variables?.list,
          },
        },
      ),
    placeholderData: q.keepPreviousData,
    ...overrides,
  });
}
