import cx from 'classnames';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import { getChildByType } from 'react-nanny';

import { Dropdown } from '@optra/kit';

import { useChartCtx } from 'modals/chart-details/context';
import VariableSelectorMetrics from 'modals/chart-details/controls/variable-selector/variable-selector-metrics';

import MetricSelectorCalculations from './metric-selector-calculations';
import MetricSelectorChartTypes from './metric-selector-chart-types';

function MetricDropdownMenu(props) {
  const {
    onSelect = noop,
    value,
    showCalculations = true,
    showCharts = true,
    disableVariables = true,
    disableNonComposeCharts = false,
  } = props;

  const {
    helpers: { getUsedVariables, variableTableMatchesConfig },
  } = useChartCtx();

  const usedVariables = getUsedVariables();

  const variableAlreadyInUse = variable => includes(usedVariables, variable);
  const variableIncompatible = variable => !variableTableMatchesConfig(variable);

  return (
    <div
      className={cx(
        'flex flex-row flex-nowrap',
        'divide-x divide-light-fg-tertiary dark:divide-gray-200/10',
      )}
    >
      <VariableSelectorMetrics
        disableVariables={disableVariables}
        variableAlreadyInUse={variableAlreadyInUse}
        variableIncompatible={variableIncompatible}
        value={value?.metric}
        onClick={metric => {
          if (disableVariables && variableAlreadyInUse(metric)) return;
          // TODO: Partial value updates?! WHAT?
          onSelect({ metric });
        }}
      />
      {!!showCalculations && (
        <MetricSelectorCalculations
          value={value?.calculation}
          onClick={calculation => onSelect({ calculation })}
        />
      )}
      {!!showCharts && (
        <MetricSelectorChartTypes
          value={value?.type}
          disableNonComposeCharts={disableNonComposeCharts}
          onClick={type => onSelect({ type })}
        />
      )}
    </div>
  );
}

function MetricSelectorButton({ children }) {
  return children;
}

function MetricSelector(props) {
  const { className, dropdown = true, disabled, children, ...rest } = props;
  if (!dropdown) {
    return <MetricDropdownMenu disabled={disabled} {...rest} />;
  }

  const button = getChildByType(children, [MetricSelectorButton]);

  return (
    <Dropdown
      className={cx(className, disabled && 'opacity-50 cursor-not-allowed')}
      disabled={disabled}
    >
      <Dropdown.Button unstyled>{button}</Dropdown.Button>
      <Dropdown.Body unstyled>
        <MetricDropdownMenu {...rest} />
      </Dropdown.Body>
    </Dropdown>
  );
}

MetricSelector.Button = MetricSelectorButton;

export default MetricSelector;
