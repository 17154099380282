import * as date from 'date-fns';

import { Table, Text } from '@optra/kit';

export default function AdminUsageExportsList({ usageExports = [] }) {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TH>Organization</Table.TH>
          <Table.TH>Timestamp</Table.TH>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {usageExports.map(usageExport => {
          return (
            <Table.Row
              key={usageExport.id}
              hover
              caret
              loading={usageExport.state.isInProgess}
              disabled={usageExport.state.isInProgess || usageExport.state.isTimedOut}
              onClick={() => {
                if (usageExport.state.isInProgess || usageExport.state.isTimedOut) return;
                const link = document.createElement('a');
                link.download = true;
                link.href = usageExport.url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              <Table.TD>
                <Text size="xs" color="muted">
                  {usageExport.isAll ? 'all' : usageExport.organization?.name || 'Unknown Owner'}
                </Text>
              </Table.TD>
              <Table.TD>
                <Text className="text-xs uppercase font-mono">
                  {date.format(usageExport.timestamp, 'yyyy-MM-dd hh:mm:ss a')}
                </Text>
              </Table.TD>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
