import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'StaticVideoPipelineInput',
  label: 'Video File',
  icon: 'Video',
  description: 'Receive frames from a video file.',
  type: 'inputs',
  inputs: [],
  outputs: [
    {
      key: 'image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
  schema: z.object({
    config: z.object({ file_path: z.string().url() }),
  }),
};
