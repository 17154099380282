import cx from 'classnames';

export default function TBody({ children, className, ...rest }) {
  return (
    <tbody
      className={cx(['divide-y divide-light-fg-tertiary dark:divide-dark-bg-secondary', className])}
      {...rest}
    >
      {children}
    </tbody>
  );
}
