import { useState } from 'react';

import { PageWrapper, PrimaryCTAButton } from '@optra/kit';

import AdminPreclaimedDevicesList from 'components/admin-preclaimed-devices-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import OrganizationSelect from 'components/organization-select';
import PinPageButton from 'components/pin-page-button';
import { usePreclaimedDevices } from 'queries';

export default function AdminPreclaimedDevices() {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('all');
  const preclaimedDevices = usePreclaimedDevices({
    list: {
      filter: { organizationId: selectedOrganizationId === 'all' ? null : selectedOrganizationId },
    },
  });
  const devices = preclaimedDevices.data?.pages?.flatMap?.(page => page?.list?.data);

  const handleSelectOrganizationId = nextOrganizationId => {
    setSelectedOrganizationId(nextOrganizationId);
  };

  return (
    <PageWrapper
      icon="Receipt"
      title="Preclaimed Devices | Admin"
      heading="Preclaimed Devices"
      inline
      loading={preclaimedDevices?.isLoading}
      error={preclaimedDevices?.error}
      components={{
        actions: (
          <>
            <OrganizationSelect
              onChange={handleSelectOrganizationId}
              value={selectedOrganizationId}
              filter={{ all: true }}
            />
            <PinPageButton />
            <PrimaryCTAButton
              to={`/admin/preclaimed-devices/upload?organizationId=${selectedOrganizationId}`}
              text="Upload List"
              icon="CloudArrowUp"
            />
          </>
        ),
      }}
    >
      <AdminPreclaimedDevicesList
        error={preclaimedDevices?.error}
        list={devices}
        selectedOrganizationId={selectedOrganizationId}
      />
      <IntersectionLoadMore
        onVisible={preclaimedDevices.fetchNextPage}
        disabled={preclaimedDevices.isFetchingNextPage || !preclaimedDevices.hasNextPage}
      />
    </PageWrapper>
  );
}
