import cx from 'classnames';

export default function FillParent({ centerContent = true, children, className, ...rest }) {
  return (
    <div
      className={cx(
        'absolute inset-0',
        centerContent && 'flex items-center justify-center',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
