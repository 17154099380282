import { api, q } from 'config/api';

export default function useDeploymentTaskStatus(taskId, overrides) {
  return q.useQuery({
    queryKey: ['workflowDeploymentExecutionTask', taskId, 'status'],
    queryFn: () =>
      api(
        `query ($taskId: ID!) {
          task: workflowDeploymentExecutionTask(id: $taskId) {
            skillsStatus
          }
        }`,
        { taskId },
      ),
    refetchInterval: 5000,
    enabled: !!taskId,
    ...overrides,
  });
}
