import { last, isEmpty, isArray } from 'lodash';
import Papa from 'papaparse';
import { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { FileInput, PrimaryCTAButton, Button } from '@optra/kit';

import CsvDocs from 'components/csv-docs';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useSignedUpload } from 'queries';

const helpColumns = [
  {
    column: 'id',
    name: 'ID',
    description: 'Item type and ID (or serial number) separated by ":"',
    examples: [
      'devices:324856078687797839',
      'devices:8080000000001',
      'workflowsSkills:324856078687797839',
    ],
    required: true,
  },
  {
    column: 'name',
    name: 'Name',
    description: 'Name of the skill or device',
    examples: ['Item Name'],
    required: false,
  },
  {
    column: 'key',
    name: 'Key',
    description: 'Env or Input key',
    examples: ['MY_ENV_VAR'],
    required: true,
  },
  {
    column: 'value',
    name: 'Value',
    description: 'Env or Input value',
    examples: ['myValue'],
    required: true,
  },
  {
    column: 'type',
    name: 'Type',
    description: 'Either "env" or "input"',
    examples: ['env', 'input'],
    required: true,
  },
];

export default function BulkEditWorkflow() {
  useTitle('Bulk Edit | Optra');
  const { workflowId } = useParams();
  const navigate = useNavigate();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileChecking, setFileChecking] = useState(false);
  const {
    handleSubmit: onSubmit,
    watch,
    setError,
    reset,
    register,
    control,
  } = useForm({
    defaultValues: {
      file: null,
    },
  });
  const { errors } = useFormState({ control });
  const selectedFile = watch('file');

  const [upload, uploadState] = useSignedUpload({ type: 'bulkEnrollments' });

  const qc = q.useQueryClient();
  const importWorkflowConfig = q.useMutation({
    mutationFn: form => {
      return api(
        `mutation importWorkflowConfig($form: importWorkflowConfigForm!) {
          r: importWorkflowConfig(form: $form) {
            success
            errors
          }
        }`,
        { form },
      );
    },
    onSuccess({ r } = {}) {
      qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
      qc.invalidateQueries({ queryKey: ['workflows'] });

      if (r?.errors?.length > 0 || !isEmpty(r?.error?.message)) {
        setError('file', {
          type: 'manual',
          message: r?.errors,
        });
        return;
      }

      navigate('/workflows');
    },
    onError(err) {
      setError('file', {
        type: 'manual',
        message: err.message,
      });
    },
  });

  const hasErrors = !isEmpty(errors);

  const exportConfig = q.useMutation({
    mutationFn: () =>
      api(
        `mutation exportWorkflowConfig($id: ID!) {
          csv: exportWorkflowConfig(id: $id) {
            url
          }
        }`,
        {
          id: workflowId,
        },
      ),
    onSuccess({ csv }) {
      const link = document.createElement('a');
      link.download = last(csv.url.split('/'));
      link.href = csv.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });

  const handleUpload = async e => {
    try {
      fileRegistration.onChange(e);
      setFileChecking(true);
      setFileUploaded(false);
      const blob = e.currentTarget.files?.[0];

      if (!blob) {
        setError('file', {
          type: 'manual',
          message: 'CSV is required.',
        });
        return;
      }

      await new Promise(resolve =>
        Papa.parse(blob, {
          worker: true,
          header: true,
          skipEmptyLines: true,
          async step({ data, errors }, parser) {
            if (errors?.length > 0) {
              setError('file', {
                type: 'manual',
                message: `Row ${errors?.[0]?.row}: ${errors?.[0]?.message}`,
              });
              parser.abort();
              return;
            }

            if (isEmpty(data?.id)) {
              setError('file', {
                type: 'manual',
                message: 'Missing id value.',
              });
              parser.abort();
              return;
            }

            const validId = /(devices|workflowsSkills):([0-9a-zA-Z])+$/;
            if (!validId.test(data?.id)) {
              setError('file', {
                type: 'manual',
                message: `ID "${data.id}" has invalid format.`,
              });
              parser.abort();
              return;
            }

            if (isEmpty(data?.type) || !['env', 'input'].includes(data?.type)) {
              setError('file', {
                type: 'manual',
                message: 'Missing or invalid type.',
              });
              parser.abort();
            }
          },
          complete() {
            resolve();
          },
        }),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setFileChecking(false);
      setFileUploaded(true);
    }
  };

  const handleSubmit = onSubmit(async form => {
    if (fileChecking) return;

    const blob = form.file[0];
    if (!blob) {
      setError('file', {
        type: 'manual',
        message: 'A CSV file is required',
      });

      return;
    }
    if (hasErrors) return;

    const { key } = await upload(blob, {
      extension: last(blob.name.split('.')),
    });

    importWorkflowConfig.mutate({
      id: workflowId,
      key,
    });
  });

  function handleReset() {
    reset();
    setFileUploaded(false);
    setFileChecking(false);
  }

  const submitting = uploadState?.fetching || importWorkflowConfig.isPending;
  const currentFile = watch('file');
  const hasFile = currentFile?.length === 1;
  const fileRegistration = register('file', { required: true });

  let detail = 'Waiting for CSV file…';
  let error =
    (!isEmpty(errors?.file?.message) && !isArray(errors?.file?.message) && errors.file.message) ||
    (isArray(errors?.file?.message) && (
      <>
        {/* Only take the first 4 messages; more than that won't fit */}
        {errors?.file?.message.slice(0, 4).map((m, idx) => (
          <div key={idx}>{m}</div>
        ))}
      </>
    ));

  if (hasFile && fileUploaded) {
    if (importWorkflowConfig.isPending) {
      detail = 'Processing configuration…';
    } else {
      detail = 'CSV file ready for upload…';
    }
  } else {
    if (fileChecking) {
      detail = 'Validating file…';
    } else if (uploadState?.fetching) {
      detail = `${uploadState.percent} Complete`;
    } else if (submitting) {
      detail = 'Processing file…';
    }
  }

  return (
    <ModalInner>
      <ModalTitle
        title="Bulk Upload"
        renderActions={() => (
          <PrimaryCTAButton
            onClick={async () => {
              if (exportConfig.isPending) return;
              exportConfig.mutate();
            }}
            loading={exportConfig.isPending}
            icon="DownloadSimple"
            text="Export Current Config"
            variant="secondary"
            size="xs"
          />
        )}
      />
      <div className="flex-shrink-0 px-4">
        <FileInput
          prompt="Upload CSV File"
          result={selectedFile?.[0]}
          accept=".csv"
          {...fileRegistration}
          onChange={handleUpload}
          detail={detail}
          error={error}
          loading={uploadState?.fetching || fileChecking}
        >
          {hasErrors && (
            <Button size="xs" variant="tertiary" onClick={handleReset}>
              Reset
            </Button>
          )}
        </FileInput>
      </div>
      <ModalBody>
        <CsvDocs columns={helpColumns} />
      </ModalBody>
      <ModalFooter>
        <Button
          size="xl"
          loading={submitting}
          disabled={!hasFile || fileChecking}
          onClick={handleSubmit}
        >
          Upload
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
