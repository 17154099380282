import { isEqual, isString, get, set, isNumber, isBoolean } from 'lodash';
import qs from 'qs';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useRouteQueryParams, parseSearchParams } from 'hooks';

export default function useRouteQueryState(ops = {}, defaultState) {
  let path;
  let method;
  if (isString(ops)) {
    path = ops;
    method = 'replace';
  } else {
    path = ops.path;
    method = ops.method || 'replace';
  }

  const isInitialRender = useRef(true);
  const location = useLocation();
  const navigate = useNavigate();
  const initialRouterQueryState = useRouteQueryParams();
  const initialState = get(initialRouterQueryState, path, defaultState);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const currentRouterQueryState = parseSearchParams(location.search);

    const proposedRouterQueryState = {
      ...currentRouterQueryState,
      ...set({}, path, state),
    };

    if (!isEqual(proposedRouterQueryState, currentRouterQueryState)) {
      const newUrl = `${location.pathname}${qs.stringify(proposedRouterQueryState, {
        addQueryPrefix: true,
        encoder(str, defaultEncoder, charset, type) {
          if (type === 'key') return str;
          if (isNumber(str)) return `n(${str})`;
          if (isBoolean(str)) return `b(${str})`;
          return defaultEncoder(str, defaultEncoder, charset, type);
        },
      })}`;

      navigate(newUrl, { replace: method === 'replace' });
    }
  }, [state, location.search, navigate, path, method]);

  return [state, setState];
}
