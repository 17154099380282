export default [
  {
    id: 'name',
    name: 'Name',
    sortable: true,
    sortKey: 'name',
  },
  {
    id: 'tags',
    name: 'Tags',
    sortable: false,
  },
  {
    id: 'firmwareVersion',
    name: 'Firmware',
    sortable: true,
    sortKey: 'firmware.version',
  },
  {
    id: 'workflows',
    name: 'Workflows',
    sortable: false,
  },
  {
    id: 'skills',
    name: 'Skills',
    sortable: false,
  },
  {
    id: 'location',
    name: 'Location',
    sortable: false,
  },
  {
    id: 'downtime',
    name: 'Downtime',
    sortable: true,
    sortKey: 'downtime.downAt',
  },
  {
    id: 'connectionStatus',
    name: 'Connection',
    sortable: false,
  },
  {
    id: 'diskStatus',
    name: 'Disk',
    sortable: true,
    sortKey: 'disk.availablePercent',
  },
  {
    id: 'memoryStatus',
    name: 'Memory',
    sortable: true,
    sortKey: 'ram.availablePercent',
  },
  {
    id: 'cpuStatus',
    name: 'CPU',
    sortable: true,
    sortKey: 'cpu.avgLoadPercent15Min',
  },
  {
    id: 'ip',
    name: 'IP Address',
    sortable: false,
  },
  {
    id: 'mac',
    name: 'MAC Address',
    sortable: false,
  },
  {
    id: 'headphoneJack',
    name: 'Audio Connection',
    sortable: false,
  },
  {
    id: 'internalTemperature',
    name: 'Internal Temperature',
    sortable: false,
  },
  {
    id: 'serialNumber',
    name: 'Serial Number',
    sortable: true,
    sortKey: 'serialNumber',
  },
  {
    id: 'warrantyStartedAt',
    name: 'Enrollment Date',
    sortable: false,
    // sortKey: 'provisionedDevice.warrantyStartedAt',
  },
];
