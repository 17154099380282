import findPathDeep from 'deepdash/findPathDeep';
import { isEmpty } from 'lodash';
import set from 'lodash/fp/set';
import { useNavigate } from 'react-router-dom';

import { Button, Table, Text, Toggle } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Message from 'components/message';
import { api, q } from 'config/api';
import dateLabel from 'lib/date-label';
import formatPublicationState from 'lib/format-publication-state';

function HideShowButton({ id, hidden }) {
  const qc = q.useQueryClient();
  const updateMarketplaceSkill = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateMarketplaceSkill($form: updateMarketplaceSkillForm!) {
          marketplaceSkill: updateMarketplaceSkill(form: $form) {
            id
          }
        }`,
        { form },
      ),
    async onMutate(form) {
      await qc.cancelQueries({ queryKey: ['marketplaceSkills'] });
      qc.setQueriesData({ queryKey: ['marketplaceSkills'] }, olds => {
        const path = findPathDeep(olds, (v, k) => k === 'id' && v === form.id, {
          pathFormat: 'array',
        });
        if (!path) return olds;
        return set([...path.slice(0, -1), 'hidden'], form.hidden, olds);
      });

      return { marketplaceSkill: { id: form.id } };
    },
    onError: (err, form) => {
      qc.setQueriesData({ queryKey: ['marketplaceSkills'] }, olds => {
        const path = findPathDeep(olds, (v, k) => k === 'id' && v === form.id, {
          pathFormat: 'array',
        });
        if (!path) return olds;
        return set([...path.slice(0, -1), 'hidden'], !form.hidden, olds);
      });
    },
    onSettled(r) {
      qc.invalidateQueries({ queryKey: ['skill', r.marketplaceSkill.id] });
      qc.invalidateQueries({ queryKey: ['marketplaceSkills'] });
    },
  });

  function toggleHidden(e) {
    e.stopPropagation();
    e.preventDefault();
    if (window.confirm(`Are you sure you want to ${hidden ? 'show' : 'hide'} this skill?`)) {
      updateMarketplaceSkill.mutate({
        id,
        hidden: !hidden,
      });
    }
  }

  return <Toggle onChange={toggleHidden} checked={!hidden} controlled stopPropagation />;
}

export default function AdminMarketplaceList({ error, list = [] }) {
  const navigate = useNavigate();

  if (error) {
    return (
      <Message variant="danger" title="Couldn't Load Firmware">
        {error.message}
      </Message>
    );
  }

  if (isEmpty(list)) {
    return (
      <EmptyContentMessage
        icon="SquaresFour"
        title="No Listings"
        renderAction={() => (
          <Button to="/admin/marketplace/listings/create" icon="Plus">
            New Listing
          </Button>
        )}
      />
    );
  }

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TH>Name</Table.TH>
          <Table.TH>Developer</Table.TH>
          <Table.TH>Latest Published Version</Table.TH>
          <Table.TH>Created</Table.TH>
          <Table.TH>Visible</Table.TH>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {list.map(li => (
          <Table.Row
            key={li.id}
            hover
            caret
            onClick={() => {
              navigate(`/admin/marketplace/listings/${li?.originSkillId}/edit`);
            }}
          >
            <Table.TD>{li.name}</Table.TD>
            <Table.TD>{li.developerProfile?.name}</Table.TD>
            <Table.TD>
              {`${li.latestVersion?.version} [${formatPublicationState(
                li.latestVersion?.publicationState,
              ).toLowerCase()}]`}
            </Table.TD>
            <Table.TD>
              <Text size="xs" color="muted">
                {dateLabel(li.createdAt)}
              </Text>
            </Table.TD>
            <Table.TD collapsed>
              <HideShowButton id={li?.id} hidden={li?.hidden} />
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
