import { useHasRoles } from 'hooks';

export default function Role(props) {
  const { deny, roles, ignoreShortCircuits, children } = props;
  const [hasRoles, loading, isPersonal, isSysAdmin] = useHasRoles(roles, ignoreShortCircuits);

  if (loading) return null;

  // NOTE: deny only applies to org members
  if (deny && !(isPersonal || isSysAdmin)) return hasRoles ? null : children;
  return hasRoles ? children : null;
}
