import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Table, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import SkillIcon from 'components/skill-icon';

export default function AdminSkillBuilderTemplatesList({ templates = [] }) {
  const navigate = useNavigate();

  if (isEmpty(templates)) {
    return <EmptyContentMessage icon="FileCode" title="No Templates" />;
  }

  return (
    <Table>
      <Table.Body>
        {templates.map(template => (
          <Table.Row
            key={template?.id}
            caret={true}
            hover={true}
            onClick={() => navigate(`/admin/skill-builder-content/templates/${template?.id}`)}
          >
            <Table.TD collapsed>
              <SkillIcon
                iconUrl={template.iconUrl}
                icon={template.icon}
                color={template.color}
                size="sm"
              />
            </Table.TD>
            <Table.TD>
              <Text size="sm">{template?.name}</Text>
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
