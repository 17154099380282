import flatMap from 'lodash/flatMap';

import { IconButton, UnreadDot } from '@optra/kit';

import { useNotifications } from 'queries';

export default function NotificationsNavItem({ onClick }) {
  const { data } = useNotifications({ list: { filter: { unread: true } } });
  const notifications = flatMap(data?.pages, page => page?.list?.data);
  const hasUnread = notifications.length > 0;

  return (
    <div className="relative inline-flex">
      <IconButton name="Bell" size="lg" onClick={onClick} variant="plain" />
      {hasUnread && <UnreadDot className="absolute top-0 right-0" />}
    </div>
  );
}
