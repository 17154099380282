import { Button, Text } from '@optra/kit';

import FlexCenter from 'components/flex-center';
import Label from 'components/label';
import Select from 'components/select';

function domain(units) {
  switch (units) {
    case 'hours': {
      return { min: 0, max: 24 };
    }
    case 'days': {
      return { min: 0, max: 31 };
    }
    case 'months': {
      return { min: 0, max: 11 };
    }
    case 'minutes':
    default: {
      return { min: 0, max: 59 };
    }
  }
}

function step(units) {
  switch (units) {
    case 'days': {
      return 3;
    }
    case 'hours':
    case 'minutes':
    case 'months':
    default: {
      return 1;
    }
  }
}

export default function DevicesFilterDowntime({ onFilter, filter }) {
  return (
    <div className="py-2 px-3 space-y-2">
      <div className="mx-auto my-2 text-center">
        <Text>
          &gt; {filter.downtime?.range} {filter.downtime?.units}
        </Text>
      </div>

      <div className="flex items-center justify-between space-x-3 p-4">
        <Text size="xs">{domain(filter.downtime?.units)?.min}</Text>
        <input
          className="w-full flex-1"
          type="range"
          step={step(filter.downtime?.units)}
          value={filter.downtime?.range}
          {...domain(filter.downtime?.units)}
          onChange={event => {
            onFilter({
              ...filter,
              downtime: {
                ...filter.downtime,
                enabled: true,
                range: event?.target?.value,
              },
            });
          }}
        />
        <Text size="xs">{domain(filter.downtime?.units)?.max}</Text>
      </div>

      <div className="flex items-center">
        <Label htmlFor="units" className="text-right mr-2">
          Units
        </Label>
        <Select
          name="units"
          value={filter.downtime?.units}
          onChange={e => {
            onFilter({
              ...filter,
              downtime: {
                ...filter.downtime,
                units: e.target.value,
                range: 0,
              },
            });
          }}
        >
          <option value="minutes">Minutes</option>
          <option value="hours">Hours</option>
          <option value="days">Days</option>
          <option value="months">Months</option>
        </Select>
      </div>

      {filter?.downtime?.enabled && (
        <FlexCenter>
          <Button
            onClick={() => {
              onFilter({
                ...filter,
                downtime: {
                  enabled: false,
                  units: 'minutes',
                  range: [0],
                },
              });
            }}
            variant="secondary"
            size="xs"
            icon="X"
          >
            Clear
          </Button>
        </FlexCenter>
      )}
    </div>
  );
}
