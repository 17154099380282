import { api, q } from 'config/api';

export default function useActions(overrides) {
  return q.useQuery({
    queryKey: ['actions'],
    queryFn: () =>
      api(
        `query actions {
          actions {
            id
            name
          }
        }`,
      ),
    ...overrides,
  });
}
