import Lockr from 'lockr';
import { useEffect } from 'react';

import useGlobalState from 'state';

export default function ColorSchemeTracker() {
  const [appliedColorScheme, setAppliedColorScheme] = useGlobalState('appliedColorScheme');
  const [userColorScheme, setUserColorScheme] = useGlobalState('userColorScheme');

  // Pull the user's preferred color scheme out of localStorage
  useEffect(() => {
    const savedUserColorScheme = Lockr.get('userColorScheme');
    if (savedUserColorScheme) {
      setUserColorScheme(savedUserColorScheme);
    }
    // eslint-disable-next-line
  }, []);

  function handlePrefersColorSchemeChanged(event) {
    if (userColorScheme === 'auto') {
      if (event.matches) {
        setAppliedColorScheme('dark');
      } else {
        setAppliedColorScheme('light');
      }
    }
  }

  // Listen to changes to the system color scheme
  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handlePrefersColorSchemeChanged);

    return () =>
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handlePrefersColorSchemeChanged);
    // eslint-disable-next-line
  }, []);

  // Calculate the color scheme to apply when a user changes their preference
  useEffect(() => {
    let calculated = userColorScheme;
    if (userColorScheme === 'auto' || !userColorScheme) {
      const isAutoDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      calculated = isAutoDark ? 'dark' : '';
    }

    Lockr.set('userColorScheme', userColorScheme);
    setAppliedColorScheme(calculated);
  }, [userColorScheme, setAppliedColorScheme]);

  // Add or remove the `dark` class on the root element
  useEffect(() => {
    document.getElementsByTagName('html')[0].className = appliedColorScheme;
  }, [appliedColorScheme]);

  return null;
}
