import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ContextHeading, Button, Icon, Tooltip, Spinner, Text } from '@optra/kit';

import DeleteSkillButton from 'components/delete-skill-button';
import { useModalContext } from 'components/modal';

import { usePipelineContext } from '../context';

import VersionSelector from './version-selector';

function ToolbarButton({
  active,
  tooltip,
  label,
  icon,
  className,
  loading,
  components = {},
  ...rest
}) {
  return (
    <Tooltip label={`${active ? 'Hide' : 'Show'} ${label}`}>
      <Button
        size="sm"
        variant="secondary"
        className={cx(
          'relative',
          'rounded-full',
          'h-9',
          'w-9',
          'py-0',
          'px-0',
          'flex',
          'items-center',
          'justify-center',
          className,
        )}
        disabled={loading}
        {...rest}
      >
        {loading ? (
          <span>
            <Spinner color="gradient" size="sm" />
          </span>
        ) : (
          <span className={cx(active && 'text-primary')}>
            <Icon name={icon} weight="regular" size="sm" />
          </span>
        )}
        {!!components?.float && (
          <span
            className={cx(
              'absolute',
              'inline-block',
              'top-0',
              'right-0',
              'leading-none',
              'origin-top-right',
              'translate-x-1',
              '-translate-y-1',
            )}
          >
            {components?.float}
          </span>
        )}
      </Button>
    </Tooltip>
  );
}

export default function TitleBar() {
  const {
    form,
    save,
    saving,
    isNew,
    skillVersion,
    skillVersionId,
    skillId,
    selectVersion,
    isPipelineValid,
    setShowConfigPreview,
  } = usePipelineContext();
  const { handleClose } = useModalContext();
  const navigate = useNavigate();
  const skillName = form.watch('name');

  const versions = skillVersion.data?.skillVersion?.skill?.versions?.data;

  return (
    <>
      <ContextHeading
        heading={
          <Text onClick={() => navigate('config')} className="cursor-pointer hidden lg:flex">
            {skillName}
          </Text>
        }
        onDismiss={handleClose}
      />
      <div className="flex items-center justify-end w-full flex-wrap space-x-2">
        {!isNew && (
          <VersionSelector
            value={skillVersionId}
            onChange={selectVersion}
            versions={versions}
            isLoading={skillVersion.isLoading}
          />
        )}
        <ToolbarButton
          className="hidden lg:flex"
          icon="Code"
          label="Config"
          onClick={() => setShowConfigPreview(true)}
        />
        <ToolbarButton
          className="hidden lg:flex"
          icon="Gear"
          label="Settings"
          onClick={() => navigate('config')}
        />
        {!isNew && (
          <DeleteSkillButton
            className="hidden lg:flex"
            size="sm"
            skillId={skillId}
            onSuccess={() => {
              handleClose();
            }}
            text={null}
          />
        )}
        <Button
          size="sm"
          className={cx('w-20')}
          onClick={save}
          loading={saving}
          disabled={!isPipelineValid()}
        >
          Save
        </Button>
      </div>
    </>
  );
}
