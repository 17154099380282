import { Button } from '@optra/kit';

import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';

export default function DeleteSkillButton(props) {
  const {
    skillId,
    onSuccess,
    onError,
    onSettled,
    size = 'xs',
    variant = 'secondary',
    icon = 'Trash',
    text = 'Delete',
    className,
  } = props;
  const [isAdmin] = useHasRoles(['admin']);

  const qc = q.useQueryClient();
  const deleteSkill = q.useMutation({
    mutationFn: ({ id }) => {
      return api(
        `mutation removeSkill($id: ID!) {
          skill: removeSkill(id: $id) {
            id
          }
        }`,
        { id },
      );
    },
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['librarySkills'] });
      return onSuccess(r);
    },
    onError,
    onSettled,
  });

  return (
    <>
      {isAdmin && (
        <Button
          size={size}
          variant={variant}
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this skill?')) {
              deleteSkill.mutate({ id: skillId });
            }
          }}
          loading={deleteSkill.isPending}
          icon={icon}
          className={className}
        >
          {text}
        </Button>
      )}
    </>
  );
}
