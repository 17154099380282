import { api, q } from 'config/api';

export default function useDeviceWorkflowSkills(id, overrides) {
  return q.useQuery({
    queryKey: ['device', id, 'workflowSkills'],
    queryFn: () =>
      api(
        `query deviceWorkflowSkills($id: ID!) {
          device(id: $id) {
            id
            workflowSkills {
              data {
                id
                name
                skill {
                  name
                }
                deviceComposeSkills(id: $id) {
                  name
                }
              }
            }
          }
        }`,
        { id },
      ),
    refetchInterval: 10000,
    ...overrides,
  });
}
