import AdminSkillBuilderBetaNodesList from 'components/admin-skill-builder-beta-nodes-list';
import AdminSkillBuilderPageWrapper from 'components/admin-skill-builder-page-wrapper';
import IntersectionLoadMore from 'components/intersection-load-more';
import { useSkillBuilderBetaNodes } from 'queries';

export default function AdminSkillBuilderBetaNodes() {
  const { data, error, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useSkillBuilderBetaNodes();
  return (
    <AdminSkillBuilderPageWrapper
      loading={isLoading}
      error={error}
      active="nodes"
      createLink="/admin/skill-builder-content/beta-nodes/new"
    >
      <AdminSkillBuilderBetaNodesList nodes={data} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={!hasNextPage || isFetchingNextPage}
      />
    </AdminSkillBuilderPageWrapper>
  );
}
