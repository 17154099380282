import cx from 'classnames';
import { debounce, isEqual } from 'lodash';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { useRef, useState, useEffect, useCallback } from 'react';

import { Icon, Tooltip, Spinner, Text, SearchField } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';
import Input from 'components/input';
import Select from 'components/select';
import SkillIcon from 'components/skill-icon';
import { useDeviceSkillOutputMappings } from 'queries';

const operatorOptions = {
  string: (
    <>
      <option value="$eq">is</option>
    </>
  ),
  boolean: (
    <>
      <option value="$eq">is</option>
    </>
  ),
  number: (
    <>
      <option value="$eq">=</option>
      <option value="$ne">!=</option>
      <option value="$gt">&gt;</option>
      <option value="$lt">&lt;</option>
      <option value="$gte">&gt;=</option>
      <option value="$lte">&lt;=</option>
    </>
  ),
};

const noTypeTooltip =
  'This output does not have a type associated with it. To filter by an output please specify a type in the outputs section when editing a skill';

export default function DevicesFilterOutputs({ onFilter, filter }) {
  const { data, isLoading } = useDeviceSkillOutputMappings();
  const mappings = flatMap(data?.pages, page => page?.list?.data);
  const outputSearchValues = useRef({});
  const outputOperatorValues = useRef({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOutputs, setSelectedOutputs] = useState(
    Object.keys(filter).filter(k => k.startsWith('output')),
  );
  const clearedFilters = useRef([]);
  const focusRef = useRef();

  const performSearch = () => {
    const newFilter = { ...filter };
    Object.entries(outputSearchValues.current).forEach(([key, ref]) => {
      if (ref?.value && selectedOutputs.includes(key)) {
        let value;
        if (ref.tagName === 'SELECT') {
          value = ref.value === 'true';
        } else if (ref.type === 'number') {
          value = +ref.value;
        } else {
          value = ref.value;
        }
        newFilter[key] = {
          [`${outputOperatorValues.current[key].value}`]: value,
        };
      } else {
        if (!isEmpty(newFilter[key])) {
          delete newFilter[key];
          clearedFilters.current = [...clearedFilters.current, key];
        }
      }
    });
    if (!isEqual(filter, newFilter)) onFilter(newFilter);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => performSearch(), [selectedOutputs]);

  const debounceSearch = useCallback(
    () => debounce(performSearch, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [outputSearchValues, selectedOutputs],
  );

  // ensure outputs reflect when the filter is cleared
  useEffect(
    () => {
      setSelectedOutputs(
        selectedOutputs.filter(
          o => Object.keys(filter).includes(o) || clearedFilters.current.includes(o),
        ),
      );
      clearedFilters.current = [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter],
  );

  useEffect(() => focusRef.current?.focus());

  if (isLoading) {
    return (
      <DevicesFilterListItem image={<Spinner size="sm" color="gradient" />} label="Loading…" />
    );
  }

  return (
    <div className="space-y-4">
      <SearchField
        searching={false}
        value={searchTerm}
        onChange={setSearchTerm}
        placeholder="Search Outputs…"
        className="m-2"
      />
      <div className="space-y-1">
        {!isEmpty(mappings) &&
          mappings.map((mapping, i) => {
            if (searchTerm && !mapping.key?.toLowerCase().includes(searchTerm)) return null;
            const selected = selectedOutputs.includes(mapping.mapping);
            const active = filter[mapping.mapping];
            const disabled = !mapping.type;
            return (
              <div
                key={mapping.mapping}
                className={cx(
                  'space-y-2 p-2',
                  'rounded-md',
                  disabled ? 'cursor-default' : 'cursor-pointer',
                  selected
                    ? 'bg-gray-500/10 dark:bg-black-300/10'
                    : !disabled && 'dark:hover:bg-black-900/40',
                )}
              >
                <div
                  className="flex items-center justify-between space-x-2"
                  onClick={() => {
                    if (selected) {
                      setSelectedOutputs(selectedOutputs.filter(o => o !== mapping.mapping));
                    } else {
                      setSelectedOutputs([...selectedOutputs, mapping.mapping]);
                      focusRef.current = outputSearchValues.current[mapping.mapping];
                    }
                  }}
                >
                  <div className={cx('flex-0', disabled && 'opacity-50')}>
                    <SkillIcon
                      icon={mapping.skill?.icon}
                      iconUrl={mapping.skill?.iconUrl}
                      color={mapping.skill?.color}
                      size="xs"
                    />
                  </div>
                  <div className={cx('flex-1 flex flex-col items-start', disabled && 'opacity-50')}>
                    <Tooltip label={mapping.key} disabled={mapping.key <= 23}>
                      <Text size="sm" className="leading-tight">
                        {truncate(mapping.key, { length: 23 })}
                      </Text>
                    </Tooltip>
                    <Tooltip label={mapping.skill?.name} disabled={mapping.skill?.name <= 23}>
                      <Text size="xs" className="opacity-70 leading-tight">
                        {truncate(mapping.skill?.name, { length: 23 })}
                      </Text>
                    </Tooltip>
                  </div>
                  {disabled ? (
                    <div className="flex-0 self-start">
                      <Tooltip label={noTypeTooltip}>
                        <Icon name="Info" weight="duotone" size="sm" />
                      </Tooltip>
                    </div>
                  ) : (
                    active && (
                      <div className="flex-0 self-start">
                        <Icon name="CheckCircle" size="sm" color="primary" />
                      </div>
                    )
                  )}
                </div>
                {!disabled && (
                  <div className={cx('flex', 'items-center', !selected && 'hidden')}>
                    <Select
                      ref={el => (outputOperatorValues.current[mapping.mapping] = el)}
                      className="basis-[30%] text-xs text-right pl-0 pr-8 font-bold rounded-l-md rounded-r-none border-r-0"
                      onChange={performSearch}
                      defaultValue={
                        filter[mapping.mapping] ? Object.keys(filter[mapping.mapping])[0] : ''
                      }
                    >
                      {operatorOptions[mapping.type]}
                    </Select>
                    {mapping.type === 'boolean' ? (
                      <Select
                        className="basis-[70%] text-xs rounded-l-none rounded-r-md"
                        ref={el => (outputSearchValues.current[mapping.mapping] = el)}
                        defaultValue={
                          filter[mapping.mapping] ? Object.values(filter[mapping.mapping])[0] : ''
                        }
                        onChange={performSearch}
                        onFocus={() => (focusRef.current = undefined)}
                      >
                        <option value=""></option>
                        <option value={true}>true</option>
                        <option value={false}>false</option>
                      </Select>
                    ) : (
                      <Input
                        type={mapping.type === 'number' ? 'number' : 'text'}
                        className="basis-[70%] text-xs rounded-l-none rounded-r-md"
                        ref={el => (outputSearchValues.current[mapping.mapping] = el)}
                        defaultValue={
                          filter[mapping.mapping] ? Object.values(filter[mapping.mapping])[0] : ''
                        }
                        placeholder="Enter value..."
                        onChange={debounceSearch()}
                        onFocus={() => (focusRef.current = undefined)}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
