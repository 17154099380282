import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { passthrough } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'ClassificationCountPerFrameProcessor',
  label: 'Sort',
  icon: 'ListNumbers',
  description:
    'One-hot encodes the label with the highest score that’s greater than the given confidence threshold.',
  type: 'processors',
  inputs: [
    {
      label: 'Confidence Scores',
      key: 'scores',
      type: DATA_TYPES['DecimalList'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'label_counts',
      label: 'Label Counts',
      type: DATA_TYPES['NumberList'],
      labelBehavior: passthrough(),
    },
  ],
  defaultConfig: {
    confidenceThreshold: 0,
  },
  schema: z.object({
    config: z.object({ confidenceThreshold: z.number().min(0) }),
    inputs: z.object({ scores: z.string().min(1) }),
  }),
};
