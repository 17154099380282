import cx from 'classnames';
import { isString, upperFirst } from 'lodash';

function statusColor(status) {
  switch (status) {
    case 'synthesized':
    case 'running':
    case 'complete':
      return 'bg-green';
    case 'failed':
    case 'error':
    case 'canceled':
    case 'paused':
    case 'stopped':
      return 'bg-red';
    case 'synthesizing':
    case 'calculating':
    case 'queued':
    case 'inProgress':
      return 'bg-cyan-600 dark:bg-cyan-500';
    case 'draft':
      return 'dark:bg-gray-50 bg-gray';
    case 'unknown':
    default:
      return 'bg-red';
  }
}

function statusLabel(status) {
  switch (status) {
    case 'synthesized':
      return 'Ready to Use';
    case 'synthesizing':
      return 'Build in Progress';
    case 'draft':
      return 'Unavailable';
    case 'complete':
      return 'Deployed';
    case 'failed':
      return 'Failed';
    case 'error':
    case 'canceled':
    case 'paused':
      return 'Deployment Failed';
    case 'calculating':
    case 'queued':
    case 'inProgress':
      return 'Deployment in Progress';
    default:
      if (isString(status)) return upperFirst(status);
      return 'Unknown';
  }
}

export default function SkillStatus({ status, hideLabel, className, ...rest }) {
  if (status) {
    return (
      <>
        <div className={cx(['h-2 w-2 relative', className])} {...rest}>
          <span className="flex h-2 w-2">
            <span
              className={cx(
                'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75',
                statusColor(status),
              )}
            />
            <span
              className={cx('relative inline-flex rounded-full h-2 w-2', statusColor(status))}
            />
          </span>
        </div>
        {!hideLabel && <span className="capitalize pl-1">{statusLabel(status)}</span>}
      </>
    );
  }

  return (
    <span className={cx(['flex h-2 w-2', className])} {...rest}>
      <span className="animate-pulse relative inline-flex rounded-full h-2 w-2 bg-primary" />
    </span>
  );
}
