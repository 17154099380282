import { last, omit } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Button, Heading, Icon, IconButton } from '@optra/kit';

import Label from 'components/label';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Select from 'components/select';
import SkillMetaFields from 'components/skill-meta-fields';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import { useInternalSkill, useSignedUpload } from 'queries';

import ItemNotFound from './item-not-found';

export default function EditSkill() {
  useTitle('Edit Agent Skill | Optra');
  const { reset, control, register, handleSubmit: onSubmit, setFocus } = useForm();
  useInputFocus(setFocus, 'name');
  const { skillId } = useParams();

  const { data, isLoading: loadingData, isSuccess, error: fetchError } = useInternalSkill(skillId);

  useOnSuccess(
    () => {
      const internalSkill = data.internalSkill;
      reset(() => ({
        name: internalSkill?.name,
        icon: {
          icon: internalSkill?.icon,
          color: internalSkill?.color,
          iconUrl: internalSkill?.iconUrl,
        },
        currentVersionId: internalSkill?.currentVersion?.id,
      }));
    },
    { isSuccess },
    [data, reset],
  );

  const [error, setError] = useState();
  const qc = q.useQueryClient();
  const { handleClose } = useModalContext();
  const updateSkill = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateInternalSkill($form: updateInternalSkillForm!) {
      internalSkill: updateInternalSkill(form: $form) {
        id
      }
    }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['internalSkill', skillId] });
      qc.invalidateQueries({ queryKey: ['internalSkills'] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });
  const [uploadIcon, uploadIconState] = useSignedUpload({ type: 'internalSkillImage' });
  const handleSubmit = onSubmit(async form => {
    setError(null);
    let iconUrl = form.icon.iconUrl;
    if (form.icon.iconUrl instanceof File) {
      const uploadedIcon = await uploadIcon(form.icon.iconUrl, {
        extension: last(form.icon.iconUrl.name.split('.')),
        name: `${skillId}-icon`,
      });
      iconUrl = uploadedIcon.url;
    }

    updateSkill.mutate({
      ...omit(form, 'icon'),
      icon: form.icon?.icon,
      color: form.icon?.color,
      iconUrl: iconUrl || null,
      id: skillId,
      currentVersionId: form.currentVersionId,
    });
  });
  const loading = loadingData || uploadIconState.fetching || updateSkill.isPending;
  const itemNotFound = useItemNotFound({
    fetching: loadingData,
    id: data?.internalSkill?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={skillId} type="Skill" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Edit Agent Skill" icon="Cube" loading={loading} />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Update Skill">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Skill">
            {fetchError.message}
          </Message>
        )}
        <SkillMetaFields
          control={control}
          register={register}
          loading={loading}
          loadingDefaults={loadingData}
          hideVersion
        />
        <div className="space-y-2">
          <Label htmlFor="currentVersionId">Current Version</Label>
          <Select {...register('currentVersionId')} readOnly={loading}>
            {data?.internalSkill?.versions?.data?.map(v => (
              <option value={v.id} key={v.id}>
                {v.version}
              </option>
            ))}
          </Select>
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Heading icon="Stack" level={3}>
              Versions
            </Heading>
            <IconButton
              name="Plus"
              variant="tertiary"
              to="../versions/add"
              state={{ fromModal: true }}
            />
          </div>
          <List loading={loadingData}>
            {data?.internalSkill?.versions?.data?.length > 0 &&
              data?.internalSkill?.versions?.data?.map(version => (
                <ListItem
                  key={version.id}
                  to={`../versions/${version.id}`}
                  state={{ fromModal: true }}
                  renderRight={() => <Icon name="CaretRight" weight="line" />}
                >
                  {version.version}
                </ListItem>
              ))}
          </List>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading} onClick={onSubmit}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
