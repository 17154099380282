import * as date from 'date-fns';
import { useRef } from 'react';

import { Button } from '@optra/kit';

import FlexCenter from 'components/flex-center';
import Input from 'components/input';
import Label from 'components/label';

function DevicesFilterLocation({ onFilter, filter }) {
  const startDateRef = useRef();
  const endDateRef = useRef();

  const startDefault = filter?.provisionedDevice?.warrantyStartedAt?.[0]
    ? date.toDate(filter?.provisionedDevice?.warrantyStartedAt?.[0])
    : null;
  const endDefault = filter?.provisionedDevice?.warrantyStartedAt?.[1]
    ? date.toDate(filter?.provisionedDevice?.warrantyStartedAt?.[1])
    : null;

  const handleClear = () => {
    startDateRef.current.value = '';
    endDateRef.current.value = '';
    onFilter({
      ...filter,
      provisionedDevice: {
        warrantyStartedAt: [],
      },
    });
  };

  return (
    <div className="py-2 px-3 space-y-2">
      <div>
        <Label>Start Date</Label>
        <Input
          type="date"
          name="warrantyStartedAtStart"
          defaultValue={(startDefault && date.format(startDefault, 'yyyy-MM-dd')) || ''}
          ref={startDateRef}
          rules={{
            required: 'Please enter a date',
            pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
          }}
        />
      </div>

      <div>
        <Label>End Date</Label>
        <Input
          type="date"
          name="warrantyStartedAtEnd"
          defaultValue={(endDefault && date.format(endDefault, 'yyyy-MM-dd')) || ''}
          ref={endDateRef}
          rules={{
            required: 'Please enter a date',
            pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
          }}
        />
      </div>

      <FlexCenter>
        {(startDefault || endDefault) && (
          <Button onClick={handleClear} variant="secondary" size="xs" icon="X">
            Clear
          </Button>
        )}
        <Button
          onClick={() => {
            const startAt = date
              .startOfDay(date.parse(startDateRef.current.value, 'yyyy-MM-dd', new Date()))
              .getTime();
            const endAt = date
              .endOfDay(date.parse(endDateRef.current.value, 'yyyy-MM-dd', new Date()))
              .getTime();

            onFilter({
              ...filter,
              provisionedDevice: {
                warrantyStartedAt: [startAt, endAt],
              },
            });
          }}
          variant="secondary"
          size="xs"
          icon="MagnifyingGlass"
          iconWeight="line"
        >
          Search
        </Button>
      </FlexCenter>
    </div>
  );
}

export default DevicesFilterLocation;
