import Input from 'components/input';
import Label from 'components/label';
import ValidationError from 'components/validation-error';

export default function WorkflowFormFields({ register, errors, loading }) {
  return (
    <div className="space-y-2">
      <Label htmlFor="name">Workflow Name</Label>
      <Input
        type="text"
        {...register('name', { required: 'Please enter a name.' })}
        readOnly={loading}
      />
      <ValidationError errors={errors} name="name" />
    </div>
  );
}
