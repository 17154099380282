import { api, q } from 'config/api';

export default function useMaintenanceMode(overrides) {
  return q.useQuery({
    queryKey: ['maintenanceMode'],
    queryFn: () =>
      api(
        `query maintenanceMode {
          maintenanceMode
        }`,
      ),
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
    ...overrides,
  });
}
