import cx from 'classnames';
import { forwardRef } from 'react';

export default forwardRef(({ className, ...rest }, ref) => {
  return (
    <input
      ref={ref}
      type="checkbox"
      className={cx(
        ['h-5', 'w-5', 'rounded-full', 'text-primary', 'focus-visible'],
        // Unchecked
        [
          // Light
          'border-light-fg-secondary',
          'bg-light-bg-secondary',
          // Dark
          'dark:border-dark-fg-secondary',
          'dark:bg-dark-bg-secondary',
        ],
        // Checked
        [
          // Light
          'checked:border-transparent',
          'checked:bg-primary',
          // Dark
          'dark:checked:border-transparent',
          'dark:checked:bg-primary',
        ],
        className,
      )}
      {...rest}
    />
  );
});
