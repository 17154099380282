import { isEmpty } from 'lodash';

import { IconButton, Tooltip } from '@optra/kit';

import { useImpersonation } from 'hooks';
import { usePinnedPage } from 'queries';

export default function PinPageButton() {
  const [, { isLoading: loadingPinnedPage, setPinnedPage, isCurrentPagePinned }] = usePinnedPage();
  const { assumedOrganizationId } = useImpersonation();
  const isLoading = setPinnedPage.isPending || loadingPinnedPage;

  if (!isEmpty(assumedOrganizationId)) return null;

  return (
    <Tooltip label={isCurrentPagePinned ? 'Unpin Page' : 'Pin Page'}>
      <IconButton
        name="PushPin"
        size="lg"
        variant="secondary"
        color={isCurrentPagePinned ? 'neon' : ''}
        iconWeight="duotone"
        onClick={() => setPinnedPage.mutate()}
        loading={isLoading}
      />
    </Tooltip>
  );
}
