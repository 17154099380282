import { isEmpty } from 'lodash';

import { COMMA } from 'modals/chart-details/charts-query-engine/characters';

//////////////////////////////////////////////
// returns: "round({{value}}, {{precision}})"
// e.g. "round(available_spaces, 3)"
//////////////////////////////////////////////

export default function round(value, precision = 3) {
  if (isEmpty(value)) return;
  return `round(${value}${COMMA}${precision})`;
}
