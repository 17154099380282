import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { passthrough } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'DetectionCountPerFrameProcessor',
  label: 'Count',
  icon: 'CrosshairSimple',
  description: 'Count the number of detections of each class label on each frame received.',
  type: 'processors',
  inputs: [
    {
      label: 'Bounding Boxes',
      key: 'bounding_boxes',
      type: DATA_TYPES['BoundingBoxes'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'label_counts',
      label: 'Counts',
      type: DATA_TYPES['NumberList'],
      labelBehavior: passthrough(),
    },
  ],
  defaultConfig: {
    confidenceThreshold: 0.75,
  },
  schema: z.object({
    config: z.object({ confidenceThreshold: z.number().min(0) }),
    inputs: z.object({ bounding_boxes: z.string().min(1) }),
  }),
};
