import noop from 'lodash/noop';
import { useOutletContext } from 'react-router-dom';

import { Button, Icon } from '@optra/kit';

import MarketplaceSkillHeader from 'components/marketplace-skill-header';
import MarketplaceSkillListing from 'components/marketplace-skill-listing';
import MarketplaceSkillManual from 'components/marketplace-skill-manual';
import MarketplaceSkillTabs from 'components/marketplace-skill-tabs';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import { useRouteQueryParams } from 'hooks';

export default function PublishSkillPreview(props) {
  const { handleSubmit = noop, hideSubmitBtn = false } = props;
  const [, skill, marketplaceSkill] = useOutletContext();
  const { tab = 'listing' } = useRouteQueryParams();

  return (
    <ModalInner style={{ maxHeight: '100vh' }}>
      <ModalBody size="lg" className="overflow-x-hidden py-0 px-0">
        <MarketplaceSkillHeader
          fetching={skill.isLoading}
          marketplaceSkill={marketplaceSkill}
          hideAddBtn
        />

        <div className="absolute top-0 left-0 right-0 bg-yellow flex p-4 justify-between items-center gap-3 rounded-t-lg z-priority">
          <Icon name="Eye" className="text-yellow-700" size="lg" />
          <span className="text-yellow-700 flex-1 uppercase select-none font-medium tracking-wide">
            Skill Preview
          </span>
          <div className="flex gap-2">
            <Button to=".." icon="Pencil" size="xs" variant="secondary">
              Edit
            </Button>
            {!hideSubmitBtn && (
              <Button
                onClick={() => handleSubmit()}
                icon="CheckCircle"
                size="xs"
                variant="secondary"
              >
                Submit
              </Button>
            )}
          </div>
        </div>

        <div className="px-6 lg:px-12 pb-16 space-y-6 z-base">
          {skill.error && (
            <Message variant="danger" title="Error Loading Skill">
              {skill.error.message}
            </Message>
          )}

          <MarketplaceSkillTabs currentTab={tab} marketplaceSkill={marketplaceSkill} />

          {!skill.isLoading && tab === 'listing' && (
            <MarketplaceSkillListing marketplaceSkill={marketplaceSkill} />
          )}
          {!skill.isLoading && tab === 'manual' && (
            <MarketplaceSkillManual marketplaceSkill={marketplaceSkill} />
          )}
        </div>
      </ModalBody>
    </ModalInner>
  );
}
