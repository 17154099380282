import { isEmpty } from 'lodash';
import * as uuid from 'uuid';

import { Card, Heading } from '@optra/kit';

import ImageSelector from 'modals/skill-pipeline-builder/components/image-selector';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function StaticImagePipelineInput(props) {
  const { nodeId } = props || {};
  const [node, { update }] = usePipelineNode(nodeId);
  const current = node.meta?.images?.at(0);

  function handleImageChange(image, ext) {
    if (image) {
      handleAddImage(image, ext);
    } else {
      handleRemoveImage();
    }
  }

  function handleAddImage(image, ext) {
    const imageId = !isEmpty(current?.imageId) ? current.imageId : uuid.v4();
    update({
      key: 'config.file_path',
      value: `file:///app/skill/config/${imageId}.${ext}`,
    });
    update({
      key: 'meta',
      value: {
        images: [
          {
            image,
            imageId,
            ext,
          },
        ],
      },
    });
  }

  function handleRemoveImage() {
    update({
      key: 'config.file_path',
      value: null,
    });
    update({
      key: 'meta',
      value: !isEmpty(current.imageId)
        ? {
            images: [
              {
                imageId: current.imageId,
              },
            ],
          }
        : null,
    });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Image File</Heading>
      <Card variant="secondary" className="flex flex-col items-center justify-center">
        <ImageSelector value={current?.image} onChange={handleImageChange} />
      </Card>
    </div>
  );
}
