import cx from 'classnames';

import ListItem from 'components/list-item';

export default function List({ children, className, loading, ...rest }) {
  return (
    <div
      className={cx([
        [
          'divide-y',
          'rounded-md',
          // Light
          'bg-light-bg-primary',
          'divide-light-fg-tertiary',
          // Dark
          'dark:bg-dark-bg-primary',
          'dark:divide-dark-bg-secondary',
        ],
        loading && ['animate-pulse'],
        className,
      ])}
      {...rest}
    >
      {loading && !children && (
        <>
          <ListItem>&nbsp;</ListItem>
          <ListItem>&nbsp;</ListItem>
          <ListItem>&nbsp;</ListItem>
        </>
      )}
      {children}
    </div>
  );
}
