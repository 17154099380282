import Lockr from 'lockr';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageWrapper, Card, Button, Spinner, Text } from '@optra/kit';

import FlexCenter from 'components/flex-center';
import Textarea from 'components/textarea';
import { api, q } from 'config/api';
import { sla } from 'config/constants';
import { useCurrentUser } from 'queries';

export default function SoftwareAgreementPage() {
  const intendedRoute = useRef(Lockr.get('intendedRoute') || '/');
  const [currentUser, isLoading, isSuccess, error] = useCurrentUser();
  const navigate = useNavigate();

  const qc = q.useQueryClient();
  const signAgreement = q.useMutation({
    mutationFn: form =>
      api(
        `mutation signAgreement($form: signAgreementForm!) {
          agreement: signEulaAgreement(form: $form) {
            state
          }
        }`,
        { form },
      ),
    async onSuccess() {
      await qc.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });

  const handleDecline = () => {
    if (!currentUser?.id) return;
    signAgreement.mutate({
      agreement: 'eula',
      state: 'declined',
    });
    navigate('/logout');
  };

  const handleAccept = () => {
    if (!currentUser?.id) return;
    signAgreement.mutate(
      {
        agreement: 'eula',
        state: 'accepted',
      },
      {
        onSuccess() {
          Lockr.rm('intendedRoute');
          navigate(intendedRoute?.current || '/');
        },
      },
    );
  };

  const eulaAccepted = isLoading ? null : currentUser?.agreements?.eula?.state === 'accepted';
  const eulaDeclined = isLoading ? null : currentUser?.agreements?.eula?.state === 'declined';

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    if (!isLoading && !currentUser?.id) {
      navigate('/login');
      return;
    }

    if (eulaAccepted) {
      Lockr.rm('intendedRoute');
      navigate(intendedRoute?.current || '/');
      return;
    }
  }, [eulaAccepted, navigate, isLoading, currentUser, isSuccess]);

  return (
    <PageWrapper heading="Software License Agreement" loading={isLoading} error={error}>
      <Card>
        <Textarea className="text-xs" value={sla} readOnly rows={20} />
        {!isLoading && eulaDeclined && (
          <Text className="text-red my-2 text-center">
            You have declined the Software License Agreement. To continue using the product please
            accept the agreement below.
          </Text>
        )}
        <FlexCenter className="my-3 space-x-2">
          {signAgreement.isPending && <Spinner color="gradient" />}
          {!signAgreement.isPending && (
            <>
              <Button
                onClick={handleDecline}
                variant="secondary"
                disabled={isLoading || !currentUser?.id}
              >
                Decline
              </Button>
              <Button onClick={handleAccept} disabled={isLoading || !currentUser?.id}>
                Accept
              </Button>
            </>
          )}
        </FlexCenter>
      </Card>
    </PageWrapper>
  );
}
