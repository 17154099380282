/* eslint-disable i18next/no-literal-string */
import { PageWrapper } from '@optra/kit';

export default function TermsAndConditions() {
  return (
    <PageWrapper heading="Terms and Conditions">
      <div className="text-content">
        <p>
          THESE TERMS AND CONDITIONS APPLY TO ALL PURCHASES OF PRODUCTS FROM THIS SITE. PLEASE READ
          THE FOLLOWING TERMS AND CONDITIONS CAREFULLY. THEY CONTAIN IMPORTANT INFORMATION ABOUT
          YOUR RIGHTS AND OBLIGATIONS, AS WELL AS LIMITATIONS AND EXCLUSIONS THAT APPLY TO YOUR
          PURCHASES.
        </p>
        <p>
          This Lexmark site located at URL{' '}
          <a adhocenable="false" href="http://www.lexmark.com/en_us.html">
            www.lexmark.com
          </a>
          (this “Site”) is owned and operated by Lexmark International, Inc. and may be used to sell
          you products by Lexmark International, Inc. and its subsidiaries (collectively,
          ”Lexmark”). The following terms and conditions govern your use of this Site and the
          purchase of products by you through this Site, Placing an order on the siteI you are
          indicating your consent to contract electronically and your acceptance of the following
          terms and conditions (the &quot;Terms and Conditions&quot;). These Terms and Conditions
          constitute the entire agreement between us as to their subject matter; they supersede and
          replace all prior and contemporaneous oral and written agreements. This Agreement may NOT
          be altered, supplemented, or amended by the use of any other document(s) unless otherwise
          agreed to in a written agreement signed by both you and Lexmark.
        </p>
        <h2>1. OFFER, ACKNOWLEDGMENT AND ACCEPTANCE, AND PAYMENT</h2>
        <h3>1.1</h3>
        <p>
          Any prices, quotations and descriptions made or referred to on this Site are subject to
          availability, do not constitute an offer and may be withdrawn or revised at any time prior
          to Lexmark’s express acceptance of your order (as described below. Promotional offers and
          prices are for a limited time.
        </p>
        <h3>1.2</h3>
        <p>
          While Lexmark makes every effort to ensure that items appearing on the Site are available,
          Lexmark cannot guarantee that all items are in stock or immediately available when you
          submit your order. Lexmark may cancel your order (without liability) if Lexmark is unable
          to process or fulfill it. Lexmark will refund any prior payment that you have made for
          that item.
        </p>
        <h3>1.3</h3>
        <p>
          An order submitted by you constitutes an offer by you to us to purchase Products or
          Services on these Conditions and is subject to Lexmark’s subsequent acceptance.
        </p>
        <h3>1.4</h3>
        <p>
          Prior to such acceptance, an automatic e-mail confirmation of your order will be
          generated. Please note that any such automatic acknowledgement does not constitute a
          formal acceptance of your order.
        </p>
        <h3>1.5</h3>
        <p>
          Our acceptance of your order takes effect and the contract concluded at the point where
          such offer is expressly accepted by us dispatching your order/commencing Services and
          accepting your credit card or other payment (&quot;Acceptance&quot;).
        </p>
        <h3>1.6</h3>
        <p>
          We may keep records of orders received, acknowledgements, acceptances and other contract
          records for a reasonable period after Acceptance. We may be able to provide you with
          copies on written request; however you must make sure you print a copy of all such
          documents and these Conditions for your own records.
        </p>
        <h3>1.7</h3>
        <p>
          Terms of payment are within Lexmark‘s sole discretion, and unless otherwise agreed to by
          Lexmark, payment must be received by Lexmark prior to Lexmark‘s acceptance of an order.
          Payment for the products will be made by credit card. Lexmark may invoice parts of an
          order separately. Your order is subject to cancellation by Lexmark, at Lexmark‘s sole
          discretion. Lexmark‘s standard pricing policy for Lexmark-branded products applies to all
          orders. Lexmark is not responsible for pricing, typographical, or other errors in any
          offer by Lexmark and reserves the right to cancel any orders resulting from such errors.
        </p>
        <h2>2. YOUR REPRESENTATIONS</h2>
        <h3>2.1</h3>
        <p>
          You represent that information provided by you when placing your order is up-to-date
          materially accurate and is sufficient for us to fulfill your order.
        </p>
        <h3>2.2</h3>
        <p>
          You are responsible for maintaining and promptly updating your account information with us
          for accuracy and completeness and keeping such information (and any passwords given to you
          for the purposes of accessing the Site and/or purchasing Products) secure against
          unauthorized access.
        </p>
        <h3>2.3</h3>
        <p>
          Unless agreed otherwise or required by applicable law, any warranties provided in relation
          to Products or Services only extend to you on the understanding that you are a user and
          not a reseller of those Products or Services.
        </p>
        <h3>2.4</h3>
        <p>
          No warranty, commitment or any other obligation should ever be assumed by you on Lexmark’s
          behalf or on behalf of a Product manufacturer, licensor or supplier without Lexmark’s
          express prior written consent.
        </p>
        <h2>3. DELIVERY AND RISK</h2>
        <h3>3.1</h3>
        <p>
          Lexmark will only ship to locations within the United States and its territories. Delivery
          timescales/dates specified on the Site, in any order acknowledgement, acceptance or
          elsewhere are estimates only. While Lexmark endeavors to meet such timescales or dates,
          Lexmark does not undertake to dispatch Products and/or commence Services by a particular
          date or dates and shall not be liable to you in respect of delays or failure to do so.
        </p>
        <h3>3.2</h3>
        <p>
          Delivery shall be to a valid address within the Territory submitted by you and subject to
          Acceptance (&quot;Delivery Address&quot;). You must check the Delivery Address on any
          acknowledgement or acceptance Lexmark provides and notify Lexmark without delay of errors
          or omissions. We reserve the right to charge you for any extra costs arising from changes
          you make to the Delivery Address after you submit an order.
        </p>
        <h3>3.3</h3>
        <p>
          If you refuse or fail to take delivery of Products provided in accordance with these
          Conditions, any risk of loss or damage to the Products shall nonetheless pass and without
          prejudice to any other rights or remedies Lexmark have:-
        </p>
        <h4>3.3.1</h4>
        <p>
          Lexmark shall be entitled to immediate payment in full for the Products or Services
          delivered and either to effect delivery by whatever means Lexmark consider appropriate or
          to store Products at your risk;
        </p>
        <h4>3.3.2</h4>
        <p>
          You shall be liable pay on demand all costs of Product storage and any additional costs
          incurred as a result of such refusal or failure to take delivery;
        </p>
        <h4>3.3.3</h4>
        <p>
          Lexmark shall be entitled 30 days after the agreed date for delivery to dispose of
          Products in such manner as Lexmark determines and may set off any proceeds of sale against
          any sums due from you.
        </p>
        <h3>3.4</h3>
        <p>
          Where Lexmark delivers Products by installments, each installment constitutes a separate
          contract and any defect in any one or more installments shall not entitle you to repudiate
          the contract as a whole nor to cancel any subsequent installment.
        </p>
        <h3>3.5</h3>
        <p>
          Save as otherwise provided in these Terms and Conditions, title and all rights, risks and
          rewards of ownership including but not limited to risk of loss of or damage to the
          Products, passes to you on shipment of the product from Lexmark or its agent. You must
          notify Lexmark within 21 days of the date of your invoice or acknowledgement if you
          believe any part of your purchase is missing, wrong or damaged. Unless you provide Lexmark
          with a valid and correct tax exemption certificate applicable to your purchase of Product
          and the Product ship-to location, you are responsible for sales and other taxes associated
          with the order.See Frequently Asked Questions for further requirements on the tax
          exemption process. Shipping dates are estimates only.
        </p>
        <h2>4. SOFTWARE</h2>
        <h3>4.1</h3>
        <p>
          Where any Product supplied is or includes software (&quot;Software&quot;), this Software
          is licensed by us or by the relevant licensor/owner subject to the relevant end-user
          license agreement or other license terms included with Software (&quot;License
          Terms&quot;) and/or the Product. In addition:
        </p>
        <h4>4.1.1</h4>
        <p>
          Such Software may not be copied, adapted, translated, made available, distributed, varied,
          modified, disassembled, decompiled, reverse engineered or combined with any other
          software, save to the extent that (i) this is permitted in the License Terms, or (ii)
          applicable law expressly mandates such a right which cannot legally be excluded by
          contract.
        </p>
        <h4>4.1.2</h4>
        <p>
          Save to the extent provided for in any applicable License Terms, your rights of return
          and/or to a refund under these Conditions and any applicable Returns Policy do not apply
          in the event that you open the Software shrink-wrap and/or break the license seal and/or
          use the Software.
        </p>
        <h4>4.1.3</h4>
        <p>
          Except to the extent expressly provided by us in writing or under relevant License Terms,
          Software is provided ‘as is‘ without any warranties, terms or conditions as to quality,
          fitness for purpose, performance or correspondence with description and Lexmark does not
          offer any warranties or guarantees in relation to Software installation, configuration or
          error/defect correction. You are advised to refer to any License Terms with regards to
          determining your rights against a manufacturer, licensor or supplier of the Software.
        </p>
        <h2>5. LIABILITY LIMITATION AND LIMITED WARRANTIES</h2>
        <h3>5.1</h3>
        <p>
          TO THE MAXIMUM EXTENT LEGALLY PERMITTED, WHETHER OR NOT WE WERE AWARE OR ADVISED OF THE
          POSSIBILITY OF DAMAGES, AND WHETHER OR NOT THE LIMITED REMEDIES PROVIDED HEREIN FAIL OF
          THEIR ESSENTIAL PURPOSE (1) OUR AGGREGATE LIABILITY (WHETHER FOR BREACH OF CONTRACT, TORT
          OR ANY OTHER LEGAL THEORY) SHALL IN NO CIRCUMSTANCES EXCEED THE COST OF THE PRODUCTS YOU
          ORDERED AND THAT ARE MOST CLOSELY RELATED TO YOUR DAMAGES AND (2) WE SHALL NOT BE LIABLE
          FOR SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, LOST REVENUE,
          OR COST OF COVER.
        </p>
        <h3>5.2</h3>
        <p>
          THE LIMITED WARRANTIES APPLICABLE TO LEXMARK PRODUCTS CAN BE FOUND AT{' '}
          <a adhocenable="false" href="http://www.lexmark.com/en_us.html">
            www.lexmark.com
          </a>{' '}
          OR IN THE DOCUMENTATION LEXMARK PROVIDES WITH LEXMARK-BRANDED PRODUCT. LEXMARK MAKES NO
          WARRANTIES FOR SERVICE, SOFTWARE, OR NON-LEXMARK BRANDED PRODUCT, SERVICE, MAINTENANCE OR
          SUPPORT. SUCH PRODUCT, SOFTWARE, SERVICE, MAINTENANCE OR SUPPORT IS PROVIDED BY LEXMARK
          &quot;AS IS&quot; AND ANY THIRD-PARTY WARRANTIES, SERVICES, MAINTENANCE AND SUPPORT ARE
          PROVIDED BY THE ORIGINAL MANUFACTURER OR SUPPLIER, NOT BY LEXMARK. LEXMARK MAKES NO
          EXPRESS WARRANTIES EXCEPT THOSE STATED IN LEXMARK‘S APPLICABLE LEXMARK-BRANDED WARRANTY OR
          SERVICE DESCRIPTION IN EFFECT ON THE DATE OF THE INVOICE, PACKING SLIP OR ACKNOWLEDGEMENT.
          LEXMARK-BRANDED WARRANTIES AND SERVICES ARE EFFECTIVE ON PAYMENT IN FULL, AND LEXMARK IS
          NOT OBLIGATED TO HONOR ANY WARRANTY OR PROVIDE SERVICE UNTIL LEXMARK RECEIVES PAYMENT IN
          FULL. LEXMARK MAY REQUEST CANCELLATION OF THIRD-PARTY-BRANDED SOFTWARE LICENSES, SERVICES,
          MAINTENANCE OR SUPPORT IF LEXMARK DOES NOT RECEIVE PAYMENT. IF YOU PURCHASE
          THIRD-PARTY-BRANDED PRODUCT, SERVICES, MAINTENANCE, OR SUPPORT, ADDITIONAL THIRD-PARTY
          TERMS AND CONDITIONS MAY APPLY.
        </p>
        <h2>6. THIRD PARTY RIGHTS</h2>
        <h3>6.1</h3>
        <p>
          You shall indemnify us against any and all liabilities, claims and costs incurred by or
          made against us as a direct or indirect result of us performing Services or carrying out
          any work on or to the Products where this has been done to your (or your representative‘s)
          specific requirements or specifications causing an infringement or alleged infringement of
          any proprietary rights of any third party.
        </p>
        <h3>6.2</h3>
        <p>
          Lexmark’s obligation to you with respect to claims of third parties arising out of or
          relating to any Lexmark products purchased by you from this site shall be limited to those
          obligations contained in Lexmark’s statement of limited warranty included with every
          product, except that to the maximum extent legally permitted, whether or not Lexmark has
          been advised of the possibility of such damages, and whether or not the limited remedies
          described fail of their essential purpose, in no event shall Lexmark be liable for any
          lost profits, lost savings, incidental damages, or consequential damages.
        </p>
        <h2>7. IMPLIED WARRANTIES DISCLAIMED</h2>
        <p>
          EXCEPT AS EXPRESSLY STATED OTHERWISE IN THE STATEMENT OF LIMITED WARRANTY INCLUDED WITH A
          PRODUCT, LEXMARK MAKES NO EXPRESS WARRANTIES OR REPRESENTATIONS AND DISCLAIMS ALL IMPLIED
          WARRANTIES AND REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. THESE CONDITIONS
          STATE YOUR SOLE AND EXCLUSIVE REMEDIES.
        </p>
        <h2>8. PERSONAL INFORMATION AND YOUR PRIVACY</h2>
        <h3>8.1</h3>
        <p>
          We will observe applicable data protection laws and will not use information that does or
          can be used to personally identify you (&quot;Personal Data&quot;) other than as set out
          in Lexmark’s Privacy Statement (&quot;Privacy Statement&quot;). By submitting your
          Personal Data in relation to your order, you consent to such Personal Data being processed
          to fulfill your order and in accordance with such Privacy Statement.
        </p>
        <h3>8.2</h3>
        <p>In relation to security of orders that you place with us:</p>
        <h4>8.2.1</h4>
        <p>
          Our secure-server software encrypts all your Personal Data including credit or debit card
          number and name and address. This means that the characters that you enter are converted
          into pieces of code that are then securely transmitted over the Internet.
        </p>
        <h2>9. RETURNS</h2>
        <h3>9.1</h3>
        <p>
          Lexmark‘s return policy can be found at www.lexmark.com/order help. You agree to the terms
          of such return policy. You must contact us directly before you attempt to return Product
          to obtain a Return Material Authorization Number for you to include with your return. You
          must return Product to us in the original or equivalent packaging. You are responsible for
          risk of loss, shipping and handling fees for returning or exchanging Product. Additional
          fees may apply. If you fail to follow the return instructions and policies provided by
          Lexmark, Lexmark is not responsible for Product that is lost, damaged, modified or
          otherwise processed for disposal or resale. At Lexmark‘s discretion, credit for partial
          returns may be less than invoice or individual component prices due to bundled or
          promotional pricing. Products returned to Lexmark outside the warranty period( define
          warranty period) or for which Lexmark has not expressly consented to a return will not be
          eligible for credit. Should you make an unauthorized return of product to Lexmark, you
          acknowledge and agree Lexmark has no obligation to return it to you or provide any credit
          to you relating to such return. You acknowledge and agree Lexmark shall have the right to
          recycle and/or otherwise dispose of such product.
        </p>
        <h3>9.2</h3>
        <p>
          Lexmark‘s policy is one of ongoing update and revision. Lexmark may revise and discontinue
          Product at any time without notice to you and this may affect information saved in your
          online &quot;cart.&quot;
        </p>
        <h2>10. DISPUTE RESOLUTION</h2>
        <p>
          ANY CLAIM, DISPUTE, OR CONTROVERSY (WHETHER IN CONTRACT, TORT, OR OTHERWISE, WHETHER
          PREEXISTING, PRESENT OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW,
          INTENTIONAL TORT AND EQUITABLE CLAIMS) BETWEEN CUSTOMER AND Lexmark, its agents,
          employees, principals, successors, assigns, affiliates (collectively for purposes of this
          paragraph, &quot;Lexmark&quot;) arising from or relating to this Agreement, its
          interpretation, or the breach, termination or validity thereof, the relationships which
          result from this Agreement (including, to the full extent permitted by applicable law,
          relationships with third parties who are not signatories to this Agreement), Lexmark‘s
          advertising, or any related purchase SHALL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING
          ARBITRATION ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION (AAA) or JAMS.
          Arbitration proceedings shall be governed by this provision and the applicable procedures
          of the selected arbitration administrator, including any applicable procedures for
          consumer-related disputes, in effect at the time the claim is filed. Consumer claimants
          (individuals whose transaction is intended for personal family or household use) may elect
          to pursue their claims in small-claims court rather than arbitration. The arbitration or
          small-claims court proceeding will be limited solely to the dispute or controversy between
          customer and Lexmark. In any dispute, NEITHER CUSTOMER NOR LEXMARK SHALL BE ENTITLED TO
          JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS, OR ARBITRATE ANY CLAIM AS A
          REPRESENTATIVE OR CLASS ACTION OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. The individual
          (non-class) nature of this dispute provision goes to the essence of the parties‘ dispute
          resolution agreement, and if found unenforceable, the entire arbitration and dispute
          resolution provision shall not be enforced. The arbitrator shall be empowered to grant
          whatever relief would be available in court under law or in equity. This transaction shall
          be governed by the Federal Arbitration Act 9 U.S.C. sec. 1-16 (FAA). Any award of the
          arbitrator(s) shall be final and binding on each of the parties, and may be entered as a
          judgment in any court of competent jurisdiction. Lexmark will be responsible for paying
          any individual consumer‘s arbitration fees. If any customer prevails on any claim that
          affords the prevailing party attorneys‘ fees, or if there is a written agreement providing
          for fees, the Arbitrator may award reasonable fees to the prevailing party, under the
          standards for fee shifting provided by law. YOU ACKNOWLEDGE THAT YOU ARE GIVING UP YOUR
          RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A JURY OR TO PARTICIPATE IN A CLASS ACTION
          OR REPRESENTATIVE ACTION WITH RESPECT TO SUCH A CLAIM. OTHER RIGHTS THAT YOU WOULD HAVE IF
          YOU WENT TO COURT, SUCH AS ACCESS TO DISCOVERY, MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED
          IN ARBITRATION. For information on AAA or JAMS, contact the following: American
          Arbitration Association, 335 Madison Avenue, 10th Floor, New York, NY 10017,{' '}
          <a adhocenable="false" href="http://www.adr.org" target="_blank" rel="noreferrer">
            www.adr.org
          </a>
          ; JAMS, 45 Broadway, New York, NY 10005, (800) 352-5267,{' '}
          <a adhocenable="false" href="http://www.jamsadr.com" target="_blank" rel="noreferrer">
            www.jamsadr.com
          </a>
          .
        </p>
        <h2>11. GENERAL</h2>
        <h3>11.1</h3>
        <p>
          You shall not assign, transfer, charge or make over or purport to assign transfer charge
          to make over your rights under these Conditions
        </p>
        <h3>11.2</h3>
        <p>
          We shall not be liable to you nor held in breach of contract for any loss or damage which
          may be suffered as a direct or indirect result of us being prevented, hindered or delayed
          in the performance by reason of any circumstances beyond Lexmark’s reasonable control
          including (but not limited to) any act of God, war, riot, civil commotion, government
          action, explosion, fire, flood, storm, accident, strike, lock-out, trade dispute or labour
          disturbance, breakdown of plant or machinery, interruption in the supply of power or
          materials and in such event Lexmark may elect to cancel your order and refund any payments
          made.
        </p>
        <h3>11.3</h3>
        <p>
          You acknowledge that these Conditions supersede and cancel all previous contracts,
          agreements and working arrangements whether oral or written, express or implied, between
          us. These Conditions prevail over any other terms or conditions contained in or referred
          to elsewhere or implied by trade, custom or course of dealing. Any purported terms or
          conditions to the contrary are hereby excluded to the fullest extent legally permitted. To
          the fullest extent permitted under applicable law, Lexmark reserve the right to modify
          these Conditions upon prior written notice to you with effect for the future - subject to
          your right to reject, by way of written notice, Lexmark’s modifications to these
          Conditions with respect to any orders for which Acceptance, but not yet fulfillment, has
          occurred.
        </p>
        <h3>11.4</h3>
        <p>
          No relaxation, forbearance, delay or indulgence by either you or us in enforcing any of
          these Conditions or the granting of time by either party to the other shall prejudice or
          restrict such rights and obligations.
        </p>
        <h3>11.5</h3>
        <p>
          No waiver of any term or condition of these Conditions shall be effective unless made in
          writing and signed by us. The waiver of any breach of any Condition shall not be construed
          as a waiver of any subsequent breach or condition.
        </p>
        <h3>11.6</h3>
        <p>
          If for any reason Lexmark determine or a court of competent jurisdiction finds that any
          provision or portion of these Conditions to be illegal, unenforceable, or invalid under
          applicable law in a particular jurisdiction:
        </p>
        <h4>11.6.1</h4>
        <p>
          these Conditions will not be affected in other jurisdictions to the extent that such
          determination or finding has no application; and
        </p>
        <h4>11.6.2</h4>
        <p>
          in the relevant jurisdiction, the remainder of these Conditions (to the fullest extent
          permitted by law) will continue in full force and effect.
        </p>
        <h3>11.7</h3>
        <p>
          Additional terms and conditions may be included with the products purchased on this Site,
          and you hereby consent to by governed by any and all of such additional terms unless you
          return the product, unused, within 30 days of purchase, and, currently with such return,
          notify Lexmark in writing of your non-acceptance.
        </p>
        <h3>11.8</h3>
        <p>
          You agree to comply with all applicable laws and regulations of the various states and of
          the United States. You agree and represent that you are buying only for your own internal
          use only, and not for resale or export.
        </p>
        <h2>12. GOVERNING LAW</h2>
        <h3>12.1</h3>
        <p>
          The construction validity and performance of these Conditions shall be governed by
          Kentucky Law and the parties submit to the exclusive jurisdiction of Fayette County,
          Kentucky in the event of legal proceedings arising from any dispute;
        </p>
        <h3>12.2</h3>
        <p>The language of any dispute resolution procedure or any proceedings will be English.</p>
        <h2>13. CHANGES TO THESE TERMS AND CONDITIONS</h2>
        <p>
          Lexmark reserves the right to change these terms and conditions at any time. If Lexmark
          makes such changes, Lexmark will post the revised terms and conditions here, along with
          the date of the revision. Any changes will apply only from the date of the revision.
          Amended dispute resolution provisions will not apply to any dispute of which the parties
          had actual notice as of the date of the change.
        </p>
        <h2>14. COPYRIGHT AND TRADEMARK NOTICE</h2>
        <h3>14.1</h3>
        <p>
          All text, graphics, logos, button icons, images, audio clips, digital downloads, data
          compilations, and software owned by Lexmark International, Inc. (and the compilation of
          such content), is the property of Lexmark International, Inc., the Online Store, and/or
          their content suppliers and is protected by United States and international copyright
          laws.
        </p>
        <h3>14.2</h3>
        <p>
          All marks, graphics, logos, page headers, button icons, scripts, service names, data
          compilations, and software not owned by Lexmark International, Inc. are the property of
          their respective owners, who may or may not be affiliated with, connected to, or sponsored
          by Lexmark International, Inc., The Online Store or their content suppliers. Neither the
          Online Store nor any materials from the Online Store may be copied, reproduced, modified,
          republished, uploaded, posted, transmitted, distributed in any form or by any means, or
          otherwise exploited for any commercial purpose without the prior written permission of
          Lexmark International, Inc.
        </p>
      </div>
    </PageWrapper>
  );
}
