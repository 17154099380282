import { ErrorBoundary } from '@optra/kit';

import ProviderComposer from 'providers/provider-composer';

import { ChartProvider, ChartInterfaceProvider, ChartTypeProvider } from './context';

export default function ChartDetails(props) {
  const { chartId, configuration, children } = props;
  return (
    <ErrorBoundary>
      <ProviderComposer
        providers={[
          <ChartProvider key="chartProvider" chartId={chartId} configuration={configuration} />,
          <ChartTypeProvider key="chartTypeProvider" />,
          <ChartInterfaceProvider key="chartInterfaceProvider" />,
        ]}
      >
        {children}
      </ProviderComposer>
    </ErrorBoundary>
  );
}

export { default as helpers } from './helpers';
