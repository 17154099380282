import { Aperture, MagnifyingGlass } from '@phosphor-icons/react';
import { without, isEmpty, flatMap, noop } from 'lodash';
import { useState } from 'react';

import {
  DetailList,
  Table,
  UiState,
  Badge,
  Button,
  Icon,
  IconButton,
  Text,
  Tooltip,
  UnreadDot,
  SearchField,
} from '@optra/kit';

import DeviceImage from 'components/device-image';
import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';
import { useHasRoles } from 'hooks';
import { useTags } from 'queries';

export default function DevicesChooser(props) {
  const {
    badgeType,
    onSelectDevice = () => false,
    devices = [],
    showEnrollmentButton = false,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    filter,
    onFilter = noop,
    newSelectedDevices = [],
    newDeselectedDevices = [],
    hideDeviceLink,
    RowDataComponent,
    showCompatibility = false,
  } = props;

  const [showLabels, setShowLabels] = useState(false);

  const [canEdit] = useHasRoles([
    'admin',
    'deviceTechnician',
    'deviceEnroller',
    'workflowEditor',
    'workflowDeployer',
  ]);
  const [canCreateDevice] = useHasRoles([
    'admin',
    'deviceEnroller',
    'deviceTechnician',
    'workflowEditor',
    'workflowDeployer',
  ]);

  // TODO: Tag pagination
  const _tags = useTags();
  const tags = flatMap(_tags?.data?.pages, page => page?.list?.data);

  const handleSelectLabel = id => {
    const tagIds = filter?.tagIds || [];
    if (tagIds.includes(id)) {
      onFilter(prev => ({
        ...prev,
        tagIds: without(tagIds, id),
      }));
    } else {
      onFilter(prev => ({
        ...prev,
        tagIds: [...(tagIds || []), id],
      }));
    }
  };

  const isFiltered = !isEmpty(filter?.$search) || !isEmpty(filter?.tagIds);
  const isSelected = device => {
    if (newSelectedDevices.includes(device.id)) return true;
    if (newDeselectedDevices.includes(device.id)) return false;
    return device?.selected;
  };

  return (
    <>
      {error && (
        <Message variant="danger" title="Couldn't Load Devices">
          {error.message}
        </Message>
      )}

      <div className="my-4 flex items-center justify-between space-x-2">
        <Button
          icon="Tag"
          variant={showLabels ? 'primary' : 'secondary'}
          onClick={() => setShowLabels(!showLabels)}
        >
          <Icon name={showLabels ? 'CaretUp' : 'CaretDown'} size="xs" className="ml-1" />
        </Button>
        <SearchField
          className="flex-1"
          value={filter?.$search || ''}
          onChange={$search => onFilter(prev => ({ ...prev, $search }))}
          onClear={() => onFilter(prev => ({ ...prev, $search: '' }))}
          searching={isLoading}
        />
      </div>

      {showLabels && (
        <div className="my-4 flex items-center space-x-2 h-11 overflow-x-scroll overflow-y-hidden">
          {tags?.map(tag => (
            <Badge
              key={tag.id}
              id={tag.id}
              variant={(filter?.tagIds || []).includes(tag.id) ? 'primary' : 'inverted'}
              className="cursor-pointer flex-shrink-0"
              onClick={() => handleSelectLabel(tag.id)}
            >
              {tag.name}
            </Badge>
          ))}
        </div>
      )}

      {isLoading ? (
        <UiState />
      ) : showEnrollmentButton ? (
        <UiState
          variant="empty"
          icon={{ component: isFiltered ? MagnifyingGlass : Aperture }}
          text={isFiltered ? 'No Devices Found...' : 'No Devices...'}
        >
          {!!(canCreateDevice && !isFiltered) && (
            <Button to="/devices/create" icon="Plus" variant="tertiary" size="sm">
              Enroll Device
            </Button>
          )}
        </UiState>
      ) : devices?.length === 0 ? (
        <UiState variant="empty" icon={{ component: Aperture }} text="No Devices..." />
      ) : (
        <>
          <Table variant="secondary">
            <Table.Body>
              {devices.map(device => {
                if (!device) {
                  return null;
                }
                const { name, model, autoClaim, status, showBadge, disabled, id, compatible } =
                  device;
                const selected = isSelected(device);
                return (
                  <Table.Row
                    key={id}
                    hover={!disabled}
                    disabled={disabled}
                    disabledSelectable={disabled}
                    selectable
                    selected={selected}
                    onClick={
                      disabled
                        ? null
                        : () => {
                            onSelectDevice(selected, device);
                          }
                    }
                  >
                    {RowDataComponent && <RowDataComponent device={device} selected={selected} />}
                    {!RowDataComponent && (
                      <>
                        <Table.TD collapsed>
                          <DeviceImage
                            model={model}
                            size="sm"
                            status={autoClaim ? 'pending' : status}
                          />
                        </Table.TD>
                        <Table.TD>
                          <Text size="sm">{name}</Text>
                          <DetailList
                            details={[
                              !!showBadge
                                ? {
                                    component: (
                                      <>
                                        <Icon
                                          name={
                                            badgeType === 'Group'
                                              ? 'FolderOpen'
                                              : 'CaretDoubleRight'
                                          }
                                          size="xs"
                                          weight="duotone"
                                          color={badgeType === 'Group' ? undefined : 'primary'}
                                        />
                                        <span>In {badgeType}</span>
                                      </>
                                    ),
                                  }
                                : null,
                              showCompatibility && !compatible
                                ? {
                                    component: (
                                      <Tooltip label="This device requires a firmware update to be compatible with all skills.">
                                        <div className="flex justify-center items-center">
                                          <span className="leading-[0] pr-1">
                                            <UnreadDot />
                                          </span>
                                          <span>Requires Firmware Update</span>
                                        </div>
                                      </Tooltip>
                                    ),
                                  }
                                : null,
                            ]}
                          />
                        </Table.TD>
                        <Table.TD>
                          {device?.tags?.data?.map(tag => (
                            <Badge size="xs" variant="secondary" key={tag?.tag?.id} className="m-1">
                              {tag?.tag?.name}
                            </Badge>
                          ))}
                        </Table.TD>
                        <Table.TD collapsed>
                          {canEdit && !hideDeviceLink && (
                            <IconButton
                              variant="secondary"
                              size="lg"
                              name="ArrowSquareOut"
                              to={`/devices/${device?.id}`}
                              state={{
                                fromModal: true,
                              }}
                              onClick={e => e.stopPropagation()}
                            />
                          )}
                        </Table.TD>
                      </>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
            <IntersectionLoadMore
              as="tfoot"
              onVisible={fetchNextPage}
              disabled={isFetchingNextPage || !hasNextPage}
            />
          </Table>
        </>
      )}
    </>
  );
}
