import cx from 'classnames';

import creditCardBrands from 'config/credit-card-brands';

export default function PaymentMethodIcon({ brand, className }) {
  return (
    <div className={cx(['w-8 text-primary', className])}>
      {creditCardBrands[brand || 'unknown']?.icon}
    </div>
  );
}
