import EmptyContentMessage from 'components/empty-content-message';
import Message from 'components/message';
import NotificationListItem from 'components/notification-list-item';
import Table from 'components/table';
import TBody from 'components/tbody';

export default function NotificationsList({ fetching, error, notifications = [], onItemClick }) {
  if (error) {
    return (
      <Message variant="danger" title="Couldn't Load Notifications">
        {error.message}
      </Message>
    );
  }

  if ((!notifications || notifications.length < 1) && !fetching) {
    return <EmptyContentMessage icon="Bell" title="No Notifications" />;
  }

  return (
    <Table className={fetching && 'animate-pulse'}>
      <TBody>
        {fetching && (
          <>
            <NotificationListItem notification={{ type: 'loading' }} read />
            <NotificationListItem notification={{ type: 'loading' }} read />
            <NotificationListItem notification={{ type: 'loading' }} read />
            <NotificationListItem notification={{ type: 'loading' }} read />
            <NotificationListItem notification={{ type: 'loading' }} read />
            <NotificationListItem notification={{ type: 'loading' }} read />
          </>
        )}
        {notifications.map(notification => (
          <NotificationListItem
            key={notification.id}
            notification={notification}
            onClick={onItemClick}
          />
        ))}
      </TBody>
    </Table>
  );
}
