import AdminGiftCardLog from 'components/admin-gift-card-log';
import AdminGiftCardUsage from 'components/admin-gift-card-usage';

export default function AdminGiftCardDetail({ giftCard }) {
  return (
    <>
      <div className="mb-8">
        <AdminGiftCardUsage giftCard={giftCard} />
      </div>
      <AdminGiftCardLog giftCardId={giftCard?.id} />
    </>
  );
}
