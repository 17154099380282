import { isEmpty, omit } from 'lodash';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Toggle, Card, Button, Icon, Text, WhatsThis } from '@optra/kit';

import Feature from 'components/feature';
import Input from 'components/input';
import KeyValueInput from 'components/key-value-input';
import Label from 'components/label';
import LocationSelect from 'components/location-select';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import TagSelector from 'components/tag-selector';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useDevice } from 'queries';

export default function DeviceDetailEdit({ deviceId, onClose }) {
  useTitle('Device Settings | Optra');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      locationId: null,
      tagIds: [],
    },
  });

  const {
    data,
    isLoading: fetching,
    isSuccess,
    error: fetchError,
  } = useDevice(
    deviceId,
    `
      id
      name
      model
      autoClaim
      disabled
      status
      provisionedDevice {
        returnAuthorization
      }
      location {
        id
        name
        lat
        lng
      }
      tags {
        data {
          tag {
            id
            name
          }
        }
      }
      env
    `,
  );

  const qc = q.useQueryClient();
  const updateDevice = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateDevice($form: updateDeviceForm!) {
          updateDevice(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess(r, { locationId }) {
      qc.invalidateQueries({ queryKey: ['devices'] });
      qc.invalidateQueries({ queryKey: ['device', deviceId] });
      if (!isEmpty(locationId) && data?.device?.location?.id !== locationId) {
        qc.invalidateQueries({ queryKey: ['devicesMap'] });
      }
      setSuccess(true);
    },
    onError(err) {
      setError(err);
    },
  });

  const deleteDevice = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeDevice($id: ID!) {
          removeDevice(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
      onClose();
    },
  });

  const removeFromSubscription = q.useMutation({
    mutationFn: form =>
      api(
        `mutation($form: removeFromSubscriptionForm!) {
          removeFromSubscription(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
      qc.invalidateQueries({ queryKey: ['device', deviceId] });
      onClose();
    },
  });

  const handleToggleDisabled = () => {
    const action = data?.device?.disabled ? 'enable' : 'disable';
    if (window.confirm(`Are you sure you want to ${action} this device?`)) {
      updateDevice.mutate({
        id: deviceId,
        disabled: !data?.device?.disabled,
      });
    }
  };

  const handleDeleteDevice = () => {
    if (window.confirm('Are you sure you want to delete this device?')) {
      deleteDevice.mutate(deviceId);
    }
  };

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel this subscription?')) {
      removeFromSubscription.mutate({
        nodeId: deviceId,
        nodeType: 'devices',
      });
    }
  };

  const handleSubmit = onSubmit(async form => {
    setSuccess(false);
    setError(null);

    // Filter out invalid values
    const formEnv = (form.env || []).filter(v => !isEmpty(v.key) && !isEmpty(v.value));
    const tagIds = form.tagIds?.map(value => ({ id: value?.value, name: value.label })) || [];
    // Remove the value from storage, instead of storing an empty array
    const env = formEnv.length > 0 ? formEnv : null;

    updateDevice.mutate({
      id: deviceId,
      ...form,
      locationId: form?.locationId?.value || '',
      tagIds,
      env,
    });
  });

  useOnSuccess(
    () => {
      reset({
        name: data?.device?.name,
        locationId: { value: data?.device?.location?.id },
        tagIds: data?.device?.tags?.data?.map(tag => ({ value: tag?.tag?.id })),
        env: data?.device?.env,
      });
    },
    { isSuccess },
    [data, reset],
  );

  const loading = fetchError || fetching || updateDevice.isPending;

  return (
    <>
      <ModalBody size="lg">
        {success && <Message variant="success" title="Device Settings Saved" className="mb-6" />}
        {error && (
          <Message variant="danger" title="Couldn't Save Workflow" className="mb-6">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Workflow" className="mb-6">
            {fetchError.message}
          </Message>
        )}

        <div className="md:flex items-center justify-between mb-4 space-y-2">
          <div className="flex flex-col md:flex-row md:items-center md:justify-center md:space-x-2 space-y-2 md:space-y-0">
            {!data?.device?.provisionedDevice?.returnAuthorization && (
              <Feature feature="replaceDevice">
                <Button
                  size="xs"
                  variant="tertiary"
                  to="./replace"
                  state={{ fromModal: true }}
                  icon="Question"
                >
                  Replace Device
                </Button>
              </Feature>
            )}
            <Button
              size="xs"
              variant="tertiary"
              icon="Trash"
              onClick={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
            >
              Delete Device
            </Button>
          </div>

          <Toggle
            label="Enabled"
            checked={!data?.device?.disabled}
            onChange={(e, checked) => handleToggleDisabled(checked)}
          />
        </div>

        {showDeleteConfirmation && (
          <Message
            variant="danger"
            title="Are you sure you want to permanently delete this device?"
          >
            <Text className="block mb-3">
              Warning, if your device is under a service contract an early termination fee may be
              applied.
            </Text>

            <div className="flex items-center space-x-2">
              <Button
                variant="plain"
                className="border border-solid border-white"
                size="xs"
                onClick={() => setShowDeleteConfirmation(false)}
                icon="X"
              >
                No, Nevermind
              </Button>
              <Button
                variant="plain"
                className="border border-solid border-white"
                size="xs"
                onClick={handleDeleteDevice}
                loading={deleteDevice.isPending}
                icon="Check"
              >
                Yes, Delete Device
              </Button>
            </div>
          </Message>
        )}

        {showCancelConfirmation && (
          <Message
            variant="danger"
            title="Are you sure you want to cancel the subscription for this device?"
          >
            <Text className="block mb-3">
              You will no longer be able to manage your device from the Optra Portal.
            </Text>

            <div className="flex items-center space-x-2">
              <Button
                variant="plain"
                className="border border-solid border-white"
                size="xs"
                onClick={() => setShowCancelConfirmation(false)}
                icon="X"
              >
                No, Nevermind
              </Button>
              <Button
                variant="plain"
                className="border border-solid border-white"
                size="xs"
                onClick={handleCancelSubscription}
                loading={removeFromSubscription.isPending}
                icon="Check"
              >
                Yes, Cancel
              </Button>
            </div>
          </Message>
        )}

        <form onSubmit={handleSubmit} className="space-y-4 mb-4">
          <Card variant="secondary" className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="space-y-2">
              <Label htmlFor="name">Device Name</Label>
              <Input
                type="text"
                {...register('name', { required: 'Please enter a name.' })}
                readOnly={loading}
                className="h-11"
              />
              <ValidationError errors={errors} name="name" />
            </div>

            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <Label htmlFor="location">Location</Label>
                <Link
                  to="./manage-locations"
                  state={{
                    fromModal: true,
                  }}
                >
                  <Text
                    size="xs"
                    color="muted"
                    className="flex items-center cursor-pointer hover:underline"
                  >
                    <Icon name="Gear" size="xs" className="mr-1" /> Manage Locations
                  </Text>
                </Link>
              </div>

              <Controller
                render={({ field }) => (
                  <LocationSelect
                    disabled={loading}
                    {...omit(field, 'ref')}
                    defaultLocation={data?.device?.location}
                  />
                )}
                name="locationId"
                control={control}
                rules={{
                  required: true,
                }}
              />
              <ValidationError errors={errors} name="locationId" />
            </div>

            <div className="space-y-2 my-2">
              <div className="flex items-center justify-between">
                <Label htmlFor="tagIds">Tags</Label>
                <Link
                  to="./manage-tags"
                  state={{
                    fromModal: true,
                  }}
                >
                  <Text
                    size="xs"
                    color="muted"
                    className="flex items-center cursor-pointer hover:underline"
                  >
                    <Icon name="Gear" size="xs" className="mr-1" /> Manage Tags
                  </Text>
                </Link>
              </div>
              <Controller
                render={({ field }) => (
                  <TagSelector {...omit(field, 'ref')} defaultSelected={data?.device?.tags?.data} />
                )}
                name="tagIds"
                control={control}
              />
            </div>
          </Card>
          <Card variant="secondary" className="block">
            <div className="mb-6 flex items-center space-x-2">
              <Label>Env</Label>
              <WhatsThis>
                Environment variables set at the device level will override those at the skill level
              </WhatsThis>
            </div>
            <Controller
              render={({ field }) => (
                <KeyValueInput {...field} keyLabel="ENV Key" valueLabel="ENV Label" />
              )}
              name="env"
              control={control}
              loading={loading}
            />
          </Card>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSubmit} type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
