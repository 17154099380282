import { api, q } from 'config/api';

export default function useCurrentBillingAccount(overrides) {
  return q.useQuery({
    queryKey: ['currentBillingAccount'],
    queryFn: () =>
      api(
        `query currentBillingAccount {
           currentBillingAccount {
            balances {
              balance
              credits
              remainingPercentage
              processing
            }
            dailyUsage
          }
        }`,
      ),
    retry: 0,
    ...overrides,
  });
}
