import isNil from 'lodash/isNil';
import { forwardRef, useRef } from 'react';

import { Icon } from '@optra/kit';

import useParseFileUrl from 'lib/use-parse-file-url';

export default forwardRef(function ImgInputArea(props, ref) {
  const {
    value,
    onChange,
    accept = 'image/*',
    width = 480 * 0.8,
    height = 300 * 0.8,
    disabled,
  } = props;
  const internalRef = useRef();

  const url = useParseFileUrl(value);
  const hasFile = !isNil(value);
  const cursor = disabled ? 'cursor-not-allowed' : 'cursor-pointer';

  return (
    <div
      className={`relative ${cursor} bg-light-bg-secondary dark:bg-dark-bg-secondary inline-block`}
      // NOTE: This is loosely following sizing in marketplace listing
      style={{ width, height }}
    >
      {!hasFile && (
        <div className="absolute inset-0 flex justify-center items-center">
          <Icon name="Plus" />
        </div>
      )}
      {hasFile && (
        <div className="absolute inset-0 bg-cover" style={{ backgroundImage: `url(${url})` }} />
      )}
      <input
        ref={el => {
          internalRef.current = el;
          ref?.(el);
        }}
        type="file"
        onChange={e => onChange(e.target.files?.[0])}
        accept={accept}
        className={`absolute inset-0 opacity-0 ${cursor} appearance-none file:appearance-none file:cursor-pointer`}
        disabled={disabled}
      />
    </div>
  );
});
