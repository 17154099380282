import { api, q } from 'config/api';

export default function useModel(id, overrides) {
  return q.useQuery({
    queryKey: ['model', id],
    queryFn: () =>
      api(
        `query model($id: ID!) {
						model(id: $id) {
							id
							name
							type
							global
							edgeImpulse {
								id
								collaborators {
									data {
										email
									}
								}
								enterprise
								url
								workflow {
									devices {
										data {
											id
										}
									}
								}
							}
						}
					}
			`,
        { id },
      ),
    ...overrides,
  });
}
