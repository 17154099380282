import { api, q } from 'config/api';

export default function useLibrarySkill(id, overrides) {
  return q.useQuery({
    queryKey: ['librarySkill', id],
    queryFn: () =>
      api(
        `query librarySkill($id: ID!) {
          librarySkill (id: $id) {
            id
            icon
            iconUrl
            color
            name
            isDockerCompose
            versions {
              data {
                id
                version
                inputs {
                  label
                  binding
                  type
                }
                outputs {
                  id
                  label
                }
                port
                publicationState
                ... on SkillVersion {
                  deprecated
                }
              }
            }
          }
        }`,
        { id },
      ),
    refetchInterval: 5000,
    ...overrides,
  });
}
