import isEmpty from 'lodash/isEmpty';

import { api, q } from 'config/api';

export default function useGeocode(address, overrides) {
  return q.useQuery({
    queryKey: ['geocode', address],
    queryFn: () =>
      api(
        `query geocode($address: String) {
          geocode(address: $address) {
            lat
            lng
          }
        }`,
        { address },
      ),
    enabled: !isEmpty(address?.trim?.()),
    ...overrides,
  });
}
