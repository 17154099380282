export default function readFile(file) {
  return new Promise((resolve, reject) => {
    const r = new FileReader();
    r.onload = event => {
      resolve(event.target.result);
    };

    r.onerror = event => {
      reject(event);
      r.abort();
    };

    r.readAsText(file);
  });
}
