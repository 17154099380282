import cx from 'classnames';
import { padStart } from 'lodash';

import { Badge, Text } from '@optra/kit';

export default function PaymentMethodLabel({
  brand,
  last4,
  className,
  expiration,
  isDefault = false,
}) {
  return (
    <div className={cx(['flex', 'items-center', 'space-x-4', className])}>
      <Text className="font-mono text-sm">{`**** **** **** ${last4}`}</Text>
      <Text className="text-xs" color="muted">
        Exp {padStart(expiration.month, 2, 0)}/{`${expiration.year}`.slice(-2)}
      </Text>
      {isDefault && (
        <Badge size="xs" variant="secondary">
          Default
        </Badge>
      )}
    </div>
  );
}
