import cx from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Text, Heading, Icon, ButtonWrap } from '@optra/kit';

import { titleText, messageText, pathFor, iconFor } from 'components/notification-list-item';

const closeAfterMs = 15000;
const fadeDurationMs = 500;

const NotificationToast = forwardRef(
  ({ id, notification: { referrerId, type, payload }, className, style = {}, ...rest }, ref) => {
    const navigate = useNavigate();
    const [visibility, setVisibility] = useState('open');

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setVisibility(prev => (prev === 'open' ? 'closing' : prev));
      }, closeAfterMs);

      return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
      let timeoutId;
      if (visibility === 'closing') {
        timeoutId = setTimeout(() => {
          setVisibility(prev => (prev === 'closing' ? 'closed' : prev));
        }, fadeDurationMs + 20);
      }

      return () => {
        if (!timeoutId) return;
        clearTimeout(timeoutId);
      };
    }, [visibility, setVisibility]);

    function handleMarkAsRead() {
      setVisibility(prev => (prev === 'open' ? 'closing' : prev));
    }

    function handleNavigate(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      handleMarkAsRead();
      const path = pathFor({ referrerId, type });
      if (path) navigate(path);
    }

    function handleClose(event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      handleMarkAsRead();
    }

    if (visibility === 'closed') return null;

    return (
      <div
        ref={ref}
        // hack for preventing an open modal from getting closed
        onMouseDown={e => e.stopPropagation()}
        onClick={handleNavigate}
        className={cx([
          'p-4',
          'w-96',
          'rounded-lg',
          'shadow-lg',
          'cursor-pointer',
          'border',
          // Light
          ['bg-white', 'border-gray-200/20'],
          // Dark
          ['dark:bg-black-900', 'dark:border-[#000]/20'],
          className,
        ])}
        style={{
          transition: `opacity ${fadeDurationMs}ms ease-in-out`,
          opacity: ['closing', 'closed'].includes(visibility) ? 0 : 1,
          ...style,
        }}
        {...rest}
      >
        <div className="flex items-center space-x-3">
          <div className="flex-0 w-8 flex items-center justify-center">{iconFor({ type })}</div>
          <div className="flex-1 flex flex-col">
            <Heading level={3}>{titleText({ type })}</Heading>
            <Text size="xs" color="muted">
              {messageText({
                type,
                payload,
              })}
            </Text>
          </div>
          <div className="flex-0">
            <ButtonWrap
              onClick={handleClose}
              className="opacity-30 hover:opacity-100 focus:opacity-100"
            >
              <Icon name="XCircle" weight="duotone" />
            </ButtonWrap>
          </div>
        </div>
      </div>
    );
  },
);

export default NotificationToast;
