import { api, q } from 'config/api';

export default function useWorkflowsDevices(id, variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflow', id, variables, 'devices'],
    queryFn({ pageParam }) {
      return api(
        `query workflowsDevices($id: ID!, $list: listFields) {
          workflow(id: $id) {
            id
            devices(list: { filter: { includeGrouped: true } }) {
              count
            }
            list: devicesAndGroups(list: $list) {
              count
              data {
                ... on WorkflowDevice {
                  __typename
                  id
                  deleted
                  synchronized
                  isSynchronizing
                  incompatibleSkills {
                    id
                  }
                  device {
                    id
                    name
                    status
                    model
                    autoClaim
                  }
                }
                ... on WorkflowDeviceGroup {
                  __typename
                  id
                  name
                  synchronized
                  autoClaim
                  hasIncompatibleDevices
                  members {
                    count
                  }
                }
              }
              cursor {
                after
              }
            }
          }
        }`,
        {
          ...variables,
          id,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.workflow?.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
    enabled: !!id,
    ...overrides,
  });
}
