import { Cube } from '@phosphor-icons/react';
import { noop } from 'lodash';

import { Table, UiState, SearchField } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';

export default function SkillsChooser(props) {
  const {
    onSelectSkill = () => false,
    skills = [],
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    filter,
    onFilter = noop,
    newSelectedSkills = [],
    newDeselectedSkills = [],
    RowDataComponent,
  } = props;

  const isSelected = skill => {
    if (newSelectedSkills.includes(skill.id)) {
      return true;
    }
    if (newDeselectedSkills.includes(skill.id)) {
      return false;
    }
    return skill?.selected;
  };

  return (
    <>
      {error && (
        <Message variant="danger" title="Couldn't Load Skills">
          {error.message}
        </Message>
      )}

      <div className="my-4 flex items-center justify-between space-x-2">
        <SearchField
          className="flex-1"
          value={filter?.$search || ''}
          onChange={$search => onFilter(prev => ({ ...prev, $search }))}
          onClear={() => onFilter(prev => ({ ...prev, $search: '' }))}
          searching={isLoading}
        />
      </div>

      {isLoading ? (
        <UiState />
      ) : skills?.length === 0 ? (
        <UiState variant="empty" icon={{ component: Cube }} text="No Skills..." />
      ) : (
        <>
          <Table variant="secondary">
            <Table.Body>
              {skills.map(skill => {
                if (!skill) {
                  return null;
                }
                const { disabled, id } = skill;
                const selected = isSelected(skill);

                return (
                  <Table.Row
                    key={id}
                    hover={!disabled}
                    disabled={disabled}
                    disabledSelectable={disabled}
                    selectable
                    selected={selected}
                    onClick={
                      disabled
                        ? null
                        : () => {
                            onSelectSkill(selected, skill);
                          }
                    }
                  >
                    {RowDataComponent && <RowDataComponent skill={skill} selected={selected} />}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <IntersectionLoadMore
            onVisible={fetchNextPage}
            disabled={isFetchingNextPage || !hasNextPage}
          />
        </>
      )}
    </>
  );
}
