import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export const DIAGNOSTICS_VIEW_KEY = 'AdminViewOutput';

export default {
  key: 'inference_engine_factory',
  label: 'Inference Engine Factory',
  icon: 'ListMagnifyingGlass',
  description:
    'Based on the given model path, determine what inference engine node is needed and automatically create it.',
  type: 'factories',
  inputs: [],
  outputs: [
    {
      key: 'image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
};
