import { isEmpty } from 'lodash';
import { useState, useRef } from 'react';
import { useDebounce } from 'react-use';

import { useRouteQueryState } from 'hooks';

export default function useDebouncedState(defaultValue, _ops = {}) {
  const ops = useRef({
    storeInRoute: false,
    delayMS: 500,
    ..._ops,
  });
  const { storeInRoute, delayMS } = ops.current;

  /* eslint-disable */
  const [value, setValue] = !isEmpty(storeInRoute)
    ? useRouteQueryState(storeInRoute, defaultValue)
    : useState(defaultValue);
  /* eslint-enable */

  const [$value, set$value] = useState(value);
  useDebounce(
    () => {
      set$value(value);
    },
    delayMS,
    [value],
  );

  return [$value, setValue, value];
}
