import { set } from 'lodash/fp';
import { useSessionStorage } from 'usehooks-ts';

import { api, q } from 'config/api';
import { defaultStatusMapping, defaultDeviceColumns } from 'config/constants';
import { useOrganizationClient } from 'providers/organization-provider';

export default function useCurrentOrganization(overrides) {
  const [, setVisibleColumns] = useSessionStorage('visibleColumns', []);
  const [, setStatusMapping] = useSessionStorage('statusMapping', defaultStatusMapping);
  const [, setAssumedOrganizationId] = useSessionStorage('assumedOrganizationId', null);
  const [selectedOrganizationId, client] = useOrganizationClient();
  const {
    data,
    isLoading: fetching,
    isSuccess,
  } = q.useQuery({
    queryKey: ['currentOrganization'],
    queryFn: () =>
      api(
        `query currentOrganization {
          organization: currentOrganization {
            id
            name
          }
        }`,
      ),
    ...overrides,
  });

  function handleChange(orgId, redirectPath) {
    client.set(orgId);
    setAssumedOrganizationId(null);
    setVisibleColumns(defaultDeviceColumns);
    setStatusMapping(defaultStatusMapping);
    const cache = JSON.parse(localStorage.getItem('REACT_QUERY_OFFLINE_CACHE'));
    const nextQueries = cache.clientState.queries.filter(({ queryKey }) => {
      return !queryKey.includes('roles');
    });
    const nextCache = set('clientState.queries', nextQueries, cache);
    localStorage.setItem('REACT_QUERY_OFFLINE_CACHE', JSON.stringify(nextCache));
    if (redirectPath) {
      window.location.href = redirectPath;
    } else {
      window.location.reload();
    }
  }

  return [
    {
      id: selectedOrganizationId || '$$NONE',
      ...data?.organization,
    },
    {
      fetching,
      isSuccess,
      handleChange,
    },
  ];
}
