import Lockr from 'lockr';
import { useAuth0 } from 'react-auth0rize';
import { useLocation } from 'react-router-dom';

export default function IntendedRouteTracker({ ignore = [] }) {
  const location = useLocation();
  const auth = useAuth0();

  if (window.location.origin.toLowerCase().includes('www.')) {
    window.location.replace(window.location.href.replace('www.', ''));
    return null;
  }

  const intendedRoute = Lockr.get('intendedRoute');
  const isBlacklisted = ignore.includes(location.pathname);

  if (!auth.authenticated && !intendedRoute && !isBlacklisted) {
    Lockr.set('intendedRoute', location.pathname);
  }

  return null;
}
