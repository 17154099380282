import AdminViewOutput from './admin-view-output';
import BetaNode from './beta-node';
import BoundingBoxCropElement from './bounding-box-crop-element';
import ClassificationCountPerFrameProcessor from './classification-count-per-frame-processor';
import CombineLabelsProcessor from './combine-labels-processor';
import CountToLabelProcessor from './count-to-label-processor';
import DetectionCountPerFrameProcessor from './detection-count-per-frame-processor';
import EdgeImpulseClassificationInferenceEngine from './edge-impulse-classification-inference-engine';
import EdgeImpulseObjectDetectionInferenceEngine from './edge-impulse-object-detection-inference-engine';
import ImageLayerElement from './image-layer-element';
import ImageResultDecoratorElement from './image-result-decorator-element';
import LabelAverageProcessor from './label-average-processor';
import LabelSequenceProcessor from './label-sequence-processor';
import LabelToImageElement from './label-to-image-element';
import ResultsIndicatorElement from './results-indicator-element';
import RotateImageElement from './rotate-image-element';
import SourceImagePipelineInput from './source-image-pipeline-input';
import StaticCropImageElement from './static-crop-image-element';
import StaticImageElement from './static-image-element';
import StaticImagePipelineInput from './static-image-pipeline-input';
import StaticVideoPipelineInput from './static-video-pipeline-input';

export default {
  AdminViewOutput,
  BoundingBoxCropElement,
  BetaNode,
  ImageLayerElement,
  ImageResultDecoratorElement,
  LabelToImageElement,
  ResultsIndicatorElement,
  RotateImageElement,
  StaticCropImageElement,
  StaticImageElement,
  StaticImagePipelineInput,
  StaticVideoPipelineInput,
  ClassificationCountPerFrameProcessor,
  CombineLabelsProcessor,
  CountToLabelProcessor,
  DetectionCountPerFrameProcessor,
  LabelAverageProcessor,
  LabelSequenceProcessor,
  EdgeImpulseObjectDetectionInferenceEngine,
  EdgeImpulseClassificationInferenceEngine,
  SourceImagePipelineInput,
};
