import { Flags, useMatchFlags } from 'lib/feature-flags';

export function useFeature(feature, scope) {
  const matchingFlags = useMatchFlags([feature], scope);
  return matchingFlags.length === 1;
}

export const useFeatures = useMatchFlags;

export default function Feature(props) {
  const { feature, ...otherProps } = props;
  return <Flags authorizedFlags={[feature]} {...otherProps} />;
}
