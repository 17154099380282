import { isEmpty, isFinite as _isFinite, difference, uniq, uniqueId } from 'lodash';
import { createContext, useState, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useOnSuccess } from 'config/api';
import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import getNodeDef from 'modals/skill-pipeline-builder/context/get-node-def';

import isNodeValid from './is-node-valid';
import newId from './newId';
import usePipelineReducer from './use-pipeline-reducer';
import useSkillBuilder from './use-skill-builder';

export const PipelineContext = createContext();

export function usePipelineContext() {
  return useContext(PipelineContext);
}

export function PipelineProvider({ children }) {
  const [showConfigPreview, setShowConfigPreview] = useState(false);
  const [pipeline, updatePipeline] = usePipelineReducer();
  const { skillId, skillVersionId } = useParams();
  const isNew = isEmpty(skillVersionId);

  const form = useForm({
    defaultValues: {
      name: 'Untitled',
      version: '1.0.0',
      icon: {
        color: '#00C425',
        icon: 'action',
      },
      isNewVersion: false,
      targetVisionFamily: false,
    },
  });

  const defaultValuesAreSet = useRef(false);
  const navigate = useNavigate();
  const [skillVersion, { upsert, saving }] = useSkillBuilder({
    skillVersionId,
  });

  useOnSuccess(
    () => {
      const sv = skillVersion.data.skillVersion;
      const { pipeline, skill } = sv;

      updatePipeline({
        action: 'restore',
        payload: { pipeline },
      });

      form.reset({
        name: skill.name,
        version: sv.version,
        icon: {
          icon: skill?.icon,
          color: skill?.color,
          iconUrl: skill?.iconUrl,
        },
        targetVisionFamily: sv?.targetDeviceFamily === 'vision',
        env: Object.entries(sv?.env || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
        devices: {
          sound: sv?.devices?.sound,
          hdmi: sv?.devices?.hdmi,
          // For now, this is a toggle. In the future, we may allow the user to choose a number
          cameras: _isFinite(sv?.devices?.cameras),
        },
        removableMedia: sv?.removableMedia,
        hostNetworking: sv?.hostNetworking,
        storage: Object.entries(sv?.storage || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
        hostName: sv?.hostName,
        endpointAliases: sv?.endpointAliases?.map(alias => ({
          _id: uniqueId(),
          alias,
        })),
        port: sv?.port,
        portBindings: (sv?.portBindings || []).map(p => ({
          _id: uniqueId(),
          ...p,
        })),
        tmpfs: (sv?.tmpfs || []).map(t => ({
          _id: uniqueId(),
          ...t,
          sizeBytes: t.sizeBytes,
        })),
        shmSize: sv?.shmSize,
        ...(!isEmpty(sv?.createOptions)
          ? { createOptions: JSON.stringify(sv?.createOptions, null, 2) }
          : { createOptions: '' }),
      });
    },
    { isSuccess: skillVersion.isSuccess },
    [form, skillVersion.data, updatePipeline],
  );

  const save = form.handleSubmit(async form => {
    if (saving) return;

    upsert.mutate(
      {
        ...(form.isNewVersion ? {} : { id: skillVersionId }),
        pipeline,
        storage: {
          skillbuilder: '/app/skill/config',
        },
        targetDeviceFamily: form.targetVisionFamily ? 'vision' : 'compute',
        port: 3000,
        devices: {
          hdmi: true,
          cameras: 5,
        },
        version: form.version,
        skill: {
          id: skillId,
          name: form.name,
          icon: form.icon?.icon,
          color: form.icon?.color,
          iconUrl: form.icon?.iconUrl,
        },
      },
      {
        onSuccess(r) {
          if (isNew) {
            navigate('/skills');
            return;
          }
          if (form.isNewVersion) {
            navigate(`/skills/${skillId}/versions/${r.version.id}/pipeline`);
            return;
          }
          updatePipeline({
            action: 'restore',
            payload: { pipeline: r.version.pipeline },
          });
        },
      },
    );
  });

  const selectVersion = id => {
    defaultValuesAreSet.current = false;
    navigate(`/skills/${skillId}/versions/${id}/pipeline`);
  };

  function addNode({ betaNode, nodeType, id }) {
    const nodeId = id || newId();
    updatePipeline({
      action: 'addNode',
      payload: {
        nodeId,
        nodeType,
        betaNode,
      },
    });
    navigate(`./nodes/${nodeId}`);
  }

  function isPipelineValid() {
    const pipelineItems = Object.values(pipeline.pipeline);
    const inputs = pipelineItems.filter(i => i.type === 'inputs');
    const models = pipelineItems.filter(i => i.type === 'inference');
    const logics = pipelineItems.filter(
      i => i.type === 'processors' || i.type === 'graphic_elements',
    );
    return (
      inputs.length > 0 &&
      models.length > 0 &&
      logics.length > 0 &&
      !pipelineItems.some(n => !isNodeValid(n))
    );
  }

  function missingDataTypesForNode(nodeDef) {
    if (nodeDef.type === 'inputs' || nodeDef.type === 'inference') {
      return [];
    }

    const pipelineItems = Object.values(pipeline.pipeline);
    const availableOutputTypes = pipelineItems
      .flatMap(pipelineItem => {
        const pipelineItemDef = getNodeDef(pipelineItem);
        return pipelineItemDef.outputs.map(o => o.type.key);
      })
      .filter(o => !isEmpty(o));

    // CombineLabelsProcessor requires min 2x DATA_TYPES['Text']
    if (nodeDef.key === 'CombineLabelsProcessor') {
      const requiredInputType = DATA_TYPES['Text'].key;
      const numberAvailable = availableOutputTypes.filter(o => o === requiredInputType).length;
      if (numberAvailable < 2) {
        return [requiredInputType, requiredInputType];
      } else {
        return [];
      }
    }

    const requiredInputTypes = uniq((nodeDef?.inputs || []).map(i => i.type.key))
      .filter(k => !isEmpty(k))
      .filter(k => k !== 'Any');
    return difference(requiredInputTypes, uniq(availableOutputTypes));
  }

  function uniqueLabelForNode(node) {
    const pipelineItems = Object.entries(pipeline.pipeline).map(([id, n]) => ({
      id,
      ...n,
    }));
    const dupDefs = pipelineItems.filter(i => i.name === node.name);
    const label = getNodeDef(node).label;

    if (dupDefs.length > 1) {
      return `${label} ${dupDefs.findIndex(i => i.id === node.id) + 1}`;
    }

    return label;
  }

  function sortPipeline({ items = [], types = [] }) {
    updatePipeline({
      action: 'reorder',
      payload: {
        items,
        types,
      },
    });
  }

  const value = {
    showConfigPreview,
    setShowConfigPreview,
    pipeline,
    updatePipeline,
    sortPipeline,
    form,
    save,
    saving,
    isNew,
    skillVersion,
    skillId,
    skillVersionId,
    selectVersion,
    addNode,
    isPipelineValid,
    missingDataTypesForNode,
    uniqueLabelForNode,
    newId,
  };

  return <PipelineContext.Provider value={value}>{children}</PipelineContext.Provider>;
}
