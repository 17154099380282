import { api, q } from 'config/api';

export default function useChart(id, overrides) {
  return q.useQuery({
    queryKey: ['chart', id],
    queryFn: () =>
      api(
        `query chart($id: ID!) {
          chart(id: $id) {
            id
            name
            query
            isCustomQuery
            type
            source
            config {
              calculation
              calculationConfig
              yAxis
              xAxis
              timeframeBin
              interpolate,
              interpolationFill,
              interpolationStyle,
              discardZeros
              syncTimezones,
              disabledHours,
              disabledDays,
              timeframe {
                start
                end
              }
              workflowIds
              deviceIds
              skillIds
              skillVersionIds
              workflowSkillIds
              serialNumbers
              tagIds
              locationIds
              metrics {
                metric
                type
                calculation
                color
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...overrides,
  });
}
