import set from 'lodash/set';
import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Button, Text } from '@optra/kit';

import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SQLInput from 'components/sql-input';
import Table from 'components/table';
import TBody from 'components/tbody';
import TD from 'components/td';
import TH from 'components/th';
import THead from 'components/thead';
import TR from 'components/tr';
import { api, q, useOnSuccess } from 'config/api';
import { useWorkflowAction } from 'queries';

export default function DataSinkActionQuery() {
  const { workflowActionId } = useParams();
  const modal = useRef();
  const [query, setQuery] = useState('');

  const workflowActionQuery = useWorkflowAction(workflowActionId, {
    refetchInterval: 60000,
  });
  const { workflowAction } = workflowActionQuery.data;

  useOnSuccess(
    () => {
      setQuery(
        workflowAction?.configuration?.eagerQuery?.query ||
          `select * from "${
            workflowAction?.configuration?.shared?.tableName || '<tableName>'
          }" limit 10`,
      );
    },
    { isSuccess: workflowActionQuery.isSuccess },
    [workflowAction],
  );

  const isLoadingDefaults = workflowAction.isLoading;
  const configuration = workflowAction.data?.workflowAction?.configuration;

  const qc = q.useQueryClient();
  const runQuery = q.useMutation({
    mutationFn: form =>
      api(
        `query runDataSinkQuery ($id: ID!, $fn: String!, $props: JSON) {
          result: runWorkflowActionQuery(id: $id, fn: $fn, props: $props)
        }`,
        {
          id: workflowActionId,
          fn: 'runQuery',
          props: form,
        },
      ),
    onSuccess(r) {
      const queryKey = ['workflowAction', workflowActionId];
      qc.setQueryData(
        queryKey,
        set(
          { ...qc.getQueryData(queryKey) },
          'workflowAction.configuration.eagerQuery.status.state',
          'RUNNING',
        ),
      );
      qc.invalidateQueries({ queryKey });
    },
  });

  const loadPartitions = q.useMutation({
    mutationFn: () =>
      api(
        `query runDataSinkQuery ($id: ID!, $fn: String!, $props: JSON) {
          result: runWorkflowActionQuery(id: $id, fn: $fn, props: $props)
        }`,
        {
          id: workflowActionId,
          fn: 'loadPartitions',
        },
      ),
    onSuccess(r) {
      const queryKey = ['workflowAction', workflowActionId];
      qc.setQueryData(
        queryKey,
        set(
          { ...qc.getQueryData(queryKey) },
          'workflowAction.configuration.loadPartitionsQuery.status.state',
          'RUNNING',
        ),
      );
      qc.invalidateQueries({ queryKey });
    },
  });

  const [resultsHeaderRow, ...resultsRows] = configuration?.eagerQueryResults || [];

  return (
    <Modal ref={modal}>
      <ModalInner>
        <ModalTitle
          title="Run SQL Query"
          icon="MagnifyingGlass"
          loading={isLoadingDefaults}
          renderActions={() => (
            <Button
              size="xs"
              variant="secondary"
              onClick={() => loadPartitions.mutate()}
              loading={
                loadPartitions.isPending ||
                configuration?.loadPartitionsQuery?.status?.state === 'RUNNING'
              }
            >
              Load {configuration?.shared?.tableName || ''} Partitions
            </Button>
          )}
        />
        <ModalBody size="lg" className="space-y-4">
          {runQuery.error && (
            <Message variant="danger" title="Couldn't run command">
              {runQuery.error.message}
            </Message>
          )}

          <Card
            variant="secondary"
            className="flex items-center justify-between space-x-2 overflow-hidden"
            noPadding
          >
            <div className="h-40 w-full">
              <SQLInput
                onChange={setQuery}
                value={query}
                completions={{ table: configuration.availableTableNames }}
              />
            </div>
          </Card>

          <Card
            variant="secondary"
            className="flex items-center justify-between space-x-2 overflow-hidden"
          >
            <Text>{configuration?.eagerQuery?.status?.state || 'READY'}</Text>
            <Text>{configuration?.eagerQuery?.statistics?.totalExecutionTimeInMillis}ms</Text>
          </Card>

          <Card
            variant="secondary"
            className="flex items-center justify-between space-x-2 overflow-hidden"
            noPadding
          >
            <Table compact={false} className={workflowAction.isLoading ? 'animate-pulse' : ''}>
              <THead>
                {resultsHeaderRow && (
                  <TR hover={false}>
                    {resultsHeaderRow?.data?.map?.(({ varCharValue: datum }) => (
                      <TH key={datum}>{datum}</TH>
                    ))}
                  </TR>
                )}
              </THead>
              <TBody>
                {(resultsRows || []).map((row, idx) => (
                  <TR key={idx}>
                    {row.data.map(({ varCharValue: datum }, idx) => (
                      <TD key={idx}>{datum}</TD>
                    ))}
                  </TR>
                ))}
              </TBody>
            </Table>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => runQuery.mutate({ query })}
            size="xl"
            disabled={isLoadingDefaults}
            loading={runQuery.isPending || configuration?.eagerQuery?.status?.state === 'RUNNING'}
          >
            Run Query
          </Button>
        </ModalFooter>
      </ModalInner>
    </Modal>
  );
}
