import * as date from 'date-fns';
import { isUndefined } from 'lodash';

import { Group, Groups } from '@optra/kit';

import DevicesFilterConnectionStatus from 'components/devices-filter-connection-status';
import DevicesFilterCpuUsage from 'components/devices-filter-cpu-usage';
import DevicesFilterDiskUsage from 'components/devices-filter-disk-usage';
import DevicesFilterDowntime from 'components/devices-filter-downtime';
import DevicesFilterEnrollmentDate from 'components/devices-filter-enrollment-date';
import DevicesFilterEnrollmentStatus from 'components/devices-filter-enrollment-status';
import DevicesFilterFirmwareVersion from 'components/devices-filter-firmware-version';
import DevicesFilterGroups from 'components/devices-filter-groups';
import DevicesFilterHeadphoneJack from 'components/devices-filter-headphone-jack';
import DevicesFilterInternalTemperature from 'components/devices-filter-internal-temperature';
import DevicesFilterLocation from 'components/devices-filter-location';
import DevicesFilterMemoryUsage from 'components/devices-filter-memory-usage';
import DevicesFilterModel from 'components/devices-filter-model';
import DevicesFilterOutputs from 'components/devices-filter-outputs';
import DevicesFilterSkills from 'components/devices-filter-skills';
import DevicesFilterTags from 'components/devices-filter-tags';
import DevicesFilterWorkflows from 'components/devices-filter-workflows';
import { useFeature } from 'components/feature';

const filterComponents = {
  model: {
    label: 'Model',
    component: DevicesFilterModel,
  },
  tags: {
    label: 'Tags',
    component: DevicesFilterTags,
  },
  firmwareVersion: {
    label: 'Firmware Version',
    component: DevicesFilterFirmwareVersion,
  },
  workflows: {
    label: 'Workflow',
    component: DevicesFilterWorkflows,
  },
  skills: {
    label: 'Skill',
    component: DevicesFilterSkills,
  },
  outputs: {
    label: 'Outputs',
    component: DevicesFilterOutputs,
  },
  location: {
    label: 'Location',
    component: DevicesFilterLocation,
  },
  downtime: {
    label: 'Downtime',
    component: DevicesFilterDowntime,
  },
  connectionStatus: {
    label: 'Connection Status',
    component: DevicesFilterConnectionStatus,
  },
  enrollmentStatus: {
    label: 'Enrollment Status',
    component: DevicesFilterEnrollmentStatus,
  },
  diskUsage: {
    label: 'Disk Usage',
    component: DevicesFilterDiskUsage,
  },
  memoryUsage: {
    label: 'Memory Usage',
    component: DevicesFilterMemoryUsage,
  },
  cpuUsage: {
    label: 'CPU Usage',
    component: DevicesFilterCpuUsage,
  },
  groups: {
    label: 'Groups',
    component: DevicesFilterGroups,
  },
  headphoneJack: {
    label: 'Audio Connection',
    component: DevicesFilterHeadphoneJack,
  },
  internalTemperature: {
    label: 'Internal Temperature',
    component: DevicesFilterInternalTemperature,
  },
  warrantyStartedAt: {
    label: 'Enrollment Date',
    component: DevicesFilterEnrollmentDate,
  },
};

function selectedItemsForFilter(key, filter = {}) {
  switch (key) {
    case 'model':
      return filter?.model ? 1 : 0;
    case 'tags':
      return filter?.tagIds?.length || 0;
    case 'groups':
      return filter?.groupIds?.length || 0;
    case 'firmwareVersion':
      return filter?.versions?.length || 0;
    case 'workflows':
      return filter?.workflowIds?.length || 0;
    case 'skills':
      return filter?.skillIds?.length || 0;
    case 'location':
      return filter?.locationIds || null;
    case 'downtime':
      return filter?.downtime?.enabled
        ? `> ${filter?.downtime?.range} ${filter?.downtime?.units}`
        : 0;
    case 'lastEvent':
      return 0;
    case 'connectionStatus':
      return filter?.status ? 1 : 0;
    case 'enrollmentStatus':
      return !isUndefined(filter?.autoClaim) ? 1 : 0;
    case 'cpuUsage':
      return filter?.cpu ? 1 : 0;
    case 'memoryUsage':
      return filter?.ram ? 1 : 0;
    case 'diskUsage':
      return filter?.disk ? 1 : 0;
    case 'headphoneJack':
      return !isUndefined(filter?.headphoneJack) ? 1 : 0;
    case 'internalTemperature':
      return filter?.internalTemperatureUsage ? 1 : 0;
    case 'warrantyStartedAt': {
      const start = filter?.provisionedDevice?.warrantyStartedAt[0]
        ? date.toDate(filter?.provisionedDevice?.warrantyStartedAt[0])
        : null;
      const end = filter?.provisionedDevice?.warrantyStartedAt[1]
        ? date.toDate(filter?.provisionedDevice?.warrantyStartedAt[1])
        : null;

      if (!start && !end) return 0;

      // Start but no end = > 01/01/21
      if (start && !end) {
        return `> ${date.format(start, 'MM/dd/yy')}`;
      }

      // End but no start = < 01/01/21
      if (!start && end) {
        return `< ${date.format(end, 'MM/dd/yy')}`;
      }

      // both = x days
      return date.formatDuration({ days: Math.abs(date.differenceInDays(start, end)) });
    }
    case 'subscription': {
      return filter?.subscription ? 1 : 0;
    }
    case 'outputs': {
      return Object.keys(filter).filter(k => k.startsWith('output')).length;
    }
    default:
      return 0;
  }
}

export default function DevicesFilterBar({ onFilter, filter }) {
  const deviceConnectionEnabled = useFeature('deviceConnection');
  const skillInputOutputEnabled = useFeature('skillInputOutput');
  let components = Object.entries(filterComponents);

  if (!deviceConnectionEnabled) {
    components = components.filter(
      ([_, { label }]) =>
        label !== filterComponents.connectionStatus.label &&
        label !== filterComponents.downtime.label,
    );
  }

  if (!skillInputOutputEnabled) {
    components = components.filter(([_, { label }]) => label !== filterComponents.outputs.label);
  }

  return (
    <Groups>
      {components.map(([key, { component: DeviceFilter, label }]) => {
        const count = selectedItemsForFilter(key, filter);

        return (
          <Group
            key={key}
            heading={label}
            count={count}
            defaultCollapsed={!count}
            collapsible
            hideOverflow={key !== 'location'}
          >
            <DeviceFilter onFilter={onFilter} filter={filter} />
          </Group>
        );
      })}
    </Groups>
  );
}
