import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'RotateImageElement',
  label: 'Rotate',
  icon: 'ArrowClockwise',
  description: 'Rotate a given image.',
  type: 'graphic_elements',
  inputs: [
    {
      label: 'Source Image',
      key: 'image',
      type: DATA_TYPES['Image'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
  defaultConfig: {
    orientation: 'clockwise',
  },
  schema: z.object({
    config: z.object({ orientation: z.string().min(1) }),
    inputs: z.object({ image: z.string().min(1) }),
  }),
};
