import { isString, truncate as _truncate } from 'lodash';
import { Fragment } from 'react';

import { Tooltip } from '@optra/kit';

import { useChartCtx } from 'modals/chart-details/context';
import { CONSTANTS, TIMEFRAME_BINS } from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

const { SPLIT_SYMBOL_2 } = CONSTANTS;

export default function ChartValue(props) {
  const {
    children,
    displayName: _displayName,
    as = Fragment,
    enable = true,
    tooltip = true,
    truncate = CONSTANTS.MAX_LABEL_LENGTH,
    timeFormat,
    ...passedProps
  } = props;
  const {
    helpers: { getChartConfig },
  } = useChartCtx();

  const Component = as;

  if (!enable) {
    return <Component {...passedProps}>{children}</Component>;
  }

  if (!isString(children)) {
    return <Component {...passedProps} />;
  }

  const [name, key, rootValue, valueAlias] = helpers.splitStringViaSymbol(children, SPLIT_SYMBOL_2);

  if (!rootValue) {
    return <Component {...passedProps}>{children}</Component>;
  }

  const { timeframeBin, syncTimezones } = getChartConfig();

  const isTimeGroup = helpers.isTimeGroupDimension(key);
  const isTime = helpers.isTimeDimension(key);

  const value = isTimeGroup
    ? helpers.formatTimeGroup(key, rootValue, { useLocal: !syncTimezones })
    : isTime
    ? helpers.formatTime(
        rootValue,
        timeFormat || TIMEFRAME_BINS[timeframeBin]?.format,
        syncTimezones,
      )
    : rootValue;

  const resolvedValue = valueAlias || value;
  const truncatedValue = _truncate(resolvedValue, { length: truncate });

  const displayName = _displayName && name && !isTime;

  return (
    <Component {...passedProps}>
      {displayName && `${name}: `}
      {tooltip ? (
        <Tooltip label={resolvedValue} disabled={resolvedValue <= truncatedValue}>
          <span>{truncatedValue}</span>
        </Tooltip>
      ) : (
        truncatedValue
      )}
    </Component>
  );
}
