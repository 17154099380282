import cx from 'classnames';

import { Icon, Text } from '@optra/kit';

const BORDERS = {
  primary: ['border-primary'],
  red: ['border-red'],
  yellow: ['border-yellow'],
  green: ['border-green'],
  blue: ['border-blue'],
};

function DataTypeIcon({ color, icon, className }) {
  return (
    <div
      className={cx([
        'flex-shrink-0',
        'w-10',
        'h-10',
        'flex',
        'items-center',
        'justify-center',
        'rounded-full',
        'bg-light-bg-secondary',
        'border',
        'border-solid',
        BORDERS[color],
        className,
      ])}
    >
      <Icon name={icon} color={color} weight="duotone" size="md" />
    </div>
  );
}

export default function DataTypeHeading({ type, label }) {
  return (
    <div className="flex flex-row item-center space-x-2">
      {Array.isArray(type) ? (
        <div className="w-10 h-10 relative">
          {type.map((t, i) => (
            <DataTypeIcon
              key={`data-type-icon-${i}`}
              color={t?.color}
              icon={t?.icon}
              className={cx(['absolute', i === 0 ? ['-top-2', '-left-2'] : ['top-2', 'left-2']])}
            />
          ))}
        </div>
      ) : (
        <DataTypeIcon color={type?.color} icon={type?.icon} />
      )}
      <div className="flex-col">
        <Text size="sm" className="block leading-tight">
          {label}
          <br />
          <Text size="xs" color="muted">
            Type: {Array.isArray(type) ? type.map(t => t.label).join(' or ') : type?.label}
          </Text>
        </Text>
      </div>
    </div>
  );
}
