import { useParams, Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { Button, Icon, Text } from '@optra/kit';

import DeviceDetailCameras from 'components/device-detail-cameras';
import DeviceDetailEdit from 'components/device-detail-edit';
import DeviceDetailLogs from 'components/device-detail-logs';
import DeviceDetailOverview from 'components/device-detail-overview';
import DeviceDetailSkills from 'components/device-detail-skills';
import { useFeature } from 'components/feature';
import Message from 'components/message';
import Modal, { useModalContext } from 'components/modal';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Tab from 'components/tab';
import Tabs from 'components/tabs';
import { api, q } from 'config/api';
import { useItemNotFound } from 'hooks';
import AdminCreateFirmware from 'modals/admin-create-firmware';
import CreateCamera from 'modals/create-camera';
import DeviceFirmware from 'modals/device-firmware';
import EditCamera from 'modals/edit-camera';
import EWSController from 'modals/ews-controller';
import ItemNotFound from 'modals/item-not-found';
import ManageLocations from 'modals/manage-locations';
import ManageTags from 'modals/manage-tags';
import ReplaceDevice from 'modals/replace-device';
import SkillWebUI from 'modals/skill-web-ui';
import SSHController from 'modals/ssh-controller';
import { useDevice, useCurrentOrganization } from 'queries';

export default function DeviceDetail() {
  const { deviceId, workflowId } = useParams();
  const { handleClose } = useModalContext();
  const [organization] = useCurrentOrganization();
  const billingBetaEnabled = useFeature('billingBeta');

  const {
    data,
    isLoading: fetching,
    error: fetchError,
  } = useDevice(
    deviceId,
    `
      id
      name
      family
      autoClaim
      provisionedDevice {
        returnAuthorization
      }
      firmware {
        firmware {
          name
          buildTarget
          iotedgeVersion
          releaseNotes
        }
      }
    `,
  );

  const qc = q.useQueryClient();
  const removeWorkflowDevice = q.useMutation({
    mutationFn: form =>
      api(
        `mutation removeWorkflowDevice($form: removeWorkflowDeviceForm!) {
          workflowDevice: removeWorkflowDevice(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
      handleClose();
    },
  });

  const handleRemove = async event => {
    event.stopPropagation();
    event.preventDefault();
    if (window.confirm('Are you sure you want to remove this device from its workflow?')) {
      removeWorkflowDevice.mutate({
        workflowId,
        deviceId,
      });
    }
  };

  const loading = fetching;

  const itemNotFound = useItemNotFound({
    fetching: loading,
    id: data?.device?.id,
  });

  const subscriptionExpired =
    !fetching &&
    billingBetaEnabled &&
    organization.pricing === 'credit' &&
    data?.device?.subscriptionActive !== true;

  let render;
  if (itemNotFound) {
    render = <ItemNotFound id={deviceId} type="Device" />;
  } else {
    render = (
      <>
        <ModalTitle
          title={data?.device?.name}
          icon="Aperture"
          loading={loading}
          renderActions={() =>
            workflowId && (
              <Button
                icon="Trash"
                size="xs"
                variant="secondary"
                onClick={handleRemove}
                loading={removeWorkflowDevice.isPending}
              >
                Remove
              </Button>
            )
          }
        />

        {data?.device?.provisionedDevice?.returnAuthorization && (
          <div className="bg-secondary p-3 md:flex items-center justify-between space-y-2">
            <div className="md:flex items-center md:space-x-2">
              <Icon name="Lightning" size="xs" />
              <Text>RMA Number Generated:</Text>
              <Text className="font-medium">
                {data?.device?.provisionedDevice?.returnAuthorization}
              </Text>
            </div>
            <Button
              size="xs"
              variant="plain"
              to={`./edit/replace`}
              state={{ fromModal: true }}
              icon="Question"
              className="border border-white border-solid"
            >
              View Details
            </Button>
          </div>
        )}

        {data?.device?.autoClaim && (
          <div className="bg-blue text-white p-3 md:flex items-center justify-between space-y-2">
            <div className="md:flex items-center md:space-x-2">
              <Icon name="Clock" size="xs" />
              <Text>Device Pending Enrollment</Text>
            </div>
          </div>
        )}

        {subscriptionExpired && (
          <div className="bg-red text-white p-3 md:flex items-center justify-between space-y-2">
            <div className="md:flex items-center md:space-x-2">
              <Icon name="Warning" size="xs" />
              <div className="flex flex-col">
                <Text className="font-medium">Subscription Expired</Text>
                <Text size="sm">
                  The Optra Portal Subscription for this device is no longer active. Would you like
                  to re-subscribe?
                </Text>
              </div>
            </div>
            <Button
              size="xs"
              variant="plain"
              to={`./subscribe`}
              state={{ fromModal: true }}
              icon="Check"
              className="border border-white border-solid"
            >
              Subscribe
            </Button>
          </div>
        )}

        <Tabs className="p-4">
          <Tab to="./overview" icon="SquaresFour">
            Overview
          </Tab>
          <Tab to="./skills" icon="Cube">
            Skills
          </Tab>
          {data?.device?.family === 'vision' && (
            <Tab to="./cameras" icon="VideoCamera">
              Cameras
            </Tab>
          )}
          <Tab to="./edit" icon="Gear">
            Settings
          </Tab>
          <Tab to="./logs" icon="Terminal">
            Logs
          </Tab>
        </Tabs>

        {fetchError && (
          <Message variant="danger" title="Error Loading Device">
            {fetchError.message}
          </Message>
        )}
      </>
    );
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <ModalInner>
            {render}
            <Outlet />
          </ModalInner>
        }
      >
        <Route index element={<Navigate to="./overview" replace />} />
        <Route
          path="overview"
          element={
            <>
              <DeviceDetailOverview deviceId={deviceId} />
              <Outlet />
            </>
          }
        >
          <Route
            path="firmware"
            element={
              <Modal>
                <DeviceFirmware />
              </Modal>
            }
          />
          <Route
            path="firmware/override"
            element={
              <Modal>
                <AdminCreateFirmware
                  override
                  defaultValues={data?.device?.firmware?.firmware}
                  isLoadingDefaults={fetching}
                  deviceId={deviceId}
                />
              </Modal>
            }
          />
          <Route
            path="ews"
            element={
              <Modal>
                <EWSController />
              </Modal>
            }
          />
        </Route>
        <Route path="skills">
          <Route index element={<DeviceDetailSkills deviceId={deviceId} />} />
          <Route
            path=":workflowSkillId/web-ui"
            element={
              <Modal>
                <SkillWebUI />
              </Modal>
            }
          />
        </Route>
        <Route
          path="edit"
          element={
            <>
              <DeviceDetailEdit deviceId={deviceId} onClose={handleClose} />
              <Outlet />
            </>
          }
        >
          <Route
            path="manage-tags/*"
            element={
              <Modal>
                <ManageTags />
              </Modal>
            }
          />
          <Route
            path="manage-locations/*"
            element={
              <Modal>
                <ManageLocations />
              </Modal>
            }
          />
          <Route
            path="replace"
            element={
              <Modal>
                <ReplaceDevice />
              </Modal>
            }
          />
        </Route>
        <Route
          path="cameras"
          element={
            <>
              <DeviceDetailCameras deviceId={deviceId} />
              <Outlet />
            </>
          }
        >
          <Route
            path="create"
            element={
              <Modal>
                <CreateCamera />
              </Modal>
            }
          />
          <Route
            path=":cameraId/edit"
            element={
              <Modal>
                <EditCamera />
              </Modal>
            }
          />
        </Route>
        <Route
          path="logs"
          element={
            <>
              <DeviceDetailLogs deviceId={deviceId} />
              <Outlet />
            </>
          }
        >
          <Route
            path="ssh"
            element={
              <Modal>
                <SSHController />
              </Modal>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}
