import cx from 'classnames';
import { merge } from 'lodash/fp';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PrimaryCTAButton, AddPlaceholderButton } from '@optra/kit';

import ChartSettingsModal from 'components/charts/chart-settings-modal';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';
import { CALCULATIONS, CHARTS, TIMEFRAMES, TIMEFRAME_BINS } from 'modals/chart-details/data';

export const DEFAULT_CHART = {
  name: '',
  query: '',
  type: CHARTS.bar.type,
  config: {
    timeframe: {
      start: TIMEFRAMES['14d'].type,
    },
    timeframeBin: TIMEFRAME_BINS['1h'].type,
    calculation: CALCULATIONS.AVG.type,
    xAxis: ['time'],
  },
};

export default function CreateChartButton(props) {
  const { variant = 'placeholder' } = props;
  const workspaceId = props?.workspace?.id;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [readOnly] = useHasRoles(['chartViewerOnly'], true);

  const qc = q.useQueryClient();
  const createWorkspaceChart = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createWorkspaceChart($form: createWorkspaceChartForm!) {
          workspaceChart: createWorkspaceChart(form: $form) {
            id
            layout
            chart {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess: () => qc.invalidateQueries({ queryKey: ['workspace'] }),
  });

  const buttonVariants = {
    placeholder: (
      <AddPlaceholderButton
        text="Add Chart"
        onClick={() => setIsOpen(true)}
        loading={createWorkspaceChart.isPending}
        className={cx('col-span-6 md:col-span-3 h-80')}
        large
      />
    ),
    primary: (
      <PrimaryCTAButton
        onClick={() => setIsOpen(true)}
        loading={createWorkspaceChart.isPending}
        text="Add Chart"
      />
    ),
  };

  const chartDefaults = merge(DEFAULT_CHART);

  return (
    <>
      {buttonVariants[variant]}
      <ChartSettingsModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={data => {
          if (readOnly) return;
          createWorkspaceChart.mutate(
            {
              workspaceId,
              chart: chartDefaults(data),
            },
            {
              onSuccess({ workspaceChart }) {
                const { chart } = workspaceChart;
                setIsOpen(false);
                navigate(`chart/${chart.id}/edit`);
              },
            },
          );
        }}
        isError={createWorkspaceChart.isError}
        isLoading={createWorkspaceChart.isPending}
        workspace={props?.workspace}
      />
    </>
  );
}
