import cx from 'classnames';
import { forwardRef } from 'react';

const VARIANTS = {
  base: ['block', 'w-full', 'rounded-md', 'shadow-sm'],
  default: [
    // Light
    'border-opacity-50',
    'bg-light-bg-secondary',
    'border-light-fg-secondary',
    // Dark
    'dark:border-opacity-50',
    'dark:bg-dark-bg-primary',
    'dark:border-dark-fg-secondary',
    // Focus
    'focus:border-primary',
    'focus:ring-primary',
  ],
  danger: ['bg-red-800', 'border-red'],
  logs: ['bg-black', 'text-green', 'font-mono', 'text-xs', 'resize-none'],
};

export default forwardRef(({ variant = 'default', className, ...rest }, ref) => (
  <textarea ref={ref} className={cx([VARIANTS.base, VARIANTS[variant], className])} {...rest} />
));
