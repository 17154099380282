import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Card, Button } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';

export default function CreateDeviceGroup() {
  useTitle('New Device Group | Optra');
  const { workflowId } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');

  const qc = q.useQueryClient();
  const createWorkflowDeviceGroup = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createWorkflowDeviceGroup($form: createWorkflowDeviceGroupForm!) {
          group: createWorkflowDeviceGroup(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
      navigate(`/workflows/${workflowId}/groups/${r?.group?.id}`, {
        state: {
          fromModal: true,
        },
      });
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    createWorkflowDeviceGroup.mutate({
      workflowId,
      ...form,
    });
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="New Device Group"
        icon="FolderPlus"
        loading={createWorkflowDeviceGroup.isPending}
      />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create Device Group">
            {error.message}
          </Message>
        )}
        <Card variant="secondary">
          <div className="space-y-2">
            <Label htmlFor="name">Group Name</Label>
            <Input
              type="text"
              {...register('name', { required: 'Please enter a name.' })}
              readOnly={createWorkflowDeviceGroup.isPending}
            />
            <ValidationError errors={errors} name="name" />
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={createWorkflowDeviceGroup.isPending}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
