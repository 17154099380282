import { api, q } from 'config/api';

export default function useGiftCard(id, overrides = null) {
  return q.useQuery({
    queryKey: ['giftCard', id],
    queryFn: () =>
      api(
        `
					query GiftCard($id: ID!) {
						giftCard(id: $id) {
							id
							amount
							balance
							code
							isRedeemed
							isRevoked
							redeemedOn
							revokedOn
							purchaseOrder {
								id
								poNumber
								vendor
							}
						}
					}
			`,
        { id },
      ),
    ...overrides,
  });
}
