import cx from 'classnames';
import { truncate } from 'lodash';

import { Card, Dropdown, Icon, Text, Badge, DROPDOWN_MENU_PLACEMENT } from '@optra/kit';

import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import getNodeDef from 'modals/skill-pipeline-builder/context/get-node-def';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function InputChooser(props) {
  const { nodeId, type, value, onChange, availableOutputs: _availableOutputs } = props;

  const { uniqueLabelForNode } = usePipelineContext();
  const [, { availableOutputsForInputType }] = usePipelineNode(nodeId);
  const availableOutputs = Array.isArray(_availableOutputs)
    ? _availableOutputs
    : availableOutputsForInputType({ type });

  function handleChange(value) {
    onChange(value);
  }

  const selectedOutput = availableOutputs.find(
    output => value === `${output.node.id}.${output.key}`,
  );

  return (
    <Dropdown
      components={{
        button: ({ open }) => (
          <Card
            variant="clean"
            size="sm"
            className={cx([
              'flex',
              'flex-row',
              'items-center',
              'justify-between',
              !selectedOutput && ['border-red'],
            ])}
          >
            {selectedOutput ? (
              <div className="flex flex-row items-center space-x-2">
                <Icon
                  name={getNodeDef(selectedOutput.node).icon}
                  color="primary"
                  weight="duotone"
                />
                <div className="flex flex-col items-start">
                  <Text size="sm" className="block">
                    {truncate(getNodeDef(selectedOutput.node).label, { length: 24 })}
                  </Text>
                  <Badge variant={selectedOutput.type.color} size="xxs">
                    {selectedOutput.label}
                  </Badge>
                </div>
              </div>
            ) : (
              <div className="flex flex-row items-center space-x-2">
                <Icon name="Plug" color="primary" weight="duotone" />
                <div className="flex flex-col items-start">
                  <Text size="sm" className="block" color="danger">
                    Choose an input…
                  </Text>
                </div>
              </div>
            )}
            <Icon name={open ? 'CaretUp' : 'CaretDown'} size="xs" />
          </Card>
        ),
      }}
      placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
      className="block w-full"
    >
      <Dropdown.MenuBody divide scrolling>
        {availableOutputs.map(output => {
          const active = value === `${output.node.id}.${output.key}`;
          return (
            <Dropdown.Item
              key={`${output.node.id}.${output.key}`}
              active={active}
              onClick={() => handleChange(`${output.node.id}.${output.key}`)}
              hoverSubtle
            >
              <div className="px-1 py-3 w-full flex items-center justify-between space-x-8">
                <div className="flex items-center space-x-2">
                  <Icon name={getNodeDef(output.node).icon} color="primary" weight="duotone" />
                  <div className="flex flex-col items-start">
                    <Text size="sm" className="block">
                      {truncate(uniqueLabelForNode(output.node), { length: 24 })}
                    </Text>
                    <Badge variant={output.type.color} size="xxs" className="leading-[8px]">
                      {output.label}
                    </Badge>
                  </div>
                </div>
                <Icon
                  name={active ? 'CheckCircle' : 'Circle'}
                  color={active ? 'primary' : 'gray'}
                  weight={active ? 'fill' : 'thin'}
                  size="sm"
                />
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.MenuBody>
    </Dropdown>
  );
}
