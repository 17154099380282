import { useController } from 'react-hook-form';

import { Table, WhatsThis, DetailHeading, DetailList } from '@optra/kit';

import Label from 'components/label';

export default function SkillBuilderProjectTypeSelect(props) {
  const { control, name: _name, defaultValue, loading, seatsLeft = 0 } = props;
  const name = _name || 'enterprise';
  const controller = useController({
    control,
    name,
    defaultValue: defaultValue || false,
  });
  const currentValue = controller.field.value;
  const select = value => {
    if (loading) return;
    controller.field.onChange(value);
  };

  return (
    <>
      <div className="mb-3">
        <Label htmlFor={name}>Project Type</Label>
        <WhatsThis>
          An enterprise project uses higher performing compute for faster builds
        </WhatsThis>
      </div>
      <Table>
        <Table.Body>
          <Table.Row
            hover
            selectable
            selected={!currentValue}
            loading={loading}
            onClick={() => select(false)}
          >
            <Table.TD>
              <DetailHeading>Free</DetailHeading>
            </Table.TD>
          </Table.Row>
          <Table.Row
            hover
            selectable
            disabled={!seatsLeft}
            selected={currentValue}
            loading={loading}
            onClick={() => select(true)}
          >
            <Table.TD>
              <DetailHeading>Enterprise</DetailHeading>
              <DetailList details={[`${seatsLeft} remaining`]} />
            </Table.TD>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}
