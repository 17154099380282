import { flatMap, isEmpty, startCase } from 'lodash';

import { Table, Text } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import dateLabel from 'lib/date-label';
import { useGiftCardLogs } from 'queries/use-gift-card-logs';

export default function AdminGiftCardLog({ giftCardId }) {
  const giftCardLogs = useGiftCardLogs(giftCardId);
  const logs = flatMap(giftCardLogs.data?.pages, page => page?.list?.data);

  const getLogMessage = log => {
    switch (log.event) {
      case 'created':
        return `Created by ${log.actor?.name || ''}`;
      case 'used':
        return `${log.device?.name || ''} ${log.amount > 0 ? 'added' : 'used'} ${Math.abs(
          log.amount,
        )} credits`;

      case 'redeemed':
        return `Redeemed by ${log.actor?.name || ''}`;
      case 'revoked':
        return `Revoked by ${log.actor?.name || ''}`;
      default:
        break;
    }
  };

  return (
    <>
      <Text size="xl" variant="bold">
        History
      </Text>
      <Table className="mt-2">
        <Table.Body>
          {!isEmpty(logs) && !giftCardLogs?.isLoading ? (
            logs.map(log => (
              <Table.Row key={log.createdAt} hover>
                <Table.TD>
                  <Text color="muted">{startCase(log.event)}</Text>
                </Table.TD>
                <Table.TD>
                  <Text color="muted">{getLogMessage(log)}</Text>
                </Table.TD>
                <Table.TD>
                  <Text color="muted">{dateLabel(log.createdAt)}</Text>
                </Table.TD>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.TD>
                <Text>No history available</Text>
              </Table.TD>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <IntersectionLoadMore
        onVisible={giftCardLogs.fetchNextPage}
        disabled={giftCardLogs.isFetchingNextPage || !giftCardLogs.hasNextPage}
      />
    </>
  );
}
