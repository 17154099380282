import { Controller } from 'react-hook-form';

import { Card } from '@optra/kit';

import ImgInputArea from 'components/img-input-area';
import Label from 'components/label';
import ValidationError from 'components/validation-error';

export default function PublishSkillBanner(props) {
  const { form, disabled } = props;

  return (
    <Card variant="secondary" className="space-y-4">
      <div className="space-y-2">
        <Label>Images (optional)</Label>
        <div className="scroll-smooth overflow-x-auto whitespace-nowrap space-x-2">
          <Controller
            name="screenshotUrls[0]"
            control={form.control}
            render={({ field }) => <ImgInputArea {...field} disabled={disabled} />}
          />
          <Controller
            name="screenshotUrls[1]"
            control={form.control}
            render={({ field }) => <ImgInputArea {...field} disabled={disabled} />}
          />
          <Controller
            name="screenshotUrls[2]"
            control={form.control}
            render={({ field }) => <ImgInputArea {...field} disabled={disabled} />}
          />
        </div>

        <ValidationError errors={form.formState.errors} name="screenshotUrls[0]" />
        <ValidationError errors={form.formState.errors} name="screenshotUrls[1]" />
        <ValidationError errors={form.formState.errors} name="screenshotUrls[2]" />
      </div>
    </Card>
  );
}
