import { api, q } from 'config/api';

export default function useWorkflowActionConfiguration({ id, inputs }, overrides) {
  return q.useQuery({
    queryKey: ['workflowAction', id, inputs, 'configuration'],
    queryFn: () =>
      api(
        `query workflowActionConfiguration($id: ID!, $inputs: JSON) {
          workflowAction(id: $id) {
            id
            configuration
            fields(inputs: $inputs) {
              configuration
            }
          }
        }`,
        { id, inputs },
      ),
    enabled: !!id,
    ...overrides,
  });
}
