import FeatureToggle from 'components/feature-toggle';
import { useChartCtx } from 'modals/chart-details/context';

export default function ControlSyncTimezones({ variant = 'dark', ...rest }) {
  const {
    helpers: { getChartConfig },
    actions: { setSyncTimezones },
  } = useChartCtx();
  const { syncTimezones } = getChartConfig();
  return (
    <FeatureToggle
      title="Local Time"
      description="Sync timezones based on device location"
      variant={variant}
      {...rest}
      checked={!!syncTimezones}
      onChange={(e, checked) => setSyncTimezones(checked)}
    />
  );
}
