import { useParams, useNavigate } from 'react-router-dom';

import { Card, Button, Text, Heading } from '@optra/kit';

import MarketplaceSkillPrivilegeCards from 'components/marketplace-skill-privilege-cards';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';
import { api, q } from 'config/api';
import { useMarketplaceSkill } from 'queries';

export default function MarketplaceSubscribe() {
  const { marketplaceSkillId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading: fetching, error: fetchError } = useMarketplaceSkill(marketplaceSkillId);

  // TODO: Move this to a mutations hook
  const subscribeToMarketplaceSkill = q.useMutation({
    mutationFn: id =>
      api(
        `mutation subscribeToMarketplaceSkill($id: ID!) {
          subscribeToMarketplaceSkill(id: $id) {
            id
          }
        }`,
        { id },
      ),
  });

  const handleSubscribe = () =>
    subscribeToMarketplaceSkill.mutateAsync(marketplaceSkillId, {
      onSuccess() {
        // NOTE: Only runs if the component is still mounted
        navigate('/skills');
      },
    });

  return (
    <ModalInner>
      <ModalTitle title="Add Skill" icon="Plus" loading={fetching} />
      <ModalBody>
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Skill">
            {fetchError.message}
          </Message>
        )}

        <Card variant="secondary" className="flex flex-2 flex-col items-center justify-center">
          <SkillIcon
            size="lg"
            color={data?.marketplaceSkill?.color}
            icon={data?.marketplaceSkill?.icon}
            iconUrl={data?.marketplaceSkill?.iconUrl}
            className="mb-2"
            fetching={fetching}
          />

          <div className="text-center">
            <Heading>{data?.marketplaceSkill?.name || 'Loading…'}</Heading>
          </div>

          {data?.marketplaceSkill?.developerProfile?.name && (
            <div className="text-center">
              <Text size="sm" color="muted">
                by {data?.marketplaceSkill?.developerProfile?.name}
              </Text>
            </div>
          )}
        </Card>

        <MarketplaceSkillPrivilegeCards marketplaceSkill={data?.marketplaceSkill} />
      </ModalBody>
      <ModalFooter className="space-x-3">
        <Button
          icon="Check"
          onClick={handleSubscribe}
          loading={subscribeToMarketplaceSkill.isPending}
        >
          Add to Library
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
