import { PrimaryCTAButton, PageWrapper, SearchField } from '@optra/kit';

import AdminOrganizationsList from 'components/admin-organizations-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import useDebouncedState from 'hooks/use-debounced-state';
import { useOrganizations } from 'queries';

export default function AdminOrganizations() {
  const [orgSearch, setOrgSearch, currentOrgSearch] = useDebouncedState('');
  const {
    isLoading,
    error,
    data: organizations,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isRefetching,
    isFetchedAfterMount,
  } = useOrganizations({ all: true, $search: orgSearch || '' });

  return (
    <PageWrapper
      icon="Buildings"
      title="Organizations | Admin"
      heading="Organizations"
      components={{
        actions: (
          <>
            <PinPageButton />
            <PrimaryCTAButton to="/admin/organizations/create" text="New Organization" />
          </>
        ),
        controls: (
          <div className="flex items-center justify-between">
            <SearchField
              searching={isRefetching && !isFetchedAfterMount}
              value={currentOrgSearch}
              onChange={setOrgSearch}
              onClear={() => setOrgSearch('')}
              placeholder="Search Orgs…"
              onKeyDown={e => e.stopPropagation()}
              className="max-w-sm"
            />
          </div>
        ),
      }}
      inline={true}
      loading={isLoading}
      error={error}
    >
      <AdminOrganizationsList fetching={isLoading} error={error} organizations={organizations} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isLoading || isFetchingNextPage || !hasNextPage}
      />
    </PageWrapper>
  );
}
