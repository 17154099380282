import flatMap from 'lodash/flatMap';

import { PageWrapper } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import MarketplaceList from 'components/marketplace-list';
import PinPageButton from 'components/pin-page-button';
import SkillsSubNav from 'components/skills-sub-nav';
import { useMarketplaceSkills } from 'queries';

export default function Marketplace() {
  const { data, error, fetchNextPage, isLoading, isFetchingNextPage, hasNextPage } =
    useMarketplaceSkills();
  const skills = flatMap(data?.pages, page => page?.list?.data);

  return (
    <PageWrapper
      icon="SquaresFour"
      title="Skills Marketplace"
      heading="Skills Marketplace"
      components={{
        subnav: <SkillsSubNav active="marketplace" />,
        actions: <PinPageButton />,
      }}
      loading={isLoading}
      error={error}
    >
      <MarketplaceList fetching={isLoading} error={error} marketplaceSkills={skills} />

      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isLoading || isFetchingNextPage || !hasNextPage}
      />
    </PageWrapper>
  );
}
