import { Icon, Text } from '@optra/kit';

function DevicesFilterListItem({ image, label, selected, border = true, onClick }) {
  return (
    <div onClick={onClick}>
      <div className="flex items-center justify-between px-2 py-3 cursor-pointer hover:bg-light-bg-primary dark:hover:bg-dark-bg-primary">
        <div className="flex items-center">
          <div className="mr-2">{image}</div>
          <Text size="xs" variant="label">
            {label}
          </Text>
        </div>
        {selected && <Icon name="CheckCircle" size="sm" color="primary" />}
      </div>
    </div>
  );
}

export default DevicesFilterListItem;
