"use strict";
const MODEL_TYPE = {
    CUSTOM: {
        label: 'Custom',
        value: 'CUSTOM'
    },
    EDGE_IMPULSE: {
        label: 'Edge Impulse',
        value: 'EDGE_IMPULSE'
    }
};
module.exports = {
    MODEL_TYPE
};
