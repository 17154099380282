import { noop, uniqueId } from 'lodash';

import FileButton from 'components/file-button';
import readFile from 'lib/read-file';

export default function SkillJsonUploadButton({
  onError = noop,
  setValue,
  label = 'Config',
  variant = 'secondary',
  className,
}) {
  return (
    <FileButton
      label={label}
      accept=".json"
      variant={variant}
      size="xs"
      className={className}
      onChange={async e => {
        const raw = await readFile(e.currentTarget.files[0]);
        let content = {};
        try {
          content = JSON.parse(raw);
        } catch (err) {
          onError(err);
          return;
        }

        Object.entries(content).forEach(([k, v]) => {
          switch (k) {
            case 'outputs': {
              setValue(
                k,
                Object.entries(v).map(([id, output]) => ({
                  _id: uniqueId(),
                  key: id,
                  value: output.label,
                  unhealthyTimeoutMS: output.unhealthyTimeoutMS || 1000 * 60 * 60,
                })),
              );
              break;
            }
            case 'inputs': {
              setValue(
                k,
                Object.entries(v).map(([id, input]) => ({
                  _id: uniqueId(),
                  key: id,
                  value: input.label,
                })),
              );
              break;
            }
            case 'env': {
              setValue(
                k,
                Object.entries(content.env).map(([key, value]) => ({
                  _id: uniqueId(),
                  key,
                  value,
                })),
              );
              break;
            }
            case 'storage': {
              setValue(
                k,
                Object.entries(content.storage).map(([key, value]) => ({
                  _id: uniqueId(),
                  key,
                  value,
                })),
              );
              break;
            }
            case 'createOptions': {
              setValue(k, JSON.stringify(v));
              break;
            }
            case 'exposedPorts': {
              setValue('port', parseInt(v.ui));
              break;
            }
            default: {
              return setValue(k, v);
            }
          }
        });
      }}
    />
  );
}
