import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Card, Icon, Table, Text } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import LocationInput from 'components/location-input';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useLocation } from 'queries';

export default function EditLocation() {
  const { handleBack } = useModalContext();
  const { locationId } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit: onSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      name: '',
      location: { lat: 0, lng: 0 },
    },
  });
  const [error, setError] = useState();
  const { data, error: fetchError, isLoading, isSuccess } = useLocation(locationId);
  const devices = data?.location?.devices;

  useOnSuccess(
    () => {
      const { location } = data;

      reset(formValues => {
        return {
          name: location?.name,
          location: {
            lat: location?.lat,
            lng: location?.lng,
          },
        };
      });
    },
    { isSuccess },
    [data, reset],
  );

  const qc = q.useQueryClient();
  const updateLocation = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateLocation($form: updateLocationForm!) {
          updateLocation(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['locations'] });
      qc.invalidateQueries({ queryKey: ['location', locationId] });
      reset({
        name: '',
      });
    },
    onError(err) {
      setError(err);
    },
  });

  const handleUpdate = onSubmit(async form => {
    setError(null);
    updateLocation.mutate(
      {
        id: locationId,
        name: form?.name,
        lat: form?.location?.lat,
        lng: form?.location?.lng,
      },
      {
        onSuccess: () => {
          handleBack();
        },
      },
    );
  });

  const removeLocation = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeLocation($id: ID!) {
          removeLocation(id: $id)
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['locations'] });
    },
  });

  const handleRemove = () => {
    if (
      window.confirm(
        'Are you sure you want to remove this location and remove it from all devices?',
      )
    ) {
      removeLocation.mutate(locationId, { onSuccess: () => handleBack() });
    }
  };

  return (
    <>
      <ModalTitle
        title="Edit Location"
        icon="MapPin"
        showBackButton={true}
        renderActions={() => (
          <Button
            size="xs"
            variant="secondary"
            onClick={() => {
              handleRemove();
            }}
            loading={removeLocation.isPending}
            icon="Trash"
          >
            Delete
          </Button>
        )}
      />
      <ModalBody>
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Location">
            {fetchError.message}
          </Message>
        )}
        {error && (
          <Message variant="danger" title="Couldn't Update Location">
            {error.message}
          </Message>
        )}

        <form onSubmit={handleUpdate}>
          <Card variant="secondary">
            <div className="flex flex-col space-y-2">
              <Label htmlFor="name">Location Name</Label>
              <Input
                type="text"
                placeholder="Location Name"
                readOnly={updateLocation.isPending}
                {...register('name', { required: 'Please enter a location name.' })}
              />
              <ValidationError errors={errors} name="name" />
            </div>
          </Card>
          <Table variant="secondary" className="mt-4">
            <Table.Body>
              <Table.Row
                caret={devices?.count}
                hover
                disabled={!devices?.count}
                onClick={() => navigate('devices')}
              >
                <Table.TD>
                  <Icon name="Aperture" color="gradient" className="mr-2" />
                  <Text>
                    {devices?.count || 0} Device
                    {devices?.count === 1 ? '' : 's'}
                  </Text>
                </Table.TD>
              </Table.Row>
            </Table.Body>
          </Table>
          <Card variant="secondary" className="mt-4">
            <Controller
              render={({ field }) => <LocationInput disabled={isLoading} {...field} />}
              name="location"
              control={control}
            />
          </Card>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" onClick={handleUpdate} loading={updateLocation.isPending} size="xl">
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
