import { api, q } from 'config/api';

export default function useDevice(deviceId, fragment, overrides) {
  return q.useQuery({
    queryKey: ['device', deviceId, fragment],
    queryFn: () =>
      api(
        `query device($deviceId: ID!) {
          device(id: $deviceId) {
            ${fragment}
          }
        }`,
        { deviceId },
      ),
    // refetchInterval: 5000,
    ...overrides,
  });
}
