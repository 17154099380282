import { GoogleMap, Marker } from '@react-google-maps/api';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useState, useEffect, forwardRef } from 'react';

import { Text, SearchField } from '@optra/kit';

import { statePin } from 'components/devices-map-marker';
import Input from 'components/input';
import Label from 'components/label';
import { useOnSuccess } from 'config/api';
import { defaultLocation } from 'config/constants';
import mapStyles from 'config/map-styles';
import { useGeocode } from 'queries';
import useGlobalState from 'state';

export default forwardRef(
  (
    { detectLocation = false, value, state = 'stable', compact = false, onChange = () => false },
    ref,
  ) => {
    const [mapScriptLoaded] = useGlobalState('mapScriptLoaded');
    const [appliedColorScheme] = useGlobalState('appliedColorScheme');

    useEffect(() => {
      if (detectLocation && (value?.lat === null || value?.lng === null)) {
        navigator.geolocation.getCurrentPosition(
          position => {
            onChange({
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            });
          },
          err => {
            // NOTE: error code 1 indicates the user denied geolocation permission, or their device doesn't support it
            // If we set the defaultLocation on this error, these users are blocked from making any change to input.
            if (err.code !== 1) {
              onChange(defaultLocation);
            }
          },
        );
      }
    }, [value, onChange, detectLocation]);

    const handleMovePin = ({ lat, lng }) => {
      onChange({ lat, lng });
    };

    const handleChangeLat = e => {
      const input = e.target.value;
      const { lng } = value;
      onChange({ lat: input.replace(/[^0-9.-]/g, ''), lng });
    };

    const handleChangeLng = e => {
      const input = e.target.value;
      const { lat } = value;
      onChange({ lat, lng: input.replace(/[^0-9.-]/g, '') });
    };

    const handBlurLat = e => {
      if (isEmpty(e.target.value) || Number.isNaN(parseFloat(e.target.value)))
        onChange({ lat: defaultLocation.lat, lng: value.lng });
      else onChange({ lat: parseFloat(e.target.value), lng: value.lng });
    };

    const handBlurLng = e => {
      if (isEmpty(e.target.value) || Number.isNaN(parseFloat(e.target.value)))
        onChange({ lat: value.lat, lng: defaultLocation.lng });
      else onChange({ lat: value.lat, lng: parseFloat(e.target.value) });
    };

    const [search, setSearch] = useState('');
    const handleSearch = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        setSearch(event.target.value);
      }
    };
    const { data, isLoading: fetching, isSuccess } = useGeocode(search);

    useOnSuccess(
      () => {
        if (data?.geocode?.lat && data?.geocode?.lng) {
          onChange({
            lat: data?.geocode?.lat || defaultLocation.lat,
            lng: data?.geocode?.lng || defaultLocation.lng,
          });
        }
      },
      { isSuccess },
      [data],
    );

    const [latFloat, lngFloat] = [parseFloat(value?.lat), parseFloat(value?.lng)];
    const calculatedLocation = {
      lat: !Number.isNaN(latFloat) ? latFloat : defaultLocation.lat,
      lng: !Number.isNaN(lngFloat) ? lngFloat : defaultLocation.lng,
    };

    return (
      <div className={cx('flex', 'flex-col', 'space-y-4')}>
        {mapScriptLoaded && (
          <div>
            <SearchField
              placeholder="Search Address…"
              onKeyDown={handleSearch}
              searching={fetching}
              variant="inverted"
            />
          </div>
        )}
        {mapScriptLoaded && (
          <>
            <GoogleMap
              mapContainerStyle={{
                height: '100%',
                minHeight: 260,
                width: '100%',
              }}
              zoom={14}
              center={{
                lat: calculatedLocation.lat,
                lng: calculatedLocation.lng,
              }}
              options={{
                disableDefaultUI: true,
                gestureHandling: true,
                zoomControl: true,
                styles: mapStyles({ darkMode: appliedColorScheme === 'dark' }),
              }}
            >
              <Marker
                onDragEnd={e =>
                  handleMovePin({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  })
                }
                draggable
                options={{
                  position: {
                    lat: calculatedLocation.lat,
                    lng: calculatedLocation.lng,
                  },
                  icon: {
                    url: statePin(state),
                    size: new google.maps.Size(16, 16),
                    origin: new google.maps.Point(0, 0),
                    scaledSize: new google.maps.Size(12, 12),
                    anchor: new google.maps.Point(6, 6),
                  },
                }}
              />
            </GoogleMap>
            <div className="space-y-6">
              <div className="flex items-center">
                <Label className="w-24 mr-2 text-right">Latitude</Label>
                <Input
                  type="text"
                  value={value?.lat === null ? '' : value.lat}
                  onChange={handleChangeLat}
                  onBlur={handBlurLat}
                  autoComplete="off"
                />
              </div>
              <div className="flex items-center">
                <Label className="w-24 mr-2 text-right">Longitude</Label>
                <Input
                  type="text"
                  value={value?.lng === null ? '' : value.lng}
                  onChange={handleChangeLng}
                  onBlur={handBlurLng}
                  autoComplete="off"
                />
              </div>
              <div className="flex items-center">
                <Label className="w-24 mr-2 text-right" />
                <div className="w-full flex items-center justify-start">
                  <img src={statePin(state)} alt="Map Pin" className="w-4 h-4 mr-2" />
                  <Text size="xs" color="muted">
                    Drag pin to set precise location
                  </Text>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  },
);
