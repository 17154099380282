import { useState } from 'react';

import { DetailHeading, Table, Badge, Icon, Text } from '@optra/kit';

import Textarea from 'components/textarea';

function CsvDocs({ columns }) {
  const [showHelp, setShowHelp] = useState(false);

  if (!Array.isArray(columns) || !columns.length) return null;

  const csvCols = columns.map(({ column }) => column).join(',');
  const csvValues = columns.map(({ examples = [] }) => examples[0]).join(',');
  const csvExamaple = `${csvCols}
${csvValues}`;

  return (
    <div className="space-y-2">
      <Text
        size="xs"
        color="muted"
        className="underline cursor-pointer flex items-center"
        onClick={() => setShowHelp(!showHelp)}
      >
        <Icon name={showHelp ? 'CaretDown' : 'CaretRight'} size="xs" className="mr-1" />
        {!showHelp && 'Show Documentation'}
        {showHelp && 'Hide Documentation'}
      </Text>
      {showHelp && (
        <div className="space-y-4">
          <div className="space-y-2">
            <Text variant="label" size="xs">
              Sample CSV
            </Text>
            <Textarea rows={4} readOnly className="text-xs" defaultValue={csvExamaple} />
          </div>

          <div className="space-y-2">
            <Text variant="label" size="xs">
              CSV Columns
            </Text>
            <Table>
              <Table.Body>
                {columns.map(({ name, description, column, examples, required }) => (
                  <Table.Row key={column}>
                    <Table.TD className="space-y-1">
                      <DetailHeading variant="loud" as="span">
                        {name}
                      </DetailHeading>
                      {!!description && (
                        <Text size="xs" color="muted">
                          {description}
                        </Text>
                      )}
                    </Table.TD>
                    <Table.TD collapsed>
                      {!!required && (
                        <Badge size="xs" variant="danger" className="capitalize">
                          required
                        </Badge>
                      )}
                    </Table.TD>
                    <Table.TD>
                      <Text size="xs" className="font-mono">
                        {column}
                      </Text>
                      <br />
                      {!!(Array.isArray(examples) && examples.length) && (
                        <span className="space-x-1">
                          {examples.map((example, idx) => (
                            <Badge
                              key={idx}
                              variant="inverted"
                              size="xs"
                              className="font-mono rounded-md whitespace-nowrap"
                              uppercase={false}
                            >
                              {example}
                            </Badge>
                          ))}
                        </span>
                      )}
                    </Table.TD>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

export default CsvDocs;
