import mapValues from 'lodash/mapValues';
import { useWindowSize } from 'react-use';
import { CONFIG } from '@optra/kit';
const useBreakpoint = ()=>{
    const windowSize = useWindowSize();
    const { width  } = windowSize;
    const breakpoints = mapValues(CONFIG.BREAKPOINTS, (breakpoint)=>parseInt(breakpoint, 10)) || {};
    const thresholds = mapValues(breakpoints, (breakpoint)=>breakpoint < width) || {};
    return {
        windowSize,
        breakpoints,
        thresholds
    };
};
export default useBreakpoint;
