import cx from 'classnames';

const DIRECTIONS = {
  horizontal: [
    'flex',
    'flex-row',
    'items-center',
    'justify-start',
    'md:justify-center',
    'space-x-1',
    'md:space-x-3',
    'overflow-x-scroll',
    'overflow-y-hidden',
    'scroll-bar-hidden',
  ],
  vertical: ['flex', 'flex-col', 'items-start'],
};

export default function Tabs({ children, className, direction = 'horizontal', ...rest }) {
  return (
    <nav aria-label="Tabs" className={cx([DIRECTIONS[direction], className])} {...rest}>
      {children}
    </nav>
  );
}
