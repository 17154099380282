import { api, q } from 'config/api';

export default function usePaymentMethods() {
  return q.useQuery({
    queryKey: ['paymentMethods'],
    queryFn: () =>
      api(
        `query {
          paymentMethods {
            id
            last4
            default
            processor
            expiration {
              month
              year
            }
          }
        }`,
      ),
    placeholderData: q.keepPreviousData,
    // refetchInterval: 5000,
  });
}
