import keys from 'lodash/keys';

import { Dropdown, Filter } from '@optra/kit';

import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import { useChartCtx } from 'modals/chart-details/context';
import { TIMEFRAME_BINS, INTERPOLATION_STYLES } from 'modals/chart-details/data';

export default function ControlInterpolate({ variant = 'dark', disabled, ...rest }) {
  const {
    helpers: { getChartConfig },
    actions: { setInterpolation, setInterpolationFill, setInterpolationStyle },
  } = useChartCtx();
  const {
    timeframeBin: _timeframeBin,
    interpolate,
    interpolationStyle: _interpolationStyle,
    interpolationFill,
  } = getChartConfig();
  const timeframeBin = TIMEFRAME_BINS[_timeframeBin];
  const currentGranularity = timeframeBin?.trunc_unit || timeframeBin?.unit;

  const interpolationStyle =
    INTERPOLATION_STYLES[_interpolationStyle] || INTERPOLATION_STYLES.interpolate_fill;

  return (
    <FeatureToggle
      title="Interpolate Data"
      description={
        currentGranularity &&
        !disabled &&
        `Fill gaps in data to display those ${currentGranularity}s`
      }
      variant={variant}
      disabled={disabled}
      {...rest}
      checked={!!interpolate && !disabled}
      expanded={!!interpolate && !disabled}
      onChange={(e, checked) => setInterpolation(checked)}
      beta
    >
      <div className="space-y-3">
        <Filter
          label="Style"
          variant="light"
          menu={{
            text: interpolationStyle.name,
            body: keys(INTERPOLATION_STYLES).map(type => {
              const { name, description } = INTERPOLATION_STYLES[type];
              const active = type === interpolationStyle.type;
              return (
                <Dropdown.Item
                  key={type}
                  active={active}
                  text={name}
                  detail={description}
                  onClick={() => setInterpolationStyle(type)}
                />
              );
            }),
          }}
        />
        {interpolationStyle.type === INTERPOLATION_STYLES.interpolate_fill.type && (
          <Filter label="Fill Value" variant="light">
            <div>
              <Input
                type="number"
                value={interpolationFill ?? 0}
                placeholder="0"
                className="!border-transparent !rounded-l-none bg-transparent !shadow-none"
                onChange={e => setInterpolationFill(e.target.value)}
              />
            </div>
          </Filter>
        )}
      </div>
    </FeatureToggle>
  );
}
