import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { generate } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'CountToLabelProcessor',
  label: 'Categorize',
  icon: 'TreeStructure',
  description:
    'Map the number of items detected to a new label. This is helpful with inventory related tasks.',
  type: 'processors',
  inputs: [
    {
      label: 'Label Counts',
      key: 'label_counts',
      type: DATA_TYPES['NumberList'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'label',
      label: 'Label',
      type: DATA_TYPES['Text'],
      labelBehavior: generate(node => {
        const selectedLabels = Object.values(node?.config?.countMapping) || [];
        return [...selectedLabels, 'none'];
      }),
    },
  ],
  defaultConfig: {
    countMapping: {
      '0-0': '',
    },
    labelOfInterest: '',
    maxCount: 50,
  },
  schema: z.object({
    config: z.object({
      countMapping: z.record(z.string().min(1), z.string().min(1)),
      labelOfInterest: z.string().min(1),
      maxCount: z.number().min(0),
    }),
    inputs: z.object({ label_counts: z.string().min(1) }),
  }),
};
