import React from 'react';
import { useAuth0 } from 'react-auth0rize';
import { Navigate, Outlet } from 'react-router-dom';

import { UiState } from '@optra/kit';

import { useHasRoles } from 'hooks';
import { useCurrentUser } from 'queries';

export default function ProtectedRoute({
  authenticate,
  requiresGlobalAdmin,
  orgMemberRoles = [],
  element = <Outlet />,
  allowChartViewer = false,
}) {
  const auth = useAuth0();
  const [currentUser, loadingCurrentUser] = useCurrentUser();
  const [hasRoles, loadingRoles] = useHasRoles(orgMemberRoles);
  const [viewChartOnly, loadingViewChartOnly] = useHasRoles(['chartViewerOnly'], true);

  if (loadingCurrentUser || loadingRoles || loadingViewChartOnly) {
    return <UiState center />;
  }

  // NOTE: currentUser sometimes exists but is filled with all null values.
  //       If the user's id is not defined, then the user is not logged in.
  //       If we don't get them logged in, they end up stuck on the EULA page.
  if ((authenticate && !auth.authenticated) || !currentUser?.id) {
    return <Navigate replace to="/login" />;
  }

  // NOTE: Assumes a route with authenticate requires terms to be agreed on
  if (authenticate && auth.authenticated && currentUser?.agreements?.eula?.state !== 'accepted') {
    return <Navigate replace to="/eula" />;
  }

  const missingAdminAuth = !!requiresGlobalAdmin && currentUser?.isSysAdmin !== true;
  const missingRoles = orgMemberRoles.length > 0 && !hasRoles;

  if (!allowChartViewer && viewChartOnly) {
    return <Navigate replace to="/charts" />;
  }

  if (missingAdminAuth || missingRoles) {
    return <Navigate replace to="/" />;
  }

  return element;
}
