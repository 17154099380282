import { isBoolean, keyBy, truncate } from 'lodash';
import { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';

import { Card, Button, Icon, Text, Heading } from '@optra/kit';

import Checkbox from 'components/checkbox';
import FirebaseActionMessageFields from 'components/firebase-action-message-fields';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import SkillIcon from 'components/skill-icon';
import { api, q, useOnSuccess } from 'config/api';
import { useWorkflowAction } from 'queries';

export default function FirebasePushNotificationActionForm({ workflowActionId, onClose }) {
  const {
    handleSubmit: onSubmit,
    reset,
    register,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      configurationFields: {},
    },
  });
  const [error, setError] = useState();
  const { fields: configurationFields } = useFieldArray({
    control,
    name: 'configurationFields',
  });

  const {
    data,
    isLoading: fetching,
    isSuccess,
    error: fetchError,
  } = useWorkflowAction(workflowActionId);
  const qc = q.useQueryClient();
  const updateWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowActionFirebase ($form: updateWorkflowActionForm!) {
          workflowAction: updateWorkflowAction(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      onClose();
    },
    onError(error) {
      setError(error);
    },
  });

  useOnSuccess(
    () => {
      const workflowSkills = (data?.workflowAction?.workflow?.skills?.data || []).filter(
        ws => !ws.disabled,
      );
      const triggerRules = data?.workflowAction?.triggerRules || [];
      const triggerRulesByWorkflowSkill = keyBy(triggerRules, 'workflowSkillId');
      const configurationFields = workflowSkills.map?.(workflowSkill => {
        const existingConfig = data?.workflowAction?.configuration?.[workflowSkill?.id] || {};
        return {
          ...workflowSkill,
          enabled: isBoolean(existingConfig?.disabled) ? !existingConfig.disabled : false,
          title: existingConfig?.title || '',
          message: existingConfig?.message || '',
          triggerRules: triggerRulesByWorkflowSkill[workflowSkill?.id] || {},
        };
      });

      reset({
        configurationFields,
      });
    },
    { isSuccess },
    [data, reset],
  );

  const handleSubmit = onSubmit(form => {
    setError(null);

    let configuration = {};
    let triggerRules = [];

    form?.configurationFields?.forEach(configurationField => {
      configuration[configurationField?.id] = {
        disabled: !configurationField?.enabled,
        title: configurationField?.title,
        message: configurationField?.message,
      };

      if (configurationField.triggerRules?.conditions.length > 0) {
        triggerRules.push({
          ...configurationField.triggerRules,
          workflowSkillId: configurationField.id,
        });
      }
    });

    updateWorkflowAction.mutate({
      id: workflowActionId,
      configuration,
      triggerRules,
    });
  });

  const loading = fetchError || fetching || updateWorkflowAction.isPending;

  return (
    <>
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Save Action">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Action">
            {fetchError.message}
          </Message>
        )}

        <Text color="muted" size="xs" className="flex items-center justify-center mb-8">
          <Icon name="Bell" size="xs" className="mr-2" />
          {`Firebase Push Notifications will be sent via Firebase Cloud Messaging`}
        </Text>

        {configurationFields?.map((configurationField, index) => (
          <Card variant="secondary" className="space-y-4" key={configurationField.id}>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <SkillIcon
                  size="sm"
                  color={configurationField.skill?.color}
                  icon={configurationField.skill?.icon}
                  iconUrl={configurationField.skill?.iconUrl}
                  className="mr-3"
                />
                <Heading level={4}>
                  {truncate(configurationField?.name || configurationField?.skill?.name, {
                    length: 32,
                  })}
                </Heading>
              </div>

              <Label>
                <Checkbox
                  className="mr-2"
                  {...register(`configurationFields.${index}.enabled`)}
                  defaultChecked={configurationField.enabled}
                />
                Enable Notification
              </Label>
            </div>

            <FirebaseActionMessageFields
              register={register}
              control={control}
              watch={watch}
              errors={errors}
              configurationField={configurationField}
              index={index}
              loading={loading}
            />
          </Card>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button size="xl" loading={loading} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
