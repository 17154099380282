import { api, q } from 'config/api';

export default function useWorkflowSkill(id, overrides) {
  return q.useQuery({
    queryKey: ['workflowSkill', id],
    queryFn: () =>
      api(
        `query workflowSkill($id: ID!) {
          workflowSkill(id: $id) {
            id
            name
            deleted
            disabled {
              disabled
              reasons
            }
            port
            automaticPort
            exposeToNetwork
            outputs {
              id
              disabled
              output {
                label
              }
            }
            inputs {
              key
              value
            }
            version {
              id
              version
              creditsCost
              inputs {
                label
                binding
                type
              }
              outputs {
                id
                label
              }
              port
            }
            skill {
              id
              icon
              iconUrl
              color
              name
              isDockerCompose
              versions {
                data {
                  id
                  version
                  inputs {
                    label
                    binding
                    type
                  }
                  outputs {
                    id
                    label
                  }
                  port
                  ... on SkillVersion {
                    deprecated
                  }
                }
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
