import { api, q } from 'config/api';

export default function useDeviceSkillOutputMappings(variables) {
  return q.useInfiniteQuery({
    queryKey: ['deviceSkillOutputMappings', variables],
    queryFn({ pageParam }) {
      return api(
        `query deviceSkillOutputMappings($list: listFields) {
          list: deviceSkillOutputMappings(list: $list) {
            data {
              id
              mapping
              key
              skill {
                id
                name
                icon
                iconUrl
                color
              }
              statusMapping {
                upperThreshold
                lowerThreshold
                direction
              }
              type
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    // refetchInterval: 5000,
  });
}
