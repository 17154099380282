import omit from 'lodash/omit';
import qs from 'qs';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useRouteQueryParams } from 'hooks';

export default function Forward({ to, omit: _omit = [] }) {
  const queryParams = useRouteQueryParams();

  const forwardQueryParams = qs.stringify(omit(queryParams, _omit), {
    addQueryPrefix: true,
  });

  if (!to) return null;

  return <Navigate to={`${to}${forwardQueryParams}`} />;
}
