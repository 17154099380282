import Lockr from 'lockr';
import { pullAt } from 'lodash';

const OrganizationClient = {
  set(organizationId) {
    Lockr.set('organizationId', organizationId);
    this.__notifyListeners(organizationId);
  },
  unset() {
    Lockr.rm('organizationId');
    this.__notifyListeners();
  },
  get: () => Lockr.get('organizationId'),
  __notifyListeners(newOrganizationId) {
    this.__listeners.forEach(listener => {
      listener(newOrganizationId);
    });
  },
  __listeners: [],
  listen(fn) {
    const fnIdx = this.__listeners.push(fn) - 1;
    // eslint-disable-next-line lodash/prefer-immutable-method
    return () => pullAt(this.__listeners, fnIdx);
  },
};

export default OrganizationClient;
