import { Card, Text, Heading } from '@optra/kit';

import FlexCenter from 'components/flex-center';

function EmptyContentMessage({
  icon = 'Question',
  variant = 'tertiary',
  title,
  body,
  renderAction,
  ...rest
}) {
  return (
    <FlexCenter className="my-12">
      <Card
        variant={variant}
        size="lg"
        className="text-center flex flex-col items-center justify-center"
        {...rest}
      >
        <div className="mt-2 mb-3">
          <Heading icon={icon} variant="centered">
            {title}
          </Heading>
          {body && <Text color="muted">{body}</Text>}
        </div>
        <div>{renderAction && renderAction()}</div>
      </Card>
    </FlexCenter>
  );
}

export default EmptyContentMessage;
