import { forwardRef, useRef } from 'react';

import { Button } from '@optra/kit';

export default forwardRef(function FileButton(props, ref) {
  const {
    label = 'Upload File',
    icon = 'FileArrowUp',
    size,
    variant,
    onChange,
    className,
    ...rest
  } = props;
  const fileInputRef = useRef(ref);
  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={`relative cursor-pointer ${className}`}>
      <input
        {...rest}
        className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
        component={Button}
        ref={fileInputRef}
        type="file"
        onChange={onChange}
      />
      <Button icon={icon} variant={variant} size={size} onClick={() => handleClick()}>
        {label}
      </Button>
    </div>
  );
});
