import { range } from 'lodash';
import moment from 'moment';

import { Card, Group } from '@optra/kit';

import FeatureToggle from 'components/feature-toggle';

import { useChartCtx } from '../context';

export default function ControlHourSelection(props) {
  const {
    heading = 'HOURS OF DAY',
    description = 'Select the hours of the day to include in your query',
    collapsible = true,
    scrollable = false,
    defaultCollapsed = false,
    ...rest
  } = props;
  const {
    helpers: { getChartConfig },
    actions: { toggleDisabledHours },
  } = useChartCtx();
  const { disabledHours: _disabledHours, syncTimezones } = getChartConfig();
  const disabledHours = _disabledHours || [];

  // put 12:00 AM first
  const AM = [
    {
      label: '12:00 AM',
      value: syncTimezones ? 24 : moment().hour(24).utc().hour(),
    },
    ...range(1, 12).map(n => ({
      label: `${n === 0 ? 12 : n}:00 AM`,
      value: syncTimezones ? n : moment().hour(n).utc().hour(),
    })),
  ];
  const PM = [
    {
      label: '12:00 PM',
      value: syncTimezones ? 12 : moment().hour(12).utc().hour(),
    },
    ...range(13, 24).map(n => ({
      label: `${n === 0 ? 12 : n - 12}:00 PM`,
      value: syncTimezones ? n : moment().hour(n).utc().hour(),
    })),
  ];

  const count = 24 - disabledHours.length;

  return (
    <Group
      heading={heading}
      description={description}
      count={count === 24 ? null : count}
      collapsible={collapsible}
      scrollable={scrollable}
      defaultCollapsed={defaultCollapsed}
      {...rest}
    >
      <div className="grid grid-cols-2 gap-3">
        <Card variant="dark" className="pb-3">
          {AM.map(({ label, value }) => {
            const isActive = !disabledHours.includes(value);
            return (
              <FeatureToggle
                key={value}
                variant="plain"
                title={label}
                checked={!!isActive}
                onClick={() => toggleDisabledHours(value)}
                uppercase={false}
                bold={false}
              />
            );
          })}
        </Card>
        <Card variant="dark" className="pb-3">
          {PM.map(({ label, value }) => {
            const isActive = !disabledHours.includes(value);
            return (
              <FeatureToggle
                key={value}
                variant="plain"
                title={label}
                checked={!!isActive}
                onClick={() => toggleDisabledHours(value)}
                uppercase={false}
                bold={false}
              />
            );
          })}
        </Card>
      </div>
    </Group>
  );
}
