import { isArray } from 'lodash';
import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'StaticImageElement',
  label: 'Image or Color',
  icon: 'Image',
  description: 'Upload an image or enter a color value.',
  type: 'graphic_elements',
  inputs: [],
  outputs: [
    {
      key: 'image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
  defaultConfig: {
    imageSrc: null,
  },
  schema: z
    .object({
      config: z.record(
        z.union([
          z.string().min(1, 'ImageSrc is required'),
          z.array(z.number()).length(3, 'Color format is invalid'),
        ]),
      ),
      meta: z
        .object({
          images: z.array(z.any()).min(1),
        })
        .optional(),
    })
    .refine(value => {
      if (isArray(value.config.imageSrc)) {
        return !value?.meta;
      } else {
        return value?.meta?.images?.length && value?.meta?.images?.every(img => img?.name !== '');
      }
    }),
};
