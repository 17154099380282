import { isFinite, uniqueId } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect, useTitle } from 'react-use';

import { Button } from '@optra/kit';

import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillComposeFields from 'components/skill-compose-fields';
import SkillComposePrivilegesFields from 'components/skill-compose-privileges-fields';
import SkillEnvFields from 'components/skill-env-fields';
import SkillFormTabs, { isActiveTab } from 'components/skill-form-tabs';
import SkillMetaFields from 'components/skill-meta-fields';
import { api, q } from 'config/api';
import cleanInputArray from 'lib/clean-input-array';
import { useLatestSkillVersion, useSignedUpload } from 'queries';

export default function CreateSkillDockerComposeVersion() {
  useTitle('Create Docker Compose Version | Optra');
  const { skillId } = useParams();
  const navigate = useNavigate();
  const form = useForm();
  const {
    control,
    formState: { errors },
    handleSubmit: onSubmit,
    register,
    reset,
    watch,
  } = form;
  const [upload, uploadState] = useSignedUpload({ type: 'dockerCompose' });
  const [error, setError] = useState();
  const [tab, setTab] = useState('compose');

  const { data, isLoading: fetching } = useLatestSkillVersion(skillId);
  let latestVersion = data?.skill?.versions?.data?.[0] || {};

  const qc = q.useQueryClient();
  const createSkillVersion = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createDockerComposeSkillVersion($form: createDockerComposeSkillVersionForm!) {
          version: createDockerComposeSkillVersion(form: $form) {
            id
            skill {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      qc.invalidateQueries({ queryKey: ['skillVersions'] });
      qc.invalidateQueries({ queryKey: ['librarySkillVersions'] });
      navigate(`/skills/${skillId}/edit`);
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    const tarFile = form.tarFile[0];
    const { key } = await upload(tarFile, { extension: 'tgz' });
    const env = {};
    cleanInputArray(form.env).forEach(v => {
      env[v.key] = v.value;
    });
    const port = parseInt(form.port);
    createSkillVersion.mutate({
      version: form.version,
      key,
      skillId,
      env,
      port: isFinite(port) ? port : null,
    });
  });

  useDeepCompareEffect(() => {
    if (latestVersion) {
      console.log(latestVersion);
      reset({
        version: latestVersion?.version,
        env: Object.entries(latestVersion?.env || {}).map(([key, value]) => ({
          _id: uniqueId(),
          key,
          value,
        })),
      });
    }
  }, [latestVersion]);

  const loading = uploadState.fetching || fetching || createSkillVersion.isPending;

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Create Docker Compose Version" icon="SquaresFour" />
      <ModalBody className="space-y-3">
        {error && (
          <Message variant="danger" title="Couldn't Create Skill">
            {error.message}
          </Message>
        )}
        <SkillMetaFields
          hideName
          hideIcon
          loading={loading}
          register={register}
          control={control}
          errors={errors}
        />
        <div>
          <SkillFormTabs tab={tab} onChange={setTab} tabs={['compose', 'env', 'privileges']} />
        </div>
        <SkillComposeFields
          loading={loading}
          register={register}
          errors={errors}
          watch={watch}
          required={true}
          visible={isActiveTab(tab, 'compose')}
        />
        <SkillEnvFields loading={loading} control={control} visible={isActiveTab(tab, 'env')} />
        <SkillComposePrivilegesFields
          loading={loading}
          visible={isActiveTab(tab, 'privileges')}
          form={form}
        />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
