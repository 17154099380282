import { groupBy, isFinite } from 'lodash';

import { Card, Heading, Text, Toggle } from '@optra/kit';

import DataTypeHeading from 'modals/skill-pipeline-builder/components/data-type-heading';
import getNodeDef from 'modals/skill-pipeline-builder/context/get-node-def';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function AdminViewOutput(props) {
  const { nodeId } = props;
  const [node, { pipeline, update, nodeDef, diagnosticsNodeHasOutputKey }] =
    usePipelineNode(nodeId);

  const nodeTypes = Object.entries(pipeline.pipeline).map(([id, node]) => ({
    ...getNodeDef(node),
    nodeId: id,
  }));

  const outputs = nodeTypes.flatMap(nodeType =>
    nodeType?.outputs?.map(o => ({
      ...o,
      nodeId: nodeType.nodeId,
      nodeTypeLabel: nodeType.label,
      inputKey: `${nodeType.nodeId}|${nodeType.label} ${o.label}`,
    })),
  );

  const groupedOutputs = groupBy(outputs, 'nodeTypeLabel');

  const compressionLevel = isFinite(node?.config?.compressionLevel)
    ? node?.config?.compressionLevel
    : nodeDef?.defaultConfig?.compressionLevel;
  const updateFrequency = isFinite(node?.config?.updateFrequency)
    ? node?.config?.updateFrequency
    : nodeDef?.defaultConfig?.updateFrequency;

  function handleToggle(nodeId, outputKey, key) {
    const value = `${nodeId}.${key}`;
    update({
      key: `inputs.${outputKey}`,
      value: diagnosticsNodeHasOutputKey(outputKey) ? null : value,
    });
  }

  return (
    <div className="space-y-4">
      <Heading level={3}>Compression Level</Heading>
      <Card variant="secondary" className="space-y-6">
        <Text size="xl" variant="bold" className="block">
          {parseInt(compressionLevel, 10)}%
        </Text>
        <input
          type="range"
          step={1}
          min={0}
          max={100}
          value={compressionLevel}
          onChange={event => {
            update({
              key: 'config.compressionLevel',
              value: parseInt(event?.target?.value, 10),
            });
          }}
          className="w-full"
        />
      </Card>

      <Heading level={3}>Update Frequency</Heading>
      <Card variant="secondary" className="space-y-6">
        <Text size="xl" variant="bold" className="block">
          {parseFloat(updateFrequency).toFixed(2)} (FPS)
        </Text>
        <input
          type="range"
          step={0.01}
          min={0}
          max={1}
          value={updateFrequency}
          onChange={event => {
            update({
              key: 'config.updateFrequency',
              value: parseFloat(parseFloat(event?.target?.value).toFixed(2)),
            });
          }}
          className="w-full"
        />
      </Card>

      {Object.entries(groupedOutputs).map(([label, outputs]) => (
        <div key={label} className="space-y-1">
          <Heading level={3}>{label}</Heading>
          <Card variant="secondary" className="space-y-6">
            {outputs.map(output => (
              <div key={output.inputKey} className="grid grid-cols-8 gap-2">
                <div className="col-span-4">
                  <DataTypeHeading type={output.type} label={output.label} />
                </div>
                <div className="block col-span-4 text-right">
                  <Toggle
                    label="Debug"
                    checked={node?.inputs[output.inputKey]}
                    onChange={() => {
                      handleToggle(output.nodeId, output.inputKey, output.key);
                    }}
                  />
                </div>
              </div>
            ))}
          </Card>
        </div>
      ))}
    </div>
  );
}
