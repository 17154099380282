import { isEmpty, noop, omit } from 'lodash';
import { useState, useEffect, forwardRef } from 'react';
import * as uuid from 'uuid';

import { IconButton } from '@optra/kit';

import Input from 'components/input';
import Select from 'components/select';
import countryCodes from 'config/country-codes';

export default forwardRef(({ value, onChange = noop }, ref) => {
  const [phoneNumbers, setPhoneNumbers] = useState(value);
  useEffect(() => {
    if (isEmpty(value)) {
      setPhoneNumbers({
        [uuid.v4()]: {
          countryCode: 'US',
          areaCode: countryCodes.US.dialCode,
          number: '',
        },
      });
    } else {
      setPhoneNumbers(value);
    }
  }, [value, setPhoneNumbers]);

  return (
    <div>
      {Object.entries(phoneNumbers).map(([id, { countryCode, number }]) => (
        <div key={id} className="flex items-center mb-3 space-x-2">
          <div className="w-44">
            <Select
              value={countryCode}
              onChange={e => {
                onChange({
                  ...phoneNumbers,
                  [id]: {
                    ...phoneNumbers[id],
                    countryCode: e.target.value,
                    areaCode: countryCodes[e.target.value]?.dialCode || '',
                  },
                });
              }}
            >
              {Object.entries(countryCodes).map(([, c]) => (
                <option key={`${c.code}-${c.dialCode}`} value={c.code}>
                  {`${c.name} (${c.dialCode})`}
                </option>
              ))}
            </Select>
          </div>
          <div className="flex-1">
            <Input
              type="tel"
              value={number}
              onChange={e => {
                onChange({
                  ...phoneNumbers,
                  [id]: {
                    ...phoneNumbers[id],
                    number: e.target.value.replace(/\D/g, ''),
                  },
                });
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div>
            <IconButton
              onClick={e => {
                e.preventDefault();
                onChange(omit(phoneNumbers, id));
              }}
              variant="tertiary"
              name="X"
            />

            <IconButton
              onClick={e => {
                e.preventDefault();
                onChange({
                  ...phoneNumbers,
                  [uuid.v4()]: {
                    countryCode: 'US',
                    number: '',
                    areaCode: countryCodes.US.dialCode,
                  },
                });
              }}
              variant="tertiary"
              name="Plus"
            />
          </div>
        </div>
      ))}
    </div>
  );
});
