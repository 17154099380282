import { PageWrapper, PrimaryCTAButton } from '@optra/kit';
import { Button, Toggle } from '@optra/kit';

import { useFeature } from 'components/feature';
import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import UsersList from 'components/users-list';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';
import { useCurrentOrganizationMembers } from 'queries';

function ToggleEnforceMFA() {
  const qc = q.useQueryClient();
  const current = q.useQuery({
    queryKey: ['mfaEnforced'],
    queryFn: async () => {
      const r = await api(
        `query mfaEnforced {
          organization: currentOrganization {
            enforceMFA
          }
        }`,
      );
      return r.organization?.enforceMFA;
    },
    retry: 0,
  });

  const update = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateEnforceMFA($form: updateCurrentOrganizationForm!) {
          organization: updateCurrentOrganization(form: $form) {
            enforceMFA
          }
        }`,
        { form },
      ),
    async onMutate(form) {
      await qc.cancelQueries({ queryKey: ['mfaEnforced'] });
      qc.setQueryData(['mfaEnforced'], form.enforceMFA);
      return true;
    },
    onError: () => {
      qc.setQueryData(['mfaEnforced'], current.data);
    },
    onSettled() {
      qc.invalidateQueries({ queryKey: ['mfaEnforced'] });
      qc.invalidateQueries({ queryKey: ['currentOrganization'] });
    },
  });

  function toggle(e) {
    e.stopPropagation();
    e.preventDefault();
    update.mutate({
      enforceMFA: !current.data,
    });
  }

  return (
    <Toggle
      onChange={toggle}
      checked={current.data || false}
      controlled
      stopPropagation
      disabled={current.isLoading || update.isPending}
      label="Enforce MFA"
    />
  );
}

export default function Users() {
  const {
    data: members,
    isLoading,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useCurrentOrganizationMembers();

  const accessSecretsEnabled = useFeature('accessSecrets');
  const [canCreate] = useHasRoles(['admin']);

  return (
    <PageWrapper
      icon="Users"
      heading="Users"
      components={{
        actions: (
          <>
            <ToggleEnforceMFA />
            <PinPageButton />
            {accessSecretsEnabled && (
              <Button to="/users/api-keys" icon="CodeSimple" variant="secondary">
                API Keys
              </Button>
            )}
            {canCreate && <PrimaryCTAButton to="/users/create" text="New User" />}
          </>
        ),
      }}
      loading={isLoading}
      error={error}
    >
      <UsersList members={members} canCreate={canCreate} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={!hasNextPage || isFetchingNextPage}
      />
    </PageWrapper>
  );
}
