import { useRef, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { Button, ProgressCircle } from '@optra/kit';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const useRefDimensions = ref => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [ref]);
  return dimensions;
};

export default function MarketplaceSkillManual(props) {
  const { marketplaceSkill } = props;
  const frame = useRef();
  const frameDimensions = useRefDimensions(frame);
  const [numPages, setNumPages] = useState(0);
  const [documentProgress, setDocumentProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  // NOTE: Defaults to A4
  const [pageRatio, setPageRatio] = useState(1.4142);

  return (
    <div ref={frame} style={{ height: frameDimensions.width * pageRatio }}>
      {navigator.pdfViewerEnabled && (
        <iframe
          title="pdfReader"
          frameBorder="0"
          src={marketplaceSkill?.manualUrl}
          className="w-full"
          style={{ height: frameDimensions.width * pageRatio }}
        />
      )}

      {!navigator.pdfViewerEnabled && (
        <>
          {!isLoaded && (
            <div className="h-48 flex items-center justify-center">
              <ProgressCircle size="md" progress={documentProgress / 100} complete={isLoaded} />
            </div>
          )}
          {isLoaded && (
            <div className="mb-4 text-right">
              <Button href={marketplaceSkill?.manualUrl} download size="xs" variant="secondary">
                Download ({numPages} pages)
              </Button>
            </div>
          )}
          <Document
            file={marketplaceSkill?.manualUrl}
            className={`w-full h-full ${!isLoaded ? 'hidden' : ''}`}
            onSourceSuccess={() => {
              setDocumentProgress(0);
            }}
            onLoadProgress={({ loaded, total }) =>
              setDocumentProgress(Math.min(100, Math.floor(loaded / total)))
            }
            onLoadSuccess={pdf => {
              setDocumentProgress(100);
              setNumPages(pdf.numPages);
            }}
          >
            <Page
              pageNumber={1}
              canvasBackground={isLoaded ? null : 'transparent'}
              width={frameDimensions.width}
              onLoadSuccess={pdfPage => {
                setPageRatio(pdfPage.originalHeight / pdfPage.originalWidth);
                setIsLoaded(true);
              }}
            />
          </Document>
        </>
      )}
    </div>
  );
}
