import { flatMap, uniq } from 'lodash';
import { useEffect, useState } from 'react';

import { Button, Modal, Table, Text } from '@optra/kit';

import WorkflowsChooser from 'components/workflows-chooser';
import { useWorkflows } from 'queries';

function WorkflowsSelector({
  handleSelectWorkflow,
  isOpen,
  reset,
  selectButtonText = 'Select',
  setIsOpen,
}) {
  const [filter, setFilter] = useState({});
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const [deselectedWorkflows, setDeselectedWorkflows] = useState([]);

  const updateSelectedWorkflows = (selected, workflow) => {
    if (selected) {
      setSelectedWorkflows(uniq(selectedWorkflows.filter(s => s !== workflow.id)));
      setDeselectedWorkflows(uniq([...deselectedWorkflows, workflow.id]));
    } else {
      setSelectedWorkflows(uniq([...selectedWorkflows, workflow.id]));
      setDeselectedWorkflows(uniq(deselectedWorkflows.filter(d => d !== workflow.id)));
    }
  };

  const workflows = useWorkflows(
    {
      list: {
        filter,
        sort: {
          direction: 'desc',
        },
      },
    },
    undefined,
  );

  const list = flatMap(workflows.data?.pages, page => page?.list?.data);

  useEffect(() => {
    if (reset) {
      setSelectedWorkflows([]);
      setDeselectedWorkflows([]);
      setFilter({});
    }
  }, [reset]);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Modal.Header heading="Choose Workflows" />
      <Modal.Body>
        <WorkflowsChooser
          isLoading={workflows?.isLoading}
          workflows={list}
          error={workflows.error}
          hasNextPage={workflows?.hasNextPage}
          fetchNextPage={workflows?.fetchNextPage}
          isFetchingNextPage={workflows?.isFetchingNextPage}
          onSelectWorkflow={updateSelectedWorkflows}
          filter={filter}
          onFilter={setFilter}
          newSelectedWorkflows={selectedWorkflows}
          newDeselectedWorkflows={deselectedWorkflows}
          RowDataComponent={({ workflow }) => (
            <>
              <Table.TD>
                <Text size="sm">{workflow.name}</Text>
              </Table.TD>
            </>
          )}
        />
      </Modal.Body>
      <Modal.Footer className="justify-center">
        <Button
          size="xl"
          loading={workflows?.isLoading}
          onClick={() => {
            handleSelectWorkflow({ push: selectedWorkflows, pull: deselectedWorkflows });
            setIsOpen(false);
          }}
        >
          {selectButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkflowsSelector;
