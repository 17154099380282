import { ChartLine, Cursor } from '@phosphor-icons/react';
import cx from 'classnames';
import { compact, find, flatMap, uniqBy } from 'lodash';
import { Route, Routes, useParams } from 'react-router-dom';

import { PageWrapper, Icon, Dropdown, DROPDOWN_MENU_PLACEMENT, UiState } from '@optra/kit';

import ChartWorkspaceStarButton from 'components/charts/chart-workspace-star-button';
import ChartsWorkspaceCardList, {
  CARD_GRID,
  CARD_GAP,
} from 'components/charts/charts-workspace-card-list';
import CreateChartButton from 'components/charts/create-chart-button';
import DuplicateWorkspaceButton from 'components/charts/duplicate-workspace-button';
import EditWorkspaceButton from 'components/charts/edit-workspace-button';
import RemoveWorkspaceButton from 'components/charts/remove-workspace-button';
import WorkspaceAccessControls from 'components/charts/workspace-access-controls';
import Modal from 'components/modal';
import PinPageButton from 'components/pin-page-button';
import { useHasRoles } from 'hooks';
import ChartDetails from 'modals/chart-details/chart-details';
import ChartLayout from 'modals/chart-details/chart-layout';
import { useWorkspace } from 'queries';

export default function ChartsWorkspace() {
  const { workspaceId } = useParams();

  const [readOnly, loadingRoles] = useHasRoles(['chartViewerOnly'], true);

  const Workspace = useWorkspace(workspaceId);
  const { data, isError } = Workspace;
  const isLoading = Workspace.isLoading || loadingRoles;

  const workspaceCharts = compact(flatMap(data, data => data?.workspaceCharts?.data));
  const workspaceCardOrder = compact([...flatMap(data, data => data?.layout?.order)]);

  const orderedWorkspaceCharts = uniqBy(
    [
      ...compact(
        workspaceCardOrder?.length
          ? workspaceCardOrder.map(id => find(workspaceCharts, ['id', id]))
          : workspaceCharts,
      ),
      ...workspaceCharts,
    ],
    'id',
  );

  const { workspace } = data || {};

  if (!isLoading && !workspace) {
    return <UiState icon={{ component: Cursor }} variant="empty" text="Workspace Not Found" />;
  }

  if (Workspace.isFetching && !Workspace.isFetched) {
    return <UiState center />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PageWrapper
            title={workspace?.name}
            heading={
              <>
                <span>{workspace?.name}</span>
                <ChartWorkspaceStarButton id={workspaceId} />
              </>
            }
            inline
            loading={isLoading}
            error={isError}
          >
            <PageWrapper.Actions>
              <PinPageButton />
              {!readOnly && (
                <>
                  <WorkspaceAccessControls workspaceId={workspace?.id} />
                  <Dropdown placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT} unmount={false}>
                    <Dropdown.Button>
                      <Icon name="Gear" size="sm" />
                    </Dropdown.Button>
                    <Dropdown.MenuBody>
                      <EditWorkspaceButton workspace={workspace} />
                      <DuplicateWorkspaceButton workspace={workspace} />
                      <RemoveWorkspaceButton workspaceId={workspaceId} />
                    </Dropdown.MenuBody>
                  </Dropdown>
                  {!!orderedWorkspaceCharts?.length && (
                    <CreateChartButton workspace={workspace} variant="primary" />
                  )}
                </>
              )}
            </PageWrapper.Actions>
            {!!orderedWorkspaceCharts?.length ? (
              <ChartsWorkspaceCardList
                workspaceCharts={orderedWorkspaceCharts}
                workspaceId={workspaceId}
              />
            ) : (
              <div className={cx(CARD_GRID, CARD_GAP)}>
                {readOnly ? (
                  <UiState
                    variant="empty"
                    icon={{
                      component: ChartLine,
                    }}
                    text="No Charts"
                    className="animate-fade-in"
                    center
                  />
                ) : (
                  <CreateChartButton variant="placeholder" workspace={workspace} />
                )}
              </div>
            )}
          </PageWrapper>
        }
      />

      <Route
        path="chart/:chartId/edit"
        element={
          <Modal cover>
            <ChartDetails>
              <ChartLayout />
            </ChartDetails>
          </Modal>
        }
      />
    </Routes>
  );
}
