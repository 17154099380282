import { api, q } from 'config/api';

export default function useEntitlements(overrides) {
  return q.useQuery({
    queryKey: ['entitlements'],
    queryFn: () =>
      api(
        `query entitlements {
          edgeImpulse: currentOwnerEntitlement(entitlement: edgeImpulseEnterpriseAllowance) {
            limit: value
            current: currentValue
          }
        }`,
      ),
    retry: 0,
    ...overrides,
  });
}
