import { isEmpty } from 'lodash';
import { useState } from 'react';

import { Button, IconButton } from '@optra/kit';

import Checkbox from 'components/checkbox';
import Input from 'components/input';
import Label from 'components/label';
import Select from 'components/select';
import { DATA_TYPES } from 'modals/skill-pipeline-builder/config';

function OutputItem({ idx, output, onChange, onAddOutput, onRemoveOutput }) {
  const [showLabelBehaviors, setShowLabelBehaviors] = useState(!isEmpty(output.labelBehavior));

  function handleToggleShowLabelBehaviors() {
    if (showLabelBehaviors === true) {
      setShowLabelBehaviors(false);
      onChange(idx, { labelBehavior: '' });
    } else {
      setShowLabelBehaviors(true);
    }
  }

  return (
    <div className="grid grid-cols-3 gap-2">
      <Input
        required
        type="text"
        name="label"
        value={output.label}
        placeholder="Label"
        onChange={event => {
          const label = event?.target?.value;
          const currentKey = output?.key || '';
          const currentLabel = output?.label || '';
          if (currentKey === '' || currentKey === currentLabel.toLowerCase().replace(/\s/gi, '_')) {
            onChange(idx, { label, key: label.toLowerCase().replace(/\s/gi, '_') });
          } else {
            onChange(idx, { label });
          }
        }}
      />
      <Input
        required
        type="text"
        name="key"
        value={output.key}
        placeholder="Key"
        onChange={event => onChange(idx, { key: event?.target?.value })}
      />
      <div className="flex flex-row items-center space-x-2">
        <Select
          required
          className="flex-1"
          name="type"
          value={output.type}
          onChange={event => onChange(idx, { type: event?.target?.value })}
        >
          <option value="">Data Type…</option>
          {Object.values(DATA_TYPES).map(d => (
            <option key={d.key} value={d.key}>
              {d.label}
            </option>
          ))}
        </Select>
        <IconButton variant="tertiary" name="Plus" onClick={onAddOutput} />
        <IconButton variant="tertiary" name="X" onClick={() => onRemoveOutput(idx)} />
      </div>
      <Label>
        <Checkbox
          className="mr-2"
          checked={showLabelBehaviors}
          onChange={handleToggleShowLabelBehaviors}
        />
        Produces Labels?
      </Label>
      {showLabelBehaviors && (
        <>
          <Select
            name="labelBehavior"
            value={output.labelBehavior}
            onChange={event => onChange(idx, { labelBehavior: event?.target?.value })}
          >
            <option value="">Label Behavior…</option>
            {/* <option value="generate">Generate New</option> */}
            <option value="passthrough">Passthrough Existing</option>
            <option value="append">Append to Existing</option>
            <option value="replace">Replace Existing</option>
          </Select>
          {output.labelBehavior === 'append' && (
            <Input
              required
              type="text"
              placeholder="Label 1, Label 2, Label 2"
              value={output.appendedLabels}
              onChange={event => onChange(idx, { appendedLabels: event?.target?.value })}
            />
          )}
          {output.labelBehavior === 'replace' && (
            <Input
              required
              type="text"
              placeholder="Label 1, Label 2, Label 2"
              value={output.replacementLabels}
              onChange={event => onChange(idx, { replacementLabels: event?.target?.value })}
            />
          )}
        </>
      )}
    </div>
  );
}

export default function AdminSkillBuilderBetaNodeOutputs(props) {
  const { value = '[]', onChange } = props;
  const outputs = JSON.parse(value);

  function handleAddOutput() {
    const newOutputs = [...outputs, { label: '', key: '', type: '' }];
    onChange(JSON.stringify(newOutputs));
  }

  function handleRemoveOutput(idx) {
    const newOutputs = [...outputs.slice(0, idx), ...outputs.slice(idx + 1)];
    onChange(JSON.stringify(newOutputs));
  }

  function handleChangeOutput(idx, vals) {
    const newOutputs = [
      ...outputs.slice(0, idx),
      {
        ...outputs[idx],
        ...vals,
      },
      ...outputs.slice(idx + 1),
    ];
    onChange(JSON.stringify(newOutputs));
  }

  return (
    <>
      {outputs.length < 1 && (
        <div>
          <Button icon="Plus" variant="tertiary" onClick={handleAddOutput}>
            Add Output
          </Button>
        </div>
      )}
      {outputs.map((output, idx) => (
        <OutputItem
          key={`outputs-${idx}`}
          idx={idx}
          output={output}
          onChange={handleChangeOutput}
          onAddOutput={handleAddOutput}
          onRemoveOutput={handleRemoveOutput}
        />
      ))}
    </>
  );
}
