import { api, q } from 'config/api';

export default function useBillingAddress(overrides) {
  return q.useQuery({
    queryKey: ['billingAddress'],
    queryFn: () =>
      api(
        `query currentBillingAddress {
          currentBillingAddress {
            name
            country
            state
            city
            zip
            line1
            line2
          }
        }`,
      ),
    ...overrides,
  });
}
