import { isEmpty } from 'lodash';
import { useState } from 'react';

import { Dropdown } from '@optra/kit';

import ChartsWorkspaceEdit from 'components/charts/charts-workspace-edit';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';
import helpers from 'modals/chart-details/helpers';

export default function EditWorkspaceButton(props) {
  const workspaceId = props?.workspace?.id;

  const [isOpen, setIsOpen] = useState(false);
  const [readOnly] = useHasRoles(['chartViewerOnly'], true);

  const qc = q.useQueryClient();
  const updateWorkspace = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkspace($form: updateWorkspaceForm!) {
          workspace: updateWorkspace(form: $form) {
            id
            workspaceCharts {
              data {
                id
                layout
                chart {
                  id
                }
              }
            }
          }
        }`,
        { form },
      ),
    onMutate: async workspace => {
      await qc.cancelQueries({ queryKey: ['workspace', workspaceId] });

      qc.setQueryData(['workspace', workspaceId], old => ({
        workspace: helpers.assignWorkspace(old.workspace, workspace),
      }));

      return { workspace };
    },
    // TODO: add optimistic error handling
    onSettled(data) {
      const { workspace: updatedWorkspace } = data;
      qc.invalidateQueries({ queryKey: ['workspace', updatedWorkspace.id] });
      qc.invalidateQueries({ queryKey: ['workspaces'] });

      updatedWorkspace?.workspaceCharts?.data?.forEach?.(wc => {
        qc.invalidateQueries({ queryKey: ['chart', wc.chart.id] });
      });
    },
  });

  return (
    <>
      <Dropdown.Item icon="PencilSimple" text="Settings" onClick={() => setIsOpen(true)} />
      <ChartsWorkspaceEdit
        workspace={props?.workspace}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={form => {
          if (readOnly || isEmpty(form) || isEmpty(workspaceId)) return;
          updateWorkspace.mutate(
            {
              id: workspaceId,
              ...form,
            },
            {
              onSuccess() {
                setIsOpen(false);
              },
            },
          );
        }}
        isError={updateWorkspace.isError}
        isLoading={updateWorkspace.isPending}
      />
    </>
  );
}
