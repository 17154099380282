import {
  isEmpty,
  concat,
  filter,
  flatMap,
  includes,
  keys,
  isFinite,
  orderBy,
  isBoolean,
  isString,
} from 'lodash';
import { useLayoutEffect, useState } from 'react';

import { useChartCtx, useChartInterface, useChartType } from 'modals/chart-details/context';
import {
  CONSTANTS,
  DIMENSIONS,
  TIME_DIMENSIONS,
  TIME_GROUP_DIMENSIONS,
} from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';
import useChartSql from 'modals/chart-details/use-chart-sql';
import { useChartDetails } from 'queries';

const { SPLIT_SYMBOL_2 } = CONSTANTS;

const useChartData = () => {
  const {
    state: { isCustomQuery, filterDefinedQueryData },
    methods: { parseQueryResult },
    helpers: { getChart, getAxisVariables, getTimeKey },
  } = useChartCtx();
  const {
    state: {
      ui: { showAllDetailDimensions },
    },
  } = useChartInterface();
  const {
    state: { selectedCategoryKey, activeGroupsKeys, disabledGroupKeys },
  } = useChartType();

  const chart = getChart();

  const {
    generateSelectStatement,
    createWhereConditions,
    createOrderByValue,
    getClauseValues,
    timeExtract,
    createAlias,
  } = useChartSql({ chartConfig: chart?.config });

  const selectedCategoryFilter = helpers.convertKeyToObject(selectedCategoryKey);
  const activeGroupsFilterArray = activeGroupsKeys?.map(helpers.convertKeyToObject);

  // only filter by group keys if there are deselected legend items,
  // otherwise we are querying for the whole set
  const activeGroupsFilter = disabledGroupKeys?.length
    ? activeGroupsFilterArray?.reduce((filter, groupObj) => {
        const currentKeys = keys(groupObj);
        for (let i = 0; i < currentKeys.length; i++) {
          const k = currentKeys[i];
          if (filter[k]) {
            filter[k].push(groupObj[k]);
            break;
          }
          filter[k] = [groupObj[k]];
        }
        return filter;
      }, {})
    : {};

  const queryFilter = {
    ...selectedCategoryFilter,
    ...activeGroupsFilter,
  };

  const timeKey = getTimeKey();

  const orderByColumns = getClauseValues(getClauseValues(filterDefinedQueryData?.ORDER_BY));
  const firstOrderByValue = selectedCategoryKey ? timeKey : orderByColumns[0];

  const [orderByKey, setOrderByKey] = useState(firstOrderByValue);
  const [orderDirection, setOrderDirection] = useState('ASC');

  function toggleOrderKey(column, e) {
    e?.stopPropagation();
    if (column === orderByKey) {
      setOrderDirection(orderDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setOrderByKey(column);
      setOrderDirection('ASC');
    }
  }

  // ensure order key gets set back to
  // something that is included in the query
  useLayoutEffect(() => {
    setOrderByKey(firstOrderByValue);
    setOrderDirection('ASC');
  }, [setOrderByKey, setOrderDirection, firstOrderByValue, selectedCategoryKey]);

  // selected category, filtered, and ordered query
  const query = isCustomQuery
    ? chart?.query
    : generateSelectStatement({
        ...filterDefinedQueryData,
        SELECT: selectedCategoryKey
          ? {
              values: [
                ...filter(
                  concat(
                    keys(selectedCategoryFilter),
                    getClauseValues(filterDefinedQueryData?.ORDER_BY),
                  ),
                  helpers.isTimeGroupDimension,
                ).map(key => {
                  const { type, extract } = TIME_GROUP_DIMENSIONS[key];
                  return createAlias({
                    text: timeExtract({
                      key: timeKey,
                      extract,
                    }),
                    alias: type,
                  });
                }),
                '*',
              ],
              options: filterDefinedQueryData?.SELECT?.options,
            }
          : filterDefinedQueryData.SELECT,
        WHERE: {
          values: [
            ...getClauseValues(filterDefinedQueryData?.WHERE),
            ...createWhereConditions(queryFilter, { timeKey }),
          ],
          options: filterDefinedQueryData?.WHERE?.options,
        },
        GROUP_BY: selectedCategoryKey ? undefined : filterDefinedQueryData.GROUP_BY,
        ORDER_BY: {
          values: [orderByKey, ...orderByColumns],
          options: {
            ...(filterDefinedQueryData?.ORDER_BY?.options || {}),
            iteratee: column =>
              createOrderByValue({
                value: column,
                direction: column === orderByKey ? orderDirection : 'ASC',
              }),
          },
        },
      });

  const limit = 100;
  const chartDetails = useChartDetails(
    {
      query,
      limit,
    },
    { enabled: !!query },
  );

  const queryResult = flatMap(chartDetails?.data?.pages, page => page?.chartDetails?.queryResult);
  const { variables, data } = parseQueryResult(queryResult);
  const rows = data?.map((item, i) => ({
    ...item,
    _rowNum: i + 1,
    _timeKey: helpers.joinArrayViaSymbol(
      [TIME_DIMENSIONS[timeKey].name, TIME_DIMENSIONS[timeKey].type, item.time],
      SPLIT_SYMBOL_2,
    ),
  }));

  // Controls chart-data-table column order because why not
  // TODO: Move this into chart data table
  const byTimeKey = column => column === timeKey && helpers.isTimeDimension(column);
  const byTimeGroup = column => helpers.isTimeGroupDimension(column);
  const byTime = column => helpers.isTimeDimension(column);
  const byBlob = column => !!rows?.[0]?.[column]?.startsWith?.('http');
  const byValues = column => !helpers.isDimension(column) && isFinite(rows?.[0]?.[column]);
  const byBooleans = column => !helpers.isDimension(column) && isBoolean(rows?.[0]?.[column]);
  const byStrings = column =>
    !helpers.isDimension(column) && isString(rows?.[0]?.[column]) && !isEmpty(rows?.[0]?.[column]);

  const order = [byTimeKey, byTimeGroup, byBlob, byValues, byBooleans, byStrings, byTime];
  const columns = orderBy(
    isCustomQuery
      ? variables
      : filter(variables, column => {
          if (showAllDetailDimensions) return true;
          if (column === timeKey) return true;
          return !!(includes(getAxisVariables(), column) || !includes(keys(DIMENSIONS), column));
        }),
    order,
    Array(order.length).fill('desc'),
  );

  return {
    ...chartDetails,
    limit,
    parsedData: { columns, rows },
    query,
    orderByKey,
    orderDirection,
    setOrderByKey,
    setOrderDirection,
    toggleOrderKey,
  };
};

export default useChartData;
