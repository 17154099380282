import { api, q } from 'config/api';

export default function useInternalSkillVersion(id, overrides) {
  return q.useQuery({
    queryKey: ['internalSkillVersion', id],
    queryFn: () =>
      api(
        `query internalSkillVersion($id: ID!) {
          internalSkillVersion(id: $id) {
            id
            repository {
              uri
              username
              password
            }
            createOptions
            storage
            hostName
            endpointAliases
            portBindings
            tmpfs
            shmSize
            port
            removableMedia
            hostNetworking
            gpio
            cx2000IRRemote
            usbSerialConverter
            cx2000VideoAcceleration
            dockerInDocker
            privileged
            mountVolumes
            led
            binds
            capAdd
            capDrop
            addedDevice
            writableRootFS
            devices {
              sound
              hdmi
              cameras
            }
            env
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
