import { api, q } from 'config/api';

export function useGiftCardPurchaseOrders(variables) {
  return q.useInfiniteQuery({
    queryKey: ['giftCardPurchaseOrders', variables],
    queryFn({ pageParam }) {
      return api(
        `
					query giftCardPurchaseOrders($list: listFields) {
						list: giftCardPurchaseOrders(list: $list) {
							count
							data {
								id
								poNumber
								vendor
								giftCards {
									count
									data {
										id
										code
									}
								}
							}
						}
					}
			`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 5000,
  });
}
