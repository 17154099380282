import isEmpty from 'lodash/isEmpty';

import { Button } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Workflow from 'components/workflow';

export default function WorkflowsList({
  canCreateWorkflow,
  scrollToId,
  workflows = [],
  expanded = true,
}) {
  if (isEmpty(workflows)) {
    return (
      <EmptyContentMessage
        icon="GitBranch"
        title="No Workflows"
        renderAction={() =>
          canCreateWorkflow && (
            <Button to="/workflows/create" icon="Plus">
              Add Workflow
            </Button>
          )
        }
      />
    );
  }

  return (
    <>
      {workflows?.map(workflow => (
        <Workflow
          key={workflow.id}
          workflow={workflow}
          scrollTo={scrollToId === workflow.id}
          expanded={expanded}
        />
      ))}
    </>
  );
}
