import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IconButton } from '@optra/kit';

import { useMarketplaceSkill } from 'queries';

export default function MarketplaceDetailGallery(props) {
  const { marketplaceSkillId } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { data } = useMarketplaceSkill(marketplaceSkillId);
  const screenshotUrls =
    data?.marketplaceSkill?.screenshotUrls || props?.marketplaceSkill?.screenshotUrls || [];

  function handleClose() {
    setOpen(false);

    setTimeout(() => {
      navigate(-1);
    }, 300);
  }

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 10);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-top inset-0" open={open} onClose={handleClose}>
        <div className="fixed inset-0 flex items-center justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-black bg-opacity-95" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="absolute inset-0">
              <IconButton
                variant="tertiary"
                size="xl"
                name="X"
                onClick={handleClose}
                className="absolute top-4 right-4 z-priority"
              />
              <div
                className="absolute inset-0 py-16 pl-6 pr-10 lg:p-16 overflow-y-hidden scroll-smooth overflow-x-auto whitespace-nowrap space-x-6"
                style={{
                  scrollSnapType: 'y mandatory',
                }}
              >
                {screenshotUrls?.map((screenshotUrl, index) => (
                  <div
                    className="bg-gray-600 inline-block"
                    key={`screenshot-${marketplaceSkillId || props?.marketplaceSkill?.id}-${index}`}
                    style={{ scrollSnapAlign: 'center' }}
                  >
                    <img
                      src={screenshotUrl}
                      alt={`screenshot for skill ID ${
                        marketplaceSkillId || props?.marketplaceSkill?.id
                      }, ${index + 1}`}
                      className="max-w-screen max-h-[75vh] lg:max-h-[70vh]"
                    />
                  </div>
                ))}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
