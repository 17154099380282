import { Dialog, Transition } from '@headlessui/react';
import cx from 'classnames';
import { isFunction, isNil } from 'lodash';
import {
  Fragment,
  useState,
  useEffect,
  createContext,
  useContext,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ErrorBoundary } from '@optra/kit';

export const ModalContext = createContext();

export function useModalContext() {
  return useContext(ModalContext);
}

export default forwardRef(function Modal(props, ref) {
  const navigate = useNavigate();
  const {
    showBackButton: _showBackButton,
    closePath,
    onClose,
    full = false,
    cover = false,
    children,
    handleBack = () => navigate(-1),
  } = props;
  const location = useLocation();
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);

    setTimeout(() => {
      if (closePath) {
        navigate(closePath);
      } else if (onClose) {
        onClose();
      } else {
        navigate('..');
      }
    }, 300);
  }

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 10);
  }, []);

  const showBackButton = isNil(_showBackButton)
    ? location?.state?.fromModal || location?.state?.showBackButton
    : _showBackButton;

  useImperativeHandle(ref, () => ({
    handleClose,
    handleBack,
    showBackButton,
    open,
    setOpen,
  }));

  const isEdgeToEdge = !!(full || cover);

  return (
    <ModalContext.Provider value={{ handleClose, handleBack, showBackButton, open, setOpen }}>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" static className="fixed z-top inset-0" open={open} onClose={handleClose}>
          <div className="fixed inset-0 flex items-center justify-center">
            {!isEdgeToEdge && (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="pointer-events-none fixed inset-0 transition-opacity bg-light-bg-primary dark:bg-dark-bg-primary bg-opacity-75 backdrop-filter backdrop-blur-lg dark:bg-opacity-75 dark:backdrop-filter dark:backdrop-blur-lg" />
              </Transition.Child>
            )}

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={cx([
                  'shadow-2x',
                  'bg-light-bg-secondary dark:bg-dark-bg-secondary',
                  isEdgeToEdge
                    ? 'h-full w-full inset-0'
                    : 'sm:w-auto overflow-hidden rounded-lg w-full',
                ])}
              >
                <ErrorBoundary>
                  {isFunction(children)
                    ? children({ handleClose, handleBack, showBackButton })
                    : children}
                </ErrorBoundary>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </ModalContext.Provider>
  );
});
