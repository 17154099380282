import { FilterContainer, Group } from '@optra/kit';

import { useChartCtx } from '../../context';

import ControlFilterDevices from './control-filter-devices';
import ControlFilterLocations from './control-filter-locations';
import ControlFilterSkills from './control-filter-skills';
import ControlFilterTags from './control-filter-tags';
import ControlFilterWorkflows from './control-filter-workflows';

export { default as ControlFilterWorkflows } from './control-filter-workflows';
export { default as ControlFilterDevices } from './control-filter-devices';
export { default as ControlFilterSkills } from './control-filter-skills';
export { default as ControlFilterTags } from './control-filter-tags';
export { default as ControlFilterLocations } from './control-filter-locations';

export default function ControlFilters(props) {
  const {
    heading = 'Filters',
    description = 'Refine data by workflow, device, etc.',
    collapsible = true,
    scrollable = false,
    defaultCollapsed = true,
    ...rest
  } = props;
  const {
    helpers: { getChartConfig, getFilters },
    actions: {
      clearWorkflows,
      toggleWorkflows,
      clearDevices,
      toggleDevices,
      clearSkills,
      toggleSkills,
      clearTags,
      toggleTags,
      clearLocations,
      toggleLocations,
    },
  } = useChartCtx();

  const { workflowIds, deviceIds, skillIds, tagIds, locationIds } = getChartConfig();

  const count = getFilters().length;

  return (
    <Group
      heading={heading}
      description={description}
      count={count}
      collapsible={collapsible}
      scrollable={scrollable}
      defaultCollapsed={defaultCollapsed}
      {...rest}
    >
      <FilterContainer stacked>
        <ControlFilterWorkflows
          selectedWorkflowIds={workflowIds}
          onClear={clearWorkflows}
          onSelect={toggleWorkflows}
        />
        <ControlFilterDevices
          selectedDeviceIds={deviceIds}
          onClear={clearDevices}
          onSelect={toggleDevices}
        />
        <ControlFilterSkills
          selectedSkillIds={skillIds}
          onClear={clearSkills}
          onSelect={toggleSkills}
        />
        <ControlFilterTags selectedTagIds={tagIds} onClear={clearTags} onSelect={toggleTags} />
        <ControlFilterLocations
          selectedLocationIds={locationIds}
          onClear={clearLocations}
          onSelect={toggleLocations}
        />
      </FilterContainer>
    </Group>
  );
}
