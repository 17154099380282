import cx from 'classnames';

import { Icon } from '@optra/kit';

const VARIANTS = {
  danger: {
    container: ['bg-red'],
    title: ['uppercase', 'text-red-50'],
    body: ['text-red-100'],
    icon: ['text-red-50'],
  },
  warning: {
    container: ['bg-yellow'],
    title: ['uppercase', 'text-yellow-50'],
    body: ['text-yellow-100'],
    icon: ['text-yellow-50'],
  },
  success: {
    container: ['bg-green'],
    title: ['uppercase', 'text-green-50'],
    body: ['text-green-100'],
    icon: ['text-green-50'],
  },
  info: {
    container: ['bg-blue'],
    title: ['uppercase', 'text-blue-50'],
    body: ['text-blue-100'],
    icon: ['text-blue-50'],
  },
};

const ICONS = {
  danger: 'Warning',
  warning: 'Warning',
  success: 'CheckCircle',
  info: 'Info',
};

export default function Example({
  body,
  children,
  className,
  icon = true,
  title,
  variant = 'info',
  ...rest
}) {
  return (
    <div className={cx([VARIANTS[variant]?.container, 'rounded-md p-4 my-2', className])}>
      <div className="flex">
        {icon && (
          <div className="flex-shrink-0">
            <Icon
              name={ICONS[variant]}
              className={cx([VARIANTS[variant]?.icon, 'h-5 w-5'])}
              aria-hidden="true"
            />
          </div>
        )}
        <div className="ml-3 space-y-2">
          {title && (
            <h3 className={cx([VARIANTS[variant]?.title, 'text-sm font-medium'])}>{title}</h3>
          )}
          {children && (
            <div className={[cx([VARIANTS[variant]?.body, 'text-sm max-h-24 overflow-y-scroll'])]}>
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
