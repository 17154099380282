import { useNavigate } from 'react-router-dom';

import { Badge, Button, Tooltip, UnreadDot } from '@optra/kit';

import { api, q } from 'config/api';
import { useDeviceSsh, useCurrentUser } from 'queries';

function ButtonComponent({ icon = 'Terminal', text = 'SSH', tooltip, unread, ...rest }) {
  return (
    <Tooltip label={tooltip} disabled={!tooltip}>
      <span>
        <Button icon={icon} size="xs" variant="secondary" {...rest}>
          <span className="flex flex-nowrap items-center space-x-2">
            <span>{text}</span>
            <Badge variant="danger" size="xs">
              Beta
            </Badge>
          </span>
          {!!unread && (
            <span className="absolute top-0 right-0">
              <UnreadDot />
            </span>
          )}
        </Button>
      </span>
    </Tooltip>
  );
}

function SSHButton(props) {
  const { deviceId } = props;

  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const redirectUrl = 'ssh';

  const { data, isLoading } = useDeviceSsh(deviceId, {
    refetchInterval: 10000,
  });
  const device = data?.device;

  const qc = q.useQueryClient();
  const openTunnel = q.useMutation({
    mutationFn: () =>
      api(
        `mutation openDeviceTunnel($form: openDeviceTunnelForm!) {
          tunnel: openDeviceTunnel(form: $form) {
            id
            url
          }
        }`,
        { form: { deviceId, type: 'ssh' } },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['device', deviceId, 'ssh'] });
      navigate(redirectUrl);
    },
  });

  if (!currentUser?.isSysAdmin) return null;

  if (!device?.capabilities?.sshTunneling) {
    return <ButtonComponent disabled unread tooltip="Firmware Update Required" />;
  }

  const isWaiting = isLoading || openTunnel.isPending;
  const remainingTime = (device?.tunnel?.expiresAt || 0) - Date.now();
  const totalTime = (device?.tunnel?.expiresAt || 0) - (device?.tunnel?.createdAt || 0);
  const progressUntilClose = Math.max(remainingTime / Math.max(totalTime, 1), 0);
  const isOpen = progressUntilClose > 0;

  // Show a warning if the device isn't connected and there isn't an error
  // (because the tooltip for the error badge conflicts with this warning).
  const displayConnectionWarning = !device?.adminConnected && !openTunnel.isError;

  return (
    <ButtonComponent
      tooltip={
        displayConnectionWarning
          ? 'Device not fully connected - opening tunnel may fail'
          : undefined
      }
      disabled={isWaiting}
      loading={openTunnel.isPending}
      hideChildrenWhenLoading={false}
      showProgress={isOpen}
      progress={progressUntilClose}
      error={openTunnel.isError ? 'Unable to open tunnel' : null}
      onClick={() => {
        if (isWaiting) return;
        if (isOpen) {
          navigate(redirectUrl);
          return;
        }
        openTunnel.mutate();
      }}
    />
  );
}

export default SSHButton;
