import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Table, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';

import SkillIcon from './skill-icon';

export default function AdminInternalSkillsList({ skills = [] }) {
  const navigate = useNavigate();

  if (isEmpty(skills)) {
    return <EmptyContentMessage icon="Cube" title="No Agents" />;
  }

  return (
    <Table>
      <Table.Body>
        {skills.map(skill => (
          <Table.Row
            key={skill?.id}
            caret={true}
            hover={true}
            onClick={() => navigate(`/admin/agents/${skill?.id}/edit`)}
          >
            <Table.TD collapsed>
              <SkillIcon
                size="sm"
                color={skill?.color}
                icon={skill?.icon}
                iconUrl={skill?.iconUrl}
              />
            </Table.TD>
            <Table.TD>
              <Text size="sm">{skill?.name}</Text>
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
