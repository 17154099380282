import { Controller } from 'react-hook-form';

import { Card } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import LocationInput from 'components/location-input';
import ValidationError from 'components/validation-error';

export default function CameraFormFields({
  detectLocation = false,
  register,
  control,
  errors,
  loading,
}) {
  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="name">Camera Name</Label>
        <Input
          type="text"
          {...register('name', { required: 'Please enter a name.' })}
          readOnly={loading}
        />
        <ValidationError errors={errors} name="name" />
      </div>

      <div className="space-y-2">
        <Label htmlFor="name">IP Address / URL</Label>
        <Input type="text" {...register('ip')} readOnly={loading} />
      </div>

      <Card variant="secondary" className="space-y-4">
        <Controller
          render={({ field }) => (
            <LocationInput detectLocation={detectLocation} compact disabled={loading} {...field} />
          )}
          name="location"
          control={control}
        />
      </Card>
    </>
  );
}
