import { Table } from '@optra/kit';

import DeviceLocationsListItem from 'components/device-locations-list-item';

export default function DeviceLocationsList({ devices, locationId }) {
  return (
    <Table>
      <Table.Body>
        {devices?.map(device => (
          <DeviceLocationsListItem key={device.id} device={device} locationId={locationId} />
        ))}
      </Table.Body>
    </Table>
  );
}
