import { api, q } from 'config/api';

export default function useOrganizationMember(id, overrides) {
  return q.useQuery({
    queryKey: ['organizationMember', id],
    queryFn: () =>
      api(
        `query organizationMember($id: ID!) {
          organizationMember(id: $id) {
            id
            email
            roles
            user: userProfile {
              id
              email
              name
              image
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
