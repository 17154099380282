import { omit } from 'lodash';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Card, Button, IconButton, PageWrapper } from '@optra/kit';

import AdminSkillBuilderBetaNodeConfigFields from 'components/admin-skill-builder-beta-node-config-fields';
import AdminSkillBuilderBetaNodeInputs from 'components/admin-skill-builder-beta-node-inputs';
import AdminSkillBuilderBetaNodeOutputs from 'components/admin-skill-builder-beta-node-outputs';
import IconChooser from 'components/icon-chooser';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import Select from 'components/select';
import Textarea from 'components/textarea';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';

import { validateJSON } from './admin-skill-builder-beta-node-detail';

export default function AdminSkillBuilderBetaNodeCreate() {
  const navigate = useNavigate();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      label: '',
      description: '',
      inputs: '[{}]',
      outputs: '[{}]',
      configFields: '[{}]',
      type: '',
      icon: {
        icon: 'action',
      },
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'label');

  const qc = q.useQueryClient();
  const createSkillBuilderBetaNode = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createSkillBuilderBetaNode($form: createSkillBuilderBetaNodeForm!) {
          skillBuilderBetaNode: createSkillBuilderBetaNode(form: $form) {
            id
          }
        }`,
        {
          form: {
            ...omit(form, 'icon'),
            icon: form.icon?.icon,
          },
        },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skillBuilderBetaNodes'] });
      navigate('/admin/skill-builder-content/beta-nodes');
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);

    if (!validateJSON(form)) {
      setError({
        message: 'Invalid node configuration! Check your inputs, outputs and config fields.',
      });
      return;
    }

    createSkillBuilderBetaNode.mutate({
      ...form,
    });
  });

  return (
    <PageWrapper
      icon="Nut"
      title="New Node"
      heading="New Node"
      inline
      components={{
        headingBefore: (
          <IconButton
            name="CaretLeft"
            variant="plain"
            to="/admin/skill-builder-content/beta-nodes"
          />
        ),
      }}
    >
      {error && (
        <Message variant="danger" title="Couldn't Create Node">
          {error.message}
        </Message>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="label">Label</Label>
          <Input
            type="text"
            {...register('label', {
              required: 'Please enter a label',
            })}
            readOnly={createSkillBuilderBetaNode.isPending}
          />
          <ValidationError errors={errors} name="label" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="name">Class Name</Label>
          <Input
            type="text"
            {...register('name', {
              required: 'Please enter a name',
            })}
            readOnly={createSkillBuilderBetaNode.isPending}
          />
          <ValidationError errors={errors} name="name" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="description">Description</Label>
          <Textarea
            {...register('description', {
              required: 'Please enter a description',
            })}
            readOnly={createSkillBuilderBetaNode.isPending}
          />
          <ValidationError errors={errors} name="description" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="type">Type</Label>
          <Select
            {...register('type', {
              required: 'Please select a type',
            })}
            readOnly={createSkillBuilderBetaNode.isPending}
          >
            <option>Choose a type</option>
            <option value="inputs">Input</option>
            <option value="inference">Model</option>
            <option value="processors">Data Processor</option>
            <option value="graphic_elements">Image Processor</option>
            <option value="outputs">Output</option>
          </Select>
          <ValidationError errors={errors} name="type" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="icon">Icon</Label>
          <Controller
            name="icon"
            control={control}
            rules={{
              required: 'Please choose an icon.',
            }}
            render={({ field }) => (
              <Card>
                <IconChooser
                  {...field}
                  loading={createSkillBuilderBetaNode.isPending}
                  disabled={createSkillBuilderBetaNode.isPending}
                  showColors={false}
                  allowsUpload={false}
                />
              </Card>
            )}
          />
          <ValidationError errors={errors} name="icon" />
        </div>

        <div className="space-y-2">
          <Label htmlFor="inputs">Inputs</Label>
          <Controller
            name="inputs"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <AdminSkillBuilderBetaNodeInputs {...field} />
            )}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="configFields">Config Fields</Label>
          <Controller
            name="configFields"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <AdminSkillBuilderBetaNodeConfigFields {...field} />
            )}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="outputs">Outputs</Label>
          <Controller
            name="outputs"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <AdminSkillBuilderBetaNodeOutputs {...field} />
            )}
          />
        </div>

        <Button type="submit" size="xl" loading={createSkillBuilderBetaNode.isPending}>
          Create
        </Button>
      </form>
    </PageWrapper>
  );
}
