import mapValuesDeep from 'deepdash/mapValuesDeep';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

export function parseSearchParams(searchString) {
  if (isEmpty(searchString)) return {};
  const encodedQueryParams = qs.parse(searchString, {
    ignoreQueryPrefix: true,
    decoder(str, defaultDecoder, charset, type) {
      if (type === 'key') {
        return str;
      }
      const isNum = str.startsWith('n(') && str.endsWith(')');
      const isBool = str.startsWith('b(') && str.endsWith(')');

      if (isNum) {
        return Number(str.slice(2).slice(0, -1));
      }

      if (isBool) {
        return JSON.parse(str.slice(2).slice(0, -1));
      }

      return defaultDecoder(str, defaultDecoder, charset, type);
    },
  });
  return mapValuesDeep(
    encodedQueryParams,
    v => {
      try {
        const isURIComponent = decodeURI(v) !== decodeURIComponent(v);
        if (isURIComponent) return JSON.parse(decodeURIComponent(v));
        return v;
      } catch (err) {
        return v;
      }
    },
    { leavesOnly: true },
  );
}

export function useRouteQueryParams() {
  const { search } = useLocation();
  return parseSearchParams(search);
}
