import { api, q } from 'config/api';

export default function useProvisionedDeviceBySerialNumber(serialNumber, overrides) {
  return q.useQuery({
    queryKey: ['provisionedDevice', 'bySerialNumber', serialNumber],
    queryFn: () =>
      api(
        `query provisionedDeviceBySerialNumber($serialNumber: ID) {
          provisionedDevice (serialNumber: $serialNumber) {
            id
          }
        }`,
        { serialNumber },
      ),
    ...overrides,
  });
}
