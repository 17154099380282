import { Card, Heading } from '@optra/kit';

import Input from 'components/input';
import Select from 'components/select';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function SourceImagePipelineInput(props) {
  const { nodeId } = props || {};
  const [node, { update }] = usePipelineNode(nodeId);

  let protocol = 'camera://';
  let address = '0';

  if (node?.config?.source) {
    protocol = `${node?.config.source.split('://')[0]}://`;
    address = node?.config.source.split('://')[1];
  }

  function handleChangeProtocol(event) {
    const value = `${event.target.value}${address}`;
    update({ key: 'config.source', value });
  }

  function handleChangeAddress(event) {
    const value = `${protocol}${event.target.value}`;
    update({ key: 'config.source', value });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Camera Source</Heading>
      <Card variant="secondary" className="grid grid-cols-6 gap-2">
        <Select onChange={handleChangeProtocol} className="col-span-2" value={protocol}>
          <option value="camera://">camera://</option>
          <option value="rtsp://">rtsp://</option>
        </Select>
        <Input
          type="text"
          placeholder="URL or IP Address…"
          className="col-span-4"
          value={address}
          onChange={handleChangeAddress}
        />
      </Card>
    </div>
  );
}
