import { Transition } from '@headlessui/react';
import cx from 'classnames';
import { Fragment } from 'react';

import { Panel, UiState, ContextHeading } from '@optra/kit';

import ChartDuplicateModal from 'components/charts/chart-duplicate-modal';
import ChartSettingsModal from 'components/charts/chart-settings-modal';
import { useModalContext } from 'components/modal';
import { CONSTANTS } from 'modals/chart-details/data';

import Chart from './chart/chart';
import ChartDataDetails from './chart/chart-data-details';
import ChartHeading from './chart/chart-heading';
import { useChartCtx, useChartInterface } from './context';
import Controls from './controls/controls';
import QueryInput from './query-input';
import TopBar from './top-bar';

const { WINDOW_BG, PANEL_BG, BORDER_COLOR, PADDING, X_PADDING, Y_PADDING, PANEL_TRANSITION } =
  CONSTANTS;

function MobileControlsDrawer() {
  const {
    state: { chartInitialized },
    helpers: { getChart },
  } = useChartCtx();
  const {
    state: {
      ui: { showMobileControls },
    },
    actions: { setShowMobileControls },
  } = useChartInterface();
  const { handleClose } = useModalContext();

  const { name } = getChart() || {};

  return (
    <Transition
      show={!!showMobileControls}
      as="div"
      enter={PANEL_TRANSITION}
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave={PANEL_TRANSITION}
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
      className={cx('fixed left-0 top-0 h-full max-w-[100%] w-[400px] z-20', PADDING)}
    >
      <div
        className={cx(
          'absolute',
          'top-0 right-0',
          'bg-gradient-to-r',
          'from-black-900/20 via-gblack-900/20 to-transparent',
          'dark:from-[#000]/50 dark:via-[#000]/40 dark:to-transparent',
          'pointer-events-none',
        )}
        style={{ width: 'calc(100% + 100px)', height: '100%' }}
      />
      <div
        className={cx(
          'relative h-full w-full inset-0',
          'overflow-auto scroll-bar-hidden',
          'rounded-2xl',
          'shadow-lg shadow-gray-200/50 dark:shadow-[#000]/40',
          'border',
          BORDER_COLOR,
          PANEL_BG,
          PADDING,
        )}
      >
        <ContextHeading
          heading={chartInitialized ? 'Chart Options' : name}
          onDismiss={chartInitialized ? () => setShowMobileControls(false) : handleClose}
          dismissText={chartInitialized ? 'Close' : 'Back'}
          dismissIcon={chartInitialized ? 'XCircle' : 'ArrowCircleLeft'}
          className="mb-10"
        />
        <Controls />
      </div>
    </Transition>
  );
}

export default function ChartLayout() {
  const {
    state: { loading, chartInitialized, isCustomQuery },
    configuration: { showInterface, readOnly },
    queries,
    mutations,
    actions: { updateChart, duplicateChart },
    helpers: { getChart },
  } = useChartCtx();
  const {
    state: {
      ui: { showSqlInput, showSettingsModal, showDuplicateModal, showDataTable, fullscreenChart },
      isMobile,
    },
    actions: { setShowSettingsModal, setShowDuplicateModal },
    refs,
  } = useChartInterface();

  const chart = getChart();

  return (
    <>
      <Panel.Row fill className={cx('overflow-hidden', WINDOW_BG)}>
        <Panel.Col>
          {!(isMobile && !chartInitialized) && (
            <>
              <Panel.Row
                ref={refs.topBar}
                collapse
                className={cx(
                  'z-10',
                  !queries.chart.isLoading &&
                    !showSqlInput && [
                      'shadow-gray-200/50 dark:shadow-[#000]/40',
                      'shadow-lg bg-white dark:bg-black-900',
                    ],
                  PADDING,
                )}
              >
                <Panel.Col>
                  <TopBar />
                </Panel.Col>
              </Panel.Row>
              {showInterface && (
                <Transition
                  show={!!showSqlInput}
                  as={Fragment}
                  enter={PANEL_TRANSITION}
                  enterFrom="opacity-0 h-0"
                  enterTo="opacity-100 h-1/4 lg:h-1/4"
                  leave={PANEL_TRANSITION}
                  leaveFrom="opacity-100 h-1/4 lg:h-1/4"
                  leaveTo="opacity-0 h-0"
                >
                  <Panel.Row collapse className="dark">
                    <Panel.Col className={cx('border-y', BORDER_COLOR)}>
                      <QueryInput />
                    </Panel.Col>
                  </Panel.Row>
                </Transition>
              )}
            </>
          )}
          <Panel.Row>
            {queries.chart.isLoading ? (
              <UiState center className="animate-fade-in" />
            ) : (
              <>
                {!isMobile && !isCustomQuery && !readOnly && (
                  <Transition
                    show={!fullscreenChart}
                    as={Fragment}
                    enter={PANEL_TRANSITION}
                    enterFrom="opacity-0 w-0"
                    enterTo="opacity-100 w-[340px]"
                    leave={PANEL_TRANSITION}
                    leaveFrom="opacity-100 w-[340px]"
                    leaveTo="opacity-0 w-0"
                  >
                    <Panel.Col
                      collapse
                      scroll
                      className={cx('w-[375px] border-r', PANEL_BG, BORDER_COLOR)}
                    >
                      <div className={cx('w-[375px]', PADDING)}>
                        <Controls />
                      </div>
                    </Panel.Col>
                  </Transition>
                )}
                <Panel.Col className={X_PADDING}>
                  <Panel.Row collapse className={Y_PADDING}>
                    <Panel.Col>
                      <ChartHeading />
                    </Panel.Col>
                  </Panel.Row>
                  {loading ? (
                    <Panel.Row>
                      <Panel.Col>
                        <UiState center className="animate-fade-in" />
                      </Panel.Col>
                    </Panel.Row>
                  ) : (
                    <>
                      <Panel.Row>
                        <Panel.Col>
                          <Chart />
                        </Panel.Col>
                      </Panel.Row>
                      {showInterface && (
                        <Transition
                          show={!!showDataTable}
                          as={Fragment}
                          enter={PANEL_TRANSITION}
                          enterFrom="opacity-0 h-0"
                          enterTo="opacity-100 h-1/2 lg:h-3/5"
                          leave={PANEL_TRANSITION}
                          leaveFrom="opacity-100 h-1/2 lg:h-3/5"
                          leaveTo="opacity-0 h-0"
                        >
                          <Panel.Row collapse>
                            <Panel.Col>
                              <ChartDataDetails />
                            </Panel.Col>
                          </Panel.Row>
                        </Transition>
                      )}
                    </>
                  )}
                </Panel.Col>
              </>
            )}
          </Panel.Row>
        </Panel.Col>
      </Panel.Row>

      {!queries.chart.isLoading && !readOnly && (
        <>
          {isMobile && !isCustomQuery && <MobileControlsDrawer />}
          <ChartSettingsModal
            chart={chart}
            isOpen={showSettingsModal}
            onClose={() => setShowSettingsModal(false)}
            onSubmit={data => {
              updateChart(data);
              setShowSettingsModal(false);
            }}
            isError={mutations.updateChart.isError}
            isLoading={mutations.updateChart.isPending}
          />
          <ChartDuplicateModal
            chart={chart}
            isOpen={showDuplicateModal}
            onClose={() => setShowDuplicateModal(false)}
            onSubmit={form => {
              duplicateChart(form, { setShowDuplicateModal });
            }}
            isError={mutations.duplicateChart.isError}
            isLoading={mutations.duplicateChart.isPending}
            error={mutations.duplicateChart?.error?.message}
          />
        </>
      )}
    </>
  );
}
