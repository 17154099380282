import { Text } from '@optra/kit';

import DeviceImage from 'components/device-image';

export default function CompatibilityBadge({ compatibleDeviceModels = [] }) {
  if (compatibleDeviceModels.length < 1) {
    return null;
  }

  let deviceImage = 'compute550';
  if (compatibleDeviceModels.includes('cx1000')) {
    deviceImage = 'cx1000';
  }
  if (compatibleDeviceModels.includes('vz1000')) {
    deviceImage = 'vz1000';
  }
  if (compatibleDeviceModels.includes('vz5000')) {
    deviceImage = 'vz5000';
  }

  let label = compatibleDeviceModels.map(c => (c === 'compute550' ? 'c550' : c)).join(', ');
  if (compatibleDeviceModels.length === 4) {
    label = 'All Devices';
  }

  return (
    <div className="inline-flex flex-row items-center py-1 px-4 rounded-xl border-2 border-solid border-light-bg-primary dark:border-dark-bg-tertiary bg-light-bg-secondary dark:bg-dark-bg-primary">
      <DeviceImage model={deviceImage} hideStatus size="sm" className="mr-3" />
      <div>
        <Text color="muted" size="xs" className="block">
          Compatible With
        </Text>
        <Text variant="label" className="block">
          {label}
        </Text>
      </div>
    </div>
  );
}
