import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { UiState } from '@optra/kit';

import { usePinnedPage } from 'queries';

export default function PinnedRoute() {
  const [pinnedLandingPage, { isLoading }] = usePinnedPage();

  if (isLoading) {
    return <UiState center />;
  }

  if (!isEmpty(pinnedLandingPage)) {
    return <Navigate replace to={pinnedLandingPage} />;
  } else {
    return <Navigate replace to="/workflows" />;
  }
}
