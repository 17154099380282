import { upperFirst } from 'lodash';

import { Text } from '@optra/kit';

export function formatStatusLabel(status) {
  switch (status) {
    case 'enabled':
      return 'Online';
    case 'disabled':
      return 'Offline';
    case 'partial':
    case 'updating':
    case 'pending':
      return upperFirst(status);
    default:
      return 'Unknown';
  }
}

function StatusLabel({ className, status = 'enabled', size = 'sm', ...rest }) {
  return (
    <Text size={{ size }} color="muted" className={{ className }} {...rest}>
      {formatStatusLabel(status)}
    </Text>
  );
}

export default StatusLabel;
