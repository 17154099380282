import { useOnSuccess } from 'config/api';
import { useMaintenanceMode } from 'queries';

export default function MaintenanceModeTracker(props) {
  const { data, isSuccess } = useMaintenanceMode();

  useOnSuccess(
    () => {
      props?.onChange?.(data?.maintenanceMode);
    },
    { isSuccess },
    [(data, props)],
  );

  return null;
}
