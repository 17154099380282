import { flatMap } from 'lodash';
import { useState } from 'react';

import { PrimaryCTAButton, PageWrapper } from '@optra/kit';

import AdminMarketplaceList from 'components/admin-marketplace-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import Select from 'components/select';
import { useMarketplaceSkills } from 'queries';

export default function AdminMarketplacePage() {
  const defaultFilter = { showHidden: true };
  const [filter, setFilter] = useState({ ...defaultFilter, showAll: true });

  const { data, error, fetchNextPage, isLoading, isFetchingNextPage, hasNextPage } =
    useMarketplaceSkills({
      list: {
        filter,
      },
    });
  const skills = flatMap(data?.pages, page => page?.list?.data);

  return (
    <PageWrapper
      icon="SquaresFour"
      title="Marketplace | Admin"
      heading="Marketplace"
      inline={true}
      loading={isLoading}
      error={error}
      components={{
        actions: (
          <>
            <PinPageButton />
            <PrimaryCTAButton to="/admin/marketplace/listings/create" text="New Listing" />
          </>
        ),
        controls: (
          <div className="flex items-center justify-end">
            <div>
              <Select
                variant="button"
                onChange={e => {
                  const publicationState = e.target.value;
                  if (publicationState === 'all') {
                    setFilter({ ...defaultFilter, showAll: true });
                    return;
                  }
                  setFilter({ ...defaultFilter, publicationState });
                }}
                value={filter.showAll ? 'all' : filter.publicationState}
              >
                <option value="all">All</option>
                <option value="reviewing">Reviewing</option>
                <option value="changesRequested">Changes Requested</option>
                <option value="published">Published</option>
                <option value="failed">Failed</option>
              </Select>
            </div>
          </div>
        ),
      }}
    >
      <AdminMarketplaceList fetching={isLoading} error={error} list={skills} />

      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isLoading || isFetchingNextPage || !hasNextPage}
      />
    </PageWrapper>
  );
}
