import { api, q } from 'config/api';

export default function useRoles(overrides) {
  return q.useQuery({
    queryKey: ['roles'],
    queryFn: () =>
      api(
        `query roles {
          model: __type(name: "roles") {
            list: enumValues {
              value: name
              label: description
            }
          }
        }`,
      ),
    placeholderData: q.keepPreviousData,
    ...overrides,
  });
}
