import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from './translations';

export default function initializeI18N() {
  i18n.use(initReactI18next).init({
    // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    resources,
    lng: 'en', // NOTE: Remove once translations exist
    returnEmptyString: false, // NOTE: Makes sure that empty strings fallback to english instead of empty
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
}
