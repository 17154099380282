import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Table, Text } from '@optra/kit';

import DeviceImage from 'components/device-image';
import EmptyContentMessage from 'components/empty-content-message';

export default function AdminUsageList({ devices = [] }) {
  const navigate = useNavigate();

  if (isEmpty(devices)) {
    return <EmptyContentMessage icon="Activity" title="No Usage" />;
  }

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TH />
          <Table.TH>Serial Number</Table.TH>
          <Table.TH>Owner</Table.TH>
          <Table.TH>YTD Messages Transmitted</Table.TH>
          <Table.TH>Location</Table.TH>
          <Table.TH />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {devices.map((device, idx) => {
          return (
            <Table.Row
              key={device?.usages?.data?.[0]?.id || device?.device?.id || idx}
              hover
              caret
              onClick={
                device?.device?.id ? () => navigate(`/admin/usage/${device?.device?.id}`) : null
              }
            >
              <Table.TD collapsed>
                <DeviceImage
                  model={device.provisionedDevice?.model}
                  status={device?.device?.autoClaim ? 'pending' : device?.device?.status}
                  hideStatus={!device.device?.status}
                  size="sm"
                />
              </Table.TD>
              <Table.TD>
                <Text className="text-xs uppercase font-mono">
                  {device?.provisionedDevice?.serialNumber}
                </Text>
              </Table.TD>
              <Table.TD>
                <Text size="xs" color="muted">
                  {device?.ownerName}
                </Text>
              </Table.TD>
              <Table.TD>
                <Text size="xs" color="muted">
                  {Object.values(
                    device?.messagesTransmitted?.[new Date().getFullYear()] || {},
                  ).reduce((agg, v) => agg + v, 0)}
                </Text>
              </Table.TD>
              <Table.TD>
                <Text size="xs" color="muted" className="block">
                  {device?.device?.location?.address?.address}
                </Text>
                <Text size="xs" color="muted" className="block">
                  {device?.device?.location?.address?.locality}
                </Text>
              </Table.TD>
              <Table.TD collapsed>
                {!device?.device?.id && (
                  <Text size="xs" color="muted">
                    Removed
                  </Text>
                )}
              </Table.TD>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
