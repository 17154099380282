import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import { Button, IconButton, UiState } from '@optra/kit';

import Input from 'components/input';
import OutputSelect from 'components/output-select';
import Select from 'components/select';

const comparisonSymbolToEnumMap = {
  equals: '=',
  notEquals: '!=',
  greaterThan: '>',
  lessThan: '<',
  contains: 'contains',
};

export default function ActionTriggerRules(props) {
  const { control, register, name } = props;

  const {
    fields: conditions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${name}.conditions`,
  });

  if (isEmpty(conditions)) {
    return (
      <UiState variant="empty" text="The action will trigger on every event">
        <Button
          variant="secondary"
          size="xs"
          onClick={() => {
            append({
              output: '',
              comparison: 'equals',
              value: '',
            });
          }}
          icon="Plus"
        >
          Add Condition
        </Button>
      </UiState>
    );
  }

  return (
    <div className="my-10">
      <div className="flex items-center justify-between mb-4">
        <label className="mr-2">Only send when...</label>
        <div>
          <Select {...register(`${name}.conditionsMergeRule`)}>
            <option value="every">All are true</option>
            <option value="some">Any are true</option>
          </Select>
        </div>
      </div>

      {conditions?.map((condition, idx) => (
        <div key={condition.id} className="flex items-center space-x-4 my-2">
          <div className="w-[320px]">
            <Controller
              control={control}
              name={`${name}.conditions.${idx}.output`}
              render={({ field }) => {
                return (
                  <OutputSelect
                    onChange={selected => field.onChange(selected?.value || '')}
                    value={{ value: field?.value }}
                  />
                );
              }}
            />
          </div>

          <div className="w-[130px]">
            <Select {...register(`${name}.conditions.${idx}.comparison`)}>
              {Object.entries(comparisonSymbolToEnumMap).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <Input
              className="border p-2 rounded"
              placeholder="Value"
              {...register(`${name}.conditions.${idx}.value`)}
            />
          </div>

          <IconButton
            name="X"
            variant="plain"
            size="sm"
            onClick={() => {
              remove(idx);
            }}
          />
        </div>
      ))}

      <div className="text-center">
        <Button
          variant="secondary"
          size="xs"
          onClick={() => {
            append({
              output: '',
              comparison: 'equals',
              value: '',
            });
          }}
          icon="Plus"
        >
          Add Condition
        </Button>
      </div>
    </div>
  );
}
