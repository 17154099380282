export default function mapStyles({ darkMode = true }) {
  const backgroundPrimary = darkMode ? '#1C1B1F' : '#D8DBDF';
  const backgroundSecondary = darkMode ? '#323137' : '#FFFFFF';
  const foregroundPrimary = darkMode ? '#EBEAED' : '#25282D';
  const foregroundSecondary = darkMode ? '#323137' : '#D8DBDF';

  return [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: '0',
        },
        {
          color: foregroundPrimary,
        },
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: foregroundSecondary,
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: backgroundPrimary,
        },
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: backgroundSecondary,
        },
        {
          lightness: '0',
        },
        {
          gamma: '1',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: backgroundSecondary,
        },
        {
          lightness: '0',
        },
        {
          gamma: '1',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: backgroundPrimary,
        },
        {
          lightness: '0',
        },
        {
          weight: 1.2,
        },
      ],
    },

    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: backgroundPrimary,
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: backgroundPrimary,
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: backgroundSecondary,
        },
        {
          lightness: '0',
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: backgroundPrimary,
        },
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: backgroundPrimary,
        },
        {
          lightness: '0',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: backgroundPrimary,
        },
        {
          lightness: 19,
        },
      ],
    },
  ];
}
