import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useTitle } from 'react-use';

import { Button } from '@optra/kit';

import CreditCardInput from 'components/credit-card-input';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';

export default function AddCreditsNewCard() {
  useTitle('New Credit Card | Optra');
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState();
  const { handleClose, handleBack, showBackButton } = useModalContext();

  const createSetupIntent = q.useMutation({
    mutationFn: () =>
      api(
        `mutation {
          createSetupIntent
        }`,
      ),
  });

  const qc = q.useQueryClient();
  const createPaymentMethod = q.useMutation({
    mutationFn: form =>
      api(
        `mutation($form: createPaymentMethodForm!) {
          createPaymentMethod(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['paymentMethods'] });
      handleBack();
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = async event => {
    setError(null);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const createSetupIntentResult = await createSetupIntent.mutateAsync();
    const clientSecret = createSetupIntentResult?.createSetupIntent;

    const cardElement = elements.getElement(CardElement);
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        type: 'card',
        card: cardElement,
      },
    });

    if (error) {
      setError('');
      return;
    }

    createPaymentMethod.mutate({
      stripeSetupIntentId: setupIntent?.id,
    });
  };

  const loading = createPaymentMethod.isPending;

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="New Credit Card"
        icon="CreditCard"
        handleClose={handleClose}
        handleBack={handleBack}
        showBackButton={showBackButton}
        loading={loading}
      />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Save Credit Card">
            {error.message}
          </Message>
        )}

        <CreditCardInput />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
