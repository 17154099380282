import { flatMap } from 'lodash';

import AdminSkillBuilderPageWrapper from 'components/admin-skill-builder-page-wrapper';
import AdminSkillBuilderTemplatesList from 'components/admin-skill-builder-templates-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import { useSkillBuilderTemplates } from 'queries';

export default function AdminSkillBuilderTemplates() {
  const { data, error, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useSkillBuilderTemplates();
  const templates = flatMap(data?.pages, page => page.list?.data);
  return (
    <AdminSkillBuilderPageWrapper
      loading={isLoading}
      error={error}
      active="templates"
      createLink="/admin/skill-builder-content/templates/new"
    >
      <AdminSkillBuilderTemplatesList templates={templates} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={!hasNextPage || isFetchingNextPage}
      />
    </AdminSkillBuilderPageWrapper>
  );
}
