import { Outlet } from 'react-router-dom';

import { PageWrapper, Card } from '@optra/kit';

export default function ProfileLayout() {
  return (
    <PageWrapper>
      <Card className="max-w-screen-sm mx-auto space-y-6">
        <Outlet />
      </Card>
    </PageWrapper>
  );
}
