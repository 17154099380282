import cx from 'classnames';
import isBoolean from 'lodash/isBoolean';
import { Link, useLocation, useResolvedPath } from 'react-router-dom';

import { Icon, Text } from '@optra/kit';

const VARIANTS = {
  default: [
    // Base
    'appearance-none',
    'select-none',
    'focus:outline-none',
    'font-medium',
    'text-xs',
    'uppercase',
    'flex',
    'flex-1',
    'items-center',
    'rounded-md',
    'px-4',
    'py-2',
    'hover:opacity-80',
  ],
  'default-inactive': [
    // Light
    'bg-light-bg-primary',
    // Dark
    'dark:bg-dark-bg-primary',
  ],
  'default-active': ['bg-gradient-to-r', 'from-primary-500', 'to-primary-400', 'text-white'],
  underline: [
    // Base
    'appearance-none',
    'select-none',
    'focus:outline-none',
    'flex',
    'items-center',
    'justify-center',
    'w-1/4',
    'py-4',
    'px-1',
    'text-center',
    'border-b-4',
    'font-medium',
    'text-xs',
    'uppercase',
    'hover:border-primary',
  ],
  'underline-inactive': [
    'border-transparent',
    // Light
    'text-light-fg-secondary',
    // Dark
    'dark:text-dark-fg-secondary',
  ],
  'underline-active': ['border-primary', 'text-light-fg-primary', 'dark:text-dark-fg-primary'],
  pill: [
    'appearance-none',
    'select-none',
    'focus:outline-none',
    'font-medium',
    'text-xs',
    'uppercase',
    'flex',
    'flex-1',
    'items-center',
    'rounded-full',
    'px-4',
    'py-2',
    'hover:opacity-80',
  ],
  'pill-inactive': [
    // Light
    'bg-light-bg-primary',
    // Dark
    'dark:bg-dark-bg-primary',
  ],
  'pill-active': ['bg-gradient-to-r', 'from-secondary', 'to-primary', 'text-white'],
};

export default function Tab({
  active: _active,
  children,
  className,
  icon,
  to,
  variant = 'default',
  caseSensitive = false,
  end = false,
  ...rest
}) {
  let Wrapper = 'button';
  if (to) {
    Wrapper = Link;
  }

  const location = useLocation();
  const path = useResolvedPath(to || '');

  let locationPathname = location.pathname;
  let toPathname = path.pathname;
  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase();
    toPathname = toPathname.toLowerCase();
  }

  const active = isBoolean(_active)
    ? _active
    : locationPathname === toPathname ||
      (!end &&
        locationPathname.startsWith(toPathname) &&
        locationPathname.charAt(toPathname.length) === '/');

  return (
    <Wrapper
      to={to}
      type="button"
      className={cx([
        VARIANTS[variant],
        active ? VARIANTS[`${variant}-active`] : VARIANTS[`${variant}-inactive`],
        className,
      ])}
      aria-current={active}
      {...rest}
    >
      {icon && <Icon name={icon} weight="duotone" size="sm" className="mr-1 md:mr-3" />}
      <Text>{children}</Text>
    </Wrapper>
  );
}
