import flatMap from 'lodash/flatMap';
import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Toggle, Card, Button, Icon, Text } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useWorkflowsNames } from 'queries';

export default function CopyWorkflowItems() {
  useTitle('Choose Workflow | Optra');
  const { workflowId } = useParams();
  const { handleClose } = useModalContext();
  const [error, setError] = useState();
  const [replace, setReplace] = useState(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState();
  const selectWorkflow = useCallback(
    id => {
      if (id === selectedWorkflowId) {
        setSelectedWorkflowId(null);
        return;
      }
      setSelectedWorkflowId(id);
    },
    [setSelectedWorkflowId, selectedWorkflowId],
  );

  const {
    data,
    isLoading,
    error: fetchError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useWorkflowsNames({
    list: {
      filter: {
        id: { $ne: workflowId },
      },
    },
  });
  const workflows = flatMap(data?.pages, page => page?.list?.data);

  const qc = q.useQueryClient();
  const copyWorkflow = q.useMutation({
    mutationFn: form =>
      api(
        `mutation copyWorkflow($form: copyWorkflowForm!) {
          workflow: copyWorkflow(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r.workflow.id] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  function handleSave() {
    setError(null);
    copyWorkflow.mutate({
      sourceWorkflowId: workflowId,
      targetWorkflowId: selectedWorkflowId,
      replace,
    });
  }

  const loading = fetchError || isLoading || copyWorkflow.isPending || isFetchingNextPage;

  return (
    <ModalInner>
      <ModalTitle title="Choose Workflow" icon="ArrowBendUpRight" loading={loading} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Copy Workflow">
            {error.message}
          </Message>
        )}

        <Card variant="secondary" className="mb-4">
          <Toggle
            label="Replace Items In Target Workflow?"
            checked={replace}
            onChange={(e, checked) => setReplace(checked)}
          />
        </Card>

        <List loading={loading}>
          {workflows.length > 0 &&
            workflows?.map(workflow => (
              <ListItem
                key={workflow.id}
                onClick={() => selectWorkflow(workflow.id)}
                renderLeft={() => (
                  <Icon
                    name={selectedWorkflowId === workflow.id ? 'CheckCircle' : 'Circle'}
                    color={selectedWorkflowId === workflow.id ? 'gradient' : 'gray'}
                    weight={selectedWorkflowId === workflow.id ? 'fill' : 'line'}
                  />
                )}
              >
                <div className="flex items-center">
                  <Icon name="GitBranch" color="gradient" size="sm" className="mx-2" />
                  <Text>{workflow.name}</Text>
                </div>
              </ListItem>
            ))}
        </List>
        <IntersectionLoadMore
          onVisible={fetchNextPage}
          disabled={!(workflows.length > 0) || isFetchingNextPage || !hasNextPage}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSave} loading={loading} disabled={!selectedWorkflowId}>
          Copy to Workflow
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
