import { last, isEmpty } from 'lodash';

import { Button } from '@optra/kit';

import { api, q } from 'config/api';

export default function DownloadLogsButton({ loading, logs: lines }) {
  const createFile = q.useMutation({
    mutationFn: form =>
      api(
        `mutation downloadLogs($form: createFileForm!) {
          txt: createFile(form: $form) {
            url
          }
        }`,
        { form },
      ),
  });

  const ready = !createFile.isPending && !loading;

  return (
    <Button
      variant="secondary"
      size="xs"
      onClick={async () => {
        if (!ready || isEmpty(lines)) return;

        lines.reverse();
        const { txt } = await createFile.mutateAsync({
          lines,
        });

        const link = document.createElement('a');
        link.download = last(txt.url.split('/'));
        link.href = txt.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }}
      icon="FileArrowDown"
      iconWeight="line"
      loading={createFile.isPending}
      disabled={loading || isEmpty(lines)}
    >
      Download Logs
    </Button>
  );
}
