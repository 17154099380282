import cx from 'classnames';
import { useState } from 'react';

import { Card, DetailHeading, Icon, IconButton, Text } from '@optra/kit';

import ListItem from 'modals/skill-pipeline-builder/components/list-item';
import isNodeValid from 'modals/skill-pipeline-builder/context/is-node-valid';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

function Item({ id, ...rest }) {
  const [node, { nodeDef }] = usePipelineNode(id);
  return (
    <ListItem
      variant={isNodeValid(node) ? 'default' : 'invalid'}
      icon={nodeDef.icon}
      name={nodeDef.label}
      to={`./nodes/${id}`}
      inputs={nodeDef.inputs}
      outputs={nodeDef.outputs}
      {...rest}
    />
  );
}

export default function PipelineCard({ title, subtitle, icon, addPath, items = [], onSort }) {
  const [dragState, setDragState] = useState({
    draggedFromIndex: null,
    draggedFromCategory: null,
    draggedToIndex: null,
    isDragging: false,
    prevSortedItems: [],
    newSortedItems: [],
  });

  const onDragStart = event => {
    const draggedFromIndex = Number(event.currentTarget.dataset.position);
    const draggedFromCategory = event.currentTarget.dataset.category;

    setDragState({
      ...dragState,
      draggedFromIndex,
      draggedFromCategory,
      isDragging: true,
      prevSortedItems: items,
    });
    event.dataTransfer.setData('text/html', '');
  };

  const onDragOver = event => {
    event.preventDefault();

    // attributes of the item being dragged
    const { draggedFromIndex, draggedFromCategory, prevSortedItems } = dragState;

    // don't register drags on other categories
    const draggedToCategory = event.currentTarget.dataset.category;
    if (draggedToCategory !== draggedFromCategory) {
      return;
    }

    // index of the drop area being hovered
    const draggedToIndex = Number(event.currentTarget.dataset.position);
    // get the element that's at the position of "draggedFromIndex"
    const itemDragged = prevSortedItems[draggedFromIndex];
    // filter out the item being dragged
    const remainingItems = prevSortedItems.filter((item, index) => index !== draggedFromIndex);

    // update the list
    const newSortedItems = [
      ...remainingItems.slice(0, draggedToIndex),
      itemDragged,
      ...remainingItems.slice(draggedToIndex),
    ];

    // Check if the targets are actually different:
    if (draggedToIndex !== dragState.draggedToIndex) {
      setDragState({
        ...dragState,
        newSortedItems,
        draggedToIndex,
      });
    }
  };

  const onDragLeave = event => {
    const { draggedFromCategory } = dragState;
    const draggedToIndex = Number(event.currentTarget.dataset.position);
    const draggedToCategory = event.currentTarget.dataset.category;
    if (draggedToCategory !== draggedFromCategory || draggedToIndex !== dragState.draggedToIndex) {
      setDragState({
        ...dragState,
        draggedToIndex: null,
      });
    }
  };

  const onDrop = () => {
    const { newSortedItems } = dragState;
    onSort(newSortedItems);

    // clear out the state
    setDragState({
      ...dragState,
      draggedFromIndex: null,
      draggedFromCategory: null,
      draggedToIndex: null,
      isDragging: false,
    });
  };

  return (
    <Card
      variant="subtle"
      className={cx(
        'relative',
        'flex',
        'flex-col',
        'p-5',
        'pb-0',
        'overflow-hidden',
        'mb-4',
        'lg:mb-0',
      )}
      noPadding
    >
      <div className="flex-0 flex items-center justify-between pb-5">
        <div className="inline-flex items-center space-x-2">
          <Icon name={icon} color="primary" weight="duotone" />
          <div className="flex flex-col space-y-1">
            <DetailHeading level={3} variant="loud">
              {title}
            </DetailHeading>
            <Text size="xs" color="muted">
              {subtitle}
            </Text>
          </div>
        </div>
        {addPath && <IconButton to={addPath} icon="Plus" variant="tertiary" />}
      </div>
      <div className={cx(['flex-1', 'overflow-auto', 'scroll-bar-hidden', 'pb-3'])}>
        {items.map((item, idx) => {
          return (
            <Item
              key={item.id}
              id={item.id}
              draggable
              isDraggedOn={dragState?.draggedToIndex === idx && dragState?.draggedFromIndex !== idx}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragLeave={onDragLeave}
              data-position={idx}
              data-category={title}
            />
          );
        })}
        {addPath && items.length < 1 && (
          <ListItem icon="PlusCircle" name={`Add ${title}`} to={addPath} variant="empty" />
        )}
      </div>
    </Card>
  );
}
