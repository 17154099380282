import omit from 'lodash/omit';

import DeviceImage from 'components/device-image';
import DevicesFilterListItem from 'components/devices-filter-list-item';
import modelNames from 'config/model-names';

function DeviceModelFilterListItem({ model, onFilter, filter }) {
  return (
    <DevicesFilterListItem
      image={<DeviceImage model={model} size="xs" />}
      label={modelNames[model]}
      selected={filter.model === model}
      onClick={() => {
        if (filter.model === model) {
          onFilter(omit(filter, 'model'));
        } else {
          onFilter({ ...filter, model });
        }
      }}
    />
  );
}

export default function DevicesFilterModel({ onFilter, filter }) {
  return (
    <>
      <DeviceModelFilterListItem model="vz1000" onFilter={onFilter} filter={filter} />
      <DeviceModelFilterListItem model="vz5000" onFilter={onFilter} filter={filter} />
      <DeviceModelFilterListItem model="compute550" onFilter={onFilter} filter={filter} />
      <DeviceModelFilterListItem model="cx1000" onFilter={onFilter} filter={filter} />
      <DeviceModelFilterListItem model="cx2000" onFilter={onFilter} filter={filter} />
      <DeviceModelFilterListItem model="vz5100" onFilter={onFilter} filter={filter} />
      <DeviceModelFilterListItem model="vz6100" onFilter={onFilter} filter={filter} />
      <DeviceModelFilterListItem model="vz6000" onFilter={onFilter} filter={filter} />
    </>
  );
}
