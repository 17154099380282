import { api, q } from 'config/api';

export default function useWorkflowDeviceGroup(id, overrides) {
  return q.useQuery({
    queryKey: ['workflowDeviceGroup', id],
    queryFn: () =>
      api(
        `query workflowDeviceGroup($id: ID!) {
          workflowDeviceGroup(id: $id) {
            id
            name
            members {
              data {
                id
                device {
                  id
                  device {
                    id
                  }
                }
              }
            }
            workflow {
              id
              devices {
                data {
                  device {
                    id
                  }
                }
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    placeholderData: q.keepPreviousData,
    // refetchInterval: 5000,
    ...overrides,
  });
}
