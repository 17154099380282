import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'SourceImagePipelineInput',
  label: 'Camera Feed',
  icon: 'VideoCamera',
  description: 'Receive frames from a USB or RTSP camera.',
  type: 'inputs',
  inputs: [],
  outputs: [
    {
      key: 'image',
      label: 'Resulting Image',
      type: DATA_TYPES['Image'],
    },
  ],
  defaultConfig: {
    source: 'camera://0',
  },
  schema: z.object({
    config: z.object({ source: z.string().url() }),
  }),
};
