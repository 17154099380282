import { isEmpty } from 'lodash';
import { useFieldArray } from 'react-hook-form';

import { Button, IconButton } from '@optra/kit';

import Checkbox from 'components/checkbox';
import Input from 'components/input';

export default function DynamicOutputFields(props) {
  const { control, register, name } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const addNewField = () => {
    append({
      id: '',
      as: '',
      enabled: true,
    });
  };

  if (isEmpty(fields)) {
    return (
      <div className="text-right">
        <Button variant="secondary" size="xs" onClick={addNewField} icon="Plus">
          Add Output
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {fields.map((field, index) => (
        <div key={index} className="space-y-2">
          <div className="flex space-x-4 items-center">
            <Checkbox {...register(`${name}[${index}].enabled`)} />
            <IconButton name="X" variant="plain" size="sm" onClick={() => remove(index)} />
            <Input
              className="flex-1"
              placeholder="key"
              {...register(`${name}[${index}].id`)}
              type="text"
            />
            <Input
              className="flex-1"
              placeholder="rename key"
              {...register(`${name}[${index}].as`)}
              type="text"
            />
          </div>
        </div>
      ))}

      <div className="text-right">
        <Button variant="secondary" size="xs" onClick={addNewField} icon="Plus">
          Add Output
        </Button>
      </div>
    </div>
  );
}
