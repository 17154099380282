import { api, q } from 'config/api';

export default function useChartDetails(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['chartDetails', variables],
    queryFn: ({ pageParam: nextToken }) =>
      api(
        `query chartDetails($query: String!, $nextToken: String, $limit: Int) {
          chartDetails: chartQuery(query: $query, nextToken: $nextToken, limit: $limit) {
            queryResult
            nextToken
          }
        }`,
        { ...variables, nextToken },
      ),
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage?.chartDetails?.nextToken,
    refetchOnWindowFocus: false,
    ...overrides,
  });
}
