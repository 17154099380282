import { flatMap, isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { useDebounce } from 'react-use';

import { SideModal, SearchField, SubNavList, Text, useBreakpoint } from '@optra/kit';

import Checkbox from 'components/checkbox';
import { useFeature } from 'components/feature';
import Label from 'components/label';
import LogsScroller from 'components/logs-scroller';
import OffsetDateRangeSelector from 'components/offset-date-range-selector';
import DevicesSelector from 'modals/devices-selector';
import SkillsSelector from 'modals/skills-selector';
import TagsSelector from 'modals/tags-selector';
import WorkflowsSelector from 'modals/workflows-selector';
import { useEvents } from 'queries';

const allEventTypes = ['INFO', 'WARN', 'ERROR', 'DEBUG'];

export default function LogsSidebar({ isOpen, onClose }) {
  const { thresholds } = useBreakpoint();
  const isMobile = !thresholds.md;
  const [selectedEventTypes, setSelectedEventTypes] = useState(allEventTypes);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filteredDateRange, setFilteredDateRange] = useState({});
  const [filteredDeviceIds, setFilteredDeviceIds] = useState([]);
  const [filteredSkillIds, setFilteredSkillIds] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [filteredWorkflowIds, setFilteredWorkflowIds] = useState([]);
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [isDevicesFilterOpen, setIsDevicesFilterOpen] = useState(false);
  const [isSkillsFilterOpen, setIsSkillsFilterOpen] = useState(false);
  const [isTagsFilterOpen, setIsTagsFilterOpen] = useState(false);
  const [isWorkflowsFilterOpen, setIsWorkflowsFilterOpen] = useState(false);
  const deviceTelemetryEnabled = useFeature('deviceTelemetry');

  const updateSelectedEventTypes = type => {
    if (!type) {
      setSelectedEventTypes(allEventTypes);
    } else if (selectedEventTypes.includes(type)) {
      setSelectedEventTypes([...selectedEventTypes.filter(t => t !== type)]);
    } else {
      setSelectedEventTypes([...selectedEventTypes, type]);
    }
  };
  const updateSelectedDateRange = range => setFilteredDateRange(range);
  const updateSelectedDevices = ({ push }) => setFilteredDeviceIds(push);
  const updateSelectedSkills = ({ push }) => setFilteredSkillIds(push);
  const updateSelectedWorkflows = ({ push }) => setFilteredWorkflowIds(push);
  const updateSelectedTags = ({ push }) => setFilteredTags(push);

  const eventsQuery = useEvents({
    list: {
      sort: { direction: 'desc' },
      filter: {
        $search: debouncedSearch,
        type: selectedEventTypes,
        deviceId: filteredDeviceIds,
        skillId: filteredSkillIds,
        tag: filteredTags,
        time: filteredDateRange,
        workflowId: filteredWorkflowIds,
      },
    },
  });
  const events = flatMap(eventsQuery.data?.pages, page => page?.logs);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const isFilterActive = () =>
    !isEmpty(selectedEventTypes) ||
    !isEmpty(search) ||
    !isEmpty(filteredDateRange) ||
    !isEmpty(filteredDeviceIds) ||
    !isEmpty(filteredSkillIds) ||
    !isEmpty(filteredTags) ||
    !isEmpty(filteredWorkflowIds);

  return (
    <SideModal isOpen={isOpen} onClose={onClose} icon="TerminalWindow" title="Logs">
      <div className="w-full space-y-4">
        <div className="flex flex-col md:flex-row space-x-2">
          <div className="flex-1">
            <SearchField
              searching={eventsQuery.isRefetching}
              value={search}
              onChange={setSearch}
              placeholder="Search Logs…"
            />
          </div>
          <div className="flex flex-row">
            <SubNavList>
              <Label className="flex flex-row items-center p-2">
                <Checkbox
                  checked={selectedEventTypes.includes('INFO')}
                  onChange={() => updateSelectedEventTypes('INFO')}
                  className="mr-2"
                />
                Info
              </Label>

              <Label className="flex flex-row items-center p-2">
                <Checkbox
                  checked={selectedEventTypes.includes('WARN')}
                  onChange={() => updateSelectedEventTypes('WARN')}
                  className="mr-2"
                />
                Warn
              </Label>

              <Label className="flex flex-row items-center p-2">
                <Checkbox
                  checked={selectedEventTypes.includes('ERROR')}
                  onChange={() => updateSelectedEventTypes('ERROR')}
                  className="mr-2"
                />
                Error
              </Label>

              <Label className="flex flex-row items-center p-2">
                <Checkbox
                  checked={selectedEventTypes.includes('DEBUG')}
                  onChange={() => updateSelectedEventTypes('DEBUG')}
                  className="mr-2"
                />
                Debug
              </Label>
            </SubNavList>
          </div>
        </div>

        <LogsScroller
          logs={events}
          loading={eventsQuery.isLoading}
          hasMore={eventsQuery.hasNextPage}
          loadingMore={eventsQuery.isFetchingNextPage}
          onLoadMore={eventsQuery.fetchNextPage}
          reverse
        />

        <div className="flex flex-row items-center justify-end mb-4 space-x-2">
          <Text variant="label" color="muted" size="xs" className="block">
            Filter by…
          </Text>
          <SubNavList>
            <SubNavList.Item
              active={!isEmpty(filteredDateRange)}
              size={isMobile ? 'sm' : 'md'}
              onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}
              icon="CalendarBlank"
            >
              Date Range
            </SubNavList.Item>
            {/* NOTE: The backend is not tagging logs yet */}
            {/* <SubNavList.Item
              active={filteredTags?.length}
              size={isMobile ? 'sm' : 'md'}
              onClick={() => setIsTagsFilterOpen(!isTagsFilterOpen)}
              icon="Tag"
            >
              Tags
            </SubNavList.Item> */}
            {deviceTelemetryEnabled && (
              <SubNavList.Item
                active={filteredDeviceIds?.length}
                size={isMobile ? 'sm' : 'md'}
                onClick={() => setIsDevicesFilterOpen(!isDevicesFilterOpen)}
                icon="Aperture"
              >
                Devices
              </SubNavList.Item>
            )}
            <SubNavList.Item
              active={filteredWorkflowIds?.length}
              size={isMobile ? 'sm' : 'md'}
              onClick={() => setIsWorkflowsFilterOpen(!isWorkflowsFilterOpen)}
              icon="CaretDoubleRight"
            >
              Workflows
            </SubNavList.Item>
            {/* NOTE: The backend is not associating skills to logs yet */}
            {/* <SubNavList.Item
              active={filteredSkillIds?.length}
              size={isMobile ? 'sm' : 'md'}
              onClick={() => setIsSkillsFilterOpen(!isSkillsFilterOpen)}
              icon="Cube"
            >
              Skills">
            </SubNavList.Item> */}
          </SubNavList>
        </div>
      </div>

      <OffsetDateRangeSelector
        handleSelectRange={updateSelectedDateRange}
        open={isDateFilterOpen}
        reset={!isFilterActive()}
        onClose={setIsDateFilterOpen}
        min={2}
        max={7}
        minDate={moment().subtract(7, 'days')}
      />
      <DevicesSelector
        handleSelectDevice={updateSelectedDevices}
        isOpen={isDevicesFilterOpen}
        reset={!isFilterActive()}
        setIsOpen={setIsDevicesFilterOpen}
      />
      <SkillsSelector
        handleSelectSkill={updateSelectedSkills}
        isOpen={isSkillsFilterOpen}
        reset={!isFilterActive()}
        setIsOpen={setIsSkillsFilterOpen}
      />
      <TagsSelector
        handleSelectTag={updateSelectedTags}
        isOpen={isTagsFilterOpen}
        reset={!isFilterActive()}
        setIsOpen={setIsTagsFilterOpen}
      />
      <WorkflowsSelector
        handleSelectWorkflow={updateSelectedWorkflows}
        isOpen={isWorkflowsFilterOpen}
        reset={!isFilterActive()}
        setIsOpen={setIsWorkflowsFilterOpen}
      />
    </SideModal>
  );
}
