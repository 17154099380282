import { useState } from 'react';

import { Button } from '@optra/kit';

import AdminSkillBuilderPageWrapper from 'components/admin-skill-builder-page-wrapper';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { api, q, useOnSuccess } from 'config/api';

export default function AdminSkillBuilderConfig() {
  const [baseImage, setBaseImage] = useState('');
  const currentConfig = q.useQuery({
    queryKey: ['skillBuilderConfig'],
    queryFn: () =>
      api(
        `query skillBuilderConfig {
          config: skillBuilderConfig {
            baseImage
          }
        }`,
      ),
    select: data => data.config,
  });

  useOnSuccess(() => {
    setBaseImage(currentConfig.data.baseImage);
  }, currentConfig);

  const updateSkillBuilder = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateSkillBuilderConfig($form: updateSkillBuilderConfigForm!) {
          config: updateSkillBuilderConfig(form: $form)
        }`,
        { form },
      ),
  });

  return (
    <AdminSkillBuilderPageWrapper>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (updateSkillBuilder.isPending) return;

          const form = new FormData(e.target);
          const update = {};
          for (const [k, v] of form.entries()) {
            update[k] = v;
          }
          updateSkillBuilder.mutate(update);
        }}
      >
        <div className="space-y-4">
          {updateSkillBuilder?.error && (
            <Message variant="danger" title="Couldn't Update Skill Builder Config">
              {updateSkillBuilder.error.message}
            </Message>
          )}

          <div className="space-y-2">
            <Label htmlFor="baseImage">Base Image</Label>
            <Input
              id="baseImage"
              name="baseImage"
              type="text"
              readOnly={updateSkillBuilder.isPending}
              onChange={e => setBaseImage(e.target.value)}
              value={baseImage}
              disabled={currentConfig.isPending}
            />
            {/* <ValidationError errors={errors} name="buildTarget" /> */}
          </div>

          <Button type="submit" loading={updateSkillBuilder.isPending || currentConfig.isPending}>
            Save
          </Button>
        </div>
      </form>
    </AdminSkillBuilderPageWrapper>
  );
}
