import cx from 'classnames';
import { filter, sortBy, truncate, uniqBy } from 'lodash';

import { Group, Groups, LegendCheck, Text } from '@optra/kit';

import { useChartCtx, useChartInterface, useChartType } from 'modals/chart-details/context';
import { CONSTANTS, CALCULATIONS } from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

import ChartValue from './chart-value';

const { BORDER_COLOR, PANEL_BG, SPLIT_SYMBOL_3 } = CONSTANTS;

function LegendItem({ group, checked, interactive = true }) {
  const {
    state: { chartData },
    actions: { toggleGroup },
  } = useChartType();

  const { value, dataKey } = group;
  const valueArray = helpers.splitStringViaSymbol(value);

  const { metric = {} } = chartData?.groups[dataKey] || {};
  const { color } = metric || {};

  return (
    <li key={value}>
      <LegendCheck
        checked={checked}
        interactive={interactive}
        color={color}
        onClick={
          interactive
            ? e => {
                e.stopPropagation();
                toggleGroup(group);
              }
            : null
        }
      >
        {valueArray.map((value, i) => {
          // // when multi-metric, there is a metric data added to the
          // // groupKey but we dont want to display it, we clear that here
          if (valueArray?.length > 1 && i < 1) return null;
          return (
            <ChartValue
              key={value}
              as={Text}
              size="xs"
              className={cx(
                'block',
                'text-gray-900 dark:text-gray-300',
                'whitespace-nowrap',
                'leading-4',
              )}
              displayName={valueArray.length > 2}
            >
              {value}
            </ChartValue>
          );
        })}
      </LegendCheck>
    </li>
  );
}

function LegendList({ list, interactive }) {
  const {
    helpers: { isActiveGroup },
  } = useChartType();
  const {
    state: { isMobile },
  } = useChartInterface();
  if (!Array.isArray(list) || !list?.length) return null;
  return (
    <ul
      className={cx(
        'flex flex-row items-center lg:flex-col lg:items-start',
        isMobile ? 'space-x-3' : 'space-y-3',
      )}
    >
      {list?.map(group => {
        const checked = isActiveGroup(group.value);
        return (
          <LegendItem key={group.value} group={group} checked={checked} interactive={interactive} />
        );
      })}
    </ul>
  );
}

function Legend({ groups = [] }) {
  const {
    helpers: { getChartConfig },
  } = useChartCtx();
  const {
    state: { isMobile },
  } = useChartInterface();

  const { metrics = [], yAxis = [] } = getChartConfig();

  return (
    <div
      className={cx(
        isMobile && [
          'max-h-[30vh]',
          'p-5',
          'border rounded-lg',
          'overflow-auto scroll-bar-hidden',
          PANEL_BG,
          BORDER_COLOR,
        ],
      )}
      role="group"
    >
      {!!(yAxis?.length && metrics?.length > 1) ? (
        <Groups>
          {metrics?.map(({ metric, calculation: _calculation, type: _type }, i) => {
            const Variable = helpers.parseVariable(metric);
            const metricName = Variable?.meta?.name || '';
            const metricItems = filter(groups, ({ value }) => {
              const itemMetricName = helpers.splitStringViaSymbol(value, SPLIT_SYMBOL_3)[1];
              return Variable?.column === itemMetricName;
            });
            const calculation = CALCULATIONS[_calculation];
            return (
              <Group
                key={`${metric}_${i}`}
                heading={truncate(metricName, { length: 25 })}
                subHeading={!!calculation && calculation.nickname}
                collapsible
                defaultCollapsed={isMobile}
                scrollable
                count={metricItems?.length}
              >
                <LegendList list={metricItems} interactive={groups.length > 1} />
              </Group>
            );
          })}
        </Groups>
      ) : (
        <LegendList list={groups} interactive={groups.length > 1} />
      )}
    </div>
  );
}

export default function ChartLegend({ payload = [] }) {
  const {
    state: { isCustomQuery },
  } = useChartCtx();
  const {
    state: { isMobile },
    refs,
  } = useChartInterface();
  const {
    state: { disabledGroups = [] },
  } = useChartType();

  // Merges disabled groups with enabled groups to capture the whole set.
  // We have to use the payload here to keep the colors in sync
  // but only has the subset of enabled groups.
  // This allows us to persist the legend while narrowing the plotted chart data.
  const groups = sortBy(uniqBy([...payload, ...disabledGroups], 'value'), 'value');

  if (isCustomQuery) return null;

  return (
    <div
      ref={refs.legend}
      className={cx(
        'relative lg:pl-[40px]',
        'flex flex-col items-stretch w-full',
        'lg:overflow-auto scroll-bar-hidden',
      )}
      style={
        isMobile
          ? undefined
          : {
              height: refs.chart?.current?.offsetHeight,
            }
      }
    >
      {!!groups.length && (
        <div className="pb-3">
          <Legend groups={groups} />
        </div>
      )}
    </div>
  );
}
