import cx from 'classnames';
import { flatMap, isEmpty, isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useDebounce } from 'react-use';

import { useTags } from 'queries';

export default function TagSelector({
  value = [],
  onChange = () => false,
  defaultSelected = [],
  props,
}) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data, isLoading } = useTags({
    list: {
      filter: {
        $search: debouncedSearch,
      },
    },
  });
  const tags = flatMap(data?.pages, page => page?.list?.data).map(tag => ({
    value: tag?.id,
    label: tag?.name,
  }));

  const [options, setOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (!isEqual(tags, options)) {
      setOptions(tags);
    }

    if (!isEmpty(value) && isEmpty(selectedTags)) {
      let initial = tags.filter(tag => value?.includes(tag?.value));

      if (isEmpty(initial) && !isEmpty(defaultSelected)) {
        initial = defaultSelected.map(selected => ({
          value: selected?.tag?.id,
          label: selected?.tag?.name,
        }));
        setOptions([...tags, ...initial]);
      }

      setSelectedTags(initial);
    }
  }, [defaultSelected, options, selectedTags, tags, value]);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const handleCreateOption = inputValue => {
    const newOption = { label: inputValue, value: '' };
    setOptions(prev => [...prev, newOption]);
    setSelectedTags(prev => [...prev, newOption]);
    onChange([...selectedTags, newOption]);
  };

  const handleChange = newValue => {
    setSelectedTags(newValue);
    onChange(newValue);
  };

  const handleInputChange = inputValue => {
    setSearch(inputValue);
  };

  const clearIndicatorStyles = 'text-white rounded-md';
  const controlStyles = {
    base: [
      'py-[3px]',
      '!rounded-md',
      '!border',
      'shadow-sm',
      'hover:cursor-pointer',
      // Light
      'border-opacity-50',
      'bg-light-bg-secondary',
      'border-light-fg-secondary',
      // Dark
      'dark:border-opacity-50',
      'dark:bg-dark-bg-primary',
      'dark:border-dark-fg-secondary',
    ],
    focused: 'border-primary ring-primary ring-1',
    nonFocused: 'dark:bg-dark-bg-primary dark:border-dark-fg-secondary',
  };
  const indicatorsContainerStyles = 'p-0.5 gap-1';
  const inputStyles = 'px-1 py-0.5 dark:text-white';
  const menuStyles = 'border-none dark:bg-black rounded-lg';
  const menuListStyles = 'p-1';
  const multiValueLabelStyles = 'dark:text-white';
  const multiValueRemoveStyles =
    'hover:text-gray-300 dark:hover:text-white text-gray-500 rounded-md';
  const multiValueStyles =
    'dark:bg-dark-bg-secondary text-white rounded items-center py-0.5 pl-2 pr-1 gap-1.5';
  const optionStyles = {
    base: ['hover:cursor-pointer px-3 rounded'],
    notFocused: 'opacity-80 text-white',
    focused: ['!bg-light-bg-primary dark:!bg-dark-bg-primary', 'text-white'],
  };
  const noOptionsMessageStyles = 'dark:text-white dark:bg-dark rounded-sm';
  const placeholderStyles = 'dark:text-white';

  return (
    <CreatableSelect
      name="tagIds"
      isMulti
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      onInputChange={handleInputChange}
      options={options}
      value={selectedTags}
      placeholder="Select tags"
      styles={{
        control: (styles, state) => ({
          ...styles,
          boxShadow: '',
          borderColor: '',
          '&:hover': {
            borderColor: '',
          },
          borderWidth: '2px',
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        multiValueRemove: (baseStyles, state) => ({
          ...baseStyles,
          ':hover': { 'background-color': '', color: '' },
        }),
      }}
      classNames={{
        clearIndicator: () => clearIndicatorStyles,
        control: ({ isFocused }) =>
          cx(controlStyles.base, isFocused ? controlStyles.focused : controlStyles.nonFocused),
        indicatorsContainer: () => indicatorsContainerStyles,
        input: () => inputStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        menu: () => menuStyles,
        menuList: () => menuListStyles,
        noOptionsMessage: () => noOptionsMessageStyles,
        option: ({ isFocused }) =>
          cx(optionStyles.base, isFocused ? optionStyles.focused : optionStyles.notFocused),
        placeholder: () => placeholderStyles,
      }}
    />
  );
}
