export default {
  action: 'Lightning',
  alarm: 'Bell',
  arrowDown: 'ArrowCircleDown',
  arrowLeft: 'ArrowCircleLeft',
  arrowRight: 'ArrowCircleRight',
  arrowUp: 'ArrowCircleUp',
  back: 'SkipBack',
  bundle: 'Package',
  chat: 'Chat',
  check: 'ShieldCheck',
  chevron: 'CaretCircleRight',
  close: 'XCircle',
  code: 'Code',
  cog: 'Gear',
  copy: 'Copy',
  edit: 'Note',
  email: 'Envelope',
  folder: 'Folders',
  globe: 'Globe',
  grid: 'SquaresFour',
  help: 'Question',
  list: 'Rows',
  menu: 'List',
  more: 'DotsThreeOutline',
  network: 'ShareNetwork',
  notification: 'Warning',
  parkingSpot: 'Car',
  play: 'PlayCircle',
  plus: 'PlusCircle',
  power: 'Power',
  print: 'Printer',
  search: 'MagnifyingGlass',
  device: 'Aperture',
  send: 'PaperPlane',
  signal: 'Broadcast',
  skill: 'Cube',
  smartphone: 'DeviceMobile',
  speaker: 'SpeakerHigh',
  spreadsheet: 'FileText',
  star: 'Star',
  sync: 'ArrowsClockwise',
  trash: 'Trash',
  truck: 'Truck',
  upload: 'Upload',
  user: 'UserCircle',
  workflow: 'GitBranch',
  collector: 'HardDrives',
  model: 'Graph',
};
