import { useNavigate } from 'react-router-dom';

import { Table } from '@optra/kit';

import Message from 'components/message';
import ModelListItem from 'components/models/model-list-item';

export default function ModelsList({ error, fetching = true, models = [] }) {
  const navigate = useNavigate();

  if (fetching || error) {
    return (
      <>
        {error && (
          <Message variant="danger" title="Couldn't Load Models">
            {error.message}
          </Message>
        )}
      </>
    );
  }

  if (!fetching) {
    return (
      <Table>
        <Table.Body>
          {models.map(model => (
            <ModelListItem
              key={model?.id}
              {...model}
              onClick={() => navigate(`/skills/models/${model.id}/edit`)}
            />
          ))}
        </Table.Body>
      </Table>
    );
  }
}
