import { api, q } from 'config/api';

export default function useChooseGroupDevices(workflowId, groupId, variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflowDeviceGroup', groupId, workflowId, 'devices', variables],
    queryFn({ pageParam }) {
      return api(
        `query chooseGroupDevices($list: listFields, $groupId: ID!, $workflowId: ID!) {
          total: devices {
            count
          }
          list: devices(list: $list) {
            data {
              id
              name
              model
              status
              autoClaim
              tags {
                data {
                  tag {
                    id
                    name
                  }
                }
              }
              selected: belongsToWorkflowGroup(groupId: $groupId)
              showBadge: belongsToWorkflow(workflowId: $workflowId)
              compatible: isCompatibleWithWorkflow(workflowId: $workflowId)
            }
            cursor {
              after
            }
          }
        }`,
        {
          workflowId,
          groupId,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    enabled: !!groupId || !!workflowId,
    ...overrides,
  });
}
