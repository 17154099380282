import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { generate } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'CombineLabelsProcessor',
  label: 'Combine',
  icon: 'ArrowsMerge',
  description:
    'Combines the label outputs of multiple nodes to generate new class labels based on configured logic.',
  type: 'processors',
  inputs: [
    {
      key: 'input_label_1',
      label: 'Label',
      type: DATA_TYPES['Text'],
    },
    {
      key: 'input_label_2',
      label: 'Label',
      type: DATA_TYPES['Text'],
    },
  ],
  outputs: [
    {
      key: 'label',
      label: 'Combined Label',
      type: DATA_TYPES['Text'],
      labelBehavior: generate(node => Object.keys(node?.config?.logic || {}) || []),
    },
  ],
  defaultConfig: {
    logic: {
      '': '',
    },
  },
  schema: z.object({
    config: z.object({ logic: z.record(z.string().min(1), z.string().min(1)) }),
    inputs: z.object({ input_label_1: z.string().min(1) }),
  }),
};
