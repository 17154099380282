import { Card, Heading } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import ValidationError from 'components/validation-error';
import { rfc5322 } from 'lib/validate-email';

import FeatureToggle from './feature-toggle';

export default function UserFormFields({
  register,
  loading,
  errors,
  roles = [],
  selectedRoles = [],
  onSelectRole = () => false,
}) {
  const exclusiveRoles = ['admin', 'chartViewerOnly'];
  const selectedExclusiveRoles = exclusiveRoles.filter(r => selectedRoles.includes(r));
  return (
    <div className="space-y-4">
      <Card variant="secondary" className="space-y-2">
        <Label htmlFor="email">User Email</Label>
        <Input
          type="text"
          id="email"
          {...register('email', {
            required: 'Please enter an email address.',
            pattern: rfc5322,
          })}
          readOnly={loading}
        />
        <ValidationError errors={errors} name="email" />
      </Card>

      <div className="space-y-2">
        <Heading icon="ShieldCheck" level={3} className="pl-4">
          Choose Roles
        </Heading>
        <ul>
          {roles?.map(role => {
            const checked = (selectedRoles || []).includes(role.value);
            if (role?.value === 'owner') {
              return null;
            }
            return (
              <FeatureToggle
                as="li"
                key={role?.value}
                title={role.label.split(' | ')[0]}
                description={role.label.split(' | ')[1]}
                checked={!!checked}
                disabled={
                  selectedExclusiveRoles.length > 0 && !selectedExclusiveRoles.includes(role.value)
                }
                controlled
                onChange={() => {
                  if (checked) {
                    onSelectRole(selectedRoles.filter(sr => sr !== role.value));
                  } else {
                    if (exclusiveRoles.includes(role.value)) {
                      onSelectRole([role.value]);
                    } else {
                      onSelectRole([...selectedRoles, role.value]);
                    }
                  }
                }}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}
