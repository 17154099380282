import * as date from 'date-fns';
import _isFinite from 'lodash/isFinite';
import {
  useParams,
  Routes,
  Route,
  useNavigate,
  Outlet,
  useResolvedPath,
  useLocation,
} from 'react-router-dom';
import { useTitle } from 'react-use';

import { Button } from '@optra/kit';

import Label from 'components/label';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Select from 'components/select';
import Tab from 'components/tab';
import Tabs from 'components/tabs';
import { useHasRoles, useRouteQueryState } from 'hooks';
import SyncProgressDeviceLogs from 'modals/sync-progress-device-logs';
import SyncProgressDevices from 'modals/sync-progress-devices';
import SyncProgressLogs from 'modals/sync-progress-logs';
import { useWorkflowsLatestDeployment } from 'queries';
import useDeployWorkflow, { isInProgress, isComplete } from 'queries/use-deploy-workflow';

function SyncProgressLayout() {
  const [status, setStatus] = useRouteQueryState('status', '');

  const location = useLocation();
  const path = useResolvedPath('logs');
  const showStatusSelector = location.pathname !== path.pathname;

  return (
    <>
      <div className="md:flex items-center justify-between mb-4 space-y-2">
        <div className="md:flex md:items-center md:justify-center md:space-x-2 space-y-2 md:space-y-0">
          <Tabs>
            <Tab to="." end onClick={() => setStatus('')}>
              Devices
            </Tab>
            <Tab to="logs">Logs</Tab>
          </Tabs>
        </div>
        {showStatusSelector && (
          <Label className="flex items-center justify-center space-x-2 w-60">
            Status
            <Select
              id="statuses"
              name="statuses"
              value={status || ''}
              className="ml-2"
              onChange={event => setStatus(event?.target?.value)}
            >
              <option value="">All</option>
              <option value="queued">Waiting</option>
              <option value="inProgress">Syncing</option>
              <option value="complete">Complete</option>
              <option value="error">Failed</option>
            </Select>
          </Label>
        )}
      </div>
      <Outlet />
    </>
  );
}

export default function WorkflowSyncProgress() {
  useTitle('Sync Progress | Optra');
  const { workflowId } = useParams();
  const navigate = useNavigate();
  const [canDeploy] = useHasRoles(['admin', 'workflowDeployer']);

  const { deploy, cancel } = useDeployWorkflow();

  const { data } = useWorkflowsLatestDeployment(workflowId);
  const workflow = data?.workflow;
  const deployment = data?.workflow?.latestDeployment;

  const progress = _isFinite(deployment?.progress) ? (deployment?.progress || 0) / 100 : 0;
  const inProgress = deploy?.isPending || deploy?.isPending || isInProgress(deployment?.status);

  const complete = isComplete(deployment?.status);

  const getSubTitle = () => {
    if (!deployment) return 'Loading...';

    if (deployment?.status === 'calculating') return 'Calculating Deployment...';
    if (deployment?.status === 'queued') return 'Deployment Queued...';

    const completedAt =
      deployment?.completedAt && complete
        ? ` • ${date.format(new Date(deployment?.completedAt), 'M/d/yyyy HH:mm')}`
        : '';

    // inProgress, complete, error, paused, canceled
    return `${deployment?.progress || 0}% Complete${completedAt}`;
  };

  const handleSync = () => {
    if (inProgress) return;
    return deploy.mutate(workflowId);
  };

  return (
    <ModalInner>
      <ModalTitle
        title={workflow?.name}
        subTitle={getSubTitle()}
        showProgress
        complete={complete}
        progress={progress}
        renderActions={() => {
          if (!canDeploy) {
            return (
              <Button disabled icon="ArrowsClockwise" size="xs" variant="secondary">
                Resync
              </Button>
            );
          }

          if (complete) {
            return (
              <Button
                icon={!inProgress && 'ArrowsClockwise'}
                size="xs"
                variant="secondary"
                showProgress={inProgress}
                progress={0}
                disabled={inProgress}
                onClick={handleSync}
              >
                Resync
              </Button>
            );
          }

          if (inProgress) {
            return (
              <Button
                icon="X"
                size="xs"
                variant="secondary"
                disabled={cancel?.isPending || deployment?.status === 'canceled'}
                onClick={() => cancel.mutate(workflowId)}
              >
                Cancel
              </Button>
            );
          }
          return (
            <Button disabled icon="ArrowsClockwise" size="xs" variant="secondary">
              Resync
            </Button>
          );
        }}
      />
      <ModalBody size="lg" className="border-t border-light-bg-primary dark:border-dark-bg-primary">
        <Routes>
          <Route element={<SyncProgressLayout />}>
            <Route
              index
              element={<SyncProgressDevices deploymentId={workflow?.latestDeployment?.id} />}
            />
            <Route
              path="logs"
              element={<SyncProgressLogs deploymentId={workflow?.latestDeployment?.id} />}
            />
          </Route>
          <Route
            path="tasks/:taskId"
            element={
              <SyncProgressDeviceLogs workflowId={workflowId} handleBack={() => navigate('.')} />
            }
          />
        </Routes>
      </ModalBody>
    </ModalInner>
  );
}
