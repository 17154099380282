import { VersionLabel } from '@optra/kit';

export default function FirmwareVersionLabel({
  version,
  to,
  updateAvailable = false,
  updating = false,
}) {
  return (
    <VersionLabel
      version={version}
      icon="Cpu"
      to={to}
      loading={updating}
      ping={updateAvailable}
      onClick={event => event.stopPropagation()}
    />
  );
}
