import { api, q } from 'config/api';

export default function useWorkflow(id, overrides) {
  return q.useQuery({
    queryKey: ['workflow', id],
    queryFn: () =>
      api(
        `query workflow($id: ID!) {
          workflow(id: $id) {
            id
            name
            disabled
            deleted
            creditCost
            latestDeployment {
              status
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
