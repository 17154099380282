import flatMap from 'lodash/flatMap';
import includes from 'lodash/includes';

import { Dropdown, Filter, UiState, Badge, Button } from '@optra/kit';

import { QUERY_OVERRIDES } from 'modals/chart-details/data';
import { useWorkflowSkillSkills } from 'queries';

export default function ControlFilterSkills(props) {
  const { selectedSkillIds, skillsQuery, onClear, onSelect, ...rest } = props;
  const { data, error, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useWorkflowSkillSkills(undefined, QUERY_OVERRIDES);

  const skills = flatMap(data?.pages, page => page?.list?.data);

  return (
    <Filter
      label="Skills"
      loading={isLoading}
      onClear={onClear}
      menu={{
        text: !!(selectedSkillIds && selectedSkillIds.length) && (
          <Badge className="pl-2 pr-2">{selectedSkillIds.length}</Badge>
        ),
        body: isLoading ? (
          <UiState margin={false} className="m-10" />
        ) : error ? (
          <UiState variant="error" margin={false} className="m-10" />
        ) : !skills?.length ? (
          <UiState variant="empty" text="No Skills" margin={false} className="m-10" />
        ) : (
          <>
            {skills.map(device => {
              const { name, id } = device;
              const active = selectedSkillIds && includes(selectedSkillIds, id);
              return (
                <Dropdown.Item
                  uppercase={false}
                  key={id}
                  text={name}
                  active={active}
                  onClick={e => {
                    onSelect(id);
                    e.preventDefault();
                  }}
                  selectable
                />
              );
            })}
            {hasNextPage && (
              <div className="flex justify-center py-3">
                <Button
                  variant="plain"
                  size="xs"
                  className="p-0 text-primary"
                  onClick={fetchNextPage}
                  loading={isFetchingNextPage}
                >
                  Load More
                </Button>
              </div>
            )}
          </>
        ),
      }}
      {...rest}
    />
  );
}
