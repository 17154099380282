import cx from 'classnames';

import { Icon, Tooltip, UnreadDot } from '@optra/kit';

import WorkflowListItem from './workflow-list-item';

function DeviceGroupListItem({
  id,
  name,
  workflowId,
  count = 0,
  dirty = false,
  hasPending = false,
  hasIncompatibleDevices = false,
  disabled = false,
}) {
  const to = `/workflows/${workflowId}/groups/${id}`;

  let variant = 'default';
  if (dirty) variant = 'dirty';
  if (hasPending) variant = 'dirty';
  if (disabled) variant = 'disabled';

  let statusIcon = null;
  let tooltipLabel = null;

  if (dirty) {
    statusIcon = <Icon name="ArrowsClockwise" size="xs" />;
    tooltipLabel = 'This group has un-synced changes.';
  }
  if (hasPending) {
    statusIcon = <Icon name="Clock" size="xs" />;
    tooltipLabel = 'This group has pending devices.';
  }
  if (hasIncompatibleDevices) {
    statusIcon = <UnreadDot />;
    tooltipLabel = 'Some devices require firmware updates to be compatible with all skills.';
  }

  return (
    <WorkflowListItem
      variant={variant}
      name={name}
      details={[`${count} Items`]}
      disabled={disabled}
      to={to}
      components={{
        before: (
          <div
            className={cx([
              'w-10 h-10',
              'rounded',
              'flex items-center justify-center',
              'bg-light-bg-secondary dark:bg-dark-bg-tertiary',
            ])}
          >
            <Icon name="Folder" color="gradient" />
          </div>
        ),
        after: statusIcon && (
          <Tooltip label={tooltipLabel}>
            <span className="leading-none">{statusIcon}</span>
          </Tooltip>
        ),
      }}
    />
  );
}

export default DeviceGroupListItem;
