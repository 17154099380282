import { useNavigate } from 'react-router-dom';

import { DetailList, Table, Avatar, Badge, Text } from '@optra/kit';

import AdminUserHiddenButton from 'components/admin-user-hidden-button';
import { useCurrentUser } from 'queries';

export default function UserListItem({ member }) {
  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const { user } = member;

  return (
    <Table.Row caret hover onClick={() => navigate(`/users/${member.id}/edit`)}>
      <Table.TD collapsed>
        <Avatar src={user?.image} size="xs" />
      </Table.TD>
      <Table.TD>
        <Text>{user?.name || 'Invited User'}</Text>
        <DetailList details={[member?.email]} />
      </Table.TD>
      <Table.TD>
        <div className="flex items-start justify-start flex-wrap">
          {(member?.roles || []).map(role => (
            <Badge
              key={`${member?.id}-${role}`}
              variant={role === 'owner' ? 'primary' : 'secondary'}
              size="xs"
              className="m-1"
            >
              {role}
            </Badge>
          ))}
        </div>
      </Table.TD>
      {currentUser?.isSysAdmin && (
        <Table.TD>
          <AdminUserHiddenButton id={member.id} value={member?.hidden || false} />
        </Table.TD>
      )}
    </Table.Row>
  );
}
