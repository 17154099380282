/* eslint-disable i18next/no-literal-string */
import { PageWrapper } from '@optra/kit';

export default function Privacy() {
  return (
    <PageWrapper heading="Privacy">
      <div className="text-content">
        <p>
          Welcome to Lexmark’s privacy page and thank you for your interest. Our program,
          Privacy@Lexmark, exists to help you understand what personal data we may collect and the
          steps we take to protect your privacy.&nbsp; Lexmark strives to design products, services,
          processes, and solutions that ensure your personal data is properly handled and protected.
          To that end, we look to incorporate the following recognized privacy principles in
          everything we do:
        </p>
        <ul>
          <li>Transparent notice and informed choice</li>
          <li>Lawful use</li>
          <li>Data Access</li>
          <li>Data Integrity</li>
          <li>Data Minimization</li>
          <li>Data Security</li>
          <li>Limited Sharing</li>
          <li>Accountability for data transfers</li>
        </ul>
        <p>
          Privacy@Lexmark is a dedicated organization whose sole purpose is to implement, promote,
          and maintain a corporate culture that understands and respects your right to privacy. If
          we can answer any questions or be of service to you in any way, please contact us as
          explained below.
        </p>
        <p>
          Best regards,
          <br />
          Your P@L Team.
        </p>
        <h2>What is Personal Data?</h2>
        <p>
          Lexmark takes a broad view of Personal Data to best protect your privacy. Generally, the
          term “Personal Data” means any information relating to an identified or identifiable
          person (as opposed to a company or an organization). An identifiable person is one who can
          be identified, directly or indirectly, by reference to things such as a name, an
          identification number, an address, an employee ID, job title, or a telephone number. For
          example, Jane Alvarez who lives at 123 Lexmark Lane with a telephone number of
          859-123-4567 is an identifiable person:&nbsp; her name, address, and telephone number are
          all examples of her Personal Data.&nbsp; Other types of Personal Data can be less
          obvious—such as an IP address or other types of online identifiers.
        </p>
        <h2>
          What is covered by this notice?<b></b>
        </h2>
        <p>
          This notice covers all Personal Data collected in the use of our websites, our products
          and our services.&nbsp; If in any case our privacy practices differ from those explained
          in this notice, we will advise you at the time we ask for your information.
        </p>
        <p>
          Some pages on our websites may contain links to third-party websites. We do not endorse
          and have no control over the privacy practices or content of such websites. We recommend
          you carefully read the privacy notices of each site you visit – including ours.
        </p>
        <p>
          Some products or solutions we offer may be co-branded or offered in conjunction with
          products or solutions from other companies. These products and solutions may come with
          other registration requests or terms and conditions. If you register or use these
          products, both we and the other company may receive the information you provide. While we
          carefully select our business partners, we do not endorse and have no control over the
          privacy practices or content of any third-party companies.
        </p>
        <h2>When do we collect Personal Data?</h2>
        <p>We collect Personal Data when you contact or do business with Lexmark, such as when:</p>
        <ul>
          <li>you make purchases of products or services;</li>
          <li>you register products;</li>
          <li>you request support for a product;</li>
          <li>you request software downloads;</li>
          <li>you create a user account (login user name and password);</li>
          <li>you request information or materials (e.g., whitepapers or newsletters);</li>
          <li>you participate in surveys and evaluations;</li>
          <li>you participate in promotions, contests or giveaways;</li>
          <li>you apply for a job or submit your resume;</li>
          <li>you or someone you work for enters into a contract with us;</li>
          <li>you submit questions or comments to us.</li>
        </ul>
        <h2>What types of Personal Data do we collect?</h2>
        <p>We may collect various types of Personal Data, including:</p>
        <ul>
          <li>your first and last name;</li>
          <li>your title and your company‘s name;</li>
          <li>
            your home, billing, or other physical address (including street name, name of a city or
            town, state/province, postal code);
          </li>
          <li>your e-mail address;</li>
          <li>your telephone number;</li>
          <li>
            your educational background, employment experience, and job interest (for job applicants
            submitting information);
          </li>
          <li>
            any other identifier that permits Lexmark to make physical or online contact with you;
          </li>
          <li>
            information that we collect online from you and maintain in association with your
            account, such as your username and your password;
          </li>
          <li>
            other information that you provide to us when you use our web shop or communicate with
            us.
          </li>
        </ul>
        <p>
          We may also record the IP address you use to connect to the Internet. An IP address is a
          unique identifier used by devices to identify and communicate with each other on the
          Internet.&nbsp;
          <br />
          <br />
          You do not need to provide us Personal Data simply to browse our site, but we do collect
          certain information using cookies and other technology as explained in our{' '}
          <a adhocenable="false" href="https://www.lexmark.com/en_us/cookies.html">
            cookie notice
          </a>
          .&nbsp;
          <br />
          <br />
          If your Personal Data comes to us from a third party (for instance, from your employer),
          we require the third party to assure us they have the legal right to collect and provide
          us the data.&nbsp;
          <br />
          <br />
          Lexmark does not actively market or target any communication to children or knowingly
          collect their information. We encourage parents and guardians to take an active role in
          their children’s online activities and interests.
        </p>
        <h2>How do we use your information?</h2>
        <p>
          Like all companies, we use the Personal Data we collect in multiple ways. Examples
          include:
        </p>
        <ul>
          <li>to operate our websites;</li>
          <li>to fulfill your requests for information;</li>
          <li>to register your products;</li>
          <li>to process your job application;</li>
          <li>to allow you to participate in contests and surveys;</li>
          <li>to provide service and support,</li>
          <li>to carry out the purchases you have requested;</li>
          <li>to track your preferences related to our contact with you;</li>
          <li>to provide you support and maintenance on your Lexmark products;</li>
          <li>to fulfill our contractual responsibilities with your company.</li>
        </ul>
        <p>
          We also use your Personal Data to communicate with you. We may use your Personal Data to
          send you certain mandatory service communications such as welcome letters, billing
          reminders, information on technical service issues, security warnings, software updates,
          and other support announcements.&nbsp; If you choose to receive promotional materials from
          us we may also use your Personal Data to confirm that choice where necessary and to
          promote and market products, services, and special offers.
        </p>
        <p>
          The Personal Data in our care may be stored, transferred, combined with other data, and
          otherwise processed, all in accordance with our privacy principles.
        </p>
        <h2>Your rights</h2>
        <p>Your rights related to your Personal Data will vary by jurisdiction and may include:</p>
        <ul>
          <li>
            The right to be fully informed of what data we collect, why we collect the data, and
            whether we intend to transfer it.
          </li>
          <li>The right to access your data and correct or update any errors;</li>
          <li>The right to erasure;</li>
          <li>The right to notification;</li>
          <li>The right to restrict the use of Personal Data;</li>
          <li>The right to object to some forms of processing.</li>
          <li>The right to lodge a complaint with the competent supervisory authority.</li>
        </ul>
        <p>
          If our use of your Personal Data is based on your consent, you may withdraw it at any time
          by contacting us in writing as indicated below or, if applicable, through the{' '}
          <a adhocenable="false" href="/en_us/GlobalPreferenceCenter.html">
            Global Preference Center.
          </a>{' '}
          We will inform you of the implications of withdrawing consent.
        </p>
        <h2>How to contact us</h2>
        <p>
          Please feel free to direct any questions or concerns regarding this notice or our
          treatment of Personal Data by contacting us via the email address or the physical address
          indicated below. To ensure the most efficient handling of your issue, we highly encourage
          you to use the P@L email address. When we receive written questions and concerns, it is
          our policy to contact the individual user regarding his or her concerns as soon as
          reasonably possible. We will investigate and attempt to resolve any questions or concerns
          related to Personal Data or our privacy program in accordance with the principles
          contained in this notice.
        </p>
        <h3>E-mail</h3>
        <p>
          <u>
            <a href="mailto:privacy@lexmark.com">privacy@lexmark.com</a>
          </u>
        </p>
        <h3>Mail</h3>
        <p>
          Lexmark International, Inc.
          <br />
          Data Protection Officer
          <br />
          740 West New Circle Road
          <br />
          Lexington, Kentucky 40550
        </p>
        <p>
          When you contact us please let us know your name, address, any email address you have
          provided on our website(s) and a description of the circumstances under which you provided
          the data (for example, to register a product, to receive a newsletter, etc.). To protect
          your privacy and security, we will also take reasonable steps to verify your identity
          before taking any action.
        </p>
        <h2>Changes to this notice</h2>
        <p>
          We regularly review our compliance with this Privacy Notice. If we change this notice, we
          will post the revised notice here, along with the date of revision. If the changes are
          significant, we may also post a notice on our home page. Any changes will apply only from
          the date of the revision.
        </p>
        <h2>Our privacy principles</h2>
        <p>The following sections provide a more detailed explanation of our privacy principles.</p>
        <h3>Notice of Personal Data use</h3>
        <p>
          It is Lexmark’s intent to be fully transparent in its collection and use of Personal Data.
          We provide notice when collecting your Personal Data to explain the purpose for which we
          are collecting the data.&nbsp; This notice is intended to help you make an informed
          choice. We use our best efforts to provide information and an explanation in a clear and
          convenient manner at every point we ask for your data.
        </p>
        <p>
          We provide notice regarding the types of data we collect, how the data will be used, how
          the data is obtained (if it is not already clear), with whom the data may be shared, and
          how long it will be retained. An example may be found in our{' '}
          <a adhocenable="false" href="/en_us/GlobalPreferenceCenter.html">
            Global Preference Center
          </a>
          . If we have not been clear or you have any question or concern, please ask.
        </p>
        <p>
          We will not use the data for any purpose other than as provided in the notice. If you or
          your employer choose to have a contractual or other business relationship with Lexmark, we
          may contact you in connection with that business relationship when necessary.
        </p>
        <p>
          Most of the time, you may simply choose not to provide us your Personal Data. If you
          choose not to provide the Personal Data we request online, you can still visit most of our
          web pages, but you may be unable to access certain options, offers, and services that
          involve our interaction with you.
        </p>
        <h3>Lawful use</h3>
        <p>
          Depending on the circumstances under which we collect Personal Data, Lexmark’s use and
          processing of the data is based on one or more of the following:
        </p>
        <ul>
          <li>your consent;</li>
          <li>fulfillment of an obligation to you;</li>
          <li>our compliance with an applicable law, regulation, or other legal obligation;</li>
          <li>a need to protect your or another natural person’s vital interests; or,</li>
          <li>
            our company`s or a third party’s legitimate interests where such interest overrides your
            personal interest, fundamental rights or freedoms.
          </li>
        </ul>
        <p>
          Lexmark frequently reviews the purposes for which data is collected to ensure that our
          data collection supports reasonable business requirements.
        </p>
        <h3>Data access</h3>
        <p>
          Your personal data belongs to you. We will provide you as much access and information as
          we reasonably can to update or amend the Personal Data in our care. Where applicable, we
          also provide a method to withdraw your consent, request a copy or transfer of your data,
          and even delete your Personal Data. Please keep in mind that any such request must be
          valid and legal and we may require additional details from you to respond appropriately.
          We will respond to each request in accordance with the law.
        </p>
        <h3>Data integrity</h3>
        <p>
          Lexmark takes reasonable steps to ensure that the Personal Data we process is accurate,
          complete and current by encouraging you to update your Personal Data as necessary.
        </p>
        <p>
          We strive to keep your Personal Data accurately recorded. We have implemented technology,
          management processes, and policies to help maintain data accuracy. We provide individuals
          with reasonable access to review and correct their Personal Data.
        </p>
        <h3>Data minimization</h3>
        <p>
          Lexmark’s practice is to gather the least amount of Personal Data required for the stated
          purpose. It is also our policy to retain any Personal Data only as long as required to
          meet the business need for which it is collected. Once the business need has expired, the
          Personal Data will be routinely deleted. In some cases, Personal Data must be retained
          longer than either of us may expect due to legal obligations.
        </p>
        <h3>Data security</h3>
        <p>
          We take seriously the trust you place in us and work hard to incorporate information
          security into our systems, our products, and our services. We employ a global team of
          security professionals that sets standards and governs the enforcement of a number of
          corporate policies.
        </p>
        <p>
          To protect against unauthorized access, misuse, disclosure, or alteration of your Personal
          Data, we employ appropriate physical, technical, organizational, and administrative
          measures.
        </p>
        <p>
          Lexmark products and services are designed with privacy and security in mind using tested
          methodologies such as a Secure Development Lifecycle and Privacy Impact Assessments. Our
          devices have received worldwide recognition year after year for their robust security
          options and our organization has received security related certifications such as ISO
          27001.
        </p>
        <h3>Sharing data</h3>
        <p>
          We keep your Personal Data confidential. We will not sell, rent, trade or lease your
          Personal Data to others. Unless we have your permission, we will not use or share your
          Personal Data in ways unrelated to the ones we describe at the time you provide the data
          or as described in a controlling contract. We engage service providers and suppliers
          (“agents”) to perform certain functions on our behalf. For example, these agents may
          deliver products to you, provide product service and support, maintain our information
          technology systems, and assist us with legitimate marketing and communication initiatives.
          Those agents will be permitted to process Personal Data only to perform the services
          specified by Lexmark. They are required to maintain the confidentiality of the information
          and are prohibited from using it for any other purpose. Any agent will have entered into
          written terms with Lexmark requiring that the agent abide by terms no less protective than
          Lexmark`s own privacy standards.
        </p>
        <p>
          Lexmark or its affiliates could merge with or be acquired by another business, or their
          respective assets may be acquired. Lexmark will seek appropriate protection for personal
          information in these types of transactions
        </p>
        <h3>Cross-border transfers</h3>
        <p>
          We are a global organization with offices and customers around the world. To efficiently
          manage our business and best serve you, all kinds of data – not just Personal Data – may
          be transferred and accessed by Lexmark entities worldwide on the basis of this Privacy
          Notice and in alignment with international data privacy standards.&nbsp;&nbsp; We may
          store, transfer, and process Personal Data in the United States and other countries where
          we maintain facilities.&nbsp; By using our websites or services you consent to any such
          transfer of information outside your country
        </p>
        <h3>California Privacy Rights</h3>
        <p>
          If you are a California resident, you may have rights under the California Consumer
          Privacy Act of 2018. &nbsp;To learn more about your California privacy rights, visit
          the&nbsp;
          <a adhocenable="false" href="/en_us/ccpanotice.html">
            Lexmark Privacy Notice for California Residents
          </a>
          .
        </p>
      </div>
    </PageWrapper>
  );
}
