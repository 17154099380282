import cx from 'classnames';
import { Controller } from 'react-hook-form';

import ActionTriggerRules from 'components/action-trigger-rules';
import DataMessageInput from 'components/data-message-input';
import Input from 'components/input';
import Label from 'components/label';
import ValidationError from 'components/validation-error';

export default function EmailActionMessageFields({
  configurationField,
  index,
  register,
  errors,
  control,
  watch,
  loading,
}) {
  const enabled = watch(`configurationFields.${index}.enabled`);

  return (
    <div className={cx(['space-y-4', enabled ? 'block' : 'hidden'])}>
      <input type="hidden" {...register(`configurationFields.${index}.id`)} />

      <div className="space-y-2">
        <Label htmlFor={`configurationFields.${index}.recipients`}>Recipients</Label>
        <Input
          type="text"
          placeholder="one@optra.com, two@optra.com"
          readOnly={loading}
          defaultValue={configurationField.recipients}
          {...register(`configurationFields.${index}.recipients`, {
            validate: value => {
              if (enabled && value.trim() === '') {
                return 'Please enter recipient(s).';
              }
              return true;
            },
          })}
        />
        <ValidationError errors={errors} name={`configurationFields.${index}.recipients`} />
      </div>

      <div className="space-y-2">
        <Label htmlFor={`configurationFields.${index}.subject`}>Subject</Label>
        <Input
          type="text"
          placeholder="Let your recipients know what this notification is about"
          readOnly={loading}
          defaultValue={configurationField.subject}
          {...register(`configurationFields.${index}.subject`, {
            validate: value => {
              if (enabled && value.trim() === '') {
                return 'Please enter a subject.';
              }
              return true;
            },
          })}
        />
        <ValidationError errors={errors} name={`configurationFields.${index}.subject`} />
      </div>

      <div className="space-y-2">
        <Label htmlFor={`configurationFields.${index}.message`}>Message Template</Label>
        <Controller
          render={({ field }) => (
            <DataMessageInput
              rows={8}
              disabled={loading}
              placeholder="Type your custom message and add skill variables from the list below…"
              outputs={configurationField?.outputs || []}
              {...field}
            />
          )}
          name={`configurationFields.${index}.message`}
          defaultValue={configurationField.message}
          control={control}
          rules={{
            validate: value => {
              if (enabled && value.trim() === '') {
                return 'Please enter a message.';
              }
              return true;
            },
          }}
        />
        <ValidationError errors={errors} name={`configurationFields.${index}.message`} />
      </div>
      <ActionTriggerRules
        control={control}
        register={register}
        name={`configurationFields.${index}.triggerRules`}
      />
    </div>
  );
}
