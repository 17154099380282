import flatMap from 'lodash/flatMap';

import { PrimaryCTAButton, PageWrapper } from '@optra/kit';

import AdminFirmwaresList from 'components/admin-firmwares-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import { useFirmwares } from 'queries';

// TODO: Pagination
export default function AdminFirmware() {
  const { isLoading, error, data, fetchNextPage, isFetchingNextPage, hasNextPage } = useFirmwares();
  const firmwares = flatMap(data?.pages, page => page?.list?.data);

  return (
    <PageWrapper
      icon="Cpu"
      title="Firmware | Admin"
      heading="Firmware"
      components={{
        actions: (
          <>
            <PinPageButton />
            <PrimaryCTAButton to="/admin/firmware/create" text="New Firmware" />
          </>
        ),
      }}
      inline={true}
      loading={isLoading}
      error={error}
    >
      <AdminFirmwaresList fetching={isLoading} error={error} firmwares={firmwares} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isFetchingNextPage || !hasNextPage}
      />
    </PageWrapper>
  );
}
