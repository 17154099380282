import { without, flatMap } from 'lodash';

import { Badge, Spinner } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';
import { useTags } from 'queries';

export default function DevicesFilterTags({ onFilter, filter }) {
  const { data, isLoading: fetching } = useTags();
  const tags = flatMap(data?.pages, page => page?.list?.data);

  if (fetching) {
    return (
      <DevicesFilterListItem image={<Spinner size="sm" color="gradient" />} label="Loading…" />
    );
  }

  // TODO: pagination of sorts
  return (
    <div className="py-2 px-3 flex-wrap">
      {tags?.map?.(tag => {
        const selected = filter.tagIds?.includes?.(tag.id);
        return (
          <Badge
            key={tag.id}
            variant={selected ? 'primary' : 'muted'}
            size="xs"
            className="m-1 cursor-pointer"
            onClick={() => {
              if (selected) {
                onFilter({
                  ...filter,
                  tagIds: without(filter.tagIds, tag.id),
                });
              } else {
                onFilter({
                  ...filter,
                  tagIds: [...(filter.tagIds || []), tag.id],
                });
              }
            }}
          >
            {tag.name}
          </Badge>
        );
      })}
    </div>
  );
}
