import { last, omit } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import {
  DetailList,
  DetailHeading,
  Card,
  Button,
  Icon,
  IconButton,
  Spinner,
  Text,
  Heading,
} from '@optra/kit';

import DeleteSkillButton from 'components/delete-skill-button';
import { useFeature } from 'components/feature';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillMetaFields from 'components/skill-meta-fields';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useSkill, useSignedUpload, useCurrentUser } from 'queries';

export default function EditSkill() {
  useTitle('Edit Skill | Optra');
  const { skillId } = useParams();
  const { handleSubmit: onSubmit, control, register, setFocus, reset } = useForm();
  const [error, setError] = useState();
  useInputFocus(setFocus, 'name');
  const { handleClose } = useModalContext();

  const [currentUser] = useCurrentUser();
  const navigate = useNavigate();
  const canPublish = useFeature('publishSkill');

  const { data, isLoading: isLoadingDefaults, isSuccess, error: fetchError } = useSkill(skillId);

  const skill = data?.skill;

  useOnSuccess(
    () => {
      reset(formValues => {
        return {
          name: skill?.name,
          icon: {
            icon: skill?.icon,
            color: skill?.color,
            iconUrl: skill?.iconUrl,
          },
          // NOTE: SkillBuilderDeviceSelect loads selected devices internally, we want to make sure
          // we're not modifying those values on reset
          devices: formValues.devices,
          collaborators: formValues.collaborators,
        };
      });
    },
    { isSuccess },
    [reset, skill],
  );

  const qc = q.useQueryClient();
  const updateSkill = q.useMutation({
    mutationFn: form => {
      return api(
        `mutation updateSkill($form: updateSkillForm!) {
          skill: updateSkill(form: $form) {
            id
          }
        }`,
        { form },
      );
    },
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      handleClose();
    },
    onError(err) {
      setError(err);
    },
  });

  const [uploadIcon, uploadIconState] = useSignedUpload({ type: 'skillImage' });
  const handleSubmit = onSubmit(async form => {
    setError(null);

    let iconUrl = form.icon.iconUrl;
    if (form.icon.iconUrl instanceof File) {
      const uploadedIcon = await uploadIcon(form.icon.iconUrl, {
        extension: last(form.icon.iconUrl.name.split('.')),
        name: `${skillId}-icon`,
      });
      iconUrl = uploadedIcon.url;
    }

    updateSkill.mutate({
      ...omit(form, 'icon'),
      icon: form.icon?.icon,
      color: form.icon?.color,
      iconUrl: iconUrl || null,
      id: skillId,
    });
  });

  const loading =
    fetchError || isLoadingDefaults || updateSkill.isPending || uploadIconState.fetching;

  const itemNotFound = useItemNotFound({
    fetching: isLoadingDefaults,
    id: skill?.id,
  });

  const isListed =
    skill?.published || skill?.publishing || skill?.reviewing || skill?.changesRequested;

  let publicationIcon = 'LockLaminated';
  if (skill?.published) publicationIcon = 'CheckCircle';
  if (skill?.reviewing) publicationIcon = 'DotsThreeCircle';
  if (skill?.changesRequested) publicationIcon = 'WarningCircle';

  let publicationText = 'Private Skill';
  if (skill?.published) publicationText = 'Published Skill';
  if (skill?.reviewing) publicationText = 'Under Review';
  if (skill?.changesRequested) publicationText = 'Changes Requested';

  if (itemNotFound) {
    return <ItemNotFound id={skillId} type="Skill" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Edit Skill"
        icon={skill?.isSkillBuilder ? 'Graph' : 'Cube'}
        loading={loading}
        renderActions={() => (
          <>
            <DeleteSkillButton
              skillId={skillId}
              onSuccess={() => {
                handleClose();
              }}
              onError={err => {
                setError(err);
              }}
            />
            {currentUser?.isSysAdmin &&
              !skill?.isSkillBuilder &&
              !isListed &&
              !skill?.isDockerCompose && (
                <Button
                  size="xs"
                  variant="secondary"
                  onClick={() => {
                    navigate('copy');
                  }}
                  icon="Copy"
                >
                  Copy To
                </Button>
              )}
          </>
        )}
      />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Update Skill">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Skill">
            {fetchError.message}
          </Message>
        )}

        {canPublish && !skill?.isSkillBuilder && (
          <Card className="flex items-center justify-between gap-3 shadow-lg" size="sm">
            {skill?.publishing && <Spinner />}
            {!skill?.publishing && <Icon name={publicationIcon} size="lg" />}
            <div className="flex-1">
              <Heading level={3}>{publicationText}</Heading>
              <Text className="inline-block" size="xs">
                This skill has {skill?.published ? '' : 'not'} been published to the marketplace.
                {skill?.reviewing ? ' A version is under review.' : ''}
                {skill?.changesRequested ? ' A version has requested changes.' : ''}
              </Text>
            </div>
            {isListed && (
              <div>
                <Button size="xs" variant="tertiary" to="listing">
                  {'Edit Listing'}
                </Button>
              </div>
            )}
            <div>
              <Button size="xs" variant="tertiary" to="publish/version">
                {'Publish'}
              </Button>
            </div>
          </Card>
        )}

        {skill?.isSkillBuilder && (
          <Card
            variant="secondary"
            className="cursor-pointer flex items-center justify-between gap-3"
          >
            <div className="flex-1">
              <DetailHeading variant="loud">DEPRECATED</DetailHeading>
              <DetailList
                details={[
                  'Support for this skill will be removed, the underlying model has been moved to the models tab for use with the skill builder',
                ]}
              />
            </div>
          </Card>
        )}

        <SkillMetaFields
          control={control}
          register={register}
          loading={loading}
          loadingDefaults={isLoadingDefaults}
          hideVersion
          hideIcon={skill?.isSkillBuilder}
        />

        {!skill?.isSkillBuilder && (
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <Heading icon="Stack" level={3}>
                Versions
              </Heading>
              <IconButton
                name="Plus"
                variant="tertiary"
                to={skill?.isDockerCompose ? '../versions/add_compose' : '../versions/add'}
                state={{ fromModal: true }}
              />
            </div>
            <List loading={isLoadingDefaults}>
              {skill?.versions?.data?.length > 0 &&
                skill?.versions?.data?.map(version => (
                  <ListItem
                    key={version.id}
                    to={`../versions/${version.id}`}
                    state={{ fromModal: true }}
                    renderRight={() => <Icon name="CaretRight" weight="line" />}
                  >
                    {version.version}
                  </ListItem>
                ))}
            </List>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={loading} onClick={onSubmit}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
