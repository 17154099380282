import { Button, PageWrapper } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';

export default function NoMatch() {
  return (
    <PageWrapper>
      <EmptyContentMessage
        icon="Question"
        title="Page Not Found"
        renderAction={() => <Button to="/">Go Home</Button>}
      />
    </PageWrapper>
  );
}
