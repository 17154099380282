import * as Sentry from '@sentry/react';
import Lockr from 'lockr';
import { useRef } from 'react';
import { useAuthorize } from 'react-auth0rize';
import { Navigate } from 'react-router-dom';

import { Card, Button, Spinner, Heading } from '@optra/kit';

import FillParent from 'components/fill-parent';
import { useCurrentUser } from 'queries';
import RestoreScroll from 'router/restore-scroll';

export default function AuthCallback() {
  const [currentUser, loadingCurrentUser] = useCurrentUser();
  const [{ authenticated, error }, authenticating] = useAuthorize();
  const intendedRoute = useRef(Lockr.get('intendedRoute') || '/');

  if (loadingCurrentUser) {
    return (
      <>
        <RestoreScroll />
        <FillParent>
          <Spinner color="gradient" size="2xl" />
        </FillParent>
      </>
    );
  }

  if (currentUser) {
    Sentry.setUser({ email: currentUser.email });
  }

  if (error) {
    return (
      <FillParent>
        <Card className="text-center" size="xl">
          <Heading variant="centered" icon="Warning">
            Error Logging In
          </Heading>
          <p className="my-4">{error?.errorDescription || 'Unauthorized Access'}</p>
          <Button to="/login">Log In</Button>
        </Card>
      </FillParent>
    );
  }

  if (authenticated && !authenticating) {
    if (currentUser?.agreements?.eula?.state === 'accepted') {
      Lockr.rm('intendedRoute');
    }

    return <Navigate to={intendedRoute.current} />;
  }

  return (
    <>
      <RestoreScroll />
      <FillParent>
        <Spinner color="gradient" size="2xl" />
      </FillParent>
    </>
  );
}
