import { noop } from 'lodash';
import { useState } from 'react';

import { Button } from '@optra/kit';

import ColorSelector from 'modals/skill-pipeline-builder/components/color-selector';
import ImageSelector from 'modals/skill-pipeline-builder/components/image-selector';

export default function ImageColorSelector(props) {
  const { value, onChange: _onChange = noop, disabled } = props;

  const onChange = disabled ? noop : _onChange;
  const useOnChange = type => (nextValue, ext) => {
    onChange({
      type,
      value: nextValue,
      ext,
    });
  };

  const isColor = Array.isArray(value);
  const defaultTab = isColor ? 'color' : 'image';
  const [tab, setTab] = useState(defaultTab);

  return (
    <>
      <div className="flex flex-1 items-center justify-center pb-6">
        <Button
          variant={tab === 'image' ? 'secondary' : 'tertiary'}
          onClick={() => {
            setTab('image');
          }}
          size="xs"
          className="rounded-r-none relative"
          disabled={disabled}
        >
          Image
        </Button>
        <Button
          variant={tab === 'color' ? 'secondary' : 'tertiary'}
          onClick={() => {
            setTab('color');
          }}
          size="xs"
          className="rounded-l-none"
          disabled={disabled}
        >
          Color
        </Button>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className={tab !== 'color' ? 'hidden' : ''}>
          <ColorSelector value={isColor ? value : null} onChange={useOnChange('color')} />
        </div>

        <div className={tab !== 'image' ? 'hidden' : ''}>
          <ImageSelector value={!isColor ? value : null} onChange={useOnChange('image')} />
        </div>
      </div>
    </>
  );
}
