import { useEffect } from 'react';

export default function useInputFocus(setFocus, name) {
  useEffect(() => {
    try {
      setFocus(name);
    } catch (e) {
      return;
    }
  }, [setFocus, name]);
}
