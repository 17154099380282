import cx from 'classnames';
import { truncate } from 'lodash';

import { Icon, Text, DetailList, InteractiveWrap } from '@optra/kit';

const VARIANTS = {
  default: ['bg-light-bg-primary', 'dark:bg-dark-bg-primary'],
  dirty: ['bg-primary-500', 'bg-opacity-20', 'border-primary-600', 'border-dashed'],
  deleted: ['bg-red-200', 'dark:bg-red-800', 'border-red-600', 'border-dashed'],
  disabled: [
    'bg-light-bg-primary',
    'dark:bg-dark-bg-primary',
    'bg-opacity-50',
    'dark:bg-opacity-50',
    'border-light-fg-secondary',
    'dark:border-dark-fg-secondary',
    'border-dashed',
    'cursor-not-allowed',
  ],
};

function WorkflowListItem({
  variant = 'default',
  name,
  details,
  components = {},
  className,
  disabled,
  disabledAllowInteraction,
  ...rest
}) {
  return (
    <InteractiveWrap
      className={cx([
        'relative',
        'flex flex-row items-center gap-3',
        'rounded-md',
        'p-5',
        'mb-3',
        'border border-transparent',
        'hover:opacity-60',
        VARIANTS[variant],
        className,
      ])}
      role="button"
      type="button"
      disabled={disabled && !disabledAllowInteraction}
      {...rest}
    >
      {!!components?.before && <div className="flex-0">{components?.before}</div>}
      <div className="flex-1">
        <Text size="sm" className="font-medium">
          {truncate(name, { length: 36 })}
        </Text>
        {!!details && <DetailList details={details} size="xs" />}
      </div>
      {!!components?.after && <div className="flex-0">{components?.after}</div>}
      {!disabled && (
        <div className="flex-0">
          <Icon name="CaretRight" weight="line" size="sm" />
        </div>
      )}
    </InteractiveWrap>
  );
}

export default WorkflowListItem;
