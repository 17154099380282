import { api, q } from 'config/api';

export default function useDeviceTelemetryLogs(id, variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['deviceTelemetryLogs', id, variables],
    async queryFn({ pageParam }) {
      const r = await api(
        `query deviceTelemetryLogs($id: ID!, $list: listFields) {
          device(id: $id) {
            logs(list: $list) {
              data {
                id
                message
                type
                descriptors
                createdAt
              }
              cursor {
                after
              }
            }
          }
        }`,
        {
          ...variables,
          id,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );

      const logs = r?.device?.logs?.data;

      return {
        logs,
        cursor: r?.device?.logs?.cursor,
      };
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
    ...overrides,
  });
}
