import { WebClient } from 'react-auth0rize';

const client = new WebClient({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin.toLowerCase().replace('www.', '')}/authorize`,
  responseType: 'token id_token',
  scope: 'openid profile email',
  logoutRedirectUri: `${window.location.origin.toLowerCase().replace('www.', '')}/login`,
  storageKey: 'auth',
});

export default client;
