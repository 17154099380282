import { api, q } from 'config/api';

export default function useDeploymentTasks(id, variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflowDeploymentExecutionTasks', id, variables],
    queryFn({ pageParam }) {
      return api(
        `query workflowDeploymentExecutionTasks($id: ID!, $list: listFields) {
          list: workflowDeploymentExecutionTasks(workflowDeploymentId: $id, list: $list) {
            count
            data {
              id
              status
              error
              priority
              maxAttempts
              attempts
              skillsStatus
              completedAt
              device {
                id
                name
                model
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          id,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
    enabled: !!id,
    ...overrides,
  });
}
