import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import IntersectionLoadMore from 'components/intersection-load-more';
import formatUserLog from 'lib/format-user-log';

const logColor = {
  ERROR: 'text-red',
  INFO: 'text-green',
  WARN: 'text-yellow',
  DEBUG: 'text-gray',
};

export default function LogsScroller(props) {
  const {
    loading,
    hasMore,
    loadingMore,
    logs = [],
    onLoadMore,
    formatLog = formatUserLog,
    endless = false,
  } = props;

  const isFetching = loading || loadingMore;
  const isLoaded = !isFetching && !hasMore;
  const noLogsFound = isEmpty(logs) && isLoaded;

  return (
    <div
      className={cx([
        'bg-black',
        'font-mono',
        'text-xs',
        'rounded-md',
        'shadow-sm',
        'p-2',
        'border',
        // Light
        'border-opacity-50',
        'border-light-fg-secondary',
        // Dark
        'dark:border-opacity-50',
        'dark:border-dark-fg-secondary',
        'overflow-x-scroll',
        'h-[640px]', // lineHeight (16) * numberOfLines (40) = 640
        'flex',
        'flex-col-reverse',
        'max-w-full',
      ])}
    >
      {logs.map((log, idx) => (
        <span
          key={log?.id || idx}
          className={cx('block whitespace-nowrap', logColor[log?.type] || 'text-green')}
        >
          {formatLog(log)}
        </span>
      ))}
      {isFetching && (
        <span className={cx('block whitespace-nowrap', 'text-gray')}>Loading Logs…</span>
      )}
      {noLogsFound && (
        <span className={cx('block whitespace-nowrap', 'text-gray')}>
          No logs found, listening for logs…
        </span>
      )}
      {!endless && isLoaded && (
        <span className={cx('block whitespace-nowrap', 'text-gray')}>Start of Logs</span>
      )}
      {onLoadMore && (
        <IntersectionLoadMore onVisible={onLoadMore} disabled={loadingMore || !hasMore} />
      )}
    </div>
  );
}
