import { api, q } from 'config/api';

export default function useWorkflowsLicenses(filters, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflowsLicenses', filters],
    queryFn({ pageParam }) {
      return api(
        `query workflowsLicenses($list: listFields) {
          list: workflowsLicenses(list: $list) {
            data {
              id
              license {
                id
                active
                disabled
              }
              device {
                id
                name
                model
              }
              workflowSkill {
                name
                skill {
                  color
                  icon
                  iconUrl
                }
                version {
                  creditsCost
                }
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            ...filters,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => data.pages.flatMap(page => page.list.data),
    ...overrides,
  });
}
