const NODE_DATA_TYPES = {
  Text: {
    key: 'Text',
    label: 'Text',
    icon: 'TextAa',
    color: 'blue',
  },
  Image: {
    key: 'Image',
    label: 'Image',
    icon: 'Image',
    color: 'yellow',
  },
  BoundingBoxes: {
    key: 'BoundingBoxes',
    label: 'Bounding Box',
    icon: 'BoundingBox',
    color: 'red',
  },
  NumberList: {
    key: 'NumberList',
    label: 'Number Data',
    icon: 'Database',
    color: 'green',
  },
  DecimalList: {
    key: 'DecimalList',
    label: 'Decimal Data',
    icon: 'Database',
    color: 'purple',
  },
  Any: {
    key: 'Any',
    label: 'Any',
    icon: 'CheckCircle',
    color: 'orange',
  },
};

export default NODE_DATA_TYPES;
