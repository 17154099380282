import { truncate, without, flatMap } from 'lodash';

import { Spinner } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';
import SkillIcon from 'components/skill-icon';
import { useWorkflowSkillSkills } from 'queries';

export default function DevicesFilterSkills({ onFilter, filter }) {
  const { data, isLoading: fetching } = useWorkflowSkillSkills();
  const skills = flatMap(data?.pages, page => page?.list?.data);

  if (fetching) {
    return (
      <DevicesFilterListItem image={<Spinner size="sm" color="gradient" />} label="Loading…" />
    );
  }

  // TODO: Pagination
  return (
    skills.map?.((skill, idx) => {
      const selected = filter.skillIds?.includes?.(skill.id);

      return (
        <DevicesFilterListItem
          key={skill.id}
          image={
            <SkillIcon icon={skill?.icon} iconUrl={skill?.iconUrl} color={skill?.color} size="xs" />
          }
          label={truncate(skill.name, { length: 24 })}
          selected={selected}
          onClick={() => {
            if (selected) {
              onFilter({
                ...filter,
                skillIds: without(filter.skillIds, skill.id),
              });
            } else {
              onFilter({
                ...filter,
                skillIds: [...(filter.skillIds || []), skill.id],
              });
            }
          }}
        />
      );
    }) || null
  );
}
