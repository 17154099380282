import { api, q } from 'config/api';

export default function useNextAvailablePort(overrides) {
  return q.useQuery({
    queryKey: ['nextAvailablePort'],
    queryFn: () =>
      api(
        `query nextAvailablePort {
          nextAvailablePort
        }`,
      ),
    ...overrides,
  });
}
