import moment from 'moment';

import between from 'modals/chart-details/charts-query-engine/between';
import dateAdd from 'modals/chart-details/charts-query-engine/date-add';
import endOf from 'modals/chart-details/charts-query-engine/end-of';
import startOf from 'modals/chart-details/charts-query-engine/start-of';

export default {
  latest: {
    name: 'Latest',
    resolver: ({ db, table }) =>
      `(time >= (SELECT "time" FROM "${db}"."${table}" ORDER BY "time" DESC LIMIT 1))`,
    daysInMonth: () => moment().startOf('day').daysInMonth(),
  },
  today: {
    name: 'Today',
    resolver: ({ key }) => between(key, startOf('day'), endOf('day')),
    daysInMonth: () => moment().startOf('day').daysInMonth(),
  },
  yesterday: {
    name: 'Yesterday',
    resolver: ({ key }) => between(key, startOf('day', dateAdd('day', -1)), startOf('day')),
    daysInMonth: () => moment().subtract(1, 'day').startOf('day').daysInMonth(),
  },
  this_week: {
    name: 'This Week',
    resolver: ({ key }) => between(key, startOf('week'), endOf('week')),
    daysInMonth: () => moment().startOf('week').daysInMonth(),
  },
  last_week: {
    name: 'Last Week',
    resolver: ({ key }) => between(key, startOf('week', dateAdd('week', -1)), startOf('week')),
    daysInMonth: () => moment().subtract(1, 'week').startOf('week').daysInMonth(),
  },
  this_month: {
    name: 'This Month',
    description: moment().format('MMMM YYYY'),
    resolver: ({ key }) => between(key, startOf('month'), endOf('month')),
    daysInMonth: () => moment().startOf('month').daysInMonth(),
  },
  last_month: {
    name: 'Last Month',
    description: moment().subtract(1, 'month').format('MMMM YYYY'),
    resolver: ({ key }) => between(key, startOf('month', dateAdd('month', -1)), startOf('month')),
    daysInMonth: () => moment().subtract(1, 'month').startOf('month').daysInMonth(),
    unit: 'month',
  },
  this_year: {
    name: 'This Year',
    description: moment().format('YYYY'),
    resolver: ({ key }) => between(key, startOf('year'), endOf('year')),
    daysInMonth: () => moment().startOf('year').daysInMonth(),
  },
  last_year: {
    name: 'Last Year',
    description: moment().subtract(1, 'year').format('YYYY'),
    resolver: ({ key }) => between(key, startOf('year', dateAdd('year', -1)), startOf('year')),
    daysInMonth: () => moment().subtract(1, 'year').startOf('year').daysInMonth(),
  },
};
