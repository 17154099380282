import { isBoolean, noop } from 'lodash';
import { useState, useImperativeHandle, forwardRef } from 'react';

import { Table, UiState, Icon, Modal, Button, SearchField } from '@optra/kit';

import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';

export default forwardRef(function ItemChooser(props, ref) {
  const {
    onSelect = () => false,
    isSelected = item => {
      return item?.selected;
    },
    items = [],
    itemsInfiniteQuery,
    isLoading: isLoadingOverride,
    search,
    onSearch = noop,
    RowDataComponent,
    emptyIcon,
    emptyText,
    loadErrorTitle = "Couldn't Load Items",
    defaultIsOpen = false,
    heading = 'Choose',
  } = props;

  const {
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: _isLoading,
  } = itemsInfiniteQuery || {};

  const isLoading = isBoolean(isLoadingOverride) ? isLoadingOverride : _isLoading;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  useImperativeHandle(ref, () => ({ open, close }), []);

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Modal.Header heading={heading} />
      <Modal.Body className="px-1">
        {error && (
          <Message variant="danger" title={loadErrorTitle}>
            {error.message}
          </Message>
        )}

        <div className="my-4 flex items-center">
          <SearchField
            className="flex-1"
            value={search || ''}
            onChange={$search => onSearch($search)}
            onClear={() => onSearch('')}
            searching={isLoading}
          />
        </div>

        {isLoading ? (
          <UiState />
        ) : items?.length === 0 ? (
          <UiState
            variant="empty"
            icon={{
              component: () => (
                <Icon
                  name={emptyIcon || 'Warning'}
                  className="h-12 w-12 text-gray-300 dark:text-gray-700"
                  weight="duotone"
                />
              ),
            }}
            text={emptyText || 'Nothing Found'}
          />
        ) : (
          <Table variant="secondary">
            <Table.Body>
              {items.map(item => {
                if (!item) {
                  return null;
                }
                const { id, disabled } = item;
                const selected = isSelected(item);
                return (
                  <Table.Row
                    key={id}
                    hover={!disabled}
                    disabled={disabled}
                    disabledSelectable={disabled}
                    selectable
                    selected={selected}
                    onClick={
                      disabled
                        ? null
                        : () => {
                            onSelect(selected, item);
                          }
                    }
                  >
                    {RowDataComponent && <RowDataComponent item={item} selected={selected} />}
                  </Table.Row>
                );
              })}
            </Table.Body>
            <IntersectionLoadMore
              as="tfoot"
              onVisible={fetchNextPage}
              disabled={isFetchingNextPage || !hasNextPage}
            />
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-center">
        <Button size="xl" loading={isLoading} onClick={close}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
