import AdminViewOutput, {
  DIAGNOSTICS_VIEW_KEY,
} from 'modals/skill-pipeline-builder/config/defs/node-def-admin-view-output';
import BoundingBoxCropElement from 'modals/skill-pipeline-builder/config/defs/node-def-bounding-box-crop-element';
import ClassificationCountPerFrameProcessor from 'modals/skill-pipeline-builder/config/defs/node-def-classification-count-per-frame-processor';
import CombineLabelsProcessor from 'modals/skill-pipeline-builder/config/defs/node-def-combine-labels-processor';
import CountToLabelProcessor from 'modals/skill-pipeline-builder/config/defs/node-def-count-to-label-processor';
import DetectionCountPerFrameProcessor from 'modals/skill-pipeline-builder/config/defs/node-def-detection-count-per-frame-processor';
import EdgeImpulseClassificationInferenceEngine from 'modals/skill-pipeline-builder/config/defs/node-def-edge-impulse-classification-inference-engine';
import EdgeImpulseObjectDetectionInferenceEngine from 'modals/skill-pipeline-builder/config/defs/node-def-edge-impulse-object-detection-inference-engine';
import ImageLayerElement from 'modals/skill-pipeline-builder/config/defs/node-def-image-layer-element';
import ImageResultDecoratorElement from 'modals/skill-pipeline-builder/config/defs/node-def-image-result-decorator-element';
import inference_engine_factory from 'modals/skill-pipeline-builder/config/defs/node-def-inference-engine-factory';
import input_factory from 'modals/skill-pipeline-builder/config/defs/node-def-input-factory';
import LabelAverageProcessor from 'modals/skill-pipeline-builder/config/defs/node-def-label-average-processor';
import LabelSequenceProcessor from 'modals/skill-pipeline-builder/config/defs/node-def-label-sequence-processor';
import LabelToImageElement from 'modals/skill-pipeline-builder/config/defs/node-def-label-to-image-element';
import MajorityLabelProcessor from 'modals/skill-pipeline-builder/config/defs/node-def-majority-label-processor';
import ResultsIndicatorElement from 'modals/skill-pipeline-builder/config/defs/node-def-results-indicator-element';
import RotateImageElement from 'modals/skill-pipeline-builder/config/defs/node-def-rotate-image-element';
import SourceImagePipelineInput from 'modals/skill-pipeline-builder/config/defs/node-def-source-image-pipeline-input';
import StaticCropImageElement from 'modals/skill-pipeline-builder/config/defs/node-def-static-crop-image-element';
import StaticImageElement from 'modals/skill-pipeline-builder/config/defs/node-def-static-image-element';
import StaticImagePipelineInput from 'modals/skill-pipeline-builder/config/defs/node-def-static-image-pipeline-input';
import StaticVideoPipelineInput from 'modals/skill-pipeline-builder/config/defs/node-def-static-video-pipeline-input';
import UserViewOutput from 'modals/skill-pipeline-builder/config/defs/node-def-user-view-output';

export { default as DATA_TYPES } from 'modals/skill-pipeline-builder/config/node-data-types';
export { CATEGORIES, CATEGORIES_ORDER } from 'modals/skill-pipeline-builder/config/node-categories';

export { DIAGNOSTICS_VIEW_KEY };

export const NODE_DEFS = {
  input_factory,
  inference_engine_factory,
  SourceImagePipelineInput,
  StaticImagePipelineInput,
  StaticVideoPipelineInput,
  EdgeImpulseClassificationInferenceEngine,
  EdgeImpulseObjectDetectionInferenceEngine,
  ClassificationCountPerFrameProcessor,
  CombineLabelsProcessor,
  CountToLabelProcessor,
  DetectionCountPerFrameProcessor,
  LabelAverageProcessor,
  LabelSequenceProcessor,
  MajorityLabelProcessor,
  BoundingBoxCropElement,
  ImageLayerElement,
  ImageResultDecoratorElement,
  LabelToImageElement,
  ResultsIndicatorElement,
  RotateImageElement,
  StaticCropImageElement,
  StaticImageElement,
  [DIAGNOSTICS_VIEW_KEY]: AdminViewOutput,
  UserViewOutput,
};
