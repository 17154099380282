import findPathDeep from 'deepdash/findPathDeep';
import set from 'lodash/fp/set';

import { Toggle } from '@optra/kit';

import { api, q } from 'config/api';

export default function AdminUserHiddenButton(props) {
  const { id: _id, value = false } = props;

  const qc = q.useQueryClient();
  const toggleOrganizationMemberHidden = q.useMutation({
    mutationFn: id =>
      api(
        `mutation toggleOrganizationMemberHidden($id: ID!) {
          toggleOrganizationMemberHidden(id: $id) {
            id
          }
        }`,
        { id },
      ),
    async onMutate(id) {
      await qc.cancelQueries({ queryKey: ['organizationMembers'] });
      const next = !value;
      qc.setQueriesData({ queryKey: ['organizationMembers'] }, olds => {
        const path = findPathDeep(olds, (v, k) => k === 'id' && v === id, {
          pathFormat: 'array',
        });
        if (!path) return olds;
        return set([...path.slice(0, -1), 'hidden'], next, olds);
      });

      return next;
    },
    onError: (err, id, prev) => {
      qc.setQueriesData({ queryKey: ['organizationMembers'] }, olds => {
        const path = findPathDeep(olds, (v, k) => k === 'id' && v === id, {
          pathFormat: 'array',
        });
        if (!path) return olds;
        return set([...path.slice(0, -1), 'hidden'], prev, olds);
      });
    },
    onSettled() {
      qc.invalidateQueries({ queryKey: ['organizationMembers'] });
    },
  });

  function toggleHidden(e) {
    e.stopPropagation();
    if (toggleOrganizationMemberHidden.isPending) return;
    toggleOrganizationMemberHidden.mutate(_id);
  }

  return (
    <Toggle
      onChange={toggleHidden}
      checked={value}
      disabled={toggleOrganizationMemberHidden.isPending}
      controlled
      stopPropagation
    />
  );
}
