import { Controller } from 'react-hook-form';

import { Card, SemanticVersionInput } from '@optra/kit';

import FeatureToggle from 'components/feature-toggle';
import IconChooser from 'components/icon-chooser';
import Input from 'components/input';
import Label from 'components/label';
import ValidationError from 'components/validation-error';

export default function SkillMetaFields({
  control,
  errors,
  hideIcon,
  hideName,
  hideVersion,
  loading,
  loadingDefaults,
  register,
  versionMinValue,
  showNewVersionToggle,
  showTargetFamilyToggle,
}) {
  return (
    <div className="space-y-4">
      {!hideIcon && (
        <Card variant="secondary" noPadding className="space-y-2 py-5">
          <Controller
            name="icon"
            control={control}
            rules={{
              required: 'Please choose an icon.',
            }}
            render={({ field, fieldState }) => <IconChooser {...field} loading={loadingDefaults} />}
          />
          <ValidationError name="icon" errors={errors} />
        </Card>
      )}

      {showNewVersionToggle && (
        <Controller
          name="isNewVersion"
          control={control}
          render={({ field }) => (
            <FeatureToggle
              {...field}
              title="New Version"
              description="Create a new version from the current configuration"
              readOnly={loadingDefaults}
              checked={field.value || false}
            />
          )}
        />
      )}

      {showTargetFamilyToggle && (
        <Controller
          name="targetVisionFamily"
          control={control}
          render={({ field }) => (
            <FeatureToggle
              {...field}
              title="Use GPU Model"
              description="The created model will be for VZ devices"
              readOnly={loadingDefaults}
              checked={field.value || false}
            />
          )}
        />
      )}

      <Card variant="secondary" className="space-y-4">
        {!hideName && (
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              {...register('name', {
                required: 'Please enter a name',
              })}
              readOnly={loading}
            />
            <ValidationError name="name" errors={errors} />
          </div>
        )}

        {!hideVersion && (
          <div className="space-y-2">
            <Label htmlFor="version">Version Number</Label>
            <Controller
              name="version"
              control={control}
              disabled={loading}
              render={({ field }) => <SemanticVersionInput {...field} minValue={versionMinValue} />}
            />
          </div>
        )}
      </Card>
    </div>
  );
}
