import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import DeviceLocationsList from 'components/device-locations-list';
import EmptyContentMessage from 'components/empty-content-message';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalTitle from 'components/modal-title';
import { useLocation } from 'queries';

export default function EditDeviceLocations() {
  const { locationId } = useParams();

  const { data, error } = useLocation(locationId);
  const devices = data?.location?.devices?.data;

  return (
    <>
      <ModalTitle title="Devices" icon="Aperture" showBackButton={true} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Load Devices">
            {error.message}
          </Message>
        )}

        <div className="space-y-2">
          {isEmpty(devices) ? (
            <EmptyContentMessage icon="Aperture" title="No devices" />
          ) : (
            <DeviceLocationsList devices={devices} locationId={locationId} />
          )}
        </div>
      </ModalBody>
    </>
  );
}
