import { Icon, IconButton, Table, Text } from '@optra/kit';

import { api, q } from 'config/api';

export default function DeviceLocationsListItem({ device, locationId }) {
  const qc = q.useQueryClient();
  const removeDeviceLocation = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateDevice($form: updateDeviceForm!) {
          updateDevice(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['location', locationId] });
    },
  });

  const handleRemoveDeviceLocation = deviceId => {
    removeDeviceLocation.mutate({ id: deviceId, locationId: null });
  };

  return (
    <Table.Row hover>
      <Table.TD>
        <Icon name="Aperture" color="gradient" className="mr-2" />
        <Text>{device?.name}</Text>
      </Table.TD>
      <Table.TD className="flex justify-end">
        <IconButton
          name="X"
          variant="tertiary"
          loading={removeDeviceLocation.isPending}
          onClick={() => handleRemoveDeviceLocation(device?.id)}
        />
      </Table.TD>
    </Table.Row>
  );
}
