"use strict";
Object.defineProperty(exports, // TODO: Use this function in web/calculate-raw-configs and api/calclateCreateOptions
// NOTE: For some reason the function is hanging on web
"default", {
    enumerable: true,
    get: function() {
        return parseSkillCreateOptions;
    }
});
const _async_to_generator = require("@swc/helpers/_/_async_to_generator");
const _object_spread = require("@swc/helpers/_/_object_spread");
const _object_spread_props = require("@swc/helpers/_/_object_spread_props");
const _lodash = require("lodash");
const _createOptionsSchema = require("./createOptionsSchema");
function getBinding(str) {
    var _str_split;
    // NOTE: Permissions are always 3rd and are the only optional field
    const [binding = '', path = '', permissions = 'rw'] = (str === null || str === void 0 ? void 0 : (_str_split = str.split) === null || _str_split === void 0 ? void 0 : _str_split.call(str, ':')) || [];
    return {
        binding,
        path,
        permissions,
        normalized: `${binding}:${path}:${permissions}`
    };
}
function parseCGroupRule(rule) {
    const [type, majorAndMinor, access] = rule.split(' ');
    const [major, minor] = majorAndMinor.split(':');
    return {
        type,
        major,
        minor,
        access
    };
}
function resolveCreateOptionsVariables(createOptions, { exposedUIPort , hostUIPort , id  } = {}) {
    return !(0, _lodash.isEmpty)(createOptions) ? JSON.parse((0, _lodash.template)(JSON.stringify(createOptions))({
        exposedUIPort,
        hostUIPort,
        id
    })) : {};
}
function parseSkillCreateOptions(config, ops) {
    return _parseSkillCreateOptions.apply(this, arguments);
}
function _parseSkillCreateOptions() {
    _parseSkillCreateOptions = _async_to_generator._(function*(config, ops) {
        var _config_devices, _config_addedDevice, _config_devices1, _config_devices2, _config_devices3, _config_devices4, _config_devices5, _config_capAdd, _config_capDrop, _config_devices6, _parsed_NetworkingConfig, _parsed_NetworkingConfig1, _parsed_NetworkingConfig_EndpointsConfig, _parsed_NetworkingConfig_EndpointsConfig_azureiotedge;
        const { hostName , endpointAliases , portBindings , tmpfs , shmSize , writableRootFS , createOptions ='{}' , mountVolumes  } = config !== null && config !== void 0 ? config : {}; // config is stored in skill versions
        const { exposedUIPort , hostUIPort , id , onSoftError , deviceFamily , localhostOnly , workflowSkillPort , isPreview =false  } = ops !== null && ops !== void 0 ? ops : {};
        let parsed = {};
        try {
            parsed = resolveCreateOptionsVariables(createOptions, {
                exposedUIPort,
                hostUIPort,
                id
            });
        } catch (error) {
            yield onSoftError === null || onSoftError === void 0 ? void 0 : onSoftError(error);
            parsed = {};
            if (isPreview) {
                return {
                    data: {},
                    errors: [
                        error.message
                    ]
                };
            }
        }
        const customHostConfig = parsed.HostConfig || {};
        const gpioDevice = config.gpio ? [
            {
                PathOnHost: '/dev/gpiochipUSR',
                PathInContainer: '/dev/gpiochipUSR',
                CgroupPermissions: 'rwm'
            }
        ] : [];
        const hdmi = ((_config_devices = config.devices) === null || _config_devices === void 0 ? void 0 : _config_devices.hdmi) ? [
            'DISPLAY=:0'
        ] : [];
        // binds
        const dockerInDockerBinds = config.dockerInDocker ? [
            '/var/run/docker.sock:/var/run/docker.sock',
            '/usr/bin/docker:/usr/bin/docker'
        ] : [];
        const deviceBinds = ((_config_addedDevice = config.addedDevice) === null || _config_addedDevice === void 0 ? void 0 : _config_addedDevice.length) > 0 ? [
            '/dev:/dev:ro'
        ] : [];
        const cx2000IRRemoteBinds = config.cx2000IRRemote ? [
            '/dev:/dev:ro'
        ] : [];
        const usbSerialConverterBinds = config.usbSerialConverter ? [
            '/dev:/dev:ro'
        ] : [];
        const gpioBinds = config.gpio ? [
            '/dev:/dev:ro'
        ] : [];
        const ledBinds = config.led ? [
            '/sys/devices/platform/leds/leds/player-red:/leds/player-red:rw',
            '/sys/devices/platform/leds/leds/player-green:/leds/player-green:rw',
            '/sys/devices/platform/leds/leds/player-blue:/leds/player-blue:rw',
            '/sys/devices/platform/leds/leds/status-red:/leds/status-red:rw',
            '/sys/devices/platform/leds/leds/status-green:/leds/status-green:rw',
            '/sys/devices/platform/leds/leds/status-blue:/leds/status-blue:rw',
            '/sys/devices/platform/leds/leds/setup-green:/leds/setup-green:rw'
        ] : [];
        const cx2000VideoAccelerationBinds = config.cx2000VideoAcceleration ? [
            '/dev:/dev:ro'
        ] : [];
        const allBinds = (0, _lodash.union)((config.storage || []).filter((b)=>!(0, _lodash.isEmpty)(b)).map(({ key , value  })=>{
            const binding = getBinding(`${key}:${value}`);
            return binding.normalized;
        }), ((_config_devices1 = config.devices) === null || _config_devices1 === void 0 ? void 0 : _config_devices1.sound) ? [
            '/etc/asound.conf:/etc/asound.conf',
            '/dev:/dev:ro'
        ] : [], ((_config_devices2 = config.devices) === null || _config_devices2 === void 0 ? void 0 : _config_devices2.hdmi) ? [
            '/tmp/.X11-unix:/tmp/.X11-unix'
        ] : [], config.removableMedia ? [
            '/run/removable_media:/media:shared'
        ] : [], ((_config_devices3 = config.devices) === null || _config_devices3 === void 0 ? void 0 : _config_devices3.cameras) ? [
            '/dev:/dev:ro'
        ] : [], dockerInDockerBinds, (config.binds || []).filter((b)=>!(0, _lodash.isEmpty)(b)).map((bind)=>`${bind.hostPath}:${bind.containerPath}`), deviceBinds, cx2000IRRemoteBinds, usbSerialConverterBinds, gpioBinds, ledBinds, cx2000VideoAccelerationBinds, customHostConfig.Binds || []);
        // Filter duplicates in Binds, but only based on name and path (ignore permissions)
        const Binds = allBinds.reduce((binds, bind)=>{
            const binding = getBinding(bind);
            // If the bind name and path match an existing entry, replace it
            const hasDuplicates = binds.some((existingBind)=>{
                const otherBinding = getBinding(existingBind);
                return otherBinding.binding === binding.binding && otherBinding.path === binding.path;
            });
            if (hasDuplicates) return binds;
            return [
                ...binds,
                binding.normalized
            ];
        }, []);
        // CgroupRules
        const soundRules = ((_config_devices4 = config.devices) === null || _config_devices4 === void 0 ? void 0 : _config_devices4.sound) ? [
            'c 116:* rmw'
        ] : [];
        const deviceCgroupRules = (config.addedDevice || []).filter((device)=>{
            return (0, _lodash.isFinite)(device === null || device === void 0 ? void 0 : device.majorNumber);
        }).map((device)=>`c ${device.majorNumber}:* rmw`);
        const cx2000IRRemoteRules = config.cx2000IRRemote ? [
            'c 13:* rmw'
        ] : [];
        const usbSerialConverterRules = config.usbSerialConverter ? [
            'c 188:* rmw',
            'c 189:* rmw'
        ] : [];
        const cx2000VideoAccelerationRules = config.cx2000VideoAcceleration ? [
            'c 10:* rmw',
            'c 29:* rmw',
            'c 226:* rmw',
            'c 242:* rmw',
            'c 249:* rmw',
            'c 252:* rmw'
        ] : [];
        const usbCameraRules = ((_config_devices5 = config.devices) === null || _config_devices5 === void 0 ? void 0 : _config_devices5.cameras) ? [
            'c 81:* rmw'
        ] : [];
        const dedupedCgroupRules = (0, _lodash.sortBy)((0, _lodash.uniqBy)([
            ...customHostConfig.DeviceCgroupRules || [],
            ...usbCameraRules,
            ...soundRules,
            ...deviceCgroupRules,
            ...cx2000IRRemoteRules,
            ...usbSerialConverterRules,
            ...cx2000VideoAccelerationRules
        ], (rule)=>{
            const { major , minor  } = parseCGroupRule(rule);
            return `${major}:${minor}`;
        }), (rule)=>{
            const { major  } = parseCGroupRule(rule);
            return parseInt(major, 10);
        });
        const DeviceCgroupRules = !(0, _lodash.isEmpty)(dedupedCgroupRules) ? dedupedCgroupRules : undefined;
        const allEnv = (0, _lodash.uniq)([
            ...hdmi
        ]);
        const Env = allEnv.length > 0 ? allEnv : undefined;
        const skillVersionPort = config.port;
        const calculatedCreateOptions = _object_spread._(_object_spread_props._(_object_spread._({}, parsed || {}), {
            HostConfig: _object_spread._(_object_spread_props._(_object_spread._({}, customHostConfig || {}, deviceFamily === 'vision' ? {
                runtime: 'nvidia'
            } : {}), {
                Binds,
                DeviceCgroupRules,
                Privileged: config.privileged || undefined,
                CapAdd: ((_config_capAdd = config.capAdd) === null || _config_capAdd === void 0 ? void 0 : _config_capAdd.length) > 0 ? config.capAdd.filter((c)=>{
                    var _c_capability;
                    return c && ((_c_capability = c.capability) === null || _c_capability === void 0 ? void 0 : _c_capability.trim());
                }).map((c)=>c.capability) : undefined,
                CapDrop: ((_config_capDrop = config.capDrop) === null || _config_capDrop === void 0 ? void 0 : _config_capDrop.length) > 0 ? config.capDrop.filter((c)=>{
                    var _c_capability;
                    return c && ((_c_capability = c.capability) === null || _c_capability === void 0 ? void 0 : _c_capability.trim());
                }).map((c)=>c.capability) : undefined,
                Devices: (0, _lodash.unionBy)(((_config_devices6 = config.devices) === null || _config_devices6 === void 0 ? void 0 : _config_devices6.sound) ? [
                    {
                        CgroupPermissions: 'rwm',
                        PathInContainer: '/dev/snd',
                        PathOnHost: '/dev/snd'
                    }
                ] : [], [
                    ...gpioDevice
                ], customHostConfig.Devices || [], (d)=>`${d.PathInContainer}${d.PathOnHost}`),
                StorageOpt: writableRootFS ? {
                    size: `${writableRootFS}k`
                } : undefined,
                PortBindings: _object_spread._({}, customHostConfig.PortBindings || {}, (portBindings === null || portBindings === void 0 ? void 0 : portBindings.length) > 0 ? portBindings.reduce((bindings, binding)=>_object_spread_props._(_object_spread._({}, bindings), {
                        [`${binding.containerPort}/${binding.protocol}`]: [
                            {
                                HostPort: `${binding.hostPort}`
                            }
                        ]
                    }), {}) : {}, isPreview && !!skillVersionPort ? {
                    [`${skillVersionPort}/tcp`]: [
                        {
                            HostPort: '<User Selected Port>'
                        }
                    ]
                } : {}, !isPreview && !!skillVersionPort && !!workflowSkillPort ? {
                    [`${skillVersionPort}/tcp`]: [
                        _object_spread._({
                            HostPort: `${workflowSkillPort}`
                        }, localhostOnly ? {
                            HostIp: '127.0.0.1'
                        } : {})
                    ]
                } : {}),
                Mounts: (0, _lodash.unionBy)([
                    ...(tmpfs || []).filter((t)=>!(0, _lodash.isEmpty)(t)).map((t)=>_object_spread._({
                            Type: 'tmpfs',
                            Target: t.containerDevicePath
                        }, t.sizeBytes ? {
                            TmpfsOptions: {
                                SizeBytes: t.sizeBytes
                            }
                        } : {})),
                    ...(mountVolumes || []).filter((t)=>!(0, _lodash.isEmpty)(t)).map((volume)=>({
                            Type: 'volume',
                            Source: volume.volumeName,
                            Target: volume.mountPath,
                            VolumeOptions: {
                                DriverConfig: {
                                    Options: {
                                        size: volume.sizeLimit
                                    }
                                }
                            }
                        }))
                ], customHostConfig.Mounts || [], (m)=>`${m.Target}${m.Source}${m.Type}`)
            }), shmSize ? {
                ShmSize: shmSize
            } : {}, config.hostNetworking ? {
                NetworkMode: 'host'
            } : {}),
            Env
        }), !(0, _lodash.isEmpty)(hostName) ? {
            Hostname: hostName
        } : {}, (endpointAliases === null || endpointAliases === void 0 ? void 0 : endpointAliases.length) > 0 || config.hostNetworking ? {
            NetworkingConfig: {
                EndpointsConfig: _object_spread._({}, (parsed === null || parsed === void 0 ? void 0 : (_parsed_NetworkingConfig = parsed.NetworkingConfig) === null || _parsed_NetworkingConfig === void 0 ? void 0 : _parsed_NetworkingConfig.EndpointsConfig) || {}, config.hostNetworking ? {
                    host: {}
                } : {}, (endpointAliases === null || endpointAliases === void 0 ? void 0 : endpointAliases.length) > 0 ? {
                    'azure-iot-edge': {
                        Aliases: (0, _lodash.union)(endpointAliases.filter((t)=>!(0, _lodash.isEmpty)(t)).map((a)=>{
                            return a === null || a === void 0 ? void 0 : a.alias;
                        }), (parsed === null || parsed === void 0 ? void 0 : (_parsed_NetworkingConfig1 = parsed.NetworkingConfig) === null || _parsed_NetworkingConfig1 === void 0 ? void 0 : (_parsed_NetworkingConfig_EndpointsConfig = _parsed_NetworkingConfig1.EndpointsConfig) === null || _parsed_NetworkingConfig_EndpointsConfig === void 0 ? void 0 : (_parsed_NetworkingConfig_EndpointsConfig_azureiotedge = _parsed_NetworkingConfig_EndpointsConfig['azure-iot-edge']) === null || _parsed_NetworkingConfig_EndpointsConfig_azureiotedge === void 0 ? void 0 : _parsed_NetworkingConfig_EndpointsConfig_azureiotedge.Aliases) || [])
                    }
                } : {})
            }
        } : {});
        return _createOptionsSchema.default.safeParseAsync(calculatedCreateOptions).then(({ error  })=>{
            return {
                data: calculatedCreateOptions,
                errors: (error === null || error === void 0 ? void 0 : error.errors) || []
            };
        }).catch((err)=>{
            return {
                data: calculatedCreateOptions,
                errors: [
                    err === null || err === void 0 ? void 0 : err.message
                ]
            };
        });
    });
    return _parseSkillCreateOptions.apply(this, arguments);
}
