import { Aperture } from '@phosphor-icons/react';
import { flatMap, uniq, groupBy } from 'lodash';
import { useState } from 'react';
import { useController } from 'react-hook-form';

import {
  ButtonWrap,
  Table,
  UiState,
  WhatsThis,
  Modal,
  Button,
  Icon,
  IconButton,
  Text,
} from '@optra/kit';

import DeviceImage from 'components/device-image';
import DevicesChooser from 'components/devices-chooser';
import Label from 'components/label';
import { api, q, useOnSuccess } from 'config/api';

export default function SkillBuilderDeviceSelect(props) {
  const { rules, name, control, projectId, skillId } = props;
  const controller = useController({
    control,
    rules,
    name: name || 'devices',
    defaultValue: {
      push: [],
      pull: [],
    },
  });
  const currentValue = controller.field?.value;
  const [additionalDevices, setAdditionalDevices] = useState([]);
  const [loadingSelected, setLoadingSelected] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const openSelector = () => setIsOpen(true);
  const closeSelector = () => setIsOpen(false);

  const [filter, setFilter] = useState({});

  const handleSelectDevice = (selected, device) => {
    if (selected) {
      controller.field.onChange({
        push: uniq(currentValue.push.filter(d => d !== device.id)),
        pull: uniq([...currentValue.pull, device.id]),
      });
    } else {
      controller.field.onChange({
        push: uniq([...currentValue.push, device.id]),
        pull: uniq(currentValue.pull.filter(d => d !== device.id)),
      });
    }
  };

  const devices = q.useInfiniteQuery({
    queryKey: ['devices', 'skillBuilder', 'select', filter, skillId, projectId],
    queryFn({ pageParam }) {
      return api(
        `query skillBuilderDeviceSelect($list: listFields ${skillId ? ', $skillId: ID!' : ''} ${
          projectId ? ', $projectId: ID!' : ''
        }) {
          total: devices {
            count
          }
          list: devices(list: $list) {
            data {
              id
              name
              model
              ${projectId ? 'selected: belongsToEdgeImpulseProject(projectId: $projectId)' : ''}
              ${skillId ? 'selected: belongsToEdgeImpulseSkill(skillId: $skillId)' : ''}
            }
            cursor {
              after
            }
          }
        }`,
        {
          skillId,
          projectId,
          list: {
            filter,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
  });
  const deviceList = flatMap(devices.data?.pages, page => page?.list?.data);
  const devicesById = groupBy([...deviceList, ...additionalDevices], 'id');

  const selectedDevicesQuery = q.useQuery({
    queryKey: ['devices', 'skillBuilder', 'selected', skillId, projectId],
    async queryFn() {
      const getPage = async ({ after } = {}) => {
        const r = await api(
          `query Devices($list: listFields, ${projectId ? ', $projectId: ID!' : ''} ${
            skillId ? ', $skillId: ID!' : ''
          } ) {
              devices(list: $list) {
                count
                cursor {
                  after
                }
                data {
                  ${projectId ? 'selected: belongsToEdgeImpulseProject(projectId: $projectId)' : ''}
                  ${skillId ? 'selected: belongsToEdgeImpulseSkill(skillId: $skillId)' : ''}
                  id
                  name
                  model
                }
              }
            }`,
          {
            projectId,
            skillId,
            list: {
              filter: { deleted: false },
              cursor: { after },
            },
          },
        );

        return {
          after: r.devices?.cursor?.after,
          data: r.devices?.data.map(device => device).filter(device => device.selected),
        };
      };

      const getAllPages = async ({ devices = [], after } = {}) => {
        const r = await getPage({ after });
        const nextDevices = [...devices, ...r.data];
        if (r.after) {
          return getAllPages({ devices: nextDevices, after: r.after });
        }
        return nextDevices;
      };

      return getAllPages();
    },
    placeholderData: q.keepPreviousData,
    enabled: (!!skillId || !!projectId) && loadingSelected,
  });

  useOnSuccess(
    () => {
      setLoadingSelected(false);
      setAdditionalDevices(selectedDevicesQuery.data);
      controller.field.onChange({
        push: uniq([...currentValue.push, ...selectedDevicesQuery.data.map(({ id }) => id)]),
        pull: currentValue.pull,
      });
    },
    { isSuccess: selectedDevicesQuery.isSuccess },
    [controller.field, currentValue.pull, currentValue.push, selectedDevicesQuery.data],
  );

  return (
    <>
      <div>
        <div className="flex items-center justify-between mb-3">
          <div>
            <Label htmlFor="devices">Data Collection Devices</Label>
            <WhatsThis>These devices will gather data for the skill's underlying model</WhatsThis>
          </div>
          <IconButton icon="Plus" variant="tertiary" onClick={openSelector} />
        </div>
        {selectedDevicesQuery.isLoading && (
          <UiState variant="loading" text="Loading Devices" icon={{ component: Aperture }} />
        )}
        {!selectedDevicesQuery.isLoading && currentValue.push?.length === 0 && (
          <UiState variant="empty" text="No Devices" icon={{ component: Aperture }}>
            <Button size="xs" variant="tertiary" icon="Plus" onClick={openSelector}>
              ADD
            </Button>
          </UiState>
        )}
        {!selectedDevicesQuery.isLoading && currentValue.push?.length > 0 && (
          <Table>
            <Table.Body>
              {currentValue.push?.map?.(deviceId => {
                const device = devicesById[deviceId][0];
                return (
                  <Table.Row key={deviceId}>
                    <Table.TD collapsed>
                      <DeviceImage size="sm" hideStatus />
                    </Table.TD>
                    <Table.TD>
                      <Text size="sm">{device.name}</Text>
                      {/* <DetailList details={[deviceId]} /> */}
                    </Table.TD>
                    <Table.TD collapsed>
                      <ButtonWrap
                        as="div"
                        className="opacity-50 hover:opacity-100"
                        onClick={() => handleSelectDevice(true, device)}
                      >
                        <Icon name="XCircle" size="sm" weight="duotone" />
                      </ButtonWrap>
                    </Table.TD>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </div>
      <Modal isOpen={isOpen} onClose={closeSelector}>
        <Modal.Header heading="Choose Devices" />
        <Modal.Body>
          <DevicesChooser
            isLoading={devices?.isLoading || selectedDevicesQuery.isLoading}
            devices={deviceList}
            showEnrollmentButton={devices.data?.pages?.[0]?.total?.count === 0}
            error={devices.error}
            hasNextPage={devices?.hasNextPage}
            fetchNextPage={devices?.fetchNextPage}
            isFetchingNextPage={devices?.isFetchingNextPage}
            onSelectDevice={handleSelectDevice}
            filter={filter}
            onFilter={setFilter}
            newSelectedDevices={currentValue.push}
            newDeselectedDevices={currentValue.pull}
            RowDataComponent={({ device }) => (
              <>
                <Table.TD collapsed>
                  <DeviceImage model={device.model} size="sm" hideStatus />
                </Table.TD>
                <Table.TD>
                  <Text size="sm">{device.name}</Text>
                  {/* <DetailList details={[device.id]} /> */}
                </Table.TD>
              </>
            )}
          />
        </Modal.Body>
        <Modal.Footer className="justify-center">
          <Button size="xl" loading={devices?.isLoading} onClick={closeSelector}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
