import { groupBy, isString } from 'lodash';
import { Outlet } from 'react-router-dom';

import { PageWrapper, DetailHeading, NavList, Button } from '@optra/kit';

import { useFeatures } from 'components/feature';

const tabs = [
  { name: 'Organizations', path: '/admin/organizations', icon: 'Buildings' },
  { name: 'Users', path: '/admin/users', icon: 'UserCircle' },
  { name: 'Super Users', path: '/admin/super-users', icon: 'UserCircleGear' },
  { name: 'Service Accounts', path: '/admin/service-accounts', icon: 'Robot' },
  { name: 'Firmware', path: '/admin/firmware', icon: 'Cpu' },
  { name: 'Provisioning', path: '/admin/provisioning', icon: 'GitFork' },
  {
    name: 'Preclaimed Devices',
    path: '/admin/preclaimed-devices',
    icon: 'Receipt',
    requiresFeature: 'preclaimedDevices',
  },
  { name: 'Usage', path: '/admin/usage', icon: 'Activity' },
  {
    name: 'Marketplace',
    path: '/admin/marketplace',
    icon: 'SquaresFour',
  },
  {
    name: 'Usage Credits',
    path: '/admin/usage-credits',
    icon: 'Key',
    requiresFeature: 'resellerCodes',
  },
  { name: 'Features', path: '/admin/features', icon: 'ListChecks' },
  { name: 'Agents', path: '/admin/agents', icon: 'Cube' },
  { name: 'Skill Builder Content', path: '/admin/skill-builder-content', icon: 'PuzzlePiece' },
];

export default function AdminLayout() {
  const features = useFeatures(tabs.map(tab => tab.requiresFeature).filter(isString), 'global');
  const featuresByName = groupBy(features, 'name');

  return (
    <PageWrapper
      title="Admin"
      restoreScroll={false}
      components={{
        leftToggle: (
          <Button variant="secondary" size="sm" as="span">
            MENU
          </Button>
        ),
        left: ({ close }) => (
          <div className="w-48 animate-fade-in space-y-6">
            <DetailHeading variant="decorated">Admin</DetailHeading>
            <NavList>
              {tabs
                .filter(({ requiresFeature }) => {
                  if (!isString(requiresFeature)) return true;
                  return featuresByName[requiresFeature]?.[0]?.isActive;
                })
                .map(({ name, path, icon }) => {
                  return (
                    <NavList.Item key={path} to={path} icon={icon} onClick={close}>
                      {name}
                    </NavList.Item>
                  );
                })}
            </NavList>
          </div>
        ),
      }}
    >
      <Outlet />
    </PageWrapper>
  );
}
