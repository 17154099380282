import ModelsSelect from 'components/models/models-select';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function EdgeImpulseClassificationInferenceEngine(props) {
  const { nodeId } = props || {};
  const [node, { update }] = usePipelineNode(nodeId);

  return (
    <ModelsSelect
      value={node?.meta?.modelId}
      onChange={(selected, model) => {
        if (!selected) {
          update({
            key: 'meta',
            value: {
              modelId: `${model.id}`,
              labels: model.labels,
            },
          });

          update({
            key: 'config.model',
            value: `file:///app/skill/config/${model.id}.eim`,
          });
        } else {
          update({
            key: 'meta',
            value: null,
          });

          update({
            key: 'config.model',
            value: `file:///app/skill/config/model.eim`,
          });
        }
      }}
    />
  );
}
