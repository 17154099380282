import { ChartLine } from '@phosphor-icons/react';
import { isString, clamp, isFunction, truncate } from 'lodash';
import { useId } from 'react';
import { Legend, Tooltip, ComposedChart } from 'recharts';

import { UiState } from '@optra/kit';

import { useChartCtx, useChartInterface, useChartType } from 'modals/chart-details/context';
import { CHART_CONSTANTS, CONSTANTS } from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

import ChartAxisTick from './chart-axis-tick';
import ChartDot from './chart-dot';
import ChartLegend from './chart-legend';
import ChartTooltip from './chart-tooltip';

export default function ChartBase(props) {
  const {
    type,
    children,
    chartData: passedChartData,
    chartProps: passedChartProps,
    xAxisProps: passedXAxisProps,
    yAxisProps: passedYAxisProps,
    groupProps: passedGroupProps,
    tooltipProps: passedTooltipProps,
    legendProps: passedLegendProps,
    components = {},
    onClick,
    ...rest
  } = props;
  const { MAX_LABEL_LENGTH } = CONSTANTS;
  const {
    configuration: { showInterface },
  } = useChartCtx();
  const {
    state: { isMobile },
    actions: { setShowDataTable },
  } = useChartInterface();
  const {
    state: { chartData: _chartData, disabledGroups },
    helpers: { getColorByIndex },
    actions: { selectCategory },
  } = useChartType();

  const uniquId = useId();

  //////////
  // DATA //
  //////////

  const chartData = passedChartData || _chartData;

  if (!chartData) return <UiState center />;

  const { list: data, groups, groupKeys, uniqueCategories } = chartData;

  if (!data?.length || !groupKeys?.length) {
    return (
      <UiState
        variant="empty"
        icon={{
          component: ChartLine,
        }}
        text="No data"
        center
      />
    );
  }

  ///////////////////
  // CONFIGURATION //
  ///////////////////

  const chartProps = {
    ...rest,
    data,
    onClick: isFunction(onClick)
      ? onClick
      : data => {
          selectCategory(data?.activeLabel);
          if (isString(data?.activeLabel)) {
            setShowDataTable(true);
          }
        },
    ...(passedChartProps || {}),
    margin: showInterface
      ? { top: 0, left: -10, right: 0, bottom: 10, ...(passedChartProps?.margin || {}) }
      : { left: -8, ...(passedChartProps?.margin || {}) },
  };

  const longestCategoryLabelLength = truncate(helpers.getLongestString(uniqueCategories), {
    length: MAX_LABEL_LENGTH,
  }).length;

  const xAxisProps = {
    type: 'category',
    dataKey: 'category',
    interval: 0,
    axisLine: false,
    tickLine: false,
    angle: 45,
    height: clamp(longestCategoryLabelLength * 5.75, 90, 9999),
    allowDataOverflow: true,
    ...(passedXAxisProps || {}),
  };

  const yAxisProps = {
    scale: 'linear',
    domain: [min => (min < 0 ? min * 1.15 : 0), max => max * 1.15],
    axisLine: false,
    tickSize: 0,
    ...(passedYAxisProps || {}),
  };

  ////////////////
  // COMPONENTS //
  ////////////////

  const { axisTick = <ChartAxisTick /> } = components;

  const {
    main = ComposedChart,
    tooltip = <ChartTooltip {...(passedTooltipProps || {})} />,
    legend = <ChartLegend {...(passedLegendProps || {})} />,
    dot = <ChartDot />,
  } = components;

  //////////
  // MAIN //
  //////////

  const provided = {
    id: uniquId,
    groups,
    groupKeys: groupKeys?.map(groupKey =>
      disabledGroups?.find?.(group => group.value === groupKey) ? undefined : groupKey,
    ),
    getColorByIndex,
    groupProps: {
      isAnimationActive: false,
      connectNulls: true,
      ...(passedGroupProps || {}),
      dot,
    },
    xAxisProps: {
      ...xAxisProps,
      stroke: CHART_CONSTANTS.STROKE,
      tick: axisTick,
    },
    yAxisProps: {
      ...yAxisProps,
      stroke: CHART_CONSTANTS.STROKE,
      tick: axisTick,
    },
    ...CHART_CONSTANTS,
  };

  const MainComponent = main;

  return (
    <MainComponent {...chartProps}>
      {isFunction(children) ? children(provided) : children}
      {!!tooltip && (
        <Tooltip
          content={tooltip}
          wrapperStyle={{ outline: 'none' }}
          animationDuration={0}
          cursor={{ fill: CHART_CONSTANTS.FILL, stroke: CHART_CONSTANTS.STROKE }}
          filterNull={false}
        />
      )}
      {showInterface && legend && (
        <Legend
          layout="vertical"
          verticalAlign={isMobile ? 'bottom' : 'top'}
          width={isMobile ? '100%' : undefined}
          align={isMobile ? 'center' : 'right'}
          content={legend}
        />
      )}
    </MainComponent>
  );
}
