export const STATUS_MAP = {
  inProgress: 'pending',
  queued: 'updating',
  retry: 'updating',
  paused: 'updating',
  error: 'disabled',
  canceled: 'disabled',
  complete: 'complete',
};

export const STATUS_LABEL_MAP = {
  inProgress: 'Syncing...',
  error: 'Failed',
  queued: 'Waiting...',
  complete: 'Complete',
  canceled: 'Canceled',
  retry: 'Retrying...',
  paused: 'Paused',
};
