import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { generate } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'LabelAverageProcessor',
  label: 'Average',
  icon: 'ChartLine',
  description: 'Track the average count for each class label over a given window of frames.',
  type: 'processors',
  inputs: [
    {
      label: 'Label Counts',
      key: 'label_counts',
      type: DATA_TYPES['NumberList'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'window_averages',
      label: 'Averages',
      type: DATA_TYPES['DecimalList'],
      labelBehavior: generate(node => {
        const selectedLabels = node?.config?.label_list || [];
        return [...selectedLabels];
      }),
    },
    {
      key: 'rounded_window_averages',
      label: 'Rounded Averages',
      type: DATA_TYPES['NumberList'],
      labelBehavior: generate(node => {
        const selectedLabels = node?.config?.label_list || [];
        return [...selectedLabels];
      }),
    },
    {
      key: 'window_winner',
      label: 'Winner',
      type: DATA_TYPES['Text'],
      labelBehavior: generate(node => {
        const selectedLabels = node?.config?.label_list || [];
        return [...selectedLabels, 'none'];
      }),
    },
  ],
  defaultConfig: {
    windowSize: 10,
    label_list: [],
  },
  schema: z.object({
    config: z.object({ windowSize: z.number().min(0) }),
    inputs: z.object({ label_counts: z.string().min(1) }),
  }),
};
