import { api, q } from 'config/api';

export default function useOrganizations(filter) {
  return q.useInfiniteQuery({
    queryKey: ['organizations', filter],
    queryFn({ pageParam }) {
      return api(
        `query organizations($list: listFields) {
          list: organizations(list: $list) {
            count
            data {
              id
              name
              parentOrganizations {
                data {
                  parent {
                    id
                    name
                  }
                }
              }
              childOrganizations {
                data {
                  child {
                    id
                    name
                  }
                }
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            filter,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => data.pages.flatMap(page => page.list.data),
  });
}
