import { noop, isEmpty } from 'lodash';
import { useEffect } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/ext-language_tools';

// NOTE: completions={{ table: ['one', 'two', 'three'] }}

export default function SQLInput(props) {
  const { value, onChange = noop, placeholder = '', completions } = props;

  useEffect(() => {
    if (!isEmpty(completions)) {
      const langTools = window.ace.require('ace/ext/language_tools');
      Object.entries(completions).forEach(([key, _definitions]) => {
        const definitions = (_definitions || []).map(value => ({
          value,
          caption: value,
          meta: key,
        }));
        langTools.addCompleter({
          getCompletions: (editor, session, pos, prefix, callback) => {
            callback(null, definitions);
          },
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="h-full w-full relative">
      <AceEditor
        mode="sql"
        // TODO: Switch theme based on light/dark theme
        theme="terminal"
        value={value}
        placeholder={placeholder}
        height="100%"
        width="100%"
        tabSize={2}
        onChange={onChange}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
        }}
      />
    </div>
  );
}
