import noop from 'lodash/noop';
import { useParams } from 'react-router-dom';

import { Button } from '@optra/kit';

import { api, q } from 'config/api';

export default function DeprecateMarketplaceSkillVersionButton(props) {
  const { versions = [], marketplaceVersions = [], form, setError = noop } = props;
  const qc = q.useQueryClient();
  const { skillId } = useParams();
  const formValues = form.watch();

  const selectedVersion = versions?.find(v => v.id === formValues.versionId);
  const selectedMarketplaceSkillVersion = marketplaceVersions?.find(
    v => v.originSkillVersionId === selectedVersion?.id,
  );

  const enabled = versions?.length > 1 && marketplaceVersions?.length > 0;
  const deprecated = selectedMarketplaceSkillVersion?.deprecated || false;

  const deprecateMarketplaceSkill = q.useMutation({
    mutationFn: async form => {
      const { id, deprecated } = form;

      return api(
        `mutation deprecateMarketplaceSkillVersion($form: deprecateMarketplaceSkillVersionForm!) {
          marketplaceSkillVersion: deprecateMarketplaceSkillVersion(form: $form) {
            id
          }
        }`,
        {
          form: {
            id,
            deprecated,
          },
        },
      );
    },
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      qc.invalidateQueries({ queryKey: ['marketplaceSkills'] });
    },
    onError(err) {
      setError(err.message);
    },
  });

  const handleDeprecate = async event => {
    event.stopPropagation();
    event.preventDefault();

    const message = deprecated ? `undeprecate` : `deprecate`;

    if (
      window.confirm(
        `Are you sure you want to ${message} this skill version (${selectedMarketplaceSkillVersion?.version})?`,
      )
    ) {
      deprecateMarketplaceSkill.mutate({
        id: selectedMarketplaceSkillVersion?.id,
        deprecated: !deprecated,
      });
    }
  };

  if (!enabled) return null;

  return (
    <Button
      onClick={handleDeprecate}
      loading={deprecateMarketplaceSkill?.isPending}
      variant="secondary"
      className="w-full"
      icon={deprecated ? 'ArrowArcLeft' : 'ArchiveBox'}
    >
      {deprecated ? 'Undeprecate' : 'Deprecate'} Version
    </Button>
  );
}
