import { api, q } from 'config/api';

export default function useSkillBuilderBetaNode(id, overrides) {
  return q.useQuery({
    queryKey: ['skillBuilderBetaNode', id],
    queryFn: () =>
      api(
        `query skillBuilderBetaNode($id: ID!) {
          skillBuilderBetaNode(id: $id) {
            id
            name
            label
            description
            icon
            type
            inputs
            outputs
            configFields
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
