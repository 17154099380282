import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillMetaFields from 'components/skill-meta-fields';
import { useInputFocus } from 'hooks';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';

export default function ConfigureSkillVersion() {
  const { form, saving, isNew } = usePipelineContext();
  const {
    control,
    formState: { errors },
    register,
    setFocus,
  } = form;
  useInputFocus(setFocus, 'name');

  return (
    <ModalInner as="form">
      <ModalTitle title="Configuration" icon="Cube" loading={saving} />
      <ModalBody className="space-y-4">
        <div>
          <SkillMetaFields
            loading={saving}
            register={register}
            control={control}
            errors={errors}
            showNewVersionToggle={!isNew}
            showTargetFamilyToggle
          />
        </div>
      </ModalBody>
    </ModalInner>
  );
}
