import { MagnifyingGlass } from '@phosphor-icons/react';
import { isEmpty, noop } from 'lodash';

import { SearchField, Dropdown, UiState, DetailHeading } from '@optra/kit';

import useDebouncedState from 'hooks/use-debounced-state';
import { useModels } from 'queries';

export default function ModelsDropdownSelect(props) {
  const {
    onChange = noop,
    value,
    placement = Dropdown.placement.BOTTOM_RIGHT,
    placeholderText = 'Select Model',
    size = 'sm',
    direction = 'desc',
    filter,
  } = props || {};

  const [search, setSearch, currentSearch] = useDebouncedState('');
  const Models = useModels({
    list: {
      sort: { direction },
      filter: {
        ...filter,
        ...(!isEmpty(search) ? { $search: search } : {}),
      },
    },
  });
  const selected = Models.data?.find?.(org => org.id === value);

  return (
    <Dropdown divide={false} scrolling placement={placement} className="inline-block">
      <Dropdown.Button size={size}>{selected?.name || placeholderText}</Dropdown.Button>
      <Dropdown.Body>
        <div className="px-3 space-y-2">
          <DetailHeading>Models</DetailHeading>
          <SearchField
            searching={Models.isRefetching && !Models.isFetchedAfterMount}
            value={currentSearch}
            onChange={setSearch}
            onClear={() => setSearch('')}
            placeholder="Search…"
            onKeyDown={e => e.stopPropagation()}
          />
        </div>
        <div>
          {Models.data?.map?.(model => (
            <Dropdown.Item
              key={model.id}
              text={model.name}
              detail={model.owner?.name}
              active={value === model.id}
              selectable
              hoverSubtle
              uppercase={false}
              className="!rounded-md"
              onClick={() => onChange(model)}
            />
          ))}
          {isEmpty(Models.data) && (
            <UiState
              variant="empty"
              icon={!isEmpty(currentSearch) ? { component: MagnifyingGlass } : undefined}
              text={!isEmpty(currentSearch) ? 'Nothing found...' : 'No Organizations'}
              size="sm"
            />
          )}
        </div>
      </Dropdown.Body>
    </Dropdown>
  );
}
