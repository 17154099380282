import cx from 'classnames';
import Color from 'color';
import { isEmpty, isNil, noop } from 'lodash';
import { useState, useEffect, useRef, forwardRef } from 'react';

import { Button, Icon, CONFIG } from '@optra/kit';

import SkillIcon from 'components/skill-icon';
import skillIcons from 'config/skill-icons';
import useParseFileUrl from 'lib/use-parse-file-url';

const iconCn = [
  'w-24',
  'h-24',
  'flex',
  'shrink-0',
  'items-center',
  'justify-center',
  'flex-shrink-0',
  'rounded-2xl',
];
const placeholderCn = ['w-24', 'h-24', 'opacity-100', 'shrink-0'];
const iconColorCn = [
  'h-6',
  'w-6',
  'rounded-md',
  'shadow-sm',
  'hover:ring-2',
  'hover:ring-white',
  'cursor-pointer',
  'relative',
];

function ColorOption(props) {
  const { value, active, onClick } = props;

  return (
    <div
      className={cx(iconColorCn, active ? ['ring-2', 'ring-white'] : [])}
      style={{ background: value }}
      onClick={onClick}
    >
      {active && (
        <div className="absolute inset-0 flex justify-center items-center">
          <Icon name="CheckCircle" size="xs" color="white" />
        </div>
      )}
    </div>
  );
}

export default forwardRef(function IconChooser(props, ref) {
  const {
    value = { icon: 'action', color: CONFIG.COLORS.green.DEFAULT, iconUrl: null },
    onChange: _onChange,
    loading,
    defaultTab: _defaultTab = 'preset',
    disabled,
    showColors = true,
    allowsUpload = true,
  } = props;

  const onChange = disabled ? noop : _onChange;

  const defaultTab = useRef(_defaultTab);
  const [tab, setTab] = useState(defaultTab.current);
  useEffect(() => {
    if (!loading) {
      const hasImage = !isEmpty(value?.iconUrl?.name) || !isEmpty(value?.iconUrl);
      setTab(hasImage ? 'upload' : defaultTab.current);
    }
  }, [loading, value?.iconUrl]);

  const presetsContainer = useRef();
  const iconRefs = useRef({});

  // NOTE: Changing sizes of buttons in any way requires updating the offset
  useEffect(() => {
    if (tab === 'preset') {
      presetsContainer.current.scrollLeft =
        (iconRefs.current?.[value?.icon]?.offsetLeft || 0) -
        presetsContainer.current.clientWidth / 2.3;
    }
  }, [value?.icon, tab]);

  const uploadIconInput = useRef();
  const iconUrl = useParseFileUrl(value?.iconUrl);

  const isCustomColor = ![
    CONFIG.COLORS.green.DEFAULT,
    CONFIG.COLORS.blue.DEFAULT,
    CONFIG.COLORS.red.DEFAULT,
    CONFIG.COLORS.yellow.DEFAULT,
  ].includes(value?.color);

  return (
    <div className="max-w-modal-sm">
      {allowsUpload && (
        <div className="flex flex-1 items-center justify-center pb-6">
          <Button
            variant={tab === 'preset' ? 'secondary' : 'tertiary'}
            onClick={() => {
              setTab('preset');
            }}
            size="xs"
            className="rounded-r-none"
            disabled={disabled}
          >
            Choose
          </Button>
          <Button
            variant={tab === 'upload' ? 'secondary' : 'tertiary'}
            onClick={() => setTab('upload')}
            size="xs"
            className="rounded-l-none relative"
            disabled={disabled}
          >
            Upload
          </Button>
        </div>
      )}

      <div className={tab !== 'preset' ? 'hidden' : ''}>
        {/* ICON */}
        <div className="scroll-smooth snap-x overflow-x-auto flex space-x-2" ref={presetsContainer}>
          <div className={cx(placeholderCn)} />
          <div className={cx(placeholderCn)} />
          <div className={cx(placeholderCn)} />

          {Object.entries(skillIcons).map(([v, iconName]) => (
            <div
              key={v}
              ref={el => (iconRefs.current[v] = el)}
              role="img"
              className={cx(
                iconCn,
                [
                  'snap-center',
                  'bg-gradient-to-r',
                  'from-primary-500',
                  'to-primary-400',
                  'text-white',
                  'cursor-pointer',
                ],
                value?.icon === v ? 'opacity-100' : ['opacity-50', 'hover:opacity-60'],
              )}
              style={{
                // NOTE: These override tailwind's from-* and to-*
                '--tw-gradient-from': Color(value?.color).darken(0.35),
                '--tw-gradient-to': value?.color,
              }}
              onClick={() => onChange({ ...value, icon: v })}
            >
              <Icon name={iconName} size="2xl" />
            </div>
          ))}

          <div className={cx(placeholderCn)} />
          <div className={cx(placeholderCn)} />
          <div className={cx(placeholderCn)} />
        </div>

        {/* ICON COLOR */}
        {showColors && (
          <div className="flex items-center justify-center space-x-2 mt-4">
            <ColorOption
              value={CONFIG.COLORS.green.DEFAULT}
              active={value?.color === CONFIG.COLORS.green.DEFAULT}
              onClick={() => onChange({ ...value, color: CONFIG.COLORS.green.DEFAULT })}
            />
            <ColorOption
              value={CONFIG.COLORS.blue.DEFAULT}
              active={value?.color === CONFIG.COLORS.blue.DEFAULT}
              onClick={() => onChange({ ...value, color: CONFIG.COLORS.blue.DEFAULT })}
            />
            <ColorOption
              value={CONFIG.COLORS.red.DEFAULT}
              active={value?.color === CONFIG.COLORS.red.DEFAULT}
              onClick={() => onChange({ ...value, color: CONFIG.COLORS.red.DEFAULT })}
            />
            <ColorOption
              value={CONFIG.COLORS.yellow.DEFAULT}
              active={value?.color === CONFIG.COLORS.yellow.DEFAULT}
              onClick={() => onChange({ ...value, color: CONFIG.COLORS.yellow.DEFAULT })}
            />

            <div
              className={cx(iconColorCn, isCustomColor ? ['ring-2', 'ring-white'] : 'bg-white')}
              style={{ ...(isCustomColor ? { background: value.color } : {}) }}
            >
              <div className="absolute inset-0 flex justify-center items-center">
                <Icon
                  name="CaretDown"
                  size="xs"
                  color={
                    isCustomColor ? `${Color(value?.color).isLight() ? 'black' : 'white'}` : 'gray'
                  }
                  weight="light"
                />
              </div>
              <input
                type="color"
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={e => onChange({ ...value, color: e.target.value })}
              />
            </div>
          </div>
        )}
      </div>

      {/* CUSTOM ICON */}
      {allowsUpload && (
        <div className={tab !== 'upload' ? 'hidden' : ''}>
          <div className="flex items-center justify-center space-x-2">
            <div
              className={cx(
                iconCn,
                'relative',
                'bg-light-bg-secondary text-light-fg-secondary dark:bg-dark-bg-secondary dark:text-dark-fg-secondary',
              )}
            >
              {isNil(value?.iconUrl) && <Icon name="CloudArrowUp" size="xl" />}
              {!isNil(value?.iconUrl) && <SkillIcon size="lg" iconUrl={iconUrl} />}
              <input
                ref={uploadIconInput}
                className="absolute inset-0 opacity-0 cursor-pointer"
                type="file"
                accept="image/*"
                onChange={e => onChange({ ...value, iconUrl: e.target.files?.[0] })}
              />
            </div>
          </div>
          <div className="flex items-center justify-center mt-4">
            <Button
              variant="tertiary"
              onClick={() => {
                uploadIconInput.current.value = '';
                onChange({ ...value, iconUrl: null });
              }}
              size="xs"
              disabled={disabled}
            >
              Clear
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
