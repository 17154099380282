import cx from 'classnames';
import { isFunction, truncate, isEmpty } from 'lodash';
import { Droppable } from 'react-beautiful-dnd';

import { AddPlaceholderButton, DetailList, Icon, InteractiveWrap, Tooltip } from '@optra/kit';

import { useChartCtx } from 'modals/chart-details/context';
import { VARIABLE_STATUS } from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

import VariableSelector from './variable-selector';

export function AdditemButton({ as = 'span', text = 'Add', className, ...rest }) {
  return (
    <AddPlaceholderButton
      as={as}
      text={text}
      className={cx('dark:border-transparent', className)}
      {...rest}
    />
  );
}

export function AddItemDropdown({ field, ...rest }) {
  const {
    actions: { addVariable },
  } = useChartCtx();

  // TODO: props should not be spread across multiple components
  return (
    <VariableSelector onSelect={id => addVariable(id, field)} {...rest}>
      <VariableSelector.Button>
        <AdditemButton {...rest} />
      </VariableSelector.Button>
    </VariableSelector>
  );
}

export function DraggableItem(props) {
  const { variable, details, className, onRemove, draggableProps } = props;
  const {
    helpers: { variableTableMatchesConfig },
  } = useChartCtx();

  const { provided, snapshot } = draggableProps;

  const Variable = helpers.parseVariable(variable);
  const id = Variable.column;
  const text = Variable.meta?.name || 'Select...';

  const isPending = isEmpty(Variable.meta.table) && Variable.type === 'metric';
  const error =
    id === VARIABLE_STATUS.error
      ? 'Invalid variable.'
      : !variableTableMatchesConfig(variable) && 'Tables do not match.';

  return (
    <span
      ref={provided.innerRef}
      snapshot={snapshot}
      {...provided.draggableProps}
      className={cx(
        'flex items-stretch justify-between rounded-md',
        'bg-white dark:bg-[#0F0F10]',
        'ring-1 ring-gray-200/70 dark:ring-transparent',
        'shadow-md dark:shadow-[#000]/30',
        className,
      )}
    >
      <InteractiveWrap
        as="span"
        {...provided.dragHandleProps}
        className={cx(
          'flex-0 flex items-center px-2',
          'bg-gray-200/50 dark:bg-[#000]/90',
          'rounded-tl-md rounded-bl-md',
          'text-black-300 dark:text-black-400',
        )}
      >
        <Icon name="DotsSixVertical" weight="bold" size="sm" />
      </InteractiveWrap>
      <span
        className={cx(
          'flex-1',
          'flex items-center w-full',
          'px-3 pr-0',
          !!details ? 'py-2' : 'py-3',
        )}
      >
        <span className="flex-1 flex flex-nowrap items-center space-x-2">
          {!!error && (
            <Tooltip label={error}>
              <Icon name="Warning" weight="regular" size="sm" className="text-red" />
            </Tooltip>
          )}
          <span className="flex flex-col">
            <Tooltip label={text} disabled={text?.length <= 26}>
              <span className="font-semibold text-sm leading-tight">
                {truncate(text, { length: 26 })}
              </span>
            </Tooltip>
            <DetailList details={details} size="xs" />
          </span>
        </span>
        {isPending && (
          <div className="flex-0">
            <Tooltip label="Table pending...">
              <Icon name="DotsThreeCircle" weight="regular" size="sm" className="text-blue" />
            </Tooltip>
          </div>
        )}
        <span className="flex-0">
          <Icon name="CaretDown" weight="regular" size="xs" className="text-gray-500" />
        </span>
      </span>
      <InteractiveWrap
        as="span"
        className={cx(
          'flex-0 flex items-center px-3',
          'text-gray-500 hover:text-red',
          'rounded-tr-md rounded-br-md',
        )}
        onClick={isFunction(onRemove) && onRemove}
      >
        <Icon name="TrashSimple" weight="duotone" size="xs" />
      </InteractiveWrap>
    </span>
  );
}

export function DroppableZone({ children, droppableId, ...rest }) {
  return (
    <div {...rest}>
      {isFunction(children) ? (
        <Droppable droppableId={droppableId} direction="vertical">
          {({ droppableProps, placeholder, innerRef }) =>
            children({
              placeholder,
              ref: innerRef,
              className: cx('DroppableZone', 'grid grid-cols-1 gap-2'),
              ...droppableProps,
            })
          }
        </Droppable>
      ) : (
        children
      )}
    </div>
  );
}
