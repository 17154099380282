import isFunction from 'lodash/isFunction';
import { createContext, useContext, useLayoutEffect, useMemo, useReducer, useRef } from 'react';
import { useMeasure, useWindowSize } from 'react-use';

import { useBreakpoint } from '@optra/kit';

import { useChartCtx } from './chart-context';

const ChartInterfaceContext = createContext();
const useChartInterface = () => useContext(ChartInterfaceContext);

///////////////////////
// STATE
///////////////////////

const INIT_STATE = {
  ui: {
    showSqlInput: false,
    showSettingsModal: false,
    showDuplicateModal: false,
    fullscreenChart: false,
    showMobileControls: false,
    showDataTable: false,
    showAllDetailDimensions: false,
    showDetailLineChart: true,
  },
};

const reducer = (state, { type, payload }) => {
  const { ui = {} } = state;
  switch (type) {
    case 'resetState':
      return INIT_STATE;
    case 'setState':
      return { ...state, ...payload };
    case 'setUI':
      return {
        ...state,
        ui: {
          ...ui,
          ...payload,
        },
      };
    default:
      return state;
  }
};

function ChartInterfaceProvider({ children }) {
  const {
    configuration: { showInterface },
    state: { loading, chartInitialized },
    queries,
  } = useChartCtx();

  const { thresholds } = useBreakpoint();
  const isMobile = !thresholds.lg;

  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  /////////////
  // ACTIONS //
  /////////////

  const actions = useMemo(
    () => ({
      setUI: payload =>
        dispatch({
          type: 'setUI',
          payload,
        }),
      setShowSettingsModal: showSettingsModal => actions.setUI({ showSettingsModal }),
      setShowDuplicateModal: showDuplicateModal => actions.setUI({ showDuplicateModal }),
      setShowSqlInput: showSqlInput => actions.setUI({ showSqlInput }),
      setFullScreenChart: fullscreenChart => actions.setUI({ fullscreenChart }),
      setShowMobileControls: showMobileControls => actions.setUI({ showMobileControls }),
      setShowDataTable: showDataTable => actions.setUI({ showDataTable }),
      setShowAllDetailDimensions: showAllDetailDimensions =>
        actions.setUI({ showAllDetailDimensions }),
      setShowDetailLineChart: showDetailLineChart => actions.setUI({ showDetailLineChart }),
      dispatch,
    }),
    [],
  );

  /////////////
  // EFFECTS //
  /////////////

  useLayoutEffect(() => {
    if (!showInterface) return;
    if (queries.chart.isLoading) return;
    if (!isMobile || chartInitialized) return;
    actions.setShowMobileControls(true);
  }, [queries.chart.isLoading, isMobile, chartInitialized, showInterface, actions]);

  useLayoutEffect(() => {
    if (!showInterface) return;
    if (!loading) return;
    actions.setShowDataTable(false);
  }, [loading, showInterface, actions]);

  //////////////
  // ELEMENTS //
  //////////////

  const chartRef = useRef(null);
  const legendRef = useRef(null);

  const windowSize = useWindowSize();
  const [topBarRef, topBarSize] = useMeasure();

  const refs = {
    topBar: topBarRef,
    chart: chartRef,
    legend: legendRef,
  };

  const measurements = {
    windowSize,
    topBarSize,
  };

  //////////////////////
  // CALCULATED STATE //
  //////////////////////

  const calculatedState = {
    isMobile,
  };

  //////////////
  // PROVIDER //
  //////////////

  const value = {
    state: {
      ...state,
      ...calculatedState,
    },
    measurements,
    actions,
    refs,
  };

  return (
    <ChartInterfaceContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </ChartInterfaceContext.Provider>
  );
}

export { ChartInterfaceContext, ChartInterfaceProvider, useChartInterface };
