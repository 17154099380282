import { isString } from 'lodash';

import { NODE_DEFS, DATA_TYPES } from 'modals/skill-pipeline-builder/config';

export default function getNodeDef(node, nodeId) {
  if (node?.meta && node?.meta?.isBetaNode) {
    // use this nodes `meta` as its NODE_DEF
    // and "hydrate" the data types for inputs and outputs
    return {
      ...node.meta,
      inputs: (node.meta?.inputs || []).map(input => ({
        ...input,
        type: isString(input.type) ? DATA_TYPES[input.type] : input.type,
      })),
      outputs: (node.meta?.outputs || []).map(output => ({
        ...output,
        type: isString(output.type) ? DATA_TYPES[output.type] : output.type,
      })),
    };
  }

  if (node?.name && NODE_DEFS[node.name]) {
    return NODE_DEFS[node.name];
  }

  if (nodeId && NODE_DEFS[nodeId]) {
    return NODE_DEFS[nodeId];
  }

  return {};
}
