import findPathDeep from 'deepdash/findPathDeep';
import { isEmpty } from 'lodash';
import set from 'lodash/fp/set';
import { useState } from 'react';

import { IconButton } from '@optra/kit';

import { api, q } from 'config/api';
import { useWorkspace } from 'queries';

export default function ChartWorkspaceStarButton(props) {
  const { id, size, value, controlled } = props;

  const [spring, setSpring] = useState(false);
  const Workspace = useWorkspace(id, {
    enabled: !controlled,
  });

  const qc = q.useQueryClient();
  const toggleWorkflowStar = q.useMutation({
    mutationFn: id =>
      api(
        `mutation toggleWorkspaceStar($id: ID!) {
          workspace: toggleWorkspaceStar(id: $id) {
            id
            starred
          }
        }`,
        { id },
      ),
    onMutate: async () => {
      setSpring(true);
      await qc.cancelQueries({ queryKey: ['workspace', id] });

      const next = !Workspace.data?.workspace?.starred;
      qc.setQueryData(['workspace', id], old => set('workspace.starred', next, old));
      qc.setQueriesData({ queryKey: ['workspaces'] }, old => {
        const workspacePath = findPathDeep(old, n => n === id);
        if (isEmpty(workspacePath)) return old;
        return set(`${workspacePath.replace('.id', '')}.starred`, next, old);
      });

      return next;
    },
    onError(err, form, prev) {
      qc.setQueryData(['workspace', id], old => set('workspace.starred', prev, old));
      qc.setQueriesData({ queryKey: ['workspaces'] }, old => {
        const workspacePath = findPathDeep(old, n => n === id);
        if (isEmpty(workspacePath)) return old;
        return set(`${workspacePath.replace('.id', '')}.starred`, prev, old);
      });
    },
    onSettled: () => {
      qc.invalidateQueries({ queryKey: ['workspace', id] });
      qc.invalidateQueries({ queryKey: ['workspaces'] });
    },
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflow', id] });
      qc.invalidateQueries({ queryKey: ['workflows'] });
    },
  });

  const isStarred = controlled ? value : Workspace.data?.workspace?.starred;

  return (
    <IconButton
      name="Star"
      color={isStarred ? 'yellow' : 'gray'}
      iconWeight="fill"
      variant="plain"
      size={size}
      className={`ml-2 ${spring && 'animate-spring'} ${!isStarred && 'opacity-40'}`}
      onAnimationEnd={() => setSpring(false)}
      onClick={e => {
        e.preventDefault();
        toggleWorkflowStar.mutate(id);
      }}
      disabled={Workspace.isLoading || toggleWorkflowStar.isPending}
      aria-pressed={isStarred}
    />
  );
}
