import * as Sentry from '@sentry/react';
import Lockr from 'lockr';
import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import initializeI18n from 'config/i18n/initializeI18n';
import * as serviceWorker from 'config/service-worker';

import App from './app';

const sentryEnabled =
  !['development', 'test'].includes(process.env.REACT_APP_ENVIRONMENT) ||
  process.env.REACT_APP_SENTRY_DSN;

if (sentryEnabled) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
  Sentry.setUser({ email: Lockr.get('auth')?.idTokenPayload?.email });
}

const SentryApp = sentryEnabled ? Sentry.withProfiler(App) : App;

initializeI18n();
const root = createRoot(document.getElementById('root'));
root.render(<SentryApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
