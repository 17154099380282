import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { generate } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'EdgeImpulseObjectDetectionInferenceEngine',
  label: 'AI Detection',
  icon: 'CrosshairSimple',
  description: 'Performs inference on an input image using a detection AI model from Edge Impulse.',
  type: 'inference',
  inputs: [
    {
      label: 'Source Image',
      key: 'image',
      type: DATA_TYPES['Image'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'cropped_image',
      label: 'Cropped Image',
      type: DATA_TYPES['Image'],
    },
    {
      key: 'cropped_bounding_boxes',
      label: 'Cropped Bounding Boxes',
      type: DATA_TYPES['BoundingBoxes'],
      labelBehavior: generate(node => node?.meta?.labels || []),
    },
    {
      key: 'full_res_bounding_boxes',
      label: 'Bounding Boxes',
      type: DATA_TYPES['BoundingBoxes'],
      labelBehavior: generate(node => node?.meta?.labels || []),
    },
  ],
  defaultConfig: {
    model: 'file:///app/skill/config/model.eim',
  },
  schema: z.object({
    config: z.object({ model: z.string().url() }),
    inputs: z.object({ image: z.string().min(1) }),
    meta: z.object({ modelId: z.string().min(1) }), // require the user chooses a model
  }),
};
