import cx from 'classnames';
import pathToString from 'deepdash/pathToString';
import { isEmpty, isString } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { Card, Button, CopyButton, Icon, Tooltip, Spinner, Text } from '@optra/kit';

import Label from 'components/label';
import SkillJsonUploadButton from 'components/skill-json-upload-button';
import Textarea from 'components/textarea';
import ValidationError from 'components/validation-error';
import { q } from 'config/api';
import calculateRawConfig from 'lib/calculate-raw-config';
import { useCurrentUser } from 'queries';

function KeyText({ value }) {
  return (
    <span className="px-1 mb-1 font-mono rounded-md shadow-sm flex-nowrap bg-light-bg-secondary dark:bg-dark-bg-secondary">
      {value}
    </span>
  );
}

export default function RawConfig({ form, setError, loading, allowView = true }) {
  const { register, watch, setValue } = form;
  const [configOpen, setConfigOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [currentUser] = useCurrentUser();
  const bottomRef = useRef(null);

  const formValues = watch();

  const { data, isLoading } = q.useQuery({
    queryKey: ['createOptions', { formValues }],
    queryFn: () => calculateRawConfig({ form: formValues }),
    placeholderData: q.keepPreviousData,
  });

  const ready = !isLoading && !editing;

  const formatted = ready ? JSON.stringify(data?.data, null, 2) : '';

  const jsonErrors = ready
    ? data?.errors?.map?.((error, idx) => {
        if (isString(error)) {
          return error;
        }
        const { path, message } = error;
        return (
          <span key={idx} className="flex flex-wrap content-center">
            <KeyText value={pathToString(path)} />
            &nbsp;{message}
          </span>
        );
      })
    : [];

  // Scroll input into view
  useEffect(() => {
    configOpen && bottomRef?.current?.scrollIntoView();
  }, [configOpen]);

  const showControls = !isLoading && configOpen;

  return (
    <>
      <div className="pt-2 border-b border-light-bg-tertiary dark:border-dark-bg-primary" />
      <Card variant="secondary">
        <Label
          htmlFor="createOptions"
          onClick={() => {
            setEditing(false);
            setConfigOpen(c => !c);
          }}
          className="flex items-center cursor-pointer space-x-2"
        >
          <div className="flex-0">
            <Icon name="FileDotted" size="lg" color="gray" weight="regular" />
          </div>

          <div className="flex-1">
            <Text size="md">Raw Config</Text>
          </div>

          <div
            className={cx(
              'flex-0',
              'flex items-center',
              'space-x-2',
              showControls ? 'block' : 'hidden',
            )}
          >
            {currentUser?.isSysAdmin && (
              <SkillJsonUploadButton
                label="Upload"
                variant="tertiary"
                onError={setError}
                setValue={setValue}
                className={!editing ? 'block' : 'hidden'}
              />
            )}
            <CopyButton
              label="Copy"
              variant="tertiary"
              size="xl"
              value={formatted}
              beforeCopy={e => e.stopPropagation()}
              className={!editing ? 'block' : 'hidden'}
            />

            {currentUser?.isSysAdmin && (
              <Tooltip
                label={editing ? 'Stop editing and merge' : 'Edit customOptions'}
                hideOnClick={false}
              >
                <span>
                  <Button
                    variant="tertiary"
                    icon={editing ? 'CaretLeft' : 'Pencil'}
                    iconWeight="line"
                    size="xs"
                    onClick={e => {
                      e.stopPropagation();
                      setEditing(e => !e);
                    }}
                  >
                    {editing ? 'Done' : 'Edit'}
                  </Button>
                </span>
              </Tooltip>
            )}

            {!currentUser?.isSysAdmin && allowView && (
              <span>
                <Button
                  variant="tertiary"
                  icon={editing ? 'CaretLeft' : ''}
                  iconWeight="line"
                  size="xs"
                  onClick={e => {
                    e.stopPropagation();
                    setEditing(e => !e);
                  }}
                >
                  {editing ? 'Back' : 'View Custom Options'}
                </Button>
              </span>
            )}
          </div>
          {isLoading && configOpen && <Spinner color="gradient" />}
          <Icon name={configOpen ? 'CaretUp' : 'CaretDown'} weight="line" />
        </Label>
        {!isEmpty(jsonErrors) &&
          jsonErrors.map((e, idx) => (
            <ValidationError message={e} className="whitespace-pre-line mt-2" key={idx} />
          ))}
        <Textarea
          className={`mt-4 h-80 ${showControls && editing ? 'block' : 'hidden'}`}
          disabled={!currentUser.isSysAdmin}
          variant="logs"
          {...register('createOptions', {
            validate: value => {
              try {
                if (isEmpty(value)) return true;
                JSON.parse(value);
                return true;
              } catch (err) {
                setError(new Error(`Invalid JSON in Raw Config: ${err.message}`));
                return 'Invalid JSON!';
              }
            },
          })}
        />
        <Textarea
          className={`mt-4 h-80 ${showControls && !editing ? 'block' : 'hidden'}`}
          variant="logs"
          readOnly={true}
          value={formatted}
        />
      </Card>
      <div ref={bottomRef} className="!mt-0" />
    </>
  );
}
