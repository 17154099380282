import FeatureToggle from 'components/feature-toggle';
import { useChartCtx } from 'modals/chart-details/context';
import { TIMEFRAME_BINS } from 'modals/chart-details/data';

export default function ControlDiscardZeros({ variant = 'dark', disabled, ...rest }) {
  const {
    helpers: { getChartConfig },
    actions: { setDiscardZeros },
  } = useChartCtx();
  const { discardZeros, timeframeBin: _timeframeBin } = getChartConfig();
  const timeframeBin = TIMEFRAME_BINS[_timeframeBin];
  const currentGranularity = timeframeBin?.trunc_unit || timeframeBin?.unit;
  return (
    <FeatureToggle
      title="Discard Empty Data"
      description={
        currentGranularity
          ? `Ignore ${currentGranularity}s with the value '0'`
          : "Ignore data points with the value '0'"
      }
      variant={variant}
      disabled={disabled}
      {...rest}
      checked={!!discardZeros && !disabled}
      onChange={(e, checked) => setDiscardZeros(checked)}
    />
  );
}
