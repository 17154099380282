import cx from 'classnames';

import { Text } from '@optra/kit';

export default function TH({ children, className, variant = 'label', ...rest }) {
  return (
    <th className={cx(['px-4 py-2 whitespace-nowrap text-left', className])} {...rest}>
      <Text size="xs" color="muted" variant={variant}>
        {children}
      </Text>
    </th>
  );
}
