import { Route, Routes } from 'react-router-dom';
import { useTitle } from 'react-use';

import CreateTag from 'components/create-tag';
import EditTag from 'components/edit-tag';
import ModalInner from 'components/modal-inner';
import ManageTagsOverview from 'modals/manage-tags-overview';

export default function ManageTags() {
  useTitle('Manage Tags Overview | Optra');

  return (
    <ModalInner>
      <Routes>
        <Route index element={<ManageTagsOverview />} />
        <Route path="new" element={<CreateTag />} />
        <Route path=":tagId/edit" element={<EditTag />} />
      </Routes>
    </ModalInner>
  );
}
