import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10, schemeDark2, schemeSet1, schemeTableau10 } from 'd3-scale-chromatic';

import { CONFIG } from '@optra/kit';

import CALCULATIONS from './calculations';

export const SPACING = {
  X_PADDING: 'px-5 lg:px-9',
  Y_PADDING: 'py-5 lg:py-9',
  PADDING: 'px-5 lg:px-9 py-5 lg:py-9',
  X_SPACING: 'space-x-5 lg:space-x-7',
  Y_SPACING: 'space-y-5 lg:space-y-7',
  SPACING: 'space-x-5 lg:space-x-7 space-x-5 lg:space-x-7',
};

export const CONSTANTS = {
  TIMESTREAM_TIMESTAMP_FORMAT: 'YYYY-MM-DD H:mm:ss',
  SPLIT_SYMBOL: '$#',
  SPLIT_SYMBOL_2: '%&',
  SPLIT_SYMBOL_3: '*^',
  BORDER_COLOR: [
    'border-gray-200/70 dark:border-black-800',
    'divide-gray-200/70 dark:divide-black-800',
  ],
  WINDOW_BG: [
    'bg-gradient-to-b from-gray-100 via-white to-white',
    'dark:bg-gradient-to-b dark:from-black-900 dark:to-[#000]/70',
  ],
  PANEL_BG: 'bg-white dark:bg-black-900',
  PANEL_TRANSITION: 'transition-all ease-in-out duration-200',
  MAX_LABEL_LENGTH: 30,
  ...SPACING,
};

export const CHART_CONSTANTS = {
  COLORS: [
    CONFIG.COLORS.primary.DEFAULT,
    CONFIG.COLORS.blue.DEFAULT,
    ...scaleOrdinal(schemeDark2).range(),
    ...scaleOrdinal(schemeCategory10).range(),
    ...scaleOrdinal(schemeTableau10).range(),
    ...scaleOrdinal(schemeSet1).range(),
  ],
  STROKE: 'rgba(127,127,127,0.15)',
  FILL: 'rgba(127,127,127,0.15)',
  AREA_CHART_STOP_OPACITIES: [0.2, 0],
  AREA_CHART_OFFSETS: ['20%', '100%'],
};

export const VARIABLE_STATUS = {
  loading: 'loading',
  pending: 'pending',
  error: 'error',
  valid: 'valid',
};

export const TIME_GROUP_DIMENSIONS = {
  hour_of_day: {
    type: 'hour_of_day',
    extract: 'HOUR',
    name: 'Hour of Day',
    selectable: true,
  },
  day_of_week: {
    type: 'day_of_week',
    extract: 'DAY_OF_WEEK',
    name: 'Day of Week',
    selectable: true,
  },
  day_of_month: {
    type: 'day_of_month',
    extract: 'DAY_OF_MONTH',
    name: 'Day of Month',
    selectable: true,
  },
  month_of_year: {
    type: 'month_of_year',
    extract: 'MONTH',
    name: 'Month of Year',
    selectable: true,
  },
  quarter: {
    type: 'quarter',
    extract: 'QUARTER',
    name: 'Quarter',
    selectable: true,
  },
};

export const TIME_DIMENSIONS = {
  time: {
    type: 'time',
    name: 'Time',
    selectable: true,
  },
  tzTime: {
    type: 'tzTime',
    name: 'Time (Local)',
    selectable: false,
  },
  tzExists: {
    type: 'tzExists',
    name: 'Local Time Captured',
    selectable: false,
  },
  ...TIME_GROUP_DIMENSIONS,
};

export const GENERAL_DIMENSIONS = {
  workflow_id: {
    type: 'workflow_id',
    name: 'Workflow',
    selectable: true,
  },
  device_id: {
    type: 'device_id',
    name: 'Device',
    selectable: true,
  },
  event_id: {
    type: 'event_id',
    name: 'Event ID',
    selectable: false,
  },
  skill_id: {
    type: 'skill_id',
    name: 'Skill',
    selectable: true,
  },
  skill_version_id: {
    type: 'skill_version_id',
    name: 'Skill Version',
    selectable: true,
  },
  workflow_skill_id: {
    type: 'workflow_skill_id',
    name: 'Workflow Skill',
    selectable: true,
  },
  serial_number: {
    type: 'serial_number',
    name: 'Serial Number',
    selectable: true,
  },
  geohash: {
    type: 'geohash',
    name: 'Geohash',
    selectable: true,
  },
  location_id: {
    type: 'location_id',
    name: 'Location',
    selectable: true,
  },
  owner_id: {
    type: 'owner_id',
    name: 'Owner',
    selectable: false,
  },
  measure_name: {
    type: 'measure_name',
    name: 'Measure',
    selectable: false,
  },
};

export const DIMENSIONS = {
  ...TIME_DIMENSIONS,
  ...GENERAL_DIMENSIONS,
};

export const DIMENSION_ALIASES = {
  device_id: 'device_name',
  location_id: 'location_name',
  skill_id: 'skill_name',
  skill_version_id: 'skill_version',
  workflow_id: 'workflow_name',
  workflow_skill_id: 'workflow_skill_name',
};

export { CALCULATIONS };

export const CHARTS = {
  bar: {
    type: 'bar',
    name: 'Bar',
    compose: true,
  },
  'bar-stacked': {
    type: 'bar-stacked',
    name: 'Bar Stacked',
    compose: true,
  },
  line: {
    type: 'line',
    name: 'Line',
    compose: true,
  },
  area: {
    type: 'area',
    name: 'Area',
    compose: true,
  },
  'area-stacked': {
    type: 'area-stacked',
    name: 'Area Stacked',
    compose: true,
  },
  radar: {
    type: 'radar',
    subType: 'line',
    name: 'Radar',
    compose: false,
  },
  number: {
    type: 'number',
    subType: 'line',
    name: 'Large Number',
    compose: false,
  },
  columns: {
    type: 'columns',
    subType: 'line',
    name: 'Table',
    compose: false,
  },
};

export const DEFAULT_METRIC = {
  metric: null,
  color: null,
  type: CHARTS.bar.type,
  calculation: CALCULATIONS.AVG.type,
};

export const INTERVAL_LITERALS = {
  SECOND: '1s',
  MINUTE: '1m',
  HOUR: '1h',
  DAY: '24h',
  DAY_ALT: '1d',
  WEEK: '7d',
  TWO_WEEKS: '14d',
  MONTH: '30d',
  MONTH_ALT: '1month',
  TWO_MONTHS: '60d',
  QUARTER: '90d',
  HALF_YEAR: '180d',
  YEAR: '365d',
};

export const TIMEFRAMES = {
  [INTERVAL_LITERALS.HOUR]: {
    type: INTERVAL_LITERALS.HOUR,
    name: 'Past Hour',
    nickname: '1H',
    value: 1,
    unit: 'hour',
  },
  [INTERVAL_LITERALS.DAY]: {
    type: INTERVAL_LITERALS.DAY,
    name: 'Past 24 Hours',
    nickname: '24H',
    value: 24,
    unit: 'hour',
  },
  [INTERVAL_LITERALS.WEEK]: {
    type: INTERVAL_LITERALS.WEEK,
    name: 'Past 7 Days',
    nickname: '1W',
    value: 7,
    unit: 'day',
  },
  [INTERVAL_LITERALS.TWO_WEEKS]: {
    type: INTERVAL_LITERALS.TWO_WEEKS,
    name: 'Past 14 Days',
    nickname: '2W',
    value: 14,
    unit: 'day',
  },
  [INTERVAL_LITERALS.MONTH]: {
    type: INTERVAL_LITERALS.MONTH,
    name: 'Past 30 Days',
    nickname: '1M',
    value: 30,
    unit: 'day',
  },
  [INTERVAL_LITERALS.TWO_MONTHS]: {
    type: INTERVAL_LITERALS.TWO_MONTHS,
    name: 'Past 60 Days',
    nickname: '2M',
    value: 60,
    unit: 'day',
  },
  [INTERVAL_LITERALS.QUARTER]: {
    type: INTERVAL_LITERALS.QUARTER,
    name: 'Past 90 Days',
    nickname: '3M',
    value: 90,
    unit: 'day',
  },
  [INTERVAL_LITERALS.HALF_YEAR]: {
    type: INTERVAL_LITERALS.HALF_YEAR,
    name: 'Past 180 Days',
    nickname: '6M',
    value: 180,
    unit: 'day',
  },
  [INTERVAL_LITERALS.YEAR]: {
    type: INTERVAL_LITERALS.YEAR,
    name: 'Past 365 Days',
    nickname: '1Y',
    value: 365,
    unit: 'day',
  },
};

export const DATE_FORMAT = 'MMM Do YY';
export const DATE_FORMAT_FULL = `${DATE_FORMAT}, h:mm:ss.SSS A`;

export const TIMEFRAME_BINS = {
  [INTERVAL_LITERALS.SECOND]: {
    type: INTERVAL_LITERALS.SECOND,
    name: 'Per Second',
    value: 1,
    unit: 'second',
    format: DATE_FORMAT_FULL,
  },
  [INTERVAL_LITERALS.MINUTE]: {
    type: INTERVAL_LITERALS.MINUTE,
    name: 'Per Minute',
    value: 1,
    unit: 'minute',
    format: `${DATE_FORMAT}, h:mm A`,
  },
  [INTERVAL_LITERALS.HOUR]: {
    type: INTERVAL_LITERALS.HOUR,
    name: 'Per Hour',
    value: 1,
    unit: 'hour',
    format: `${DATE_FORMAT}, h:mm A`,
  },
  [INTERVAL_LITERALS.DAY]: {
    type: INTERVAL_LITERALS.DAY,
    name: 'Per Day',
    value: 1,
    unit: 'day',
    format: `${DATE_FORMAT}, dddd`,
  },
  [INTERVAL_LITERALS.DAY_ALT]: {
    type: INTERVAL_LITERALS.DAY_ALT,
    name: 'Per Day',
    value: 1,
    unit: 'day',
    format: `${DATE_FORMAT}, dddd`,
  },
  [INTERVAL_LITERALS.WEEK]: {
    type: INTERVAL_LITERALS.WEEK,
    name: 'Per Week',
    value: 7,
    unit: 'day',
    trunc_unit: 'week',
    format: DATE_FORMAT,
  },
  [INTERVAL_LITERALS.MONTH_ALT]: {
    type: INTERVAL_LITERALS.MONTH_ALT,
    name: 'Per Month',
    value: 1,
    unit: 'month',
    format: 'MMMM YYYY',
  },
};

export { default as SMART_TIMEFRAMES } from './smart-timeframes';

export const INTERPOLATION_STYLES = {
  interpolate_fill: {
    type: 'interpolate_fill',
    funcName: 'interpolate_fill',
    name: 'Fill',
  },
  interpolate_linear: {
    type: 'interpolate_linear',
    funcName: 'interpolate_linear',
    name: 'Linear',
  },
  interpolate_spline_cubic: {
    type: 'interpolate_spline_cubic',
    funcName: 'interpolate_spline_cubic',
    name: 'Cubic Spline',
  },
  interpolate_locf: {
    type: 'interpolate_locf',
    funcName: 'interpolate_locf',
    name: 'Last Observed Value',
  },
};

export const QUERY_OVERRIDES = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  refetchInterval: false,
};
