import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Card, IconButton } from '@optra/kit';

import Input from 'components/input';
import Message from 'components/message';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';

export default function CreateGoogleSpreadsheetForm({
  workflowActionId,
  onCancel = () => false,
  onComplete = () => false,
}) {
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      configuration: {
        newSpreadsheetName: '',
      },
    },
  });
  const [error, setError] = useState();

  const qc = q.useQueryClient();
  const updateWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowActionGoogle ($form: updateWorkflowActionForm!) {
          workflowAction: updateWorkflowAction(form: $form) {
            id
            configuration
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      reset({
        configuration: {
          newSpreadsheetName: '',
        },
      });

      const { createdSpreadsheetId, createdSpreadsheetName } =
        r?.workflowAction?.configuration?.shared;
      onComplete({ id: createdSpreadsheetId, name: createdSpreadsheetName });
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(form => {
    if (updateWorkflowAction.isPending) return;
    setError(null);
    updateWorkflowAction.mutate({
      id: workflowActionId,
      ...form,
    });
  });

  return (
    <Card variant="secondary">
      {error && (
        <Message variant="danger" title="Couldn't Create Spreadsheet">
          {error.message}
        </Message>
      )}

      <form onSubmit={handleSubmit} className="flex items-center justify-between space-x-2">
        <div className="space-y-2 flex-1">
          <Input
            type="text"
            placeholder="Spreadsheet Name"
            {...register('configuration.newSpreadsheetName', { required: 'Please enter a name.' })}
            readOnly={updateWorkflowAction.isPending}
          />
          <ValidationError errors={errors} name="configuration.newSpreadsheetName" />
        </div>
        <IconButton
          variant="tertiary"
          name="X"
          onClick={onCancel}
          size="lg"
          disabled={updateWorkflowAction.isPending}
        />
        <IconButton
          name="Check"
          type="submit"
          size="lg"
          disabled={updateWorkflowAction.isPending}
        />
      </form>
    </Card>
  );
}
