import { Dialog } from '@headlessui/react';
import cx from 'classnames';

import { Icon, Heading, ProgressCircle } from '@optra/kit';

import { useModalContext } from 'components/modal';

export default function ModalTitle(props) {
  const {
    handleBack: _handleBack,
    handleClose: _handleClose,
    icon,
    renderActions = () => false,
    showBackButton: _showBackButton,
    title,
    subTitle,
    loading,
    showProgress = false,
    complete,
    progress = 1,
    children,
  } = props;

  const context = useModalContext();

  const handleBack = _handleBack || context.handleBack;
  const handleClose = _handleClose || context.handleClose;
  const showBackButton = _showBackButton ?? context.showBackButton;

  return (
    <>
      <div
        className={cx([
          { 'h-20': !!subTitle, 'h-16': !subTitle },
          'flex-shrink-0 flex items-center justify-between px-4 rounded-t-lg bg-light-bg-secondary dark:bg-dark-bg-secondary',
        ])}
      >
        <div className="flex items-center content-center">
          {showBackButton && (
            <button
              type="button"
              onClick={handleBack}
              className="appearance-none select-none w-6 h-6 inline-flex items-center justify-center mr-2"
            >
              <Icon name="CaretLeft" weight="line" />
            </button>
          )}
          {showProgress && (
            <ProgressCircle className="mr-3" size="md" progress={progress} complete={complete} />
          )}
          <div className="content-center">
            <Dialog.Title as={Heading} level={2} icon={icon} loading={loading}>
              <div className="mt-1">{title}</div>
            </Dialog.Title>
            {subTitle && (
              <Dialog.Description className="text-sm text-light-fg-secondary dark:text-dark-fg-secondary uppercase font-medium tracking-wide">
                {subTitle}
              </Dialog.Description>
            )}
          </div>
        </div>
        {children && children}
        <div className="flex items-center pt-1">
          <div className="hidden md:flex items-center mr-4 space-x-1">{renderActions()}</div>
          <button
            type="button"
            onClick={handleClose}
            className="appearance-none select-none w-6 h-6 inline-flex items-center justify-center"
          >
            <Icon name="X" />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-start space-x-1 px-4 md:hidden">
        {renderActions()}
      </div>
    </>
  );
}
