import { api, q } from 'config/api';

export default function useAuthorizedWorkflowAction(id, overrides) {
  const origin = window.location.href;
  const forwardUrl = `${window.location.origin}/actions/authorize`;

  const r = q.useQuery({
    queryKey: ['workflowAction', id, origin, forwardUrl],
    queryFn: () =>
      api(
        `query authorizedWorkflowAction($id: ID!, $origin: String!, $forwardUrl: String!) {
          workflowAction(id: $id) {
            id
            disabled
            action {
              id
              name
              authUrl(origin: $origin, forwardUrl: $forwardUrl)
            }
            configuration
            fields {
              configuration
            }
            authorization {
              user {
                name
              }
            }
            workflow {
              skills {
                data {
                  id
                  name
                  skill {
                    id
                    name
                    icon
                    iconUrl
                    color
                  }
                  disabled {
                    disabled
                  }
                  outputs {
                    disabled
                    output {
                      id
                      label
                    }
                  }
                }
              }
            }
          }
        }`,
        { id, origin, forwardUrl },
      ),
    enabled: !!id,
    placeholderData: q.keepPreviousData,
    ...overrides,
  });

  return [r, { origin, forwardUrl }];
}
