import { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { FilterContainer, Group, Groups, SubNavList } from '@optra/kit';

import { useChartCtx, useChartInterface } from '../context';

import ControlCategories from './control-categories';
import ControlDataGrouping from './control-data-grouping';
import ControlDaySelection from './control-day-selection';
import ControlDiscardZeros from './control-discard-zeros';
import ControlGranularity from './control-granularity';
import ControlHourSelection from './control-hour-selection';
import ControlInterpolate from './control-interpolate';
import ControlMetrics from './control-metrics';
import ControlSyncTimezones from './control-sync-timezones';
import ControlFilters from './controls-filters';

function GeneralControls() {
  const {
    actions: { handleOnDragEnd },
  } = useChartCtx();
  return (
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Groups>
          <ControlMetrics />
          <ControlCategories />
          <ControlDataGrouping />
          <ControlFilters />
        </Groups>
      </DragDropContext>
    </div>
  );
}

export function TimeControls() {
  const {
    state: { showFrequency, showInterpolate, showDiscardZeros },
  } = useChartCtx();
  const {
    state: { isMobile },
  } = useChartInterface();
  return (
    <div>
      <Groups>
        <Group
          heading="General"
          description={
            isMobile
              ? 'Choose granularity, adjust for timezones, fill gaps in data, etc'
              : 'Adjust for timezones, fill gaps in data, etc'
          }
          scrollable={false}
          collapsible
        >
          <div className="space-y-4">
            {isMobile && showFrequency && (
              <FilterContainer stacked>
                <ControlGranularity />
              </FilterContainer>
            )}
            <ControlSyncTimezones />
            <ControlInterpolate disabled={!showInterpolate} />
            <ControlDiscardZeros disabled={!showDiscardZeros} />
          </div>
        </Group>
        <ControlDaySelection />
        <ControlHourSelection />
      </Groups>
    </div>
  );
}

export default function Controls() {
  const [currentTab, setCurrentTab] = useState('data');

  return (
    <div>
      <div className="sticky top-5 lg:top-9 z-10">
        <SubNavList block className="mb-7">
          <SubNavList.Item
            icon="ChartScatter"
            active={currentTab === 'data'}
            size="sm"
            onClick={() => setCurrentTab('data')}
          >
            Data
          </SubNavList.Item>
          <SubNavList.Item
            icon="ClockCountdown"
            active={currentTab === 'time'}
            size="sm"
            onClick={() => setCurrentTab('time')}
          >
            Time
          </SubNavList.Item>
        </SubNavList>
      </div>
      {currentTab === 'data' && <GeneralControls />}
      {currentTab === 'time' && <TimeControls />}
    </div>
  );
}
