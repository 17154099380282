import { api, q } from 'config/api';

export default function useDeviceEws(deviceId, overrides) {
  return q.useQuery({
    queryKey: ['device', deviceId, 'ews'],
    queryFn: () =>
      api(
        `query deviceEWSTunnel($id: ID!) {
          device(id: $id) {
            id
            adminConnected
            capabilities {
              tunneling
            }
            tunnel: ewsTunnel {
              id
              url
              authentication
              createdAt
              expiresAt
              open
              creatorId
            }
          }
        }`,
        { id: deviceId },
      ),
    enabled: !!deviceId,
    ...overrides,
  });
}
