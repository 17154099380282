import { Card, Badge, Button, Icon, IconButton, Text, Heading } from '@optra/kit';

import BillingTransactionListItem from 'components/billing-transaction-list-item';
import Feature, { useFeature } from 'components/feature';
import List from 'components/list';
import ListItem from 'components/list-item';
import { creditsToFormattedString } from 'lib/format-credits';
import { useCurrentBillingAccount, useBillingAccountTransactions, useProfile } from 'queries';

export default function ProfileCreditsSection() {
  const { data: profile } = useProfile();

  const billingAccount = useCurrentBillingAccount({
    refetchInterval: 10000,
    retry: 3,
  });
  const billingAccountTransactions = useBillingAccountTransactions({
    list: {
      filter: { processed: false },
      limit: 3,
    },
  });

  const currentBillingAccount = billingAccount?.data?.currentBillingAccount;
  const balances = currentBillingAccount?.balances;
  let estimatedMonthlyUsage = Math.abs(balances?.dailyUsage * 30) || 0;
  if (estimatedMonthlyUsage < 100000) {
    estimatedMonthlyUsage = 100000;
  }

  const resellerCodesEnabled = useFeature('resellerCodes', 'global');

  const currentOwner = profile?.currentOwner;

  if (!resellerCodesEnabled || currentOwner?.pricing !== 'credit') {
    return null;
  }

  const transactions = billingAccountTransactions?.data || [];

  return (
    <div>
      <Text variant="label" size="xs" className="mr-2">
        Credits
      </Text>
      <Card variant="secondary" noPadding>
        <div className="flex row items-center justify-between px-4 py-5">
          <div>
            <Heading className="block">
              {`${creditsToFormattedString(balances?.balance)} Credits`}
              {balances?.balance < estimatedMonthlyUsage && (
                <Badge variant="danger" size="sm" className="ml-2">
                  <Icon name="Warning" weight="fill" size="xs" className="mr-1" />
                  Low
                </Badge>
              )}
            </Heading>
            <Text className="block" color="muted" size="xs">
              {`${creditsToFormattedString(estimatedMonthlyUsage)} Estimated Monthly`}
            </Text>
          </div>
          <div className="flex row items-center space-x-2">
            <Button
              size="xs"
              variant="tertiary"
              icon="PlusCircle"
              to="./add-credits?returnTo=/profile"
            >
              Add Credits
            </Button>
            <Feature feature="billingBeta">
              <IconButton name="Gear" variant="tertiary" to="./credits-settings" />
            </Feature>
          </div>
        </div>

        {transactions.length > 0 && (
          <List>
            <div />
            {transactions.map(transaction => (
              <BillingTransactionListItem
                key={transaction?.id}
                id={transaction?.id}
                amount={transaction?.amount}
                description={transaction?.description}
                recurringInterval={transaction?.recurringInterval}
                createdAt={transaction?.createdAt}
              />
            ))}
            {transactions.length > 3 && (
              <ListItem
                to="/profile/transactions"
                renderLeft={() => <Icon name="FileText" weight="line" />}
                renderRight={() => <Icon name="CaretRight" weight="line" />}
              >
                View All Transactions
              </ListItem>
            )}
          </List>
        )}
      </Card>
    </div>
  );
}
