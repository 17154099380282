import { api, q } from 'config/api';

export default function useSkillBuilderRecipe(id, overrides) {
  return q.useQuery({
    queryKey: ['skillBuilderRecipe', id],
    queryFn: () =>
      api(
        `query skillBuilderRecipe($id: ID!) {
          skillBuilderRecipe(id: $id) {
            id
            name
            description
            config
            icon
            color
            iconUrl
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
