import cx from 'classnames';

import { DetailHeading, Text } from '@optra/kit';

import { CALCULATIONS } from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

export default function ChartTotal(props) {
  const { heading, total = 0, calculation: _calculation, className, ...rest } = props;

  const calculation = CALCULATIONS[_calculation];

  return (
    <div className={cx('flex flex-col space-y-2', className)} {...rest}>
      {!!heading && (
        <DetailHeading variant="loud">
          {heading} {calculation && <span className="opacity-60">{calculation.name}</span>}
        </DetailHeading>
      )}
      <div className={cx('relative w-full', 'h-[102px]')}>
        <Text
          className={cx(
            'text-[100px] leading-[100px]',
            'text-transparent bg-clip-text bg-gradient-to-r',
            'from-primary-400 to-primary-400 dark:from-primary-200 dark:to-primary-500',
            'font-semibold inline-block',
          )}
        >
          {helpers.formatNumber(total)}
        </Text>
      </div>
    </div>
  );
}
