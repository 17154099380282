import { ChartLine } from '@phosphor-icons/react';
import cx from 'classnames';
import cleanProps from 'clean-react-props';
import { compact, filter, includes, isBoolean, isFunction, max, min, truncate } from 'lodash';
import mime from 'mime';
import { useEffect, useState, useCallback } from 'react';

import {
  DetailHeading,
  InteractiveWrap,
  UiState,
  Button,
  Icon,
  Tooltip,
  Text,
  ButtonWrap,
  Spinner,
} from '@optra/kit';
import { utils } from '@optra/shared';

import Chart from 'modals/chart-details/chart/chart';
import { useChartCtx, useChartType, useChartInterface } from 'modals/chart-details/context';
import {
  CHARTS,
  TIME_DIMENSIONS,
  DIMENSIONS,
  DIMENSION_ALIASES,
  CONSTANTS,
  DATE_FORMAT_FULL,
  TIMEFRAME_BINS,
} from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

const { SPLIT_SYMBOL_2, TIMESTREAM_TIMESTAMP_FORMAT, Y_PADDING, PANEL_BG } = CONSTANTS;
const HOVER_ELEMENT_CLASSES = [
  'bg-gray-200/50 dark:bg-black-800/80',
  'backdrop-blur-md',
  'dark:drop-shadow-md dark:drop-shadow-lg dark:shadow-[#000]/30',
];
const PADDING = 'p-2';
const ROW_NUM_COLOR = 'text-black-200 dark:text-white/30';
const SERIES_CHART_HEIGHT = 100;
const SERIES_CHART_AXIS_HEIGHT = 12;
const { isValidUrl } = utils;

function Pagination(props) {
  const {
    limit,
    start,
    end,
    currentListLength,
    onFirstPage,
    onLastPage,
    isSinglePage,
    isLoading,
    isFetchingNextPage,
    handleGoToPrevPage,
    handleGoToNextPage,
    className,
  } = props;
  if (isSinglePage) return null;
  return (
    //stop propagation here to prevent selecting a chart when paging
    // from a workspace card
    <div
      onClick={e => e.stopPropagation()}
      className={cx(
        'flex items-center space-x-2',
        'relative z-20',
        isLoading && 'animate-pulse pointer-events-none',
        className,
      )}
    >
      <Tooltip disabled={onFirstPage} label={`Previous ${limit} rows`}>
        <Button
          variant="secondary"
          size="xs"
          disabled={onFirstPage}
          onClick={handleGoToPrevPage}
          as="span"
        >
          Prev
        </Button>
      </Tooltip>

      <Text className="text-xs inline-flex flex-nowrap items-center space-x-1">
        <span>{start + 1}</span>
        <span>-</span>
        {isFetchingNextPage ? (
          <Spinner size="xs" className="opacity-50" />
        ) : (
          <span>{onLastPage ? currentListLength : end}</span>
        )}
      </Text>

      <Tooltip disabled={onLastPage} label={`Next ${limit} rows`}>
        <Button
          variant="secondary"
          size="xs"
          disabled={onLastPage || isFetchingNextPage}
          onClick={handleGoToNextPage}
          as="span"
        >
          Next
        </Button>
      </Tooltip>
    </div>
  );
}

function SeriesChart({ data, onSelect }) {
  const {
    helpers: { getTimeKey },
  } = useChartCtx();
  const {
    methods: { generateChartGraphData },
  } = useChartType();

  if (!data) return;

  const timeKey = getTimeKey();

  const _chartData = generateChartGraphData(data, {
    categoryKeys: [timeKey],
  });

  const chartData = _chartData
    ? { ..._chartData, list: filter(_chartData.list, ({ category }) => !!category) }
    : undefined;

  const currentTimestamps = compact(
    chartData?.list?.map(item => {
      const [, , timestamp] = helpers.splitStringViaSymbol(item.category, SPLIT_SYMBOL_2);
      return timestamp;
    }),
  );
  const minTimestamp = helpers.formatTime(min(currentTimestamps));
  const maxTimestamp = helpers.formatTime(max(currentTimestamps));

  return (
    <div style={{ height: SERIES_CHART_HEIGHT + SERIES_CHART_AXIS_HEIGHT }}>
      <div className="relative" style={{ height: SERIES_CHART_HEIGHT }}>
        <Chart
          chartData={chartData}
          components={{
            yAxis: null,
            legend: null,
          }}
          chartProps={{
            margin: { left: 0 },
          }}
          groupProps={{
            connectNulls: true,
          }}
          tooltipProps={{
            maxVisibleItems: 2,
            clickMore: false,
            isCompact: true,
            timeFormat: DATE_FORMAT_FULL,
          }}
          xAxisProps={{
            hide: true,
          }}
          onClick={data => onSelect(data?.activeLabel)}
        />
      </div>
      <div
        className={cx(
          'flex items-center gap-2',
          '-mt-[6px]',
          'text-black dark:text-white opacity-60 text-[10px]',
        )}
      >
        <span>{minTimestamp}</span>
        <span className="italic opacity-50">to</span>
        <span>{maxTimestamp}</span>
      </div>
    </div>
  );
}

const simplisticURL = s => {
  const strings = s.split('/');
  const simple = [strings[2], strings[3]].join('/');
  return truncate(simple, { length: 30 });
};

function CellText({ text, tooltip, href, className }) {
  const isEmpty = helpers.isUnknownValue(text);

  const isLink = !!(href && !isEmpty);

  let Comp = 'span';

  const passedProps = {};

  if (isLink) {
    Comp = ButtonWrap;
    passedProps.href = href;
    passedProps.target = href;
  }

  const classNames = cx(
    'inline-flex items-center space-x-1',
    'font-regular',
    'text-xs',
    isLink
      ? [
          'text-primary-500 hover:text-primary-400',
          'dark:text-primary-400 dark:hover:text-primary-500',
          'underline underline-offset-2 decoration-primary-400/40 decoration-dotted',
        ]
      : isEmpty
      ? 'text-black-200 dark:text-gray-800 italic'
      : ['text-black-900 dark:text-gray-200'],

    className,
  );

  const displayText = isLink ? simplisticURL(href) : isEmpty ? 'NULL' : text;

  return (
    <Tooltip disabled={!tooltip && !href} label={tooltip || href}>
      <Comp className="inline-flex flex-col" {...passedProps}>
        <Text className={classNames}>
          <span>{displayText}</span>
          {isLink && <Icon name="ArrowSquareOut" size="xs" weight="duotone" />}
        </Text>
      </Comp>
    </Tooltip>
  );
}

function CellMedia({ text, tooltip, href }) {
  const isEmpty = helpers.isUnknownValue(text);
  const isLink = !!(href && !isEmpty);
  let Comp = 'span';
  const passedProps = {};

  if (isLink) {
    Comp = ButtonWrap;
    passedProps.href = href;
    passedProps.target = '_blank';
    passedProps.rel = 'noreferrer noopener';
  }

  const getMediaElement = () => {
    const url = new URL(href);
    const mediaType = mime.getType(`${url.origin}${url.pathname}`);

    if (!mediaType) {
      return null;
    }

    const type = mediaType.split('/')?.at(0);

    switch (type) {
      case 'image':
        return (
          <div
            className="w-20 h-20 bg-contain bg-center bg-no-repeat"
            style={{ backgroundImage: `url('${href}')` }}
          />
        );
      case 'video':
        return (
          <video
            controls
            src={href}
            className="w-32 h-24 aspect-square hover:aspect-video"
            type={mediaType}
          />
        );
      case 'audio':
        return <audio controls src={href} type={mediaType} className="w-32 h-16" />;
      default:
        break;
    }
  };

  return (
    <Tooltip disabled={!tooltip && !href} label={tooltip || href}>
      <Comp className="inline-flex flex-col" {...passedProps}>
        {getMediaElement()}
      </Comp>
    </Tooltip>
  );
}

function CellValue({ variable, value, alias }) {
  const {
    helpers: { getChartConfig },
  } = useChartCtx();
  const {
    state: { selectedCategoryKey },
  } = useChartType();
  const { timeframeBin, syncTimezones } = getChartConfig();

  if (isBoolean(value)) {
    return (
      <div
        className={cx(
          'inline-flex items-center rounded-md',
          'px-2 py-[2px]',
          'uppercase font-medium',
          !!value
            ? ['bg-primary/30 text-primary-600', 'dark:bg-primary/10 dark:text-primary-300']
            : ['bg-red/20 text-red', 'dark:bg-red/20 dark:text-red-300'],
        )}
      >
        <Text size="xs">{`${value}`}</Text>
      </div>
    );
  }

  if (helpers.isTimeGroupDimension(variable)) {
    return (
      <CellText text={helpers.formatTimeGroup(variable, value, { useLocal: !syncTimezones })} />
    );
  }

  if (helpers.isTimeDimension(variable)) {
    const format = selectedCategoryKey ? undefined : TIMEFRAME_BINS[timeframeBin]?.format;
    // NOTE: time in this context is already defined (by tzTime or time) and needs no formatting
    return (
      <CellText
        text={helpers.formatTime(value, format, false)}
        tooltip={helpers.formatTime(value, TIMESTREAM_TIMESTAMP_FORMAT, false)}
      />
    );
  }

  if (isValidUrl(value)) {
    return (
      <CellMedia
        text={alias || value}
        tooltip={!!alias ? value : undefined}
        href={isValidUrl(value) ? value : undefined}
      />
    );
  }

  return (
    <CellText
      text={alias || value}
      tooltip={!!alias ? value : undefined}
      href={isValidUrl(value) ? value : undefined}
    />
  );
}

export default function ChartDataTable(props) {
  const {
    naked,
    columns,
    rows,
    limit,
    orderByKey,
    orderDirection = 'ASC',
    onColumnClick,
    cellWrap = true,
    displayAlias = true,
    className,
    error,
    isError,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    selectedCategoryKey,
    tableScrollRef,
    ...rest
  } = props;
  const {
    helpers: { getChartConfig },
    configuration: { showInterface },
  } = useChartCtx();
  const {
    state: {
      isMobile,
      ui: { showDetailLineChart },
    },
  } = useChartInterface();

  const [focusedTimeKey, setFocusedTimeKey] = useState();
  const [focusedRowOffset, setFocusedRowOffset] = useState(0);
  const focusedRowRef = useCallback(node => setFocusedRowOffset(node?.offsetTop), []);

  const { metrics = [], interpolate } = getChartConfig();
  const aliasKeys = Object.values(DIMENSION_ALIASES);

  const aliasColumns = displayAlias
    ? filter(columns, variable => !includes(aliasKeys, variable))
    : columns;

  const [page, setPage] = useState(0);

  const start = page * limit;
  const end = (page + 1) * limit;

  const currentListLength = rows?.length;

  const allDataLoaded = !hasNextPage;
  const onFirstPage = !page;
  const onLastLoadedPage = currentListLength >= start && currentListLength <= end;
  const onLastPage = allDataLoaded && onLastLoadedPage;
  const isSinglePage = onFirstPage && onLastPage;

  const paginationProps = {
    rows,
    limit,
    page,
    start,
    end,
    currentListLength,
    allDataLoaded,
    onFirstPage,
    onLastLoadedPage,
    onLastPage,
    isSinglePage,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    handleGoToPrevPage: () => {
      if (onFirstPage) return;
      setPage(page - 1);
    },
    handleGoToNextPage: () => {
      if (onLastPage) return;
      setPage(page + 1);
      if (onLastLoadedPage && !allDataLoaded) {
        fetchNextPage();
      }
    },
  };

  const pagedList = rows?.slice(paginationProps.start, paginationProps.end);

  const showSeriesChart = !!(
    !naked &&
    !isMobile &&
    showInterface &&
    showDetailLineChart &&
    pagedList?.length &&
    CHARTS[metrics[0].type]?.compose &&
    (orderByKey === TIME_DIMENSIONS.time.type || orderByKey === TIME_DIMENSIONS.tzTime.type)
  );

  const scrollToPosition = useCallback(
    options => {
      if (!tableScrollRef?.current) return;
      tableScrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
        ...options,
      });
    },
    [tableScrollRef],
  );

  // paging cleanup / reset
  useEffect(() => {
    setPage(0);
    setFocusedTimeKey(undefined);
    setFocusedRowOffset(undefined);
    scrollToPosition({
      top: 0,
      behavior: 'instant',
    });
  }, [selectedCategoryKey, orderByKey, orderDirection, scrollToPosition]);

  // scroll to top when paging
  useEffect(() => {
    setFocusedTimeKey(undefined);
    setFocusedRowOffset(undefined);
    scrollToPosition({
      top: 0,
      behavior: 'instant',
    });
  }, [page, scrollToPosition]);

  // scroll to row when subchart is selected
  useEffect(
    () =>
      scrollToPosition({
        top: focusedRowOffset,
        behavior: 'smooth',
      }),
    [focusedRowOffset, scrollToPosition],
  );

  return (
    <>
      {showSeriesChart && (
        <div
          className={cx('w-full bg-red sticky left-0 top-0 z-10', PANEL_BG)}
          style={{
            height: SERIES_CHART_HEIGHT + SERIES_CHART_AXIS_HEIGHT,
            marginBottom: -(SERIES_CHART_HEIGHT + SERIES_CHART_AXIS_HEIGHT),
          }}
        ></div>
      )}
      <div
        className={cx(
          'ChartDataTable',
          'relative',
          'w-full min-h-full',
          'cursor-default',
          'space-y-4',
          isRefetching && 'animate-pulse pointer-events-none',
          Y_PADDING,
          className,
        )}
        {...cleanProps(rest)}
      >
        {showInterface && <Pagination {...paginationProps} />}
        {!!(isLoading || isRefetching || isFetchingNextPage) ? (
          <UiState center className="animate-fade-in" />
        ) : isError ? (
          <UiState variant="error" detail={error?.message} center className="animate-fade-in" />
        ) : !rows?.length ? (
          <UiState
            variant="empty"
            icon={{
              component: ChartLine,
            }}
            text="No data"
            detail={
              interpolate &&
              'This is likely an interpolated data point. Clear selection and view this table to see all non-interpolated values.'
            }
            center
            className="animate-fade-in"
          />
        ) : (
          <>
            {showSeriesChart && (
              <div className={cx('w-full sticky top-0 z-20', PANEL_BG)}>
                <SeriesChart data={pagedList} onSelect={setFocusedTimeKey} />
              </div>
            )}
            <table className="w-full animate-fade-in">
              <thead
                className={cx('sticky', !showSeriesChart && 'top-0')}
                style={{
                  top: showSeriesChart ? SERIES_CHART_HEIGHT + SERIES_CHART_AXIS_HEIGHT : undefined,
                }}
              >
                <tr className={cx(HOVER_ELEMENT_CLASSES)}>
                  <th className={cx('rounded-l-lg text-center', PADDING, 'pr-0')}></th>
                  {aliasColumns.map(variable => {
                    const isInteractive = !!(orderByKey && isFunction(onColumnClick));
                    const isOrderByKey = orderByKey === variable;
                    return (
                      <th key={variable} scope="col" className="last:rounded-r-lg">
                        <InteractiveWrap
                          onClick={isInteractive ? e => onColumnClick(variable, e) : null}
                          className={cx(
                            'flex flex-row items-center space-x-1 w-full text-left animate-fade-in',
                            PADDING,
                          )}
                        >
                          <DetailHeading variant={isOrderByKey ? 'loud' : 'muted'}>
                            <span className="text-[10px]">
                              {DIMENSIONS[variable]?.name ||
                                variable
                                  .replace(/(_VARCHAR|_BOOLEAN|_DOUBLE|_TIMESTAMP)$/g, '')
                                  .trim()}
                            </span>
                          </DetailHeading>
                          {isOrderByKey && (
                            <Icon
                              name="SortAscending"
                              size="xs"
                              weight="bold"
                              color="primary"
                              className="transition-transform duration-300 animate-fade-in"
                              style={{
                                transform:
                                  orderDirection === 'ASC' ? 'rotateX(0deg)' : 'rotateX(180deg)',
                              }}
                            />
                          )}
                        </InteractiveWrap>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody
                className={cx(
                  'divide-y',
                  'divide-gray-200/40 dark:divide-gray/10',
                  'transition-opacity duration-500',
                )}
              >
                {pagedList?.map((row, i) => {
                  const { _timeKey } = row;
                  const isFocused = _timeKey === focusedTimeKey;
                  return (
                    <tr
                      key={`${i}_${row.num}`}
                      ref={isFocused ? focusedRowRef : undefined}
                      className={cx(
                        isFocused && 'animate-highlight-row !bg-opacity-50',
                        '!hover:bg-gray-300/10 !dark:hover:bg-gray-900/20',
                      )}
                    >
                      <td className={cx('text-right w-px pl-0', PADDING)}>
                        <Text className={cx('text-xs font-medium', ROW_NUM_COLOR)}>
                          {row._rowNum}.
                        </Text>
                      </td>
                      {aliasColumns.map(variable => {
                        const alias = !!displayAlias && row[DIMENSION_ALIASES[variable]];
                        const value = row[variable];
                        return (
                          <td
                            key={variable}
                            className={cx(
                              cellWrap ? 'whitespace-wrap' : 'whitespace-nowrap',
                              PADDING,
                            )}
                          >
                            <CellValue variable={variable} value={value} alias={alias} />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination {...paginationProps} />
          </>
        )}
      </div>
    </>
  );
}
