import without from 'lodash/without';

import { Icon, Spinner } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';
import IntersectionLoadMore from 'components/intersection-load-more';
import { useDeviceSoftwareVersions } from 'queries';

export default function DevicesFilterFirmwareVersion({ onFilter, filter }) {
  const {
    data,
    isLoading: fetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useDeviceSoftwareVersions();

  if (fetching) {
    return (
      <DevicesFilterListItem image={<Spinner size="sm" color="gradient" />} label="Loading…" />
    );
  }

  return (
    <>
      {data?.map?.((version, idx) => {
        const selected = filter.versions?.includes?.(version.version);

        return (
          <DevicesFilterListItem
            key={version.version}
            image={<Icon name="Cpu" size="sm" color="gradient" />}
            label={version.version}
            selected={selected}
            onClick={() => {
              if (selected) {
                onFilter({
                  ...filter,
                  versions: without(filter.versions, version.version),
                });
              } else {
                onFilter({
                  ...filter,
                  versions: [...(filter.versions || []), version.version],
                });
              }
            }}
          />
        );
      }) || null}
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isFetchingNextPage || !hasNextPage}
      />
    </>
  );
}
