import cx from 'classnames';

import { Table, Icon, Tooltip } from '@optra/kit';

export default function DevicesTableHeading({ column, onSort, currentSort, maxChars = 25 }) {
  if (!column) {
    return <Table.TH />;
  }

  const handleClick = () => {
    if (!column.sortable) {
      return false;
    }

    return onSort({
      by: column.sortKey,
      direction: currentSort?.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  const fullLabelText = `${column?.name}${column?.skill ? ` - ${column?.skill?.name}` : ''}`;
  let useMinifiedText = false;
  let minifiedLabelText = '';
  if (fullLabelText.length > maxChars) {
    minifiedLabelText = `${fullLabelText.substring(0, maxChars - 3)}...`;
    useMinifiedText = true;
  }

  return (
    <Table.TH onClick={handleClick} className="hidden lg:table-cell">
      {useMinifiedText && (
        <Tooltip label={fullLabelText}>
          <span
            className={cx(['flex items-center space-x-2', column.sortable && 'cursor-pointer'])}
          >
            <span>{minifiedLabelText}</span>
            {column?.sortable && column.sortKey === currentSort?.by && (
              <Icon size="xs" name={currentSort?.direction === 'asc' ? 'CaretUp' : 'CaretDown'} />
            )}
          </span>
        </Tooltip>
      )}
      {!useMinifiedText && (
        <span className={cx(['flex items-center space-x-2', column.sortable && 'cursor-pointer'])}>
          <span>{fullLabelText}</span>
          {column?.sortable && column.sortKey === currentSort?.by && (
            <Icon size="xs" name={currentSort?.direction === 'asc' ? 'CaretUp' : 'CaretDown'} />
          )}
        </span>
      )}
    </Table.TH>
  );
}
