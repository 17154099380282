import cx from 'classnames';
import { noop, isFinite as _isFinite } from 'lodash';

import Label from 'components/label';
import Select from 'components/select';
import StepperInput from 'components/stepper-input';

const DEFAULT_HEIGHT = 5;
const DEFAULT_WIDTH = 5;

export const IMAGE_SIZES = {
  custom: 'custom',
  fit: 'fit',
};

export default function InputDimensions({
  className,
  name,
  value = {},
  onChange: _onChange = noop,
  showUnitLabel = true,
  unitLabel = 'px',
  min = 1,
  max = 999999,
}) {
  const { height: heightValue, width: widthValue } = value;
  const showDimensions = _isFinite(widthValue) && _isFinite(heightValue);
  const currentImageSize = !showDimensions ? IMAGE_SIZES.fit : IMAGE_SIZES.custom;
  const onChange = (field, nextValue) => {
    _onChange({ ...value, [field]: nextValue, size: currentImageSize });
  };

  const updateImageSize = size => {
    switch (size) {
      case IMAGE_SIZES.custom:
        _onChange({ height: DEFAULT_HEIGHT, width: DEFAULT_WIDTH, size });
        break;
      case IMAGE_SIZES.fit:
        _onChange({ size });
        break;
      default:
        break;
    }
  };

  return (
    <div className={cx('grid grid-cols-8 gap-1', className)}>
      <div className="col-span-8 justify-start grid grid-cols-8 gap-0 mb-2">
        <Label className="flex items-center justify-end col-span-1 ml-1 mr-6">Size</Label>
        <Select
          className="col-span-7"
          name="size"
          value={currentImageSize}
          onChange={e => {
            const value = e.target.value;
            updateImageSize(value);
          }}
        >
          <option value={IMAGE_SIZES.fit}>Fit</option>
          <option value={IMAGE_SIZES.custom}>Custom</option>
        </Select>
      </div>
      {showDimensions && (
        <>
          <div className="col-span-4 justify-center grid grid-cols-4 gap-1">
            <Label htmlFor={name} className="flex items-center justify-end col-span-1">
              Height
            </Label>
            <StepperInput
              className="col-span-3"
              id={`${name}-height`}
              value={heightValue}
              onChange={value => {
                onChange('height', value);
              }}
              min={min}
              max={max}
              showUnitLabel={showUnitLabel}
              unitLabel={unitLabel}
            />
          </div>
          <div className="col-span-4 justify-center grid grid-cols-4 gap-1">
            <Label htmlFor={name} className="flex items-center justify-end col-span-1">
              Width
            </Label>
            <StepperInput
              className="col-span-3"
              id={`${name}-width`}
              value={widthValue}
              onChange={value => {
                onChange('width', value);
              }}
              min={min}
              max={max}
              showUnitLabel={showUnitLabel}
              unitLabel={unitLabel}
            />
          </div>
        </>
      )}
    </div>
  );
}
