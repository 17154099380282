import { Card, Heading, Text } from '@optra/kit';

import StepperInput from 'components/stepper-input';
import ImageSizePreview from 'modals/skill-pipeline-builder/components/image-size-preview';

export default function StaticCropImageElement({ config = {}, onChange = () => false }) {
  function handleChange(key, value) {
    onChange({ key, value });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Crop Region</Heading>
      <Card variant="secondary" className="space-y-2 flex flex-col items-center">
        <div className="flex flex-row items-center space-x-4">
          <div className="flex flex-row items-center space-x-2">
            <Text variant="label" size="xs">
              Size
            </Text>
            <StepperInput
              label="px"
              value={config?.width}
              max={10000}
              onChange={v => handleChange('width', parseFloat(v))}
            />
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Text variant="label" size="xs">
              Top
            </Text>
            <StepperInput
              label="px"
              value={config?.y_val}
              max={10000}
              onChange={v => handleChange('y_val', v)}
            />
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Text variant="label" size="xs">
              Left
            </Text>
            <StepperInput
              label="px"
              value={config?.x_val}
              max={10000}
              onChange={v => handleChange('x_val', v)}
            />
          </div>
        </div>
        <ImageSizePreview
          width={config?.width}
          height={config?.width}
          top={config?.y_val}
          left={config?.x_val}
        />
      </Card>
    </div>
  );
}
