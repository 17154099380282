import cx from 'classnames';

export default function ModalFooter({ children, className }) {
  return (
    <div
      className={cx(
        'h-20 flex flex-shrink-0 items-center justify-center px-4 rounded-b-lg bg-light-bg-secondary dark:bg-dark-bg-secondary',
        className,
      )}
    >
      {children}
    </div>
  );
}
