import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-terminal';

import { Cpu } from '@phosphor-icons/react';
import { isEmpty } from 'lodash';
import { Fragment, useState } from 'react';
import AceEditor from 'react-ace';
import YAML from 'yaml';

import { Table, Text, DetailList, VersionLabel, IconButton } from '@optra/kit';

import AdminSkillBuilderPageWrapper from 'components/admin-skill-builder-page-wrapper';
import EmptyContentMessage from 'components/empty-content-message';
import IntersectionLoadMore from 'components/intersection-load-more';
import ModelsDropdownSelect from 'components/models/models-dropdown-select';
import { api, q } from 'config/api';
import useIsDarkMode from 'hooks/use-is-dark-mode';
import { useModels } from 'queries';

function AdminSkillBuilderAddGlobalModel() {
  const [selected, setSelected] = useState();

  const qc = q.useQueryClient();
  const toggle = q.useMutation({
    mutationFn: form =>
      api(
        `mutation toggleGlobalModel($form: updateGlobalModelForm!) {
          model: updateGlobalModel(form: $form) {
            id
          }
        }`,
        { form },
      ),
    async onMutate({ global }) {
      const next = global;
      setSelected(prev => ({ ...prev, global: next }));
      return next;
    },
    onError: (err, id, prev) => {
      setSelected(olds => ({ ...olds, global: prev }));
    },
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['models'] });
    },
  });

  return (
    <div className="flex justify-end items-center gap-2">
      <ModelsDropdownSelect
        filter={{ showAll: true }}
        hideAllOption
        onChange={setSelected}
        value={selected?.id}
      />
      <IconButton
        name={selected?.global ? 'Minus' : 'Plus'}
        loading={toggle.isPending}
        variant="primary"
        size="lg"
        onClick={() => {
          if (!selected) return;
          toggle.mutate({ id: selected.id, global: !selected.global });
        }}
      />
    </div>
  );
}

export default function AdminSkillBuilderModels() {
  const isDarkMode = useIsDarkMode();
  const Models = useModels({
    list: {
      filter: { global: true },
      sort: { direction: 'desc' },
    },
  });

  return (
    <AdminSkillBuilderPageWrapper loading={Models.isLoading} error={Models.error} active="models">
      <AdminSkillBuilderAddGlobalModel />
      <br />
      {isEmpty(Models.data) && <EmptyContentMessage icon="FileCode" title="No Models" />}
      {!isEmpty(Models.data) && (
        <Table>
          <Table.Body>
            {Models.data?.map?.(model => (
              <Fragment key={model.id}>
                <Table.Row key={model.id} hover>
                  <Table.TD collapsed>
                    <Cpu size={40} />
                  </Table.TD>
                  <Table.TD>
                    <Text size="sm">{model.name}</Text>
                    <DetailList
                      details={[
                        {
                          title: 'Owner',
                          component: model.owner.name,
                        },
                        {
                          title: 'ID',
                          component: model.id,
                        },
                        {
                          title: 'labels',
                          component: model.labels?.join(', '),
                        },
                        model.latestVersion && {
                          title: 'Version',
                          component: <VersionLabel version={model.latestVersion} />,
                        },
                      ]}
                    />
                  </Table.TD>
                </Table.Row>
                <Table.Row>
                  <Table.TD colSpan={2}>
                    <AceEditor
                      mode="yaml"
                      theme={isDarkMode ? 'terminal' : 'tomorrow'}
                      value={YAML.stringify(
                        {
                          [model.id]: {
                            config: {
                              model: `file:///app/skill/config/${model.id}.eim`,
                            },
                            inputs: {
                              image: '<INPUT>.image',
                            },
                            type: 'inference',
                            name: 'EdgeImpulseClassificationInferenceEngine|EdgeImpulseObjectDetectionInferenceEngine',
                            meta: {
                              modelId: model.id,
                              labels: model.labels,
                            },
                          },
                        },
                        { aliasDuplicateObjects: false },
                      )}
                      width="100%"
                      height="230px"
                      tabSize={2}
                      setOptions={{ useWorker: false }}
                      readOnly
                    />
                  </Table.TD>
                </Table.Row>
              </Fragment>
            ))}
          </Table.Body>
        </Table>
      )}

      <IntersectionLoadMore
        onVisible={Models.fetchNextPage}
        disabled={!Models.hasNextPage || Models.isFetchingNextPage}
      />
    </AdminSkillBuilderPageWrapper>
  );
}
