import { api, q } from 'config/api';

export default function usePublishSkill(id, overrides) {
  return q.useQuery({
    queryKey: ['skill', id, 'publish'],
    queryFn: () =>
      api(
        `
          query publishSkill($id: ID!) {
            currentDeveloperProfile {
              name
              website
              privacyPolicy
            }
            skill: librarySkill(id: $id) {
              id
              name
              icon
              iconUrl
              color
              published
              versions {
                data {
                  id
                  version
                  publicationState
                  storage
                  env
                  port
                  devices {
                    sound
                    hdmi
                  }
                  ... on SkillVersion {
                    deprecated
                  }
                }
              }
            }
            marketplaceSkill(originSkillId: $id) {
              id
              reviewing
              changesRequested
              featured
              requiresContact
              developerProfile {
                name
                website
                privacyPolicy
              }
              versions {
                data {
                  id
                  version
                  ... on MarketplaceSkillVersion {
                    name
                    color
                    icon
                    iconUrl
                    summary
                    description
                    screenshotUrls
                    licenseUrl
                    manualUrl
                    compatibleFirmwareVersions
                    compatibleDeviceModels
                    releaseNotes
                    originSkillVersionId
                    requestedChanges
                    deprecated
                    creditsCost
                  }
                  storage
                  env
                  port
                  devices {
                    sound
                    hdmi
                  }
                  publicationState
                }
              }
            }
          }
        `,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
