import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Button, DROPDOWN_MENU_PLACEMENT } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import OrganizationSelect from 'components/organization-select';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus, useItemNotFound } from 'hooks';
import ItemNotFound from 'modals/item-not-found';
import { useOrganization, useCurrentOrganization, useOrganizationsLimits } from 'queries';

export default function EditOrganization() {
  useTitle('Edit Organization | Optra');
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const [, { handleChange: handleChangeCurrentOrg }] = useCurrentOrganization();
  const userLimits = useOrganizationsLimits();

  const {
    control,
    handleSubmit: onSubmit,
    register,
    reset,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      name: '',
      parentId: '$$NONE',
    },
  });
  const [error, setError] = useState();

  useInputFocus(setFocus, 'name');

  const {
    isLoading: fetching,
    isSuccess,
    error: fetchError,
    data,
  } = useOrganization(organizationId);

  const updateOrganization = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateOrganization($form: updateOrganizationForm!) {
          updateOrganization(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onError(error) {
      setError(error);
    },
  });

  const removeOrganization = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeOrganization($id: ID!) {
          removeOrganization(id: $id) {
            id
          }
        }`,
        { id },
      ),
  });

  useOnSuccess(
    () => {
      reset({
        name: data?.organization?.name,
        parentId: data?.organization?.parentOrganizations?.data?.at(0)?.parent?.id ?? '$$NONE',
      });
    },
    { isSuccess },
    [data, reset],
  );

  const handleSubmit = onSubmit(async form => {
    setError(null);

    updateOrganization.mutate(
      {
        id: organizationId,
        ...form,
      },
      {
        onSuccess: data => {
          handleChangeCurrentOrg(data?.updateOrganization?.id, '/profile');
        },
      },
    );
  });

  const handleDelete = async event => {
    event.stopPropagation();
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this organization?')) {
      removeOrganization.mutate(organizationId, {
        onSuccess: () => {
          setTimeout(() => {
            handleChangeCurrentOrg('$$NONE');
          }, 500);
          navigate('/profile');
        },
      });
    }
  };

  const loading = fetchError || fetching || updateOrganization.isPending;

  const itemNotFound = useItemNotFound({
    fetching,
    id: data?.organization?.id,
  });
  if (itemNotFound) {
    return <ItemNotFound id={organizationId} type="Organization" />;
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Edit Organization"
        icon="Buildings"
        loading={loading}
        renderActions={() => (
          <Button
            size="xs"
            variant="secondary"
            onClick={handleDelete}
            loading={removeOrganization.isPending}
            icon="Trash"
          >
            Delete
          </Button>
        )}
      />
      <ModalBody className="space-y-6">
        {error && (
          <Message variant="danger" title="Couldn't Create Organization">
            {error.message}
          </Message>
        )}
        {userLimits.data?.profile?.canCreateChildOrganizations && (
          <div className="flex flex-row items-center space-x-2">
            <Label htmlFor="parentOrganization">Parent</Label>
            <Controller
              name="parentId"
              control={control}
              disabled={updateOrganization.isPending}
              render={({ field: { ref, ...field } }) => (
                <OrganizationSelect
                  placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
                  placeholderText="No Parent"
                  allOptionText="No Parent"
                  allOptionValue="$$NONE"
                  allOptionIcon="LinkBreak"
                  filters={{
                    features: { createChildOrganizations: true },
                    excludeOrganizationId: organizationId,
                  }}
                  {...field}
                />
              )}
            />
          </div>
        )}
        <div className="space-y-2">
          <Label htmlFor="name">Organization Name</Label>
          <Input
            type="text"
            {...register('name', { required: 'Please enter a name.' })}
            readOnly={updateOrganization.isPending}
          />
          <ValidationError errors={errors} name="name" />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={updateOrganization.isPending}>
          Save Organization
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
