import { api, q } from 'config/api';

export default function useEvents(variables, ops) {
  return q.useInfiniteQuery({
    queryKey: ['console', variables],
    async queryFn({ pageParam }) {
      const func = await api(
        `
          query Logs($list: listFields) {
            logs(list: $list) {
              data {
                id
                createdAt
                descriptors
                message
                type
              }
              cursor {
                after
              }
            }
          }
        `,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );

      return {
        logs: func.logs.data,
        cursor: func?.logs?.cursor,
      };
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 20000,
    ...ops,
  });
}
