import { flatMap } from 'lodash';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle, useDebounce } from 'react-use';

import { Card, Button, SearchField } from '@optra/kit';

import Checkbox from 'components/checkbox';
import IntersectionLoadMore from 'components/intersection-load-more';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Tab from 'components/tab';
import Tabs from 'components/tabs';
import { api, q, useOnSuccess } from 'config/api';
import { useRouteQueryState } from 'hooks';

export default function AdminMarketplaceEditAccessList(props) {
  useTitle('Admin Marketplace Listing | Optra');

  const {
    Form: { register, setValue, formState },
    submitting,
    subtitle,
  } = props;

  const { skillId } = useParams();
  const { handleClose } = useModalContext();
  const defaultValuesAreSet = useRef({});

  const [search, setSearch] = useRouteQueryState('search', '');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const variables = {
    list: {
      filter: {
        $search: debouncedSearch,
      },
    },
  };

  const {
    isLoading,
    isRefetching,
    isFetchedAfterMount,
    error,
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = q.useInfiniteQuery({
    queryKey: ['organizations', 'accessList', skillId, variables],
    queryFn({ pageParam }) {
      return api(
        `query marketplaceSkillAccessList($list: listFields, $originSkillId: ID!) {
            list: organizations(list: $list) {
              count
              data {
                id
                name
                isSubscribedToMarketplaceSkill(originSkillId: $originSkillId)
              }
              cursor {
                after
              }
            }
          }`,
        {
          ...variables,
          originSkillId: skillId,
          list: {
            ...variables?.list,
            filter: {
              all: true,
              ...variables?.list?.filter,
              excludeOrganizationBySkillOwner: skillId,
            },
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    enabled: !!skillId,
  });

  const organizations = flatMap(data?.pages, page => page?.list?.data);

  useOnSuccess(
    () => {
      organizations.forEach(organization => {
        if (
          defaultValuesAreSet.current[organization.id] ||
          formState.touchedFields?.organizations?.[organization.id]
        )
          return;

        setValue(`organizations.${organization.id}`, organization.isSubscribedToMarketplaceSkill);
        defaultValuesAreSet.current[organization.id] = true;
      });
    },
    { isSuccess },
    [formState.touchedFields?.organizations, organizations, setValue],
  );

  return (
    <ModalInner>
      <ModalTitle title="Access List" subTitle={subtitle} icon="Cards" loading={isLoading} />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Load Organizations">
            {error.message}
          </Message>
        )}

        <Tabs>
          <Tab to="../access-list/organizations">Organizations</Tab>
          <Tab to="../access-list/users">Users</Tab>
        </Tabs>

        <SearchField
          searching={isRefetching && !isFetchedAfterMount}
          value={search}
          onChange={setSearch}
          placeholder="Search Organizations…"
        />

        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            {organizations?.map?.(organization => (
              <Label key={organization.id} className="flex-1">
                <Checkbox
                  {...register(`organizations.${organization.id}`, {
                    onChange(e) {
                      // NOTE: Required for mobile, some devices don't detect the touched field
                      setValue(`organizations.${organization.id}`, e.target.checked, {
                        shouldTouch: true,
                      });
                    },
                  })}
                  className="mr-2"
                  readOnly={submitting}
                />
                {organization.name}
              </Label>
            ))}
          </div>
          <IntersectionLoadMore
            onVisible={fetchNextPage}
            disabled={isLoading || isFetchingNextPage || !hasNextPage}
          />
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button size="xl" onClick={handleClose}>
          Ok
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
