import { Cpu } from '@phosphor-icons/react';
import { isEmpty } from 'lodash';
import { useState, useRef } from 'react';

import {
  Card,
  ButtonWrap,
  Table,
  UiState,
  Button,
  Heading,
  Icon,
  IconButton,
  Text,
  Spinner,
  DetailList,
  Badge,
} from '@optra/kit';

import ItemChooser from 'components/item-chooser';
import useModel from 'queries/models/use-model';
import useModels from 'queries/models/use-models';

export default function ModelsSelect(props) {
  const { onChange, value } = props;

  const chooser = useRef();

  const [search, setSearch] = useState('');

  const Models = useModels({
    list: {
      sort: { direction: 'desc' },
      filter: {
        includeGlobals: true,
        ...(!isEmpty(search) ? { $search: search } : {}),
      },
    },
  });
  const models = Models.data;
  const selectedModel = useModel(value, {
    enabled: !isEmpty(value),
  });

  return (
    <>
      <div>
        <div className="flex items-center justify-between mb-3">
          <div>
            <Heading level={3}>Model</Heading>
          </div>
          <IconButton icon="Plus" variant="tertiary" onClick={() => chooser.current.open()} />
        </div>
        <Card variant="secondary">
          {Models.isLoading && (
            <UiState variant="loading" text="Loading Models" icon={{ component: Cpu }} />
          )}
          {!Models.isLoading && isEmpty(value) && (
            <UiState variant="empty" text="No Model Selected" icon={{ component: Cpu }}>
              <Button
                size="xs"
                variant="tertiary"
                icon="Plus"
                onClick={() => chooser.current.open()}
              >
                ADD
              </Button>
            </UiState>
          )}
          {!Models.isLoading && !isEmpty(value) && (
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.TD collapsed>
                    <Cpu size={40} />
                  </Table.TD>
                  <Table.TD>
                    {!selectedModel.isLoading && (
                      <>
                        <Text size="sm">{selectedModel.data?.model?.name}</Text>
                        <DetailList
                          details={[
                            {
                              title: 'Model Origin',
                              component: (
                                <Badge
                                  size="xs"
                                  variant={
                                    selectedModel.data?.model?.global ? 'primary' : 'secondary'
                                  }
                                  className="mt-1"
                                >
                                  {selectedModel.data?.model?.global ? 'Global' : 'Personal'}
                                </Badge>
                              ),
                            },
                          ]}
                        />
                      </>
                    )}
                    {selectedModel.isLoading && <Spinner size="sm" color="white" />}
                  </Table.TD>
                  <Table.TD collapsed>
                    <ButtonWrap
                      as="div"
                      className="opacity-50 hover:opacity-100"
                      onClick={() => onChange(true, selectedModel)}
                    >
                      <Icon name="XCircle" size="sm" weight="duotone" />
                    </ButtonWrap>
                  </Table.TD>
                </Table.Row>
              </Table.Body>
            </Table>
          )}
        </Card>
      </div>
      <ItemChooser
        ref={chooser}
        items={models}
        itemsInfiniteQuery={Models}
        isLoading={models?.isLoading}
        onSelect={onChange}
        isSelected={model => {
          return model?.id === value;
        }}
        search={search}
        onSearch={setSearch}
        emptyIcon="Cpu"
        heading="Choose Model"
        loadErrorTitle="Couldn't Load Model"
        RowDataComponent={({ item }) => (
          <>
            <Table.TD collapsed>
              <Cpu size={40} />
            </Table.TD>
            <Table.TD>
              <Text size="sm">{item.name}</Text>
              <DetailList
                details={[
                  {
                    title: 'Model Origin',
                    component: (
                      <Badge size="xs" variant="secondary" className="m-1">
                        {item?.global ? 'Global' : 'Personal'}
                      </Badge>
                    ),
                  },
                ]}
              />
            </Table.TD>
          </>
        )}
      />
    </>
  );
}
