import * as date from 'date-fns';

import { Card, StatusDot, Text } from '@optra/kit';

import CreditsRequiredBanner from 'components/credits-required-banner';
import DeviceImage from 'components/device-image';
import IntersectionLoadMore from 'components/intersection-load-more';
import ActivateLicenseToggle from 'components/licenses/activate-license-toggle';
import { useCurrentBillingAccount } from 'queries';
import useSkillLicenses from 'queries/use-skill-licenses';

export default function MarketplaceSkillInstalled(props) {
  const { marketplaceSkillId, marketplaceSkill } = props;
  const billingAccount = useCurrentBillingAccount();
  const currentBillingAccount = billingAccount?.data?.currentBillingAccount;
  const { balance } = currentBillingAccount?.balances || 0;
  const SkillLicenses = useSkillLicenses({ filter: { marketplaceSkillId } });

  return (
    <div>
      <CreditsRequiredBanner required={marketplaceSkill.currentCreditCost} balance={balance} />
      <div className="pt-4 space-y-2">
        {SkillLicenses.data?.map(license => {
          const { device } = license;
          const activeUntil = date.format(new Date(license.activeUntil), 'MMM dd, yyyy');

          return (
            <Card
              key={license.id}
              variant="secondary"
              className="flex items-center justify-between gap-4"
            >
              <div className="flex items-center gap-2">
                <DeviceImage model={device?.model} size="sm" hideStatus />
                <Text>{device?.name}</Text>
              </div>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <StatusDot className={license.active ? 'bg-green' : 'bg-red'} />
                  <Text>{license.active ? 'Active' : 'Inactive'}</Text>
                </div>
                {license.active && !license.disabled && <Text>Renews {activeUntil}</Text>}
                {license.active && license.disabled && <Text>Ends {activeUntil}</Text>}
                {!license.active && license.disabled && <Text>Ended {activeUntil}</Text>}
                {!license.active && !license.disabled && <Text>Insufficient Credit</Text>}
                <ActivateLicenseToggle
                  id={license.id}
                  disabled={license.disabled}
                  label=""
                  marketplaceSkillId={marketplaceSkillId}
                />
              </div>
            </Card>
          );
        })}
        <IntersectionLoadMore
          onVisible={SkillLicenses.fetchNextPage}
          disabled={SkillLicenses.isFetchingNextPage || !SkillLicenses.hasNextPage}
        />
      </div>
    </div>
  );
}
