import { DROPDOWN_MENU_PLACEMENT, FilterContainer, DetailHeading, Panel } from '@optra/kit';

import { useChartCtx, useChartInterface } from '../context';
import ControlDates from '../controls/control-dates';
import ControlGranularity from '../controls/control-granularity';

export default function ChartHeading() {
  const {
    state: { isCustomQuery, showFrequency },
    configuration: { readOnly },
    methods: { generateNameFromConfig },
  } = useChartCtx();
  const {
    state: { isMobile },
  } = useChartInterface();
  const subHeading = generateNameFromConfig({
    includePeriod: isMobile,
    includePeriodBin: isMobile,
  });

  if (!subHeading) return null;

  return (
    <Panel.Col className="space-y-5">
      <Panel.Row wrap={true} alignItems>
        <DetailHeading level={3} wrap={true} variant="loud">
          {isCustomQuery ? 'Custom Query' : subHeading}
        </DetailHeading>
      </Panel.Row>
      {!isCustomQuery && (
        <Panel.Row wrap={true} alignItems className="justify-between">
          <Panel.Col collapse>
            <ControlDates variant="plain" className="animate-fade-in" />
          </Panel.Col>
          {!isMobile && !readOnly && (
            <Panel.Col collapse>
              <Panel.Row className="space-x-4" alignItems>
                <FilterContainer>
                  {showFrequency && (
                    <ControlGranularity
                      variant="plain"
                      placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
                      className="animate-fade-in"
                    />
                  )}
                </FilterContainer>
              </Panel.Row>
            </Panel.Col>
          )}
        </Panel.Row>
      )}
    </Panel.Col>
  );
}
