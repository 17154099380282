import { api, q } from 'config/api';

export default function useSkillBuilderBetaNodes() {
  return q.useInfiniteQuery({
    queryKey: ['skillBuilderBetaNodes'],
    queryFn({ pageParam }) {
      return api(
        `query skillBuilderBetaNodes($list: listFields) {
          list: skillBuilderBetaNodes(list: $list) {
            count
            data {
              id
              name
              label
              description
              icon
              type
              inputs
              outputs
              configFields
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => data.pages.flatMap(page => page.list.data),
  });
}
