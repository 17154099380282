import * as Sentry from '@sentry/react';
import Lockr from 'lockr';
import { Logout } from 'react-auth0rize';

export default function LogoutPage() {
  Lockr.rm('intendedRoute');
  Lockr.rm('organizationId');
  window.sessionStorage.clear();
  Sentry.configureScope(scope => scope.setUser(null));
  return <Logout />;
}
