import { useState, useEffect } from 'react';

export function parseFileUrl(fileOrUrl) {
  return fileOrUrl instanceof File ? URL.createObjectURL(fileOrUrl) : fileOrUrl;
}

export default function useParseFileUrl(fileOrUrl) {
  const [iconUrl, setIconUrl] = useState(parseFileUrl(fileOrUrl));
  useEffect(() => {
    setIconUrl(parseFileUrl(fileOrUrl));
  }, [fileOrUrl]);

  return iconUrl;
}
