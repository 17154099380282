import { Routes, Route } from 'react-router-dom';

import AddCreditsChoose from 'modals/add-credits-choose';
import AddCreditsEditCard from 'modals/add-credits-edit-card';
import AddCreditsNewCard from 'modals/add-credits-new-card';
import AddCreditsPayment from 'modals/add-credits-payment';

export default function AddCredits() {
  return (
    <Routes>
      <Route index element={<AddCreditsChoose />} />
      {/* FUTURE ROUTE:
        <Route path="refill-settings" element={<AddCreditsRefillSettings />} />
      */}
      <Route path="payment" element={<AddCreditsPayment />} />
      <Route path="edit-card/:paymentMethodId" element={<AddCreditsEditCard />} />
      <Route path="new-card" element={<AddCreditsNewCard />} />
    </Routes>
  );
}
