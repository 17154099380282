import isEmpty from 'lodash/isEmpty';

import EmptyContentMessage from 'components/empty-content-message';
import MarketplaceGridItem from 'components/marketplace-grid-item';
import Message from 'components/message';

function MarketplaceList({ canCreate, fetching = true, error, marketplaceSkills = [] }) {
  if (fetching || error) {
    return (
      <>
        {error && (
          <Message variant="danger" title="Couldn't Load Skills">
            {error.message}
          </Message>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 animate-pulse">
          <MarketplaceGridItem />
          <MarketplaceGridItem />
          <MarketplaceGridItem />
        </div>
      </>
    );
  }

  if (isEmpty(marketplaceSkills) && !fetching) {
    return <EmptyContentMessage icon="SquaresFour" title="No Skills Available Yet" />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {marketplaceSkills.map(marketplaceSkill => (
        <MarketplaceGridItem key={marketplaceSkill.id} marketplaceSkill={marketplaceSkill} />
      ))}
    </div>
  );
}

export default MarketplaceList;
