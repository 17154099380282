import { Button, Spinner } from '@optra/kit';

import FlexCenter from 'components/flex-center';

export default function PaginationButtons({ loading, onPrevPage, onNextPage, hasPrev, hasNext }) {
  return (
    <FlexCenter className="my-4 space-x-3">
      <Button variant="tertiary" onClick={onPrevPage} icon="CaretLeft" disabled={!hasPrev}>
        Prev
      </Button>
      {loading && <Spinner size="sm" />}
      <Button variant="tertiary" onClick={onNextPage} iconRight="CaretRight" disabled={!hasNext}>
        Next
      </Button>
    </FlexCenter>
  );
}
