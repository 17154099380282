import { api, q } from 'config/api';

export default function useProvisionedDevice(id, overrides) {
  return q.useQuery({
    queryKey: ['provisionedDevice', id],
    queryFn: () =>
      api(
        `query provisionedDevice($id: ID) {
          provisionedDevice (id: $id) {
            id
            claimable
            model
            serialNumber
            device {
              id
              name
              location {
                id
                name
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
