import { flatMap, includes } from 'lodash';

import { Dropdown, Filter, UiState, Badge, Button } from '@optra/kit';

import { QUERY_OVERRIDES } from 'modals/chart-details/data';
import { useWorkflows } from 'queries';

export default function ControlFilterWorkflows(props) {
  const { selectedWorkflowIds, workflowsQuery, onClear, onSelect, ...rest } = props;
  const { data, error, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useWorkflows(
    {
      list: {
        sort: {
          by: 'createdAt',
          direction: 'desc',
        },
      },
    },
    QUERY_OVERRIDES,
  );

  const workflows = flatMap(data?.pages, page => page?.list?.data);

  return (
    <Filter
      label="Workflows"
      loading={isLoading}
      onClear={onClear}
      menu={{
        text: !!(selectedWorkflowIds && selectedWorkflowIds.length) && (
          <Badge className="pl-2 pr-2">{selectedWorkflowIds.length}</Badge>
        ),
        body: isLoading ? (
          <UiState margin={false} className="m-10" />
        ) : error ? (
          <UiState variant="error" margin={false} className="m-10" />
        ) : !workflows?.length ? (
          <UiState variant="empty" text="No Workflows" margin={false} className="m-10" />
        ) : (
          <>
            {workflows.map(workflow => {
              const { name, id } = workflow;
              const active = selectedWorkflowIds && includes(selectedWorkflowIds, id);
              return (
                <Dropdown.Item
                  uppercase={false}
                  key={id}
                  text={name}
                  active={active}
                  onClick={e => {
                    onSelect(id);
                    e.preventDefault();
                  }}
                  selectable
                />
              );
            })}
            {hasNextPage && (
              <div className="flex justify-center py-3">
                <Button
                  variant="plain"
                  size="xs"
                  className="p-0 text-primary"
                  onClick={fetchNextPage}
                  loading={isFetchingNextPage}
                >
                  Load More
                </Button>
              </div>
            )}
          </>
        ),
      }}
      {...rest}
    />
  );
}
