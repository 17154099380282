import { uniq } from 'lodash';

import { Button, Card, Heading, Table, Text } from '@optra/kit';

import Label from 'components/label';
import StepperInput from 'components/stepper-input';
import useInputLabels from 'modals/skill-pipeline-builder/context/use-input-labels';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

function SelectOption(props) {
  const { selected, onClick, label } = props;

  return (
    <Table.Row selectable selected={selected} onClick={onClick}>
      <Table.TD>
        <Text size="sm">{label}</Text>
      </Table.TD>
    </Table.Row>
  );
}

export default function LabelAverageProcessor(props) {
  const { nodeId } = props;
  const [node, { update, hasInput }] = usePipelineNode(nodeId);
  const labels = useInputLabels(node, 'label_counts');
  const allLabelsSelected = labels.every(label => node.config?.label_list?.includes?.(label));

  return (
    <div className="space-y-3">
      <Heading level={3}>Config</Heading>
      <Card variant="secondary" className="space-y-6">
        <Label htmlFor="windowSize" className="mb-2">
          Frames per Calculation
        </Label>
        <StepperInput
          id="windowSize"
          value={node.config?.windowSize ?? ''}
          onChange={value => {
            update({
              key: 'config.windowSize',
              value,
            });
          }}
          min={0}
        />

        {hasInput && (
          <div>
            <div className="flex flex-row items-center justify-between mb-3">
              <Label>Labels</Label>
              <Button
                variant={allLabelsSelected ? 'primary' : 'tertiary'}
                icon={allLabelsSelected ? 'X' : 'Checks'}
                size="xs"
                onClick={() => {
                  if (allLabelsSelected) {
                    update({
                      key: 'config.label_list',
                      value: [],
                    });
                  } else {
                    update({
                      key: 'config.label_list',
                      value: [...labels],
                    });
                  }
                }}
              >
                {allLabelsSelected ? 'Clear All' : 'Select All'}
              </Button>
            </div>
            <Table variant="secondary">
              <Table.Body>
                {labels.map(label => {
                  const current = node.config?.label_list || [];
                  const selected = current.includes(label);
                  return (
                    <SelectOption
                      key={label}
                      label={label}
                      selected={selected}
                      onClick={() => {
                        if (selected) {
                          update({
                            key: 'config.label_list',
                            value: current.filter(lbl => lbl !== label),
                          });
                          return;
                        }
                        update({
                          key: 'config.label_list',
                          value: uniq([...current, label]),
                        });
                      }}
                    />
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
      </Card>
    </div>
  );
}
