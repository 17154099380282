import cx from 'classnames';
import { compact, noop, find, uniq } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';

import { DetailHeading, Card, Icon, Tooltip, Text, ButtonWrap, UiState } from '@optra/kit';

import { api, q } from 'config/api';
import Chart from 'modals/chart-details/chart/chart';
import ChartDetails, { helpers as chartHelpers } from 'modals/chart-details/chart-details';
import ChartLayout from 'modals/chart-details/chart-layout';
import { useChartCtx } from 'modals/chart-details/context/chart-context';
import {
  CHARTS,
  TIMEFRAMES,
  SMART_TIMEFRAMES,
  CALCULATIONS,
  TIMEFRAME_BINS,
} from 'modals/chart-details/data';
import helpers from 'modals/chart-details/helpers';

export const LAYOUT_VARIANTS = {
  full: 'full',
  compact: 'compact',
};

export const CARD_GRID = 'grid grid-cols-6 auto-rows-max';
export const CARD_GAP = 'gap-4 md:gap-6 lg:gap-8';

function ExpandToggle(props) {
  const { expanded, onClick, className } = props;
  const [isHovered, setIsHovered] = useState();
  const classNames = [
    'w-1 h-6',
    isHovered ? 'bg-gray-300 dark:bg-gray-500' : 'bg-gray-200 dark:bg-gray-800',
    'rounded-full',
    'transition-all',
    'ease-in-out',
  ];

  return (
    <Tooltip label={expanded ? 'Compact' : 'Full Width'}>
      <ButtonWrap
        as="span"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onClick}
        className={cx(
          'flex items-center justify-center w-7',
          'animate-fade-in',
          'cursor-pointer',
          className,
        )}
      >
        <span
          className={cx(
            'inline-flex flex-col items-center leading-none',
            !expanded && 'rotate-180',
          )}
        >
          <div className={cx(classNames, 'translate-y-[2px]', isHovered && 'rotate-[20deg]')} />
          <div className={cx(classNames, '-translate-y-[2px]', isHovered && ' -rotate-[20deg]')} />
        </span>
      </ButtonWrap>
    </Tooltip>
  );
}

function CallOutTotal(props) {
  const {
    total,
    metric,
    timeframeBin = {},
    calculation = {},
    align = 'right',
    large,
    className,
  } = props;
  const detailString = compact([metric, calculation?.type, timeframeBin?.name]).join(', ');
  return (
    <div
      className={cx(
        'inline-flex flex-col space-y-1',
        align === 'right' ? 'items-end' : 'items-start',
        className,
      )}
    >
      {!!(!large && detailString) && (
        <DetailHeading as="span" bold={false} wrap style={{ fontSize: 11 }}>
          {detailString}
        </DetailHeading>
      )}
      <Text
        className={cx(
          'text-3xl font-semibold inline-block',
          large
            ? [
                'text-5xl',
                'text-transparent bg-clip-text bg-gradient-to-r',
                'from-primary-400 to-primary-400 dark:from-primary-200 dark:to-primary-500',
              ]
            : '3xl',
        )}
      >
        {total}
      </Text>
      {!!(large && detailString) && (
        <DetailHeading as="span" bold={false} wrap style={{ fontSize: 11 }} className="mt-1">
          {detailString}
        </DetailHeading>
      )}
    </div>
  );
}

function TimeFrame({ timeframe }) {
  if (!timeframe) return null;
  const { start, end } = timeframe;
  const ALL_TIMEFRAMES = { ...SMART_TIMEFRAMES, ...TIMEFRAMES };
  return (
    <div className="text-lg flex items-center flex-nowrap whitespace-nowrap space-x-2 font-medium">
      {ALL_TIMEFRAMES[start] ? (
        <Text>
          {ALL_TIMEFRAMES[start].name}{' '}
          {ALL_TIMEFRAMES[start].description && (
            <span className="text-primary font-light dark:font-thin">
              {ALL_TIMEFRAMES[start].description}
            </span>
          )}
        </Text>
      ) : (
        <>
          <Text>
            {chartHelpers.formatTime(start, 'MMMM Do')}{' '}
            <span className="text-primary font-light dark:font-thin">
              {chartHelpers.formatTime(start, 'YYYY')}
            </span>
          </Text>
          <Icon name="ArrowRight" size="sm" weight="regular" color="gray" />
          <Text>
            {chartHelpers.formatTime(end, 'MMMM Do')}{' '}
            <span className="text-primary font-light dark:font-thin">
              {chartHelpers.formatTime(end, 'YYYY')}
            </span>
          </Text>
        </>
      )}
    </div>
  );
}

function CardHeading({ text, timeframe, detail, draggable = true, className, loading }) {
  return (
    <div className={cx('flex items-center animate-fade-in', className)}>
      {draggable && (
        <div className="flex-0">
          <Icon
            name="DotsSixVertical"
            weight="bold"
            size="sm"
            className={cx(
              'dragHandle',
              'cursor-move',
              '-mx-1 mr-2 hidden md:inline-block',
              'text-gray-400 hover:text-gray-700 dark:text-gray-600 hover:dark:text-gray-300',
              !detail && '-mt-1',
            )}
            onClick={e => e.stopPropagation()}
          />
        </div>
      )}
      <div className="flex-1">
        {!!loading ? (
          <div className="flex flex-col space-y-2 animate-fade-in">
            <div className="h-6 w-24 bg-gray-100 dark:bg-white/10 rounded-md animate-pulse"></div>
            {!!detail && (
              <div className="h-3 w-1/3 bg-gray-100 dark:bg-white/10 rounded-md animate-pulse"></div>
            )}
          </div>
        ) : (
          <>
            <div className="flex justify-between animate-fade-in">
              <Text className="font-medium text-lg">{text || 'Untitled'}</Text>
              {!!timeframe?.start && <TimeFrame timeframe={timeframe} />}
            </div>
            {!!detail && (
              <DetailHeading
                as="span"
                bold={false}
                uppercase={false}
                wrap
                className="animate-fade-in"
              >
                {detail}
              </DetailHeading>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function ChartProviderCard(props) {
  const {
    workspaceId,
    workspaceChart: { id: workspaceChartId, chart, layout },
    showExpandToggle,
    padding = 'p-7',
    className,
    style = {},
    children,
  } = props;
  const {
    configuration: { readOnly },
  } = useChartCtx();
  const navigate = useNavigate();

  if (!chart) return null;

  const { id } = chart;

  const isFullWidth = layout?.variant === LAYOUT_VARIANTS.full;

  const qc = q.useQueryClient();
  const updateWorkspaceChart = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkspaceChart($form: updateWorkspaceChartForm!) {
          workspaceChart: updateWorkspaceChart(form: $form) {
            id,
            layout
          }
        }`,
        { form },
      ),
    onMutate: async form => {
      await qc.cancelQueries({ queryKey: ['workspace', workspaceId] });

      qc.setQueryData(['workspace', workspaceId], old => ({
        workspace: helpers.assignWorkspace(old.workspace, {
          workspaceCharts: { data: [form] },
        }),
      }));

      return { form }; // TODO: This is useless, should be current data instead
    },
    onSettled: () => qc.invalidateQueries({ queryKey: ['workspace', workspaceId] }),
  });

  const handleToggleWidth = e => {
    updateWorkspaceChart.mutate({
      id: workspaceChartId,
      layout: {
        variant:
          layout?.variant === LAYOUT_VARIANTS.full ? LAYOUT_VARIANTS.compact : LAYOUT_VARIANTS.full,
      },
    });
    e.stopPropagation();
  };

  return (
    <Card
      as="button"
      variant="subtle"
      className={cx(
        'ChartProviderCard',
        'relative',
        'overflow-hidden',
        'cursor-pointer',
        'focus-visible',
        className,
      )}
      noPadding
      onClick={() => navigate(`chart/${id}/edit`)}
      style={{
        textAlign: 'inherit',
        ...style,
      }}
    >
      <div className={cx('relative h-full w-full', padding)}>{children}</div>
      {!!showExpandToggle && !readOnly && (
        <ExpandToggle
          className="absolute right-0 top-1/2 -translate-y-1/2 hidden md:flex "
          expanded={isFullWidth}
          onClick={handleToggleWidth}
        />
      )}
    </Card>
  );
}

function ChartsWorkspaceNumberCard(props) {
  const { workspaceChart = {}, className, workspaceId } = props;
  const { chart } = workspaceChart;
  const {
    state: { loading, parsedQueryResults = {} },
    helpers: { getChartConfig },
    configuration: { readOnly },
  } = useChartCtx();

  const { values = {} } = parsedQueryResults;
  const { metrics = [], timeframe: _timeframe, timeframeBin: _timeframeBin } = getChartConfig();

  const primaryMetric = metrics[0] || {};
  const { metric, calculation: _calculation } = primaryMetric;
  const Variable = helpers.parseVariable(metric);
  const metricName = Variable?.meta?.name || '';

  const calculation = CALCULATIONS[_calculation];
  const timeframeBin = TIMEFRAME_BINS[_timeframeBin];

  const total = values?.metrics ? values?.metrics?.[Variable?.column]?.total : 0;

  return (
    <ChartProviderCard
      className={cx('row-span-1', 'col-span-6 md:col-span-3', className)}
      showExpandToggle={false}
      workspaceChart={workspaceChart}
      workspaceId={workspaceId}
    >
      <div className="space-y-3">
        <CardHeading
          text={chart?.name}
          timeframe={_timeframe}
          draggable={!readOnly}
          loading={loading}
        />
        {!!loading && <UiState center />}
        <CallOutTotal
          total={helpers.formatNumber(total)}
          calculation={calculation}
          metric={metricName}
          timeframeBin={calculation?.name === CALCULATIONS.AVG.name ? timeframeBin : undefined}
          align="left"
          large
          className={cx(loading ? 'opacity-0' : 'opacity-100', 'transition-opacity')}
        />
      </div>
    </ChartProviderCard>
  );
}

function ChartsWorkspaceCard(props) {
  const { workspaceId, workspaceChart = {}, className } = props;
  const { chart, layout } = workspaceChart;
  const isFullWidth = layout?.variant === LAYOUT_VARIANTS.full;

  const {
    methods: { generateNameFromConfig },
    helpers: { getChartConfig },
    configuration: { readOnly, showInterface },
    state: { loading },
  } = useChartCtx();

  const { timeframe } = getChartConfig();
  const detail = generateNameFromConfig({
    includeCalculation: false,
    includePeriod: false,
    includePeriodBin: true,
  });

  // TODO: this logic more likely belongs within chart
  const chartComponent = showInterface ? <ChartLayout /> : <Chart />;
  return (
    <ChartProviderCard
      className={cx(
        'row-span-2',
        'col-span-6',
        isFullWidth ? 'md:col-span-6' : 'md:col-span-3',
        className,
      )}
      padding="p-7 pb-0"
      showExpandToggle
      workspaceChart={workspaceChart}
      workspaceId={workspaceId}
    >
      <div className="flex flex-col items-stretch justify-between space-y-4">
        <div className="flex-0">
          <CardHeading
            text={chart?.name}
            timeframe={timeframe}
            detail={detail}
            draggable={!readOnly}
            loading={loading}
          />
        </div>
        <div className="flex-1">
          <div
            className={cx(
              'relative overflow-hidden',
              !loading && 'animate-fade-in',
              isFullWidth ? 'h-96' : 'h-64',
            )}
          >
            {loading ? <UiState center /> : chartComponent}
          </div>
        </div>
      </div>
    </ChartProviderCard>
  );
}

export default function ChartsWorkspaceCardList(props) {
  const { workspaceCharts = [], workspaceId, className } = props;

  const qc = q.useQueryClient();
  const updateWorkspaceChartOrder = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkspace($form: updateWorkspaceForm!) {
          workspace: updateWorkspace(form: $form) {
            id
            layout
          }
        }`,
        { form },
      ),
    onMutate: async workspace => {
      await qc.cancelQueries({ queryKey: ['workspace', workspaceId] });

      qc.setQueryData(['workspace', workspaceId], old => ({
        workspace: helpers.assignWorkspace(old.workspace, workspace),
      }));

      return { workspace };
    },
    onSettled: () => {
      qc.invalidateQueries({ queryKey: ['workspace', workspaceId] });
    },
  });

  if (!Array.isArray(workspaceCharts)) {
    return null;
  }
  const onDragDropEnds = ({ oldIndex, newIndex }) => {
    const newList = [...workspaceCharts];
    const item = newList.splice(oldIndex, 1)[0];
    newList.splice(newIndex, 0, item);
    const ids = newList.map(({ id }) => id);
    updateWorkspaceChartOrder.mutate({
      id: workspaceId,
      layout: {
        order: uniq(compact(ids.map(id => (find(workspaceCharts, ['id', id]) ? id : undefined)))),
      },
    });
  };

  return (
    <ReactSortable
      list={workspaceCharts}
      tag="div"
      ghostClass="sortable-ghost" // Class name for the drop placeholder
      chosenClass="sortable-chosen" // Class name for the chosen item
      dragClass="sortable-drag" // Class name for the dragging item
      handle=".dragHandle"
      animation={150}
      easing="ease-in-out"
      className={cx(CARD_GRID, CARD_GAP, className)}
      setList={noop}
      onEnd={onDragDropEnds}
    >
      {workspaceCharts.map(workspaceChart => {
        const primaryMetric = workspaceChart?.chart?.config?.metrics?.[0];
        if (primaryMetric?.type === CHARTS.number.type) {
          return (
            <ChartDetails
              key={workspaceChart.id}
              chartId={workspaceChart.chart?.id}
              configuration={{
                showInterface: false,
                inModal: false,
                preserveXAxis: true,
              }}
            >
              <ChartsWorkspaceNumberCard
                workspaceChart={workspaceChart}
                workspaceId={workspaceId}
              />
            </ChartDetails>
          );
        }

        return (
          <ChartDetails
            key={workspaceChart.id}
            chartId={workspaceChart.chart?.id}
            configuration={{
              showInterface: false,
              inModal: false,
              preserveXAxis: true,
            }}
          >
            <ChartsWorkspaceCard workspaceChart={workspaceChart} workspaceId={workspaceId} />
          </ChartDetails>
        );
      })}
    </ReactSortable>
  );
}
