import { Table, Button } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Message from 'components/message';
import UserListItem from 'components/user-list-item';
import { useCurrentUser } from 'queries';

export default function UsersList({ fetching, error, members = [], canCreate }) {
  const [currentUser] = useCurrentUser();

  if (error) {
    return (
      <Message variant="danger" title="Couldn't Load Users">
        {error.message}
      </Message>
    );
  }

  if ((!members || members.length < 1) && !fetching) {
    return (
      <EmptyContentMessage
        icon="Users"
        title="No Users"
        renderAction={() =>
          canCreate && (
            <Button to="/users/create" icon="Plus">
              New User
            </Button>
          )
        }
      />
    );
  }

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.TH />
          <Table.TH>Name</Table.TH>
          <Table.TH>Roles</Table.TH>
          {currentUser?.isSysAdmin && <Table.TH>Hidden</Table.TH>}
          <Table.TH />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {members.map(member => (
          <UserListItem key={member.id} member={member} />
        ))}
      </Table.Body>
    </Table>
  );
}
