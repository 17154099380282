import { api, q } from 'config/api';

export default function useOrganization(id, overrides) {
  return q.useQuery({
    queryKey: ['organization', id],
    queryFn: () =>
      api(
        `query organization($id: ID!) {
          organization(id: $id) {
            id
            name
            pricing
            parentOrganizations {
              data {
                parent {
                  id
                  name
                }
              }
            }
            features {
              data {
                enabled
                enabledAsConfigured
                feature {
                  id
                  label
                }
              }
            }
          }
          deviceSkillSyncLimit: ownerEntitlement(ownerId: $id, entitlement: deviceSkillSyncLimit) {
            value
          }
          deviceSyncLimit: ownerEntitlement(ownerId: $id, entitlement: deviceSyncLimit) {
            value
          }
          edgeImpulseEnterpriseAllowance: ownerEntitlement(ownerId: $id, entitlement: edgeImpulseEnterpriseAllowance) {
            value
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
