import * as date from 'date-fns';

import { Icon, Text, StatusIndicator } from '@optra/kit';

import DeviceImage from 'components/device-image';
import { useFeature } from 'components/feature';
import ListItem from 'components/list-item';
import SyncProgressDeviceSkillStatus from 'components/sync-progress-device-skill-status';
import { STATUS_MAP, STATUS_LABEL_MAP } from 'config/deployment-task-status-maps';

export default function SyncProgressDeviceListItem({ task }) {
  const skillStatusEnabled = useFeature('skillStatus');

  return (
    <ListItem
      to={
        task.status !== 'queued'
          ? {
              pathname: `./tasks/${task.id}`,
              state: { fromModal: true },
            }
          : undefined
      }
      renderLeft={() => <DeviceImage model={task.device?.model} size="md" hideStatus />}
      renderRight={() =>
        task.status !== 'queued' ? (
          <Icon name="CaretRight" weight="line" />
        ) : (
          // Reserve this space to ensure status is aligned
          <div className="w-6"></div>
        )
      }
      className="flex flex-row flex-nowrap"
    >
      <div className="flex items-center justify-between space-x-4">
        <div className="flex items-center flex-1">{task.device?.name || '(Deleted device)'}</div>

        <div className="flex items-center md:flex-1">
          <StatusIndicator status={STATUS_MAP[task?.status]} size="md" className="mr-3" />
          <Text size="xs" color="muted" variant="label" className="hidden md:inline">
            {STATUS_LABEL_MAP[task?.status]}
            {task?.status === 'complete' && task?.completedAt && (
              <span className="ml-2">({date.format(task?.completedAt, 'M/d/yy H:mm')})</span>
            )}
          </Text>
          <div className="flex items-center ml-8">
            {!!task?.device && task?.status === 'complete' && skillStatusEnabled && (
              <SyncProgressDeviceSkillStatus taskId={task.id} />
            )}
          </div>
        </div>
      </div>
    </ListItem>
  );
}
