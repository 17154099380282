import { isEmpty } from 'lodash';

import { Button, Icon, Text, Spinner } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import FlexCenter from 'components/flex-center';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import PaymentMethodListItem from 'components/payment-method-list-item';
import { usePaymentMethods } from 'queries';

export default function ProfilePaymentMethods(props) {
  const { data, isLoading: fetching, error } = usePaymentMethods();
  const { addCreditCardPath = '../new-card', editCreditCardRootPath = '../edit-card' } =
    props || {};

  return (
    <div>
      <Text variant="label" color="muted" className="block text-xs mb-2">
        Payment Methods
      </Text>

      {error && (
        <Message variant="danger" title="Couldn't Load Billing">
          {error.message}
        </Message>
      )}

      {!fetching && isEmpty(data?.paymentMethods) && (
        <EmptyContentMessage
          icon="CreditCard"
          title="No Payment Methods"
          variant="secondary"
          renderAction={() => <Button to={addCreditCardPath}>Add Credit Card</Button>}
        />
      )}

      {fetching && (
        <FlexCenter>
          <Spinner size="lg" color="gradient" />
        </FlexCenter>
      )}

      <List>
        {!isEmpty(data?.paymentMethods) &&
          data?.paymentMethods?.map(paymentMethod => (
            <PaymentMethodListItem
              key={paymentMethod.id}
              id={paymentMethod.id}
              processor={paymentMethod.processor}
              expiration={paymentMethod.expiration}
              isDefault={paymentMethod.default}
              last4={paymentMethod.last4}
              to={`${editCreditCardRootPath}/${paymentMethod.id}`}
            />
          ))}
        {!isEmpty(data?.paymentMethods) && (
          <ListItem
            to={addCreditCardPath}
            state={{ fromModal: true }}
            renderLeft={() => <Icon name="PlusCircle" color="gradient" />}
            renderRight={() => <Icon name="CaretRight" weight="line" />}
          >
            <Text>New Credit Card</Text>
          </ListItem>
        )}
      </List>
    </div>
  );
}
