import { Card, Text } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Textarea from 'components/textarea';
import ValidationError from 'components/validation-error';

export default function PublishSkillDescription(props) {
  const { form, skill, disabled } = props;
  return (
    <Card variant="secondary" className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="summary">Summary</Label>
        <Input
          type="text"
          {...form.register('summary', {
            required: 'Please enter a summary',
          })}
          readOnly={skill.isLoading}
          disabled={disabled}
        />
        <ValidationError name="summary" errors={form.formState.errors} />
      </div>

      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <Label htmlFor="description">Description</Label>
          <Text size="xs" color="muted" className="underline">
            <a target="_blank" rel="noreferrer" href="https://commonmark.org/help">
              Formatting Help
            </a>
          </Text>
        </div>
        <Textarea
          rows={6}
          {...form.register('description', { required: 'Please enter description.' })}
          readOnly={form.formState.isSubmitting}
          disabled={disabled}
        />
        <ValidationError errors={form.formState.errors} name="description" />
      </div>
    </Card>
  );
}
