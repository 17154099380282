import { api, q } from 'config/api';

export default function useDevices(variables, overrides, includeOutputs = false) {
  return q.useInfiniteQuery({
    queryKey: ['devices', variables],
    queryFn({ pageParam }) {
      return api(
        `query devices($list: listFields, $stateMapping: deviceMapStateMappings) {
          list: devices(list: $list) {
            count
            data {
              id
              createdAt
              name
              model
              status
              autoClaim
              location {
                name
                lat
                lng
                address {
                  address
                  locality
                }
              }
              tags {
                data {
                  tag {
                    id
                    name
                  }
                }
              }
              workflows {
                data {
                  id
                  name
                  skills {
                    data {
                      id
                      name
                      version {
                        version
                      }
                      skill {
                        id
                        name
                        icon
                        iconUrl
                        color
                      }
                    }
                  }
                }
              }
              firmware {
                version
                releaseNotes
                updateAvailable
                isUpdating
                latestAvailableFirmware {
                  version
                  releaseNotes
                }
              }
              downtime {
                down
                occurrences
                downAt
                recoveredAt
              }
              disk {
                usage
              }
              ram {
                usage
              }
              cpu {
                usage
              }
              ip
              mac
              headphoneJack
              internalTemperatureUsage
              serialNumber
              provisionedDevice {
                warrantyStartedAt
              }
              subscriptionStatus
              mapState(stateMapping: $stateMapping)
              ${
                includeOutputs
                  ? `skillOutputs {
                output1 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output2 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output3 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output4 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output5 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output6 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output7 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output8 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output9 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output10 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output11 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output12 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output13 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output14 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output15 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output16 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output17 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output18 {
                  key
                  value
                  skill {
                    name
                  }
                }
                output19 {
                  key
                  value
                  skill {
                    name
                  }
                }
              }`
                  : ''
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
    ...overrides,
  });
}
