import { useNavigate, useParams } from 'react-router-dom';

import { Icon, PageWrapper, PrimaryCTAButton, Text } from '@optra/kit';
import { CopyButton } from '@optra/kit';

import AdminGiftCardDetail from 'components/admin-gift-card-detail';
import { api, q } from 'config/api';
import useGiftCard from 'queries/use-gift-card';

export default function AdminResellerCodeGiftCardDetail() {
  const navigate = useNavigate();
  const { giftCardId, purchaseOrderId } = useParams();
  const giftCard = useGiftCard(giftCardId);
  const card = giftCard?.data?.giftCard;

  const qc = q.useQueryClient();
  const revokeCode = q.useMutation({
    mutationFn: form =>
      api(
        `mutation RevokeGiftCardPurchaseOrderCodes($form: revokeGiftCardCodesForm!) {
          revokeGiftCardPurchaseOrderCodes(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['giftCard', giftCardId] });
      qc.invalidateQueries({ queryKey: ['giftCardLogs', giftCardId] });
    },
  });

  const handleRevokeCode = () => {
    revokeCode.mutate({
      giftCardPurchaseOrderId: purchaseOrderId,
      giftCardIds: [giftCardId],
    });
  };

  return (
    <PageWrapper
      title={`${card?.code} | Admin`}
      heading={
        <div className="flex flex-row">
          <div onClick={() => navigate(`/admin/usage-credits/purchase-order/${purchaseOrderId}`)}>
            <Icon name="CaretLeft" className="mt-2 mr-1 hover:cursor-pointer" />
          </div>
          <div className="flex flex-col">
            <div>
              <Text>{card?.code}</Text>
              <CopyButton value={card?.code} variant="tertiary" />
            </div>
            <Text size="sm">{card?.purchaseOrder?.poNumber}</Text>

            <Text size="sm" color="muted">
              {card?.purchaseOrder?.vendor}
            </Text>
          </div>
        </div>
      }
      inline
      loading={giftCard.isLoading}
      error={giftCard.error}
      components={{
        actions: (
          <>
            {!card?.isRevoked && (
              <PrimaryCTAButton
                onClick={handleRevokeCode}
                text="Revoke Code"
                icon="Trash"
                loading={revokeCode.isPending}
                disabled={revokeCode.isPending}
              />
            )}
          </>
        ),
      }}
    >
      <AdminGiftCardDetail giftCard={card} />
    </PageWrapper>
  );
}
