import { isBoolean, truncate } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Routes, Route } from 'react-router-dom';

import { Card, Button, Icon, Text, Heading } from '@optra/kit';

import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import SkillIcon from 'components/skill-icon';
import SpreadsheetDataFields from 'components/spreadsheet-data-fields';
import { api, q, useOnSuccess } from 'config/api';
import { useRouteQueryParams } from 'hooks';
import ChooseSpreadsheet from 'modals/choose-spreadsheet';
import { useAuthorizedWorkflowAction } from 'queries';

export default function GoogleSpreadsheetActionForm({ workflowActionId, onClose }) {
  const navigate = useNavigate();
  const authResponse = useRouteQueryParams();

  const [error, setError] = useState();
  const {
    handleSubmit: onSubmit,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      configuration: {},
    },
  });

  const [{ data, isLoading: fetching, isSuccess }, { forwardUrl }] =
    useAuthorizedWorkflowAction(workflowActionId);

  const qc = q.useQueryClient();
  const authorizeWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation ($form: authorizeWorkflowActionForm!) {
          authorization: authorizeWorkflowAction(form: $form) {
            user {
              name
            }
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
    },
  });

  const updateWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation ($form: updateWorkflowActionForm!) {
          workflowAction: updateWorkflowAction(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      onClose();
    },
    onError(err) {
      setError(err);
    },
  });

  useEffect(() => {
    if (authResponse?.code) {
      navigate('.', { replace: true });
      authorizeWorkflowAction.mutate({
        response: {
          ...authResponse,
          forwardUrl,
        },
        workflowActionId,
        actionId: 'googleSpreadsheet',
      });
    }
  }, [workflowActionId, forwardUrl, authResponse, navigate, authorizeWorkflowAction]);

  useOnSuccess(
    () => {
      if (data?.workflowAction?.id) {
        const configuration = {
          // TODO: hack to get react-hook-form to register the configuration object
          authorizedBy: data?.workflowAction?.configuration?.authorizedBy,
        };

        data?.workflowAction?.workflow?.skills?.data?.forEach(workflowSkill => {
          configuration[workflowSkill?.id] = {};

          workflowSkill?.outputs?.forEach(output => {
            const existingConfig =
              data?.workflowAction?.configuration?.[workflowSkill.id]?.[output.output.id];
            configuration[workflowSkill.id][output.output.id] = {
              id: output.output.id,
              range: existingConfig?.range || '',
              fn: existingConfig?.fn || 'append',
              enabled: isBoolean(existingConfig?.enabled) ? existingConfig?.enabled : false,
            };
          });
        });

        reset({
          configuration,
        });
      }
    },
    { isSuccess },
    [data, reset],
  );

  const handleSubmit = onSubmit(form => {
    setError(null);

    const configuration = {
      ...data?.workflowAction?.configuration,
      ...form?.configuration,
    };

    updateWorkflowAction.mutate({
      id: workflowActionId,
      configuration,
    });
  });

  const loading = authorizeWorkflowAction?.isPending || updateWorkflowAction.isPending || fetching;
  const hasSelectedSpreadsheet = data?.workflowAction?.configuration?.spreadsheetName;

  return (
    <>
      <Routes>
        <Route path="choose-spreadsheet" element={<ChooseSpreadsheet />} />
      </Routes>

      {!loading && !data?.workflowAction?.configuration?.authorizedBy && (
        <ModalBody>
          <Card variant="secondary" className="flex flex-col items-center justify-center space-y-3">
            <Text size="sm" color="muted">
              Sign in with your Google account to select a sheet…
            </Text>
            <a
              href={data?.workflowAction?.action?.authUrl}
              style={{
                display: 'block',
                width: 191,
                height: 46,
                background:
                  'transparent url(/btn_google_signin_light_normal_web@2x.png) top left no-repeat',
                backgroundSize: '100%',
                textDecoration: 'none',
                textIndent: '-9999em',
              }}
            >
              Sign in with Google
            </a>
          </Card>
        </ModalBody>
      )}
      {(loading || data?.workflowAction?.configuration?.authorizedBy) && (
        <>
          <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
            {error && (
              <Message variant="danger" title="Couldn't Save Action">
                {error.message}
              </Message>
            )}

            <div>
              <Text variant="label" size="xs" color="muted">
                Signed in as…
              </Text>
              <Card variant="secondary" className="flex items-center justify-between">
                <div className="flex items-center">
                  <Icon name="GoogleLogo" color="gradient" className="mr-2" />
                  <Text>
                    {data?.workflowAction?.configuration?.authorizedBy ||
                      data?.workflowAction?.user?.name}
                  </Text>
                </div>
                <Button href={data?.workflowAction?.action?.authUrl} size="xs" variant="tertiary">
                  Change
                </Button>
              </Card>
            </div>

            {/* TODO: hack to get react-hook-form to register the configuration object */}
            <input type="hidden" {...register('configuration.authorizedBy')} />

            <div>
              <Text variant="label" size="xs" color="muted">
                Selected Spreadsheet…
              </Text>
              <Card variant="secondary" className="flex items-center justify-between">
                <div className="flex items-center">
                  <Icon name="FileText" color="gradient" className="mr-2" />
                  <Text>{hasSelectedSpreadsheet || 'No sheet selected…'}</Text>
                </div>
                <Button to="./choose-spreadsheet" size="xs" variant="tertiary">
                  {hasSelectedSpreadsheet ? 'Change' : 'Select Sheet'}
                </Button>
              </Card>
            </div>

            {hasSelectedSpreadsheet && (
              <div className="space-y-4">
                <div>
                  <Heading icon="Cube" level={3}>
                    Skill Data
                  </Heading>
                  <Text color="muted" size="xs" className="flex items-center justify-start mt-2">
                    <Icon name="Warning" size="xs" className="mr-2" />
                    Avoid gaps across columns in your data when using “Insert New Row”.
                  </Text>
                </div>
                {(data?.workflowAction?.workflow?.skills?.data || [])
                  .filter(ws => !ws.disabled?.disabled)
                  .map?.(workflowSkill => (
                    <Card variant="secondary" key={workflowSkill?.id}>
                      <div className="flex items-center">
                        <SkillIcon
                          size="sm"
                          color={workflowSkill?.skill?.color}
                          icon={workflowSkill?.skill?.icon}
                          iconUrl={workflowSkill?.skill?.iconUrl}
                          className="mr-3"
                        />
                        <Heading level={4}>
                          {truncate(workflowSkill?.name, {
                            length: 32,
                          })}
                        </Heading>
                      </div>

                      {(workflowSkill.outputs || [])
                        .filter(({ disabled }) => !disabled)
                        .map(({ output }) => (
                          <SpreadsheetDataFields
                            key={output.id}
                            register={register}
                            watch={watch}
                            output={output}
                            workflowSkill={workflowSkill}
                            loading={loading}
                            errors={errors}
                          />
                        ))}
                    </Card>
                  ))}
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button size="xl" loading={loading} onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
}
