import { Button, Card, Heading, IconButton, Text } from '@optra/kit';

import LabelChooser from 'modals/skill-pipeline-builder/components/label-chooser';
import useInputLabels from 'modals/skill-pipeline-builder/context/use-input-labels';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function LabelSequenceProcessor({ nodeId, config, onChange = () => false }) {
  const [node] = usePipelineNode(nodeId);
  const labels = useInputLabels(node, 'label');

  function addItem() {
    onChange({
      key: 'sequence',
      value: {
        ...config.sequence,
        [`state${Object.keys(config.sequence).length + 1}`]: '',
      },
    });
  }

  function removeItem(key) {
    const current = {
      ...config.sequence,
    };
    delete current[key];
    onChange({
      key: 'sequence',
      value: current,
    });
  }

  return (
    <div className="space-y-3">
      <Heading level={3}>Sequence</Heading>
      <Card variant="secondary" className="space-y-6">
        {Object.entries(config.sequence).map(([k, v]) => (
          <div key={`sequence-${k}`} className="flex flex-row items-center space-x-2">
            <Text variant="label" size="sm">
              {k}
            </Text>
            <LabelChooser
              labels={labels}
              value={v}
              onChange={label => {
                onChange({
                  key: 'sequence',
                  value: {
                    ...config.sequence,
                    [k]: label,
                  },
                });
              }}
            />
            <IconButton name="Trash" variant="secondary" onClick={() => removeItem(k)} />
          </div>
        ))}
        <Button icon="Plus" size="sm" variant="secondary" onClick={addItem}>
          Add Item
        </Button>
      </Card>
    </div>
  );
}
