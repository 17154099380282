import cx from 'classnames';
import { isNil, noop, last } from 'lodash';
import { useRef } from 'react';

import { IconButton, Icon } from '@optra/kit';

import SkillIcon from 'components/skill-icon';
import useParseFileUrl from 'lib/use-parse-file-url';

export default function ImageSelector(props) {
  const { value, onChange: _onChange = noop, disabled } = props;

  const onChange = disabled ? noop : _onChange;

  const uploadIconInput = useRef();
  const fileUrl = useParseFileUrl(value);

  const onImageChange = e => {
    const file = e.target.files?.[0];
    if (!file) return;

    const fileExt = last(file.name.split('.'));
    const img = new Image();
    img.onload = function onload() {
      onChange(file, fileExt);
    };
    img.src = URL.createObjectURL(file);
  };

  return (
    <div className={cx(['w-24', 'h-24', 'relative'])}>
      <div className="flex items-center justify-center space-x-2">
        <div
          className={cx(
            'w-24',
            'h-24',
            'flex',
            'shrink-0',
            'items-center',
            'justify-center',
            'flex-shrink-0',
            'rounded-2xl',
            'relative',
            'bg-light-bg-secondary',
            'text-light-fg-secondary',
            'dark:bg-dark-bg-secondary',
            'dark:text-dark-fg-secondary',
          )}
        >
          {isNil(value) && <Icon name="CloudArrowUp" size="xl" />}
          {!isNil(value) && <SkillIcon size="lg" iconUrl={fileUrl} />}
          <input
            ref={uploadIconInput}
            className="absolute inset-0 opacity-0 cursor-pointer"
            type="file"
            accept="image/*"
            onChange={onImageChange}
          />
        </div>
      </div>
      {value && (
        <IconButton
          name="X"
          variant="tertiary"
          onClick={() => {
            uploadIconInput.current.value = '';
            onChange(null);
          }}
          disabled={disabled}
          className="absolute -top-2 -right-2"
        />
      )}
    </div>
  );
}
