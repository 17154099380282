import { without, flatMap } from 'lodash';

import { Icon, Spinner } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';
import { useWorkflowDeviceGroups } from 'queries';

export default function DevicesFilterGroups({ onFilter, filter }) {
  const { data, isLoading: fetching } = useWorkflowDeviceGroups();
  const groups = flatMap(data?.pages, page => page?.list?.data);

  if (fetching) {
    return (
      <DevicesFilterListItem image={<Spinner size="sm" color="gradient" />} label="Loading…" />
    );
  }

  // TODO: Pagination
  return (
    groups?.map?.((group, idx) => {
      const selected = filter.groupIds?.includes?.(group.id);

      return (
        <DevicesFilterListItem
          key={group.id}
          image={<Icon name="Folder" size="sm" color="gradient" />}
          label={`${group.name} (${group.workflow.name})`}
          selected={selected}
          onClick={() => {
            if (selected) {
              onFilter({
                ...filter,
                groupIds: without(filter.groupIds, group.id),
              });
            } else {
              onFilter({
                ...filter,
                groupIds: [...(filter.groupIds || []), group.id],
              });
            }
          }}
        />
      );
    }) || null
  );
}
