import cx from 'classnames';

export default function Table({ compact = true, children, className, ...rest }) {
  return (
    <div
      className={cx(
        [
          'shadow-sm',
          'border',
          'border-light-fg-tertiary',
          'dark:border-dark-bg-secondary',
          'rounded-md',
          'bg-light-bg-secondary',
          'dark:bg-dark-bg-primary',
          'overflow-auto',
        ],
        compact && ['w-full', 'md:max-w-screen-md', 'mx-auto'],
        !compact && ['w-full'],
      )}
    >
      <table
        className={cx([
          'min-w-full divide-y divide-light-fg-tertiary dark:divide-dark-bg-secondary',
          className,
        ])}
        {...rest}
      >
        {children}
      </table>
    </div>
  );
}
