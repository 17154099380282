import { api, q } from 'config/api';

export default function useOrganizationsLimits() {
  return q.useQuery({
    queryKey: ['userOrganizationsLimits'],
    queryFn: () =>
      api(
        `query userOrganizationsLimits {
          currentUser {
            id
            profile {
              id
              canCreateChildOrganizations
            }
          }
        }`,
      ),
    retry: 0,
    select: r => r.currentUser,
  });
}
