import { api, q } from 'config/api';

export default function useLocations(id, overrides) {
  return q.useQuery({
    queryKey: ['location', id],
    queryFn: () =>
      api(
        `query location ($id: ID!) {
          location(id: $id) {
            id
            name
            lat
            lng
            address {
              address
              locality
              country
            }
            devices {
              count
              data {
                id
                name
              }
            }
          }
        }`,
        { id },
      ),
    ...overrides,
  });
}
