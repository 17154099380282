import { Icon, Text } from '@optra/kit';

import List from 'components/list';
import ListItem from 'components/list-item';
import useGlobalState from 'state';

export default function ProfileThemeSection() {
  const [userColorScheme, setUserColorScheme] = useGlobalState('userColorScheme');

  return (
    <div>
      <Text variant="label" size="xs" className="mr-2">
        Theme
      </Text>
      <List>
        <ListItem
          renderLeft={() => <Icon name="Browsers" size="sm" />}
          renderRight={() =>
            userColorScheme === 'auto' ? (
              <Icon name="CheckCircle" color="gradient" />
            ) : (
              <Icon name="Circle" weight="line" color="gray" />
            )
          }
          onClick={() => setUserColorScheme('auto')}
        >
          <Text>System</Text>
        </ListItem>
        <ListItem
          renderLeft={() => <Icon name="Sun" size="sm" />}
          renderRight={() =>
            userColorScheme === 'light' ? (
              <Icon name="CheckCircle" color="gradient" />
            ) : (
              <Icon name="Circle" weight="line" color="gray" />
            )
          }
          onClick={() => setUserColorScheme('light')}
        >
          <Text>Light</Text>
        </ListItem>
        <ListItem
          renderLeft={() => <Icon name="Moon" size="sm" />}
          renderRight={() =>
            userColorScheme === 'dark' ? (
              <Icon name="CheckCircle" color="gradient" />
            ) : (
              <Icon name="Circle" weight="line" color="gray" />
            )
          }
          onClick={() => setUserColorScheme('dark')}
        >
          <Text>Dark</Text>
        </ListItem>
      </List>
    </div>
  );
}
