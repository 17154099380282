import { isEmpty, uniqueId } from 'lodash';
import { forwardRef, useRef, cloneElement, Children, Fragment } from 'react';
import * as uuid from 'uuid';

import { Button, IconButton } from '@optra/kit';

import FlexCenter from 'components/flex-center';
import Input from 'components/input';
import Label from 'components/label';
import Select from 'components/select';

const FIELD_TYPES = {
  string: 'text',
  number: 'number',
};

export default forwardRef((props = {}, ref) => {
  const {
    form: { register, unregister, watch, setValue },
    disabled,
    fields = {
      field1: {
        label: 'Field',
        type: 'string',
        validate: () => true,
      },
    },
    loading = false,
    name: _name,
    fieldSetWrapper = <div className="space-y-2" />,
    enumWrapper,
    stringWrapper,
  } = props;

  const defaultDatum = () => {
    const data = Object.keys(fields).reduce((data, key) => {
      const field = fields[key];
      if (field.type === 'enum' && field.hideChooseOption) {
        return { ...data, [key]: field?.enumValues?.[0]?.value || field?.enumValues?.[0] || '' };
      }
      return { ...data, [key]: '' };
    }, {});

    return {
      _id: uniqueId(),
      ...data,
    };
  };

  const randomName = useRef(uuid.v4().split('-')[0]);
  const name = isEmpty(_name) ? randomName.current : _name;

  const values = watch(name);

  const data = values && values.length > 0 ? values : [defaultDatum()];

  // TODO: Move to useFieldArray
  const add = () => {
    setValue(`${name}.${data.length}`, defaultDatum());
  };

  const remove = idx => {
    setValue(`${name}.${idx}`, {});
    unregister(`${name}.${idx}`);
  };

  return (
    <>
      {data.map((_datum, idx) => {
        const { _id: datumId, ...datum } = _datum;
        return (
          <div key={`${name}${datumId}`} className="flex items-end justify-between space-x-2 my-4">
            {Object.keys(datum || {}).map?.(fieldName => {
              const field = fields[fieldName];
              const SelectField = (
                <Select
                  {...register(`${name}.${idx}.${fieldName}`, {
                    value: datum?.[fieldName] || '',
                    validate: field?.validate || {},
                    shouldUnregister: true,
                    ...field?.options,
                  })}
                  disabled={loading || disabled}
                  variant="big"
                >
                  {!field?.hideChooseOption && <option value="">Choose…</option>}
                  {field?.enumValues?.map(v => (
                    <option value={v?.value || v} key={v?.value || v}>
                      {v?.label || v}
                    </option>
                  ))}
                </Select>
              );

              const InputField = (
                <Input
                  {...register(`${name}.${idx}.${fieldName}`, {
                    value: datum?.[fieldName] || '',
                    validate: fields[fieldName]?.validate || {},
                    valueAsNumber: fields[fieldName]?.type === 'number',
                    shouldUnregister: true,
                    ...fields[fieldName]?.options,
                  })}
                  type={FIELD_TYPES[fields[fieldName]?.type || 'string']}
                  disabled={loading || disabled}
                  placeholder={field.placeholder}
                />
              );

              return (
                <Fragment key={`${name}.${datumId}.${fieldName}`}>
                  {fieldSetWrapper &&
                    Children.map([fieldSetWrapper], child =>
                      cloneElement(child, {
                        children: (
                          <>
                            <Label htmlFor={`${name}.${idx}.${fieldName}`}>{field?.label}</Label>
                            {field?.type === 'enum' ? (
                              <>
                                {enumWrapper &&
                                  Children.map([enumWrapper], child =>
                                    cloneElement(child, { children: SelectField }),
                                  )}
                                {!enumWrapper && SelectField}
                              </>
                            ) : (
                              <>
                                {stringWrapper &&
                                  Children.map([stringWrapper], child =>
                                    cloneElement(child, { children: InputField }),
                                  )}
                                {!stringWrapper && InputField}
                              </>
                            )}
                          </>
                        ),
                      }),
                    )}
                </Fragment>
              );
            })}

            <Input
              {...register(`${name}.${idx}._id`, {
                value: datumId,
                shouldUnregister: true,
              })}
              className="hidden"
            />
            <div className="w-9 h-11 flex items-center justify-center">
              {data.length > 1 && idx > 0 && (
                <IconButton
                  variant="secondary"
                  onClick={() => {
                    if (disabled) return;
                    if (data.length > 1) {
                      remove(idx);
                    }
                  }}
                  name="X"
                />
              )}
            </div>
          </div>
        );
      })}
      <FlexCenter className="mt-4">
        <Button
          variant="secondary"
          size="xs"
          onClick={() => {
            if (disabled) return;
            add();
          }}
          icon="Plus"
        >
          Add Item
        </Button>
      </FlexCenter>
    </>
  );
});
