import { api, q } from 'config/api';

export default function useGlobalFeatures(ids = [], overrides) {
  return q.useQuery({
    queryKey: ['globalFeatures'],
    queryFn: () =>
      api(
        `query globalFeatures($ids: [GlobalFeatureId]) {
          features: globalFeatures(ids: $ids) {
            data {
              id
              enabled
              label
            }
          }
        }`,
        {
          ids,
        },
      ),
    placeholderData: q.keepPreviousData,
    ...overrides,
  });
}
