import { useRoles } from 'providers/roles-provider';

export default function useHasRoles(_roles, ignoreShortCircuits = false) {
  const [roles, isPersonal, isSysAdmin, loading] = useRoles();

  if (!ignoreShortCircuits && (isPersonal || isSysAdmin))
    return [true, loading, isPersonal, isSysAdmin];

  return [roles.some(cr => (_roles || []).includes(cr)), loading, isPersonal, isSysAdmin];
}
