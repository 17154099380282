import { isEmpty } from 'lodash';
import flatMap from 'lodash/flatMap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce, useTitle } from 'react-use';

import { Card, Button, Icon, SearchField, Table, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalTitle from 'components/modal-title';
import { useLocations } from 'queries';

export default function ManageLocationsOverview() {
  useTitle('Manage Locations | Optra');

  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const {
    data,
    isFetching,
    isLoading,
    error: fetchError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useLocations({
    list: {
      filter: {
        $search: debouncedSearch,
      },
    },
  });
  const locations = flatMap(data?.pages, page => page?.list?.data);
  const navigate = useNavigate();

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const locationList = () => (
    <>
      <Table>
        <Table.Body>
          {locations?.map(location => (
            <Table.Row
              key={location.id}
              caret
              hover
              onClick={() => navigate(`${location.id}/edit`)}
            >
              <Table.TD>
                <Icon name="MapPin" color="gradient" className="mr-2" />
                <Text>{location.name}</Text>
              </Table.TD>
              <Table.TD>
                {location.devices?.count || 0} Device{location.devices?.count === 1 ? '' : 's'}
              </Table.TD>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isFetchingNextPage || !hasNextPage}
      />
    </>
  );
  return (
    <>
      <ModalTitle title="Manage Locations" icon="MapPin" loading={isFetching} />
      <ModalBody className="space-y-4">
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Location">
            {fetchError.message}
          </Message>
        )}
        <Card variant="secondary" className="flex items-start space-x-2">
          <SearchField
            searching={!isLoading && isFetching}
            value={search}
            onChange={setSearch}
            onClear={() => setSearch('')}
            placeholder="Search Locations"
          />
          <Button type="button" icon="Plus" to="new">
            New
          </Button>
        </Card>
        {isEmpty(locations) ? (
          <EmptyContentMessage icon="MapPin" title="No Locations" />
        ) : (
          locationList()
        )}
      </ModalBody>
    </>
  );
}
