import { api, q } from 'config/api';

export default function useDeploymentTaskLogs({ taskId, list }, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['workflowDeploymentExecutionTask', taskId, list, 'logs'],
    async queryFn({ pageParam }) {
      const r = await api(
        `query workflowDeploymentExecutionTaskLogs($taskId: ID!, $list: listFields) {
            task: workflowDeploymentExecutionTask(id: $taskId) {
              logs(list: $list) {
                data {
                  id
                  message
                  type
                  descriptors
                  createdAt
                }
                cursor {
                  after
                }
              }
            }
          }`,
        {
          taskId,
          list: {
            ...list,
            cursor: { after: pageParam },
          },
        },
      );

      const logs = r?.task?.logs?.data;

      return {
        logs,
        cursor: r?.task?.logs?.cursor,
      };
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 10000,
    enabled: !!taskId,
    ...overrides,
  });
}
