import { useFeature } from 'components/feature';
import Tab from 'components/tab';
import Tabs from 'components/tabs';

export const isActiveTab = (currentTab, tab) => (currentTab || '').split('|').includes(tab);

export default function SkillFormTabs(props) {
  let { tab: currentTab, onChange: setCurrentTab, tabs = [] } = props;
  const skillInputOutputEnabled = useFeature('skillInputOutput');

  if (!skillInputOutputEnabled) {
    tabs = tabs.filter(tab => tab !== 'inputs' && tab !== 'outputs');
  }

  const tabNames = {
    container: 'Container',
    compose: 'Compose',
    inputs: 'Inputs',
    outputs: 'Outputs',
    env: 'ENV',
    privileges: 'Privileges',
  };

  return (
    <Tabs>
      {tabs.map(tab => (
        <Tab key={tab} active={isActiveTab(currentTab, tab)} onClick={() => setCurrentTab(tab)}>
          {tabNames[tab]}
        </Tab>
      ))}
    </Tabs>
  );
}
