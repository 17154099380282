import { Button, Card, Heading, IconButton, Text } from '@optra/kit';

import Input from 'components/input';
import StepperInput from 'components/stepper-input';
import LabelChooser from 'modals/skill-pipeline-builder/components/label-chooser';
import useInputLabels from 'modals/skill-pipeline-builder/context/use-input-labels';
import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

function extractRangeFromKey(key) {
  const range = key.split('-');
  return [
    parseInt((range?.[0] ?? 0).replaceAll(/\D+/gi, ''), 10),
    parseInt((range?.[1] ?? 0).replaceAll(/\D+/gi, ''), 10),
  ];
}

export default function CountToLabelProcessor({ nodeId, config = {}, onChange = () => false }) {
  const [node] = usePipelineNode(nodeId);
  const labels = useInputLabels(node, 'label_counts');

  function addItem() {
    const lastKey = Object.keys(config.countMapping).at(-1);
    const [, max] = extractRangeFromKey(lastKey);
    const nextMin = max + 1;
    const nextMax = nextMin + 10;

    onChange({
      key: 'countMapping',
      value: {
        ...config.countMapping,
        [`${nextMin}-${nextMax}`]: '',
      },
    });
  }

  function updateItem(index, min, max, value) {
    const currentMapping = structuredClone(config.countMapping);

    const newKey = `${min}-${max}`;
    const newMapping = Object.keys(currentMapping).reduce((ac, a, i) => {
      if (i === index) {
        delete currentMapping[a];
        ac[newKey] = value;
      } else {
        ac[a] = currentMapping[a];
      }
      return ac;
    }, {});

    onChange({
      key: 'countMapping',
      value: newMapping,
    });
  }

  function removeItem(k) {
    const mapping = structuredClone(config.countMapping);
    delete mapping[k];

    onChange({
      key: 'countMapping',
      value: mapping,
    });
  }

  const entries = Object.entries(config?.countMapping);

  return (
    <div className="space-y-3">
      <Heading level={3}>Source Label</Heading>
      <Card variant="secondary" className="space-y-6">
        <LabelChooser
          labels={labels}
          value={config?.labelOfInterest}
          onChange={value => onChange({ key: 'labelOfInterest', value })}
        />
      </Card>

      <Heading level={3}>Output Labels</Heading>
      <Card variant="secondary" className="space-y-6">
        {entries.map(([k, v], idx) => {
          const [min, max] = extractRangeFromKey(k);
          return (
            <div key={idx} className="flex row items-center space-x-2">
              <Text variant="label" size="sm">
                Range
              </Text>
              <StepperInput
                value={min}
                min={0}
                max={max}
                onChange={newMin => updateItem(idx, newMin, max, v)}
              />
              <Text variant="label" size="sm">
                –
              </Text>
              <StepperInput
                value={max}
                min={min}
                max={10000}
                onChange={newMax => updateItem(idx, min, newMax, v)}
              />
              <Input
                type="text"
                value={v}
                placeholder={`label${idx + 1}`}
                onChange={e => {
                  const newValue = e.target.value.replace(/\W/g, '');
                  onChange({
                    key: 'countMapping',
                    value: {
                      ...config.countMapping,
                      [k]: newValue,
                    },
                  });
                }}
              />
              {idx > 0 && (
                <IconButton name="Trash" variant="secondary" onClick={() => removeItem(k)} />
              )}
            </div>
          );
        })}
        <Button icon="Plus" size="sm" variant="secondary" onClick={addItem}>
          Add Item
        </Button>
      </Card>
    </div>
  );
}
