import { api, q } from 'config/api';

export default function useChartMetrics(overrides) {
  return q.useQuery({
    queryKey: ['chartMetrics'],
    queryFn: () =>
      api(
        `query chartMetrics {
          metrics: chartMetrics {
            id
            name
            db
            dbName
            table
            tableName
            exists
          }
        }`,
      ),
    initialData: [],
    select: r => r?.metrics || [],
    placeholderData: q.keepPreviousData,
    refetchOnWindowFocus: false,
    ...overrides,
  });
}
