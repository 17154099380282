import omit from 'lodash/omit';

import { StatusIndicator } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';

export default function DevicesFilterConnectionStatus({ onFilter, filter }) {
  return (
    <>
      <DevicesFilterListItem
        image={<StatusIndicator status="enabled" size="lg" />}
        label="Online"
        selected={filter.status === 'enabled'}
        onClick={() => {
          if (filter.status === 'enabled') {
            onFilter(omit(filter, 'status'));
          } else {
            onFilter({ ...filter, status: 'enabled' });
          }
        }}
      />
      <DevicesFilterListItem
        image={<StatusIndicator status="disabled" size="lg" />}
        label="Offline"
        selected={filter.status === 'disabled'}
        onClick={() => {
          if (filter.status === 'disabled') {
            onFilter(omit(filter, 'status'));
          } else {
            onFilter({ ...filter, status: 'disabled' });
          }
        }}
      />
      <DevicesFilterListItem
        image={<StatusIndicator status="partial" size="lg" />}
        label="Partial"
        selected={filter.status === 'partial'}
        onClick={() => {
          if (filter.status === 'partial') {
            onFilter(omit(filter, 'status'));
          } else {
            onFilter({ ...filter, status: 'partial' });
          }
        }}
      />
    </>
  );
}
