import cx from 'classnames';
import { flatMap, isEmpty, isEqual, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDebounce } from 'react-use';

import { useLocations } from 'queries';

export default function LocationSelect({
  value,
  onChange = () => false,
  defaultLocation,
  includeNoLocationOption = false,
  isMulti = false,
  props,
}) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data, isLoading } = useLocations({
    list: {
      filter: {
        $search: debouncedSearch,
      },
    },
  });
  const locations = flatMap(data?.pages, page => page?.list?.data).map(location => ({
    value: location?.id,
    label: location?.name,
  }));

  const [options, setOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    if (
      !isEqual(
        locations,
        options.filter(opt => opt.value !== '$$NONE'),
      )
    ) {
      setOptions([
        ...(includeNoLocationOption ? [{ value: '$$NONE', label: '(No Location)' }] : []),
        ...locations,
      ]);
    }

    if ((isMulti && (isNil(value) || isEmpty(value))) || (!isMulti && isNil(value?.value))) {
      setSelectedLocations(null);
      return;
    }

    if ((!isEmpty(value) || value) && isEmpty(selectedLocations)) {
      let initial = [];

      if (Array.isArray(value)) {
        locations.filter(
          location => value?.some(v => v?.value === location?.value) || value === location?.value,
        );
      } else {
        const initialValue = locations.find(
          location => location.value === value || location.value === value?.value,
        );
        if (initialValue) {
          initial.push(initialValue);
        }
      }

      if (isEmpty(initial) && defaultLocation) {
        initial = [{ value: defaultLocation?.id, label: defaultLocation?.name }];
        setOptions([...initial, ...options]);
      }

      setSelectedLocations([...initial]);
    }
  }, [
    defaultLocation,
    includeNoLocationOption,
    isMulti,
    locations,
    options,
    selectedLocations,
    setSelectedLocations,
    value,
  ]);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const handleChange = newValue => {
    setSelectedLocations(newValue);
    onChange(newValue);
  };

  const handleInputChange = inputValue => {
    setSearch(inputValue);
  };

  const controlStyles = {
    base: [
      'max-h-40',
      'overflow-y-scroll',
      'py-[3px]',
      '!rounded-md',
      '!border',
      'shadow-sm',
      'hover:cursor-pointer',
      // Light
      'border-opacity-50',
      'bg-light-bg-secondary',
      'border-light-fg-secondary',
      // Dark
      'dark:border-opacity-50',
      'dark:bg-dark-bg-primary',
      'dark:border-dark-fg-secondary',
    ],
    focused: 'border-primary ring-primary ring-1',
    nonFocused: 'dark:bg-dark-bg-primary dark:border-dark-fg-secondary',
  };
  const inputStyles = 'px-1 py-0.5 dark:text-white';
  const menuStyles = 'border-none dark:bg-black rounded-lg';
  const menuListStyles = 'p-1';
  const optionStyles = {
    base: ['hover:cursor-pointer px-3 rounded'],
    selected: ['bg-gradient-to-r', 'from-primary-500', 'to-primary-400', 'text-white'],
    notFocused: 'opacity-80 text-white',
    focused: ['!bg-light-bg-primary dark:!bg-dark-bg-primary', 'text-white'],
  };
  const multiValueLabelStyles = 'dark:text-white';
  const multiValueRemoveStyles =
    'hover:text-gray-300 dark:hover:text-white text-gray-500 rounded-md';
  const multiValueStyles =
    'dark:bg-dark-bg-secondary text-white rounded items-center py-0.5 pl-2 pr-1 gap-1.5';
  const singleValueStyles = 'dark:text-white';

  return (
    <Select
      name="locationId"
      menuPortalTarget={document.body}
      isDisabled={isLoading}
      isLoading={isLoading}
      isMulti={isMulti}
      noOptionsMessage={() => 'No matching locations'}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      placeholder={'Select Location'}
      value={selectedLocations}
      styles={{
        control: (styles, state) => ({
          ...styles,
          boxShadow: '',
          borderColor: '',
          '&:hover': {
            borderColor: '',
          },
          borderWidth: '2px',
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        multiValueRemove: (baseStyles, state) => ({
          ...baseStyles,
          ':hover': { 'background-color': '', color: '' },
        }),
        singleValue: baseStyles => ({
          ...baseStyles,
          color: '',
        }),
      }}
      classNames={{
        control: ({ isFocused }) =>
          cx(controlStyles.base, isFocused ? controlStyles.focused : controlStyles.nonFocused),
        input: () => inputStyles,
        menu: () => menuStyles,
        menuList: () => menuListStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        option: ({ isFocused, isSelected }) =>
          cx(
            optionStyles.base,
            isFocused ? optionStyles.focused : optionStyles.notFocused,
            isSelected && optionStyles.selected,
          ),
        singleValue: () => singleValueStyles,
      }}
    />
  );
}
