import cx from 'classnames';

import { Card, FileInput } from '@optra/kit';

import Label from './label';
import ValidationError from './validation-error';

export default function SkillComposeFields({
  loading,
  register,
  watch,
  errors,
  required,
  visible,
}) {
  const fileUploaded = watch('tarFile');
  return (
    <Card variant="secondary" className={cx('space-y-2 py-5', visible ? 'block' : 'hidden')}>
      <div className="space-y-2">
        <Label htmlFor="tarFile">Tar File</Label>
        <FileInput
          id="tarFile"
          {...register('tarFile', required && { required: 'Please upload a tar file.' })}
          prompt="Upload tar file"
          accept=".tgz"
          result={fileUploaded?.[0]}
          readOnly={loading}
        />
        <ValidationError errors={errors} name="tarFile" />
      </div>
    </Card>
  );
}
