import { api, q } from 'config/api';

export function useGiftCardPurchaseOrder(id, overrides = null) {
  return q.useQuery({
    queryKey: ['giftCardPurchaseOrder', id],
    queryFn: () =>
      api(
        `
					query giftCardPurchaseOrder($id: ID) {
						giftCardPurchaseOrder(id: $id) {
							id
							poNumber
							vendor
							createdBy {
								name
							}
							giftCards {
								count
								data {
									id
									amount
									code
									isRedeemed
									isRevoked
								}
							}
						}
					}
				`,
        { id },
      ),
    ...overrides,
  });
}
