import { api, q } from 'config/api';

export default function useDevicesNames(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['devices', variables, 'names'],
    queryFn({ pageParam }) {
      return api(
        `query devicesNames($list: listFields) {
          list: devices(list: $list) {
            count
            data {
              id
              name
              model
              status
              autoClaim
              tags {
                data {
                  tag {
                    id
                    name
                  }
                }
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    ...overrides,
  });
}
