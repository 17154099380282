import { api, q } from 'config/api';

export default function useModels(variables, ops) {
  return q.useInfiniteQuery({
    queryKey: ['models', variables],
    queryFn({ pageParam }) {
      return api(
        `
          query models($list: listFields) {
            list: models(list: $list) {
              data {
                id
                name
                global
                owner {
                  ... on UserProfile {
                    id
                    name
                  }
                  ... on Organization {
                    id
                    name
                  }
                }
                type
                latestVersion
                labels
                edgeImpulse {
                  id
                  active
                  url
                  enterprise
                  workflow {
                    devices(list: {filter: {deleted: false}}) {
                      count
                    }
                    latestDeployment {
                      status
                    }
                  }
                }
              }
              count
              cursor {
                after
              }
            }
          }
        `,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage?.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 5000,
    select: data => data.pages.flatMap(page => page.list.data),
    ...ops,
  });
}
