import cx from 'classnames';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import keys from 'lodash/keys';
import { forwardRef } from 'react';

import { DetailHeading, Toggle, Badge, Card, Icon, Spinner, Text } from '@optra/kit';

import ValidationError from 'components/validation-error';

const FeatureToggle = forwardRef(
  (
    {
      as,
      icon,
      title,
      description,
      variant = 'secondary',
      expanded,
      className,
      style,
      children,
      beta,
      errors: _errors,
      disabled,
      loading,
      uppercase,
      bold,
      components = {},
      ...rest
    },
    ref,
  ) => {
    const errors = !!(Array.isArray(_errors) && _errors?.length)
      ? compact(
          flatMap(
            _errors?.map(error =>
              keys(error).map(field =>
                error && error[field]?.type && error[field]?.message
                  ? { type: error[field]?.type, message: error[field]?.message }
                  : undefined,
              ),
            ),
          ),
        )
      : [];

    return (
      <Card
        as={as}
        ref={ref}
        variant={variant}
        className={cx(
          'divide-y',
          !!(components?.validation || errors?.length)
            ? 'divide-red/30'
            : variant === 'dark'
            ? 'divide-gray-200/50 dark:divide-black-800/60'
            : 'divide-light-bg-secondary dark:divide-dark-bg-secondary',
          'overflow-hidden',
          'mb-2',
          className,
        )}
        style={style}
        noPadding
      >
        <label
          className={cx(
            'flex items-center cursor-pointer',
            variant === 'plain' ? 'space-x-3 pr-1' : 'p-4 space-x-4',
          )}
        >
          {icon && (
            <div className={cx('flex-0', disabled && 'opacity-40')}>
              <Icon name={icon} color="primary" weight="duotone" />
            </div>
          )}
          <div className={cx('flex-1 flex flex-col', disabled && 'opacity-40')}>
            <div className="flex flex-row space-x-2">
              <DetailHeading as="span" variant="loud" uppercase={uppercase} bold={bold}>
                {title}
              </DetailHeading>
              {beta && (
                <Badge variant="danger" size="xs">
                  Beta
                </Badge>
              )}
              {loading && <Spinner className="self-center w-4 h-4" color="primary" />}
            </div>
            <Text color="muted" size="xs" className="normal-case font-normal mt-1">
              {description}
            </Text>
          </div>
          <div className="flex-0">
            <Toggle as="div" disabled={!!(disabled || loading)} {...rest} />
          </div>
        </label>
        {components?.validation ? (
          <div className="flex flex-col space-y-1 px-4 py-3 bg-red/10">
            {components?.validation}
          </div>
        ) : (
          !!errors?.length && (
            <div className="flex flex-col space-y-1 px-4 py-3 bg-red/10">
              {errors?.map(({ type, message }) => (
                <ValidationError key={type} message={message} />
              ))}
            </div>
          )
        )}
        {children && <div className={cx(expanded ? 'p-4 block' : 'hidden')}>{children}</div>}
      </Card>
    );
  },
);

export default FeatureToggle;
