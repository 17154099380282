import omit from 'lodash/omit';

import { StatusIndicator } from '@optra/kit';

import DevicesFilterListItem from 'components/devices-filter-list-item';

export default function DevicesFilterConnectionStatus({ onFilter, filter }) {
  return (
    <>
      <DevicesFilterListItem
        image={<StatusIndicator status="enabled" size="lg" />}
        label="Connected"
        selected={filter.headphoneJack === true}
        onClick={() => {
          if (filter.headphoneJack === true) {
            onFilter(omit(filter, 'headphoneJack'));
          } else {
            onFilter({ ...filter, headphoneJack: true });
          }
        }}
      />
      <DevicesFilterListItem
        image={<StatusIndicator status="disabled" size="lg" />}
        label="Disconnected"
        selected={filter.headphoneJack === false}
        onClick={() => {
          if (filter.headphoneJack === false) {
            onFilter(omit(filter, 'headphoneJack'));
          } else {
            onFilter({ ...filter, headphoneJack: false });
          }
        }}
      />
    </>
  );
}
