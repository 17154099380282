import { api, q } from 'config/api';
import { marketplaceFragment } from 'queries';

export default function useMarketplaceSkill(id, overrides) {
  return q.useQuery({
    queryKey: ['marketplaceSkill', id],
    queryFn: () =>
      api(
        `query marketplaceSkill($id: ID!) {
          marketplaceSkill (id: $id) {
            ${marketplaceFragment}
          }
        }`,
        { id },
      ),
    refetchInterval: 5000,
    enabled: !!id,
    ...overrides,
  });
}
