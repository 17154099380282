import flatMap from 'lodash/flatMap';

import EmptyContentMessage from 'components/empty-content-message';
import IntersectionLoadMore from 'components/intersection-load-more';
import List from 'components/list';
import { useRouteSearchParams } from 'hooks';
import SyncProgressDeviceListItem from 'modals/sync-progress-device-list-item';
import { useDeploymentTasks } from 'queries';

export default function SyncProgressDevices(props) {
  const { status } = useRouteSearchParams();
  const { deploymentId } = props;

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useDeploymentTasks(
    deploymentId,
    {
      list: {
        filter: {
          status,
        },
      },
    },
  );
  const tasks = flatMap(data?.pages, page => page?.list?.data);

  const loading = isLoading || isFetchingNextPage;
  const isFiltered = !!status;
  const filteredTasks = isFiltered ? tasks.filter(t => t.status === status) : tasks;

  if (!loading && filteredTasks?.length < 1) {
    return (
      <EmptyContentMessage
        icon={isFiltered ? 'MagnifyingGlass' : 'Aperture'}
        title={isFiltered ? 'No Devices Found' : 'No Devices'}
        variant="secondary"
      />
    );
  }

  return (
    <>
      <List loading={loading}>
        {!isLoading &&
          filteredTasks?.map(task => <SyncProgressDeviceListItem task={task} key={task.id} />)}
      </List>

      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isLoading || isFetchingNextPage || !hasNextPage}
      />
    </>
  );
}
