import { api, q } from 'config/api';

export default function useInternalSkill(id, overrides) {
  return q.useQuery({
    queryKey: ['internalSkill', id],
    queryFn: () =>
      api(
        `query internalSkill($id: ID!) {
          internalSkill(id: $id) {
            id
            name
            icon
            color
            iconUrl
            versions {
              data {
                id
                version
              }
            }
            currentVersion {
              id
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
