import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';
import { generate } from 'modals/skill-pipeline-builder/config/node-label-behaviors';

export default {
  key: 'LabelSequenceProcessor',
  label: 'Sequence',
  icon: 'DotsThreeOutline',
  description:
    'Keep track of the sequence of labels seen by an AI model, and determine if the perceived sequence matches the given sequence.',
  type: 'processors',
  inputs: [
    {
      label: 'Source Label',
      key: 'label',
      type: DATA_TYPES['Text'],
      required: false,
    },
  ],
  outputs: [
    {
      key: 'sequence_state',
      label: 'State',
      type: DATA_TYPES['Text'],
      labelBehavior: generate(node => Object.keys(node?.config?.sequence) || []),
    },
  ],
  defaultConfig: {
    sequence: { state1: '' },
  },
  schema: z.object({
    config: z.object({ sequence: z.record(z.string().min(1), z.string().min(1)) }).refine(value => {
      return Object.values(value?.sequence || {}).length > 0;
    }),
    inputs: z.object({ label: z.string().min(1) }),
  }),
};
