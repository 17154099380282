export const PORT_RANGE = [1024, 65535];
export const FORBIDDEN_PORTS = [8080, 443];

export default {
  portIsNumeric: value => {
    if (value?.length) {
      const p = new RegExp('^[0-9]*$');
      return p.test(value) || 'Port number must be a number';
    }
    return true;
  },
  portIsInRange: port => {
    if (port?.length) {
      if (FORBIDDEN_PORTS.includes(port)) return `Port number ${port} is not available`;
      return (
        (port >= PORT_RANGE[0] && port <= PORT_RANGE[1]) ||
        `Port number ${port} is outside the valid range (${PORT_RANGE[0]} - ${PORT_RANGE[1]})`
      );
    }
    return true;
  },
};
