import { Routes, Route, Outlet } from 'react-router-dom';

import TimestreamActionForm from 'components/timestream-action-form';
import TimestreamActionQuery from 'components/timestream-action-query';

export default function TimestreamActionRoutes(props) {
  const { onClose } = props;
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <TimestreamActionForm onClose={onClose} />
            <Outlet />
          </>
        }
      >
        <Route path="query" element={<TimestreamActionQuery />} />
      </Route>
    </Routes>
  );
}
