import { api, q, timeout } from 'config/api';
import { FlagsProvider } from 'lib/feature-flags';

export default function FeaturesProvider(props) {
  const { data } = q.useQuery({
    queryKey: ['features'],
    queryFn: () => {
      return timeout(
        api(
          `query features {
            owner: currentOwner {
              ... on UserProfile {
                features {
                  data {
                    enabled
                    feature {
                      id
                    }
                  }
                }
              }
              ... on Organization {
                features {
                  data {
                    enabled
                    feature {
                      id
                    }
                  }
                }
              }
            }
            globalFeatures {
              data {
                id
                enabled
              }
            }
          }`,
        ),
      );
    },
    retry: 3,
    retryDelay: 1000,
  });

  const features = [
    ...(data?.owner?.features?.data?.map?.(ft => ({
      name: ft?.feature?.id,
      isActive: ft?.enabled || false,
      level: 'owner',
    })) || []),
    ...(data?.globalFeatures?.data?.map?.(ft => ({
      name: ft?.id,
      isActive: ft?.enabled || false,
      level: 'global',
    })) || []),
  ];

  return <FlagsProvider value={features} {...props} />;
}
