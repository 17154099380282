import { DetailHeading, Dropdown } from '@optra/kit';

import { CHARTS } from 'modals/chart-details/data';

export default function MetricSelectorChartTypes(props) {
  const { value, disableNonComposeCharts, onClick } = props;
  const list = Object.values(CHARTS);

  return (
    <Dropdown.MenuBody divide={false} scrolling className="p-5">
      <DetailHeading className="px-3 mb-4">CHART</DetailHeading>
      {list.map(({ name, type, compose }, i) => {
        const active = type === value;
        const disabled = disableNonComposeCharts && !compose;
        return (
          <Dropdown.Item
            key={`${type}_${i}`}
            text={name}
            uppercase={false}
            disabled={disabled}
            className="rounded-md"
            onClick={() => onClick(type)}
            active={active}
          />
        );
      })}
    </Dropdown.MenuBody>
  );
}
