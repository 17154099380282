import { Button, IconButton, Toggle } from '@optra/kit';

import Input from 'components/input';
import Select from 'components/select';

function castValue(type, value) {
  switch (type) {
    case 'Integer':
      return parseInt(value, 10);
    case 'Float':
      return parseFloat(value);
    case 'Boolean':
      return value === 'true' ? true : false;
    case 'String':
    case 'Multiple':
    case 'Label':
    default:
      return value;
  }
}

export default function AdminSkillBuilderBetaNodeConfigFields(props) {
  const { value = '[]', onChange, availableInputs = [] } = props;
  const configFields = JSON.parse(value);

  function handleAddConfigField() {
    const newConfigFields = [...configFields, { label: '', key: '', type: '', required: false }];
    onChange(JSON.stringify(newConfigFields));
  }

  function handleRemoveConfigField(idx) {
    const newConfigFields = [...configFields.slice(0, idx), ...configFields.slice(idx + 1)];
    onChange(JSON.stringify(newConfigFields));
  }

  function handleChangeConfigField(idx, vals) {
    const newConfigFields = [
      ...configFields.slice(0, idx),
      {
        ...configFields[idx],
        ...vals,
      },
      ...configFields.slice(idx + 1),
    ];
    onChange(JSON.stringify(newConfigFields));
  }

  return (
    <>
      {configFields.length < 1 && (
        <div>
          <Button icon="Plus" variant="tertiary" onClick={handleAddConfigField}>
            Add Field
          </Button>
        </div>
      )}
      {configFields.map((configField, idx) => (
        <div className="grid grid-cols-4 gap-2" key={`configFields-${idx}`}>
          <Input
            required
            type="text"
            name="label"
            value={configField.label}
            placeholder="Label"
            onChange={event => {
              const label = event?.target?.value;
              const currentKey = configField?.key || '';
              const currentLabel = configField?.label || '';
              if (
                currentKey === '' ||
                currentKey === currentLabel.toLowerCase().replace(/\s/gi, '_')
              ) {
                handleChangeConfigField(idx, {
                  label,
                  key: label.toLowerCase().replace(/\s/gi, '_'),
                });
              } else {
                handleChangeConfigField(idx, { label });
              }
            }}
          />
          <Input
            required
            type="text"
            name="key"
            value={configField.key}
            placeholder="Key"
            onChange={event => handleChangeConfigField(idx, { key: event?.target?.value })}
          />
          <Select
            required
            className="flex-1"
            name="type"
            value={configField.type}
            onChange={event => handleChangeConfigField(idx, { type: event?.target?.value })}
          >
            <option value="">Data Type…</option>
            <option value="String">String</option>
            <option value="Integer">Integer</option>
            <option value="Float">Float</option>
            <option value="Boolean">Boolean</option>
            <option value="Multiple">Multiple</option>
            <option value="Label">Label</option>
          </Select>
          <div className="flex flex-row items-center space-x-2">
            <Toggle label="Required" value={configField.required} />
            <IconButton variant="tertiary" name="Plus" onClick={handleAddConfigField} />
            <IconButton variant="tertiary" name="X" onClick={() => handleRemoveConfigField(idx)} />
          </div>
          {configField.type !== 'Boolean' && (
            <Input
              type={['Integer', 'Float'].includes(configField.type) ? 'number' : 'text'}
              name="defaultValue"
              value={configField.defaultValue}
              placeholder="Default Value"
              onChange={event =>
                handleChangeConfigField(idx, {
                  defaultValue: castValue(configField.type, event?.target?.value),
                })
              }
            />
          )}
          {configField.type === 'Boolean' && (
            <Select
              name="defaultValue"
              value={configField.defaultValue}
              placeholder="Default Value"
              onChange={event => {
                const val = event?.target?.value;
                const defaultValue = val === 'true' ? true : false;
                handleChangeConfigField(idx, { defaultValue });
              }}
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </Select>
          )}
          {configField.type === 'Multiple' && (
            <Input
              type="text"
              name="options"
              placeholder="Options"
              value={configField?.options ?? ''}
              onChange={event =>
                handleChangeConfigField(idx, {
                  options: castValue(configField.type, event?.target?.value),
                })
              }
            />
          )}
          {configField.type === 'Integer' && (
            <>
              <Input
                type="number"
                name="min"
                placeholder="Min Value"
                value={configField.min}
                onChange={event =>
                  handleChangeConfigField(idx, {
                    min: castValue(configField.type, event?.target?.value),
                  })
                }
              />
              <Input
                type="number"
                name="max"
                placeholder="Max Value"
                value={configField.max}
                onChange={event =>
                  handleChangeConfigField(idx, {
                    max: castValue(configField.type, event?.target?.value),
                  })
                }
              />
            </>
          )}
          {configField.type === 'Float' && (
            <>
              <Input
                type="number"
                name="min"
                placeholder="Min Value"
                value={configField.min}
                onChange={event =>
                  handleChangeConfigField(idx, {
                    min: castValue(configField.type, event?.target?.value),
                  })
                }
              />
              <Input
                type="number"
                name="max"
                placeholder="Max Value"
                value={configField.max}
                onChange={event =>
                  handleChangeConfigField(idx, {
                    max: castValue(configField.type, event?.target?.value),
                  })
                }
              />
              <Input
                type="number"
                name="step"
                placeholder="Step Value"
                value={configField.step}
                onChange={event =>
                  handleChangeConfigField(idx, {
                    step: castValue(configField.type, event?.target?.value),
                  })
                }
              />
            </>
          )}
          {configField.type === 'Label' && (
            <Select
              required
              name="sourceInput"
              value={configField?.sourceInput}
              onChange={event =>
                handleChangeConfigField(idx, { sourceInput: event?.target?.value })
              }
            >
              <option value="">Choose a source input…</option>
              {availableInputs.map(input => (
                <option key={input?.key} value={input?.key}>
                  {input?.label}
                </option>
              ))}
            </Select>
          )}
          <hr className="my-4 col-span-4" />
        </div>
      ))}
    </>
  );
}
