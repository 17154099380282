import { omit } from 'lodash';
import { Link } from 'react-router-dom';

import { Button, Icon, Text } from '@optra/kit';

import LocationSelect from 'components/location-select';

export default function DevicesFilterLocation({ onFilter, filter }) {
  const handleClear = () => {
    onFilter({
      ...omit(filter, 'locationIds'),
    });
  };

  const handleChange = newValue => {
    onFilter({
      ...filter,
      locationIds: newValue,
    });
  };

  return (
    <div className="flex flex-col mt-2 space-y-3 items-center justify-center">
      <LocationSelect
        value={filter.locationIds}
        onChange={handleChange}
        isMulti
        includeNoLocationOption
      />
      <Link to="./manage-locations">
        <Text size="xs" color="muted" className="flex items-center cursor-pointer hover:underline">
          <Icon name="Gear" size="xs" className="mr-1" /> Manage Locations
        </Text>
      </Link>
      {filter.locationIds && (
        <Button onClick={handleClear} size="xs" variant="secondary" icon="X">
          Clear
        </Button>
      )}
    </div>
  );
}
