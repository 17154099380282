import { MagnifyingGlass } from '@phosphor-icons/react';
import cx from 'classnames';
import { filter, includes, orderBy } from 'lodash';
import { useState } from 'react';

import { DetailHeading, Dropdown, UiState, Icon, SearchField } from '@optra/kit';

import { useChartMetrics } from 'queries';

/**
 * Component for selecting metrics in a variable selector.
 *
 * @param {Object} props - The component props.
 * @param {string} props.value - The currently selected metric ID.
 * @param {boolean} props.disableVariables - Flag to disable variable selection.
 * @param {Function} props.variableAlreadyInUse - Function to check if a variable is already in use.
 * @param {Function} props.variableIncompatible - Function to check if a variable is incompatible.
 * @param {Function} props.onClick - Function to handle metric selection.
 * @returns {JSX.Element} The rendered component.
 */
export default function VariableSelectorMetrics(props) {
  const { value, disableVariables, variableAlreadyInUse, variableIncompatible, onClick } = props;
  const metrics = useChartMetrics();

  const list = metrics.data;

  const [searchFocused, setSearchFocused] = useState(false);

  const [search, setSearch] = useState('');
  const filteredList = filter(list, ({ name, id }) => {
    const searchableValue = `${name} - ${id}`?.toLowerCase();
    return includes(searchableValue, search.toLowerCase());
  });
  const orderedList = orderBy(filteredList, ['db', 'populated', 'name'], ['asc', 'desc', 'asc']);

  return (
    <Dropdown.MenuBody
      divide={false}
      scrolling
      className="p-5 w-[350px]"
      // prevent a-z keys from focusing items when trying to search
      onKeyDown={e => !!searchFocused && e.stopPropagation()}
    >
      <div className="flex flex-col">
        <DetailHeading className="px-3">METRICS</DetailHeading>
        <div className="p-4">
          <SearchField
            value={search}
            onChange={setSearch}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setSearchFocused(false)}
            placeholder="Search Metrics…"
            debounce={10}
            searching={metrics.isFetching}
          />
        </div>
        {metrics.isLoading && <UiState margin={false} className="m-10" />}
        {metrics.isFetched && orderedList.length === 0 && (
          <UiState variant="empty" text="No metrics..." icon={{ component: MagnifyingGlass }} />
        )}
        {orderedList.map(metric => {
          const { id, name, dbName, tableName, exists } = metric;
          const active = id === value;
          const inUse = disableVariables && variableAlreadyInUse(id);
          const incompatibleTable = disableVariables && variableIncompatible(id);
          const disabled = inUse || incompatibleTable;

          return (
            <Dropdown.Item
              key={id}
              text={name}
              title={incompatibleTable ? 'Tables do not match.' : undefined}
              detail={
                <span className="flex flex-nowrap items-center space-x-2">
                  <span className="flex flex-nowrap items-center space-x-1">
                    <Icon
                      name={incompatibleTable ? 'Lock' : 'Database'}
                      weight="regular"
                      size="xs"
                      className="opacity-60"
                    />
                    <span>
                      {dbName}.{tableName}
                    </span>
                  </span>
                  {!exists && (
                    <>
                      <span className="opacity-50 mx-2">•</span>
                      <span className="flex flex-nowrap items-center space-x-1">No Data</span>
                    </>
                  )}
                </span>
              }
              uppercase={false}
              small={false}
              className={cx(
                'rounded-md',
                (incompatibleTable || (disabled && !active)) && 'opacity-30',
              )}
              disabled={disabled}
              onClick={disabled ? null : () => onClick(id)}
              active={active}
            />
          );
        })}
      </div>
    </Dropdown.MenuBody>
  );
}
