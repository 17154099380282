import { api, q } from 'config/api';

export default function useWorkflowsWithGroups(overrides) {
  return q.useQuery({
    queryKey: ['workflows', 'withGroups'],
    queryFn: () =>
      api(
        `query workflowsWithGroups {
          workflows {
            count
            data {
              id
              name
              groups {
                count
                data {
                  id
                  name
                }
              }
            }
          }
        }`,
      ),
    ...overrides,
  });
}
