import AdminSkillBuilderPageWrapper from 'components/admin-skill-builder-page-wrapper';
import AdminSkillBuilderRecipesList from 'components/admin-skill-builder-recipes-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import { useSkillBuilderRecipes } from 'queries';

export default function AdminSkillBuilderRecipes() {
  const { data, error, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useSkillBuilderRecipes();
  return (
    <AdminSkillBuilderPageWrapper
      loading={isLoading}
      error={error}
      active="recipes"
      createLink="/admin/skill-builder-content/recipes/new"
    >
      <AdminSkillBuilderRecipesList recipes={data} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={!hasNextPage || isFetchingNextPage}
      />
    </AdminSkillBuilderPageWrapper>
  );
}
