import isEmpty from 'lodash/isEmpty';
import { useOutletContext } from 'react-router-dom';

import { Card, Button, Icon, Spinner, Text, Heading } from '@optra/kit';

import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';

export default function PublishSkillSubmitted(props) {
  const { error } = props;
  const [form, skill, proposedMarketplaceSkill] = useOutletContext();

  return (
    <ModalInner>
      <ModalTitle />
      <ModalBody className="space-y-10 px-4 pb-4" noPadding>
        <div className="flex flex-col items-center">
          {form.formState.isSubmitting && (
            <>
              <div className="mb-3">
                <Spinner size="xl" />
              </div>
              <Heading level={3}>Your skill is being submitted for review.</Heading>
              <Text size="xs" color="muted">
                Do not close this window.
              </Text>
            </>
          )}
          {!form.formState.isSubmitting && form.formState.isSubmitSuccessful && (
            <>
              <Icon name="CheckCircle" color="primary" size="xl" className="mb-3" />
              <Heading level={3}>Your skill has been submitted successfully.</Heading>
              <Text size="xs" color="muted">
                We will review your skill and notify you with status changes.
              </Text>
            </>
          )}
          {!form.formState.isSubmitting && !isEmpty(error) && (
            <>
              <Icon name="XCircle" color="red" size="xl" className="mb-3" />
              <Heading level={3}>Your skill has some errors.</Heading>
              <Text size="xs" className="text-red-200">
                {error || 'Review all fields in the form.'}
              </Text>
            </>
          )}
        </div>

        <Card
          variant="secondary"
          noPadding
          className="divide-y divide-light-fg-tertiary dark:divide-dark-fg-tertiary"
        >
          <div className="flex flex-col items-center justify-center py-5">
            <SkillIcon
              size="lg"
              color={proposedMarketplaceSkill.color}
              icon={proposedMarketplaceSkill.icon}
              iconUrl={proposedMarketplaceSkill.iconUrl}
              className="mb-2"
              fetching={skill.isLoading}
            />

            <div className="text-center">
              <Heading>{proposedMarketplaceSkill.name || 'Loading…'}</Heading>
            </div>

            {skill.data?.currentDeveloperProfile?.name && (
              <div className="text-center">
                <Text size="sm" color="muted">
                  by {skill.data?.currentDeveloperProfile?.name}
                </Text>
              </div>
            )}
          </div>
        </Card>
      </ModalBody>
      <ModalFooter className="gap-4">
        {!skill.data?.skill?.published && (
          <Button
            icon="PencilSimple"
            variant="secondary"
            size="sm"
            disabled={form.formState.isSubmitting}
            to={
              form.formState.isSubmitting
                ? null
                : form.formState.isSubmitSuccessful
                ? '../../../edit/listing'
                : '../version'
            }
          >
            Edit Listing
          </Button>
        )}
        <Button
          icon="PaperPlaneTilt"
          variant="secondary"
          size="sm"
          href="mailto:optra.help@lexmark.com"
        >
          Contact Us
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
