import cx from 'classnames';
import { noop } from 'lodash';

import { Toggle, Card, Text } from '@optra/kit';

import Checkbox from 'components/checkbox';
import Feature from 'components/feature';
import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import Label from 'components/label';
import Select from 'components/select';
import SkillIcon from 'components/skill-icon';
import ValidationError from 'components/validation-error';
import validatePortNumber from 'lib/validate-port-number';

export default function WorkflowSkillFormFields({
  errors,
  loading,
  register,
  inputFields,
  outputFields,
  setValue,
  versions,
  workflowSkill,
  showExposedUIPortField,
  skill,
  onToggleDisabled = () => false,
  automaticPortSelection,
  onToggleAutomaticPortSelection = noop,
}) {
  return (
    <>
      <Card variant="secondary">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <SkillIcon
              size="sm"
              color={skill?.color}
              icon={skill?.icon}
              iconUrl={skill?.iconUrl}
              className="mr-2"
            />
            <Text>{skill?.name}</Text>
          </div>

          {/*
          {workflowSkill?.id && (
            <Toggle label="Enabled" value={!workflowSkill?.disabled} onChange={onToggleDisabled} />
          )}
          */}
        </div>
      </Card>

      <Card variant="secondary" className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            {...register('name', { required: 'Please enter a name.' })}
            readOnly={loading}
          />
          <ValidationError errors={errors} name="name" />
        </div>
        <div className="space-y-2">
          <Label htmlFor="skillVersionId">Version</Label>
          <Select {...register('skillVersionId')} readOnly={loading}>
            {versions?.map(v => (
              <option value={v.id} key={v.id}>
                {v.version}
                {v.deprecated ? ' [DEPRECATED]' : ''}
              </option>
            ))}
          </Select>
        </div>
      </Card>

      <Feature feature="deviceTunnel">
        <FeatureToggle
          icon="Globe"
          title="Web UI Auto Port Number"
          description="Automatically generates a web ui port number"
          checked={automaticPortSelection}
          expanded={true}
          onChange={(e, checked) => onToggleAutomaticPortSelection(checked)}
          components={{
            validation: !!errors?.port && <ValidationError errors={errors} name="port" />,
          }}
          className={showExposedUIPortField ? '' : 'hidden'}
        >
          <div className="flex items-center gap-3">
            <Input
              type="text"
              readOnly={loading || automaticPortSelection}
              className={cx(
                !!(loading || automaticPortSelection) &&
                  '!bg-white/10 !border-transparent !outline-0 !ring-0',
              )}
              {...register('port', {
                validate: validatePortNumber,
              })}
            />
            <Toggle label="Expose on local network" {...register('exposeToNetwork')} />
          </div>
        </FeatureToggle>
      </Feature>

      <Card variant="secondary">
        <Label className="mb-4">Inputs</Label>

        {inputFields.map((field, index) => (
          <div key={field.key} className="mb-3 space-y-2">
            <Label htmlFor={`inputs.${index}.value`}>{field?.label}</Label>
            <input type="hidden" {...register(`inputs.${index}.key`)} />
            <Input
              type="text"
              {...register(`inputs.${index}.value`)}
              readOnly={loading}
              defaultValue={field.value}
            />
          </div>
        ))}
      </Card>

      <Card variant="secondary">
        <Label className="mb-4">Outputs</Label>

        {outputFields.map((field, index) => (
          <div key={field.id} className="mb-4">
            <input type="hidden" {...register(`outputs.${index}.id`)} />
            <Label>
              <Checkbox
                {...register(`outputs.${index}.enabled`)}
                onChange={e => {
                  const value = e.target.checked;
                  setValue(`outputs.${index}.enabled`, value);
                }}
                className="mr-2"
              />
              {field?.label}
            </Label>
          </div>
        ))}
      </Card>
    </>
  );
}
