import { GoogleMap } from '@react-google-maps/api';
import cx from 'classnames';
import flatMap from 'lodash/flatMap';
import keys from 'lodash/keys';
import { useState, useEffect, useRef } from 'react';

import { ControlBar, LegendCheck, IconButton, Tooltip, Spinner } from '@optra/kit';

import DevicesMapMarker from 'components/devices-map-marker';
import { defaultLocation } from 'config/constants';
import mapStyles from 'config/map-styles';
import { useDevicesMap } from 'queries';
import useGlobalState from 'state';

export default function DevicesMap({
  filter,
  visible = true,
  className,
  selectedStatusMapping,
  selectAll,
  selectedDeviceIds,
  devicesOmitted,
  ...rest
}) {
  const [mapScriptLoaded] = useGlobalState('mapScriptLoaded');
  const [expanded, setExpanded] = useState(false);
  const [mapRef, setMapRef] = useState();
  const center = useRef(defaultLocation);
  const [appliedColorScheme] = useGlobalState('appliedColorScheme');
  const [appliedStates, setAppliedStates] = useState(['stable', 'warn', 'danger', 'neutral']);

  const stateColorMappings = {
    danger: 'text-red',
    warn: 'text-yellow',
    stable: 'text-green',
    neutral: 'text-blue',
  };

  function AppliedStateCheck({ state }) {
    return (
      <LegendCheck
        checked={!!appliedStates.includes(state)}
        colorClass={stateColorMappings[state]}
        onClick={() =>
          appliedStates.includes(state)
            ? setAppliedStates(appliedStates.filter(s => s !== state))
            : setAppliedStates([...appliedStates, state])
        }
      />
    );
  }

  const { data, isLoading, isFetching, error, hasNextPage, fetchNextPage } = useDevicesMap({
    list: {
      filter,
    },
    ...(selectedStatusMapping.id
      ? { stateMapping: { skillOutputMappingId: selectedStatusMapping.id } }
      : {}),
  });
  const filteredDevices = flatMap(data?.pages, page => page?.list?.data)
    .filter(([lat, lng]) => [lat, lng].every(c => c))
    .filter(
      ([lat, lng, id, state]) =>
        appliedStates.includes(state) &&
        (selectAll
          ? !devicesOmitted.includes(id)
          : selectedDeviceIds.includes(id) || selectedDeviceIds.length === 0),
    );

  // While there are still more map points to load, keep loading more pages
  useEffect(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, isFetching]);

  function handleExpandMap() {
    setExpanded(!expanded);
  }

  const handleLoadMap = map => {
    setMapRef(map);
  };

  useEffect(() => {
    const handleFitBounds = () => {
      if (!mapRef) {
        return;
      }
      if (filteredDevices.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        filteredDevices.map(([lat, lng, id]) => {
          const latLng = new window.google.maps.LatLng({
            lat,
            lng,
          });
          bounds.extend(latLng);
          return id;
        });
        mapRef.fitBounds(bounds, 0);
      }
    };

    if (!expanded) {
      handleFitBounds();
    }
  }, [mapRef, expanded, visible, filteredDevices]);

  return (
    <div
      className={cx([
        // Base
        'overflow-hidden',
        'shadow-xl',
        'border',
        visible ? 'block' : 'hidden',
        expanded ? 'fixed z-priority inset-0 mb-0' : 'relative z-base h-72 mb-8 rounded',
        // Light
        'bg-light-bg-primary',
        'border-light-fg-tertiary',
        // Dark
        'dark:bg-dark-bg-primary',
        'dark:border-dark-fg-tertiary',
        className,
      ])}
      {...rest}
    >
      {mapScriptLoaded && !error && (
        <GoogleMap
          mapContainerStyle={{
            height: '100%',
            width: '100%',
          }}
          zoom={14}
          center={center.current}
          onLoad={handleLoadMap}
          options={{
            disableDefaultUI: true,
            gestureHandling: expanded ? 'greedy' : 'none',
            zoomControl: !!expanded,
            styles: mapStyles({ darkMode: appliedColorScheme === 'dark' }),
          }}
        >
          {filteredDevices.map(([lat, lng, id, state, name]) => (
            <DevicesMapMarker
              key={`marker-${id}`}
              id={id}
              name={name}
              active
              state={state}
              lat={lat}
              lng={lng}
            />
          ))}
        </GoogleMap>
      )}
      <ControlBar sticky={false} full={false} className="absolute top-3 right-3">
        {isLoading ? (
          <Spinner className="w-7 h-7" color="gradient" />
        ) : (
          <div className="flex items-center flex-nowrap animate-fade-in">
            <div className="hidden sm:flex items-center space-x-2 ml-1 mr-3">
              {keys(stateColorMappings).map(state => (
                <AppliedStateCheck key={state} state={state} />
              ))}
            </div>

            <Tooltip label={expanded ? 'Shrink Map' : 'Expand Map'}>
              <IconButton
                className="w-7 h-7"
                variant="secondary"
                onClick={handleExpandMap}
                name={expanded ? 'CornersIn' : 'CornersOut'}
              />
            </Tooltip>
          </div>
        )}
      </ControlBar>
    </div>
  );
}
