import { Badge, SubNavList } from '@optra/kit';
import { useFeature } from 'components/feature';

export default function SkillsSubNav({ active = 'library' }) {
  const skillBuilderEnabled = useFeature('skillBuilder');
  const marketplaceEnabled = useFeature('skillsMarketplace');

  if (!skillBuilderEnabled && !marketplaceEnabled) return null;

  return (
    <SubNavList>
      <SubNavList.Item icon="Cube" active={active === 'library'} to="/skills">
        Library
      </SubNavList.Item>
      {skillBuilderEnabled && (
        <SubNavList.Item icon="Cpu" active={active === 'models'} to="/skills/models">
          <span className="flex flex-row items-center gap-1 justify-center">
            Models
            <Badge
              size="xs"
              className="inline-flex items-center rounded-full font-medium shadow-sm flex-shrink-0 uppercase bg-primary text-white px-2 text-xs
                         block sm:hidden"
            >
              New
            </Badge>
            <Badge
              size="xs"
              className="absolute top-0 right-0 -translate-y-3 translate-x-4 hidden sm:block
                         inline-flex items-center rounded-full font-medium shadow-sm flex-shrink-0 uppercase bg-primary text-white px-2 text-xs" // Absolute on larger screens
            >
              New
            </Badge>
          </span>
        </SubNavList.Item>
      )}
      {marketplaceEnabled && (
        <SubNavList.Item
          icon="SquaresFour"
          active={active === 'marketplace'}
          to="/skills/marketplace"
        >
          Marketplace
        </SubNavList.Item>
      )}
    </SubNavList>
  );
}
