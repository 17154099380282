import React, { useContext, createContext } from 'react';

import { api, q } from 'config/api';

export const Context = createContext([[], true]);

export function useRoles() {
  return useContext(Context);
}

export default function RolesProvider(props) {
  const { data, isLoading, isFetching } = q.useQuery({
    queryKey: ['roles', 'current'],
    queryFn: () =>
      api(
        `query currentRoles {
          membership: currentOrganizationMember {
            roles
          }
          user: currentUser {
            isSysAdmin
          }
          org: currentOrganization {
            id
          }
        }`,
      ),
    refetchInterval: 20000,
    placeholderData: q.keepPreviousData,
    persist: true,
  });

  const isPersonal = data?.org === null;
  const isSysAdmin = data?.user?.isSysAdmin || false;
  const roles = isPersonal ? ['admin'] : data?.membership?.roles || [];

  return (
    <Context.Provider {...props} value={[roles, isPersonal, isSysAdmin, isLoading, isFetching]} />
  );
}
