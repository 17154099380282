import groupBy from 'lodash/groupBy';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Card, Button, Heading, Text, Badge, Icon, DetailHeading } from '@optra/kit';

import CreditsRequiredBanner from 'components/credits-required-banner';
import DeviceImage from 'components/device-image';
import ActivateLicenseToggle from 'components/licenses/activate-license-toggle';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SkillIcon from 'components/skill-icon';
import { creditsToFormattedString } from 'lib/format-credits';
import { useWorkflow, useCurrentBillingAccount, useWorkflowsLicenses } from 'queries';
import useDeployWorkflow, { isInProgress } from 'queries/use-deploy-workflow';

// TODO: loading indicators
export default function WorkflowSyncCreditsConfirmation() {
  useTitle('Sync Workflow | Optra');

  const { workflowId } = useParams();
  const Workflow = useWorkflow(workflowId);
  const { deploy } = useDeployWorkflow();

  // NOTE: order is guaranteed to be grouped by device
  const Licenses = useWorkflowsLicenses({ filter: { workflowId } });
  const licensesByDevice = groupBy(Licenses.data, 'device.id');

  const workflow = Workflow.data?.workflow;
  const syncInProgress = deploy.isPending || isInProgress(workflow?.latestDeployment?.status);

  const billingAccount = useCurrentBillingAccount();
  const currentBillingAccount = billingAccount?.data?.currentBillingAccount;
  const { balance } = currentBillingAccount?.balances || 0;

  return (
    <ModalInner>
      <ModalTitle icon="ArrowsClockwise" title="Sync Workflow" />
      <ModalBody size="lg">
        <div className="flex flex-col items-center justify-center pb-4">
          <Heading>Confirm Credit Usage</Heading>
          <Text>This workflow contains resources that consume credits.</Text>
        </div>
        <CreditsRequiredBanner
          isFetching={Workflow.isFetching}
          required={workflow?.creditCost}
          balance={balance}
        />

        <div className="pt-4 space-y-2">
          <DetailHeading level={2}>Current Licenses</DetailHeading>
          {Object.entries(licensesByDevice)?.map(([deviceId, workflowLicenses]) => {
            const { device } = workflowLicenses[0];
            return (
              <div key={deviceId} className="space-y-2">
                <Card variant="secondary" className="flex flex-row items-center gap-2">
                  <DeviceImage model={device?.model} size="sm" hideStatus />
                  <Text size="lg" variant="bold" className="flex-1">
                    {device?.name ?? 'Removed Device'}
                  </Text>
                </Card>
                {workflowLicenses.map(workflowLicense => {
                  const { workflowSkill } = workflowLicense;
                  const skill = workflowSkill?.skill;
                  return (
                    <Card
                      key={workflowLicense.id}
                      variant="secondary"
                      className="flex flex-row items-center gap-2"
                    >
                      <div className="relative">
                        <SkillIcon
                          size="sm"
                          color={skill?.color}
                          icon={skill?.icon}
                          iconUrl={skill?.iconUrl}
                        />
                        <div className="absolute bottom-0 right-0 bg-yellow flex justify-center items-center rounded-full p-[3px] translate-x-2 translate-y-2">
                          <Icon name="ShieldCheck" weight="fill" size="sm" />
                        </div>
                      </div>
                      <Text className="flex-1">{workflowSkill?.name}</Text>
                      <ActivateLicenseToggle
                        id={workflowLicense.license.id}
                        disabled={workflowLicense.license.disabled}
                        workflowId={workflowId}
                      />
                      <Badge size="xs">
                        <Icon name="Coins" className="mr-1" size="sm" />
                        {creditsToFormattedString(workflowSkill?.version?.creditsCost)}
                      </Badge>
                    </Card>
                  );
                })}
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={
            deploy.isPending ||
            workflow?.deleted ||
            balance < (Workflow.data?.workflow?.creditCost ?? 0)
          }
          onClick={() => deploy.mutate(workflowId)}
          size="xl"
          icon="ArrowsClockwise"
          loading={syncInProgress}
        >
          Confirm & Sync
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
