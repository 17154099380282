import { useContext, createContext } from 'react';

export const FeatureFlagsContext = createContext();

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

export const identifyMatchingFlags = (flags, authorizedFlags, scope = 'owner') => {
  return flags.filter(flag => {
    const active = flag.isActive && flag.level === scope;
    return active && (authorizedFlags || []).includes(flag.name);
  });
};

export const matchFlags = (flags, authorizedFlags, exactFlags, scope) => {
  const matchingFlags = identifyMatchingFlags(flags, authorizedFlags, scope);
  if (exactFlags) {
    return [matchingFlags.length === authorizedFlags.length, matchingFlags];
  } else {
    return [matchingFlags.length, matchingFlags];
  }
};

export function useMatchFlags(authorizedFlags, scope) {
  const flags = useFeatureFlags();
  return identifyMatchingFlags(flags, authorizedFlags, scope);
}

export function Flags(props) {
  const {
    exactFlags = false,
    renderOn = () => null,
    renderOff = () => null,
    authorizedFlags,
    scope,
    children,
  } = props;

  const resolveRender = matchingFlags => {
    return children ? children : renderOn(matchingFlags);
  };

  return (
    <FeatureFlagsContext.Consumer>
      {flags => {
        const [isMatch, matchingFlags] = matchFlags(flags, authorizedFlags, exactFlags, scope);
        return isMatch ? resolveRender(matchingFlags) : renderOff(matchingFlags);
      }}
    </FeatureFlagsContext.Consumer>
  );
}

export function FlagsProvider(props) {
  const { children, value } = props;
  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
}
