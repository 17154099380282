import cx from 'classnames';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import { getChildByType } from 'react-nanny';

import { Dropdown } from '@optra/kit';

import { useChartCtx } from 'modals/chart-details/context';

import VariableSelectorDimensions from './variable-selector-dimensions';
import VariableSelectorMetrics from './variable-selector-metrics';
import VariableSelectorTime from './variable-selector-time';

function VariablesDropdownMenu(props) {
  const {
    onSelect = noop,
    value,
    showTimeDimensions = true,
    showDimensions = true,
    disableVariables = true,
  } = props;

  const {
    helpers: { getUsedVariables, variableTableMatchesConfig },
  } = useChartCtx();

  const usedVariables = getUsedVariables();

  const variableAlreadyInUse = variable => includes(usedVariables, variable);
  const variableIncompatible = variable => !variableTableMatchesConfig(variable);

  const onClick = variable => {
    if (disableVariables && variableAlreadyInUse(variable)) {
      return;
    }
    onSelect(variable);
  };

  const passedProps = {
    value,
    disableVariables,
    variableAlreadyInUse,
    variableIncompatible,
    onClick,
  };

  return (
    <div
      className={cx(
        'flex flex-row flex-nowrap',
        'divide-x divide-light-fg-tertiary dark:divide-gray-200/10',
      )}
    >
      <VariableSelectorMetrics {...passedProps} />
      {!!showTimeDimensions && <VariableSelectorTime {...passedProps} />}
      {!!showDimensions && <VariableSelectorDimensions {...passedProps} />}
    </div>
  );
}

function VariableSelectorButton({ children }) {
  return children;
}

function VariableSelector(props) {
  const { className, dropdown = true, children, ...rest } = props;
  if (!dropdown) {
    return <VariablesDropdownMenu {...rest} />;
  }

  const button = getChildByType(children, [VariableSelectorButton]);

  return (
    <Dropdown className={className}>
      <Dropdown.Button unstyled>{button}</Dropdown.Button>
      <Dropdown.Body unstyled>
        <VariablesDropdownMenu {...rest} />
      </Dropdown.Body>
    </Dropdown>
  );
}

VariableSelector.Button = VariableSelectorButton;

export default VariableSelector;
