import cx from 'classnames';
import noop from 'lodash/noop';
import { useRef, useEffect } from 'react';
import { useIntersection } from 'react-use';

import { Spinner } from '@optra/kit';

function IntersectionLoadMoreCore(props) {
  const { children = null, observer, onVisible = noop, as = 'div' } = props || {};
  const ref = useRef(null);
  const Component = as;

  const intersection = useIntersection(ref, {
    ...observer,
    root: observer?.root || null,
    rootMargin: observer?.rootMargin || '0px',
    threshold: observer?.threshold || 0,
  });
  const isInView = (intersection && intersection.intersectionRatio > 0) || false;

  useEffect(() => {
    if (isInView) {
      onVisible();
    }
  }, [isInView, onVisible]);

  return (
    <Component ref={ref}>
      {children || (
        <div className={cx('LoadingSpinner', 'p-3', 'flex', 'justify-center')}>
          <Spinner color="primary" size="lg" />
        </div>
      )}
    </Component>
  );
}

export default function IntersectionLoadMore(props) {
  const { disabled, ...coreProps } = props;
  if (disabled) return null;
  return <IntersectionLoadMoreCore {...coreProps} />;
}
