import flatMap from 'lodash/flatMap';
import includes from 'lodash/includes';

import { Dropdown, Filter, UiState, Badge, Button } from '@optra/kit';

import { QUERY_OVERRIDES } from 'modals/chart-details/data';
import { useTags } from 'queries';

export default function ControlFilterTags(props) {
  const { selectedTagIds, tagsQuery, onClear, onSelect, ...rest } = props;
  const { data, error, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useTags(
    undefined,
    QUERY_OVERRIDES,
  );

  const tags = flatMap(data?.pages, page => page?.list?.data);

  return (
    <Filter
      label="Tags"
      loading={isLoading}
      onClear={onClear}
      menu={{
        text: !!(selectedTagIds && selectedTagIds.length) && (
          <Badge className="pl-2 pr-2">{selectedTagIds.length}</Badge>
        ),
        body: isLoading ? (
          <UiState margin={false} className="m-10" />
        ) : error ? (
          <UiState variant="error" margin={false} className="m-10" />
        ) : !tags?.length ? (
          <UiState variant="empty" text="No Tags" margin={false} className="m-10" />
        ) : (
          <>
            {tags.map(tag => {
              const { name, id } = tag;
              const active = selectedTagIds && includes(selectedTagIds, name);
              return (
                <Dropdown.Item
                  uppercase={false}
                  key={id}
                  text={name}
                  active={active}
                  onClick={e => {
                    onSelect(tag);
                    e.preventDefault();
                  }}
                  selectable
                />
              );
            })}
            {hasNextPage && (
              <div className="flex justify-center py-3">
                <Button
                  variant="plain"
                  size="xs"
                  className="p-0 text-primary"
                  onClick={fetchNextPage}
                  loading={isFetchingNextPage}
                >
                  Load More
                </Button>
              </div>
            )}
          </>
        ),
      }}
      {...rest}
    />
  );
}
