import { Badge, Icon } from '@optra/kit';

export default function ProSkillBadge({ size = 'sm', ...rest }) {
  return (
    <Badge size={size} variant="yellow" {...rest}>
      <Icon name="ShieldCheck" weight="fill" size={size} className="mr-1" />
      PRO
    </Badge>
  );
}
