import { api, q } from 'config/api';

export default function useLatestInternalSkillVersion(id, overrides) {
  return q.useQuery({
    queryKey: ['internalSkill', id, 'latestVersion'],
    queryFn: () =>
      api(
        `query latestInternalSkillVersion($id: ID!) {
          internalSkill(id: $id) {
            versions(list: { limit: 1 }) {
              data {
                id
                version
                repository {
                  uri
                  username
                  password
                }
                createOptions
                storage
                hostName
                endpointAliases
                portBindings
                tmpfs
                shmSize
                port
                removableMedia
                hostNetworking
                devices {
                  sound
                  hdmi
                  cameras
                }
                env
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    // refetchInterval: 5000,
    ...overrides,
  });
}
