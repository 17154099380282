import { isEmpty } from 'lodash';

import { DetailList, Table, Avatar, Button, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Message from 'components/message';
import { api, q } from 'config/api';

function RemoveSuperUserButton({ id }) {
  const qc = q.useQueryClient();
  const removeSuperUser = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeSuperUser($id: ID!) {
          user: removeSuperUser(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['superUsers'] });
    },
  });

  function handleRemove() {
    if (window.confirm('Are you sure you want to remove this super user?')) {
      removeSuperUser.mutate(id);
    }
  }

  return (
    <Button
      variant="secondary"
      size="xs"
      loading={removeSuperUser.isPending}
      onClick={handleRemove}
      icon="Trash"
    >
      Remove
    </Button>
  );
}

export default function AdminSuperUsersList({ error, users = [] }) {
  if (error) {
    return (
      <Message variant="danger" title="Couldn't Load Super Users">
        {error.message}
      </Message>
    );
  }

  if (isEmpty(users)) {
    return (
      <EmptyContentMessage
        icon="UserCircleGear"
        title="No Super Users"
        renderAction={() => (
          <Button to="/admin/super-users/create" icon="Plus">
            New Super User
          </Button>
        )}
      />
    );
  }

  return (
    <Table>
      <Table.Body>
        {users.map(user => (
          <Table.Row key={user?.id} hover>
            <Table.TD collapsed>
              <Avatar src={user?.user?.image} size="xs" />
            </Table.TD>

            <Table.TD>
              <Text size="sm">{user?.user?.name || 'Invited User'}</Text>
              <DetailList details={[user?.user?.email]} />
            </Table.TD>

            <Table.TD collapsed>
              <RemoveSuperUserButton id={user?.id} />
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
