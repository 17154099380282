import cx from 'classnames';
import Color from 'color';
import { isEmpty, noop } from 'lodash';

import { Icon, CONFIG } from '@optra/kit';

const iconColorCn = [
  'h-6',
  'w-6',
  'rounded-md',
  'shadow-sm',
  'hover:ring-2',
  'hover:ring-white',
  'cursor-pointer',
  'relative',
];

function ColorOption(props) {
  const { value, active, onClick } = props;

  return (
    <div
      className={cx(iconColorCn, active ? ['ring-2', 'ring-white'] : [])}
      style={{ background: value }}
      onClick={onClick}
    >
      {active && (
        <div className="absolute inset-0 flex justify-center items-center">
          <Icon name="CheckCircle" size="xs" color="white" />
        </div>
      )}
    </div>
  );
}

export default function ColorSelector(props) {
  const { value, onChange: _onChange = noop, disabled } = props;

  const rgbToHex = rgb => {
    if (!rgb) {
      return;
    }

    return (
      '#' +
      rgb
        ?.map(x => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
        .toUpperCase()
    );
  };
  const valueHex = rgbToHex(value);
  const onChange = disabled ? noop : _onChange;

  const hexToRgb = hex => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  const isCustomColor = ![
    CONFIG.COLORS.green.DEFAULT,
    CONFIG.COLORS.blue.DEFAULT,
    CONFIG.COLORS.red.DEFAULT,
    CONFIG.COLORS.yellow.DEFAULT,
  ].includes(valueHex);

  const onColorChange = targetColor => e => {
    const color = isEmpty(targetColor) ? e.target.value : targetColor;
    onChange(hexToRgb(color));
  };

  return (
    <div className="flex items-center justify-center space-x-2 mt-4">
      <ColorOption
        value={CONFIG.COLORS.green.DEFAULT}
        active={valueHex === CONFIG.COLORS.green.DEFAULT}
        onClick={onColorChange(CONFIG.COLORS.green.DEFAULT)}
      />
      <ColorOption
        value={CONFIG.COLORS.blue.DEFAULT}
        active={valueHex === CONFIG.COLORS.blue.DEFAULT}
        onClick={onColorChange(CONFIG.COLORS.blue.DEFAULT)}
      />
      <ColorOption
        value={CONFIG.COLORS.red.DEFAULT}
        active={valueHex === CONFIG.COLORS.red.DEFAULT}
        onClick={onColorChange(CONFIG.COLORS.red.DEFAULT)}
      />
      <ColorOption
        value={CONFIG.COLORS.yellow.DEFAULT}
        active={valueHex === CONFIG.COLORS.yellow.DEFAULT}
        onClick={onColorChange(CONFIG.COLORS.yellow.DEFAULT)}
      />

      <div
        className={cx(iconColorCn, isCustomColor ? ['ring-2', 'ring-white'] : 'bg-white')}
        style={{ ...(isCustomColor ? { background: valueHex } : {}) }}
      >
        <div className="absolute inset-0 flex justify-center items-center">
          <Icon
            name="CaretDown"
            size="xs"
            color={
              isCustomColor && valueHex
                ? `${Color(valueHex).isLight() ? 'black' : 'white'}`
                : 'gray'
            }
            weight="light"
          />
        </div>
        <input
          type="color"
          className="absolute inset-0 opacity-0 cursor-pointer"
          value={valueHex ?? ''}
          onChange={onColorChange()}
        />
      </div>
    </div>
  );
}
