import React, { useState, createContext, useEffect, useContext } from 'react';

import OrganizationClient from 'lib/organization-client';

export const Context = createContext([OrganizationClient.get(), OrganizationClient]);

export default function OrganizationProvider(props) {
  const [organizationId, setOrganizationId] = useState(OrganizationClient.get());

  useEffect(
    () =>
      OrganizationClient.listen(newOrganizationId => {
        setOrganizationId(newOrganizationId);
      }),
    [],
  );

  return <Context.Provider value={[organizationId, OrganizationClient]} {...props} />;
}

export function useOrganizationClient() {
  return useContext(Context);
}
