import { format } from 'date-fns';

function dateLabel(timestamp) {
  if (!timestamp) {
    return '';
  }

  return format(new Date(timestamp), 'M/d/yyyy H:mm:ss');
}

export default dateLabel;
