import cx from 'classnames';
import { Link } from 'react-router-dom';

export default function ListItem({
  renderLeft,
  renderRight,
  children,
  noPadding,
  className,
  onClick,
  to,
  href,
  disabled,
  as,
  ...rest
}) {
  let WrapperComponent = 'div';
  if (href) {
    WrapperComponent = 'a';
  }
  if (to) {
    WrapperComponent = Link;
  }
  if (onClick) {
    WrapperComponent = 'button';
  }

  return (
    <WrapperComponent
      to={to}
      href={href}
      type="button"
      onClick={!disabled ? onClick : () => false}
      className={cx([
        [
          'appearance-none',
          'select-none',
          'focus:outline-none',
          !noPadding && 'px-4',
          !noPadding && 'py-3',
          'w-full',
          'flex',
          'items-center',
          'justify-between',
          'first:rounded-t-md',
          'last:rounded-b-md',
          'space-x-3',
        ],
        (onClick || to || href) && [
          'cursor-pointer',
          'hover:bg-opacity-40',
          'hover:bg-light-bg-tertiary',
          'dark:hover:bg-opacity-80',
          'dark:hover:bg-dark-bg-tertiary',
        ],
        disabled && 'opacity-50',
        className,
      ])}
      {...rest}
    >
      {renderLeft && <div className="flex-shrink-0">{renderLeft()}</div>}
      <div className="flex-1 text-left">{children}</div>
      {renderRight && <div className="flex-shrink-0">{renderRight()}</div>}
    </WrapperComponent>
  );
}
