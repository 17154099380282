import { api, q } from 'config/api';

export default function useSkillBuilderTemplates() {
  return q.useInfiniteQuery({
    queryKey: ['skillBuilderTemplates'],
    queryFn({ pageParam }) {
      return api(
        `query skillBuilderTemplates($list: listFields) {
          list: skillBuilderTemplates(list: $list) {
            count
            data {
              id
              name
              description
              config
              iconUrl
              icon
              color
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
  });
}
