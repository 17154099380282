import { api, q } from 'config/api';

export default function useAccessSecrets(variables, ops) {
  return q.useInfiniteQuery({
    queryKey: ['accessSecrets'],
    queryFn: ({ pageParam }) =>
      api(
        `query accessSecrets($list: listFields) {
          list: accessSecrets(list: $list) {
            data {
              id
              secret
            }
            count
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      ),
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage?.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 5000,
    ...ops,
  });
}
