import { Toggle } from '@optra/kit';

import { api, q } from 'config/api';

export default function ToggleMFA() {
  const qc = q.useQueryClient();
  const current = q.useQuery({
    queryKey: ['mfa'],
    queryFn: async () => {
      const r = await api(
        `query mfaEnabled {
          currentUser {
            profile {
              mfaEnabled
              mfaEnforced
            }
          }
        }`,
      );
      return r.currentUser?.profile;
    },
    retry: 0,
  });

  const updateMFA = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateMFA($form: updateMFAForm!) {
          user: updateMFA(form: $form) {
            mfaEnabled
          }
        }`,
        { form },
      ),
    async onMutate(form) {
      await qc.cancelQueries({ queryKey: ['mfa'] });
      qc.setQueryData(['mfa'], { ...current.data, mfaEnabled: form.enabled });
      return true;
    },
    onError: () => {
      qc.setQueryData(['mfa'], current.data);
    },
    onSettled() {
      qc.invalidateQueries({ queryKey: ['mfa'] });
    },
  });

  function toggle(e) {
    e.stopPropagation();
    e.preventDefault();
    updateMFA.mutate({
      enabled: !current.data?.mfaEnabled,
    });
  }

  return (
    <Toggle
      onChange={toggle}
      checked={current.data?.mfaEnforced || current.data?.mfaEnabled || false}
      controlled
      stopPropagation
      disabled={current.isLoading || updateMFA.isPending || current.data?.mfaEnforced}
      label="MFA"
    />
  );
}
