import { MagnifyingGlass } from '@phosphor-icons/react';
import { isEmpty, noop } from 'lodash';
import { getChildByType } from 'react-nanny';

import { SearchField, Icon, Dropdown, UiState, DetailHeading } from '@optra/kit';

import useDebouncedState from 'hooks/use-debounced-state';
import { useOrganizations } from 'queries';

function OrganizationSelectFooter(props) {
  return <Dropdown.Item {...props} />;
}

// TODO: add multiselect support
function OrganizationSelect(props) {
  const {
    onChange = noop,
    value,
    hideAllOption = false,
    placement = Dropdown.placement.BOTTOM_RIGHT,
    placeholderText = 'All',
    allOptionText = 'All',
    allOptionIcon = 'Check',
    allOptionValue = 'all',
    size = 'sm',
    filters,
    variant,
    children,
  } = props || {};
  const footer = getChildByType(children, [OrganizationSelectFooter]);
  const [orgSearch, setOrgSearch, currentOrgSearch] = useDebouncedState('');
  const {
    data: organizations,
    isRefetching,
    isFetchedAfterMount,
  } = useOrganizations({
    ...filters,
    $search: orgSearch || '',
  });
  const selectedOrg = organizations?.find?.(org => org.id === value);

  return (
    <Dropdown divide={false} scrolling placement={placement}>
      <Dropdown.Button size={size} variant={variant}>
        <Icon name="Buildings" size="sm" color="gray" className="mr-2" />
        {selectedOrg?.name || placeholderText}
      </Dropdown.Button>
      <Dropdown.Body>
        <div className="px-3 space-y-2">
          <DetailHeading>Organizations</DetailHeading>
          <SearchField
            searching={isRefetching && !isFetchedAfterMount}
            value={currentOrgSearch}
            onChange={setOrgSearch}
            onClear={() => setOrgSearch('')}
            placeholder="Search…"
            onKeyDown={e => e.stopPropagation()}
          />
        </div>
        <div>
          {!hideAllOption && (
            <Dropdown.Item
              icon={allOptionIcon}
              text={allOptionText}
              active={value === allOptionValue}
              selectable
              hoverSubtle
              uppercase={false}
              className="!rounded-md"
              onClick={() => onChange(allOptionValue)}
            />
          )}

          {organizations?.map?.(org => (
            <Dropdown.Item
              key={org.id}
              icon="Buildings"
              text={org.name}
              detail={(org?.parentOrganizations?.data || [])[0]?.parent?.name}
              active={value === org.id}
              selectable
              hoverSubtle
              uppercase={false}
              className="!rounded-md"
              onClick={() => onChange(org.id)}
            />
          ))}

          {footer}

          {isEmpty(organizations) && (
            <UiState
              variant="empty"
              icon={!isEmpty(currentOrgSearch) ? { component: MagnifyingGlass } : undefined}
              text={!isEmpty(currentOrgSearch) ? 'Nothing found...' : 'No Organizations'}
              size="sm"
            />
          )}
        </div>
      </Dropdown.Body>
    </Dropdown>
  );
}

OrganizationSelect.Footer = OrganizationSelectFooter;
export default OrganizationSelect;
