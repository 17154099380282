import isUndefined from 'lodash/isUndefined';
import { useLocation } from 'react-router-dom';

import { api, q } from 'config/api';

export default function usePinnedPage(overrides) {
  const qc = q.useQueryClient();
  const location = useLocation();

  const { data, isLoading } = q.useQuery({
    queryKey: ['currentLandingPage'],
    queryFn: () =>
      api(
        `query {
           currentLandingPage
         }`,
      ),
    ...overrides,
  });

  const pinnedLandingPage = data?.currentLandingPage;
  const fullUrl = `${location.pathname}${location.search}`.trim();
  const isCurrentPagePinned = pinnedLandingPage === fullUrl;

  const getValue = form =>
    isUndefined(form)
      ? isCurrentPagePinned
        ? // If the current page is pinned, unpin it
          null
        : // otherwise, pin the current location and any query parameters
          fullUrl
      : form;

  const setPinnedPage = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateLandingPage($form: updateLandingPageForm!) {
              updateLandingPage(form: $form)
        }`,
        {
          form: {
            page: getValue(form),
          },
        },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['currentLandingPage'] });
    },
  });

  return [pinnedLandingPage, { isLoading, isCurrentPagePinned, setPinnedPage }];
}
