import { Link } from 'react-router-dom';

import { DetailHeading, Text } from '@optra/kit';

import SkillIcon from 'components/skill-icon';
import { useFeaturedSkills } from 'queries';

export default function FeaturedSkills() {
  const marketplaceSkills = useFeaturedSkills();

  if (marketplaceSkills?.data?.list?.data?.length < 1) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-row items-start justify-between mb-6">
        <DetailHeading variant="decorated">Featured Skills</DetailHeading>
        <Link to="/skills/marketplace">
          <Text size="sm" variant="bold" color="primary">
            View All
          </Text>
        </Link>
      </div>

      {/* TODO: Loading state */}
      <div className="divide-y divide-light-fg-tertiary dark:divide-dark-bg-secondary">
        {marketplaceSkills.data?.list?.data?.map?.(marketplaceSkill => (
          <Link
            to={`/skills/${marketplaceSkill?.id}`}
            key={marketplaceSkill?.id}
            className="flex flex-row items-center py-3"
          >
            <SkillIcon
              size="sm"
              icon={marketplaceSkill?.icon}
              iconUrl={marketplaceSkill?.iconUrl}
              color={marketplaceSkill?.color}
              className="mr-2"
            />
            <div>
              <Text className="block" size="sm">
                {marketplaceSkill?.name}
              </Text>
              <Text className="block" size="xs" color="muted">
                {marketplaceSkill?.developerProfile?.name}
              </Text>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
