import isFunction from 'lodash/isFunction';
import { useForm } from 'react-hook-form';

import { Modal, Card, Button } from '@optra/kit';

import ChartFiltersInput from 'components/charts/chart-filters-input';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ValidationError from 'components/validation-error';
import { useInputFocus } from 'hooks';

export default function ChartsWorkspaceDuplicate(props) {
  const { isOpen, onClose, onSubmit, workspace, isLoading, isError } = props;
  const {
    handleSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      name: workspace?.name ? `${workspace?.name} Duplicate` : '',
      config: workspace?.config || {},
    },
  });
  useInputFocus(setFocus, 'name');

  const handleOnSubmit = handleSubmit(form => {
    if (isFunction(onSubmit)) {
      onSubmit({ ...form, sourceWorkspaceId: workspace?.id });
    }
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} renderWindow={false}>
      <Modal.Window as="form" onSubmit={handleOnSubmit}>
        <Modal.Header
          icon="CopySimple"
          heading="Duplicate Workspace"
          subHeading={workspace?.name}
        />
        <Modal.Body className="space-y-3">
          {!!isError && <Message variant="danger">Something went wrong...</Message>}

          <Card variant="secondary">
            <div className="space-y-2">
              <Label htmlFor="name">Workspace Name</Label>
              <Input
                type="text"
                {...register('name', { required: 'Please enter a name.' })}
                readOnly={isLoading}
              />
              <ValidationError errors={errors} name="name" />
            </div>
          </Card>

          <ChartFiltersInput control={control} name="config" />
        </Modal.Body>
        <Modal.Footer className="justify-center">
          <Button type="submit" size="xl" loading={isLoading}>
            Create
          </Button>
        </Modal.Footer>
      </Modal.Window>
    </Modal>
  );
}
