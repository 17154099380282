import { omit } from 'lodash';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Card, Button, Icon, Text } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import LocationSelect from 'components/location-select';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import Select from 'components/select';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus } from 'hooks';
import { useProvisionedDevice, useWorkflowsWithGroups } from 'queries';

const DEFAULT_VALUES = {
  name: '',
  locationId: null,
  workflowId: null,
  groupId: null,
};

export default function DeviceCreateDetails() {
  const navigate = useNavigate();
  const { handleClose } = useModalContext();
  const { provisionedDeviceId } = useParams();
  const [error, setError] = useState();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    watch,
    control,
    reset,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });
  useInputFocus(setFocus, 'name');
  const workflowId = watch('workflowId');

  const {
    data,
    isLoading: fetching,
    isSuccess,
    error: fetchError,
  } = useProvisionedDevice(provisionedDeviceId);

  const workflows = useWorkflowsWithGroups();

  const qc = q.useQueryClient();
  const createDevice = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createDevice($form: createDeviceForm!) {
          device: createDevice(form: $form) {
            id
            name
            serialNumber
            family
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
    },
    onError(err) {
      setError(err);
    },
  });

  const updateDevice = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateDevice($form: updateDeviceForm!) {
          device: updateDevice(form: $form) {
            id
            name
            serialNumber
            family
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['devices'] });
      qc.invalidateQueries({ queryKey: ['device', data?.provisionedDevice?.device?.id] });
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    let result;
    if (data?.provisionedDevice?.device?.id) {
      result = await updateDevice.mutateAsync({
        id: data?.provisionedDevice?.device?.id,
        name: form?.name,
        locationId: form?.locationId?.value,
        // TODO: API doesn't support workflowId or groupId here
      });
    } else {
      result = await createDevice.mutateAsync({
        ...form,
        locationId: form?.locationId?.value,
        serialNumber: data?.provisionedDevice?.serialNumber,
      });
    }

    navigate(`/devices/create/${result?.device?.id}/subscription`, {
      state: { fromModal: true },
    });
  });

  useOnSuccess(
    () => {
      const device = data?.provisionedDevice?.device;
      reset({
        name: device?.name,
        locationId: { value: device?.location?.id },
      });
    },
    { isSuccess },
    [data, reset],
  );

  const loading = fetching || createDevice.isPending || updateDevice.isPending;
  const showWorkflowAndGroup = data?.provisionedDevice?.device?.id;

  return (
    <>
      <ModalTitle
        title="Enroll Device"
        icon="Aperture"
        showBackButton={false}
        loading={loading}
        handleClose={() => {
          if (window.confirm('Are you sure you want to cancel the enrollment process?')) {
            handleClose();
          }
        }}
      />
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-2">
        {error && (
          <Message variant="danger" title="Couldn't Save Device">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Error Loading Device">
            {fetchError.message}
          </Message>
        )}

        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              {...register('name', { required: 'Please enter a name.' })}
              readOnly={loading}
            />
            <ValidationError errors={errors} name="name" />
          </div>
        </Card>

        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <Label htmlFor="location">Location</Label>
              <Link
                to="./manage-locations"
                state={{
                  fromModal: true,
                }}
              >
                <Text
                  size="xs"
                  color="muted"
                  className="flex items-center cursor-pointer hover:underline"
                >
                  <Icon name="Gear" size="xs" className="mr-1" /> Manage Locations
                </Text>
              </Link>
            </div>

            <Controller
              render={({ field }) => (
                <LocationSelect
                  disabled={loading}
                  {...omit(field, 'ref')}
                  defaultLocation={data?.provisionedDevice?.device?.location}
                />
              )}
              name="locationId"
              control={control}
              rules={{
                required: true,
              }}
            />
            <ValidationError errors={errors} name="locationId" />
          </div>
        </Card>

        {showWorkflowAndGroup && (
          <Card variant="secondary" className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="workflowId">Workflow</Label>
              <div className="flex items-center">
                <Icon name="GitBranch" color="gradient" size="lg" className="mr-2" />
                <Select {...register('workflowId')} readOnly={loading}>
                  <option>Optional</option>
                  {workflows?.data?.workflows?.data?.map?.(w => (
                    <option key={w.id} value={w.id}>
                      {w.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="groupId">Group</Label>
              <div className="flex items-center">
                <Icon name="Folder" color="gradient" size="lg" className="mr-2" />
                <Select {...register('groupId')} readOnly={loading}>
                  <option>Optional</option>
                  {workflows?.data?.workflows?.data
                    ?.find(w => w.id === workflowId)
                    ?.groups?.data?.map?.(g => (
                      <option key={g.id} value={g.id}>
                        {g.name}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          </Card>
        )}
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleSubmit} type="submit" size="xl" loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
