import { Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

export function statePin(status) {
  switch (status) {
    case 'warn':
      return '/map-pin-warn.png';
    case 'danger':
      return '/map-pin-danger.png';
    case 'neutral':
      return '/map-pin-neutral.png';
    case 'stable':
      return '/map-pin-stable.png';
    default:
      return '/map-pin-neutral.png';
  }
}

export default function DevicesMapMarker({
  id,
  name,
  state,
  onClick,
  lat,
  lng,
  onNavigate = () => false,
}) {
  const navigate = useNavigate();

  function handleClick() {
    if (onClick) {
      return onClick();
    }

    navigate(`/devices/${id}`);
    return onNavigate();
  }

  return (
    <Marker
      title={name}
      onClick={handleClick}
      options={{
        position: {
          lat,
          lng,
        },
        icon: {
          url: statePin(state),
          size: new google.maps.Size(16, 16),
          origin: new google.maps.Point(0, 0),
          scaledSize: new google.maps.Size(12, 12),
          anchor: new google.maps.Point(6, 6),
        },
      }}
    />
  );
}
