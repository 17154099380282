import findPathDeep from 'deepdash/findPathDeep';
import { isEmpty } from 'lodash';
import set from 'lodash/fp/set';

import { Toggle } from '@optra/kit';

import { api, q } from 'config/api';

export default function ActivateLicenseToggle(props) {
  const { id, disabled, workflowId, marketplaceSkillId, label = 'Activate License' } = props;

  const qc = q.useQueryClient();
  const toggleSkillLicense = q.useMutation({
    mutationFn: form =>
      api(
        `mutation toggleSkillLicense($form: updateSkillLicenseForm!) {
          license: updateSkillLicense(form: $form) {
            id
          }
        }`,
        { form },
      ),
    async onMutate(form) {
      await qc.cancelQueries({ queryKey: ['workflowsLicenses'] });
      await qc.cancelQueries({ queryKey: ['skillLicenses'] });
      qc.setQueriesData({ queryKey: ['workflowsLicenses'] }, olds => {
        const path = findPathDeep(olds, (v, k) => k === 'id' && v === form.id, {
          pathFormat: 'array',
        });
        if (!path) return olds;
        return set([...path.slice(0, -1), 'disabled'], form.disabled, olds);
      });
      qc.setQueriesData({ queryKey: ['skillLicenses'] }, olds => {
        const path = findPathDeep(olds, (v, k) => k === 'id' && v === form.id, {
          pathFormat: 'array',
        });
        if (!path) return olds;
        return set([...path.slice(0, -1), 'disabled'], form.disabled, olds);
      });
      return null;
    },
    onError: (err, form) => {
      qc.setQueriesData({ queryKey: ['workflowsLicenses'] }, olds => {
        const path = findPathDeep(olds, (v, k) => k === 'id' && v === form.id, {
          pathFormat: 'array',
        });
        if (!path) return olds;
        return set([...path.slice(0, -1), 'disabled'], !form.disabled, olds);
      });
      qc.setQueriesData({ queryKey: ['skillLicenses'] }, olds => {
        const path = findPathDeep(olds, (v, k) => k === 'id' && v === form.id, {
          pathFormat: 'array',
        });
        if (!path) return olds;
        return set([...path.slice(0, -1), 'disabled'], !form.disabled, olds);
      });
    },
    onSettled() {
      qc.invalidateQueries({ queryKey: ['workflowsLicenses'] });
      qc.invalidateQueries({ queryKey: ['skillLicenses'] });
      if (!isEmpty(marketplaceSkillId))
        qc.invalidateQueries({ queryKey: ['marketplaceSkill', marketplaceSkillId] });
      if (!isEmpty(workflowId)) qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
    },
  });

  return (
    <Toggle
      label={label}
      controlled
      checked={!disabled}
      onChange={(e, nextDisabled) => toggleSkillLicense.mutate({ id, disabled: !nextDisabled })}
      disabled={toggleSkillLicense.isPending}
    />
  );
}
