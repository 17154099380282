import findPathDeep from 'deepdash/findPathDeep';
import { set, get } from 'lodash/fp';

import { api, q } from 'config/api';
import { workflowFragment } from 'queries/use-workflows';

export const isComplete = status => ['complete', 'canceled', 'error'].includes(status);
export const isInProgress = status => ['queued', 'inProgress', 'calculating'].includes(status);

export default function useDeployWorkflow() {
  const qc = q.useQueryClient();
  const deploy = q.useMutation({
    mutationFn: id => {
      return api(
        `${workflowFragment}
        mutation deployWorkflowV3($id: ID!) {
          deployment: deployWorkflowV3(id: $id) {
            id
            workflow {
              ...WorkflowFields
            }
          }
        }`,
        { id },
      );
    },
    async onMutate(id) {
      await qc.cancelQueries({ queryKey: ['workflows'] });
      const workflowsQueries = qc.getQueriesData({ queryKey: ['workflows'] });
      workflowsQueries.forEach(([queryKey, data]) => {
        const path = findPathDeep(data, n => n?.id === id, {
          leavesOnly: false,
        });
        if (!path) return;
        const current = get(path, data);
        qc.setQueryData(
          queryKey,
          set(
            path,
            {
              ...current,
              latestDeployment: {
                ...current.latestDeployment,
                completedTasks: 0,
                failedTasks: 0,
                totalTasks: 0,
                progress: 0,
                id: null,
                status: 'calculating',
              },
              status: {
                ...current.status,
                failed: 0,
                running: 0,
                starting: 0,
                unknown: 0,
                step: 'deploying',
              },
            },
            data,
          ),
        );
      });
    },
    onError: (err, id) => {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', id] });
    },
    onSuccess(r) {
      const workflowsQueries = qc.getQueriesData({ queryKey: ['workflows'] });
      workflowsQueries.forEach(([queryKey, data]) => {
        const path = findPathDeep(data, n => n?.id === r.deployment?.workflow?.id, {
          leavesOnly: false,
        });
        qc.setQueryData(queryKey, set(path, r.deployment?.workflow, data));
      });

      qc.invalidateQueries({ queryKey: ['workflow', r.deployment?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowDeploymentExecutionTasks', r.deployment?.id] });
    },
  });

  const cancel = q.useMutation({
    mutationFn: id =>
      api(
        `${workflowFragment}
        mutation ($id: ID) {
          deployment: cancelWorkflowDeployment(workflowId: $id) {
            id
            workflow {
              ...WorkflowFields
            }
          }
        }`,
        { id },
      ),
    async onMutate(id) {
      await qc.cancelQueries({ queryKey: ['workflows'] });
      const workflowsQueries = qc.getQueriesData({ queryKey: ['workflows'] });
      workflowsQueries.forEach(([queryKey, data]) => {
        const path = findPathDeep(data, n => n?.id === id, {
          leavesOnly: false,
        });
        if (!path) return;
        const current = get(path, data);
        qc.setQueryData(
          queryKey,
          set(
            path,
            {
              ...current,
              latestDeployment: {
                ...current.latestDeployment,
                status: 'canceled',
              },
              status: {
                ...current.status,
                step: 'running',
              },
            },
            data,
          ),
        );
      });
    },
    onError: (err, id) => {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', id] });
    },
    onSuccess(r) {
      const workflowsQueries = qc.getQueriesData({ queryKey: ['workflows'] });
      workflowsQueries.forEach(([queryKey, data]) => {
        const path = findPathDeep(data, n => n?.id === r.deployment?.workflow?.id, {
          leavesOnly: false,
        });
        qc.setQueryData(queryKey, set(path, r.deployment?.workflow, data));
      });

      qc.invalidateQueries({ queryKey: ['workflow', r.deployment?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowDeploymentExecutionTasks', r.deployment?.id] });
    },
  });

  return { deploy, cancel };
}
