import { isNil, last } from 'lodash';
import { forwardRef, useRef } from 'react';

import { Button, Icon, Text } from '@optra/kit';

import useParseFileUrl from 'lib/use-parse-file-url';

export default forwardRef(function FileInputButton(props, ref) {
  const { value, onChange, accept, disabled } = props;
  const internalRef = useRef();

  const url = useParseFileUrl(value);
  const hasFile = !isNil(value);
  const cursor = disabled ? 'cursor-not-allowed' : 'cursor-pointer';

  return (
    <div>
      <Button
        type={null}
        className={`relative ${cursor} ${hasFile ? 'opacity-0 h-0 w-0 pointer-events-none' : ''}`}
        style={{
          ...(hasFile
            ? {
                padding: 0,
                border: 'none',
                position: 'absolute',
              }
            : {}),
        }}
        icon={hasFile ? null : 'CloudArrowUp'}
        variant="tertiary"
        disabled={disabled}
      >
        Upload
        <input
          ref={el => {
            internalRef.current = el;
            ref?.(el);
          }}
          type="file"
          onChange={e => onChange(e.target.files?.[0])}
          accept={accept}
          className={`absolute inset-0 opacity-0 ${cursor} appearance-none file:appearance-none file:${cursor}`}
          disabled={disabled}
        />
      </Button>

      {hasFile && (
        <div className={`flex justify-between ${cursor} items-center gap-2`}>
          <Icon name="FileText" size="lg" />
          <Text className="flex-1">{value?.name || last(url?.split?.('/'))}</Text>
          <Text
            variant="tertiary"
            onClick={() => {
              internalRef.current.value = '';
              onChange(null);
            }}
            size="xs"
            color="muted"
            className="flex items-center hover:underline"
          >
            Remove
          </Text>
        </div>
      )}
    </div>
  );
});
