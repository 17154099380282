import { CardElement } from '@stripe/react-stripe-js';
import cx from 'classnames';

import Label from 'components/label';
import useIsDarkMode from 'hooks/use-is-dark-mode';

const VARIANTS = {
  base: [
    'rounded-md',
    'shadow-sm',
    'border',
    'space-x-2',
    'p-4',
    // Light
    'border-opacity-50',
    'bg-light-bg-secondary',
    'border-light-fg-secondary',
    // Dark
    'dark:border-opacity-50',
    'dark:bg-dark-bg-primary',
    'dark:border-dark-fg-secondary',
  ],
};

export default function CreditCardInput({ className, onChange, disabled = false }) {
  const isDarkMode = useIsDarkMode();

  const foregroundPrimary = isDarkMode ? '#EBEAED' : '#25282D';
  const foregroundSecondary = isDarkMode ? '#817F8D' : '#858C99';

  return (
    <div className="space-y-2">
      <Label>Payment Info</Label>
      <div className={cx(VARIANTS.base, className, disabled && 'opacity-60 select-none')}>
        <CardElement
          onChange={onChange}
          options={{
            style: {
              base: {
                iconColor: '#00C425',
                color: foregroundPrimary,
                fontFamily:
                  'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: foregroundSecondary,
                },
              },
              invalid: {
                color: '#A62921',
                iconColor: '#A62921',
              },
            },
          }}
        />
      </div>
    </div>
  );
}
