import cx from 'classnames';
import { truncate } from 'lodash';

import { Icon, Text, InteractiveWrap } from '@optra/kit';

import DependencyIcon from 'modals/skill-pipeline-builder/components/dependency-icon';

const VARIANTS = {
  default: [
    'border',
    // Light
    'bg-light-bg-secondary',
    'border-gray-200/60',
    // Dark
    'dark:bg-dark-bg-primary',
    'dark:border-black-800',
  ],
  empty: [
    'border',
    // Light
    'bg-light-bg-secondary',
    'border-gray-200/60',
    // Dark
    'dark:bg-dark-bg-secondary',
    'dark:border-black-800',
    'bg-light-fg-tertiary/20',
    'dark:bg-dark-bg-secondary/20',
  ],
  invalid: ['border', 'border-red', 'text-red'],
};

function ListItem({
  variant = 'default',
  icon = 'BracketsSquare',
  name,
  description,
  errorDescription,
  components = {},
  className,
  truncateDescription = true,
  inputs = [],
  outputs = [],
  isDraggedOn = false,
  to,
  disabled,
  renderBadge = () => false,
  ...rest
}) {
  return (
    <div className={cx(['w-full', 'py-2', isDraggedOn && 'pt-[82px]'])} {...rest}>
      <InteractiveWrap
        className={cx([
          'w-full',
          'relative',
          'flex',
          'flex-row',
          'items-center',
          'rounded-md',
          'py-5',
          'px-8',
          'hover:opacity-60',
          VARIANTS[variant],
          className,
        ])}
        role="button"
        type="button"
        to={to}
        draggable={false}
        disabled={disabled}
      >
        {inputs.length > 0 && (
          <div
            className={cx([
              'flex',
              'flex-col',
              'items-center',
              'justify-center',
              'space-y-1',
              'rounded-l-md',
              'bg-opacity-20',
              'bg-light-fg-tertiary',
              'dark:bg-dark-bg-secondary',
              'absolute',
              'top-0',
              'left-0',
              'bottom-0',
              'p-1',
              'border-r',
              'border-gray-200/60',
              'dark:border-black-800',
            ])}
          >
            {inputs.map(input => {
              if (Array.isArray(input.type)) {
                return input.type.map(type => (
                  <DependencyIcon key={`${input.key}-${type.key}`} color={type.color} />
                ));
              } else {
                return <DependencyIcon key={input.key} color={input.type.color} />;
              }
            })}
          </div>
        )}
        <Icon name={icon} color="primary" weight="duotone" />
        <div className="flex-1 flex-col ml-2">
          <div className="flex flex-row space-x-2">
            <Text size="sm" className="font-medium block">
              {truncate(name, { length: 36 })}
            </Text>
            {renderBadge()}
          </div>
          {!!description && (
            <Text size="xs" color="muted" className="block leading-tight">
              {truncateDescription ? truncate(description, { length: 60 }) : description}
              {!!errorDescription && <Text color="danger">{errorDescription}</Text>}
            </Text>
          )}
        </div>
        {outputs.length > 0 && (
          <div
            className={cx([
              'flex',
              'flex-col',
              'items-center',
              'justify-center',
              'space-y-1',
              'rounded-r-md',
              'bg-opacity-20',
              'bg-light-fg-tertiary',
              'dark:bg-dark-bg-secondary',
              'absolute',
              'top-0',
              'right-0',
              'bottom-0',
              'p-1',
              'border-l',
              'border-gray-200/60',
              'dark:border-black-800',
            ])}
          >
            {outputs.map(output => (
              <DependencyIcon key={`${output.key}-${output.type.key}`} color={output.type.color} />
            ))}
          </div>
        )}
      </InteractiveWrap>
    </div>
  );
}

export default ListItem;
