import cx from 'classnames';

import { Icon } from '@optra/kit';

import Label from 'components/label';
import StepperInput from 'components/stepper-input';

export default function InputNumberLock(props) {
  const {
    locked,
    onClickLock,
    className,
    label,
    name,
    value,
    onChange,
    showUnitLabel,
    unitLabel = '%',
    min,
    max,
  } = props;

  return (
    <div className={cx('grid grid-cols-6 gap-1', className)}>
      <div className="flex items-center justify-end col-span-1">
        <Icon
          name={locked ? 'Lock' : 'LockOpen'}
          color={locked ? 'primary' : 'gray'}
          size="sm"
          weight="duotone"
          onClick={onClickLock}
        />
      </div>
      <Label htmlFor={name} className="flex items-center justify-end col-span-2">
        {label}
      </Label>
      <div className="col-span-3 relative">
        <StepperInput
          id={name}
          disabled={locked}
          value={value || ''}
          onChange={onChange}
          min={min}
          max={max}
          showUnitLabel={showUnitLabel}
          unitLabel={unitLabel}
        />
      </div>
    </div>
  );
}
