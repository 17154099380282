import cx from 'classnames';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-terminal';
import YAML from 'yaml';

import { Dropdown, DROPDOWN_MENU_PLACEMENT, Button, Icon, SideModal } from '@optra/kit';

import useIsDarkMode from 'hooks/use-is-dark-mode';

import { usePipelineContext } from '../context';

export default function ConfigPreview({ isOpen, onClose }) {
  const { pipeline, showConfigPreview, setShowConfigPreview } = usePipelineContext();
  const isDarkMode = useIsDarkMode();

  const preview = YAML.stringify(pipeline, { aliasDuplicateObjects: false });

  const handleCopy = () => {
    navigator.clipboard.writeText(preview);
    window.alert('Copied config file to your clipboard.');
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = 'config.yml';
    const data = new Blob([preview], { type: 'text/plain' });
    link.href = window.URL.createObjectURL(data);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <SideModal
      isOpen={showConfigPreview}
      onClose={() => setShowConfigPreview(false)}
      icon="Code"
      title={
        <Dropdown
          components={{
            button: ({ open }) => (
              <Button as="span" icon="Gear" size="xs" variant="tertiary">
                Configuration File
                <Icon
                  name={open ? 'CaretUp' : 'CaretDown'}
                  weight="regular"
                  size="xs"
                  className="ml-1"
                />
              </Button>
            ),
            body: (
              <>
                <Dropdown.Item icon="Clipboard" text="Copy to Clipboard" onClick={handleCopy} />
                {/* <Dropdown.Item icon="ClipboardText" text="Paste File" />
                <Dropdown.Item icon="FileArrowUp" text="Upload" /> */}
                <Dropdown.Item icon="FloppyDisk" text="Download" onClick={handleDownload} />
              </>
            ),
          }}
          placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
        />
      }
    >
      <div className={cx('h-full')}>
        <div className="h-full w-full relative">
          <AceEditor
            mode="yaml"
            theme={isDarkMode ? 'terminal' : 'tomorrow'}
            value={preview}
            placeholder="Preview YAML config"
            height="100%"
            width="100%"
            tabSize={2}
            onChange={() => false}
            setOptions={{ useWorker: false }}
            readOnly
          />
        </div>
      </div>
    </SideModal>
  );
}
