/* eslint-disable i18next/no-literal-string */
import { PageWrapper } from '@optra/kit';

export default function TermsOfUse() {
  return (
    <PageWrapper heading="Terms of Use">
      <div className="text-content">
        <p>
          Lexmark considers the privacy of our customers to be paramount and has developed a terms
          of use to protect and inform our users (&quot;Terms of Use&quot;). The current Privacy
          Policy is incorporated herein by reference and made part of these Terms and Conditions, as
          are the Purchase Terms and Conditions.
        </p>
        <h2>Feedback</h2>
        <p>
          Lexmark welcomes all comments, feedback, information, or materials, which you submit to
          Lexmark through or in conjunction with this Site (&quot;Feedback&quot;). Please note that
          your Feedback shall be considered non-confidential and become property of Lexmark. By
          submitting your Feedback to Lexmark, you agree to a no charge assignment to Lexmark of all
          right, title and interest in copyrights and other intellectual property rights on a
          worldwide basis to your Feedback. Lexmark shall be free to use your Feedback on an
          unrestricted basis.
        </p>
        <h2>Links</h2>
        <p>
          This Site may contain links to other Web sites on the Internet that are owned and operated
          by third parties. You acknowledge that Lexmark does not endorse and is not responsible for
          the operation of or content located on or through any such Web site.
        </p>
        <h2>Termination of Usage</h2>
        <p>
          Lexmark may terminate or suspend your access to or use of all or part of this Site,
          without notice, for any conduct that Lexmark believes is disruptive to this Site or is in
          violation of any applicable law or these Terms and Conditions.
        </p>
        <h2>Disclaimer and Limitation of Liability as to this Site</h2>
        <p>
          While Lexmark endeavors to provide the most accurate, up-to-date information available,
          the directories and information on this Site may be out of date or include omissions,
          inaccuracies or other errors. This Site and the materials therein are provided &quot;AS
          IS.&quot; Lexmark makes no representations or warranties, either express or implied, of
          any kind with respect to this Site, its operations, contents, information or materials.
          LEXMARK EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND WITH RESPECT
          TO THIS SITE OR ITS USE, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY AND FITNESS FOR A
          PARTICULAR PURPOSE. You agree that Lexmark, its directors, officers, employees or other
          representatives shall not be liable for damages arising from the operation, content, or
          use of this Site. You agree that this limitation of liability is comprehensive and applies
          to all damages of any kind, including without limitation direct, indirect, compensatory,
          special, incidental, punitive and consequential damages, damages for loss of profits,
          revenue, data and use, incurred by you or any third party, whether in an action in
          contract or tort, arising from your access to, and use of, this Site or any other
          hyper-linked Web site.
        </p>
        <h2>Compliance With Laws</h2>
        <p>
          You agree to comply with all applicable laws, statutes, ordinances and regulations
          regarding your use of this Site and your purchase of products or services through this
          Site. You acknowledge that violations of the Terms and Conditions or the Privacy Policy
          could be subject to criminal or civil penalties.
        </p>
        <h2>Applicable Law</h2>
        <p>
          You agree that any legal action brought against Lexmark arising out of, or relating to
          your use of the Online Store or any product purchased from Lexmark shall be governed by
          the laws of the Commonwealth of Kentucky, without regard to conflict of law principles.
          You agree that the sole jurisdiction and venue for any litigation arising from your use of
          or orders made on this Site shall be an appropriate federal or state court located in
          Fayette County, Kentucky. Lexmark makes no representations that the content in this Site
          is appropriate for access outside the United States. Those who choose to access this site
          from outside the United States do so on their own initiative and are responsible for
          compliance with local laws and are strictly prohibited from purchasing products from this
          Site.
        </p>
        <h2>Changes</h2>
        <p>
          Lexmark reserves the right to change these terms and conditions at any time. If Lexmark
          makes such changes, Lexmark will post the revised terms and conditions here, along with
          the date of the revision. Any changes will apply only from the date of the revision.
          Amended dispute resolution provisions will not apply to any dispute of which the parties
          had actual notice as of the date of the change.
        </p>
        <h2>General</h2>
        <p>
          If any provision or provisions of these Terms and Conditions shall be held to be invalid,
          illegal, or unenforceable, such provision shall be enforced to the fullest extent
          permitted by applicable law and the validity, legality, and enforceability of the
          remaining provisions shall not be affected thereby. Other Terms and Conditions How to
          Contact Lexmark If you have any comments or questions, please do not hesitate to contact
          Lexmark at 1-800-LEXMARK (539-6275).
        </p>
      </div>
    </PageWrapper>
  );
}
