import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { Card, Button } from '@optra/kit';

import Label from 'components/label';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import Textarea from 'components/textarea';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import formatPublicationState from 'lib/format-publication-state';

export default function PublishSkillReview(props) {
  const { skillId, marketplaceSkillVersionId } = useParams();
  const navigate = useNavigate();

  const Form = useForm();
  const defaults = q.useQuery({
    queryKey: ['skill', skillId, 'review', marketplaceSkillVersionId],
    queryFn: () =>
      api(
        `query reviewSkill($id: ID!) {
          version: marketplaceSkillVersion(id: $id) {
            id
            version
            publicationState
            requestedChanges
          }
        }`,
        { id: marketplaceSkillVersionId },
      ),
    enabled: !!marketplaceSkillVersionId,
  });

  useOnSuccess(
    () => {
      const version = defaults.data.version;
      Form.reset({
        requestedChanges: version?.requestedChanges || '',
      });
    },
    { isSuccess: defaults.isSuccess },
    [Form, defaults.data],
  );

  const qc = q.useQueryClient();
  const requestChanges = q.useMutation({
    mutationFn: form =>
      api(
        `mutation requestChanges($form: requestChangesMarketplaceSkillVersionForm!) {
          marketplaceSkillVersion: requestChangesMarketplaceSkillVersion(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
    },
  });

  const handleSubmit = Form.handleSubmit(async form => {
    const formValid = await Form.trigger();
    if (!formValid) throw new Error('Form invalid!');

    return requestChanges.mutateAsync({
      id: marketplaceSkillVersionId,
      requestedChanges: form.requestedChanges,
    });
  });

  const approve = q.useMutation({
    mutationFn: id =>
      api(
        `mutation approveMarketplaceSkill($id: ID!) {
          marketplaceSkillVersion: approveMarketplaceSkillVersion(id: $id) {
            id
          }
        }`,
        { id: marketplaceSkillVersionId },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['skill', skillId] });
      navigate('../');
    },
  });

  // NOTE: This is not a form because the parent context is a massive form and we should avoid forms within forms
  return (
    <ModalInner>
      <ModalTitle
        title={`Review ${defaults.data?.version?.version}`}
        subTitle={
          formatPublicationState(defaults.data?.version?.publicationState)?.toUpperCase?.() || ''
        }
        icon="CheckCircle"
        loading={defaults.isLoading}
        renderActions={() => (
          <Button
            size="xs"
            variant="secondary"
            icon="Checks"
            loading={approve.isPending}
            onClick={() => approve.mutate()}
          >
            Approve
          </Button>
        )}
      />
      <ModalBody className="space-y-4">
        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="requestedChanges">Request Changes</Label>
            <Textarea
              rows={6}
              {...Form.register('requestedChanges')}
              readOnly={Form.formState.isSubmitting}
            />
            <ValidationError errors={Form.formState.errors} name="requestedChanges" />
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          loading={Form.formState.isSubmitting}
          onClick={e => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          Request Changes
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
