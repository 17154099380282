import { api, q } from 'config/api';

export default function useCurrentOrganizationMembers(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['organizationMembers'],
    queryFn({ pageParam }) {
      return api(
        `query currentOrganizationMembers($list: listFields) {
          list: currentOrganizationMembers(list: $list) {
            data {
              id
              email
              hidden
              roles
              user: userProfile {
                id
                email
                name
                image
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
    select: data => (data?.pages || []).flatMap(page => page.list?.data || []),
    ...overrides,
  });
}
