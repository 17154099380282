import { isEmpty } from 'lodash';

import { DIAGNOSTICS_VIEW_KEY } from 'modals/skill-pipeline-builder/config';
import { usePipelineContext } from 'modals/skill-pipeline-builder/context';
import getNodeDef from 'modals/skill-pipeline-builder/context/get-node-def';

export default function usePipelineNode(nodeId) {
  const { pipeline, updatePipeline } = usePipelineContext();

  function update(props) {
    const { key, value, labels } = props;

    updatePipeline({
      action: 'upsertNode',
      payload: {
        nodeId,
        key,
        value,
        labels,
      },
    });
  }

  const node = pipeline.pipeline?.[nodeId];
  const nodeDef = getNodeDef(node, nodeId);

  const nodeInputKeys = Object.fromEntries((nodeDef?.inputs || []).map(input => [input.key, '']));
  const hasInput = Object.values({ ...nodeInputKeys, ...node?.inputs }).every(
    input => !isEmpty(input),
  );

  function diagnosticsKeyForOutput(output) {
    return `${nodeId}|${nodeDef.label} ${output.label}`;
  }

  function diagnosticsNodeHasOutputKey(key) {
    return pipeline.pipeline?.[DIAGNOSTICS_VIEW_KEY]?.inputs?.[key] || false;
  }

  function nodesConnectedToOutput(outputKey) {
    const allNodes = Object.entries(pipeline.pipeline).map(([id, node]) => ({
      id,
      ...node,
    }));

    const key = `${nodeId}.${outputKey}`;
    return allNodes
      .map(n => {
        if (Object.values(n.inputs).some(i => i === key)) {
          return n;
        }
        return null;
      })
      .filter(dep => !isEmpty(dep));
  }

  function availableOutputsForInputType({ type }) {
    return Object.keys(pipeline.pipeline)
      .filter(id => id !== nodeId)
      .flatMap(id => {
        const outputNode = {
          id,
          ...pipeline.pipeline[id],
        };
        const { outputs = [] } = getNodeDef(outputNode);
        let validOutputs = [];
        if (type?.key === 'Any' || (Array.isArray(type) && type?.some(t => t.key === 'Any'))) {
          validOutputs = outputs;
        } else {
          validOutputs = outputs.filter(o => {
            // Handle `type` param being an Array
            if (Array.isArray(type)) {
              return type.some(t => t.key === o.type.key);
            }
            return type.key === o.type.key;
          });
        }
        return validOutputs.map(o => ({ ...o, node: outputNode }));
      });
  }

  return [
    node,
    {
      pipeline,
      update,
      nodeDef,
      hasInput,
      diagnosticsKeyForOutput,
      diagnosticsNodeHasOutputKey,
      nodesConnectedToOutput,
      availableOutputsForInputType,
    },
  ];
}
