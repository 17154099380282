import { isEmpty, truncate } from 'lodash';

import { Table, Button, CopyButton, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Message from 'components/message';
import { api, q } from 'config/api';
import dateLabel from 'lib/date-label';

function RemoveFirmwareButton({ id }) {
  const qc = q.useQueryClient();
  const removeFirmware = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeFirmware($id: ID!) {
          firmware: removeFirmware(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['firmwares'] });
    },
  });

  function handleRemove() {
    if (window.confirm('Are you sure you want to remove this firmware?')) {
      removeFirmware.mutate(id);
    }
  }

  return (
    <Button
      variant="secondary"
      size="xs"
      loading={removeFirmware.isPending || removeFirmware.data?.firmware?.id}
      onClick={handleRemove}
      icon="Trash"
    >
      Remove
    </Button>
  );
}

export default function AdminFirmwaresList({ error, firmwares = [] }) {
  if (error) {
    return (
      <Message variant="danger" title="Couldn't Load Firmware">
        {error.message}
      </Message>
    );
  }

  if (isEmpty(firmwares)) {
    return (
      <EmptyContentMessage
        icon="Cpu"
        title="No Firmware"
        renderAction={() => (
          <Button to="/admin/firmware/create" icon="Plus">
            New Firmware
          </Button>
        )}
      />
    );
  }

  return (
    <Table className="whitespace-nowrap">
      <Table.Head>
        <Table.Row>
          <Table.TH>Name</Table.TH>
          <Table.TH>Key</Table.TH>
          <Table.TH>Target</Table.TH>
          <Table.TH>Created</Table.TH>
          <Table.TH />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {firmwares.map(firmware => (
          <Table.Row key={firmware?.id} hover>
            <Table.TD>{firmware?.name}</Table.TD>
            <Table.TD>
              <div className="flex items-center">
                <Text size="xs" color="muted">
                  {truncate(firmware?.key, { length: 34 })}
                </Text>
                <CopyButton value={firmware?.key} />
              </div>
            </Table.TD>
            <Table.TD>
              <Text size="xs" color="muted">
                {firmware?.buildTarget}
              </Text>
            </Table.TD>
            <Table.TD>
              <Text size="xs" color="muted">
                {dateLabel(firmware.createdAt)}
              </Text>
            </Table.TD>

            <Table.TD collapsed>
              <RemoveFirmwareButton id={firmware?.id} />
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
