import cx from 'classnames';
import get from 'lodash/get';

import { Icon, Text } from '@optra/kit';

export default function ValidationError({ message: _message, errors = {}, name, className }) {
  const error = get(errors, name);

  if (!error && !_message) {
    return null;
  }

  let message = _message || error?.message;

  if (!message && error?.type === 'pattern') {
    message = `Please enter a valid ${name}`;
  }

  if (!message) {
    message = 'This field is required';
  }

  return (
    <div className={cx(['flex items-center', className])}>
      <Icon name="Warning" size="xs" className="text-red mr-2" />
      <Text size="sm" className="text-sm text-red-200">
        {message}
      </Text>
    </div>
  );
}
