"use strict";
Object.defineProperty(exports, "giftCardPurchaseOrderUpdate", {
    enumerable: true,
    get: function() {
        return giftCardPurchaseOrderUpdate;
    }
});
const _zod = require("zod");
const giftCardPurchaseOrderUpdate = _zod.z.object({
    id: _zod.z.string().min(1, 'Purchase order id is required'),
    poNumber: _zod.z.string().optional(),
    vendor: _zod.z.string().optional()
}).refine((input)=>{
    return (input === null || input === void 0 ? void 0 : input.poNumber) || (input === null || input === void 0 ? void 0 : input.vendor);
}, {
    message: 'Purchase order number or vendor is required for update',
    path: [
        'poNumber',
        'vendor'
    ]
});
