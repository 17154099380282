import { Heading, IconButton } from '@optra/kit';

import BillingTransactionListItem from 'components/billing-transaction-list-item';
import List from 'components/list';
import { useBillingAccountTransactions } from 'queries';

export default function BillingTransactions() {
  const billingAccountTransactions = useBillingAccountTransactions();
  const transactions = billingAccountTransactions?.data || [];

  return (
    <>
      <div className="flex flex-row space-x-2">
        <IconButton name="CaretLeft" weight="line" variant="plain" to="/profile" />
        <Heading>Transactions</Heading>
      </div>
      <List>
        {transactions.map(transaction => (
          <BillingTransactionListItem
            key={transaction?.id}
            id={transaction?.id}
            amount={transaction?.amount}
            description={transaction?.description}
            createdAt={transaction?.createdAt}
            recurringInterval={transaction?.recurringInterval}
          />
        ))}
      </List>
    </>
  );
}
