import cx from 'classnames';
import { isEmpty, isFinite } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import Feature from 'components/feature';
import FeatureToggle from 'components/feature-toggle';
import Input from 'components/input';
import Label from 'components/label';

const isValidPort = port =>
  ![22, 80, 443].includes(parseInt(port)) || 'Ports 22, 80, and 443 are not allowed';

export default function SkillComposePrivilegesFields({ visible, loading = false, form }) {
  const {
    defaultValues,
    setValue,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = form;
  const [hasExposedUIPort, setHasExposedUIPort] = useState(isFinite(parseInt(defaultValues?.port)));
  const portValue = watch('port');
  useEffect(() => {
    if (!isEmpty(portValue) || isFinite(parseInt(portValue))) setHasExposedUIPort(true);
  }, [portValue]);
  return (
    <div className={cx(visible ? 'block' : 'hidden')}>
      <Feature feature="deviceTunnel">
        <FeatureToggle
          icon="Globe"
          title="Web UI"
          description="Expose a web-based user-interface via a port of your choosing."
          readOnly={loading}
          checked={hasExposedUIPort}
          expanded={hasExposedUIPort}
          onChange={() => {
            setHasExposedUIPort(hasExposedUIPort => !hasExposedUIPort);
            if (hasExposedUIPort) {
              setValue('port', '');
              clearErrors('port');
            }
          }}
          errors={errors?.port && [{ port: errors?.port }]}
        >
          <Controller
            render={({ field }) => (
              <div className="flex items-end justify-between">
                <div className="space-y-2">
                  <Label htmlFor="port">Port Number</Label>
                  <Input type="number" {...field} value={field.value || ''} />
                </div>
              </div>
            )}
            name="port"
            control={control}
            disabled={loading}
            defaultValue={isFinite(parseInt(defaultValues?.port)) || ''}
            rules={{
              validate: hasExposedUIPort ? isValidPort : {},
            }}
          />
        </FeatureToggle>
      </Feature>
    </div>
  );
}
