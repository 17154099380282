"use strict";
Object.defineProperty(exports, /**
 * Known keys as defined in the spec [here]{@link https://github.com/Azure/iotedge/tree/main/edge-agent/src/Microsoft.Azure.Devices.Edge.Agent.Docker/models}
 */ "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _zod = require("zod");
const _default = _zod.z.object({
    Hostname: _zod.z.string().nullish(),
    Domainname: _zod.z.string().nullish(),
    User: _zod.z.string().nullish(),
    AttachStdin: _zod.z.boolean().nullish(),
    AttachStdout: _zod.z.boolean().nullish(),
    AttachStderr: _zod.z.boolean().nullish(),
    Tty: _zod.z.boolean().nullish(),
    OpenStdin: _zod.z.boolean().nullish(),
    StdinOnce: _zod.z.boolean().nullish(),
    Env: _zod.z.array(_zod.z.string()).nullish(),
    Cmd: _zod.z.array(_zod.z.string()).nullish(),
    Entrypoint: _zod.z.string().nullish(),
    Image: _zod.z.string().nullish(),
    Labels: _zod.z.record(_zod.z.string()).nullish(),
    Volumes: _zod.z.record(_zod.z.object({}).passthrough()).nullish(),
    WorkingDir: _zod.z.string().nullish(),
    NetworkDisabled: _zod.z.boolean().nullish(),
    MacAddress: _zod.z.string().nullish(),
    ExposedPorts: _zod.z.record(_zod.z.object({}).passthrough()).nullish(),
    StopSignal: _zod.z.string().nullish(),
    StopTimeout: _zod.z.number().int().positive().nullish(),
    HostConfig: _zod.z.object({
        AutoRemove: _zod.z.boolean().nullish(),
        Binds: _zod.z.array(_zod.z.string()).nullish(),
        BlkioDeviceReadBps: _zod.z.array(_zod.z.object({
            Path: _zod.z.string(),
            Rate: _zod.z.number()
        }).strict()).nullish(),
        BlkioDeviceReadIOps: _zod.z.array(_zod.z.object({
            Path: _zod.z.string(),
            Rate: _zod.z.number()
        }).strict()).nullish(),
        BlkioDeviceWriteBps: _zod.z.array(_zod.z.object({
            Path: _zod.z.string(),
            Rate: _zod.z.number()
        }).strict()).nullish(),
        BlkioDeviceWriteIOps: _zod.z.array(_zod.z.object({
            Path: _zod.z.string(),
            Rate: _zod.z.number()
        }).strict()).nullish(),
        BlkioWeight: _zod.z.number().int().min(0).max(1000).nullish(),
        BlkioWeightDevice: _zod.z.array(_zod.z.object({
            Path: _zod.z.string(),
            Weight: _zod.z.number().int().min(0)
        }).strict()).nullish(),
        CpuCount: _zod.z.number().int().nullish(),
        CpuPercent: _zod.z.number().int().nullish(),
        CpuPeriod: _zod.z.number().int().nullish(),
        CpuQuota: _zod.z.number().int().nullish(),
        CpuRealtimePeriod: _zod.z.number().int().nullish(),
        CpuRealtimeRuntime: _zod.z.number().int().nullish(),
        CpuShares: _zod.z.number().int().nullish(),
        CapAdd: _zod.z.array(_zod.z.string()).nullish(),
        CapDrop: _zod.z.array(_zod.z.string()).nullish(),
        Cgroup: _zod.z.string().nullish(),
        CgroupParent: _zod.z.string().nullish(),
        ConsoleSize: _zod.z.tuple([
            _zod.z.number(),
            _zod.z.number()
        ]).nullish(),
        ContainerIDFile: _zod.z.string().nullish(),
        CpusetCpus: _zod.z.string().nullish(),
        CpusetMems: _zod.z.string().nullish(),
        Dns: _zod.z.array(_zod.z.string()).nullish(),
        DnsOptions: _zod.z.array(_zod.z.string()).nullish(),
        DnsSearch: _zod.z.array(_zod.z.string()).nullish(),
        DiskQuota: _zod.z.number().int().nullish(),
        ExtraHosts: _zod.z.array(_zod.z.string()).nullish(),
        GroupAdd: _zod.z.array(_zod.z.string()).nullish(),
        IOMaximumIOps: _zod.z.number().int().nullish(),
        IOMaximumBandwidth: _zod.z.number().int().nullish(),
        Init: _zod.z.boolean().nullish(),
        InitPath: _zod.z.string().nullish(),
        IpcMode: _zod.z.string().regex(/(none|private|shareable|host|^container:.*)/, 'Must be one of none, private, shareable, host, or container:*').nullish(),
        Isolation: _zod.z.enum([
            'default',
            'process',
            'hyperv'
        ]).nullish(),
        KernelMemory: _zod.z.number().int().nullish(),
        Links: _zod.z.array(_zod.z.string()).nullish(),
        MaximumIOBps: _zod.z.number().int().nullish(),
        MaximumIOps: _zod.z.number().int().nullish(),
        Memory: _zod.z.number().int().nullish(),
        MemoryReservation: _zod.z.number().int().nullish(),
        MemorySwap: _zod.z.number().int().nullish(),
        MemorySwappiness: _zod.z.number().int().min(0).max(100).nullish(),
        NanoCpus: _zod.z.number().int().nullish(),
        NetworkMode: _zod.z.string().nullish(),
        OomKillDisable: _zod.z.boolean().nullish(),
        OomScoreAdj: _zod.z.number().int().nullish(),
        PidMode: _zod.z.string().regex(/(host|$container:.*)/, 'Must be one of host or container:*').nullish(),
        PidsLimit: _zod.z.number().int().nullish(),
        Privileged: _zod.z.boolean().nullish(),
        PublishAllPorts: _zod.z.boolean().nullish(),
        ReadonlyRootfs: _zod.z.boolean().nullish(),
        Runtime: _zod.z.string().nullish(),
        SecurityOpt: _zod.z.array(_zod.z.string()).nullish(),
        ShmSize: _zod.z.number().int().min(0).nullish(),
        StorageOpt: _zod.z.object({}).passthrough().nullish(),
        Sysctls: _zod.z.object({}).passthrough().nullish(),
        Tmpfs: _zod.z.object({}).passthrough().nullish(),
        UTSMode: _zod.z.string().nullish(),
        Ulimits: _zod.z.array(_zod.z.object({
            Name: _zod.z.string(),
            Soft: _zod.z.number().int(),
            Hard: _zod.z.number().int()
        }).strict()).nullish(),
        UsernsMode: _zod.z.string().nullish(),
        VolumeDriver: _zod.z.string().nullish(),
        VolumesFrom: _zod.z.array(_zod.z.string()).nullish(),
        // OtherProperties // ignored
        PortBindings: _zod.z.record(_zod.z.array(_zod.z.object({
            HostIp: _zod.z.string().nullish(),
            HostPort: _zod.z.string()
        }).passthrough())).nullish(),
        Devices: _zod.z.array(_zod.z.object({
            PathOnHost: _zod.z.string().nullish(),
            PathInContainer: _zod.z.string().nullish(),
            CgroupPermissions: _zod.z.string().nullish()
        }).passthrough()).nullish(),
        Mounts: _zod.z.array(_zod.z.object({
            ReadOnly: _zod.z.boolean().nullish(),
            Source: _zod.z.string().nullish(),
            Target: _zod.z.string().nullish(),
            Type: _zod.z.string().nullish()
        }).passthrough()).nullish(),
        LogConfig: _zod.z.object({
            Type: _zod.z.enum([
                'json-file',
                'syslog',
                'journald',
                'gelf',
                'fluentd',
                'awslogs',
                'splunk',
                'etwlogs',
                'none'
            ]),
            Config: _zod.z.object({}).passthrough().nullish()
        }).passthrough().nullish(),
        RestartPolicy: _zod.z.object({
            Name: _zod.z.enum([
                '',
                'always',
                'unless-stopped',
                'on-failure'
            ]),
            MaximumRetryCount: _zod.z.number().int()
        }).passthrough().nullish()
    }).nullish(),
    NetworkingConfig: _zod.z.object({
        EndpointsConfig: _zod.z.record(_zod.z.object({
            IPAMConfig: _zod.z.object({
                IPv4Address: _zod.z.string().nullish(),
                IPv6Address: _zod.z.string().nullish(),
                LinkLocalIPs: _zod.z.array(_zod.z.string()).nullish()
            }).strict().nullish(),
            Links: _zod.z.array(_zod.z.string()).nullish(),
            Aliases: _zod.z.array(_zod.z.string()).nullish(),
            NetworkID: _zod.z.string().nullish(),
            EndpointID: _zod.z.string().nullish(),
            Gateway: _zod.z.string().nullish(),
            IPAddress: _zod.z.string().nullish(),
            IPPrefixLen: _zod.z.number().int().nullish(),
            IPv6Gateway: _zod.z.string().nullish(),
            GlobalIPv6Address: _zod.z.string().nullish(),
            GlobalIPv6PrefixLen: _zod.z.number().int().nullish(),
            MacAddress: _zod.z.string().nullish(),
            DriverOpts: _zod.z.object({}).passthrough().nullish()
        }).passthrough())
    }).passthrough().nullish()
}).strict();
