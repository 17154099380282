import { capitalize } from 'lodash';

export const statLabel = capitalize;

export const statPercentageUsed = availablePercent => {
  if ((availablePercent !== 0 && !availablePercent) || isNaN(availablePercent)) {
    return '--';
  }
  return `${Math.floor(100 - availablePercent)}%`;
};

export const statStatus = usage => {
  const library = {
    HIGH: 'disabled',
    MODERATE: 'updating',
    LOW: 'success',
    HOT: 'disabled',
    WARM: 'updating',
    COOL: 'success',
    UNKNOWN: 'pending',
    expired: 'disabled',
    expiring: 'updating',
    active: 'success',
  };
  return library[usage] || 'pending';
};

export const headphoneLabel = connected => {
  return connected ? 'Connected' : 'Disconnected';
};

export const headphoneStatus = connected => {
  return connected ? 'success' : 'disabled';
};
