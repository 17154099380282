import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Card } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import TaggedDeviceList from 'components/tagged-device-list';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useTag } from 'queries';

export default function EditTag() {
  const { handleBack } = useModalContext();
  const { tagId } = useParams();

  const { data, isLoading, isSuccess, error } = useTag(tagId || '');
  const devices = data?.tag?.devices?.data;
  const {
    handleSubmit: onSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  useOnSuccess(
    () => {
      reset({
        name: data?.tag?.name,
      });
    },
    { isSuccess },
    [data, reset],
  );

  const qc = q.useQueryClient();
  const updateTag = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateTag($form: updateTagForm!) {
          updateTag(form: $form) {
            id
            name
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['tags'] });
    },
    onError(err) {
      window.alert(err);
    },
  });

  const removeTag = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeTag($id: ID!) {
          removeTag(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['tags'] });
    },
  });

  const handleDelete = () => {
    if (
      window.confirm('Are you sure you want to delete this tag and remove it from all devices?')
    ) {
      removeTag.mutate(tagId, { onSuccess: () => handleBack() });
    }
  };

  const handleUpdate = onSubmit(form => {
    updateTag.mutate(
      {
        id: tagId,
        ...form,
      },
      { onSuccess: () => handleBack() },
    );
  });

  return (
    <>
      <ModalTitle
        title="Edit Tag"
        icon="Tag"
        showBackButton={true}
        renderActions={() => (
          <Button
            size="xs"
            variant="secondary"
            onClick={() => {
              handleDelete();
            }}
            loading={removeTag.isPending}
            icon="Trash"
          >
            Delete
          </Button>
        )}
      />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Error Loading Tag">
            {error.message}
          </Message>
        )}

        <form onSubmit={handleUpdate}>
          <Card variant="secondary">
            <div className="flex flex-col space-y-2">
              <Label htmlFor="name">Tag Name</Label>
              <Input
                type="text"
                placeholder="Tag"
                readOnly={isLoading || updateTag.isPending}
                {...register('name', { required: 'Please enter a tag name.' })}
              />
              <ValidationError errors={errors} name="name" />
            </div>
          </Card>
        </form>

        <div className="mt-4 space-y-2">
          {isEmpty(devices) ? (
            <EmptyContentMessage icon="Aperture" title="No Tagged Devices" />
          ) : (
            <TaggedDeviceList devices={devices} tagId={tagId} />
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          type="submit"
          onClick={handleUpdate}
          loading={isLoading || updateTag.isPending}
          size="xl"
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
