import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'StaticCropImageElement',
  label: 'Static Crop',
  icon: 'Scissors',
  description: 'Statically crop an image to a specified region of interest.',
  type: 'graphic_elements',
  inputs: [
    {
      label: 'Source Image',
      key: 'full_res_image',
      type: DATA_TYPES['Image'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'cropped_image',
      label: 'Cropped Image',
      type: DATA_TYPES['Image'],
    },
  ],
  defaultConfig: {
    x_val: 0,
    y_val: 0,
    width: 200,
    file_prefix: '',
  },
  schema: z.object({
    config: z.object({
      x_val: z.number().min(0),
      y_val: z.number().min(0),
      width: z.number().min(0),
      file_prefix: z.string().min(0),
    }),
    inputs: z.object({ full_res_image: z.string().min(1) }),
  }),
};
