import skillIcons from 'config/skill-icons';

import getNodeDef from './get-node-def';

export default function createBetaDef(dbObj) {
  const node = {
    type: dbObj.type,
    key: dbObj.name,
    inputs: {},
    config: {},
    meta: {
      isBetaNode: true,
      label: dbObj.label,
      key: dbObj.name,
      icon: skillIcons[dbObj?.icon] ? skillIcons[dbObj?.icon] : skillIcons['skill'],
      description: dbObj.description,
      type: dbObj.type,
      inputs: JSON.parse(dbObj?.inputs),
      outputs: JSON.parse(dbObj?.outputs),
      configFields: JSON.parse(dbObj?.configFields) || [],
    },
  };

  return getNodeDef(node);
}
