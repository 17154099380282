import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { Card, Badge, Button, Heading, Text } from '@optra/kit';

import DeviceImage from 'components/device-image';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import PortalSubscriptionFeatures from 'components/portal-subscription-features';
import { creditsToFormattedString, creditsToFormattedCurrency } from 'lib/format-credits';
import { useDevice, useCurrentBillingAccount } from 'queries';

export default function DeviceSubscription({ deviceId, onComplete }) {
  const { pathname } = useLocation();

  const billingAccount = useCurrentBillingAccount();
  const currentBillingAccount = billingAccount?.data?.currentBillingAccount;
  const { balance } = currentBillingAccount?.balances || 0;

  const { data } = useDevice(
    deviceId,
    `
      id
      name
      model
      serialNumber
    `,
  );

  const creditsRequired = 100000; // TODO: where does this come from?
  const percentAvailable = Math.min((balance / creditsRequired) * 100, 100);

  return (
    <ModalInner>
      <ModalBody className="space-y-6">
        <Card variant="secondary" className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-4">
            <DeviceImage model={data?.device?.model} hideStatus />
            <div>
              <Heading level={2}>Portal Subscription</Heading>
              <div className="flex flex-row items-center space-x-1">
                <Text size="sm" color="muted">
                  {data?.device?.name}
                </Text>
                <Text color="muted">&bull;</Text>
                <Text size="sm" color="muted" className="font-mono">
                  {data?.device?.serialNumber}
                </Text>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <Badge>{`${creditsToFormattedString(creditsRequired)} Credits`}</Badge>
            <Text size="xs" color="muted">
              {creditsToFormattedCurrency(creditsRequired)}
            </Text>
          </div>
        </Card>

        <PortalSubscriptionFeatures />

        {balance < creditsRequired && (
          <Card variant="secondary" className="flex flex-col items-center justify-center space-y-2">
            <Heading level={2}>Credit Balance</Heading>
            <div className="bg-light-bg-tertiary dark:bg-light-bg-secondary w-full h-4 rounded-full">
              <div
                style={{ width: `${percentAvailable}%` }}
                className={cx([
                  'rounded-full',
                  'h-4',
                  percentAvailable >= 100 && 'bg-green',
                  percentAvailable < 100 && 'bg-red',
                ])}
              />
            </div>
            <Text>
              {`${creditsToFormattedString(balance)} / ${creditsToFormattedString(
                creditsRequired,
              )}`}
            </Text>
            <Button
              to={`../${deviceId}/add-credits?returnTo=${pathname}`}
              state={{ fromModal: true }}
              icon="Plus"
              variant="tertiary"
            >
              Add Credits
            </Button>
          </Card>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={balance < creditsRequired} onClick={onComplete} size="xl">
          Subscribe
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
