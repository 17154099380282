import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle, useDebounce } from 'react-use';

import { Card, Button, Icon, Text, SearchField } from '@optra/kit';

import CreateGoogleSpreadsheetForm from 'components/create-google-spreadsheet-form';
import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q, useOnSuccess } from 'config/api';
import { useWorkflowActionConfiguration } from 'queries';

export default function ChooseSpreadsheet() {
  useTitle('Choose Spreadsheet | Optra');
  const { workflowActionId } = useParams();
  const modal = useRef();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [error, setError] = useState();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState();
  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const {
    data,
    isLoading: fetching,
    isSuccess,
    error: fetchError,
  } = useWorkflowActionConfiguration({
    id: workflowActionId,
    inputs: { search: debouncedSearch },
  });

  const qc = q.useQueryClient();
  const updateWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowActionGoogleChooseSheet ($form: updateWorkflowActionForm!) {
          workflowAction: updateWorkflowAction(form: $form) {
            id
            configuration
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflows'] });
      modal.current.handleBack();
    },
    onError(error) {
      setError(error);
    },
  });

  const handleCreatedSpreadsheet = ({ id, name }) => {
    setSelectedSpreadsheet({ id, name });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setError(null);
    updateWorkflowAction.mutate({
      id: workflowActionId,
      configuration: {
        shared: {
          spreadsheetId: selectedSpreadsheet?.id,
        },
      },
    });
  };

  useOnSuccess(
    () => {
      setSelectedSpreadsheet({
        id: data?.workflowAction?.configuration?.shared?.spreadsheetId,
        name: data?.workflowAction?.configuration?.spreadsheetName,
      });
    },
    { isSuccess },
    [data],
  );

  const loading = fetchError || fetching || updateWorkflowAction.isPending;
  const spreadsheets = data?.workflowAction?.fields?.[0]?.configuration?.options || [];

  return (
    <Modal ref={modal}>
      <ModalInner>
        <ModalTitle title="Choose Spreadsheet" icon="GoogleIcon" loading={loading} />
        <ModalBody className="space-y-4">
          {error && (
            <Message variant="danger" title="Couldn't Save Action">
              {error.message}
            </Message>
          )}
          {fetchError && (
            <Message variant="danger" title="Couldn't Load Action">
              {fetchError.message}
            </Message>
          )}

          {!showCreateForm && (
            <Card variant="secondary" className="flex items-center justify-between space-x-2">
              <div className="flex-1">
                <SearchField
                  placeholder="Search Sheets..."
                  onChange={setSearch}
                  searching={fetching}
                  variant="inverted"
                  value={search}
                />
              </div>
              <Button
                size="xs"
                variant="secondary"
                onClick={() => setShowCreateForm(true)}
                icon="Plus"
              >
                New Sheet
              </Button>
            </Card>
          )}

          {showCreateForm && (
            <CreateGoogleSpreadsheetForm
              workflowActionId={workflowActionId}
              onComplete={handleCreatedSpreadsheet}
              onCancel={() => setShowCreateForm(false)}
            />
          )}

          <List loading={fetching}>
            {spreadsheets?.length > 0 &&
              spreadsheets?.map?.(spreadsheet => (
                <ListItem
                  key={spreadsheet.value}
                  renderLeft={() =>
                    selectedSpreadsheet?.id === spreadsheet.value ? (
                      <Icon name="CheckCircle" color="gradient" />
                    ) : (
                      <Icon name="Circle" weight="line" color="gray" />
                    )
                  }
                  onClick={() => {
                    setSelectedSpreadsheet({ id: spreadsheet.value, name: spreadsheet.label });
                  }}
                >
                  <Text>{spreadsheet.label}</Text>
                </ListItem>
              ))}
          </List>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSubmit} size="xl" loading={loading}>
            Save
          </Button>
        </ModalFooter>
      </ModalInner>
    </Modal>
  );
}
