import { api, q } from 'config/api';

export default function useBillingAccountTransactions(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['billingAccountTransactions', variables],
    queryFn: ({ pageParam }) =>
      api(
        `query currentBillingAccountTransactions($list: listFields) {
          currentBillingAccount {
            list: transactions(list: $list) {
              data {
                id
                amount
                description
                processAfter
                processed
                recurringInterval
                createdAt
              }
              cursor {
                after
              }
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      ),
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.currentBillingAccount?.list?.cursor?.after,
    select: data => data.pages.flatMap(page => page.currentBillingAccount.list.data),
    placeholderData: q.keepPreviousData,
    retry: 0,
    ...overrides,
  });
}
