import { useNavigate } from 'react-router-dom';

import { Dropdown } from '@optra/kit';

import { api, q } from 'config/api';

export default function RemoveWorkspaceButton(props) {
  const navigate = useNavigate();
  const { workspaceId } = props;
  const qc = q.useQueryClient();

  const removeWorkspace = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removeWorkspace($id: ID!) {
          workspace: removeWorkspace(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      // NOTE: we reset all queries to ensure cached filtered data doesn't accidentally reveal the deleted workspace
      qc.resetQueries({ queryKey: ['workspaces'] });
    },
  });

  return (
    <Dropdown.Item
      icon="TrashSimple"
      text={removeWorkspace.isPending ? 'Deleting...' : 'Delete'}
      loading={removeWorkspace.isPending}
      onClick={e => {
        e.preventDefault(); // keep dropdown open while deleting
        if (window.confirm('Are you sure you want to remove this workspace?')) {
          removeWorkspace.mutate(workspaceId, {
            onSuccess: () => {
              navigate('/charts');
            },
          });
        }
      }}
    />
  );
}
