import { noop, flatten } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { api, q } from 'config/api';

export default function useDeviceHardwareLogs(
  { deviceId, workflowSkillId, composeName },
  overrides,
) {
  const initialRange = useRef({
    since: undefined,
    until: Math.floor(Date.now() / 1000),
  });

  const [logs, setLogs] = useState([]);
  const [{ since, until }, setRange] = useState(initialRange.current);

  const formatRangeValue = value =>
    Number.isInteger(value) ? Math.floor(value / 1000) : undefined;
  const _setRange = ({ since, until }) =>
    setRange({ since: formatRangeValue(since), until: formatRangeValue(until) });

  useEffect(() => {
    setLogs([]);
  }, [deviceId, workflowSkillId, composeName]);

  const currentlyFetching = useRef(false);
  useEffect(() => {
    if (overrides?.enabled) {
      const interval = setInterval(() => {
        if (!currentlyFetching.current) {
          setRange(prevRange => ({ since: prevRange.until, until: Math.floor(Date.now() / 1000) }));
        }
      }, 5000);
      return () => clearInterval(interval);
    }
    return noop;
  }, [overrides?.enabled, currentlyFetching]);

  const { data, isLoading } = q.useQuery({
    queryKey: ['deviceHardwareLogs', deviceId, workflowSkillId, until, since, composeName],
    queryFn: () =>
      api(
        `query deviceHardwareLogs($deviceId: ID!, $workflowSkillId: ID, $until: Int, $since: Int, $composeName: ID) {
          device(id: $deviceId) {
            id
            hardwareLogs(workflowSkillId: $workflowSkillId, until: $until, since: $since, composeName: $composeName)
          }
        }`,
        {
          deviceId,
          workflowSkillId,
          until,
          since,
          composeName,
        },
      ),
    ...overrides,
  });
  currentlyFetching.current = isLoading;

  useDeepCompareEffect(() => {
    if (data?.device?.hardwareLogs?.length > 0) {
      setLogs(prev => [
        ...flatten(data.device.hardwareLogs.map(group => group.split('\n')))
          .reverse()
          .map((message, idx) => ({
            id: `${prev.length}${idx}`, // Optimize rendering
            message,
          })),
        ...prev,
      ]);
    }
  }, [setLogs, data?.device?.hardwareLogs]);

  return { logs, isLoading: isLoading && logs.length === 0, setRange: _setRange };
}
