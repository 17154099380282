import cx from 'classnames';
import { isEmpty, isFinite as _isFinite } from 'lodash';
import { useEffect, useState, useRef } from 'react';

import { Icon, Text } from '@optra/kit';

import Input from 'components/input';

export default function StepperInput(props) {
  const {
    placeholder,
    value,
    name,
    disabled = false,
    min = 0,
    max = 1000,
    step = 1,
    label,
    onChange = () => false,
    className,
    showUnitLabel,
    unitLabel = '%',
    ...rest
  } = props;
  const [internalValue, setInternalValue] = useState();
  const inputRef = useRef(null);

  // Sync the internal value with the prop value. Convert it to a string or set it to an empty string if the value is not a number.
  useEffect(() => {
    setInternalValue(isNaN(value) ? '' : value?.toString());
  }, [value]);

  // Increment the value by the defined step
  function handleIncrement() {
    if (disabled) return;

    const newValue = parseInt(value, 10) + step;

    if (newValue > max) return;
    if (!_isFinite(newValue)) return;

    onChange(newValue);
    setInternalValue(newValue.toString());
  }

  function handleDecrement() {
    if (disabled) return;

    const newValue = parseInt(value, 10) - step;

    if (newValue < min) return;
    if (!_isFinite(newValue)) return;

    onChange(newValue);
    setInternalValue(newValue.toString());
  }

  function handleChangeValue(event) {
    if (disabled) return;

    const inputValue = event.target.value;
    const newValue = inputValue.replace(` ${label}`, '');

    // Check if the input is a valid number or empty string.
    // TODO: Add support for negative ranges
    if (/^\d*$/.test(newValue)) {
      setInternalValue(newValue);

      // If the new value is not empty and within range, update the external value using onChange.
      if (newValue !== '' && parseInt(newValue, 10) <= max && parseInt(newValue, 10) >= min) {
        onChange(+newValue);
      } else if (newValue === '') {
        // If the new value is empty, use 0 as default value
        onChange(0);
      }
    }
  }

  function handleKeydown(event) {
    if (disabled) return;

    if (event.which === 38) {
      event.preventDefault();
      handleIncrement();
    }

    if (event.which === 40) {
      event.preventDefault();
      handleDecrement();
    }
  }

  // Generate the display value for the input, appending the label if it is provided
  function getDisplayValue() {
    // If a label is provided, append it to the internal value. Otherwise, return the internal value as a string.
    return !isEmpty(label) ? `${internalValue || ''} ${label}` : internalValue || '';
  }

  // Set the cursor position to the end of the input value whenever the internal value changes
  useEffect(() => {
    if (inputRef.current) {
      const numericLength = internalValue?.length || 0; // Calculate the length of the internal value.
      inputRef.current.setSelectionRange(numericLength, numericLength); // Set the cursor to the end of the input value.
    }
  }, [internalValue]);

  return (
    <div className={cx(['relative', 'select-none', className])} {...rest}>
      <Input
        ref={inputRef}
        type="text"
        name={name}
        placeholder={placeholder}
        value={getDisplayValue()}
        disabled={disabled}
        readOnly={disabled}
        onKeyDown={handleKeydown}
        onChange={handleChangeValue}
      />
      <Icon
        onClick={handleIncrement}
        className="w-4 h-4 absolute top-1 right-4"
        name="CaretUp"
        weight="line"
        size="xs"
      />
      <Icon
        onClick={handleDecrement}
        className="w-4 h-4 absolute bottom-1 right-4"
        name="CaretDown"
        weight="line"
        size="xs"
      />
      {showUnitLabel && (
        <Text
          size="xs"
          color="muted"
          className="absolute inset-y-0 right-9 w-6 flex justify-center items-center pointer-events-none"
        >
          {unitLabel}
        </Text>
      )}
    </div>
  );
}
