import xor from 'lodash/xor';
import { useController } from 'react-hook-form';

import { FilterContainer, Group } from '@optra/kit';

import {
  ControlFilterWorkflows,
  ControlFilterDevices,
  ControlFilterSkills,
  ControlFilterTags,
  ControlFilterLocations,
} from 'modals/chart-details/controls/controls-filters';

export default function ChartFiltersInput(props) {
  const { rules, name, control, defaultValue = {} } = props;

  const controller = useController({
    control,
    rules,
    name,
    defaultValue,
  });

  const currentValue = controller.field?.value;

  return (
    <Group
      heading="Filters"
      description="Refine data by workflow, device, etc."
      count={0}
      collapsible={false}
      scrollable
      defaultCollapsed={false}
    >
      <FilterContainer stacked>
        <ControlFilterWorkflows
          selectedWorkflowIds={currentValue?.workflowIds || []}
          onClear={() => {
            controller.field.onChange({
              ...currentValue,
              workflowIds: [],
            });
          }}
          onSelect={id => {
            controller.field.onChange({
              ...currentValue,
              workflowIds: xor(currentValue?.workflowIds || [], [id]),
            });
          }}
        />
        <ControlFilterDevices
          selectedDeviceIds={currentValue?.deviceIds || []}
          onClear={() => {
            controller.field.onChange({
              ...currentValue,
              deviceIds: [],
            });
          }}
          onSelect={id => {
            controller.field.onChange({
              ...currentValue,
              deviceIds: xor(currentValue?.deviceIds || [], [id]),
            });
          }}
        />
        <ControlFilterSkills
          selectedSkillIds={currentValue?.skillIds || []}
          onClear={() => {
            controller.field.onChange({
              ...currentValue,
              skillIds: [],
            });
          }}
          onSelect={id => {
            controller.field.onChange({
              ...currentValue,
              skillIds: xor(currentValue?.skillIds || [], [id]),
            });
          }}
        />
        <ControlFilterTags
          selectedTagIds={currentValue?.tagIds || []}
          onClear={() => {
            controller.field.onChange({
              ...currentValue,
              tagIds: [],
            });
          }}
          onSelect={tag => {
            controller.field.onChange({
              ...currentValue,
              tagIds: xor(currentValue?.tagIds || [], [tag.name]),
            });
          }}
        />
        <ControlFilterLocations
          selectedLocationIds={currentValue?.locationIds || []}
          onClear={() => {
            controller.field.onChange({
              ...currentValue,
              locationIds: [],
            });
          }}
          onSelect={id => {
            controller.field.onChange({
              ...currentValue,
              locationIds: xor(currentValue?.locationIds || [], [id]),
            });
          }}
        />
      </FilterContainer>
    </Group>
  );
}
