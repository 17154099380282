import { Card, Heading, Text } from '@optra/kit';

import usePipelineNode from 'modals/skill-pipeline-builder/context/use-pipeline-node';

export default function DetectionCountPerFrameProcessor(props) {
  const { nodeId } = props || {};
  const [node, { update }] = usePipelineNode(nodeId);

  return (
    <div className="space-y-3">
      <Heading level={3}>Confidence Threshold</Heading>
      <Card variant="secondary" className="space-y-3 flex-col text-center">
        <Text size="xl" variant="bold" className="block">
          {parseInt(node?.config?.confidenceThreshold * 100, 10)}%
        </Text>
        <input
          type="range"
          step={0.01}
          min={0}
          max={1}
          value={node?.config?.confidenceThreshold}
          onChange={e => {
            const value = parseFloat(parseFloat(e.target.value).toFixed(2));
            update({
              key: 'config.confidenceThreshold',
              value,
            });
          }}
          className="w-full"
        />
      </Card>
    </div>
  );
}
