import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Button, Table, DetailList, CopyButton, Icon, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';

export default function AdminServiceAccountsList({ serviceAccounts = [] }) {
  const navigate = useNavigate();

  if (isEmpty(serviceAccounts)) {
    return (
      <EmptyContentMessage
        icon="Robot"
        title="No Service Accounts"
        renderAction={() => (
          <Button to="/admin/service-accounts/create" icon="Plus">
            New Service Account
          </Button>
        )}
      />
    );
  }

  return (
    <Table>
      <Table.Body>
        {serviceAccounts.map(serviceAccount => (
          <Table.Row
            key={serviceAccount?.id}
            hover
            caret
            onClick={() => navigate(`/admin/service-accounts/${serviceAccount?.id}/edit`)}
          >
            <Table.TD>
              <Text>{serviceAccount.name}</Text>
              <DetailList
                details={[
                  {
                    component: (
                      <span className="flex flex-nowrap items-center gap-1">
                        <span>{serviceAccount.secret}</span>
                        <CopyButton value={serviceAccount.secret} />
                      </span>
                    ),
                  },
                ]}
              />
            </Table.TD>
            <Table.TD collapsed>
              <DetailList
                nowrap
                className="justify-end"
                details={serviceAccount.privileges.map(privilege => ({
                  component: (
                    <span className="flex flex-nowrap items-center gap-1">
                      <Icon size="xs" name="Check" color="primary" weight="bold" />
                      <span>{privilege}</span>
                    </span>
                  ),
                }))}
              />
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
