import { cloneElement } from 'react';

const ProviderComposer = props => {
  const { providers } = props;
  return (providers || []).reduceRight(
    (children, parent) =>
      cloneElement(parent, {
        children,
      }),
    props.children,
  );
};

export default ProviderComposer;
