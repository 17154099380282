import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTitle } from 'react-use';

import { Button } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';
import validateEmail from 'lib/validate-email';

export default function AdminCreateSuperUser() {
  useTitle('Create Super User | Optra');
  const { handleClose } = useModalContext();
  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'email');

  const qc = q.useQueryClient();
  const createSuperUser = q.useMutation({
    mutationFn: form =>
      api(
        `mutation($email: String!) {
          user: createSuperUser(email: $email) {
            id
            user {
              image
              email
              name
            }
          }
        }`,
        form,
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['superUsers'] });
      handleClose();
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    createSuperUser.mutate(form);
  });

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Create Super User" icon="Users" loading={createSuperUser.isPending} />
      <ModalBody>
        {error && (
          <Message variant="danger" title="Couldn't Create Super User">
            {error.message}
          </Message>
        )}

        <div className="space-y-2">
          <Label htmlFor="email">User Email</Label>
          <Input
            type="email"
            {...register('email', {
              required: 'Please enter an email address.',
              validate: validateEmail,
            })}
            readOnly={createSuperUser.isPending}
          />
          <ValidationError errors={errors} name="email" />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={createSuperUser.isPending}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
