import Checkbox from 'components/checkbox';
import Input from 'components/input';
import Label from 'components/label';
import Select from 'components/select';

export default function SpreadsheetDataFields({
  register,
  watch,
  output,
  errors,
  workflowSkill,
  loading,
}) {
  const enabled = watch(`configuration[${workflowSkill.id}][${output.id}].enabled`);

  return (
    <div className="py-4 space-y-4">
      <Label>
        <Checkbox
          {...register(`configuration[${workflowSkill.id}][${output.id}].enabled`)}
          className="mr-2"
          readOnly={loading}
        />
        {output.label}
      </Label>

      {enabled && (
        <div className="flex items-center justify-between space-x-2">
          <div className="space-y-2 flex-1">
            <Label>Cell</Label>
            <Input
              type="text"
              {...register(`configuration[${workflowSkill.id}][${output.id}].range`)}
              placeholder="e.g. Sheet1!A1"
              readOnly={loading}
            />
          </div>
          <div className="space-y-2">
            <Label>Action</Label>
            <Select
              {...register(`configuration[${workflowSkill.id}][${output.id}].fn`)}
              readOnly={loading}
            >
              <option value="append">Insert New Row</option>
              <option value="update">Fill Cell Value</option>
            </Select>
          </div>
        </div>
      )}
    </div>
  );
}
