import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import DeviceSubscription from 'components/device-subscription';
import { useFeature } from 'components/feature';
import { useModalContext } from 'components/modal';
import ModalTitle from 'components/modal-title';
import { useCurrentOrganization } from 'queries';

export default function DeviceCreateSubscription() {
  useTitle('Subscribe to Device | Optra');
  const { handleClose, handleBack, showBackButton } = useModalContext();
  const { provisionedDeviceId } = useParams();
  const navigate = useNavigate();
  const [organization] = useCurrentOrganization();
  const billingBetaEnabled = useFeature('billingBeta');

  useEffect(() => {
    if (!billingBetaEnabled || (organization?.pricing && organization.pricing !== 'credit')) {
      navigate({
        pathname: `/devices/create/${provisionedDeviceId}/confirmation`,
        state: { fromModal: true },
        replace: true,
      });
    }
  }, [billingBetaEnabled, organization, navigate, provisionedDeviceId]);

  return (
    <>
      <ModalTitle
        title="Enroll Device"
        icon="Aperture"
        handleBack={handleBack}
        showBackButton={showBackButton}
        handleClose={() => {
          if (window.confirm('Are you sure you want to cancel the enrollment process?')) {
            handleClose();
          }
        }}
      />
      <DeviceSubscription
        deviceId={provisionedDeviceId}
        onComplete={() => {
          navigate({
            pathname: `/devices/create/${provisionedDeviceId}/confirmation`,
            state: {
              fromModal: true,
            },
          });
        }}
      />
    </>
  );
}
