import { isEmpty } from 'lodash';

import { Button, IconButton, Table, Text } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import { api, q } from 'config/api';
import dateLabel from 'lib/date-label';
export default function AdminPreclaimedDevicesList({ list = [], selectedOrganizationId }) {
  const qc = q.useQueryClient();
  const removePreclaimedDevice = q.useMutation({
    mutationFn: id =>
      api(
        `mutation removePreclaimedDevice($id: ID!) {
          preclaimedDevice: removePreclaimedDevice(id: $id) {
            id
          }
        }`,
        { id },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['preclaimedDevices'] });
    },
  });

  const handleRemovePreclaimedDevice = id => {
    if (window.confirm('Are you sure you want to remove this preclaimed device?')) {
      removePreclaimedDevice.mutate(id);
    }
  };

  if (isEmpty(list)) {
    return (
      <EmptyContentMessage
        icon="Receipt"
        title="No Devices"
        renderAction={() => (
          <Button
            to={`/admin/preclaimed-devices/upload?organizationId=${selectedOrganizationId}`}
            icon="CloudArrowUp"
          >
            Upload List
          </Button>
        )}
      />
    );
  }

  return (
    <Table>
      <Table.Body>
        {list.map(item => (
          <Table.Row hover key={item.id}>
            <Table.TD>
              <Text variant="bold">{item.serialNumber}</Text>
            </Table.TD>
            <Table.TD className="flex justify-end">
              <Text className="mr-4">
                {item?.provisionedDevice?.status === 'claimed'
                  ? `Claimed ${dateLabel(item?.provisionedDevice?.firstEnrolledAt) || ''}`
                  : 'Unclaimed'}
              </Text>
              {item?.provisionedDevice?.status !== 'claimed' && (
                <IconButton
                  name="Trash"
                  variant="plain"
                  onClick={() => handleRemovePreclaimedDevice(item.id)}
                />
              )}
            </Table.TD>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
