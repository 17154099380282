import { useTitle } from 'react-use';

import { Button } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';

export default function ItemDeleted(props) {
  useTitle('Item Deleted | Optra');

  const {
    id,
    type,
    handleClose: _handleClose,
    handleBack: _handleBack,
    showBackButton: _showBackButton,
  } = props;
  const context = useModalContext();
  const handleBack = _handleBack || context.handleBack;
  const handleClose = _handleClose || context.handleClose;
  const showBackButton = _showBackButton || context.showBackButton;

  return (
    <ModalInner>
      <ModalTitle
        title="Item Deleted"
        icon="Warning"
        handleClose={handleClose}
        handleBack={handleBack}
        showBackButton={showBackButton}
      />
      <ModalBody>
        <EmptyContentMessage
          icon="Warning"
          title="Item Deleted"
          body={`${type} with ID “${id}” is deleted`}
          renderAction={() => <Button onClick={handleClose}>Close</Button>}
        />
      </ModalBody>
    </ModalInner>
  );
}
