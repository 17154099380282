import isEmpty from 'lodash/isEmpty';

import { Button } from '@optra/kit';

export default function MarketplaceSkillTabs({ currentTab = 'listing', marketplaceSkill }) {
  const tabs = {
    installed: {
      display: marketplaceSkill?.purchased === true,
      key: 'installed',
      label: 'Installed',
      icon: 'Aperture',
    },
    listing: {
      display: true,
      key: 'listing',
      label: 'Marketplace',
      icon: 'SquaresFour',
    },
    manual: {
      display: !isEmpty(marketplaceSkill?.manualUrl),
      key: 'manual',
      label: 'Manual',
      icon: 'FileText',
    },
  };

  const displayedTabs = Object.values(tabs).filter(t => t.display === true);
  if (displayedTabs.length < 2) {
    return null;
  }

  return (
    <div className="flex items-center justify-center">
      {displayedTabs.map(({ key, label, icon }, idx) => (
        <Button
          key={key}
          variant={currentTab === key ? 'primary' : 'tertiary'}
          to={`?tab=${key}`}
          icon={icon}
          size="sm"
          className={
            idx === 0
              ? 'rounded-r-none'
              : idx === displayedTabs.length - 1
              ? 'rounded-l-none'
              : 'rounded-none'
          }
        >
          {label}
        </Button>
      ))}
    </div>
  );
}
