import { flatMap } from 'lodash';

import { PrimaryCTAButton, PageWrapper } from '@optra/kit';

import AdminServiceAccountsList from 'components/admin-service-accounts-list';
import IntersectionLoadMore from 'components/intersection-load-more';
import PinPageButton from 'components/pin-page-button';
import { api, q } from 'config/api';

function useServiceAccounts(variables) {
  return q.useInfiniteQuery({
    queryKey: ['serviceAccounts', variables],
    queryFn({ pageParam }) {
      return api(
        `query serviceAccounts($list: listFields) {
          list: serviceAccounts(list: $list) {
            data {
              id
              name
              secret
              privileges
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
  });
}

export default function AdminServiceAccounts() {
  const { isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, data } = useServiceAccounts();
  const serviceAccounts = flatMap(data?.pages, page => page?.list?.data);

  return (
    <PageWrapper
      icon="Robot"
      title="Service Accounts | Admin"
      heading="Service Accounts"
      inline
      loading={isLoading}
      components={{
        actions: (
          <>
            <PinPageButton />
            <PrimaryCTAButton to="/admin/service-accounts/create" text="New Service Account" />
          </>
        ),
      }}
    >
      <AdminServiceAccountsList serviceAccounts={serviceAccounts} />
      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isFetchingNextPage || !hasNextPage}
      />
    </PageWrapper>
  );
}
